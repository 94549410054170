/** @jsx jsx */
/* @jsxFrag React.Fragment */

import { jsx, css } from '@emotion/core';
import React from 'react';

const LongText = ({ form, field, theme, fieldsOptions, variables }) => {
  const alignOptions = {
    left: 'flex-start',
    center: 'center',
    right: 'flex-end'
  };

  const mainStyle = css({
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: alignOptions[theme.imagesAlignment],
    margin: `${theme.imagesTopMargin || 0}px 0 ${theme.imagesBottomMargin || 0}px 0`
  });

  const contentStyle = css({
    width: '100%',
    maxWidth: `${theme.imagesWidth}${theme.imagesWidthType}`,
    textAlign: field.imageAlign
  });

  let onError = (e) => {
    e.target.src = e.target.src.replace(/hash=\d*/, `hash=${Date.now()}`);
  };

  return <>
    <div css={mainStyle}>
      <div css={contentStyle}>
        <img src={field.value} onError={(e) => {
          onError(e);
          onError = () => { };
        }} alt="" style={{ width: `${field.imageScale}%` }} />
      </div>
    </div>
  </>;
};

export default LongText;
