import styles from './FromTemplate.module.css';

import config from 'config.js';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import Header from '../Header/Header.js';

import { ReactComponent as MenuDashboardIcon } from 'assets/images/menu-dashboard.svg';
import { ReactComponent as CheckmarkIcon } from 'assets/images/checkmark-circle.svg';

import { Loader, Button, FormRow, Select } from 'ui';

import {
  getTemplates
} from 'store/ducks/_templates.js';

import {
  createFormFromTemplate
} from 'store/ducks/_forms.js';

import {
  getWorkspaces
} from 'store/ducks/_workspaces.js';

const Preview = ({ selectedFormType, formClassic, formConversational }) => {
  const previewRef = React.createRef();

  const form = selectedFormType === 'classic' ? formClassic : formConversational;

  useEffect(() => {
    const iframe = previewRef.current.querySelector('iframe');

    if (iframe) iframe.remove();

    if (selectedFormType === 'classic') {
      setTimeout(() => {
        if (!previewRef.current) return;

        const box = previewRef.current.getBoundingClientRect();

        (function (a, b, c, e, f, x) {
          let s = a.createElement('script');
          s.src = b; s.setAttribute('data-form-id', e);
          s.setAttribute('data-runner-id', c);
          s.setAttribute('data-url-params', f);
          s.setAttribute('data-preview-token', x);
          s.setAttribute('data-size', `${box.width}x${box.height}`);
          a.head.appendChild(s);
        })(window.document, `${config.formUrl}/qs-form-script.min.js`, 'cqcjbhnrrsrdnkq', form._id, '[{"key":"qs__ghost","value":"true"}]', form.previewToken);
      });
    } else {
      setTimeout(() => {
        if (!previewRef.current) return;

        const box = previewRef.current.getBoundingClientRect();

        (function (a, b, c, e, f, x) {
          var s = a.createElement('script');
          s.src = b; s.setAttribute('data-form-id', e);
          s.setAttribute('data-runner-id', c);
          s.setAttribute('data-url-params', f);
          s.setAttribute('data-preview-token', x);
          s.setAttribute('data-size', `${box.width}x${box.height}`);
          a.head.appendChild(s);
        })(window.document, `${config.formUrl}/qs-form-script.min.js`, 'cqcjbhnrrsrdnkq', form._id, '[{"key":"qs__ghost","value":"true"}]', form.previewToken);
      });
    }
  }, [selectedFormType]);

  if (form === null) return <></>;

  return <div ref={previewRef} className={styles.form} style={{ overflowY: 'hidden' }}>
    <script id="cqcjbhnrrsrdnkq" />
  </div>;
}

const FromTemplate = ({ match }) => {
  const dispatch = useDispatch();

  const user = useSelector(state => state._users.user);
  const loading = useSelector(state => state._templates.getTemplates.loading);
  const loadingCreate = useSelector(state => state._forms.createFormFromTemplate.loading);
  const templates = useSelector(state => state._templates.templates);
  const categories = useSelector(state => state._templates.categories);

  const [selectedCategoryIndex, setSelectedCategoryIndex] = useState(-1);
  const [selectedTemplateIndex, setSelectedTemplateIndex] = useState(-1);
  const [selectedFormType, setSelectedFormType] = useState('classic');
  const [redirecting, setRedirecting] = useState(false);
  const [formWorkspace, setFormWorkspace] = useState(match.params.workspace || null);

  const isLoading = loadingCreate || loading || redirecting;

  const workspaces = [{
    label: 'Uncategorized', value: null
  }, ...useSelector(state => state._workspaces.workspaces).filter((item) => {
    const permissions = item.users.find((u) => u.user === user._id);

    if (user.role !== 'user') return true;

    return permissions && permissions.create;
  }).map((item) => {
    return {
      label: item.name,
      value: item._id
    };
  })];

  useEffect(() => {
    if (templates.length === 0 || categories.length === 0) dispatch(getTemplates());
    dispatch(getWorkspaces());
  }, []);

  const submit = () => {
    if (isLoading) return;

    const template = templates[selectedTemplateIndex];

    setRedirecting(true);

    dispatch(createFormFromTemplate(selectedFormType === 'classic' ? template.formClassic._id : template.formConversational._id, {
      workspace: formWorkspace,
      name: template.name
    })).then((res) => {
      if (res.payload && res.payload._id) {
        window.location.replace(`/builder/${res.payload._id}/build`);
      }
    });
  };

  return (
    <>
      <Header showAccount={true} showMessages={true} showMenu={true} />

      {isLoading && <div className={styles.main}>
        <Loader size={50} />
      </div>}

      {!isLoading && <div className={styles.main}>
        {selectedTemplateIndex === -1 && <Link to="/" className={styles.back}>
          <MenuDashboardIcon style={{ minHeight: 12, minWidth: 12 }} /><span>Return to Workspaces</span>
        </Link>}

        {selectedTemplateIndex !== -1 && <div className={styles.back} onClick={() => setSelectedTemplateIndex(-1)}>
          <MenuDashboardIcon style={{ minHeight: 12, minWidth: 12 }} /><span>Return to Templates Overview</span>
        </div>}

        {selectedTemplateIndex === -1 && <>
          <div className={styles.header}>
            <div className={styles.left}>
              <div className={styles.title}>Template Gallery</div>
              <div className={styles.desc}>Select one of our templates to create a new form or survey.</div>
            </div>

            <div className={styles.right}>
              <Button theme="blue" link={`/form/create/scratch/${match.params.workspace || ''}`}>Start from scratch instead</Button>
            </div>
          </div>

          <div className={styles.content}>
            <ul className={styles.categories}>
              <li className={selectedCategoryIndex === -1 ? styles.active : ''} onClick={() => setSelectedCategoryIndex(-1)}>
                All<MenuDashboardIcon style={{ minHeight: 12, minWidth: 12 }} />
              </li>
              {categories.map((category, index) => <li key={category._id} className={selectedCategoryIndex === index ? styles.active : ''} onClick={() => setSelectedCategoryIndex(index)}>
                {category.name}<MenuDashboardIcon style={{ minHeight: 12, minWidth: 12 }} />
              </li>)}
            </ul>

            <div className={styles.templates}>
              <div className={styles.templatesTitle}>All Templates</div>

              <ul className={styles.templatesList}>
                {templates.map((template, index) => {
                  if (selectedCategoryIndex !== -1 && template.category._id !== categories[selectedCategoryIndex]._id) return;

                  return <li key={template._id} onClick={() => setSelectedTemplateIndex(index)}>
                    <div className={styles.templatesListImage}>
                      <img src={template.image} alt={template.name} />
                    </div>
                    <span>{template.name}</span>
                  </li>;
                })}
              </ul>
            </div>
          </div>
        </>}

        {selectedTemplateIndex !== -1 && <>
          <div className={styles.preview}>
            <div className={styles.left}>
              <Preview selectedFormType={selectedFormType}
                formClassic={templates[selectedTemplateIndex].formClassic}
                formConversational={templates[selectedTemplateIndex].formConversational} />
            </div>

            <div className={styles.right}>
              <FormRow label="Form Workspace">
                <Select width="100%" maxHeight={200} options={workspaces} margin="0 0 10px 0" value={
                  { label: workspaces.find((option) => option.value === formWorkspace).label, value: formWorkspace }
                } onChange={(selected) => setFormWorkspace(selected.value)} />
              </FormRow>

              <div className={styles.title}>{templates[selectedTemplateIndex].name}</div>
              <div className={styles.desc}>{templates[selectedTemplateIndex].description}</div>

              <div className={styles.types}>
                <div className={[styles.type, selectedFormType === 'classic' ? styles.active : ''].join(' ')} onClick={() => setSelectedFormType('classic')}>
                  <div className={styles.typeBadge}>Traditional Format</div>
                  <div className={styles.typeText}>Gather data using a more traditional interface, displaying multiple questions at a time.</div>
                  {selectedFormType === 'classic' && <CheckmarkIcon />}
                </div>
                <div className={[styles.type, selectedFormType === 'conversational' ? styles.active : ''].join(' ')} onClick={() => setSelectedFormType('conversational')}>
                  <div className={styles.typeBadge}>Conversational Format</div>
                  <div className={styles.typeText}>Collect responses by showing just a single question at a time.</div>
                  {selectedFormType === 'conversational' && <CheckmarkIcon />}
                </div>
              </div>

              <Button theme="blue" fullWidth={true} onClick={() => submit()}>Use this template</Button>
              <Link to={`/form/create/scratch/${match.params.workspace || ''}`} className={styles.scratch}>Or start from scratch</Link>
            </div>
          </div>
        </>}
      </div>}
    </>
  );
};

export default FromTemplate;
