import styles from './PlanSummary.module.css';

import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getFormsPrice, getSubmissionsPrice, getUsersPrice, getDomainsPrice, getFilesStoragePrice, getTaxRateForCountry } from 'misc/helpers.js';

import { TextInput, Button, Loader, BasicLoader } from 'ui';

const PlanSummary = ({
  plan, buttonText, buttonTo, edit, loading, user, prorate, showPrevious,
  showProrate, prorateLoading, form, showTax, country, vatEu, type,
  editOnClick, editOnCancel, editOnApply, onTotalChange,
  onGetCoupon, onClearCoupon, coupon, showCoupon
}) => {
  let previousNet = 0;
  let previousGross = 0;

  const [couponCode, setCouponCode] = useState('');

  prorate = Math.round((prorate || 0) * 100) / 100;

  const showMinPrice = edit === false || (buttonTo && buttonText) || (showPrevious && showProrate);
  const taxRate = getTaxRateForCountry(country, vatEu);

  const totalNet = Math.round(((plan.brandingRemoval ? 5 : 0) + getDomainsPrice(plan.domains) + getFormsPrice(plan.forms) + getSubmissionsPrice(plan.submissions) + getUsersPrice(plan.users) + getFilesStoragePrice(plan.filesStorage)) * 100) / 100;
  const isUnlimited = totalNet >= 99 || type === 'unlimited';
  const totalGross = (Math.round(((isUnlimited ? 99 : totalNet) + ((isUnlimited ? 99 : totalNet) / 100 * taxRate)) * 100) / 100);

  if (showPrevious && user) {
    previousNet = Math.round(((user.plan.brandingRemoval ? 5 : 0) + getDomainsPrice(user.plan.domains) + getFormsPrice(user.plan.forms) + getSubmissionsPrice(user.plan.submissions) + getUsersPrice(user.plan.users) + getFilesStoragePrice(user.plan.filesStorage)) * 100) / 100
    if (previousNet >= 99) previousNet = 99;
    previousGross = Math.round((previousNet + (previousNet / 100 * taxRate)) * 100) / 100;
  }

  useEffect(() => {
    if (onTotalChange) onTotalChange(totalNet >= 99 ? 99 : totalNet);
  }, [totalNet]);

  const clearCoupon = () => {
    setCouponCode('');
    onClearCoupon();
  };

  const handleCouponEnterKeyPress = (e) => {
    if (e.key !== 'Enter') return;

    onGetCoupon(couponCode);
  };

  return <>
    <div className={styles.summary}>
      <div className={styles.title}>
        <span>Plan Summary</span>
        {edit === true && <Button size="small" theme="white" onClick={editOnClick}>Edit</Button>}
        {edit === false && <Button size="small" theme="white" onClick={editOnCancel}>Cancel</Button>}
      </div>

      <ul>
        <li>
          <div>All Features</div>
          <div style={{ color: '#24B47E' }}>Included</div>
        </li>
        <li>
          <div>Branding Removal</div>
          <div style={{ color: isUnlimited ? '#24B47E' : '#202020' }}>{isUnlimited ? 'Included' : plan.brandingRemoval ? '€5' : 'Excluded'}</div>
        </li>
        <li>
          <div>{isUnlimited ? <>Unlimited forms</> : <>{plan.forms} total forms</>}</div>
          <div style={{ color: isUnlimited ? '#24B47E' : '#202020' }}>{isUnlimited ? <>Included</> : <>€{getFormsPrice(plan.forms)}</>}</div>
        </li>
        <li>
          <div>{isUnlimited ? <>Unlimited responses</> : <>{plan.submissions} monthly responses</>}</div>
          <div style={{ color: isUnlimited ? '#24B47E' : '#202020' }}>{isUnlimited ? <>Included</> : <>€{getSubmissionsPrice(plan.submissions)}</>}</div>
        </li>
        <li>
          <div>{isUnlimited ? <>Unlimited users</> : <>{plan.users} user{plan.users > 1 && 's'}</>}</div>
          <div style={{ color: isUnlimited ? '#24B47E' : '#202020' }}>{isUnlimited ? <>Included</> : <>€{getUsersPrice(plan.users)}</>}</div>
        </li>
        <li>
          <div>{isUnlimited ? <>10GB monthly file storage</> : <>{plan.filesStorage / 1000}GB monthly file upload</>}</div>
          <div style={{ color: isUnlimited ? '#24B47E' : '#202020' }}>{isUnlimited ? <>Included</> : <>€{getFilesStoragePrice(plan.filesStorage)}</>}</div>
        </li>
        <li>
          <div>{isUnlimited ? <>Unlimited domains</> : <>{plan.domains} domain{plan.domains > 1 && 's'}</>}</div>
          <div style={{ color: isUnlimited ? '#24B47E' : '#202020' }}>{isUnlimited ? <>Included</> : <>€{getDomainsPrice(plan.domains)}</>}</div>
        </li>
      </ul>

      {showTax && <div className={styles.tax}>
        <div>Tax</div>
        <div>€{Math.round(((isUnlimited ? 99 : totalNet) / 100 * taxRate) * 100) / 100}<span>/mo</span></div>
      </div>}

      <div className={styles.total}>
        <div>Total</div>
        <div>€{totalGross}<span>/mo</span></div>
      </div>

      {showPrevious && <div className={styles.previous}>
        <div>Previous</div>
        <div>€{previousGross}<span>/mo</span></div>
      </div>}

      {showProrate && <div className={styles.prorate}>
        <div>One-time prorate</div>
        {prorateLoading && <BasicLoader size="25" />}

        {!prorateLoading && <>
          {(totalNet >= 1) && <div>€{prorate > 0 ? prorate : '0'}</div>}
        </>}
      </div>}

      {buttonTo && buttonText && <Link className={[
        styles.start,
        totalNet < 1 && type === 'flex' ? styles.disabled : ''
      ].join(' ')} to={buttonTo}>{buttonText}</Link>}

      {edit === false && <div className={styles.button}>
        <Button theme="blue" size="medium" fullWidth={true} onClick={editOnApply} disabled={totalNet < 1 && type === 'flex'}>Apply Changes</Button>
      </div>}

      {showMinPrice && <div className={styles.minimum}>{type === 'flex' && totalNet < 1 && 'The minimum total price of the QuestionScout Pro plan has to be at least 1 euro (excluding VAT).'}</div>}

      {showCoupon && <>
        {!coupon.id && <div className={styles.couponOff}>
          <TextInput onKeyPress={handleCouponEnterKeyPress} placeholder="Discount Code" value={couponCode} onChange={(e) => setCouponCode(e.target.value)} />
          <Button theme="white" onClick={() => onGetCoupon(couponCode)}>Apply</Button>
        </div>}
        {coupon.id && <div className={styles.couponOn}>
          <div className={styles.info}>
            <strong>{coupon.name}</strong> <br />
            {coupon.percent_off}% off {coupon.duration_in_months && <>for {coupon.duration_in_months} month{coupon.duration_in_months > 1 ? 's' : ''}</>}
          </div>
          <div className={styles.remove} onClick={() => clearCoupon()}>Remove</div>
        </div>}
      </>}

      {loading && <Loader size="30" background="rgba(255, 255, 255, 0.5)" />}
    </div>
  </>;
};

export default PlanSummary;
