import styles from './DropdownMenu.module.css';
import { Link } from 'react-router-dom';
import React, { useState } from 'react';
import ScrollArea from 'react-scrollbar';

import { ReactComponent as DropdowArrowSmall } from 'assets/images/dropdow-arrow-small.svg';

const types = {
  label: (item) => {
    return <div className={styles.label}>{item.text}</div>
  },
  link: (item) => {
    return <>
      {!item.outside && <Link className={styles.link} to={item.link}>{item.text}</Link>}
      {item.outside && <a className={styles.link} href={item.link} target="_blank" rel="noopener noreferrer">{item.text}</a>}
    </>
  },
  hr: () => {
    return <hr className={styles.hr} />
  },
  list: (item, setSelectedList) => {
    return <div className={styles.list} onClick={() => setSelectedList(item.id)}><span>{item.text}</span><DropdowArrowSmall /></div>
  },
  action: (item) => {
    return <div className={[styles.action, styles[item.theme], item.disabled ? styles.disabled : ''].join(' ')} onClick={() => item.onClick(...(item.params || []))}>{item.text}</div>
  }
}

export const DropdownMenu = ({ items, width, onClose, position }) => {
  const [selectedList, setSelectedList] = useState(null);

  const selectedListObject = selectedList ? items.find((item) => item.id === selectedList) : {};

  return (
    <>
      <div className={styles.cover} onClick={onClose} />
      <div className={styles.main} style={{ width, ...position }}>
        <ul>
          {!selectedList && items.filter((item) => !item.hidden).map((item, index) => <li key={index}>
            {types[item.type](item, setSelectedList)}
          </li>)}

          {selectedList && <li className={styles.back} onClick={() => setSelectedList(null)}><DropdowArrowSmall />{selectedListObject.text}</li>}
          {selectedList && <ScrollArea speed={0.8}
            className={styles.scrollArea}
            contentClassName={styles.scrollAreaContent}
            stopScrollPropagation={true}
            horizontal={false}>
            {selectedListObject.items.filter((item) => !item.hidden).map((item, index) => <li key={index}>
              <div className={[styles.action, selectedListObject.selected === item._id ? styles.selected : ''].join(' ')} onClick={() => selectedListObject.onClick(...(selectedListObject.params || []), item._id)}>{item.name}</div>
            </li>)}
          </ScrollArea>}
        </ul>
      </div>
    </>
  );
};
