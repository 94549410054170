import styles from './BillingUpdatePlan.module.css';

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import PlanSetup from 'components/shared/PlanSetup.js';
import PlanSummary from 'components/shared/PlanSummary.js';
import PlanUnlimited from 'components/shared/PlanUnlimited.js';
import { getFormsPrice, getSubmissionsPrice, getFilesStoragePrice, getDomainsPrice, getUsersPrice, getTaxRateForCountry } from 'misc/helpers.js';
import debounce from 'lodash.debounce';
import Sticky from 'react-stickynode';

import { Button, Loader } from 'ui';

import {
  getBilling
} from 'store/ducks/user.js';

import {
  updateUser
} from 'store/ducks/_users.js';

import {
  updateSelectedPlan, updateSelectedPlanType, resetSelectedPlan, getProrationPreview, setProrationPreviewDate, updateProrateLoading, cancelSubscription, updateSubscription, updateLoading
} from 'store/ducks/plan.js';

import { ReactComponent as WarningIcon } from 'assets/images/warning.svg';
import { ReactComponent as CheckmarkIcon } from 'assets/images/checkmark-circle.svg';

class BillingUpdatePlan extends Component {
  UNSAFE_componentWillMount = () => {
    this.props.getBilling();
    this.props.resetSelectedPlan().then(() => this.props.setProrationPreviewDate(Math.floor(Date.now() / 1000))).then(this.props.getProrationPreview);
    this.props.updateSelectedPlanType(this.props.user.planType);
  }

  getProrationPreview = debounce(() => {
    this.props.getProrationPreview();
  }, 500);

  selectPlanType = (type) => {
    if (this.props.user.planType === 'unlimited') {
      this.props.updateSelectedPlan('forms', 1);
      this.props.updateSelectedPlan('submissions', 50);
      this.props.updateSelectedPlan('users', 1);
      this.props.updateSelectedPlan('filesStorage', 100);
      this.props.updateSelectedPlan('domains', 0);
      this.props.updateSelectedPlan('brandingRemoval', 0);
    }

    this.props.updateSelectedPlanType(type);
  };

  handleSubmit = () => {
    const total = this.props.selectedPlanType === 'unlimited' ? 99 : Math.round(((this.props.selectedPlan.brandingRemoval ? 5 : 0) + getDomainsPrice(this.props.selectedPlan.domains) + getFormsPrice(this.props.selectedPlan.forms) + getSubmissionsPrice(this.props.selectedPlan.submissions) + getUsersPrice(this.props.selectedPlan.users) + getFilesStoragePrice(this.props.selectedPlan.filesStorage)) * 100) / 100;
    let selectedPlan = { ...this.props.selectedPlan };
    let selectedPlanType = this.props.selectedPlanType;

    if (selectedPlanType === 'unlimited' || total >= 99) {
      selectedPlan = {
        forms: 1000,
        submissions: 1000000,
        users: 1000,
        filesStorage: 10000,
        domains: 10000,
        brandingRemoval: 1
      };

      selectedPlanType = 'unlimited';

      this.props.updateSelectedPlanType('unlimited');
    }

    this.props.updateLoading(true);

    this.props.updateUser({
      plan: selectedPlan,
      planType: selectedPlanType
    }, {
      updateLocal: false
    });

    if (total === 0) {
      this.props.updateUser({
        plan: {
          forms: 1,
          users: 1,
          submissions: 50,
          filesStorage: 100,
          domains: 0,
          brandingRemoval: 0
        },
        planType: 'flex',
        hasSubscription: false,
        pro: false
      }, {
        updateApi: false
      }).then(this.props.cancelSubscription)
        .then(() => {
          this.props.history.push('/user/billing');
          this.props.updateLoading(false);
        });
    } else if (total >= 1) {
      this.props.updateUser({
        plan: selectedPlan,
        planType: selectedPlanType,
        hasSubscription: true,
        pro: true
      }, {
        updateApi: false
      }).then(this.props.updateSubscription)
        .then(() => {
          this.props.history.push('/user/billing');
          this.props.updateLoading(false);
        });
    }
  }

  render() {
    const {
      selectedPlan, selectedPlanType, user, prorate, prorateLoading, loading, billing
    } = this.props;

    if (!billing) return <></>;

    let totalNet = selectedPlanType === 'unlimited' ? 99 : (Math.round(((selectedPlan.brandingRemoval ? 5 : 0) + getDomainsPrice(selectedPlan.domains) + getFormsPrice(selectedPlan.forms) + getSubmissionsPrice(selectedPlan.submissions) + getUsersPrice(selectedPlan.users) + getFilesStoragePrice(selectedPlan.filesStorage)) * 100) / 100);

    return (
      <div className={styles.box}>
        <div className={styles.left}>
          <div className={styles.types}>
            <div className={[styles.type, styles.unlimited, selectedPlanType === 'unlimited' ? styles.active : ''].join(' ')} onClick={() => this.selectPlanType('unlimited')}>
              <div className={styles.typeBadge}>All-Inclusive</div>
              <div className={styles.typeText}>Unlimited data collection for your business.</div>
              <div className={styles.typePrice}><strong>€99</strong>/month, all-inclusive</div>
              {selectedPlanType === 'unlimited' && <CheckmarkIcon />}
            </div>
            <div className={[styles.type, styles.flex, selectedPlanType === 'flex' ? styles.active : ''].join(' ')} onClick={() => this.selectPlanType('flex')}>
              <div className={styles.typeBadge}>Pay-as-you-go</div>
              <div className={styles.typeText}>Create your own plan and only pay for what you need.</div>
              <div className={styles.typePrice}>Starting at <strong>€1</strong>/month</div>
              {selectedPlanType === 'flex' && <CheckmarkIcon />}
            </div>
          </div>

          {selectedPlanType === 'unlimited' && <PlanUnlimited onInit={() => this.props.updateProrateLoading(true).then(this.getProrationPreview)} />}
          {selectedPlanType === 'flex' && <PlanSetup
            plan={selectedPlan}
            onChange={(key, value) => this.props.updateSelectedPlan(key, value).then(() => this.props.updateProrateLoading(true)).then(this.getProrationPreview)} />}

          <div className={styles.issues}>
            {selectedPlan.forms < user.planUsage.forms && <p><WarningIcon />To reduce your total amount of forms, you must remove forms to get to your new total number before you can downgrade.</p>}
            {selectedPlan.users < user.planUsage.users && <p><WarningIcon />To reduce your total amount of users, you must remove access from {user.planUsage.users - selectedPlan.users} user to get to your new {selectedPlan.users} user limit.</p>}
            {selectedPlan.domains < user.planUsage.domains && <p><WarningIcon />To reduce your total amount of domains, you must remove domains to get to your new total number before you can downgrade.</p>}
          </div>

          <Button size="large" theme="blue" fullWidth={true} disabled={(totalNet < 1 && totalNet !== 0) || selectedPlan.domains < user.planUsage.domains || selectedPlan.users < user.planUsage.users || selectedPlan.forms < user.planUsage.forms || selectedPlanType === 'unlimited' && user.planType === 'unlimited' || user.planType === 'unlimited' && selectedPlanType === 'flex' && totalNet >= 99} onClick={this.handleSubmit} margin="20px 0 0 0">{totalNet > 0 ? 'Update Pro plan' : 'Switch to Free plan'}</Button>
          <Link to="/user/billing" className={styles.cancel}>Cancel</Link>

          {loading && <Loader size={40} background="rgba(255, 255, 255, 0.5)" />}
        </div>

        <Sticky top={50} bottomBoundary={`.${styles.box}`} className={styles.sticky}>
          <div className={styles.right}>
            <PlanSummary plan={selectedPlan}
              type={selectedPlanType}
              showTax={true}
              country={billing.address.country}
              vatEu={billing.vatEu ? billing.vatEu.value : false}
              user={user}
              showPrevious={true}
              showProrate={true}
              prorate={prorate}
              prorateLoading={prorateLoading}
              loading={loading} />
          </div>
        </Sticky>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    selectedPlan: state.plan.selectedPlan,
    selectedPlanType: state.plan.selectedPlanType,
    user: state._users.user,
    prorate: state.plan.prorate,
    prorateLoading: state.plan.prorateLoading,
    loading: state.plan.loading,
    billing: state.user.billing
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateSelectedPlan: (key, value) => dispatch(updateSelectedPlan(key, value)),
    resetSelectedPlan: () => dispatch(resetSelectedPlan()),
    getProrationPreview: () => dispatch(getProrationPreview()),
    setProrationPreviewDate: (date) => dispatch(setProrationPreviewDate(date)),
    updateProrateLoading: (value) => dispatch(updateProrateLoading(value)),
    cancelSubscription: () => dispatch(cancelSubscription()),
    updateSubscription: () => dispatch(updateSubscription()),
    updateUser: (params, options) => dispatch(updateUser(params, options)),
    updateLoading: (value) => dispatch(updateLoading(value)),
    getBilling: () => dispatch(getBilling()),
    updateSelectedPlanType: (type) => dispatch(updateSelectedPlanType(type))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(BillingUpdatePlan));
