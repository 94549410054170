/** @jsx jsx */
/* @jsxFrag React.Fragment */

import React, { useState, useRef, useEffect } from 'react';
import { jsx, css } from '@emotion/core';
import SignatureCanvas from 'react-signature-canvas';
import getFieldLabel from 'helpers/getFieldLabel.js';
import Translate from '../messages/Translate.js';

import { LabelAtom, DescriptionAtom } from './atoms.js';

const EraserIcon = ({ fill }) => <svg width="16" height="16" xmlns="http://www.w3.org/2000/svg"><path fill={fill} d="M7.333 14.438H.667a.667.667 0 1 0 0 1.333h6.666a.667.667 0 1 0 0-1.333zM13.675 4.29L9.905.518a1 1 0 0 0-1.414 0L3.318 5.693a.667.667 0 0 0 0 .943l4.24 4.243c.261.26.683.26.943 0l5.174-5.175a1 1 0 0 0 0-1.414zM1.2 12.743c.21.22.496.35.8.362h3.108c.305-.012.594-.138.81-.352L6.593 12a.333.333 0 0 0-.014-.46L2.733 7.724a.348.348 0 0 0-.472 0L.493 9.524a1.728 1.728 0 0 0-.014 2.4l.721.819zM11 14.438H9.667a.667.667 0 1 0 0 1.333H11a.667.667 0 1 0 0-1.333zM15.333 14.438H14a.667.667 0 0 0 0 1.333h1.333a.667.667 0 1 0 0-1.333z" /></svg>;
const PenIcon = ({ fill }) => <svg width="14" height="14" xmlns="http://www.w3.org/2000/svg"><path fill={fill} d="M13.192.538h-.538a.27.27 0 0 0-.232.133l-2.24 3.808a.27.27 0 0 0 .041.327l2.112 2.112a.27.27 0 0 0 .358.019l.667-.534a.27.27 0 0 0 .102-.21V.807a.27.27 0 0 0-.27-.27zM9.621 5.346a.27.27 0 0 0-.264-.068l-1.562.445a1.338 1.338 0 0 0-.924.925l-1.224 4.29a.037.037 0 0 0 .063.036l3.03-3.032a.405.405 0 1 1 .574.571l-3.032 3.033a.033.033 0 0 0-.006.041c.008.013.024.02.04.015l4.288-1.225c.45-.127.8-.48.926-.93l.42-1.585a.27.27 0 0 0-.07-.26L9.62 5.347z" /><path fill={fill} d="M5.008 11.999a1.87 1.87 0 0 1-1.239.386 1.71 1.71 0 0 1-1.423-.563c-.343-.52-.238-1.461.303-2.724C3.735 6.562 4.875 4.555 4.157 2.84 3.753 1.885 2.834 1.16 1.266.573a.538.538 0 1 0-.377 1.008c1.253.47 1.997 1.019 2.273 1.677.514 1.226-.485 3.04-1.503 5.416-.7 1.635-.772 2.893-.215 3.74a2.723 2.723 0 0 0 2.325 1.048 2.854 2.854 0 0 0 1.993-.697.534.534 0 0 0 .003-.75.543.543 0 0 0-.757-.016z" /></svg>;
const KeyboardIcon = ({ fill }) => <svg width="20" height="13" xmlns="http://www.w3.org/2000/svg"><path fill={fill} d="M17.875 0H1.625C.728 0 0 .728 0 1.625v9.75C0 12.272.728 13 1.625 13h16.25c.897 0 1.625-.728 1.625-1.625v-9.75C19.5.728 18.772 0 17.875 0zm.27 11.375c0 .15-.12.27-.27.27H1.625a.271.271 0 0 1-.27-.27v-9.75c0-.15.12-.27.27-.27h16.25c.15 0 .27.12.27.27v9.75zM5.756 6.974v-.948a.406.406 0 0 0-.406-.406h-.948a.406.406 0 0 0-.406.406v.948c0 .224.182.406.406.406h.948a.406.406 0 0 0 .406-.406zm3.25 0v-.948A.406.406 0 0 0 8.6 5.62h-.948a.406.406 0 0 0-.406.406v.948c0 .224.182.406.406.406H8.6a.406.406 0 0 0 .406-.406zm3.25 0v-.948a.406.406 0 0 0-.406-.406h-.948a.406.406 0 0 0-.406.406v.948c0 .224.182.406.406.406h.948a.406.406 0 0 0 .406-.406zm3.25 0v-.948a.406.406 0 0 0-.406-.406h-.948a.406.406 0 0 0-.406.406v.948c0 .224.182.406.406.406h.948a.406.406 0 0 0 .406-.406zM4.13 9.75v-.948a.406.406 0 0 0-.406-.406h-.948a.406.406 0 0 0-.406.406v.948c0 .224.182.406.406.406h.948a.406.406 0 0 0 .406-.406zm13 0v-.948a.406.406 0 0 0-.406-.406h-.948a.406.406 0 0 0-.406.406v.948c0 .224.182.406.406.406h.948a.406.406 0 0 0 .406-.406zm-13-5.552V3.25a.406.406 0 0 0-.406-.406h-.948a.406.406 0 0 0-.406.406v.948c0 .224.182.406.406.406h.948a.406.406 0 0 0 .406-.406zm3.25 0V3.25a.406.406 0 0 0-.406-.406h-.948a.406.406 0 0 0-.406.406v.948c0 .224.182.406.406.406h.948a.406.406 0 0 0 .406-.406zm3.25 0V3.25a.406.406 0 0 0-.406-.406h-.948a.406.406 0 0 0-.406.406v.948c0 .224.182.406.406.406h.948a.406.406 0 0 0 .406-.406zm3.25 0V3.25a.406.406 0 0 0-.406-.406h-.948a.406.406 0 0 0-.406.406v.948c0 .224.182.406.406.406h.948a.406.406 0 0 0 .406-.406zm3.25 0V3.25a.406.406 0 0 0-.406-.406h-.948a.406.406 0 0 0-.406.406v.948c0 .224.182.406.406.406h.948a.406.406 0 0 0 .406-.406zm-3.317 5.349v-.542a.406.406 0 0 0-.407-.406H6.094a.406.406 0 0 0-.407.406v.542c0 .224.182.406.407.406h7.312a.406.406 0 0 0 .406-.406z" /></svg>;

let typeCanvasCtx;

const Signature = ({ values, form, field, theme, value, onChange, onPartialResponse, fieldsOptions, variables }) => {
  const translate = new Translate(form.messages);

  const [tab, setTab] = useState('draw');

  let drawCanvas = useRef();
  let typeCanvas = useRef();
  let typeImage = useRef();

  const alignOptions = {
    left: 'flex-start',
    center: 'center',
    right: 'flex-end'
  };

  const mainStyle = css({
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: alignOptions[theme.signatureAlignment],
    margin: `${theme.signatureTopMargin || 0}px 0 ${theme.signatureBottomMargin || 0}px 0`
  });

  const contentStyle = css({
    width: '100%',
    maxWidth: `${theme.signatureWidth}${theme.signatureWidthType}`
  });

  const signatureBoxStyle = css({
    background: '#FFFFFF',
    border: `${theme.signatureIdleBorderSize}px solid ${theme.signatureIdleBorderColor}`,
    borderRadius: 3,
    width: '100%',
    position: 'relative',
    boxShadow: theme.signatureIdleShadow ? `${theme.signatureIdleShadowOffsetX}px ${theme.signatureIdleShadowOffsetY}px ${theme.signatureIdleShadowBlur}px ${theme.signatureIdleShadowSpread}px ${theme.signatureIdleShadowColor}` : 'none',
    '& canvas': {
      position: 'relative',
      zIndex: '2',
      width: '100%',
      height: '150px'
    },
    '&:hover': (() => {
      if (!theme.signatureHoverEnable) return {};
      
      const obj = {
        borderColor: theme.signatureHoverBorderColor
      };

      if (theme.signatureHoverShadow) {
        obj.boxShadow = `${theme.signatureHoverShadowOffsetX}px ${theme.signatureHoverShadowOffsetY}px ${theme.signatureHoverShadowBlur}px ${theme.signatureHoverShadowSpread}px ${theme.signatureHoverShadowColor}`;
      } else {
        obj.boxShadow = 'none';
      }

      return obj;
    })()
  });

  const signatureHeaderStyle = css({
    background: theme.signatureBackground,
    width: '100%',
    height: '49px',
    borderBottom: '1px solid #E7E7E7',
    margin: 0,
    padding: 0,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    listStyle: 'none',
    userSelect: 'none',
    borderRadius: '3px 3px 0 0',
    '& li': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
      padding: '10px 20px'
    },
    '& li svg': {
      margin: '0 0 5px 0'
    },
    '& li span': {
      color: '#202020',
      fontSize: '10px',
      fontWeight: '500',
      lineHeight: '142%',
      fontFamily: theme.typographySupportingFontFamily
    }
  });

  const signatureFooterStyle = css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center;',
    height: '60px',
    width: '100%',
    padding: '0 20px',
    boxSizing: 'border-box',
    '& div': {
      color: '#000000',
      fontSize: '14px',
      fontWeight: '400',
      lineHeight: '17px',
      lineHeight: '142%',
      fontFamily: theme.typographySupportingFontFamily,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
      userSelect: 'none'
    },
    '& div svg': {
      margin: '0 5px 0 0'
    },
    '& input': {
      fontFamily: theme.typographyContentFontFamily,
      width: '50%',
      border: '1px solid #BFBFBF',
      borderRadius: '3px',
      color: '#949494',
      fontSize: '14px',
      fontWeight: '400',
      lineHeight: '36px',
      boxSizing: 'border-box',
      padding: '0 15px'
    }
  });

  const handleTypeInputKeyUp = (e) => {
    const textWidth = typeCanvasCtx.measureText(e.target.value).width;

    typeCanvasCtx.clearRect(0, 0, 345, 150);
    typeCanvasCtx.fillText(e.target.value, (typeCanvas.current.width / 2) - (textWidth / 2), 105);
    typeImage.current.src = typeCanvasCtx.canvas.toDataURL();

    onChange({ [field._id]: e.target.value ? typeImage.current.src : null });
  };

  const saveDrawCanvas = () => {
    onChange({ [field._id]: drawCanvas.current.toDataURL() });
    onPartialResponse(field._id);
  };

  const initTypeCanvas = () => {
    setTimeout(() => {
      typeCanvasCtx = typeCanvas.current.getContext('2d');
      typeCanvasCtx.font = '20px Calligraffitti';
    });
  };

  const updateTab = (tab) => {
    setTab(tab);

    onChange({ [field._id]: null });

    if (tab === 'type') initTypeCanvas();
  };

  useEffect(() => {
    if (value && drawCanvas && drawCanvas.current) drawCanvas.current.fromDataURL(value);
  }, []);

  if (field.hidden) return <></>;

  return <>
    <div css={mainStyle}>
      <div css={contentStyle}>
        <LabelAtom required={field.required} error={field.error} theme={theme}>{getFieldLabel(field.label, values, 'jsx', fieldsOptions, variables)}</LabelAtom>

        <div css={signatureBoxStyle}>
          <ul css={signatureHeaderStyle}>
            <li onClick={() => updateTab('draw')}>
              <PenIcon fill={tab === 'draw' ? theme.signatureActiveOptionColor : theme.signatureIdleOptionColor} /><span style={{ color: tab === 'draw' ? theme.signatureActiveOptionColor : theme.signatureIdleOptionColor }}>
                {translate.text('signatureDraw')}
              </span>
            </li>
            {field.signatureAllowTyping && <li onClick={() => updateTab('type')}>
              <KeyboardIcon fill={tab === 'type' ? theme.signatureActiveOptionColor : theme.signatureIdleOptionColor} /><span style={{ color: tab === 'type' ? theme.signatureActiveOptionColor : theme.signatureIdleOptionColor }}>
                {translate.text('signatureType')}
              </span>
            </li>}
          </ul>
          
          {tab === 'draw' && <SignatureCanvas clearOnResize={false} onEnd={() => saveDrawCanvas()} ref={drawCanvas} />}
          {tab === 'type' && <>
            <canvas ref={typeCanvas} />
            <img ref={typeImage} style={{ display: 'none' }}></img>
          </>}

          <div css={signatureFooterStyle}>
            {tab === 'draw' && <div onClick={() => drawCanvas.current.clear()}>
              <EraserIcon />{translate.text('signatureClear')}
            </div>}
            {tab === 'type' && <input type="text" autoFocus={true} onBlur={() => onPartialResponse(field._id)} placeholder={translate.text('placeholderInput')} onKeyUp={handleTypeInputKeyUp} />}
          </div>
        </div>

        <DescriptionAtom theme={theme}>{getFieldLabel(field.description, values, 'jsx', fieldsOptions, variables)}</DescriptionAtom>
      </div>
    </div>
  </>;
};

export default Signature;
