import styles from './FieldsList.module.css';

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { v4 as uuid } from 'uuid';
import ObjectID from 'bson-objectid';
import _maxBy from 'lodash/maxBy';
import _minBy from 'lodash/minBy';
import OutsideClickHandler from 'react-outside-click-handler';

import {
  updateSelectedFieldOption, updateFirstHoverOnFieldOption, updateActiveButtonHover,
  updateInputBoxAnimation, updateEmptyBoxAnimation, updateFields, updateSelectedEmptyBox,
  ioAddField, updateEditedFieldRef
} from 'store/ducks/builder.js';

import { ReactComponent as BulbIcon } from 'assets/images/bulb.svg';

const fieldIcons = {
  checkbox: require('assets/images/fields-v2/checkbox.svg'),
  divider: require('assets/images/fields-v2/divider.svg'),
  dropdown: require('assets/images/fields-v2/dropdown.svg'),
  longText: require('assets/images/fields-v2/longText.svg'),
  radio: require('assets/images/fields-v2/radio.svg'),
  shortText: require('assets/images/fields-v2/shortText.svg'),
  shortTextemail: require('assets/images/fields-v2/email.svg'),
  shortTextnumber: require('assets/images/fields-v2/number.svg'),
  shortTextphone: require('assets/images/fields-v2/phone.svg'),
  title: require('assets/images/fields-v2/title.svg'),
  description: require('assets/images/fields-v2/description.svg'),
  datetime: require('assets/images/fields-v2/calendar.svg'),
  fileUpload: require('assets/images/fields-v2/upload.svg'),
  signature: require('assets/images/fields-v2/signature.svg'),
  pageBreak: require('assets/images/fields-v2/page.svg'),
  image: require('assets/images/fields-v2/image.svg'),
  scale: require('assets/images/fields-v2/scale.svg'),
  imageChoice: require('assets/images/fields-v2/imageChoice.svg'),
  section: require('assets/images/fields-v2/section.svg'),
};

const previews = {
  conversational: {
    checkbox: require('assets/images/fields-previews/conversational/checkbox.png'),
    dropdown: require('assets/images/fields-previews/conversational/dropdown.png'),
    longText: require('assets/images/fields-previews/conversational/longText.png'),
    radio: require('assets/images/fields-previews/conversational/radio.png'),
    shortText: require('assets/images/fields-previews/conversational/shortText.png'),
    shortTextemail: require('assets/images/fields-previews/conversational/shortTextemail.png'),
    shortTextnumber: require('assets/images/fields-previews/conversational/shortTextnumber.png'),
    shortTextphone: require('assets/images/fields-previews/conversational/shortTextphone.png'),
    title: require('assets/images/fields-previews/conversational/title.png'),
    description: require('assets/images/fields-previews/conversational/description.png'),
    datetime: require('assets/images/fields-previews/conversational/datetime.png'),
    fileUpload: require('assets/images/fields-previews/conversational/fileUpload.png'),
    signature: require('assets/images/fields-previews/conversational/signature.png'),
    image: require('assets/images/fields-previews/conversational/image.png'),
    scale: require('assets/images/fields-previews/conversational/scale.png'),
    imageChoice: require('assets/images/fields-previews/conversational/imageChoice.png'),
    section: require('assets/images/fields-previews/conversational/section.png')
  },
  classic: {
    checkbox: require('assets/images/fields-previews/classic/checkbox.png'),
    divider: require('assets/images/fields-previews/classic/divider.png'),
    dropdown: require('assets/images/fields-previews/classic/dropdown.png'),
    longText: require('assets/images/fields-previews/classic/longText.png'),
    radio: require('assets/images/fields-previews/classic/radio.png'),
    shortText: require('assets/images/fields-previews/classic/shortText.png'),
    shortTextemail: require('assets/images/fields-previews/classic/shortTextemail.png'),
    shortTextnumber: require('assets/images/fields-previews/classic/shortTextnumber.png'),
    shortTextphone: require('assets/images/fields-previews/classic/shortTextphone.png'),
    title: require('assets/images/fields-previews/classic/title.png'),
    description: require('assets/images/fields-previews/classic/description.png'),
    datetime: require('assets/images/fields-previews/classic/datetime.png'),
    fileUpload: require('assets/images/fields-previews/classic/fileUpload.png'),
    signature: require('assets/images/fields-previews/classic/signature.png'),
    pageBreak: require('assets/images/fields-previews/classic/pageBreak.png'),
    image: require('assets/images/fields-previews/classic/image.png'),
    scale: require('assets/images/fields-previews/classic/scale.png'),
    imageChoice: require('assets/images/fields-previews/classic/imageChoice.png'),
    section: require('assets/images/fields-previews/classic/section.png')
  }
};

const structuralFields = [
  'image',
  'divider',
  'pageBreak',
  'title',
  'description',
  'section'
];

class FieldsList extends Component {
  constructor(props) {
    super(props);

    this.fieldOptionsTimeout = null;
  }

  componentWillUnmount = () => {
    clearTimeout(this.fieldOptionsTimeout);
  }

  addFieldToForm = (option) => {
    let fields = this.props.fields;
    let rootFields = fields.filter((f) => f.section === 'root');
    let position = 1;

    if (rootFields.length > 0 && this.props.selectedEmptyBox === -1024) {
      position = _minBy(rootFields, (o) => o.position).position - 1;
    } else if (rootFields.length > 0 && rootFields.filter((f) => f.section === 'root')[this.props.selectedEmptyBox]) {
      position = rootFields[this.props.selectedEmptyBox].position;
    } else if (rootFields.length > 0) {
      position = _maxBy(rootFields, (o) => o.position).position + 1;
    } 

    const field = {
      _id: ObjectID(),
      ref: uuid(),
      type: option.type,
      label: option.label,
      value: option.value || null,
      conditionsType: 'all',
      conditionsState: 'show',
      childrens: [],
      conditions: [],
      section: 'root',
      valueMaxLength: option.valueMaxLength,
      options: typeof option.options !== 'undefined' ? option.options.map((item) => {
        return { value: item.value, ref: uuid() };
      }) : [],
      position,
      ...option.custom
    };

    clearTimeout(this.emptyBoxAnimationTimeout);

    fields.push(field);

    // update all other fields position
    fields.filter((field) => field.section === 'root').map((f) => {
      if (f.position >= field.position && field.ref !== f.ref) {
        f.position += 1;
      }

      return f;
    });

    this.props.updateEmptyBoxAnimation(false);
    this.props.updateInputBoxAnimation(false);

    this.emptyBoxAnimationTimeout = setTimeout(() => {
      this.props.updateEmptyBoxAnimation(true);
      this.props.updateInputBoxAnimation(true);
    }, 400);

    this.props.updateSelectedEmptyBox(null).then(() => {
      if (['divider', 'pageBreak'].indexOf(field.type) !== -1) {
        return Promise.resolve();
      } else {
        return this.props.updateEditedFieldRef(field.ref);
      }
    }).then(() => {
      this.props.updateFields(fields);
      this.props.ioAddField(field, { updatePosition: true });
    });
  }

  onMouseEnterFieldOptions = (option) => {
    clearTimeout(this.fieldOptionsTimeout);
    this.fieldOptionsTimeout = null;

    this.fieldOptionsTimeout = setTimeout(() => {
      this.props.updateSelectedFieldOption(option);
      this.props.updateFirstHoverOnFieldOption(false);
    }, this.props.firstHoverOnFieldOption ? 1 : 200);
  }

  onMouseLeaveFieldOptions = () => {
    clearTimeout(this.fieldOptionsTimeout);
    this.fieldOptionsTimeout = null;
  }

  onMouseLeaveFieldsList = () => {
    this.props.updateFirstHoverOnFieldOption(true);
  }

  handleOutsideClick = (e) => {
    if (e.target.className === 'emptyBoxButtonSpan') return;

    if (this.props.selectedEmptyBox !== null) {
      this.props.updateSelectedEmptyBox(null);
      this.props.updateSelectedFieldOption(null);
      this.props.updateFirstHoverOnFieldOption(true);
      this.props.updateActiveButtonHover(this.props.fields.length + 1);
    }
  }

  render() {
    const {
      selectedEmptyBox, fieldsListPosition, fieldOptions, form, selectedFieldOption
    } = this.props;

    const fieldsList = (type) => {
      let options;

      if (type === 'question') {
        options = fieldOptions.filter((option) => !option.hidden && structuralFields.indexOf(option.type) === -1);
      } else if (type === 'structural') {
        options = fieldOptions.filter((option) => !option.hidden && structuralFields.indexOf(option.type) !== -1);
      }

      if (form.type === 'conversational') {
        options = options.filter((field) => ['pageBreak', 'divider'].indexOf(field.type) === -1);
      }

      return <ul>
        {options.map((option) => {
          const isSelected = !selectedFieldOption ? `${option.type}${option.custom.format || ''}` === 'shortText' : `${selectedFieldOption.type}${selectedFieldOption.custom.format}` === `${option.type}${option.custom.format}`;

          return <li key={`${option.type}${option.custom.format ? option.custom.format : ''}`}
            className={isSelected ? styles.selected : ''}
            onClick={() => this.addFieldToForm(option)}
            onMouseEnter={() => this.onMouseEnterFieldOptions(option)}
            onMouseLeave={() => this.onMouseLeaveFieldOptions()}>
            <span>
              <img width="15px" src={fieldIcons[`${option.type}${option.custom.format ? option.custom.format : ''}`]} alt="" />
            </span>
            {option.label}
          </li>
        })}
      </ul>;
    };

    const preview = (field, selectedField) => {
      let isSelected = selectedField ? `${selectedField.type}${selectedField.custom.format}` === `${field.type}${field.custom.format}` : `${field.type}${field.custom.format || ''}` === 'shortText';

      return <div key={`${field.type}${field.custom.format}`} className={[
        styles.preview,
        isSelected ? styles.selected : ''
      ].join(' ')}>
        <div className={styles.previewImage}>
          <img height="100px" src={previews[form.type][`${field.type}${field.custom.format || ''}`]} alt={`${field.type} ${field.custom.format || ''}`} />
        </div>
        <div className={styles.previewTitle}>{field.title}</div>
        <div className={styles.previewDesc}>{field.desc}</div>
        {field.tip && <div className={styles.previewTip}>
          <BulbIcon /><span>{field.tip}</span>
        </div>}
      </div>;
    };

    return (
      <OutsideClickHandler onOutsideClick={(e) => this.handleOutsideClick(e)}>
        <div className={[
          styles.main,
          selectedEmptyBox !== null ? styles.selectonActive : ''
        ].join(' ')}
          onMouseLeave={() => this.onMouseLeaveFieldsList()}
          style={{
            top: fieldsListPosition.top,
            left: fieldsListPosition.left
          }}>

          <div className={styles.left}>
            {fieldOptions.map((field) => preview(field, selectedFieldOption))}
          </div>

          <div className={styles.right}>
            <div className={styles.fieldsListTitle}>Fields Library</div>

            <div className={styles.list}>
              <div className={styles.scrollArea}>
                <div className={styles.title}>Question</div>
                {fieldsList('question')}
                <hr />
                <div className={styles.title}>Structural</div>
                {fieldsList('structural')}
              </div>
            </div>
          </div>
        </div>
      </OutsideClickHandler>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    form: state.builder.form,
    fields: state.builder.fields,
    selectedEmptyBox: state.builder.selectedEmptyBox,
    fieldsListPosition: state.builder.fieldsListPosition,
    fieldOptions: state.builder.fieldOptions,
    firstHoverOnFieldOption: state.builder.firstHoverOnFieldOption,
    selectedFieldOption: state.builder.selectedFieldOption,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateFields: (value) => dispatch(updateFields(value)),
    updateEmptyBoxAnimation: (value) => dispatch(updateEmptyBoxAnimation(value)),
    updateInputBoxAnimation: (value) => dispatch(updateInputBoxAnimation(value)),
    updateSelectedFieldOption: (value) => dispatch(updateSelectedFieldOption(value)),
    updateFirstHoverOnFieldOption: (value) => dispatch(updateFirstHoverOnFieldOption(value)),
    updateActiveButtonHover: (value) => dispatch(updateActiveButtonHover(value)),
    updateSelectedEmptyBox: (value) => dispatch(updateSelectedEmptyBox(value)),
    ioAddField: (props, options) => dispatch(ioAddField(props, options)),
    updateEditedFieldRef: (value) => dispatch(updateEditedFieldRef(value))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FieldsList);
