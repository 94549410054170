import styles from './BillingProfile.module.css';

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getData } from 'country-list';
import { vatEuCountryCodes } from 'misc/helpers.js';

import {
  updateBilling
} from 'store/ducks/user.js';

import { Card, FormRow, TextInput, Select, Loader } from 'ui';

const countries = getData().map((obj) => {
  return {
    label: obj.name.replace('Taiwan, Province of China', 'Taiwan'),
    value: obj.code
  };
});

class BillingProfile extends Component {
  render() {
    const {
      billing, loading
    } = this.props;

    return (
      <Card className={styles.main}>
        <div className={styles.head}>
          <div className={styles.title}>Your billing profile</div>
        </div>

        <div className={styles.form}>
          {loading && <Loader size={40} />}

          {!loading && billing && <>
            <FormRow label="Your full name or company name" required={true} className={styles.row}>
              <TextInput value={billing.name} width={'100%'} onChange={(e) => this.props.updateBilling({ name: e.target.value })} />
            </FormRow>

            <FormRow label="Billing email" required={true} className={styles.row}>
              <TextInput type="email" value={billing.email} width={'100%'} onChange={(e) => this.props.updateBilling({ email: e.target.value })} />
            </FormRow>

            <FormRow label="Country of billing address" required={true} className={styles.row}>
              <Select width="100%" options={countries} disabled={true} filter={true} value={
                { label: (countries.find((country) => country.value === (billing.address ? billing.address.country : null)) || { label: '- Select -' }).label, value: billing.address ? billing.address.country : null }
              } onChange={() => {}} />
            </FormRow>       

            {vatEuCountryCodes.indexOf(billing.address ? billing.address.country : null) !== -1 && <FormRow label="Vat EU" required={false} className={styles.row}>
              <TextInput value={billing.vatEu ? billing.vatEu.value : ''} width={'100%'} onChange={() => {}} disabled={true} />
            </FormRow>}

            <FormRow label="Address Line 1" required={false} className={styles.row}>
              <TextInput value={billing.address ? billing.address.line1 : ''} width={'100%'} onChange={(e) => this.props.updateBilling({ address: { line1: e.target.value }})} />
            </FormRow>

            <FormRow label="Address Line 2" required={false} className={styles.row}>
              <TextInput value={billing.address ? billing.address.line2 : ''} width={'100%'} onChange={(e) => this.props.updateBilling({ address: { line2: e.target.value }})} />
            </FormRow>

            <FormRow label="Postal Code" required={false} className={styles.row}>
              <TextInput value={billing.address ? billing.address.postal_code : ''} width={'100%'} onChange={(e) => this.props.updateBilling({ address: { postal_code: e.target.value }})} />
            </FormRow>

            <FormRow label="City" required={false} className={styles.row}>
              <TextInput value={billing.address ? billing.address.city : ''} width={'100%'} onChange={(e) => this.props.updateBilling({ address: { city: e.target.value }})} />
            </FormRow>
          </>}
        </div>
      </Card>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    user: state._users.user,
    billing: state.user.billing,
    loading: state.user.billingLoading
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateBilling: (obj) => dispatch(updateBilling(obj)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BillingProfile);
