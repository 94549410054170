import styles from './../Edit.module.css';
import editStyles from 'components/Builder/Edit.module.css';

import React, { Component } from 'react';
import ReactTooltip from 'react-tooltip';
import { connect } from 'react-redux';

import { MentionsInput, TextInput, TextArea, Toggle, Slider } from 'ui';

import { ReactComponent as ReferToIcon } from 'assets/images/refer-to.svg';

const rangeValues = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

class Scale extends Component {
  render() {
    const {
      field, fields, handleChange, mentionsData
    } = this.props;

    return (
      <>
        <div className={editStyles.row}>
          <label>
            <div>Field Title</div>
            <a href="https://support.questionscout.com/en/articles/3947231-how-to-use-refer-to" target="_blank" rel="noopener noreferrer"><ReferToIcon data-tip="Refer to is available on this input, click to learn more." /></a>
          </label>
          <MentionsInput menu="tooltip"
            initialValue={field.label}
            onChange={(value) => handleChange({ label: value })}
            data={mentionsData}
          />
        </div>

        <div className={editStyles.row}>
          <label>
            <div>Supporting Text</div>
            <a href="https://support.questionscout.com/en/articles/3947231-how-to-use-refer-to" target="_blank" rel="noopener noreferrer"><ReferToIcon data-tip="Refer to is available on this input, click to learn more." /></a>
          </label>
          <MentionsInput menu="tooltip"
            initialValue={field.description}
            onChange={(value) => handleChange({ description: value })}
            data={mentionsData}
          />
        </div>

        <div className={[editStyles.row].join(' ')}>
          <div className={editStyles.toggle50} onClick={() => handleChange({ required: !field.required }, false)}>
            <Toggle checked={field.required} readOnly={true} /> <span>Required</span>
          </div>
          <div className={editStyles.toggle50} onClick={() => handleChange({ hidden: !field.hidden }, false)}>
            <Toggle checked={field.hidden} readOnly={true} /> <span>Hidden</span>
          </div>
          <div style={{ clear: 'both' }}></div>
        </div>

        <div className={editStyles.hr}></div>

        <div className={editStyles.row}>
          <label>Range</label>

          <div className={editStyles.slider}>
            <Slider value={[rangeValues[field.scaleRange[0]], rangeValues[field.scaleRange[1]]]} values={rangeValues} onChange={(selected) => handleChange({ scaleRange: [rangeValues[selected[0]], rangeValues[selected[1]]] })} />
            <div className={editStyles.sliderValue} style={{ width: 55, marginLeft: 10, textAlign: 'right' }}>{field.scaleRange[0]} - {rangeValues[field.scaleRange[1]]}</div>
          </div>
        </div>

        <div className={editStyles.row}>
          <label>Left Label</label>
          <TextInput value={field.scaleTextLeft} onChange={(e) => handleChange({ scaleTextLeft: e.target.value })} />
        </div>

        <div className={editStyles.row}>
          <label>Center Label</label>
          <TextInput value={field.scaleTextCenter} onChange={(e) => handleChange({ scaleTextCenter: e.target.value })} />
        </div>

        <div className={editStyles.row}>
          <label>Right Label</label>
          <TextInput value={field.scaleTextRight} onChange={(e) => handleChange({ scaleTextRight: e.target.value })} />
        </div>

        <ReactTooltip place="right" effect="solid" />
      </>
    );
  };
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Scale);
