import styles from './FromScratch.module.css';

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Header from '../Header/Header.js';

import { Button, TextInput, Select, Loader, FormRow } from 'ui';

import traditionalFormPreviewIcon from 'assets/images/traditional-form-preview.png';
import conversationalFormPreviewIcon from 'assets/images/conversational-form-preview.png';

import { ReactComponent as InfoIcon } from 'assets/images/info.svg';
import traditionalIcon from 'assets/images/traditional.svg';
import conversationalIcon from 'assets/images/conversational.svg';

import {
  getForms, createForm, resetEndpoitState
} from 'store/ducks/_forms.js';

import {
  getWorkspaces
} from 'store/ducks/_workspaces.js';

const FromScratch = ({ history, match }) => {
  const dispatch = useDispatch();

  const user = useSelector(state => state._users.user);
  const loading = useSelector(state => state._forms.createForm.loading);
  const loadingForms = useSelector(state => state._forms.getForms.loading);
  const loadingWorkspaces = useSelector(state => state._workspaces.getWorkspaces.loading);

  const workspaces = [{
    label: 'Uncategorized', value: null
  }, ...useSelector(state => state._workspaces.workspaces).filter((item) => {
    const permissions = item.users.find((u) => u.user === user._id);

    if (user.role !== 'user') return true;

    return permissions && permissions.create;
  }).map((item) => {
    return {
      label: item.name,
      value: item._id
    };
  })];

  const [isReady, setIsReady] = useState(false);
  const [step, setStep] = useState(1);
  const [formType, setFormType] = useState(null);
  const [formName, setFormName] = useState('');
  const [formWorkspace, setFormWorkspace] = useState(match.params.workspace || null);

  useEffect(() => {
    dispatch(getWorkspaces());
    dispatch(getForms());

    setIsReady(true);

    return () => dispatch(resetEndpoitState('createForm'));
  }, []);

  const goToDetails = (type) => {
    setFormType(type);
    setStep(2);
  };

  const handleEnterKeyPress = (e) => {
    if (loading || e.key !== 'Enter') return;

    submit();
  };

  const submit = () => {
    if (loading) return;
  
    dispatch(createForm({
      name: formName,
      type: formType,
      workspace: formWorkspace
    })).then((res) => {
      if (res.payload && res.payload._id) {
        history.push(`/builder/${res.payload._id}/build`);
      }
    });
  };

  const isLoading = !isReady || loadingForms || loadingWorkspaces || loading;

  return (
    <div className={styles.main}>
      <Header showAccount={true} showMessages={true} showMenu={true} />

      {isLoading && <div className={styles.content}>
        <Loader size={50} />
      </div>}

      {!isLoading && <div className={styles.content}>
        {step === 1 && <>
          <div className={styles.title}>What kind of form or survey would you like to create?</div>
          <div className={styles.desc}>This is the way your form will be displayed. If you’re not sure, don’t worry. You can change the form formType at any time.</div>

          <div className={styles.options}>
            <div className={styles.option}>
              <img src={traditionalFormPreviewIcon} width="200px" alt="" />
              <div className={styles.optionTitle}>Traditional Form</div>
              <div className={styles.optionDesc}>Gather data using a more traditional interface, displaying multiple questions at a time.</div>
              <Button theme="blue" width="100%" margin="15px 0 0 0" onClick={() => goToDetails('classic')}>Create a Traditional form</Button>
              <div className={styles.optionHint}><InfoIcon />Best used in medium to large-sized forms</div>
            </div>
            <div className={styles.option}>
              <img src={conversationalFormPreviewIcon} width="200px" alt="" />
              <div className={styles.optionTitle}>Conversational Form<span>BETA</span></div>
              <div className={styles.optionDesc}>Collect responses using a conversational interface, displaying a single question at a time.</div>
              <Button theme="blue" width="100%" margin="15px 0 0 0" onClick={() => goToDetails('conversational')}>Create a Conversational form</Button>
              <div className={styles.optionHint}><InfoIcon />Best used in small to medium-sized forms</div>
            </div>
          </div>
        </>}

        {step === 2 && <>
          <div className={styles.title}>Finishing up with the details.</div>
          <div className={styles.desc}>Make your form at home by giving it a name and assigning it to a Workspace.</div>

          <div className={styles.form}>
            <FormRow label="Form Name">
              <TextInput value={formName} autoFocus={true} width="100%" onKeyPress={handleEnterKeyPress} onChange={(e) => setFormName(e.target.value)} />
            </FormRow>

            <FormRow label="Form Workspace">
              <Select width="100%" maxHeight={200} options={workspaces} margin="0 0 10px 0" value={
                { label: workspaces.find((option) => option.value === formWorkspace).label, value: formWorkspace }
              } onChange={(selected) => setFormWorkspace(selected.value)} />
            </FormRow>

            <FormRow label="Form Type">
              <div className={styles.typeSelect}>
                <div onClick={() => setFormType('classic')} className={formType === 'classic' ? styles.active : ''}><img src={traditionalIcon} height="20px" alt="" />Traditional Survey</div>
                <div onClick={() => setFormType('conversational')} className={formType === 'conversational' ? styles.active : ''}><img src={conversationalIcon} height="20px" alt="" />Conversational Survey</div>
              </div>
            </FormRow>

            <Button theme="blue" width="100%" margin="30px 0 0 0" onClick={submit} disabled={loading}>Create Form</Button>
          </div>
        </>}
      </div>}
    </div>
  );
};

export default FromScratch;
