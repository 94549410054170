/** @jsx jsx */
/* @jsxFrag React.Fragment */

import { jsx, css } from '@emotion/core';
import isJsonString from 'helpers/isJsonString.js';
import getFieldLabel from 'helpers/getFieldLabel.js';
import React from 'react';

const Description = ({ form, field, values, theme, fieldsOptions, variables }) => {
  const alignOptions = {
    left: 'flex-start',
    center: 'center',
    right: 'flex-end'
  };

  const mainStyle = css({
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: alignOptions[theme.descriptionsAlignment],
    margin: `${theme.descriptionsTopMargin || 0}px 0 ${theme.descriptionsBottomMargin || 0}px 0`,
    lineHeight: '142%'
  });

  const textStyle = css({
    width: `${theme.descriptionsWidth}${theme.descriptionsWidthType}`,
    fontFamily: theme.typographyContentFontFamily,
    color: theme.fieldsFontColor,
    textAlign: theme.descriptionsFontAlignment,
    fontSize: theme.descriptionsFontSize,
    color: theme.descriptionsFontColor,
    fontFamily: theme.typographySupportingFontFamily,
    wordBreak: 'break-word',
    lineHeight: '142%',
    '& a': {
      color: theme.descriptionsLinkColor,
      textDecoration: 'underline'
    },
  });

  return <>
    <div css={mainStyle}>
      {isJsonString(field.value)
        ? <div css={textStyle}>{getFieldLabel(field.value, values, 'jsx', fieldsOptions, variables)}</div>
        : <div css={textStyle} dangerouslySetInnerHTML={{ __html: field.value }} />}
    </div>
  </>;
};

export default Description;
