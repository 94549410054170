import styles from './Otp.module.css';

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactCodeInput from 'react-verification-code-input';

import { ReactComponent as LogoIcon } from 'assets/images/logo-icon-bw.svg';

import {
  getUser, validateOtp
} from 'store/ducks/_users.js';

import { Button, TextInput, PasswordInput, Loader, FormRow } from 'ui';

const Otp = () => {
  const dispatch = useDispatch();

  const tokenType = useSelector(state => state._users.tokenType);

  const [code, setCode] = useState('');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    document.title = '2FA - QuestionScout';

    if (tokenType !== 'otp') window.location.replace('/login');
  }, []);

  const submit = async (value) => {
    setLoading(true);

    try {
      const res = await dispatch(validateOtp(value));

      if (res.success) dispatch(getUser());
    } catch (e) {
      if (e.message) setError(e.message);

      if (e.redirect) {
        if (e.redirect === 'login') window.location.replace('/login');
      } else {
        setLoading(false);
      }
    }
  };

  return (
    <div className={styles.main}>
      <header className={styles.header}>
        <a href="https://questionscout.com" className={styles.logo}>
          <LogoIcon />
        </a>
      </header>

      <div className={styles.content}>
        <div className={styles.text}>
          <div className={styles.title}>2-Step Verification</div>
          <div className={styles.subtitle}>Enter the code generated by your 2fa authenticator app.</div>
        </div>

        <div className={styles.form}>
          {loading && <Loader size={40} />}

          <ReactCodeInput type="number" onChange={(value) => setCode(value)} onComplete={(c) => submit(c)} fields={6} placeholder={['0', '0', '0', '0', '0', '0']} className={styles.digits} fieldWidth={25} fieldHeight={40} />
          {error && <div className={styles.error}>{error}</div>}

          <Button fullWidth={true} theme="blue" onClick={() => submit(code)} className={styles.submit}>Verify</Button>
        </div>
      </div>

      <div className={styles.rights}>
        <p>2020 QuestionScout</p>
        <p>All rights reserved</p>
      </div>
    </div>
  );
};

export default Otp;
