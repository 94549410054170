import styles from './CreateTheme.module.css';

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  hideCreateThemeModal
} from 'store/ducks/theme.js';

import {
  ioUpdateForm, updateForm
} from 'store/ducks/builder.js';

import {
  createTheme
} from 'store/ducks/_themes.js';

import { Button, Modal, FormRow, TextInput, Loader, Select, Toggle2 } from 'ui';

const CreateTheme = () => {
  const dispatch = useDispatch();

  const base = useSelector(state => state.theme.createThemeModal.baseTheme);
  const formId = useSelector(state => state.theme.createThemeModal.formId);
  const show = useSelector(state => state.theme.createThemeModal._show);
  const loading = useSelector(state => state._themes.createTheme.loading);
  const error = useSelector(state => state._themes.createTheme.error);
  const themes = useSelector(state => state._themes.themes);

  const themesOptions = [...themes.custom.map((item) => {
    return {
      label: item.name,
      value: item._id,
      type: 'custom'
    };
  }), ...themes.stock.map((item) => {
    return {
      label: item.name,
      value: item._id,
      type: 'stock'
    };
  })];

  const [themeName, setThemeName] = useState('');
  const [baseTheme, setBaseTheme] = useState(null);
  const [baseThemeType, setBaseThemeType] = useState(null);
  const [applyToForm, setApplyToForm] = useState(false);

  useEffect(() => {
    let selectedTheme;

    if (base) {
      selectedTheme = themesOptions.find((option) => option.value === base);

      setBaseTheme(base);

      if (selectedTheme) {
        setThemeName(`${selectedTheme.label} copy`);
        setBaseThemeType(selectedTheme.type);
      }
    }
  }, [base, themes]);

  const cancel = () => {
    setThemeName('');
    setBaseTheme(null);
    setBaseThemeType(null);
    setApplyToForm(false);

    dispatch(hideCreateThemeModal());
  }

  const redirectToTheme = (redirectThemeId) => {
    window.location.replace(`/themes/custom/${redirectThemeId}`);
  };

  const handleEnterKeyPress = (e) => {
    if (loading || e.key !== 'Enter') return;

    submit();
  }

  const submit = () => {
    dispatch(createTheme({
      name: themeName,
      baseTheme, baseThemeType
    })).then((res) => {
      if (!res.payload || !res.payload._id) return;

      if (formId && applyToForm) {
        dispatch(updateForm({
          themeType: 'custom',
          themeId: res.payload._id
        })).then(() => dispatch(ioUpdateForm({
          themeType: 'custom',
          themeId: res.payload._id
        }))).then(() => {
          redirectToTheme(res.payload._id);
        });
      } else {
        redirectToTheme(res.payload._id);
      }
    });
  }

  if (error) {
    cancel();
  }

  return (
    <>
      <Modal isOpen={show} onRequestClose={cancel} width={350} header={baseTheme ? `Customizing the ${(themesOptions.find((option) => option.value === baseTheme) || { label: '' }).label} Theme` : 'Creating a New Theme'} footer={<>
        <Button size="small" theme="white" onClick={cancel} margin="0 15px 0 0">Cancel</Button>
        <Button size="small" theme="blue" onClick={submit} disabled={loading}>Create Theme</Button>
      </>}>
        <div className={styles.content}>
          {loading && <Loader size={30} />}

          {!loading && <>
            {baseTheme !== null && <p>You’re about to create a custom theme based on the {(themesOptions.find((option) => option.value === baseTheme) || { label: '' }).label} stock theme. Please give your custom theme a name.</p>}

            <FormRow label="Theme Name">
              <TextInput value={themeName} autoFocus={true} width={'100%'} onKeyPress={handleEnterKeyPress} onChange={(e) => setThemeName(e.target.value)} />
            </FormRow>

            {baseTheme && <FormRow label="Base Theme" style={{ margin: 0 }}>
              {themesOptions.length > 0 && <Select width={350} disablePositionDetect={true} maxHeight={200} options={themesOptions} margin="0 0 10px 0" value={
                { label: (themesOptions.find((option) => option.value === baseTheme) || { label: '' }).label, value: baseTheme }
              } onChange={(selected) => {
                setBaseTheme(selected.value);
                setBaseThemeType(selected.type);
              }} />}
            </FormRow>}

            {formId && <Toggle2 value={applyToForm} onClick={() => setApplyToForm(!applyToForm)}>Enable on this form</Toggle2>}
          </>}
        </div>
      </Modal>
    </>
  );
};

export default CreateTheme;
