import styles from './Integrations.module.css';

import React from 'react';

import { TextInput, Toggle, Card, CardRow, CardRowLabel, CardRowContent } from 'ui';

import logoIcon from 'assets/images/integrations/facebookPixel-small.png';

const FacebookPixel = ({ integration, onChange }) => {
  return <>
    <div className={styles.head}>
      <img src={logoIcon} width="32px" alt="logo" className={styles.logo} />
      <div className={styles.label}>Facebook Pixel</div>
      <div className={styles.desc}>Add your Facebook pixel ID and get all the data you need to measure and optimize your marketing campaigns.</div>

      <div className={styles.statusLabel}>Integration Status</div>
      <div className={styles.statusValue} onClick={() => onChange('enabled', !integration.enabled, false)}>
        <Toggle checked={integration.enabled} readOnly={true} onChange={() => { }} />
        <div className={styles.statusValueText}>This integration is currently <span>{integration.enabled ? 'enabled' : 'disabled'}</span></div>
      </div>
    </div>

    <Card className={styles.card}>
      <CardRow>
        <CardRowLabel title="Facebook Pixel ID" subTitle="Insert the Facebook Pixel ID" />

        <CardRowContent>
          <TextInput value={integration.pixelId} placeholder="XXXXXXXXXXXXXX" width={180} onChange={(e) => onChange('pixelId', e.target.value, true)} />
        </CardRowContent>
      </CardRow>
    </Card>
  </>;
}

export default FacebookPixel;
