import styles from './Menu.module.css';

import React from 'react';

import {
  TitleField, SizeWeightAndColorField, CheckboxField, SizeWithColorField,
  SizeWithTypeField, SizeField, SelectField, ColorField, GradientField,
  InputStyleField, ToggleField
} from '../ThemeMenuFields.js';

const typeOptions = [
  { label: '%', value: '%' },
  { label: 'px', value: 'px' }
];

const alignOptions = [
  { label: 'Left', value: 'left' },
  { label: 'Center', value: 'center' },
  { label: 'Right', value: 'right' }
];

const Descriptions = ({ settings, colors, onChange, onNewColor }) => {
  return (
    <>
      <TitleField title="General Field Settings" />

      <SizeWithTypeField title="Maximum field width"
        settings={settings}
        onChange={onChange}
        margin="25px 0 0 0"
        types={typeOptions}
        size="descriptionsWidth"
        type="descriptionsWidthType" />

      <SelectField title="Field alignment"
        settings={settings}
        onChange={onChange}
        options={alignOptions}
        margin="25px 0 0 0"
        value="descriptionsAlignment" />

      <div className={styles.doubleRow}>
        <div className={styles.left}>
          <SizeField title="Field top margin"
            settings={settings}
            onChange={onChange}
            margin="25px 0 0 0"
            size={`descriptionsTopMargin`} />
        </div>
        <div className={styles.right}>
          <SizeField title="Field bottom margin"
            settings={settings}
            onChange={onChange}
            margin="25px 0 0 0"
            size={`descriptionsBottomMargin`} />
        </div>
      </div>

      <TitleField title="Text Styling" />

      <SizeWithColorField title="Text style"
        settings={settings}
        colors={colors}
        onChange={onChange}
        onNewColor={onNewColor}
        margin="25px 0 0 0"
        size="descriptionsFontSize"
        color="descriptionsFontColor" />

      <SelectField title="Text alignment"
        settings={settings}
        onChange={onChange}
        options={alignOptions}
        margin="25px 0 0 0"
        value="descriptionsFontAlignment" />

      <ColorField title="Links color"
        settings={settings}
        colors={colors}
        onChange={onChange}
        onNewColor={onNewColor}
        margin="25px 0 0 0"
        color="descriptionsLinkColor" />
    </>
  );
}

export default Descriptions;
