import styles from './BillingHistory.module.css';

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { format } from 'date-fns';

import { Loader } from 'ui';

class BillingHistory extends Component {
  render() {
    const {
      billingInvoices, loading
    } = this.props;

    return (
      <div className={styles.main}>
        <div className={styles.head}>
          <div className={styles.title}>Billing History</div>
        </div>

        <table className={styles.table}>
          <thead>
            <tr>
              <th>#</th>
              <th>Created</th>
              {/* <th>Due</th> */}
              <th>Status</th>
              <th>Amount</th>
              <th>Invoice PDF</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {!loading && billingInvoices && billingInvoices.map((invoice) => <tr key={invoice.number}>
              <td>{invoice.number}</td>
              <td>{format(new Date(invoice.created * 1000), 'dd MMM yyyy hhh:mma')}</td>
              {/* <td>{format(invoice.due_date * 1000, 'dd MMM yyyy hhh:mma')}</td> */}
              <td>{invoice.status}</td>
              <td>€{invoice.total / 100}</td>
              <td className={styles.link}>{invoice.pdfUrl ? <a href={invoice.pdfUrl}>Download</a> : '-'}</td>
              <td className={styles.link}>{invoice.payUrl && !invoice.paid ? <a href={invoice.payUrl} target="_blank">Pay</a> : '-'}</td>
            </tr>)}

            {loading && <tr>
              <td colSpan="6" className={styles.loading}>
                <Loader size={20} />
              </td>
            </tr>}
          </tbody>
        </table>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    billingInvoices: state.user.billingInvoices,
    loading: state.user.billingInvoicesLoading
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(BillingHistory);
