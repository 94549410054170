import styles from './Publish.module.css';

import config from 'config.js';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { getUrlWithParameters } from 'misc/helpers.js';

import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  EmailShareButton
} from 'react-share';

import {
  updateLinkCopied
} from 'store/ducks/builder.js';

import { ReactComponent as CopyIcon } from 'assets/images/copy.svg';

import { ReactComponent as ShareFacebookIcon } from 'assets/images/share-facebook.svg';
import { ReactComponent as ShareLinkedinIcon } from 'assets/images/share-linkedin.svg';
import { ReactComponent as ShareMailIcon } from 'assets/images/share-mail.svg';
import { ReactComponent as ShareTwitterIcon } from 'assets/images/share-twitter.svg';

import { ReactComponent as MenuShareIcon } from 'assets/images/menu-publish.svg';

let timeout;

class Publish extends Component {
  componentDidMount() {
    this.refs.url.focus();
    this.refs.url.select();
  }

  linkCopied = () => {
    this.props.updateLinkCopied(true);

    clearTimeout(timeout);

    timeout = setTimeout(() => {
      this.props.updateLinkCopied(false);
    }, 1000);
  }

  render() {
    const {
      form, formId, linkCopied, formName, urlParams, domains
    } = this.props;

    const domain = form.domain || {};
    const alias = form.alias || {};
    const customDomain = domains.find((d) => d._id === domain.value);
    const customAlias = alias.enabled && alias.value ? alias.value : formId;

    const url = getUrlWithParameters(`${domain.enabled && customDomain ? `https://${customDomain.hostname}` : config.formUrl}/${customAlias}`, urlParams);

    return (
      <div id="url">
        <div className={styles.card}>
          <div className={styles.title}>Access Link</div>
          <div className={styles.subTitle}>QuestionScout forms are live from the moment you create them. Please be aware that as soon as you’ve shared your form, changes will be made on the live form.</div>

          <div className={styles.link}>
            <div className={styles.copy}>
              <input type="text" value={url} ref="url" onChange={() => { }} onFocus={(e) => e.target.select()} />
              <CopyToClipboard text={url} onCopy={() => this.linkCopied()}>
                <div>{!linkCopied ? <><CopyIcon />Copy</> : 'Copied!'}</div>
              </CopyToClipboard>
            </div>

            <a href={url} target="_blank" rel="noopener noreferrer" className={styles.url}><MenuShareIcon /><span>Open your form in a new tab</span></a>
          </div>
        </div>

        <div className={styles.card}>
          <div className={styles.title}>Social Share</div>
          <div className={styles.subTitle}>Share this form easily to your social media platforms.</div>

          <div className={styles.share}>
            <EmailShareButton url={url} resetButtonStyle={false} className={styles.shareEmail} subject={formName} body="I would really appreciate it if you took a moment to submit this form I’ve created in QuestionScout:">
              <ShareMailIcon /> Email
            </EmailShareButton>

            <FacebookShareButton url={url} resetButtonStyle={false} className={styles.shareFacebook} quote={formName}>
              <ShareFacebookIcon /> Facebook
            </FacebookShareButton>

            <LinkedinShareButton url={url} resetButtonStyle={false} className={styles.shareLinkein} style={{}}>
              <ShareLinkedinIcon /> LinkedIn
            </LinkedinShareButton>

            <TwitterShareButton url={url} resetButtonStyle={false} className={styles.shareTwitter} title={formName} via="QuestionScout">
              <ShareTwitterIcon /> Twitter
            </TwitterShareButton>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    form: state.builder.form,
    formId: state.builder.formId,
    formName: state.builder.form.name,
    linkCopied: state.builder.linkCopied,
    urlParams: state.builder.form.urlParams,
    domains: state.builder.form.domains
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateLinkCopied: (value) => dispatch(updateLinkCopied(value))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Publish);
