import styles from './SettingsThankYouPage.module.css';

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import debounce from 'lodash.debounce';
import { v4 as uuid } from 'uuid';
import getFieldLabel from 'helpers/getFieldLabel.js';
import ReactTooltip from 'react-tooltip';
import Logic from 'components/Builder/shared/Logic/Logic.js';
import LogicSummary from 'components/Builder/shared/LogicSummary/LogicSummary.js';

import {
  ioUpdateForm, updateForm
} from 'store/ducks/builder.js';

import { Button, MentionsInput, TextInput, Radio, Select } from 'ui';

import { ReactComponent as LogicIcon } from 'assets/images/logic.svg';
import { ReactComponent as ReferToIcon } from 'assets/images/refer-to.svg';

const delayOptions = [
  { label: '1 second', value: 1 },
  { label: '2 seconds', value: 2 },
  { label: '3 seconds', value: 3 },
  { label: '4 seconds', value: 4 },
  { label: '5 seconds', value: 5 },
  { label: '10 seconds', value: 10 },
  { label: '15 seconds', value: 15 },
  { label: '30 seconds', value: 30 },
  { label: '1 minute', value: 60 },
  { label: '2 minutes', value: 120 }
];

const getActionLabel = (redirect, resubmit, redirectDelay, redirectUrl, values) => {
  const redirectUrlText = getFieldLabel(redirectUrl, values, 'text');

  if (!redirect && !resubmit) return 'Only display thank you message';
  if (!redirect && resubmit) return 'Display thank you message & allow to resubmit the form';
  if (redirect && redirectDelay !== 0) return `Display thank you message & redirect to "${redirectUrlText}" after ${delayOptions.find((option) => option.value === redirectDelay).label}`;
  if (redirect && redirectDelay === 0) return `Instantly redirect to "${redirectUrlText}"`;
};

const DefaultPage = ({ content, expanded, redirect, redirectDelay, redirectUrl, resubmit, onEdit, onChange, mentionsData }) => {
  const update = (obj, delay) => {
    let updated = {};

    for (let [key, value] of Object.entries(obj)) {
      updated[key] = value;
    }

    onChange({
      defaultThankYouPage: {
        content, redirect, redirectDelay, redirectUrl, resubmit,
        ...updated
      }
    }, delay);
  };

  return <>
    <div className={styles.card}>
      <div className={styles.top}>
        <div className={styles.left}>
          <div className={styles.label}>
            <span>DEFAULT</span>Thank You Page
          </div>
          <div className={styles.action}>Default thank you page cannot have Logic rule</div>
        </div>
        <div className={styles.right}>
          {!expanded && <Button theme="white" onClick={() => onEdit('default')}>Edit</Button>}
          {expanded && <Button theme="white" onClick={() => onEdit(null)}>Close</Button>}
        </div>
      </div>
      <div className={styles.bottom}>
        {!expanded && <div className={styles.logic}>
          <LogicIcon />Default thank you page cannot have Logic rules
        </div>}

        {expanded && <div className={styles.expanded}>
          <div className={styles.row}>
            <div className={styles.rowLabel}>
              <div className={styles.title}>Completion action</div>
              <div className={styles.subTitle}>The action that will occur when a respondent has completed a form.</div>
            </div>

            <div className={styles.rowContent}>
              <div className={styles.rowContentTitle} style={{ margin: '0 0 10px 0' }}>Confirmation action</div>
              <Radio label="Only display thank you message"
                badge="default"
                value={!redirect && !resubmit}
                onChange={() => { }}
                onClick={() => update({
                  redirect: false,
                  resubmit: false
                }, false)} />

              <Radio label="Display thank you message & allow to resubmit the form"
                value={!redirect && resubmit}
                onChange={() => { }}
                onClick={() => update({
                  redirect: false,
                  resubmit: true
                }, false)} />

              <Radio label="Display thank you message & redirect to a URL after designated time"
                value={redirect && redirectDelay !== 0}
                onChange={() => { }}
                onClick={() => update({
                  redirect: true,
                  resubmit: false,
                  redirectDelay: 5
                }, false)} />

              {redirect && redirectDelay !== 0 && <>
                <Select width={150} options={delayOptions} margin="2px 0 10px 26px" value={
                  { label: delayOptions.find((option) => option.value === redirectDelay).label, value: redirectDelay }
                } onChange={(selected) => update({
                  redirectDelay: selected.value
                })} />
                <div className={styles.redirectUrl}>
                  <MentionsInput style={{ margin: '2px 0 2px 26px', width: '60%' }}
                    placeholder="https://"
                    initialValue={redirectUrl || ''}
                    onChange={(value) => update({ redirectUrl: value }, false)}
                    data={mentionsData} />
                  <a href="https://support.questionscout.com/en/articles/3947231-how-to-use-refer-to" target="_blank" rel="noopener noreferrer"><ReferToIcon data-tip="Refer to is available on this input, click to learn more." /></a>
                  <ReactTooltip place="left" effect="solid" />
                </div>
              </>}

              <Radio label="Instantly redirect to a URL"
                value={redirect && redirectDelay === 0}
                onChange={() => { }}
                onClick={() => update({
                  redirect: true,
                  resubmit: false,
                  redirectDelay: 0
                }, false)} />
              {redirect && redirectDelay === 0 && <div className={styles.redirectUrl}>
                <MentionsInput style={{ margin: '2px 0 2px 26px', width: '60%' }}
                  placeholder="https://"
                  initialValue={redirectUrl || ''}
                  onChange={(value) => update({ redirectUrl: value }, false)}
                  data={mentionsData} />
                <a href="https://support.questionscout.com/en/articles/3947231-how-to-use-refer-to" target="_blank" rel="noopener noreferrer"><ReferToIcon data-tip="Refer to is available on this input, click to learn more." /></a>
                <ReactTooltip place="left" effect="solid" />
              </div>}
            </div>
          </div>

          {((redirect && redirectDelay === 0) === false) && <div className={styles.row}>
            <div className={styles.rowLabel}>
              <div className={styles.title}>Thank you message</div>
              <div className={styles.subTitle}>This message is being displayed when respondent has completed the form.</div>
            </div>

            <div className={styles.rowContent}>
              <MentionsInput menu="static"
                initialValue={content}
                onChange={(value) => update({ content: value })}
                data={mentionsData} />
            </div>
          </div>}
        </div>}
      </div>
    </div>
  </>;
};

const CustomPage = ({
  id, index, fields, thankYouPages, name, content, expanded, redirect, redirectDelay, redirectUrl, mentionsData,
  resubmit, onEdit, onChange, onRemove, conditionsType, conditions, columnOptions, values, calculationConditions
}) => {
  const update = (obj, delay) => {
    const thankYouPagesCopy = [...thankYouPages];
    let updated = {};

    for (let [key, value] of Object.entries(obj)) {
      updated[key] = value;
    }

    thankYouPagesCopy[index] = { ...thankYouPagesCopy[index], ...updated };

    onChange({ thankYouPages: thankYouPagesCopy }, delay);
  };

  return <>
    <div className={styles.card}>
      <div className={styles.top}>
        <div className={styles.left}>
          <div className={styles.label}>
            {name}
          </div>
          <div className={styles.action}>{getActionLabel(redirect, resubmit, redirectDelay, redirectUrl, values)}</div>
        </div>
        <div className={styles.right}>
          {!expanded && <Button theme="white" onClick={() => onEdit(id)}>Edit</Button>}
          {expanded && <Button theme="white" onClick={() => onEdit(null)}>Close</Button>}
          <Button theme="red" onClick={() => onRemove(index)}>Remove</Button>
        </div>
      </div>
      <div className={styles.bottom}>
        {!expanded && <div className={styles.logic}>
          <LogicIcon />
          <LogicSummary
            conditionsType={conditionsType}
            fieldConditions={conditions}
            calculationConditions={calculationConditions}
            values={values}
            fields={fields}
            prefixText="Show this page when"
            emptyMessage="Logic not configured"
          />
        </div>}

        {expanded && <div className={styles.expanded}>
          <div className={styles.row}>
            <div className={styles.rowLabel}>
              <div className={styles.title}>Thank you page name</div>
              <div className={styles.subTitle}>The name of this thank you page variation. This is only visible to you so you can identify the different pages.</div>
            </div>

            <div className={styles.rowContent}>
              <TextInput type="text" value={name} width="60%" onChange={(e) => update({
                name: e.target.value
              }, false)} />
            </div>
          </div>

          <div className={styles.row}>
            <div className={styles.rowLabel}>
              <div className={styles.title}>Completion action</div>
              <div className={styles.subTitle}>The action that will occur when a respondent has completed a form.</div>
            </div>

            <div className={styles.rowContent}>
              <div className={styles.rowContentTitle} style={{ margin: '0 0 10px 0' }}>Confirmation action</div>
              <Radio label="Only display thank you message"
                badge="default"
                value={!redirect && !resubmit}
                onChange={() => { }}
                onClick={() => update({
                  redirect: false,
                  resubmit: false
                }, false)} />

              <Radio label="Display thank you message & allow to resubmit the form"
                value={!redirect && resubmit}
                onChange={() => { }}
                onClick={() => update({
                  redirect: false,
                  resubmit: true
                }, false)} />

              <Radio label="Display thank you message & redirect to a URL after designated time"
                value={redirect && redirectDelay !== 0}
                onChange={() => { }}
                onClick={() => update({
                  redirect: true,
                  resubmit: false,
                  redirectDelay: 5
                }, false)} />
              {redirect && redirectDelay !== 0 && <>
                <Select width={150} options={delayOptions} margin="2px 0 10px 26px" value={
                  { label: delayOptions.find((option) => option.value === redirectDelay).label, value: redirectDelay }
                } onChange={(selected) => update({
                  redirectDelay: selected.value
                })} />
                <div className={styles.redirectUrl}>
                  <MentionsInput style={{ margin: '2px 0 2px 26px', width: '60%' }}
                    placeholder="https://"
                    initialValue={redirectUrl || ''}
                    onChange={(value) => update({ redirectUrl: value }, false)}
                    data={mentionsData} />
                  <a href="https://support.questionscout.com/en/articles/3947231-how-to-use-refer-to" target="_blank" rel="noopener noreferrer"><ReferToIcon data-tip="Refer to is available on this input, click to learn more." /></a>
                  <ReactTooltip place="left" effect="solid" />
                </div>
              </>}

              <Radio label="Instantly redirect to a URL"
                value={redirect && redirectDelay === 0}
                onChange={() => { }}
                onClick={() => update({
                  redirect: true,
                  resubmit: false,
                  redirectDelay: 0
                }, false)} />
              {redirect && redirectDelay === 0 && <div className={styles.redirectUrl}>
                <MentionsInput style={{ margin: '2px 0 2px 26px', width: '60%' }}
                  placeholder="https://"
                  initialValue={redirectUrl || ''}
                  onChange={(value) => update({ redirectUrl: value }, false)}
                  data={mentionsData} />
                <a href="https://support.questionscout.com/en/articles/3947231-how-to-use-refer-to" target="_blank" rel="noopener noreferrer"><ReferToIcon data-tip="Refer to is available on this input, click to learn more." /></a>
                <ReactTooltip place="left" effect="solid" />
              </div>}
            </div>
          </div>

          {((redirect && redirectDelay === 0) === false) && <div className={styles.row}>
            <div className={styles.rowLabel}>
              <div className={styles.title}>Thank you message</div>
              <div className={styles.subTitle}>This message is being displayed when respondent has completed the form.</div>
            </div>

            <div className={styles.rowContent}>
              <MentionsInput menu="static"
                initialValue={content}
                onChange={(value) => update({ content: value })}
                data={mentionsData} />
            </div>
          </div>}

          <div className={styles.row}>
            <div className={styles.rowLabel}>
              <div className={styles.title}>Logic Rules</div>
              <div className={styles.subTitle}>These Logic Rules need to be triggered in order to display this thank you page.</div>
            </div>

            <div className={styles.rowContent}>
              <Logic conditionsType={conditionsType}
                fieldConditions={conditions}
                calculationConditions={calculationConditions}
                onChange={update}
                conditionTypePrefix="Display this thank you page when"
                alwaysOne={true} />
            </div>
          </div>
        </div>}
      </div>
    </div>
  </>;
};

const SettingsThankYouPage = () => {
  const dispatch = useDispatch();

  const form = useSelector(state => state.builder.form);
  const columnOptions = useSelector(state => state.results.columnOptions);
  const values = useSelector(state => state.builder.values);
  const calculationVariables = useSelector(state => state.builder.form.calculationVariables);

  const [expandedPage, setExpandedPage] = useState(null);

  let mentionsData = form.fields.filter((f) => ['longText', 'shortText', 'scale', 'radio', 'checkbox', 'dropdown'].indexOf(f.type) !== -1).map((f) => ({
    _id: f._id,
    type: f.type,
    format: f.format,
    label: f.label
  }));

  if (calculationVariables && calculationVariables.length !== 0) mentionsData = [...mentionsData, ...calculationVariables.map((variable) => ({
    _id: variable._id,
    type: 'variable',
    format: null,
    label: variable.name
  }))];

  const ioUpdateFormWithDelay = debounce((params) => {
    dispatch(ioUpdateForm(params));
  }, 500);

  const handleChange = (params, delay = false) => {
    dispatch(updateForm(params));

    if (delay) {
      ioUpdateFormWithDelay(params);
    } else {
      dispatch(ioUpdateForm(params));
    }
  };

  const handleEdit = (value) => setExpandedPage(value);

  const addEmpty = () => {
    const thankYouPagesCopy = [...form.thankYouPages];
    const ref = uuid();

    thankYouPagesCopy.push({
      ref,
      name: `Thank You Page #${form.thankYouPages.length + 1}`,
      conditionsType: 'all',
      redirectDelay: 0,
      redirect: false,
      conditions: [{
        triggerField: null,
        type: 'equal',
        value: null
      }],
      calculationConditions: []
    });

    handleChange({
      thankYouPages: thankYouPagesCopy
    });

    setExpandedPage(ref);
  };

  const handleRemove = (index) => {
    const thankYouPagesCopy = [...form.thankYouPages];

    thankYouPagesCopy.splice(index, 1);

    handleChange({
      thankYouPages: thankYouPagesCopy
    }, false);
  };

  return <div className={styles.main}>
    {form.thankYouPages.map((page, index) => {
      return <React.Fragment key={page.ref}>
        <CustomPage name={page.name}
          content={page.content}
          redirect={page.redirect}
          redirectDelay={page.redirectDelay}
          redirectUrl={page.redirectUrl}
          resubmit={page.resubmit}
          conditionsType={page.conditionsType}
          conditions={page.conditions}
          calculationConditions={page.calculationConditions}
          mentionsData={mentionsData}
          id={page.ref}
          index={index}
          thankYouPages={form.thankYouPages}
          fields={form.fields}
          expanded={expandedPage === page.ref}
          columnOptions={columnOptions}
          values={values}
          onEdit={handleEdit}
          onChange={handleChange}
          onRemove={handleRemove} />
      </React.Fragment>
    })}

    <Button theme="blue" onClick={addEmpty} margin="0 0 40px 0">Create new Thank You Page</Button>

    <DefaultPage {...form.defaultThankYouPage}
      expanded={expandedPage === 'default'}
      mentionsData={mentionsData}
      onEdit={handleEdit}
      onChange={handleChange} />
  </div>;
}

export default SettingsThankYouPage;
