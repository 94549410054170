import styles from './Onetool.module.css';

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { formatBytes } from 'misc/helpers.js';

import { Card, Loader } from 'ui';

const Onetool = ({ }) => {
  const plan = useSelector(state => state._users.user.plan);
  const planUsage = useSelector(state => state._users.user.planUsage);

  return (
    <div className={styles.main}>
      <div className={styles.header}>
        <h1>You're using QuestionScout as a <span>onetool</span> user</h1>
      </div>

      <Card className={styles.card}>
        <ul>
          <li>
            <div>All Features</div>
            <div style={{ color: '#24B47E' }}>Included</div>
          </li>
          <li>
            <div>{plan.forms} total forms</div>
            <div>{planUsage.forms || 0} in use</div>
          </li>
          <li>
            <div>{plan.submissions} monthly responses</div>
            <div>{planUsage.submissions || 0} used this month</div>
          </li>
          <li>
            <div>{plan.users - 1} user{(plan.users - 1) > 1 && 's'}</div>
            <div>{planUsage.users || 1} in use</div>
          </li>
          <li>
            <div>{plan.domains} domain{(plan.domains) > 1 && 's'}</div>
            <div>{planUsage.domains || 1} in use</div>
          </li>
          <li>
            <div>{formatBytes((plan.filesStorage || 0) * 1000000)} file storage</div>
            <div>{formatBytes((planUsage.filesStorage || 0) * 1000000)} used this month</div>
          </li>
        </ul>
      </Card>
    </div>
  );
}

export default Onetool;
