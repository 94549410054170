import styles from './Menu/Menu.module.css';

import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useDropzone } from 'react-dropzone';

import { TextInput, ColorPaletteSelect, Checkbox, Select, Button, Toggle2, Slider, FontSelect, SearchInput, Loader } from 'ui';

import {
  getImages, updateImagesQuery, updateImagesPage, pingUnsplash
} from 'store/ducks/builder.js';

const typeOptions = [
  { label: '%', value: '%' },
  { label: 'px', value: 'px' }
];

const fontWeightOptions = [
  { label: 400, value: 400 },
  { label: 600, value: 600 }
];

export const TitleField = ({ title, subTitle, margin }) => {
  return (
    <div className={styles.sectionTitle} style={{ margin }}>
      <div className={styles.name}>{title}</div>
      <div className={styles.status}>{subTitle}</div>
    </div>
  );
}

export const CopyField = ({ id, onCopy, margin, options, values }) => {
  const [source, setSource] = useState(options[0].value);

  const handleClick = () => {
    const valuesArr = [...values];

    for (let index in valuesArr) {
      valuesArr[index] = [`${source}${valuesArr[index]}`, `${id}${valuesArr[index]}`];
    }

    onCopy(valuesArr);
  };

  return (
    <>
      <div className={styles.row} style={{ margin }}>
        <div className={styles.rowContent}>
          <div className={styles.rowLabel} style={{ margin: 0 }}>Copy styling from:</div>

          <div className={styles.rowContent}>
            <Select width={80} options={options} value={
              { label: options.find((option) => option.value === source).label, value: source }
            } onChange={(selection) => setSource(selection.value)} />
            <Button theme="white" margin="0 0 0 10px" size="default" onClick={handleClick}>Copy</Button>
          </div>
        </div>
      </div>

      <hr className={styles.hr} style={{ margin: '10px 20px 0 20px' }} />
    </>
  );
}

export const SizeWeightAndColorField = ({ title, settings, colors, onChange, onNewColor, size, weight, color, margin }) => {
  return (
    <div className={styles.row} style={{ margin }}>
      <div className={styles.rowLabel}>{title}</div>

      <div className={styles.rowContent}>
        <TextInput type="number" step="0.1" selectAllOnFocus={true} onChange={(e) => onChange(size, parseFloat(e.target.value))} value={settings[size]} prefix="px" width={'calc(100% - 160px)'} />
        <Select width={80} options={fontWeightOptions} value={
          { label: settings[weight], value: settings[weight] }
        } onChange={(selection) => onChange(weight, parseInt(selection.value))} />
        <ColorPaletteSelect onNewColor={onNewColor} onChange={(c) => onChange(color, c)} palette={colors} value={settings[color]} width={70} dropdownWidth={309} />
      </div>
    </div>
  );
}

export const CheckboxField = ({ label, settings, onChange, value, margin }) => {
  return (
    <div className={styles.row} style={{ margin }}>
      <div className={styles.rowContent}>
        <Checkbox onClick={() => onChange(value, !settings[value])} value={settings[value]} label={label} />
      </div>
    </div>
  );
}

export const SizeWithColorField = ({ title, settings, colors, onChange, onNewColor, size, color, margin }) => {
  return (
    <div className={styles.row} style={{ margin }}>
      <div className={styles.rowLabel}>{title}</div>

      <div className={styles.rowContent}>
        <TextInput type="number" step="0.1" selectAllOnFocus={true} onChange={(e) => onChange(size, parseFloat(e.target.value))} value={settings[size]} prefix="px" width={'calc(100% - 80px)'} />
        <ColorPaletteSelect onNewColor={onNewColor} onChange={(c) => onChange(color, c)} palette={colors} value={settings[color]} width={70} dropdownWidth={309} />
      </div>
    </div>
  );
}

export const SizeWithTypeField = ({ title, settings, onChange, size, type, margin }) => {
  return (
    <div className={styles.row} style={{ margin }}>
      <div className={styles.rowLabel}>{title}</div>

      <div className={styles.rowContent}>
        <TextInput type="number" step="0.1" selectAllOnFocus={true} onChange={(e) => onChange(size, parseFloat(e.target.value))} value={settings[size]} width={'calc(100% - 80px)'} />
        <Select width={70} options={typeOptions} value={
          { label: settings[type], value: settings[type] }
        } onChange={(selection) => onChange(type, selection.value)} />
      </div>
    </div>
  );
}

export const SizeField = ({ title, settings, onChange, size, margin }) => {
  return (
    <div className={styles.row} style={{ margin }}>
      <div className={styles.rowLabel}>{title}</div>

      <div className={styles.rowContent}>
        <TextInput type="number" step="0.1" selectAllOnFocus={true} onChange={(e) => onChange(size, parseFloat(e.target.value))} value={settings[size]} prefix="px" width={'100%'} />
      </div>
    </div>
  );
}

export const SelectField = ({ title, settings, onChange, options, value, margin }) => {
  return (
    <div className={styles.row} style={{ margin }}>
      <div className={styles.rowLabel}>{title}</div>

      <div className={styles.rowContent}>
        <Select width="100%" options={options} value={
          { label: options.find((option) => option.value === settings[value]).label, value: settings[value] }
        } onChange={(selection) => onChange(value, selection.value)} />
      </div>
    </div>
  );
}

export const ColorField = ({ title, settings, colors, onChange, onNewColor, color, margin }) => {
  return (
    <div className={styles.row} style={{ margin }}>
      <div className={styles.rowLabel}>{title}</div>

      <div className={styles.rowContent}>
        <ColorPaletteSelect onNewColor={onNewColor} onChange={(c) => onChange(color, c)} palette={colors} value={settings[color]} width="100%" dropdownWidth={309} />
      </div>
    </div>
  );
}

export const GradientField = ({ title, settings, onChange, direction, margin }) => {
  return (
    <div className={styles.row} style={{ margin }}>
      <div className={styles.rowLabel}>{title}</div>

      <ul className={styles.gradientSelect}>
        <li className={settings[direction] === 'left-right' ? styles.active : ''} onClick={() => onChange(direction, 'left-right')}><div style={{ backgroundImage: 'linear-gradient(225deg, #732A56 0%, #FFFFFF 100%)' }} /></li>
        <li className={settings[direction] === 'right-left' ? styles.active : ''} onClick={() => onChange(direction, 'right-left')}><div style={{ backgroundImage: 'linear-gradient(25deg, #732A56 0%, #FFFFFF 100%)' }} /></li>
        <li className={settings[direction] === 'top-bottom' ? styles.active : ''} onClick={() => onChange(direction, 'top-bottom')}><div style={{ backgroundImage: 'linear-gradient(180deg, #732A56 0%, #FFFFFF 100%)' }} /></li>
        <li className={settings[direction] === 'bottom-top' ? styles.active : ''} onClick={() => onChange(direction, 'bottom-top')}><div style={{ backgroundImage: 'linear-gradient(0deg, #732A56 0%, #FFFFFF 100%)' }} /></li>
      </ul>
    </div>
  );
}

export const ToggleField = ({ label, settings, onChange, value, margin }) => {
  return (
    <div className={styles.row} style={{ margin }}>
      <div className={styles.rowContent}>
        <Toggle2 value={settings[value]} onClick={() => onChange(value, !settings[value])}>{label}</Toggle2>
      </div>
    </div>
  );
}

export const FontField = ({ title, settings, onChange, value, margin }) => {
  const fonts = useSelector((state) => state.theme.fonts);

  return (
    <div className={styles.row} style={{ margin }}>
      <div className={styles.rowLabel}>{title}</div>

      <div className={styles.rowContent}>
        <FontSelect width="100%" options={fonts} value={
          { label: settings[value], value: settings[value] }
        } onChange={(selected) => onChange(value, selected.value)} />
      </div>
    </div>
  );
}

export const UploadBackgroundField = ({ title, images, onBackgroundUpload, onRemoveBackgroundlick, value, margin }) => {
  const uploads = useSelector((state) => state._themes.uploads);
  const file = uploads.find((file) => file.ref === 'background') || null;

  const { getRootProps, getInputProps, open } = useDropzone({
    accept: 'image/jpeg, image/png, image/gif',
    multiple: false,
    noClick: true,
    noKeyboard: true,
    onDrop: (file) => onBackgroundUpload(file)
  });

  return (
    <div className={styles.row} style={{ margin }}>
      <div className={styles.rowLabel}>{title}</div>

      <div className={styles.rowContent}>
        <div className={styles.imageUpload}>
          {(!images.background.url) && <div {...getRootProps({ className: styles.dropzone })}>
            <input {...getInputProps()} />
            {(!file) && <>
              <p>Drop your image here</p>
              <p>or</p>
              <span onClick={open}>click to upload</span>
            </>}
            {file && file.loaded !== true && <Loader size={30} />}
          </div>}

          {(images.background.type === 'upload' && images.background.url) && <div className={styles.uploaded}>
            <span className={styles.removeImage} onClick={onRemoveBackgroundlick}>Remove current image</span>
          </div>}
        </div>
      </div>
    </div>
  );
}

export const UrlBackgroundField = ({ title, images, settings, value, onChange, margin }) => {
  return <div className={styles.row} style={{ margin }}>
    <div className={styles.rowLabel}>{title}</div>

    <div className={styles.rowContent}>
      <TextInput selectAllOnFocus={true} placeholder="https://" onChange={(e) => onChange('background', e.target.value)} value={images.background.url} width={'100%'} />
    </div>
  </div>;
}

export const UnsplashBackgroundField = ({ title, settings, value, onChange, margin }) => {
  const dispatch = useDispatch();

  const images = useSelector((state) => state.builder.images);
  const query = useSelector((state) => state.builder.imagesQuery);
  const loading = useSelector((state) => state.builder.imagesLoading);
  const page = useSelector((state) => state.builder.imagesPage);
  const totalPages = useSelector((state) => state.builder.imagesTotalPages);

  useEffect(() => {
    dispatch(getImages());
  }, []);

  const searchImage = (q) => {
    dispatch(updateImagesPage(1));
    dispatch(updateImagesQuery(q));
    dispatch(getImages());
  }

  const changePage = (p) => {
    if (p < 1 || p > totalPages) return;

    dispatch(updateImagesPage(p));
    dispatch(getImages());
  }

  const selectBackgroundImage = (i) => {
    dispatch(pingUnsplash(i.links.download_location));

    onChange('background', {
      url: i.url,
      thumb: i.thumb,
      authorName: i.user.name,
      authorUrl: i.user.links.html
    });
  }

  return (
    <div className={styles.row} style={{ margin }}>
      <div className={styles.rowLabel}>{title}</div>

      <div className={styles.rowContent}>
        <div className={styles.unsplash}>
          <SearchInput value={query} placeholder="Search photo’s through Unsplash" onChange={(e) => searchImage(e.target.value)} />

          <ul>
            {images.map((image) => {
              return <li onClick={() => selectBackgroundImage(image)} key={image.id}>
                <img width={140} height={90} src={image.thumb} alt="" />
              </li>;
            })}
          </ul>

          {loading && <Loader size={30} />}

          {!loading && images.length === 0 && <p>No results found for "{query}"</p>}

          <div className={styles.pagination}>
            <Button theme="white" width="48%" onClick={() => changePage(page - 1)} disabled={page <= 1}>Previous</Button>
            <Button theme="white" width="48%" onClick={() => changePage(page + 1)} disabled={page >= totalPages}>Next</Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export const SliderField = ({ title, settings, onChange, value, options, margin }) => {
  return (
    <div className={styles.row} style={{ margin }}>
      <div className={styles.rowLabel}>{title}</div>

      <div className={[styles.rowContent, styles.sliderContent].join(' ')}>
        <Slider value={options.findIndex((v) => v === settings[value])}
          values={options}
          onChange={(selected) => onChange(value, options[selected])} />
        <span className={styles.sliderContentValue}>{settings[value]}</span>
      </div>
    </div>
  );
}
