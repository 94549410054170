import styles from './Integrations.module.css';

import React from 'react';

import { TextInput, Toggle, Card, CardRow, CardRowLabel, CardRowContent } from 'ui';

import logoIcon from 'assets/images/integrations/googleAnalytics-small.png';

const GoogleAnalytics = ({ integration, onChange }) => {
  return <>
    <div className={styles.head}>
      <img src={logoIcon} width="32px" alt="logo" className={styles.logo} />
      <div className={styles.label}>Google Analytics</div>
      <div className={styles.desc}>Discover how people find and use your forms. Get the data you need to measure campaigns, improve conversions, and more.</div>

      <div className={styles.statusLabel}>Integration Status</div>
      <div className={styles.statusValue} onClick={() => onChange('enabled', !integration.enabled, false)}>
        <Toggle checked={integration.enabled} readOnly={true} onChange={() => { }} />
        <div className={styles.statusValueText}>This integration is currently <span>{integration.enabled ? 'enabled' : 'disabled'}</span></div>
      </div>
    </div>

    <Card className={styles.card}>
      <CardRow>
        <CardRowLabel title="Google Analytics ID" subTitle="Insert the Google Analytics Tracking ID" />

        <CardRowContent>
          <TextInput value={integration.trackingId} placeholder="UA-XXXXXXX-Y" width={180} onChange={(e) => onChange('trackingId', e.target.value, true)} />
        </CardRowContent>
      </CardRow>
    </Card>
  </>;
}

export default GoogleAnalytics;
