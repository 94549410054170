import styles from './Menu.module.css';

import React from 'react';

import {
  TitleField, SizeWeightAndColorField, SelectField, SizeWithTypeField,
  ColorField, SizeWithColorField, SizeField, CheckboxField, ToggleField,
  CopyField
} from './../ThemeMenuFields.js';

const typeOptions = [
  { label: '%', value: '%' },
  { label: 'px', value: 'px' }
];

const fontWeightOptions = [
  { label: 400, value: 400 },
  { label: 600, value: 600 }
];

const alignOptions = [
  { label: 'Left', value: 'left' },
  { label: 'Center', value: 'center' },
  { label: 'Right', value: 'right' }
];

const Input = ({ title, subTitle, id, settings, colors, onChange, onNewColor, onCopy }) => {
  return <>
    <TitleField title={title} subTitle={subTitle} />

    {subTitle !== 'Idle' && <ToggleField label={`Different styling on ${subTitle}`}
      settings={settings}
      onChange={onChange}
      margin="25px 0 0 0"
      value={`${id}Enable`} />}

    {(subTitle === 'Idle' || settings[`${id}Enable`]) && <>
      {subTitle === 'Hover' && <CopyField onCopy={onCopy}
        margin="15px 0 0 0"
        id={id}
        values={['TextColor', 'PlaceholderColor', 'Background', 'BorderColor', 'Shadow', 'ShadowColor', 'ShadowOffsetX', 'ShadowOffsetY', 'ShadowBlur', 'ShadowSpread']}
        options={[
          { label: 'Idle', value: 'inputFieldsIdle' }
        ]} />}

      {subTitle === 'Active' && <CopyField onCopy={onCopy}
        margin="15px 0 0 0"
        id={id}
        values={['TextColor', 'PlaceholderColor', 'Background', 'BorderColor', 'Shadow', 'ShadowColor', 'ShadowOffsetX', 'ShadowOffsetY', 'ShadowBlur', 'ShadowSpread']}
        options={[
          { label: 'Idle', value: 'inputFieldsIdle' },
          { label: 'Hover', value: 'inputFieldsHover' }
        ]} />}

      {subTitle === 'Idle' && <SizeWeightAndColorField title="Input text"
        settings={settings}
        colors={colors}
        onChange={onChange}
        onNewColor={onNewColor}
        weights={fontWeightOptions}
        margin="25px 0 0 0"
        size={`${id}TextFontSize`}
        weight={`${id}TextWeight`}
        color={`${id}TextColor`} />}

      {subTitle !== 'Idle' && <ColorField title="Input text color"
        settings={settings}
        colors={colors}
        onChange={onChange}
        onNewColor={onNewColor}
        margin="25px 0 0 0"
        color={`${id}TextColor`} />}

      {subTitle === 'Idle' && <SizeWeightAndColorField title="Input placeholder"
        settings={settings}
        colors={colors}
        onChange={onChange}
        weights={fontWeightOptions}
        onNewColor={onNewColor}
        margin="25px 0 0 0"
        size={`${id}PlaceholderFontSize`}
        weight={`${id}PlaceholderWeight`}
        color={`${id}PlaceholderColor`} />}

      {subTitle !== 'Idle' && <ColorField title="Input placeholder color"
        settings={settings}
        colors={colors}
        onChange={onChange}
        onNewColor={onNewColor}
        margin="25px 0 0 0"
        color={`${id}PlaceholderColor`} />}

      <ColorField title="Input background"
        settings={settings}
        colors={colors}
        onChange={onChange}
        onNewColor={onNewColor}
        margin="25px 0 0 0"
        color={`${id}Background`} />

      {subTitle === 'Idle' && <SizeWithColorField title="Input border"
        settings={settings}
        colors={colors}
        onChange={onChange}
        onNewColor={onNewColor}
        margin="25px 0 0 0"
        size={`${id}BorderSize`}
        color={`${id}BorderColor`} />}

      {subTitle !== 'Idle' && <ColorField title="Input border color"
        settings={settings}
        colors={colors}
        onChange={onChange}
        onNewColor={onNewColor}
        margin="25px 0 0 0"
        color={`${id}BorderColor`} />}

      {subTitle === 'Idle' && <SizeField title="Input roundness"
        settings={settings}
        onChange={onChange}
        margin="25px 0 0 0"
        size={`${id}Roundness`} />}

      {subTitle === 'Idle' && <SizeField title="Input vertical padding"
        settings={settings}
        onChange={onChange}
        margin="25px 0 0 0"
        size={`${id}VerticalPadding`} />}

      {subTitle === 'Idle' && <SizeField title="Input horizontal padding"
        settings={settings}
        onChange={onChange}
        margin="25px 0 0 0"
        size={`${id}HorizontalPadding`} />}

      <CheckboxField label="Add shadows"
        settings={settings}
        onChange={onChange}
        margin="25px 0 0 0"
        value={`${id}Shadow`} />

      {settings[`${id}Shadow`] && <>
        <ColorField title="Input shadow color"
          settings={settings}
          colors={colors}
          onChange={onChange}
          onNewColor={onNewColor}
          margin="10px 0 0 0"
          color={`${id}ShadowColor`} />

        <SizeField title="Input shadow horizontal length"
          settings={settings}
          onChange={onChange}
          margin="25px 0 0 0"
          size={`${id}ShadowOffsetX`} />

        <SizeField title="Input shadow vertical length"
          settings={settings}
          onChange={onChange}
          margin="25px 0 0 0"
          size={`${id}ShadowOffsetY`} />

        <SizeField title="Input shadow blur"
          settings={settings}
          onChange={onChange}
          margin="25px 0 0 0"
          size={`${id}ShadowBlur`} />

        <SizeField title="Input shadow spread"
          settings={settings}
          onChange={onChange}
          margin="25px 0 0 0"
          size={`${id}ShadowSpread`} />
      </>}
    </>}
  </>
};

const InputFieldsDropdowns = ({ settings, colors, onChange, onNewColor, onCopy }) => {
  return (
    <>
      <TitleField title="General Field Settings" />

      <SizeWithTypeField title="Maximum field width"
        settings={settings}
        onChange={onChange}
        margin="25px 0 0 0"
        types={typeOptions}
        size="inputFieldsDropdownsWidth"
        type="inputFieldsDropdownsWidthType" />

      <SelectField title="Field alignment"
        settings={settings}
        onChange={onChange}
        options={alignOptions}
        margin="25px 0 0 0"
        value="inputFieldsDropdownsAlignment" />

      <div className={styles.doubleRow}>
        <div className={styles.left}>
          <SizeField title="Field top margin"
            settings={settings}
            onChange={onChange}
            margin="25px 0 0 0"
            size={`inputFieldsDropdownsTopMargin`} />
        </div>
        <div className={styles.right}>
          <SizeField title="Field bottom margin"
            settings={settings}
            onChange={onChange}
            margin="25px 0 0 0"
            size={`inputFieldsDropdownsBottomMargin`} />
        </div>
      </div>

      <TitleField title="Field Theme" />

      <ul className={styles.inputSelect}>
        <li onClick={() => onChange('inputFieldsDropdownsType', 'style1')} className={[styles.style1, settings.inputFieldsDropdownsType === 'style1' ? styles.active : ''].join(' ')}>
          <label>Field Label</label>
          <div>Field Content</div>
        </li>

        <li onClick={() => onChange('inputFieldsDropdownsType', 'style2')} className={[styles.style2, settings.inputFieldsDropdownsType === 'style2' ? styles.active : ''].join(' ')}>
          <label>Field Label</label>
          <div>Field Content</div>
        </li>

        <li onClick={() => onChange('inputFieldsDropdownsType', 'style3')} className={[styles.style3, settings.inputFieldsDropdownsType === 'style3' ? styles.active : ''].join(' ')}>
          <label>Field Label</label>
          <div>Field Content</div>
        </li>
      </ul>

      <Input title="Field Styling" subTitle="Idle" id="inputFieldsIdle" settings={settings} colors={colors} onChange={onChange} onNewColor={onNewColor} onCopy={onCopy} />
      <Input title="Field Styling" subTitle="Hover" id="inputFieldsHover" settings={settings} colors={colors} onChange={onChange} onNewColor={onNewColor} onCopy={onCopy} />
      <Input title="Field Styling" subTitle="Active" id="inputFieldsActive" settings={settings} colors={colors} onChange={onChange} onNewColor={onNewColor} onCopy={onCopy} />
    </>
  );
}

export default InputFieldsDropdowns;
