import styles from './DeleteForm.module.css';

import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router';

import {
  hideDeleteFormModal
} from 'store/ducks/dashboard.js';

import {
  deleteForm, resetEndpoitState
} from 'store/ducks/_forms.js';

import {
  getUser
} from 'store/ducks/_users.js';

import { Button, Modal, Loader } from 'ui';

const DeleteForm = () => {
  const dispatch = useDispatch();

  const user = useSelector(state => state._users.user);
  const forms = useSelector(state => state._forms.forms);
  const form = useSelector(state => state.dashboard.deleteFormModal.form);
  const show  = useSelector(state => state.dashboard.deleteFormModal._show);
  const loading = useSelector(state => state._forms.deleteForm.loading);
  const success = useSelector(state => state._forms.deleteForm.success);
  const error = useSelector(state => state._forms.deleteForm.error);

  const cancel = () => {
    dispatch(resetEndpoitState('deleteForm'));
    dispatch(hideDeleteFormModal());
    dispatch(getUser());
  }

  const submit = () => {
    dispatch(deleteForm(form));
  }

  if (success || error) {
    cancel();
  }

  return (
    <>
      <Modal isOpen={show} onRequestClose={cancel} width={320} header="Deleting Form" footer={<>
        <Button size="small" theme="white" onClick={cancel} margin="0 15px 0 0">Cancel</Button>
        <Button size="small" theme="red" onClick={submit} disabled={loading}>Yes, delete form</Button>
      </>}>
        <div className={styles.content}>
          {loading && <Loader size={30} />}

          {!loading && <p>Are you sure you want to delete this form? All associated data on this form will be removed from your account <strong>permanently</strong>.</p>}
        </div>
      </Modal>
    </>
  );
};

export default withRouter(DeleteForm);
