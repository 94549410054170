import styles from './SettingsIntegrations.module.css';

import React from 'react';
import { useSelector } from 'react-redux';

import { Button } from 'ui';

const integrations = [{
  id: 'zapier',
  img: require('assets/images/integrations/zapier.png'),
  imgHeight: 35,
  label: 'Zapier',
  showStatus: false,
  desc: 'Our integration with Zapier helps you easily push data you collect on QuestionScout forms and surveys to over 2.000 services.'
}, {
  id: 'integrately',
  img: require('assets/images/integrations/integrately.svg'),
  imgHeight: 35,
  label: 'Integrately',
  showStatus: false,
  desc: 'Connect your form to Integrately and easily get access to integrations to hundreds of applications you use every day!'
}, {
  id: 'googleTagManager',
  img: require('assets/images/integrations/googleTagManager.png'),
  imgHeight: 35,
  label: 'Google Tag Manager',
  showStatus: true,
  desc: 'Add your GTM container ID to QuestionScout forms and surveys for conversion tracking, site analytics, retargeting, and more.'
}, {
  id: 'facebookPixel',
  img: require('assets/images/integrations/facebookPixel.png'),
  imgHeight: 15,
  label: 'Facebook Pixel',
  showStatus: true,
  desc: 'Add your Facebook pixel ID and get all the data you need to measure and optimize your marketing campaigns.'
}, {
  id: 'googleAnalytics',
  img: require('assets/images/integrations/googleAnalytics.png'),
  imgHeight: 25,
  label: 'Google Analytics',
  showStatus: true,
  desc: 'Discover how people find and use your forms. Get the data you need to measure campaigns, improve conversions, and more.'
}];

const SettingsIntegrations = ({ match }) => {
  const form = useSelector(state => state.builder.form);

  return <>
    <div className={styles.integrations}>
      {integrations.map((integration) => {
        const isEnabled = form.integrations && form.integrations[integration.id] && form.integrations[integration.id].enabled;

        return <div key={integration.id} className={styles.integration}>
          <div className={styles.logo}>
            <img src={integration.img} alt={integration.label} height={integration.imgHeight} />
          </div>
          <div className={styles.label}>{integration.label}{integration.showStatus && <span className={styles[isEnabled ? 'enabled' : 'disabled']}>{isEnabled ? 'ENABLED' : 'DISABLED'}</span>}</div>
          <div className={styles.desc}>{integration.desc}</div>

          <Button theme="white" className={styles.button} link={`/builder/${match.params.id}/settings/integrations/${integration.id}`} fullWidth={true}>Configure</Button>
        </div>;
      })}
    </div>
  </>;
}

export default SettingsIntegrations;
