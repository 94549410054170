import styles from './Statistics.module.css';

import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  BarChart, Bar, ResponsiveContainer, Tooltip, PieChart, Pie, Cell
} from 'recharts';
import { format } from 'date-fns';
import { getName } from 'country-list';
import ReactTooltip from 'react-tooltip';

import {
  ComposableMap,
  ZoomableGroup,
  Geographies,
  Geography,
} from 'react-simple-maps';

import { scaleLinear } from 'd3-scale';

import {
  getFields
} from 'store/ducks/results.js';

import {
  loadStatistics, expandBox, collapseBox, updateTab, clear
} from 'store/ducks/statistics.js';

import { ReactComponent as ExpandIcon } from 'assets/images/expand.svg';
import { ReactComponent as CollapseIcon } from 'assets/images/collapse.svg';
import { ReactComponent as QuestionIcon } from 'assets/images/question.svg';

import emptyMiniChart from 'assets/images/empty-mini-chart.png';
import mapFile from 'assets/map.json';

import { Loader } from 'ui';

const CustomTooltip = ({ active, payload, end }) => {
  let name;
  let value;

  if (active && payload !== null && payload.length > 0) {
    name = payload[0].payload.date;
    value = payload[0].payload.value - payload[0].payload.emptySize;

    if (name === null) return null;

    return (
      <div className={styles.tooltip}>
        <div className={styles.date}>{format(new Date(name), 'd MMMM yyyy')}</div>
        <div className={styles.value}>{(end === 's' || end === '%') ? parseFloat(value) : Math.round(value)}{end}</div>
      </div>
    );
  }

  return null;
};

const COLORS = ['#F95D6A', '#FF7C43', '#D45087', '#FFA600', '#665191', '#619BC0', '#5E7DE8', '#A05195'];

class Statistics extends Component {
  UNSAFE_componentWillMount = () => {
    this.props.loadStatistics();
  }

  componentWillUnmount = () => {
    this.props.clear();
  }

  render() {
    const {
      loading, statistics, expanded, tabs, fields
    } = this.props;

    const {
      id, tab
    } = this.props.match.params;

    let popScale;

    if (!loading && statistics) {
      popScale = scaleLinear().domain([0, Math.max.apply(Math, statistics.countries.map((d) => d.count))]).range(['#FAFAFA', '#8B337A']);
    }

    return (
      <div className={styles.main}>
        <div className={styles.header}>
          <span>Statistics update every 10 minutes</span>
        </div>

        <div className={styles.content}>
          {loading && <Loader size={40} />}

          {!loading && <>
            <div className={styles.statistics} id="overall-stats">
              <ReactTooltip place="top" effect="solid" />

              <ul>
                <li className={[
                  expanded.totalViews ? styles.active : ''
                ].join(' ')}>
                  <div className={styles.head}>
                    {!expanded.totalViews && <>
                      <div>
                        {statistics.totalViewsHistory.length > 0 && <BarChart className={styles.miniChart} width={120} height={62} data={statistics.totalViewsHistory} barSize={3} barGap={2} barCategoryGap={2}>
                          <Bar dataKey="value" fill="#BFBFBF" radius={[3, 3, 0, 0]} barSize={3} background={false} />
                        </BarChart>}

                        {statistics.totalViewsHistory.length === 0 && <img draggable="false" className={styles.miniChart} src={emptyMiniChart} width={120} height={62} alt="" />}
                      </div>
                    </>}
                    <div>
                      <div className={styles.title}>Total Views<QuestionIcon data-tip={'The total amount of views your form has received.'} /></div>
                      <div className={styles.number}>{statistics.totalViews}</div>
                    </div>
                  </div>

                  {expanded.totalViews && <div className={styles.chart}>
                    <ResponsiveContainer width="100%" height="100%">
                      <BarChart data={statistics.totalViewsHistory} barSize={10} barGap={2} barCategoryGap={2}>
                        <Tooltip cursor={{ fill: '#FAFAFA' }} content={<CustomTooltip end={' views'} />} />
                        <Bar dataKey="value" fill="#BFBFBF" radius={[4, 4, 0, 0]} barSize={10} background={false} />
                      </BarChart>
                    </ResponsiveContainer>
                  </div>}

                  <div className={[
                    styles.expand,
                    statistics.totalViewsHistory.length === 0 ? styles.disabled : ''
                  ].join(' ')} onClick={() => expanded.totalViews ? this.props.collapseBox('totalViews') : this.props.expandBox('totalViews')}>
                    {!expanded.totalViews && <>Expand <ExpandIcon /></>}
                    {expanded.totalViews && <>Collapse <CollapseIcon /></>}
                  </div>
                </li>

                <li className={[
                  expanded.totalReplies ? styles.active : ''
                ].join(' ')}>
                  <div className={styles.head}>
                    {!expanded.totalReplies && <>
                      <div>
                        {statistics.totalRepliesHistory.length > 0 && <BarChart className={styles.miniChart} width={120} height={62} data={statistics.totalRepliesHistory} barSize={3} barGap={2} barCategoryGap={2}>
                          <Bar dataKey="value" fill="#BFBFBF" radius={[3, 3, 0, 0]} barSize={3} background={false} />
                        </BarChart>}

                        {statistics.totalRepliesHistory.length === 0 && <img draggable="false" className={styles.miniChart} src={emptyMiniChart} width={120} height={62} alt="" />}
                      </div>
                    </>}
                    <div>
                      <div className={styles.title}>Total Responses<QuestionIcon data-tip={'Number of people that responded to your form.'} /></div>
                      <div className={styles.number}>{statistics.totalReplies}</div>
                    </div>
                  </div>

                  {expanded.totalReplies && <div className={styles.chart}>
                    <ResponsiveContainer width="100%" height="100%">
                      <BarChart data={statistics.totalRepliesHistory} barSize={10} barGap={2} barCategoryGap={2}>
                        <Tooltip cursor={{ fill: '#FAFAFA' }} content={<CustomTooltip end={' responses'} />} />
                        <Bar dataKey="value" fill="#BFBFBF" radius={[4, 4, 0, 0]} barSize={10} background={false} />
                      </BarChart>
                    </ResponsiveContainer>
                  </div>}

                  <div className={[
                    styles.expand,
                    statistics.totalRepliesHistory.length === 0 ? styles.disabled : ''
                  ].join(' ')} onClick={() => expanded.totalReplies ? this.props.collapseBox('totalReplies') : this.props.expandBox('totalReplies')}>
                    {!expanded.totalReplies && <>Expand <ExpandIcon /></>}
                    {expanded.totalReplies && <>Collapse <CollapseIcon /></>}
                  </div>
                </li>

                <li className={[
                  expanded.avgCompletionTime ? styles.active : ''
                ].join(' ')}>
                  <div className={styles.head}>
                    {!expanded.avgCompletionTime && <>
                      <div>
                        {statistics.avgCompletionTimeHistory.length > 0 && <BarChart className={styles.miniChart} width={120} height={62} data={statistics.avgCompletionTimeHistory} barSize={3} barGap={2} barCategoryGap={2}>
                          <Bar dataKey="value" fill="#BFBFBF" radius={[3, 3, 0, 0]} barSize={3} background={false} />
                        </BarChart>}

                        {statistics.avgCompletionTimeHistory.length === 0 && <img draggable="false" className={styles.miniChart} src={emptyMiniChart} width={120} height={62} alt="" />}
                      </div>
                    </>}
                    <div>
                      <div className={styles.title}>Average Completion Time<QuestionIcon data-tip={'The time from landing on the form until user submits.'} /></div>
                      <div className={styles.number}>{statistics.avgCompletionTime || 0}s</div>
                    </div>
                  </div>

                  {expanded.avgCompletionTime && <div className={styles.chart}>
                    <ResponsiveContainer width="100%" height="100%">
                      <BarChart data={statistics.avgCompletionTimeHistory} barSize={10} barGap={2} barCategoryGap={2}>
                        <Tooltip cursor={{ fill: '#FAFAFA' }} content={<CustomTooltip end={'s'} />} />
                        <Bar dataKey="value" fill="#BFBFBF" radius={[4, 4, 0, 0]} barSize={10} background={false} />
                      </BarChart>
                    </ResponsiveContainer>
                  </div>}

                  <div className={[
                    styles.expand,
                    statistics.avgCompletionTimeHistory.length === 0 ? styles.disabled : ''
                  ].join(' ')} onClick={() => expanded.avgCompletionTime ? this.props.collapseBox('avgCompletionTime') : this.props.expandBox('avgCompletionTime')}>
                    {!expanded.avgCompletionTime && <>Expand <ExpandIcon /></>}
                    {expanded.avgCompletionTime && <>Collapse <CollapseIcon /></>}
                  </div>
                </li>
              </ul>

              <ul>
                <li className={[
                  expanded.uniqueViews ? styles.active : ''
                ].join(' ')}>
                  <div className={styles.head}>
                    {!expanded.uniqueViews && <>
                      <div>
                        {statistics.uniqueViewsHistory.length > 0 && <BarChart className={styles.miniChart} width={120} height={62} data={statistics.uniqueViewsHistory} barSize={3} barGap={2} barCategoryGap={2}>
                          <Bar dataKey="value" fill="#BFBFBF" radius={[3, 3, 0, 0]} barSize={3} background={false} />
                        </BarChart>}

                        {statistics.uniqueViewsHistory.length === 0 && <img draggable="false" className={styles.miniChart} src={emptyMiniChart} width={120} height={62} alt="" />}
                      </div>
                    </>}
                    <div>
                      <div className={styles.title}>Unique Views<QuestionIcon data-tip={'The number of the unique people that have visited your form.'} /></div>
                      <div className={styles.number}>{statistics.uniqueViews}</div>
                    </div>
                  </div>

                  {expanded.uniqueViews && <div className={styles.chart}>
                    <ResponsiveContainer width="100%" height="100%">
                      <BarChart data={statistics.uniqueViewsHistory} barSize={10} barGap={2} barCategoryGap={2}>
                        <Tooltip cursor={{ fill: '#FAFAFA' }} content={<CustomTooltip end={' views'} />} />
                        <Bar dataKey="value" fill="#BFBFBF" radius={[4, 4, 0, 0]} barSize={10} background={false} />
                      </BarChart>
                    </ResponsiveContainer>
                  </div>}

                  <div className={[
                    styles.expand,
                    statistics.uniqueViewsHistory.length === 0 ? styles.disabled : ''
                  ].join(' ')} onClick={() => expanded.uniqueViews ? this.props.collapseBox('uniqueViews') : this.props.expandBox('uniqueViews')}>
                    {!expanded.uniqueViews && <>Expand <ExpandIcon /></>}
                    {expanded.uniqueViews && <>Collapse <CollapseIcon /></>}
                  </div>
                </li>

                <li className={[
                  expanded.completionRate ? styles.active : ''
                ].join(' ')}>
                  <div className={styles.head}>
                    {!expanded.completionRate && <>
                      <div>
                        {statistics.completionRateHistory.length > 0 && <BarChart className={styles.miniChart} width={120} height={62} data={statistics.completionRateHistory} barSize={3} barGap={2} barCategoryGap={2}>
                          <Bar dataKey="value" fill="#BFBFBF" radius={[3, 3, 0, 0]} barSize={3} background={false} />
                        </BarChart>}

                        {statistics.completionRateHistory.length === 0 && <img draggable="false" className={styles.miniChart} src={emptyMiniChart} width={120} height={62} alt="" />}
                      </div>
                    </>}
                    <div>
                      <div className={styles.title}>Completion Rate<QuestionIcon data-tip={'Percentage of people that complete your form without abandoning.'} /></div>
                      <div className={styles.number}>{statistics.completionRate}%</div>
                    </div>
                  </div>

                  {expanded.completionRate && <div className={styles.chart}>
                    <ResponsiveContainer width="100%" height="100%">
                      <BarChart data={statistics.completionRateHistory} barSize={10} barGap={2} barCategoryGap={2}>
                        <Tooltip cursor={{ fill: '#FAFAFA' }} content={<CustomTooltip end={'%'} />} />
                        <Bar dataKey="value" fill="#BFBFBF" radius={[4, 4, 0, 0]} barSize={10} background={false} />
                      </BarChart>
                    </ResponsiveContainer>
                  </div>}

                  <div className={[
                    styles.expand,
                    statistics.completionRateHistory.length === 0 ? styles.disabled : ''
                  ].join(' ')} onClick={() => expanded.completionRate ? this.props.collapseBox('completionRate') : this.props.expandBox('completionRate')}>
                    {!expanded.completionRate && <>Expand <ExpandIcon /></>}
                    {expanded.completionRate && <>Collapse <CollapseIcon /></>}
                  </div>
                </li>

                <li className={[
                  expanded.abandonmentRate ? styles.active : ''
                ].join(' ')}>
                  <div className={styles.head}>
                    {!expanded.abandonmentRate && <>
                      <div>
                        {statistics.abandonmentRateHistory.length > 0 && <BarChart className={styles.miniChart} width={120} height={62} data={statistics.abandonmentRateHistory} barSize={3} barGap={2} barCategoryGap={2}>
                          <Bar dataKey="value" fill="#BFBFBF" radius={[3, 3, 0, 0]} barSize={3} background={false} />
                        </BarChart>}

                        {statistics.abandonmentRateHistory.length === 0 && <img draggable="false" className={styles.miniChart} src={emptyMiniChart} width={120} height={62} alt="" />}
                      </div>
                    </>}
                    <div>
                      <div className={styles.title}>Abandonment Rate<QuestionIcon data-tip={'Percentage of people that started filling out the form but didn’t submit.'} /></div>
                      <div className={styles.number}>{statistics.abandonmentRate}%</div>
                    </div>
                  </div>

                  {expanded.abandonmentRate && <div className={styles.chart}>
                    <ResponsiveContainer width="100%" height="100%">
                      <BarChart data={statistics.abandonmentRateHistory} barSize={10} barGap={2} barCategoryGap={2}>
                        <Tooltip cursor={{ fill: '#FAFAFA' }} content={<CustomTooltip end={'%'} />} />
                        <Bar dataKey="value" fill="#BFBFBF" radius={[4, 4, 0, 0]} barSize={10} background={false} />
                      </BarChart>
                    </ResponsiveContainer>
                  </div>}

                  <div className={[
                    styles.expand,
                    statistics.abandonmentRateHistory.length === 0 ? styles.disabled : ''
                  ].join(' ')} onClick={() => expanded.abandonmentRate ? this.props.collapseBox('abandonmentRate') : this.props.expandBox('abandonmentRate')}>
                    {!expanded.abandonmentRate && <>Expand <ExpandIcon /></>}
                    {expanded.abandonmentRate && <>Collapse <CollapseIcon /></>}
                  </div>
                </li>
              </ul>
            </div>
{/* 
            <div className={styles.card} id="field-bottlenecks">
              <div className={styles.row}>
                <div className={styles.rowLabel}>
                  <div className={styles.title}>Field Bottlenecks</div>
                  <div className={styles.subTitle}>Find where your users are getting stuck on your form, so you can receive more & higher quality replies.<br /><br />Potential bottlenecks are tracked only on required fields.</div>
                </div>

                <div className={styles.rowContent}>
                  <table className={styles.table}>
                    <thead>
                      <tr>
                        <th>Field Name</th>
                        <th>Dropoffs</th>
                      </tr>
                    </thead>
                    <tbody>
                      {fields && statistics.bottlenecks.sort((a, b) => a.count < b.count ? 1 : -1).map((bottleneck) => {
                        const field = fields.find((field) => field._id === bottleneck.field);

                        if (!field) return null;

                        return <tr key={bottleneck.field}>
                          <td>{field.label || field.placeholder || field.type}</td>
                          <td>{bottleneck.count}</td>
                        </tr>
                      })}

                      {statistics.bottlenecks.length === 0 && <tr><td colSpan="2">No bottlenecks found</td></tr>}
                    </tbody>
                  </table>
                </div>
              </div>
            </div> */}

            <div className={styles.card} id="location-overview">
              <div className={styles.row}>
                <div className={styles.rowLabel}>
                  <div className={styles.title}>Location Overview</div>
                  <div className={styles.subTitle}>An overview of the locations your form submitters are visiting your form from.</div>
                </div>

                <div className={styles.rowContent}>
                  <ul className={styles.rowContentTabs} style={{ marginBottom: 20 }}>
                    <li onClick={() => this.props.updateTab('country', 'table')} className={tabs.country === 'table' ? styles.active : ''}>Overview</li>
                    <li onClick={() => this.props.updateTab('country', 'map')} className={tabs.country === 'map' ? styles.active : ''}>Map View</li>
                  </ul>

                  {tabs.country === 'map' && <ComposableMap projectionConfig={{ scale: 165, rotation: [-11, 0, 0] }} style={{ width: '100%', height: 'auto' }}>
                    <ZoomableGroup center={[0, 20]} disablePanning={true}>
                      <Geographies geography={mapFile}>
                        {(geographies, projection) => geographies.map((geography, i) => {
                          const countryIso = geography.properties.ISO_A2;
                          const countryObject = statistics.countries.find((country) => country.name === countryIso);

                          return <Geography key={i}
                            geography={geography}
                            projection={projection}
                            onClick={this.handleClick}
                            style={{
                              default: {
                                fill: typeof countryObject !== 'undefined' ? popScale(countryObject.count || 0) : '#FAFAFA',
                                stroke: '#949494',
                                strokeWidth: 0.75,
                                outline: 'none',
                              },
                              hover: {
                                fill: typeof countryObject !== 'undefined' ? popScale(countryObject.count || 0) : '#FAFAFA',
                                stroke: '#000000',
                                strokeWidth: 0.75,
                                outline: 'none',
                              },
                              pressed: {
                                fill: typeof countryObject !== 'undefined' ? popScale(countryObject.count || 0) : '#FAFAFA',
                                stroke: '#000000',
                                strokeWidth: 0.75,
                                outline: 'none',
                              }
                            }} />
                        })}
                      </Geographies>
                    </ZoomableGroup>
                  </ComposableMap>}

                  {tabs.country === 'table' && <table className={styles.table}>
                    <thead>
                      <tr>
                        <th>Country</th>
                        <th>View Count</th>
                      </tr>
                    </thead>
                    <tbody>
                      {fields && statistics.countries.sort((a, b) => a.count < b.count ? 1 : -1).map((country) => <tr key={country.name}>
                        <td>{getName(country.name).replace('Taiwan, Province of China', 'Taiwan')}</td>
                        <td>{country.count}</td>
                      </tr>)}

                      {statistics.countries.length === 0 && <tr><td colSpan="2">No results</td></tr>}
                    </tbody>
                  </table>}
                </div>
              </div>
            </div>

            <div className={styles.card} style={{ padding: '40px 40px 0 40px' }} id="device-browser-types">
              <div className={styles.row}>
                <div className={styles.rowLabel}>
                  <div className={styles.title}>Operating Systems & Browser Types</div>
                  <div className={styles.subTitle}>An overview of each operating system and browser type used to view your form.</div>
                </div>

                <div className={styles.rowContent}>
                  <ul className={styles.rowContentTabs}>
                    <li onClick={() => this.props.updateTab('operatingSystemsAndBrowserTypes', 'os')} className={tabs.operatingSystemsAndBrowserTypes === 'os' ? styles.active : ''}>Operating System</li>
                    <li onClick={() => this.props.updateTab('operatingSystemsAndBrowserTypes', 'browser')} className={tabs.operatingSystemsAndBrowserTypes === 'browser' ? styles.active : ''}>Browser</li>
                  </ul>

                  <div className={styles.rowContentChart}>
                    {tabs.operatingSystemsAndBrowserTypes === 'browser' && <>
                      {statistics.browsers.length > 0 && <PieChart width={400} height={300}>
                        <Pie dataKey="count" isAnimationActive={false} data={statistics.browsers} cx={200} cy={150} innerRadius={60} outerRadius={80} label={(d) => `${d.value} ${d.name}`}>
                          {statistics.browsers.map((entry, index) => <Cell key={index} fill={COLORS[index]} />)}
                        </Pie>
                      </PieChart>}

                      {statistics.browsers.length === 0 && <span>No results</span>}
                    </>}

                    {tabs.operatingSystemsAndBrowserTypes === 'os' && <>
                      {statistics.operatingSystems.length > 0 && <PieChart width={400} height={300}>
                        <Pie dataKey="count" isAnimationActive={false} data={statistics.operatingSystems} cx={200} cy={150} innerRadius={60} outerRadius={80} label={(d) => `${d.value} ${d.name}`}>
                          {statistics.operatingSystems.map((entry, index) => <Cell key={index} fill={COLORS[index]} />)}
                        </Pie>
                      </PieChart>}

                      {statistics.operatingSystems.length === 0 && <span>No results</span>}
                    </>}
                  </div>
                </div>
              </div>
            </div>
          </>}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.statistics.loading,
    statistics: state.statistics.statistics,
    expanded: state.statistics.expanded,
    tabs: state.statistics.tabs,
    fields: state.builder.fields
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadStatistics: () => dispatch(loadStatistics()),
    getFields: () => dispatch(getFields()),
    expandBox: (id) => dispatch(expandBox(id)),
    collapseBox: (id) => dispatch(collapseBox(id)),
    updateTab: (id, value) => dispatch(updateTab(id, value)),
    clear: () => dispatch(clear())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Statistics);
