import styles from './AddWebhookModal.module.css';

import React, { Component } from 'react';
import { connect } from 'react-redux';
import validator from 'validator';

import {
  hideAddWebhookModal, updateWebhookForm, createWebhook
} from 'store/ducks/settings.js';

import {
  getForm
} from 'store/ducks/builder.js';

import { Button, Modal, Loader, FormRow, TextInput } from 'ui';

class AddWebhookModal extends Component {
  cancel = () => {
    this.props.hideAddWebhookModal();
  }

  add = () => {
    this.props.createWebhook().then((res) => {
      const success = res.type.search('SUCCESS') !== -1;

      if (success) this.props.getForm().then(this.cancel());
    });
  }

  isDisabled = () => {
    return this.props.loading || !validator.isURL(this.props.url, {
      protocols: ['http', 'https'],
      require_tld: true,
      require_protocol: false,
      require_host: true,
      require_valid_protocol: true,
      allow_underscores: true,
      host_whitelist: false,
      host_blacklist: false,
      allow_trailing_dot: false,
      allow_protocol_relative_urls: false,
      disallow_auth: false
    });
  }

  render() {
    const {
      show, url, loading
    } = this.props;

    return (
      <Modal isOpen={show} onRequestClose={this.cancel} width={450} header="Adding a webhook" footer={<>
        <Button size="small" theme="white" onClick={this.cancel} margin="0 15px 0 0">Cancel</Button>
        <Button size="small" theme="blue" onClick={this.add} disabled={this.isDisabled()}>Add Webhook</Button>
      </>}>
        <div className={styles.content}>
          {loading && <Loader size={30} />}

          {!loading && <>
            <FormRow label="Endpoint" className={styles.row}>
              <TextInput value={url} width={'100%'} placeholder="https://..." onChange={(e) => this.props.updateWebhookForm('url', e.target.value)} />
            </FormRow>
          </>}
        </div>
      </Modal>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    show: state.settings.showAddWebhookModal,
    url: state.settings.addWebhookForm.url,
    loading: state.settings.addWebhookFormLoading
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    hideAddWebhookModal: () => dispatch(hideAddWebhookModal()),
    createWebhook: () => dispatch(createWebhook()),
    getForm: () => dispatch(getForm()),
    updateWebhookForm: (key, value) => dispatch(updateWebhookForm(key, value))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddWebhookModal);
