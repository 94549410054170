import styles from './SettingsNotifications.module.css';

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import debounce from 'lodash.debounce';
import getFieldLabel from 'helpers/getFieldLabel.js';
import { v4 as uuid } from 'uuid';
import ReactTooltip from 'react-tooltip';
import Logic from 'components/Builder/shared/Logic/Logic.js';
import LogicSummary from 'components/Builder/shared/LogicSummary/LogicSummary.js';

import {
  ioUpdateForm, updateForm
} from 'store/ducks/builder.js';

import {
  getUsers
} from 'store/ducks/_users.js';

import { Checkbox, Button, TextInput, Toggle, Select, Toggle2, MentionsInput } from 'ui';

import { ReactComponent as ReferToIcon } from 'assets/images/refer-to.svg';
import { ReactComponent as LogicIcon } from 'assets/images/logic.svg';
import { ReactComponent as EmailNotificationsIcon } from 'assets/images/email-notifications.svg';

const fieldOptionLabel = (field, columnOptions) => {
  if (!field) return null;

  return <div style={{
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%'
  }}>
    <div style={{
      backgroundColor: columnOptions[field.type].backgroundColor,
      width: 22,
      height: 22,
      marginRight: 10,
      borderRadius: 2,
      boxSizing: 'border-box',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }}>
      <img src={columnOptions[field.type].icon} width="15px" alt="" />
    </div>
    <div style={{
      width: 'calc(100% - 48px)',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap'
    }}>{getFieldLabel(field.label || field.placeholder)}</div>
  </div>;
};

const Notifications = ({ name, conditionsType, conditions, calculationConditions, id, users, index, expanded, onEdit, onChange, onRemove, notifications, fields, values, notificationsUsers, fieldSelectOptions }) => {
  const update = (obj, delay) => {
    const notificationsCopy = [...notifications];
    let updated = {};

    for (let [key, value] of Object.entries(obj)) {
      updated[key] = value;
    }

    notificationsCopy[index] = { ...notificationsCopy[index], ...updated };

    onChange({ notifications: notificationsCopy }, delay);
  };

  const toggleNotificationsUser = (id) => {
    const usersCopy = [...users];
    const index = usersCopy.indexOf(id);

    if (index === -1) {
      usersCopy.push(id);
    } else {
      usersCopy.splice(index, 1);
    }

    update({
      users: usersCopy
    }, false);
  };

  const toggleLogic = () => {
    if (conditions.length === 0) {
      update({
        conditions: [{
          triggerField: null,
          type: 'equal',
          value: null
        }]
      }, false);
    } else {
      update({
        conditions: [],
        calculationConditions: []
      }, false);
    }
  };

  return <>
    <div className={styles.notification}>
      <div className={styles.top}>
        <div className={styles.left}>
          <div className={styles.label}>
            {name}
          </div>
          <div className={styles.action}>{users.length} user{users.length === 1 ? '' : 's'}</div>
        </div>
        <div className={styles.right}>
          {!expanded && <Button theme="white" onClick={() => onEdit(id)}>Edit</Button>}
          {expanded && <Button theme="white" onClick={() => onEdit(null)}>Close</Button>}
          <Button theme="red" onClick={() => onRemove(index)}>Remove</Button>
        </div>
      </div>
      <div className={styles.bottom}>
        {!expanded && <div className={styles.logic}>
          <LogicIcon />
          <LogicSummary
            conditionsType={conditionsType}
            fieldConditions={conditions}
            calculationConditions={calculationConditions}
            values={values}
            fields={fields}
            prefixText="Send a notification when"
            emptyMessage="Send a notification after every submission."
          />
        </div>}

        {expanded && <div className={styles.expanded}>
          <div className={styles.row}>
            <div className={styles.rowLabel}>
              <div className={styles.title}>Notification name</div>
              <div className={styles.subTitle}>The name of this notification variation. This is only visible to you so you can identify the different notification.</div>
            </div>

            <div className={styles.rowContent}>
              <TextInput type="text" value={name} width="60%" onChange={(e) => update({
                name: e.target.value
              }, false)} />
            </div>
          </div>

          <div className={styles.row}>
            <div className={styles.rowLabel}>
              <div className={styles.title}>Who should receive this notification email?</div>
              <div className={styles.subTitle}>Assign one or multiple users that will receive notification emails for this form.</div>
            </div>

            <div className={styles.rowContent}>
              <ul className={styles.users}>
                {notificationsUsers.map((user) => <li key={user._id}>
                  <Checkbox label={user.email} value={users.indexOf(user._id) !== -1} onChange={() => { }} onClick={() => toggleNotificationsUser(user._id)} />
                </li>)}
              </ul>
            </div>
          </div>

          <div className={styles.row}>
            <div className={styles.rowLabel}>
              <div className={styles.title} onClick={() => toggleLogic()}>
                <Toggle checked={(calculationConditions || []).length + (conditions || []).length > 0 && (fieldSelectOptions || []).length > 0} readOnly={true} onChange={() => { }} />
                <span>Logic Rules</span>
              </div>
              <div className={styles.subTitle}>These logic rules need to be triggered in order to send your email notification.</div>
            </div>

            <div className={styles.rowContent}>
              <Logic conditionsType={conditionsType}
                fieldConditions={conditions}
                calculationConditions={calculationConditions}
                onChange={update}
                conditionTypePrefix="Send this notification email when"
                alwaysOne={false} />
            </div>
          </div>
        </div>}
      </div>
    </div>
  </>;
};

const SettingsNotifications = () => {
  const dispatch = useDispatch();

  const form = useSelector(state => state.builder.form);
  const users = useSelector(state => state._users.users);
  const columnOptions = useSelector(state => state.results.columnOptions);
  const values = useSelector(state => state.builder.values);
  const calculationVariables = useSelector(state => state.builder.form.calculationVariables);

  const [expandedPage, setExpandedPage] = useState(null);

  const notificationsUsers = users.filter((u) => {
    if (!form.workspace._id) return true;

    return u.role !== 'user' || (form.workspace.users || []).findIndex((wu) => wu.user === u._id && wu.read) !== -1;
  });

  const emailFieldsOptions = form.fields.filter((f) => f.type === 'shortText' && f.format === 'email');
  const fieldSelectOptions = form.fields.filter((f) => ['radio', 'checkbox', 'dropdown', 'imageChoice', 'scale'].indexOf(f.type) !== -1);

  useEffect(() => {
    dispatch(getUsers());
  }, []);

  const addEmptyNotification = () => {
    const notificationsCopy = [...form.notifications];
    const ref = uuid();

    notificationsCopy.push({
      ref,
      name: `Email Notification #${form.notifications.length + 1}`,
      conditionsType: 'all',
      users: [],
      conditions: [],
      calculationConditions: []
    });

    handleChange({
      notifications: notificationsCopy
    });

    setExpandedPage(ref);
  };

  const ioUpdateFormWithDelay = debounce((params) => {
    dispatch(ioUpdateForm(params));
  }, 500);

  const handleChange = (params, delay = false) => {
    dispatch(updateForm(params));

    if (delay) {
      ioUpdateFormWithDelay(params);
    } else {
      dispatch(ioUpdateForm(params));
    }
  };

  const handleEdit = (value) => setExpandedPage(value);

  const handleRemove = (index) => {
    const notificationsCopy = [...form.notifications];

    notificationsCopy.splice(index, 1);

    handleChange({
      notifications: notificationsCopy
    }, false);
  };

  const toggleConfirmationEmail = () => {
    const options = form.fields.filter((f) => f.type === 'shortText' && f.format === 'email');

    const param = {
      confirmationEmail: !form.confirmationEmail
    };

    if (!param.confirmationEmail) {
      param.confirmationEmailField = null;
    } else if (options.length === 1) {
      param.confirmationEmailField = options[0]._id;
    } else {
      param.confirmationEmailField = null;
    }

    handleChange(param, false);
  }

  let mentionsData = [];

  mentionsData = form.fields.filter((f) => ['longText', 'shortText', 'scale', 'radio', 'checkbox', 'dropdown'].indexOf(f.type) !== -1).map((f) => ({
    _id: f._id,
    type: f.type,
    format: f.format,
    label: f.label
  }));

  if (calculationVariables && calculationVariables.length !== 0) mentionsData = [...mentionsData, ...calculationVariables.map((variable) => ({
    _id: variable._id,
    type: 'variable',
    format: null,
    label: variable.name
  }))];

  return <>
    <div className={styles.card}>

      {form.notifications.length !== 0 && <>
        <div className={styles.cardLabel}>
          <div>
            <h2>Notification Emails</h2>
            <p>Notification emails are sent to one or multiple account users when a new response has been submitted on this form.</p>
          </div>

          <Button theme="blue" onClick={addEmptyNotification} margin="0 0 40px 0">Add another Email Notification</Button>
        </div>

        {form.notifications.map((notification, index) => {
          return <React.Fragment key={notification.ref}>
            <Notifications name={notification.name}
              conditionsType={notification.conditionsType}
              notificationsUsers={notificationsUsers}
              conditions={notification.conditions}
              notifications={form.notifications}
              fieldSelectOptions={fieldSelectOptions}
              calculationConditions={notification.calculationConditions}
              id={notification.ref}
              users={notification.users}
              index={index}
              expanded={expandedPage === notification.ref}
              values={values}
              fields={form.fields}
              onEdit={handleEdit}
              onChange={handleChange}
              onRemove={handleRemove} />
          </React.Fragment>;
        })}
      </>}

      {form.notifications.length === 0 && <div className={styles.empty}>
        <EmailNotificationsIcon />
        <span>Start sending Notification Emails when a new response comes in.</span>
        <p>Notification emails are sent to one or multiple account users when a new response has been submitted on this form.</p>
        <Button theme="blue" onClick={addEmptyNotification}>Create a Email Notification</Button>
      </div>}
    </div>

    <div className={styles.card}>
      <div className={styles.cardLabel}>
        <h2>Respondent Notification</h2>
      </div>

      <div className={[styles.row, styles.notifications].join(' ')}>
        <div className={styles.rowLabel}>
          <div className={styles.title} onClick={() => toggleConfirmationEmail()} >
            <Toggle checked={form.confirmationEmail} readOnly={true} onChange={() => { }} />
            <span>Respondent Notifications</span>
          </div>
          <div className={styles.subTitle}>Respondent Notifications are sent to the respondent after they have submit the form.</div>
          <div className={styles.subTitle}>The default respondent notification email contains everything the respondent has submitted on the form.</div>
        </div>

        <div className={styles.rowContent}>
          <div className={[styles.rowContentTitle, (!form.confirmationEmail || emailFieldsOptions.length === 0) ? styles.disabled : ''].join(' ')} style={{ margin: '0 0 10px 0' }}>Send confirmation email to</div>

          <Select width={350} disabled={!form.confirmationEmail || emailFieldsOptions.length === 0} options={emailFieldsOptions.map((f) => {
            return { label: fieldOptionLabel(f, columnOptions) || f.placeholder, value: f._id };
          })} value={{ label: (!form.confirmationEmailField || emailFieldsOptions.length === 0) ? '- Select -' : fieldOptionLabel(emailFieldsOptions.find((ff) => ff._id === form.confirmationEmailField), columnOptions), value: form.confirmationEmailField }} onChange={(selected) => handleChange({ confirmationEmailField: selected.value }, true)} />
          {emailFieldsOptions.length === 0 && <div className={styles.rowContentDescription}>You must add an email field to your form to send confirmation emails.</div>}
          {emailFieldsOptions.length > 0 && <div className={styles.rowContentDescription}>Email from selected field will receive the notification message.</div>}

          <Toggle2 disabled={!form.confirmationEmail || emailFieldsOptions.length === 0} value={form.confirmationEmailCustomContent} style={{ margin: '20px 0 0 0' }} onClick={() => handleChange({ confirmationEmailCustomContent: !form.confirmationEmailCustomContent }, true)}>Send a customized respondent email</Toggle2>

          {form.confirmationEmailCustomContent && <>
            <div className={[styles.rowContentTitle, (!form.confirmationEmail || emailFieldsOptions.length === 0) ? styles.disabled : ''].join(' ')} style={{ margin: '15px 0 10px 0' }}>
              <div>Email subject</div>
              <a href="https://support.questionscout.com/en/articles/3947231-how-to-use-refer-to" target="_blank" rel="noopener noreferrer"><ReferToIcon data-tip="Refer to is available on this input, click to learn more." /></a>
            </div>
            
            <MentionsInput menu="tooltip"
              disabled={!form.confirmationEmail || emailFieldsOptions.length === 0}
              initialValue={form.confirmationEmailSubject || ''}
              onChange={(value) => handleChange({ confirmationEmailSubject: value })}
              data={mentionsData} />

            <div className={[styles.rowContentTitle, (!form.confirmationEmail || emailFieldsOptions.length === 0) ? styles.disabled : ''].join(' ')} style={{ margin: '15px 0 10px 0' }}>
              <div>Email content</div>
              <a href="https://support.questionscout.com/en/articles/3947231-how-to-use-refer-to" target="_blank" rel="noopener noreferrer"><ReferToIcon data-tip="Refer to is available on this input, click to learn more." /></a>
            </div>

            <MentionsInput menu="tooltip"
              disabled={!form.confirmationEmail || emailFieldsOptions.length === 0}
              initialValue={form.confirmationEmailContent || ''}
              onChange={(value) => handleChange({ confirmationEmailContent: value })}
              data={mentionsData} />
          </>}
        </div>
      </div>
    </div>

    <ReactTooltip place="left" effect="solid" />
  </>;
}

export default SettingsNotifications;
