import React, { useEffect } from 'react';
import { withRouter } from 'react-router';

import {
  updateLocationWithIo
} from 'store/ducks/main.js';

import { useDispatch } from 'react-redux';

const RouterWatch = ({ history }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const path = history.location.pathname;

    if (/^\/themes/.test(path) || /^\/builder/.test(path)) {
      dispatch(updateLocationWithIo(true));
    } else {
      dispatch(updateLocationWithIo(false));
    }
  }, [history.location.key])


  return null;
}

export default withRouter(RouterWatch);
