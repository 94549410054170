import styles from './Builder.module.css';

import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';

import {
  updateFormId, clear, builderLoaded, getForm, getFields, getTheme
} from 'store/ducks/builder.js';

import {
  getUser
} from 'store/ducks/_users.js';

import {
  getThemes
} from 'store/ducks/_themes.js';

import BuilderHeader from './BuilderHeader';
import Build from './Build/Build';
import Logic from './Logic/Logic';
import Publish from './Publish/Publish';
import Design from './Design/Design';
import Settings from './Settings/Settings';
import Statistics from './Statistics/Statistics';
import Results from './Results/Results';
import Calculations from './Calculations/Calculations';

import { Loader } from 'ui';

class Builder extends Component {
  constructor(props) {
    super(props);

    let userPromise;

    if (props.user.role === 'user') { // refresh user permissions
      userPromise = this.props.getUser();
    } else {
      userPromise = Promise.resolve();
    }

    userPromise.then(() => this.props.updateFormId(this.props.match.params.id)).then(() => Promise.all([
      this.props.getForm(this.props.match.params.id),
      this.props.getFields(this.props.match.params.id),
      // this.props.getTheme(this.props.match.params.id), // remove after themes v2 deployed
      this.props.match.params.page === 'design' ? this.props.getThemes() : Promise.resolve()
    ])).then(() => {
      this.props.builderLoaded();

      if (this.props.match.params.page !== 'design') {
        this.props.getThemes();
      }
    });
  }

  UNSAFE_componentWillMount = () => {
    document.body.style.overflowY = 'hidden';
  }

  componentWillUnmount = () => {
    document.body.style.overflowY = 'auto';

    this.props.clear();
  }

  render() {
    const {
      ready, permissions, user
    } = this.props;

    const {
      tab
    } = this.props.match.params;

    if (!ready || !permissions) return <Loader size={100} />;

    return (
      <div className={styles.main} style={{
        backgroundColor: tab ? '#FFFCFA' : '#F9F5F1'
      }}>
        <Route path="/builder/:id/:page?/:tab?" component={BuilderHeader} />

        <div className={styles.content}>
          <Switch>
            {permissions.edit && <Route path="/builder/:id/build" component={Build} />}
            {permissions.edit && <Route path="/builder/:id/logic" component={Logic} />}
            {permissions.edit && <Route path="/builder/:id/design" component={Design} />}
            {permissions.edit && <Route path="/builder/:id/publish/:tab?" component={Publish} />}
            {permissions.edit && <Route path="/builder/:id/settings/:tab?/:subPage?" component={Settings} />}
            {permissions.read && <Route path="/builder/:id/statistics" component={Statistics} />}
            {permissions.read && <Route path="/builder/:id/results/:tab" component={Results} />}
            {permissions.read && <Route path="/builder/:id/calculations" component={Calculations} />}
          </Switch>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    ready: state.builder.ready,
    user: state._users.user,
    permissions: state.builder.form.permissions
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUser: () => dispatch(getUser()),
    clear: () => dispatch(clear()),
    builderLoaded: () => dispatch(builderLoaded()),
    updateFormId: (id) => dispatch(updateFormId(id)),
    getForm: () => dispatch(getForm()),
    getFields: () => dispatch(getFields()),
    getTheme: () => dispatch(getTheme()), // remove after themes v2 deployed
    getThemes: () => dispatch(getThemes())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Builder);
