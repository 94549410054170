/** @jsx jsx */
/* @jsxFrag React.Fragment */

import { jsx, css } from '@emotion/core';
import React from 'react';

import Title from '../fields/Title.js';
import Description from '../fields/Description.js';
import Signature from '../fields/Signature.js';
import DateTime from '../fields/DateTime.js';
import ShortText from '../fields/ShortText.js';
import Dropdown from '../fields/Dropdown.js';
import LongText from '../fields/LongText.js';
import Radio from '../fields/Radio.js';
import Checkbox from '../fields/Checkbox.js';
import Image from '../fields/Image.js';
import Scale from '../fields/Scale.js';
import FileUpload from '../fields/FileUpload.js';
import ImageChoice from '../fields/ImageChoice.js';
import Section from '../fields/Section.js';
import PageBreak from '../fields/PageBreak.js';
import Divider from '../fields/Divider.js';

import ConversationalTopHelper from './ConversationalTopHelper.js';
import ConversationalBottomHelper from './ConversationalBottomHelper.js';

const ColumnsWrapper = ({ field, children }) => {
  if (field.type !== 'section') return children;
  if (!field.columns || field.columns === 1) return children;

  const styles = {
    main: css({
      display: 'grid',
      alignItems: 'center',
      gridGap: '1rem',
      alignItems: 'flex-start',
      gridTemplateColumns: field.columnSizes.map((size, index) => index !== field.columnSizes.length -1 ? `calc(${size}% - 1rem)` : '1fr').join(' '),
      '@media (max-width: 600px)': {
        gridTemplateColumns: `repeat(1, 100%)`
      }
    })
  };

  return <div css={styles.main}>
    {children}
  </div>;
};

const FormWrapper = (props) => {
  const {
    form, fields, values, styles, theme, device, mode, seed, variables, fieldsOptions, isTouchDevice,
    waitingForSubmissionId, handlePartialResponses, handleOnNextClick, handleOnPrevClick, submitIsDisabled,
    handleSubmit, handleChange, country, handleNextPage, handlePreviousPage,
    handleUpload, handleDelete, handleCancel, files
  } = props;

  const fieldsList = (field) => <>
    {field && field.type === 'shortText' && <ShortText field={field} form={form} fieldsOptions={fieldsOptions} variables={variables} theme={theme} onChange={handleChange} value={values[field._id].value} values={values} onPartialResponse={handlePartialResponses} country={country} owner={form.owner} />}
    {field && field.type === 'longText' && <LongText field={field} form={form} fieldsOptions={fieldsOptions} variables={variables} theme={theme} onChange={handleChange} value={values[field._id].value} values={values} onPartialResponse={handlePartialResponses} />}
    {field && field.type === 'dropdown' && <Dropdown field={field} form={form} fieldsOptions={fieldsOptions} variables={variables} theme={theme} seed={seed} onChange={handleChange} value={values[field._id].value} values={values} onPartialResponse={handlePartialResponses} onNextClick={handleOnNextClick} />}
    {field && field.type === 'radio' && <Radio field={field} form={form} fieldsOptions={fieldsOptions} variables={variables} theme={theme} seed={seed} onChange={handleChange} value={values[field._id].value} values={values} onPartialResponse={handlePartialResponses} onNextClick={handleOnNextClick} />}
    {field && field.type === 'checkbox' && <Checkbox field={field} form={form} fieldsOptions={fieldsOptions} variables={variables} theme={theme} seed={seed} onChange={handleChange} value={values[field._id].value} values={values} onPartialResponse={handlePartialResponses} onNextClick={handleOnNextClick} />}
    {field && field.type === 'imageChoice' && <ImageChoice field={field} form={form} fieldsOptions={fieldsOptions} variables={variables} theme={theme} seed={seed} onChange={handleChange} value={values[field._id].value} values={values} onPartialResponse={handlePartialResponses} mode={mode} device={device} onNextClick={handleOnNextClick} />}
    {field && field.type === 'datetime' && <DateTime field={field} form={form} fieldsOptions={fieldsOptions} variables={variables} theme={theme} onChange={handleChange} value={values[field._id]} values={values} onPartialResponse={handlePartialResponses} />}
    {field && field.type === 'fileUpload' && <FileUpload field={field} form={form} fieldsOptions={fieldsOptions} variables={variables} theme={theme} files={files.filter((file) => file.field === field._id)} handleUpload={handleUpload} handleDelete={handleDelete} handleCancel={handleCancel} value={values[field._id].value} values={values} />}
    {field && field.type === 'signature' && <Signature field={field} form={form} fieldsOptions={fieldsOptions} variables={variables} theme={theme} onChange={handleChange} value={values[field._id].value} values={values} onPartialResponse={handlePartialResponses} />}
    {field && field.type === 'title' && <Title field={field} form={form} fieldsOptions={fieldsOptions} variables={variables} theme={theme} onChange={handleChange} value={values[field._id].value} values={values} />}
    {field && field.type === 'description' && <Description field={field} form={form} fieldsOptions={fieldsOptions} variables={variables} theme={theme} onChange={handleChange} value={values[field._id].value} values={values} />}
    {field && field.type === 'image' && <Image field={field} form={form} fieldsOptions={fieldsOptions} variables={variables} theme={theme} onChange={handleChange} value={values[field._id].value} values={values} />}
    {field && field.type === 'scale' && <Scale field={field} form={form} fieldsOptions={fieldsOptions} variables={variables} theme={theme} onChange={handleChange} value={values[field._id].value} values={values} onPartialResponse={handlePartialResponses} onNextClick={handleOnNextClick} />}
    
    {field && field.type === 'section' && <Section {...props} field={field} fields={form.fields.filter((f) => f.section === field._id)} />}

    {field && field.type === 'pageBreak' && form.type === 'classic' && <PageBreak field={field} form={form} fieldsOptions={fieldsOptions} variables={variables} theme={theme} disabled={submitIsDisabled()} onNextPage={handleNextPage} onPreviousPage={handlePreviousPage} />}
    {field && field.type === 'divider' && form.type === 'classic' && <Divider field={field} form={form} fieldsOptions={fieldsOptions} variables={variables} theme={theme} onChange={handleChange} value={values[field._id].value} values={values} />}
  </>;

  const conversationalContent = (field, index) => {
    styles.field = {
      display: !values[field._id].visible ? 'none' : 'table',
      opacity: field.opacity || 1,
      transition: 'opacity 0.4s ease-in-out',
      boxSizing: 'border-box',
      overscrollBehavior: 'none'
    };

    return <div key={field.ref} style={styles.field} id={field._id} data-anchor={field._id} data-hidden={field.hidden || !values[field._id].visible} className="section">
      <div css={styles.conversationalFieldMain}>
        <div css={styles.conversationalFieldContent} className="sectionContent">
          <ConversationalTopHelper theme={theme} onPrevClick={handleOnPrevClick} messages={form.messages} fieldIndex={index} />

          <ColumnsWrapper field={field}>{fieldsList(field)}</ColumnsWrapper>

          <ConversationalBottomHelper field={field} theme={theme} form={form} values={values} value={values[field._id]} isTouchDevice={isTouchDevice} onNextClick={handleOnNextClick} messages={form.messages} submitLoading={waitingForSubmissionId} submitDisabled={submitIsDisabled() || waitingForSubmissionId} onSubmit={handleSubmit} />
        </div>
      </div>
    </div>;
  };

  const classicContent = (field) => {
    styles.field = css({
      display: field.hidden ? 'none' : 'block',
      opacity: field.opacity || 1,
      transition: 'opacity .3s ease-in-out'
    });

    return <div key={field._id} css={styles.field} id={field._id}>
      <ColumnsWrapper field={field}>{fieldsList(field)}</ColumnsWrapper>
    </div>;
  };

  return <>
    {form.type === 'conversational' && fields && fields.filter((f) => values && values[f._id] && !values[f._id].hidden).map((field, index) => {
      if (field.section === 'root' || !field.section) {
        return conversationalContent(field, index);
      } else {
        return classicContent(field);
      }
    })}

    {form.type === 'classic' && fields && fields.filter((f) => values && values[f._id] && values[f._id].visible).map((field) => {
      if ((field.section === 'root' || !field.section) && field.page !== form.page) return <></>;

      return classicContent(field);
    })}
  </>;
};

export default FormWrapper;
