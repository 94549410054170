import styles from './Results.module.css';

import React, { Component } from 'react';
import { Route, Switch, Link } from 'react-router-dom';
import { connect } from 'react-redux';

import Summary from './Summary/Summary';
import Responses from './Responses/Responses';

class Results extends Component {
  render() {
    return (
      <div className={styles.main}>
        <>
          <Switch>
            <Route path="/builder/:id/results/summary" component={Summary} />
            <Route path="/builder/:id/results/responses" component={Responses} />
          </Switch>
        </>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {

  };
};

const mapDispatchToProps = (dispatch) => {
  return {

  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Results);
