import styles from './Publish.module.css';

import config from 'config.js';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TextArea, TextInput, Radio, Select } from 'ui';
import { getUrlWithParameters } from 'misc/helpers.js';

import {
  updateEmbedSettings
} from 'store/ducks/builder.js';

const sizeTypeOptions = [
  { label: 'px', value: 'px' },
  { label: '%', value: '%' }
];

const Publish = () => {
  const dispatch = useDispatch();

  const formType = useSelector(state => state.builder.form.type);
  const formId = useSelector(state => state.builder.formId);
  const embedSettings = useSelector(state => state.builder.embedSettings);
  const urlParams = useSelector(state => state.builder.form.urlParams);

  const [embedType, setEmbedType] = useState(formType === 'conversational' ? 'full' : 'responsive');
  const [widthType, setWidthType] = useState('px');
  const [heightType, setHeightType] = useState('px');

  let urlParamsWithoutIds = JSON.parse(JSON.stringify(urlParams || []));

  urlParamsWithoutIds.map((u) => {
    delete u._id
    return u;
  });

  const randomId = Math.random().toString(36).replace(/[^a-z]+/g, '') + Math.random().toString(36).replace(/[^a-z]+/g, '');

  const codes = {
    popup: `<a id="${randomId}-btn" href="${getUrlWithParameters(`${config.formUrl}/${formId}`, urlParams)}" target="_blank">Open Form</a>

<script id="${randomId}">(function(a, b, c, e, f) {var s = a.createElement('script');s.src = b;s.setAttribute('data-form-id', e);s.setAttribute('data-runner-id', c);s.setAttribute('data-url-params', f);s.setAttribute('data-button', true);a.head.appendChild(s);})(window.document, '${config.formUrl}/qs-form-script.min.js', '${randomId}', '${formId}', '${JSON.stringify(urlParamsWithoutIds)}');</script>`,
    full: `<script id="${randomId}">(function(a, b, c, e, f) {var s = a.createElement('script');s.src = b;s.setAttribute('data-form-id', e);s.setAttribute('data-runner-id', c);s.setAttribute('data-url-params', f);s.setAttribute('data-size', 'full');a.head.appendChild(s);})(window.document, '${config.formUrl}/qs-form-script.min.js', '${randomId}', '${formId}', '${JSON.stringify(urlParamsWithoutIds)}');</script>`,
    responsive: `<script id="${randomId}">(function(a, b, c, e, f) {var s = a.createElement('script');s.src = b;s.setAttribute('data-form-id', e);s.setAttribute('data-runner-id', c);s.setAttribute('data-url-params', f);s.setAttribute('data-scale', true);a.head.appendChild(s);})(window.document, '${config.formUrl}/qs-form-script.min.js', '${randomId}', '${formId}', '${JSON.stringify(urlParamsWithoutIds)}');</script>`,
    fixed: `<script id="${randomId}">(function(a, b, c, e, f) {var s = a.createElement('script');s.src = b;s.setAttribute('data-form-id', e);s.setAttribute('data-runner-id', c);s.setAttribute('data-url-params', f);s.setAttribute('data-dimensions', '["${embedSettings.width}${widthType}", "${embedSettings.height}${heightType}"]');a.head.appendChild(s);})(window.document, '${config.formUrl}/qs-form-script.min.js', '${randomId}', '${formId}', '${JSON.stringify(urlParamsWithoutIds)}');</script>`
  };

  return (
    <div id="embed">
      <div className={styles.card}>
        <div className={styles.title}>Embed Code</div>
        <div className={styles.subTitle}>Please this snippet of code where you’d like the form to appear on your site. Please make sure it’s contained within the <strong>body</strong> tag.</div>

        {formType === 'classic' && <Radio label="Responsive" value={embedType === 'responsive'} onChange={() => { }} onClick={() => setEmbedType('responsive')} tooltip="The form will scale to the width of the container it’s placed in. The height will always scale to the maximum height of the form." />}
        <Radio label="Full page" value={embedType === 'full'} onChange={() => { }} onClick={() => setEmbedType('full')} tooltip="The form will take up an entire page." />
        <Radio label="Fixed size" value={embedType === 'fixed'} onChange={() => { }} onClick={() => setEmbedType('fixed')} tooltip="The form size will be fixed to the size you select." />
        <Radio label="Popup" value={embedType === 'popup'} onChange={() => { }} onClick={() => setEmbedType('popup')} tooltip="The form will launch in popup window when user clicks the button." />

        {embedType === 'fixed' && <div className={[
          styles.embedSizePx,
          embedType === 'fixed' ? '' : styles.disabled
        ].join(' ')}>
          <div>
            <div className={styles.embedSizePxTitle}>Width</div>
            <div className={styles.size}>
              <TextInput width={120} value={embedSettings.width} onChange={(e) => dispatch(updateEmbedSettings({
                width: e.target.value, sizeType: 'fixed'
              }))} />
              <Select width={65} options={sizeTypeOptions} margin="0 0 0 3px" value={
                { label: sizeTypeOptions.find((option) => option.value === widthType).label, value: widthType }
              } onChange={(selected) => setWidthType(selected.value)} />
            </div>
          </div>

          <div>
            <div className={styles.embedSizePxTitle}>Height</div>
            <div className={styles.size}>
              <TextInput width={120} value={embedSettings.height} onChange={(e) => dispatch(updateEmbedSettings({
                height: e.target.value, sizeType: 'fixed'
              }))} />
              <Select width={65} options={sizeTypeOptions} margin="0 0 0 3px" value={
                { label: sizeTypeOptions.find((option) => option.value === heightType).label, value: heightType }
              } onChange={(selected) => setHeightType(selected.value)} />
            </div>
          </div>
        </div>}

        <div className={styles.hr}></div>

        <TextArea rows={embedType === 'popup' ? 5 : 3} onFocus={(e) => e.target.select()} value={codes[embedType]} onChange={() => { }} />
      </div>
    </div>
  );
};

export default Publish;
