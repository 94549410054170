import styles from './../Edit.module.css';
import editStyles from 'components/Builder/Edit.module.css';

import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';

import { MentionsInput, Toggle, Checkbox, Slider } from 'ui';

import { ReactComponent as ReferToIcon } from 'assets/images/refer-to.svg';

const fileUploadMaxFilesValues = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25];

class FileUpload extends Component {
  toggleExtansions = (values) => {
    let extensions = this.props.field.fileUploadExtensions;
    let index;

    for (let extension of values) {
      index = extensions.indexOf(extension);

      if (index === -1) {
        extensions.push(extension);
      } else {
        extensions.splice(index, 1);
      }
    }

    this.props.handleChange({ fileUploadExtensions: extensions }, false);
  }

  updateFileUploadMaxFiles = (value) => {
    value = fileUploadMaxFilesValues[value];

    if (value > 25) {
      value = 25;
    }

    if (value < 1) {
      value = 1;
    }

    this.props.handleChange({ fileUploadMaxFiles: value });
  }

  render() {
    const {
      field, fields, handleChange, mentionsData
    } = this.props;

    return (
      <>
        <div className={editStyles.row}>
          <label>
            <div>Field Title</div>
            <a href="https://support.questionscout.com/en/articles/3947231-how-to-use-refer-to" target="_blank" rel="noopener noreferrer"><ReferToIcon data-tip="Refer to is available on this input, click to learn more." /></a>
          </label>
          <MentionsInput menu="tooltip"
            initialValue={field.label}
            onChange={(value) => handleChange({ label: value })}
            data={mentionsData}
          />
        </div>

        <div className={editStyles.row}>
          <label>
            <div>Supporting Text</div>
            <a href="https://support.questionscout.com/en/articles/3947231-how-to-use-refer-to" target="_blank" rel="noopener noreferrer"><ReferToIcon data-tip="Refer to is available on this input, click to learn more." /></a>
          </label>
          <MentionsInput menu="tooltip"
            initialValue={field.description}
            onChange={(value) => handleChange({ description: value })}
            data={mentionsData}
          />
        </div>

        <div className={[editStyles.row].join(' ')}>
          <div className={editStyles.toggle50} onClick={() => handleChange({ required: !field.required }, false)}>
            <Toggle checked={field.required} readOnly={true} /> <span>Required</span>
          </div>
          <div style={{clear: 'both'}}></div>
        </div>

        <div className={editStyles.hr}></div>

        <div className={editStyles.row}>
          <label>Accepted File Types</label>

          <div className={editStyles.fileTypes}>
            <label>DOCUMENTS</label>

            <ul>
              <li><Checkbox label=".pdf" value={field.fileUploadExtensions.indexOf('application/pdf') !== -1} onClick={() => this.toggleExtansions(['application/pdf'])} /></li>
              <li><Checkbox label=".doc" value={field.fileUploadExtensions.indexOf('application/msword') !== -1} onClick={() => this.toggleExtansions(['application/msword'])} /></li>
              <li><Checkbox label=".docx" value={field.fileUploadExtensions.indexOf('application/vnd.openxmlformats-officedocument.wordprocessingml.document') !== -1} onClick={() => this.toggleExtansions(['application/vnd.openxmlformats-officedocument.wordprocessingml.document'])} /></li>
              <li><Checkbox label=".txt" value={field.fileUploadExtensions.indexOf('text/plain') !== -1} onClick={() => this.toggleExtansions(['text/plain'])} /></li>
              <li><Checkbox label=".rtf" value={field.fileUploadExtensions.indexOf('application/rtf') !== -1} onClick={() => this.toggleExtansions(['application/rtf'])} /></li>
              <li><Checkbox label=".odt" value={field.fileUploadExtensions.indexOf('application/vnd.oasis.opendocument.text') !== -1} onClick={() => this.toggleExtansions(['application/vnd.oasis.opendocument.text'])} /></li>
            </ul>
          </div>

          <div className={editStyles.fileTypes}>
            <label>PRESENTATIONS</label>

            <ul>
              <li><Checkbox label=".key" value={field.fileUploadExtensions.indexOf('application/x-iwork-keynote-sffke') !== -1} onClick={() => this.toggleExtansions(['application/x-iwork-keynote-sffke'])} /></li>
              <li><Checkbox label=".ppt" value={field.fileUploadExtensions.indexOf('application/vnd.ms-powerpoint') !== -1} onClick={() => this.toggleExtansions(['application/vnd.ms-powerpoint'])} /></li>
              <li><Checkbox label=".pptx" value={field.fileUploadExtensions.indexOf('application/vnd.openxmlformats-officedocument.presentationml.presentation') !== -1} onClick={() => this.toggleExtansions(['application/vnd.openxmlformats-officedocument.presentationml.presentation'])} /></li>
              <li><Checkbox label=".odp" value={field.fileUploadExtensions.indexOf('application/vnd.oasis.opendocument.presentation') !== -1} onClick={() => this.toggleExtansions(['application/vnd.oasis.opendocument.presentation'])} /></li>
            </ul>
          </div>

          <div className={editStyles.fileTypes}>
            <label>DATA</label>

            <ul>
              <li><Checkbox label=".csv" value={field.fileUploadExtensions.indexOf('text/csv') !== -1} onClick={() => this.toggleExtansions(['text/csv'])} /></li>
              <li><Checkbox label=".xml" value={field.fileUploadExtensions.indexOf('text/xml') !== -1} onClick={() => this.toggleExtansions(['text/xml'])} /></li>
              <li><Checkbox label=".json" value={field.fileUploadExtensions.indexOf('application/json') !== -1} onClick={() => this.toggleExtansions(['application/json'])} /></li>
              <li><Checkbox label=".xls" value={field.fileUploadExtensions.indexOf('application/vnd.ms-excel') !== -1} onClick={() => this.toggleExtansions(['application/vnd.ms-excel'])} /></li>
              <li><Checkbox label=".xlsx" value={field.fileUploadExtensions.indexOf('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') !== -1} onClick={() => this.toggleExtansions(['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'])} /></li>
              <li><Checkbox label=".numbers" value={field.fileUploadExtensions.indexOf('application/x-iwork-numbers-sffnumbers') !== -1} onClick={() => this.toggleExtansions(['application/x-iwork-numbers-sffnumbers'])} /></li>
              <li><Checkbox label=".ods" value={field.fileUploadExtensions.indexOf('application/vnd.oasis.opendocument.spreadsheet') !== -1} onClick={() => this.toggleExtansions(['application/vnd.oasis.opendocument.spreadsheet'])} /></li>
            </ul>
          </div>

          <div className={editStyles.fileTypes}>
            <label>AUDIO</label>

            <ul>
              <li><Checkbox label=".mp3" value={field.fileUploadExtensions.indexOf('audio/mpeg') !== -1} onClick={() => this.toggleExtansions(['audio/mpeg'])} /></li>
              <li><Checkbox label=".wav" value={field.fileUploadExtensions.indexOf('audio/wav') !== -1} onClick={() => this.toggleExtansions(['audio/wav'])} /></li>
              <li><Checkbox label=".aiff" value={field.fileUploadExtensions.indexOf('audio/aiff') !== -1} onClick={() => this.toggleExtansions(['audio/aiff'])} /></li>
            </ul>
          </div>

          <div className={editStyles.fileTypes}>
            <label>IMAGES</label>

            <ul>
              <li><Checkbox label=".png" value={field.fileUploadExtensions.indexOf('image/png') !== -1} onClick={() => this.toggleExtansions(['image/png'])} /></li>
              <li><Checkbox label=".jpg" value={field.fileUploadExtensions.indexOf('image/jpeg') !== -1} onClick={() => this.toggleExtansions(['image/jpeg'])} /></li>
              <li><Checkbox label=".gif" value={field.fileUploadExtensions.indexOf('image/gif') !== -1} onClick={() => this.toggleExtansions(['image/gif'])} /></li>
            </ul>
          </div>

          <div className={editStyles.fileTypes}>
            <label>COMPRESSED FILES</label>

            <ul>
              <li><Checkbox label=".zip" value={field.fileUploadExtensions.indexOf('application/zip') !== -1} onClick={() => this.toggleExtansions(['application/zip'])} /></li>
              <li><Checkbox label=".rar" value={field.fileUploadExtensions.indexOf('application/x-rar-compressed') !== -1} onClick={() => this.toggleExtansions(['application/x-rar-compressed'])} /></li>
            </ul>
          </div>
        </div>
        

        <div className={editStyles.row}>
          <label>Maximum Files Per Answer</label>
          <div className={editStyles.slider} style={{ padding: '0 12px 0 0' }}>
            <div className={editStyles.sliderValue} style={{ width: 55, marginRight: 10 }}>{field.fileUploadMaxFiles} file{field.fileUploadMaxFiles > 1 && 's'}</div>
            <Slider value={fileUploadMaxFilesValues.findIndex((index) => index === field.fileUploadMaxFiles)}
              values={fileUploadMaxFilesValues}
              onChange={(size) => this.updateFileUploadMaxFiles(parseInt(size))} />
          </div>
        </div>

        <ReactTooltip place="right" effect="solid" />
      </>
    );
  };
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(FileUpload);
