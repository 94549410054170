import styles from './LifeTimeJoin.module.css';

import React, { useState, useEffect } from 'react';

const LifeTimeJoin = ({ match }) => {
  useEffect(() => {
    window.location.replace('/');
  }, []);

  return <></>;
};

export default LifeTimeJoin;
