/** @jsx jsx */
/* @jsxFrag React.Fragment */

import { jsx, css } from '@emotion/core';
import React, { useEffect } from 'react';
import Color from 'color';
import Translate from '../messages/Translate.js';
import getThankYouPage from 'helpers/getThankYouPage.js';
import isJsonString from 'helpers/isJsonString.js';
import getFieldLabel from 'helpers/getFieldLabel.js';

import { ReactComponent as LinkArrowIcon } from 'assets/images/link-arrow.svg';
import { ReactComponent as CheckmarkIcon } from 'assets/images/checkmark.svg';

const alignOptions = {
  left: 'flex-start',
  center: 'center',
  right: 'flex-end'
};

const redirectTo = (url, inIframe) => {
  if (!inIframe) window.location.href = url;
  if (inIframe) window.top.location.href = url;
};

const addHttps = (url) => {
  if (url && !/^https?:\/\//i.test(url)) url = `https://${url}`;

  return url;
};

const Submitted = ({ inIframe, fields, mode, values, messages, hideBranding, submittedText, respondentLimits, respondentLimitsNumber, onSubmitAgain, theme, thankYouPages, defaultThankYouPage, variables, fieldsOptions }) => {
  const translate = new Translate(messages);
  const pageProps = getThankYouPage(defaultThankYouPage, thankYouPages, fields, values, variables);

  useEffect(() => {
    if (mode === 'preview' || typeof pageProps === 'undefined') return;

    let url = getFieldLabel(pageProps.redirectUrl, values, 'text', fieldsOptions, variables);

    if (pageProps.redirect && url && pageProps.redirectDelay === 0) {
      redirectTo(addHttps(url), inIframe);
    }

    if (pageProps.redirect && url && pageProps.redirectDelay > 0) {
      setTimeout(() => {
        redirectTo(addHttps(url), inIframe);
      }, pageProps.redirectDelay * 1000)
    }
  }, [pageProps]);

  const styles = {
    main: css({
      width: '100%',
      maxWidth: '600px',
      margin: '50px auto 0 auto',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: alignOptions[theme.thankYouScreenAlignment],
      fontFamily: theme.typographyContentFontFamily
    }),
    content: css({
      width: '100%',
      maxWidth: `${theme.thankYouScreenWidth}${theme.thankYouScreenWidthType}`
    }),
    message: css({
      backgroundColor: theme.thankYouScreenBackground,
      borderRadius: `${theme.thankYouScreenRoundness}px`,
      boxShadow: theme.thankYouScreenShadow ? `${theme.thankYouScreenShadowOffsetX}px ${theme.thankYouScreenShadowOffsetY}px ${theme.thankYouScreenShadowBlur}px ${theme.thankYouScreenShadowSpread}px ${theme.thankYouScreenShadowColor}` : 'none',
      border: `${theme.thankYouScreenBorderSize}px solid ${theme.thankYouScreenBorderColor}`,
      width: '100%',
      padding: '30px 20px 0 20px',
      boxSizing: 'border-box',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      '& a': {
        color: theme.thankYouScreenTextColor,
        textDecoration: 'underline'
      },
      '.submittedText': {
        color: theme.thankYouScreenTextColor,
        fontSize: `${theme.thankYouScreenTextFontSize}px`,
        fontWeight: theme.thankYouScreenTextWeight,
        lineHeight: '142%',
        textAlign: theme.thankYouScreenTextAlignment,
        display: 'block',
        marginTop: '25px',
        width: '100%',
      }
    }),
    submittedLink: css({
      backgroundColor: theme.thankYouScreenBackground,
      borderRadius: `${theme.thankYouScreenRoundness}px`,
      boxShadow: theme.thankYouScreenShadow ? `${theme.thankYouScreenShadowOffsetX}px ${theme.thankYouScreenShadowOffsetY}px ${theme.thankYouScreenShadowBlur}px ${theme.thankYouScreenShadowSpread}px ${theme.thankYouScreenShadowColor}` : 'none',
      border: `${theme.thankYouScreenBorderSize}px solid ${theme.thankYouScreenBorderColor}`,
      width: '100%',
      padding: '15px 20px',
      boxSizing: 'border-box',
      marginTop: '20px',
      'span': {
        color: theme.thankYouScreenTextColor,
        fontSize: '14px',
        fontWeight: '400',
        lineHeight: '142%',
        textAlign: 'center',
        display: 'block'
      },
      'a': {
        display: 'block',
        color: theme.thankYouScreenLinksColor,
        fontSize: '13px',
        fontWeight: '500',
        lineHeight: '142%',
        textAlign: 'center',
        marginTop: '5px',
        WebkitTapHighlightColor: 'rgba(0, 0, 0, 0)',
        WebkitTapHighlightColor: 'transparent'
      },
      'a svg': {
        marginLeft: '2px'
      },
      'a svg *': {
        fill: theme.thankYouScreenLinksColor
      }
    }),
    submitAgain: css({
      borderTop: `1px solid ${Color(theme.thankYouScreenTextColor).fade(0.8)}`,
      width: '100%',
      margin: '20px 0 0 0',
      boxSizing: 'border-box',
      textAlign: 'center',
      display: 'flex',
      boxSizing: 'border-box',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      'div': {
        color: theme.thankYouScreenLinksColor,
        fontSize: '15px',
        fontWeight: '500',
        lineHeight: '142%',
        cursor: 'pointer',
        textAlign: 'center',
        margin: '20px 0'
      },
      'span': {
        color: theme.thankYouScreenTextColor,
        fontSize: '15px',
        fontWeight: '500',
        lineHeight: '142%',
        textAlign: 'center',
        margin: '20px 0',
        display: 'block'
      }
    }),
    checkmark: css({
      '@keyframes stroke': {
        '100%': {
          strokeDashoffset: 0
        }
      },
      '@keyframes scale': {
        '0%': {},
        '100%': {
          transform: 'none'
        },
        '50%': {
          transform: 'scale3d(1.1, 1.1, 1)'
        }
      },
      '@keyframes fill': {
        '100%': {
          boxShadow: `inset 0px 0px 0px 30px ${theme.thankYouScreenIconColor}`
        }
      },
      width: '56px',
      height: '56px',
      borderRadius: '50%',
      display: 'block',
      strokeWidth: '2',
      stroke: '#fff',
      strokeMiterlimit: '10',
      boxShadow: `inset 0px 0px 0px ${theme.thankYouScreenIconColor}`,
      animation: 'fill 0.4s ease-in-out 0.4s forwards, scale 0.3s ease-in-out 0.9s both',
      'circle': {
        strokeDasharray: '166',
        strokeDashoffset: '166',
        strokeWidth: '2',
        strokeMiterlimit: '10',
        stroke: theme.thankYouScreenIconColor,
        fill: 'none',
        animation: 'stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards'
      },
      'path': {
        transformOrigin: '50% 50%',
        strokeDasharray: '48',
        strokeDashoffset: '48',
        animation: 'stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards'
      }
    })
  };

  if (typeof pageProps !== 'undefined' && pageProps.redirect && pageProps.redirectDelay === 0 && mode !== 'preview' && getFieldLabel(pageProps.redirectUrl, values, 'text', fieldsOptions, variables)) return <></>;

  return <>
    <div css={styles.main} className="qs_submitted">
      <div css={styles.content}>
        <div css={styles.message}>
          <CheckmarkIcon css={styles.checkmark} />
          {isJsonString(pageProps.content)
            ? <span className="submittedText">{getFieldLabel(pageProps.content, values, 'jsx', fieldsOptions, variables)}</span>
            : <span className="submittedText" dangerouslySetInnerHTML={{ __html: pageProps.content }} />}

          <div css={styles.submitAgain}>
            {!respondentLimits && pageProps.resubmit && <div onClick={onSubmitAgain}>{translate.text('submitAgainBtn')}</div>}
            {respondentLimits && pageProps.resubmit && <span>
              {translate.text('respondentLimits', {
                respondentLimitsNumber: { value: respondentLimitsNumber }
              })}
            </span>}
          </div>
        </div>

        {!hideBranding && <div css={styles.submittedLink}>
          <span>This form was built for free using QuestionScout</span>
          <a href="https://questionscout.com?ref=questionscout-form" target="_blank" rel="noopener noreferrer">Start building your own form now <LinkArrowIcon /></a>
        </div>}
      </div>
    </div>
  </>;
};

export default Submitted;
