import styles from './../Edit.module.css';
import editStyles from 'components/Builder/Edit.module.css';

import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { v4 as uuid } from 'uuid';
import { useDropzone } from 'react-dropzone';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { isJsonString } from 'misc/helpers.js';

import { MentionsInput, TextInput, Toggle, Loader, Checkbox as CheckboxUI, Select } from 'ui';

import {
  uploadImage, removeImage
} from 'store/ducks/builder.js';

import { ReactComponent as RemoveIcon } from 'assets/images/remove.svg';
import { ReactComponent as DragIcon } from 'assets/images/drag.svg';
import { ReactComponent as ReferToIcon } from 'assets/images/refer-to.svg';

const optionsPerRowOptions = [
  { label: 1, value: 1 },
  { label: 2, value: 2 },
  { label: 3, value: 3 },
  { label: 4, value: 4 },
  { label: 5, value: 5 },
  { label: 6, value: 6 }
];

const reorder = (list, startIndex, endIndex) => {
  let result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);

  result.splice(endIndex, 0, removed);

  return result.filter((element) => typeof element !== 'undefined');
};

const UploadArea = (props) => {
  const file = props.uploads.find((file) => file.ref === props.id) || null;

  const { getRootProps, getInputProps, open } = useDropzone({
    accept: 'image/jpeg, image/png, image/gif',
    multiple: false,
    noClick: true,
    noKeyboard: true,
    onDrop: (acceptedFiles) => props.uploadImage(props.id, acceptedFiles, props.field)
  });

  return (
    <div className={styles.imageUpload}>
      {!props.url && <div {...getRootProps({ className: styles.dropzone })} style={{ height: 80 }}>
        <input {...getInputProps()} />
        {(!file) && <>
          <p>Drop your image here</p>
          <p>or</p>
          <span onClick={open}>click to upload</span>
        </>}
        {file && file.loaded !== true && <Loader size={30} />}
      </div>}

      {props.url && <div className={styles.uploaded} style={{ height: 80 }}>
        <div className={styles.preview}>
          <img src={props.url} alt="" />
        </div>
        <span className={styles.removeImage} onClick={props.remove}>Remove current image</span>
      </div>}
    </div>
  );
}

class ImageChoice extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentInputLenght: 0,
      showInputLenght: false
    };
  }

  onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) return;

    const options = reorder(
      this.props.field.options,
      result.source.index,
      result.destination.index
    );

    this.props.handleChange({ options }, false);
  };

  addOption = () => {
    const options = this.props.field.options;

    options.push({
      ref: uuid(),
      value: '{ text: "", url: null }'
    });

    this.props.handleChange({ options }, false);
  }

  removeOption = (ref) => {
    const changes = {};
    const optionIndex = this.props.field.options.findIndex((option) => option.ref === ref);

    if (optionIndex === -1) return;

    if (this.props.field.options.length === 0 || this.props.field.value === this.props.field.options[optionIndex].ref) {
      changes.value = JSON.stringify({ values: [], other: null });
    }

    this.props.field.options.splice(optionIndex, 1);

    changes.options = this.props.field.options;

    this.props.handleChange(changes, false);
  }

  handleOptionChange = (ref, value) => {
    let options = this.props.field.options;
    let valueLength;
    const optionIndex = options.findIndex((option) => option.ref === ref);

    if (optionIndex === -1) return;

    const currentValue = isJsonString(options[optionIndex].value) ? JSON.parse(options[optionIndex].value) : { text: '', url: null };

    if (typeof value.text !== 'undefined') {
      valueLength = (value.text || '').length;

      this.setState({
        currentInputLenght: valueLength >= this.props.field.valueMaxLength ? this.props.field.valueMaxLength : valueLength
      });
    }

    options[optionIndex].value = JSON.stringify({ ...currentValue, ...value });

    this.props.handleChange({ options: options }, false);
  }

  toggleFieldValue = (ref) => {
    let data = (this.props.field.value === JSON.stringify({ values: [], other: null }) || this.props.field.value === null) ? { values: [], other: null } : JSON.parse(this.props.field.value);
    let refIndex = data.values.indexOf(ref);

    if (refIndex !== -1) {
      data.values.splice(refIndex, 1);
    } else {
      data.values.push(ref);
    }

    this.props.handleChange({ value: JSON.stringify(data) }, false);
  }

  onValueBlur = () => {
    this.setState({
      showInputLenght: false
    });
  }

  onValueFocus = (value) => {
    this.setState({
      showInputLenght: true,
      currentInputLenght: (value || '').length
    });
  }

  clearAll = () => {
    this.props.handleChange({
      options: [{
        ref: uuid(),
        value: '{ text: "", url: null }'
      }]
    }, false);
  }

  render() {
    const {
      field, fields, handleChange, uploads, removeImage, uploadImage, mentionsData
    } = this.props;

    const {
      showInputLenght, currentInputLenght
    } = this.state;

    return (
      <>
        <div className={editStyles.row}>
          <label>
            <div>Field Title</div>
            <a href="https://support.questionscout.com/en/articles/3947231-how-to-use-refer-to" target="_blank" rel="noopener noreferrer"><ReferToIcon data-tip="Refer to is available on this input, click to learn more." /></a>
          </label>
          <MentionsInput menu="tooltip"
            initialValue={field.label}
            onChange={(value) => handleChange({ label: value })}
            data={mentionsData}
          />
        </div>

        <div className={editStyles.row}>
          <label>
            <div>Supporting Text</div>
            <a href="https://support.questionscout.com/en/articles/3947231-how-to-use-refer-to" target="_blank" rel="noopener noreferrer"><ReferToIcon data-tip="Refer to is available on this input, click to learn more." /></a>
          </label>
          <MentionsInput menu="tooltip"
            initialValue={field.description}
            onChange={(value) => handleChange({ description: value })}
            data={mentionsData}
          />
        </div>

        <div className={[editStyles.row].join(' ')}>
          <div className={editStyles.toggle50} onClick={() => handleChange({ required: !field.required }, false)}>
            <Toggle checked={field.required} readOnly={true} /> <span>Required</span>
          </div>
          <div className={editStyles.toggle50} onClick={() => handleChange({ readonly: !field.readonly }, false)}>
            <Toggle checked={field.readonly} readOnly={true} /> <span>Read-Only</span>
          </div>
          <div className={editStyles.toggle50} onClick={() => handleChange({ hidden: !field.hidden }, false)}>
            <Toggle checked={field.hidden} readOnly={true} /> <span>Hidden</span>
          </div>
          <div style={{ clear: 'both' }}></div>
        </div>

        <div className={editStyles.hr}></div>

        <div className={editStyles.row}>
          <label>
            Options
            {showInputLenght && <span>{currentInputLenght || 0}/{field.valueMaxLength}</span>}
            {!showInputLenght && <span className={styles.clearAll} onClick={() => this.clearAll()}>Clear All</span>}
          </label>

          <DragDropContext onDragEnd={this.onDragEnd}>
            <Droppable droppableId="optionsDroppableBox">
              {(provided, snapshot) => (
                <div className={styles.options} ref={provided.innerRef} {...provided.droppableProps}>
                  {field.options.map((option, index) => {
                    const value = isJsonString(option.value) ? JSON.parse(option.value) : { text: '', url: null };

                    return <Draggable draggableId={option.ref} key={option.ref} index={index}>
                      {(provided, snapshot) => (
                        <div className={styles.fileOption} ref={provided.innerRef} {...provided.draggableProps}>
                          <div className={styles.option}>
                            <CheckboxUI value={field.value && field.value.search(option.ref) !== -1} onClick={() => this.toggleFieldValue(option.ref)} onChange={() => { }} />
                            <div className={styles.drag} {...provided.dragHandleProps} tabIndex="-1"><DragIcon /></div>
                            <TextInput value={value.text} width={220} onChange={(e) => this.handleOptionChange(option.ref, { text: e.target.value })} onFocus={() => this.onValueFocus(value.text)} onBlur={() => this.onValueBlur()} />
                            <RemoveIcon className={styles.remove} onClick={() => this.removeOption(option.ref)} />
                          </div>
                          <div>
                            <UploadArea uploadImage={uploadImage} url={value.url} uploads={uploads} remove={() => {
                              this.handleOptionChange(option.ref, { url: null });
                              removeImage(option.ref, option.value.url);
                            }} id={option.ref} field={field} />
                          </div>
                        </div>
                      )}
                    </Draggable>;
                  })}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>

          <div className={styles.addOption} onClick={() => this.addOption()}>Add Option</div>

          <div className={editStyles.hr}></div>

          <div className={editStyles.row}>
            <label>Options per row</label>
            <Select width={294} options={optionsPerRowOptions} value={
              { label: optionsPerRowOptions.find((option) => option.value === (field.imageChoiceRows || 4)).label, value: (field.imageChoiceRows || 4) }
            } onChange={(selected) => handleChange({ imageChoiceRows: selected.value }, false)} />
          </div>

          <div className={editStyles.toggle100} onClick={() => handleChange({ selectionLimits: !field.selectionLimits }, false)}>
            <Toggle checked={field.selectionLimits} readOnly={true} /> <span>Selection Limits</span>
          </div>

          {field.selectionLimits && <div className={editStyles.row} style={{ margin: '5px 0 15px 0' }}>
            <label>Selection Limits Range</label>

            <div className={editStyles.inputsRange}>
              <TextInput type="number" step="1" value={field.selectionLimitsMin} placeholder="0" onChange={(e) => handleChange({ selectionLimitsMin: e.target.value })} />
              <span>to</span>
              <TextInput type="number" step="1" value={field.selectionLimitsMax} placeholder="99" onChange={(e) => handleChange({ selectionLimitsMax: e.target.value })} />
            </div>
          </div>}

          <div className={editStyles.toggle100} onClick={() => handleChange({ value: (field.value !== JSON.stringify({ values: [], other: null }) && field.value !== null) ? JSON.stringify({ values: [], other: null }) : JSON.stringify({ values: field.options.length > 0 ? [field.options[0].ref] : [], other: null }) }, false)}>
            <Toggle checked={field.value !== JSON.stringify({ values: [], other: null }) && field.value !== null} disabled={field.options.length === 0} readOnly={true} /> <span>Assign Automatically Selected Options</span>
          </div>

          <div className={editStyles.toggle100} onClick={() => handleChange({ randomizeOptions: !field.randomizeOptions }, false)}>
            <Toggle checked={field.randomizeOptions} readOnly={true} /> <span>Randomize Options</span>
          </div>
        </div>

        <ReactTooltip place="right" effect="solid" />
      </>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    uploads: state.builder.uploads
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    uploadImage: (ref, file, field) => dispatch(uploadImage(ref, file, field)),
    removeImage: (ref, url) => dispatch(removeImage(ref, url))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ImageChoice);
