import styles from './Integrations.module.css';

import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { verifyPermission } from 'misc/helpers.js';
import { useSelector, useDispatch } from 'react-redux';

import { ReactComponent as CopyIcon } from 'assets/images/copy.svg';

import { TextInput, Button, Card, CardRow, CardRowLabel, CardRowContent } from 'ui';

import logoIcon from 'assets/images/integrations/zapier-small.png';

import {
  updateApiKeys
} from 'store/ducks/user.js';

const Zapier = ({ integration, onChange }) => {
  const dispatch = useDispatch();

  const user = useSelector(state => state._users.user);

  return <>
    <div className={styles.head}>
      <img src={logoIcon} width="32px" alt="logo" className={styles.logo} />
      <div className={styles.label}>Zapier</div>
      <div className={styles.desc}>Our integration with Zapier helps you easily push data you collect on QuestionScout forms and surveys to over 2.000 services.</div>

      <div className={styles.statusLabel}>Integration Status</div>
      <div className={styles.statusValue}>
        <div className={styles.statusValueText}>This integration must be enabled through Zapier.com and cannot be controlled here.</div>
      </div>
    </div>

    <Card className={styles.card}>
      <CardRow>
        <CardRowLabel title="Your API Key" subTitle="Your API key to authenticate Zapier." />

        <CardRowContent>
          <div style={{ margin: '0 0 20px 0' }}>
            <div className={styles.copy}>
              <TextInput type="text" value={user.apiKey || ''} width={350} onChange={() => { }} onFocus={(e) => e.target.select()} />

              <CopyToClipboard text={user.apiKey || ''} >
                <div className={styles.copyBtn}><CopyIcon />Copy</div>
              </CopyToClipboard>
            </div>
          </div>

          {verifyPermission('generateApiKey', user) && <Button theme="white" onClick={() => dispatch(updateApiKeys())}>Generate new API key</Button>}
        </CardRowContent>
      </CardRow>
    </Card>
  </>;
}

export default Zapier;
