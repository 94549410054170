/** @jsx jsx */
/* @jsxFrag React.Fragment */

import { jsx, css } from '@emotion/core';
import React from 'react';
import getFieldLabel from 'helpers/getFieldLabel.js';

import { LabelAtom, DescriptionAtom } from './atoms.js';

const Scale = ({ form, field, theme, value, values, onChange, onPartialResponse, onNextClick, fieldsOptions, variables }) => {
  const options = [];

  const alignOptions = {
    left: 'flex-start',
    center: 'center',
    right: 'flex-end'
  };

  const mainStyle = css({
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: alignOptions[theme.scalesAlignment],
    margin: `${theme.scalesTopMargin || 0}px 0 ${theme.scalesBottomMargin || 0}px 0`
  });

  const contentStyle = css({
    width: '100%',
    maxWidth: `${theme.scalesWidth}${theme.scalesWidthType}`
  });

  const scaleStyle = css({
    fontFamily: theme.typographyContentFontFamily,
    color: theme.fieldsFontColor,
    width: '100%',
    listStyle: 'none',
    padding: 0,
    margin: 0,
    display: 'flex',
    flexDrection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    boxSizing: 'border-box',
    userSelect: 'none',
    borderRadius: `${theme.scalesIdleRoundness}px`,
    height: theme.scalesIdleHeight,
    overflow: 'hidden',
    boxShadow: theme.scalesIdleShadow ? `${theme.scalesIdleShadowOffsetX}px ${theme.scalesIdleShadowOffsetY}px ${theme.scalesIdleShadowBlur}px ${theme.scalesIdleShadowSpread}px ${theme.scalesIdleShadowColor}` : 'none',
    border: `${theme.scalesIdleOutsideBorderSize}px solid ${theme.scalesIdleOutsideBorderColor}`,

    '&:hover': (() => {
      if (!theme.scalesHoverEnable) return {};

      const obj = {
        borderColor: theme.scalesHoverEnable ? theme.scalesHoverOutsideBorderColor : theme.scalesIdleOutsideBorderColor
      };

      if (theme.scalesHoverShadow) {
        obj.boxShadow = `${theme.scalesHoverShadowOffsetX}px ${theme.scalesHoverShadowOffsetY}px ${theme.scalesHoverShadowBlur}px ${theme.scalesHoverShadowSpread}px ${theme.scalesHoverShadowColor}`;
      } else {
        obj.boxShadow = 'none';
      }

      return obj;
    })(),

    '&:active, &.isActive': (() => {
      if (!theme.scalesActiveEnable) return {};

      const obj = {
        borderColor: theme.scalesActiveEnable ? theme.scalesActiveOutsideBorderColor : theme.scalesIdleOutsideBorderColor
      };

      if (theme.scalesActiveShadow) {
        obj.boxShadow = `${theme.scalesActiveShadowOffsetX}px ${theme.scalesActiveShadowOffsetY}px ${theme.scalesActiveShadowBlur}px ${theme.scalesActiveShadowSpread}px ${theme.scalesActiveShadowColor}`;
      } else {
        obj.boxShadow = 'none';
      }

      return obj;
    })(),

    li: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      boxSizing: 'border-box',
      cursor: 'pointer',
      color: theme.scalesIdleTextColor,
      fontFamily: theme.typographyContentFontFamily,
      fontSize: theme.scalesIdleTextFontSize,
      fontWeight: theme.scalesIdleTextWeight,
      lineHeight: '142%',
      textAlign: 'center',
      backgroundColor: theme.scalesIdleBackground,
      height: '100%',
      boxShadow: `inset -${theme.scalesIdleInsideBorderSize}px 0px 0px 0px ${theme.scalesIdleInsideBorderColor}, inset ${theme.scalesIdleInsideBorderSize}px 0px 0px 0px transparent;`
    },

    '& li:last-child': {
      boxShadow: 'none',
    },

    '&:hover li': {
      boxShadow: theme.scalesHoverEnable ? `inset -${theme.scalesIdleInsideBorderSize}px 0px 0px 0px ${theme.scalesHoverInsideBorderColor}, inset ${theme.scalesIdleInsideBorderSize}px 0px 0px 0px transparent;` : `inset -${theme.scalesIdleInsideBorderSize}px 0px 0px 0px ${theme.scalesIdleInsideBorderColor}, inset ${theme.scalesIdleInsideBorderSize}px 0px 0px 0px transparent;`
    },
    '&:hover li:last-child': {
      boxShadow: 'none'
    },

    '&:active li, &.isActive li': {
      boxShadow: theme.scalesActiveEnable ? `inset -${theme.scalesIdleInsideBorderSize}px 0px 0px 0px ${theme.scalesActiveInsideBorderColor}, inset ${theme.scalesIdleInsideBorderSize}px 0px 0px 0px transparent;` : `inset -${theme.scalesIdleInsideBorderSize}px 0px 0px 0px ${theme.scalesIdleInsideBorderColor}, inset ${theme.scalesIdleInsideBorderSize}px 0px 0px 0px transparent;`
    },
    '&:active li:last-child, &.isActive li:last-child': {
      boxShadow: 'none'
    },

    'li:hover, li.hover': {
      backgroundColor: theme.scalesHoverEnable ? theme.scalesHoverBackground : theme.scalesIdleBackground,
      color: theme.scalesHoverEnable ? theme.scalesHoverTextColor : theme.scalesIdleTextColor,
    },
    'li:active, li.isActive': {
      backgroundColor: theme.scalesActiveEnable ? theme.scalesActiveBackground : theme.scalesIdleBackground,
      color: theme.scalesActiveEnable ? theme.scalesActiveTextColor : theme.scalesIdleTextColor,
      cursor: 'default'
    }
  });

  const infoStyle = css({
    width: '100%',
    listStyle: 'none',
    padding: 0,
    display: 'flex',
    flexFirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    userSelect: 'none',
    margin: `${theme.scalesIdleLabelsTextFontSize / 3}px 0 0 0`,

    'li': {
      textAlign: 'left',
      fontSize: `${theme.scalesIdleLabelsTextFontSize}px`,
      lineHeight: '142%',
      fontWeight: theme.scalesIdleLabelsTextWeight,
      color: theme.scalesIdleLabelsTextColor,
      boxSizing: 'border-box',
      fontFamily: theme.typographySupportingFontFamily
    }
  });

  for (let i = field.scaleRange[0]; i <= field.scaleRange[1]; i++) {
    options.push(i);
  }

  return <>
    <div css={mainStyle}>
      <div css={contentStyle}>
        <LabelAtom required={field.required} error={field.error} theme={theme}>{getFieldLabel(field.label, values, 'jsx', fieldsOptions, variables)}</LabelAtom>

        <ul css={scaleStyle} className={value !== null ? 'isActive' : ''}>
          {options.map((option, index) => {
            const width = Math.round(100 / options.length * 2) / 2;
            const lastWidth = width + (100 - width * options.length);
            const isLast = options.length - 1 === index;

            return <li key={option} className={[String(value) === String(option) ? 'isActive' : '', index === field.highlighted ? 'hover' : ''].join(' ')} style={{ width: isLast ? `${lastWidth}%` : `${width}%` }} onClick={(e) => {
              onChange({ [field._id]: option });
              onPartialResponse(field._id);
              if (field.section === 'root' || !field.section) onNextClick(field);
            }}>
              <span>{option}</span>
            </li>;
          })}
        </ul>

        {(field.scaleTextLeft || field.scaleTextCenter || field.scaleTextRight) && <ul css={infoStyle}>
          <li style={{ width: '32%', textAlign: 'left' }}>{field.scaleTextLeft}</li>
          <li style={{ width: '32%', textAlign: 'center' }}>{field.scaleTextCenter}</li>
          <li style={{ width: '32%', textAlign: 'right' }}>{field.scaleTextRight}</li>
        </ul>}

        <DescriptionAtom theme={theme}>{getFieldLabel(field.description, values, 'jsx', fieldsOptions, variables)}</DescriptionAtom>
      </div>
    </div>
  </>;
};

export default Scale;
