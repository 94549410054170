import axios from 'axios';
import Storage from './../storage.js';
import isEmail from 'validator/lib/isEmail';

import config from 'config.js';

const storage = new Storage('user');

// Constants
const ADD_ERROR = 'user/ADD_ERROR';
const CLEAR_ERRORS = 'user/CLEAR_ERRORS';
const CLEAR = 'user/CLEAR';
const SHOW_CHANGE_PASSWORD = 'user/SHOW_CHANGE_PASSWORD';
const CLEAR_SETTINGS = 'user/CLEAR_SETTINGS';
const UPDATE_PLAN = 'user/UPDATE_PLAN';
const UPDATE_DELETE_ACCOUNT_FORM = 'user/UPDATE_DELETE_ACCOUNT_FORM';

const UPDATE_OLD_PASSWORD = 'user/UPDATE_OLD_PASSWORD';
const UPDATE_NEW_PASSWORD = 'user/UPDATE_NEW_PASSWORD';

const SHOW_USER_DROPDOWN = 'user/SHOW_USER_DROPDOWN';
const HIDE_USER_DROPDOWN = 'user/HIDE_USER_DROPDOWN';

const SUBMIT_UPDATE_PASSWORD_REQUEST = 'user/UPDATE_PASSWORD_REQUEST';
const SUBMIT_UPDATE_PASSWORD_SUCCESS = 'user/UPDATE_PASSWORD_SUCCESS';
const SUBMIT_UPDATE_PASSWORD_FAILURE = 'user/UPDATE_PASSWORD_FAILURE';

const VERIFY_EMAIL_REQUEST = 'user/VERIFY_EMAIL_REQUEST';
const VERIFY_EMAIL_SUCCESS = 'user/VERIFY_EMAIL_SUCCESS';
const VERIFY_EMAIL_FAILURE = 'user/VERIFY_EMAIL_FAILURE';

const RESEND_VERIFICATION_EMAIL_REQUEST = 'user/RESEND_VERIFICATION_EMAIL_REQUEST';
const RESEND_VERIFICATION_EMAIL_SUCCESS = 'user/RESEND_VERIFICATION_EMAIL_SUCCESS';
const RESEND_VERIFICATION_EMAIL_FAILURE = 'user/RESEND_VERIFICATION_EMAIL_FAILURE';

const UPDATE_API_KEYS_REQUEST = 'user/UPDATE_API_KEYS_REQUEST';
const UPDATE_API_KEYS_SUCCESS = 'user/UPDATE_API_KEYS_SUCCESS';
const UPDATE_API_KEYS_FAILURE = 'user/UPDATE_API_KEYS_FAILURE';

const GET_BILLING_REQUEST = 'user/GET_BILLING_REQUEST';
const GET_BILLING_SUCCESS = 'user/GET_BILLING_SUCCESS';
const GET_BILLING_FAILURE = 'user/GET_BILLING_FAILURE';

const GET_BILLING_INVOICES_REQUEST = 'user/GET_BILLING_INVOICES_REQUEST';
const GET_BILLING_INVOICES_SUCCESS = 'user/GET_BILLING_INVOICES_SUCCESS';
const GET_BILLING_INVOICES_FAILURE = 'user/GET_BILLING_INVOICES_FAILURE';

const UPDATE_BILLING_REQUEST = 'user/UPDATE_BILLING_REQUEST';
const UPDATE_BILLING_SUCCESS = 'user/UPDATE_BILLING_SUCCESS';
const UPDATE_BILLING_FAILURE = 'user/UPDATE_BILLING_FAILURE';

const SHOW_DELETE_ACCOUNT_MODAL = 'user/SHOW_DELETE_ACCOUNT_MODAL';
const HIDE_DELETE_ACCOUNT_MODAL = 'user/HIDE_DELETE_ACCOUNT_MODAL';

const SHOW_ADD_USER_MODAL = 'user/SHOW_ADD_USER_MODAL';
const HIDE_ADD_USER_MODAL = 'user/HIDE_ADD_USER_MODAL';

const SHOW_DELETE_USER_MODAL = 'user/SHOW_DELETE_USER_MODAL';
const HIDE_DELETE_USER_MODAL = 'user/HIDE_DELETE_USER_MODAL';

const SHOW_ADD_EDIT_CONNECTED_MODAL = 'user/SHOW_ADD_EDIT_CONNECTED_MODAL';
const HIDE_ADD_EDIT_CONNECTED_MODAL = 'user/HIDE_ADD_EDIT_CONNECTED_MODAL';

const GET_LTD_INVOICE_REQUEST = 'user/GET_LTD_INVOICE_REQUEST';
const GET_LTD_INVOICE_SUCCESS = 'user/GET_LTD_INVOICE_SUCCESS';
const GET_LTD_INVOICE_FAILURE = 'user/GET_LTD_INVOICE_FAILURE';

const SHOW_OTP_MODAL = 'user/SHOW_OTP_MODAL';
const HIDE_OTP_MODAL = 'user/HIDE_OTP_MODAL';

const SHOW_OTP_REMOVE_MODAL = 'user/SHOW_OTP_REMOVE_MODAL';
const HIDE_OTP_REMOVE_MODAL = 'user/HIDE_OTP_REMOVE_MODAL';

// Initial State
const initialState = {
  billingLoading: false,
  billingInvoicesLoading: false,
  billing: null,
  billingInvoices: [],

  showUserDropdown: false, // header dropdown

  showChangePassword: false, // user settings password change
  changePasswordLoading: false,
  changePasswordError: false,
  changePasswordSuccess: false,

  showOtpCreateModal: false,
  showOtpRemoveModal: false,

  showDeleteAccountModal: false,
  deleteAccountLoading: false,
  deleteAccountSuccess: false,
  deleteAccountError: false,
  deleteAccountForm: {
    password: '',
    confirm: false
  },

  userVerified: false, // EmailVerification
  userVerifiedResended: storage.get('userVerifiedResended') === 'true' || false,

  oldPassword: '',
  newPassword: '',

  loading: false,

  errors: [],

  signupForm: {
    email: '',
    name: '',
    password: '',
    agree: false,
    reCaptchaToken: null
  },
  loginForm: {
    email: '',
    password: ''
  },
  forgetForm: {},

  addEditUsersModal: {
    user: null,
    _show: false
  },

  deleteUsersModal: {
    user: null,
    _show: false
  },

  addEditConnectedModal: {
    user: null,
    _show: false
  }
};

// Reducer
export default function reducer(state = initialState, action = {}) {
  if (action.type === UPDATE_BILLING_REQUEST) {
    state.billing.name = action.payload.name || state.billing.name;
    state.billing.email = action.payload.email || state.billing.email;
    state.billing.coupon = action.payload.discount || state.billing.discount;

    state.billing.address = { ...state.billing.address, ...action.payload.address };
    state.billing = { ...state.billing };

    return { ...state };
  }

  if (action.type === GET_BILLING_REQUEST) {
    state.billingLoading = true;

    return { ...state };
  }

  if (action.type === GET_BILLING_SUCCESS) {
    state.billingLoading = false;
    state.billing = { ...action.payload };

    return { ...state };
  }

  if (action.type === GET_BILLING_INVOICES_REQUEST) {
    state.billingInvoicesLoading = true;

    return { ...state };
  }

  if (action.type === GET_BILLING_INVOICES_SUCCESS) {
    state.billingInvoicesLoading = false;
    state.billingInvoices = action.payload; 
    
    return { ...state };
  }
  
  if (action.type === UPDATE_OLD_PASSWORD) {
    state.oldPassword = action.payload.value;

    return { ...state };
  }

  if (action.type === UPDATE_NEW_PASSWORD) {
    state.newPassword = action.payload.value;

    return { ...state };
  }


  if (action.type === SHOW_CHANGE_PASSWORD) {
    state.showChangePassword = true;
    state.changePasswordError = initialState.changePasswordError;
    state.changePasswordSuccess = initialState.changePasswordSuccess;

    return { ...state };
  }

  if (action.type === SHOW_USER_DROPDOWN) {
    state.showUserDropdown = true;

    return { ...state };
  }

  if (action.type === HIDE_USER_DROPDOWN) {
    state.showUserDropdown = false;

    return { ...state };
  }

  if (action.type === CLEAR) {
    state = { ...initialState };

    return { ...state };
  }

  if (action.type === CLEAR_SETTINGS) {
    state.changePassword = { ...initialState.changePassword };

    state.showChangePassword = initialState.showChangePassword;
    state.changePasswordLoading = initialState.changePasswordLoading;
    state.changePasswordError = initialState.changePasswordError;
    state.changePasswordSuccess = initialState.changePasswordSuccess;

    state.oldPassword = initialState.oldPassword;
    state.newPassword = initialState.newPassword;

    return { ...state };
  }

  if (action.type === CLEAR_ERRORS) {
    state.errors = [];

    return { ...state };
  }

  if (action.type === ADD_ERROR) {
    state.errors.push({
      message: action.payload.message,
      field: action.payload.field
    });

    return { ...state };
  }

  if (action.type === UPDATE_DELETE_ACCOUNT_FORM) {
    state.deleteAccountForm[action.payload.name] = action.payload.value;
    state.deleteAccountForm = { ...state.deleteAccountForm };

    return { ...state };
  }

  if (action.type === VERIFY_EMAIL_SUCCESS) {
    state.user = { ...state.user, ...{ verified: true }};
    state.userVerified = true;

    return { ...state };
  }

  if (action.type === RESEND_VERIFICATION_EMAIL_SUCCESS) {
    state.userVerifiedResended = true;

    storage.set('userVerifiedResended', true);

    return { ...state };
  }

  if (action.type === SUBMIT_UPDATE_PASSWORD_REQUEST) {
    state.changePasswordLoading = true;
    state.changePasswordError = false;
    state.changePasswordSuccess = false;

    return { ...state };
  }

  if (action.type === SUBMIT_UPDATE_PASSWORD_SUCCESS) {
    state.changePasswordLoading = false;
    state.showChangePassword = false;

    state.changePasswordError = false;
    state.changePasswordSuccess = true;

    return { ...state };
  }

  if (action.type === SUBMIT_UPDATE_PASSWORD_FAILURE) {
    state.changePasswordLoading = false;
    state.changePasswordError = true;

    return { ...state };
  }

  if (action.type === UPDATE_PLAN) {
    state.user.plan[action.payload.key] = Math.round((action.payload.value) * 100) / 100;
    state.user = { ...state.user };

    return { ...state };
  }

  if (action.type === SHOW_DELETE_ACCOUNT_MODAL) {
    state.showDeleteAccountModal = true;

    return { ...state };
  }

  if (action.type === HIDE_DELETE_ACCOUNT_MODAL) {
    state.showDeleteAccountModal = false;

    return { ...state };
  }

  if (action.type === SHOW_OTP_MODAL) {
    state.showOtpCreateModal = true;

    return { ...state };
  }

  if (action.type === HIDE_OTP_MODAL) {
    state.showOtpCreateModal = false;

    return { ...state };
  }

  if (action.type === SHOW_OTP_REMOVE_MODAL) {
    state.showOtpRemoveModal = true;

    return { ...state };
  }

  if (action.type === HIDE_OTP_REMOVE_MODAL) {
    state.showOtpRemoveModal = false;

    return { ...state };
  }  

  if (action.type === SHOW_ADD_USER_MODAL) {
    state.addEditUsersModal._show = true;
    state.addEditUsersModal.user = action.payload.user;

    return { ...state };
  }

  if (action.type === HIDE_ADD_USER_MODAL) {
    state.addEditUsersModal._show = false;
    state.addEditUsersModal.user = null;

    state.addEditUsersModal = { ...state.addEditUsersModal };

    return { ...state };
  }

  if (action.type === SHOW_DELETE_USER_MODAL) {
    state.deleteUsersModal._show = true;
    state.deleteUsersModal.user = action.payload.user;

    return { ...state };
  }

  if (action.type === HIDE_DELETE_USER_MODAL) {
    state.deleteUsersModal._show = false;
    state.deleteUsersModal.user = null;

    state.deleteUsersModal = { ...state.deleteUsersModal };

    return { ...state };
  }

  if (action.type === SHOW_ADD_EDIT_CONNECTED_MODAL) {
    state.addEditConnectedModal._show = true;
    state.addEditConnectedModal.user = action.payload.user;

    return { ...state };
  }

  if (action.type === HIDE_ADD_EDIT_CONNECTED_MODAL) {
    state.addEditConnectedModal._show = false;
    state.addEditConnectedModal.user = null;

    state.addEditConnectedModal = { ...state.addEditConnectedModal };

    return { ...state };
  }

  return state;
}

// Action Creators
export function clear() {
  return (dispatch) => {
    dispatch({ type: CLEAR });
  };
}

// addEditConnectedModal
export function showAddEditConnectedModal(user) {
  return (dispatch) => {
    dispatch({ type: SHOW_ADD_EDIT_CONNECTED_MODAL, payload: { user }  });
  };
}

export function hideAddEditConnectedModal() {
  return (dispatch) => {
    dispatch({ type: HIDE_ADD_EDIT_CONNECTED_MODAL });
  };
}

// addEditUsersModal
export function showAddEditUsersModal(user) {
  return (dispatch) => {
    dispatch({ type: SHOW_ADD_USER_MODAL, payload: { user }  });
  };
}

export function hideAddEditUsersModal() {
  return (dispatch) => {
    dispatch({ type: HIDE_ADD_USER_MODAL });
  };
}

// deleteUsersModal
export function showDeleteUsersModal(user) {
  return (dispatch) => {
    dispatch({ type: SHOW_DELETE_USER_MODAL, payload: { user }  });
  };
}

export function hideDeleteUsersModal() {
  return (dispatch) => {
    dispatch({ type: HIDE_DELETE_USER_MODAL });
  };
}

// otpAccountModal
export function showOtpCreateModal() {
  return (dispatch) => {
    dispatch({ type: SHOW_OTP_MODAL });
  };
}

export function hideOtpCreateModal() {
  return (dispatch) => {
    dispatch({ type: HIDE_OTP_MODAL });
  };
}

// otpAccountModal
export function showOtpRemoveModal() {
  return (dispatch) => {
    dispatch({ type: SHOW_OTP_REMOVE_MODAL });
  };
}

export function hideOtpRemoveModal() {
  return (dispatch) => {
    dispatch({ type: HIDE_OTP_REMOVE_MODAL });
  };
}

export function clearSettings() {
  return (dispatch) => {
    dispatch({ type: CLEAR_SETTINGS });
  };
}

export function updatePlan(key, value) {
  return async (dispatch) => {
    return dispatch({ type: UPDATE_PLAN, payload: { key, value } });
  };
}

export function updateDeleteAccountForm(name, value) {
  return (dispatch) => {
    dispatch({ type: UPDATE_DELETE_ACCOUNT_FORM, payload: { name, value } });
  };
}

export function addError({ message, field }) {
  return (dispatch) => {
    return dispatch({ type: ADD_ERROR, payload: { message, field } });
  };
}

export function clearErrors() {
  return (dispatch) => {
    return dispatch({ type: CLEAR_ERRORS });
  };
}

export function toggleChangePassword() {
  return (dispatch) => {
    return dispatch({ type: SHOW_CHANGE_PASSWORD });
  };
}

export function showUserDropdown() {
  return (dispatch) => {
    return dispatch({ type: SHOW_USER_DROPDOWN });
  };
}

export function hideUserDropdown() {
  return (dispatch) => {
    return dispatch({ type: HIDE_USER_DROPDOWN });
  };
}

export function updateOldPassword(value) {
  return (dispatch) => {
    return dispatch({ 
      type: UPDATE_OLD_PASSWORD,
      payload: { value } 
    });
  };
}

export function updateNewPassword(value) {
  return (dispatch) => {
    return dispatch({ 
      type: UPDATE_NEW_PASSWORD,
      payload: { value } 
    });
  };
}

export function showDeleteAccountModal() {
  return (dispatch) => {
    return dispatch({ type: SHOW_DELETE_ACCOUNT_MODAL });
  };
}

export function hideDeleteAccountModal() {
  return (dispatch) => {
    return dispatch({ type: HIDE_DELETE_ACCOUNT_MODAL });
  };
}

export function updateApiKeys() {
  const request = () => { return { type: UPDATE_API_KEYS_REQUEST } };
  const success = (res) => { return { type: UPDATE_API_KEYS_SUCCESS, payload: res } };
  const failure = () => { return { type: UPDATE_API_KEYS_FAILURE } };

  return async (dispatch, getState) => {
    dispatch(request());

    const state = getState();
    const response = await services.updateApiKeys(state._users.token);

    if (response) {
      return dispatch(success(response));
    } else {
      return dispatch(failure());
    }
  };
}


export function submitUpdatePassword() {
  const request = () => { return { type: SUBMIT_UPDATE_PASSWORD_REQUEST } };
  const success = () => { return { type: SUBMIT_UPDATE_PASSWORD_SUCCESS } };
  const failure = () => { return { type: SUBMIT_UPDATE_PASSWORD_FAILURE } };

  return async (dispatch, getState) => {
    dispatch(request());

    const state = getState();
    const response = await services.update(state._users.token, {
      oldPassword: state.user.oldPassword,
      newPassword: state.user.newPassword
    });

    if (response) {
      return dispatch(success());
    } else {
      return dispatch(failure());
    }
  };
}

export function verifyEmail(code) {
  const request = () => { return { type: VERIFY_EMAIL_REQUEST } };
  const success = () => { return { type: VERIFY_EMAIL_SUCCESS } };
  const failure = () => { return { type: VERIFY_EMAIL_FAILURE } };

  return async (dispatch, getState) => {
    dispatch(request());

    const response = await services.verifyEmail(code);

    if (response) {
      return dispatch(success(response));
    } else {
      return dispatch(failure(response));
    }
  };
}

export function resendVerificationEmail() {
  const request = () => { return { type: RESEND_VERIFICATION_EMAIL_REQUEST } };
  const success = () => { return { type: RESEND_VERIFICATION_EMAIL_SUCCESS } };
  const failure = () => { return { type: RESEND_VERIFICATION_EMAIL_FAILURE } };

  return async (dispatch, getState) => {
    dispatch(request());

    const state = getState();
    const response = await services.resendVerificationEmail(state._users.token);

    if (response) {
      dispatch(success());
    } else {
      dispatch(failure());
    }
  };
}

export function getBilling() {
  const request = () => { return { type: GET_BILLING_REQUEST } };
  const success = (res) => { return { type: GET_BILLING_SUCCESS, payload: res } };
  const failure = () => { return { type: GET_BILLING_FAILURE } };

  return async (dispatch, getState) => {
    dispatch(request());

    const state = getState();
    const response = await services.getBilling(state._users.token);

    if (response) {
      dispatch(success(response));
    } else {
      dispatch(failure());
    }
  };
}

export function getBillingInvoice() {
  const request = () => { return { type: GET_BILLING_INVOICES_REQUEST } };
  const success = (res) => { return { type: GET_BILLING_INVOICES_SUCCESS, payload: res } };
  const failure = () => { return { type: GET_BILLING_INVOICES_FAILURE } };

  return async (dispatch, getState) => {
    dispatch(request());

    const state = getState();
    const response = await services.getBillingInvoice(state._users.token);

    if (response) {
      dispatch(success(response));
    } else {
      dispatch(failure());
    }
  };
}

export function getLtdInvoice(id) {
  const request = () => { return { type: GET_LTD_INVOICE_REQUEST } };
  const success = (res) => { return { type: GET_LTD_INVOICE_SUCCESS, payload: res } };
  const failure = () => { return { type: GET_LTD_INVOICE_FAILURE } };

  return async (dispatch, getState) => {
    dispatch(request());

    const state = getState();
    const response = await services.getLtdInvoice(state._users.token, id);

    if (response && response.status === 200) {
      dispatch(success(response));

      return Promise.resolve(response.data);
    } else {
      dispatch(failure());

      return Promise.reject(response.data);
    }
  };
}

export function updateBilling(obj) {
  const request = (obj) => { return { type: UPDATE_BILLING_REQUEST, payload: obj } };
  const success = () => { return { type: UPDATE_BILLING_SUCCESS } };
  const failure = () => { return { type: UPDATE_BILLING_FAILURE } };

  return async (dispatch, getState) => {
    dispatch(request(obj));

    let state = getState();

    let billing = {
      address: { ...state.user.billing.address, ...obj.address }
    };

    if (obj.name) {
      billing.name = obj.name;
    }

    if (obj.coupon) {
      billing.coupon = obj.coupon;
    }

    if (isEmail(obj.email || '')) {
      billing.email = obj.email;
    }

    const response = await services.updateBilling(state._users.token, billing);

    if (response) {
      dispatch(success(response));
    } else {
      dispatch(failure());
    }
  };
}

// Side effects
const services = {
  verifyEmail: async (code) => {
    try {
      const response = await axios({
        method: 'POST',
        url: `${config.apiUrl}/user/verify`,
        headers: {
          'Content-Type': 'application/json'
        },
        data: {
          code
        }
      });

      return response.data;
    } catch (e) {
      return false;
    }
  },
  resendVerificationEmail: async (token, data) => {
    try {
      const response = await axios({
        method: 'POST',
        url: `${config.apiUrl}/user/resendverify`,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        data
      });

      return response.data;
    } catch (e) {
      return false;
    }
  },
  updateApiKeys:  async (token) => {
    try {
      const response = await axios({
        method: 'POST',
        url: `${config.apiUrl}/user/newapikey`,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });

      return response.data;
    } catch (e) {
      return false;
    }
  },
  getBilling: async (token) => {
    try {
      const response = await axios({
        method: 'GET',
        url: `${config.apiUrl}/billing`,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });

      return response.data;
    } catch (e) {
      return false;
    }
  },
  getBillingInvoice: async (token) => {
    try {
      const response = await axios({
        method: 'GET',
        url: `${config.apiUrl}/billing/invoice`,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });

      return response.data;
    } catch (e) {
      return false;
    }
  },
  updateBilling: async (token, obj) => {
    try {
      const response = await axios({
        method: 'POST',
        url: `${config.apiUrl}/billing`,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        data: obj
      });

      return response.data;
    } catch (e) {
      return false;
    }
  },
  getLtdInvoice: async (token, is) => {
    try {
      const response = await axios({
        method: 'GET',
        url: `${config.apiUrl}/billing/mollie/${is}/invoice`,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });

      return response;
    } catch (e) {
      return e.response;
    }
  },
  update: async (token, data) => {
    try {
      const response = await axios({
        method: 'PUT',
        url: `${config.apiUrl}/user`,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        data
      });

      return response.data;
    } catch (e) {
      return false;
    }
  }
  // deleteAccount: async (token, password) => {
  //   try {
  //     await axios({
  //       method: 'DELETE',
  //       url: `${config.apiUrl}/user`,
  //       headers: {
  //         'Content-Type': 'application/json',
  //         'Authorization': `Bearer ${token}`
  //       },
  //       data: {
  //         password
  //       }
  //     });

  //     return true;
  //   } catch (e) {
  //     return false;
  //   }
  // }
};
