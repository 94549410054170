import styles from './../Edit.module.css';
import editStyles from 'components/Builder/Edit.module.css';

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { useDropzone } from 'react-dropzone';

import { Select, Loader, Slider } from 'ui';

import {
  uploadImage, removeImage
} from 'store/ducks/builder.js';

const alignOptions = [
  { label: 'Left', value: 'left' },
  { label: 'Center', value: 'center' },
  { label: 'Right', value: 'right' }
];

const scaleValues = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100];

function UploadArea(props) {
  const file = props.uploads.find((file) => file.ref === props.id) || null;

  const { getRootProps, getInputProps, open } = useDropzone({
    accept: 'image/jpeg, image/png, image/gif',
    multiple: false,
    noClick: true,
    noKeyboard: true,
    onDrop: (acceptedFiles) => props.uploadImage(props.id, acceptedFiles, props.field)
  });

  return (
    <div className={styles.imageUpload}>
      {!props.value && <div {...getRootProps({ className: styles.dropzone })}>
        <input {...getInputProps()} />
        {(!file) && <>
          <p>Drop your image here</p>
          <p>or</p>
          <span onClick={open}>click to upload</span>
        </>}
        {file && file.loaded !== true && <Loader size={30} />}
      </div>}

      {props.value && <div className={styles.uploaded}>
        <span className={styles.removeImage} onClick={props.remove}>Remove current image</span>
      </div>}
    </div>
  );
}

class Image extends Component {
  updateImageScale = (value) => {
    value = scaleValues[value];

    if (value > 100) {
      value = 100;
    }

    if (value < 10) {
      value = 10;
    }

    this.props.handleChange({ imageScale: value });
  }

  render() {
    const {
      field, uploads, handleChange, removeImage, uploadImage
    } = this.props;

    return (
      <>
        <div className={editStyles.row}>
          <label>Upload Image</label>

          <UploadArea uploadImage={uploadImage} value={field.value} uploads={uploads} remove={() => {
            removeImage(field.ref, field.value);
            handleChange({ value: null }, false);
          }} id={field.ref} field={field} />
        </div>

        <div className={editStyles.row}>
          <label>Image align</label>

          <Select width={294} options={alignOptions} value={
            { label: alignOptions.find((option) => option.value === field.imageAlign).label, value: field.imageAlign }
          } onChange={(selected) => handleChange({ imageAlign: selected.value }, false)} />
        </div>

        <div className={editStyles.row}>
          <label>Image scale</label>

          <div className={editStyles.slider}>
            <div className={editStyles.sliderValue} style={{ width: 55 }}>{field.imageScale}%</div>
            <Slider value={scaleValues.findIndex((index) => index === field.imageScale)}
              values={scaleValues}
              onChange={(size) => this.updateImageScale(parseInt(size))} />
          </div>
        </div>
      </>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    uploads: state.builder.uploads
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    uploadImage: (ref, file, type) => dispatch(uploadImage(ref, file, type)),
    removeImage: (ref, url) => dispatch(removeImage(ref, url))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Image);
