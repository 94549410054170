import styles from './Menu.module.css';

import React from 'react';

import {
  TitleField, SizeWeightAndColorField, CheckboxField, SizeWithColorField,
  SizeWithTypeField, SizeField, SelectField, ColorField, GradientField,
  InputStyleField, ToggleField
} from '../ThemeMenuFields.js';

const typeOptions = [
  { label: '%', value: '%' },
  { label: 'px', value: 'px' }
];

const fontWeightOptions = [
  { label: 400, value: 400 },
  { label: 600, value: 600 }
];

const alignOptions = [
  { label: 'Left', value: 'left' },
  { label: 'Center', value: 'center' },
  { label: 'Right', value: 'right' }
];

const WelcomeScreen = ({ settings, colors, onChange, onNewColor }) => {
  return (
    <>
      <TitleField title="General Screen Settings" />

      <SizeWithTypeField title="Maximum width"
        settings={settings}
        onChange={onChange}
        margin="25px 0 0 0"
        types={typeOptions}
        size="welcomeScreenWidth"
        type="welcomeScreenWidthType" />

      <SelectField title="Content alignment"
        settings={settings}
        onChange={onChange}
        options={alignOptions}
        margin="25px 0 0 0"
        value="welcomeScreenAlignment" />

      <div className={styles.doubleRow}>
        <div className={styles.left}>
          <SizeField title="Field top margin"
            settings={settings}
            onChange={onChange}
            margin="25px 0 0 0"
            size={`welcomeTopMargin`} />
        </div>
        <div className={styles.right}>
          <SizeField title="Field bottom margin"
            settings={settings}
            onChange={onChange}
            margin="25px 0 0 0"
            size={`welcomeBottomMargin`} />
        </div>
      </div>
    </>
  );
}

export default WelcomeScreen;
