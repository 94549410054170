const actions = {
  '=': (a, b) => a === b,
  '!=': (a, b) => a !== b,
  '<': (a, b) => a < b,
  '>': (a, b) => a > b,
  '>=': (a, b) => a >= b,
  '<=': (a, b) => a <= b
};

export default function checkLogic(conditions, conditionsType, calculationConditions, values, fields, variables = []) {
  let results = 0;
  let result;
  let type;
  let valueObj;
  let variableAValue;
  let variableBValue;

  if ((conditions || []).length + (calculationConditions || []).length === 0) {
    return false; // no logic
  }

  if (conditions && conditions.length > 0) {
    for (let condition of conditions) {
      if (values && values[condition.triggerField] && values[condition.triggerField].value !== null) {
        type = fields.find((f) => f._id === condition.triggerField).type;

        if (!values[condition.triggerField].visible) {
          continue;
        }

        if (type === 'radio') {
          valueObj = JSON.parse(values[condition.triggerField].value);

          if (condition.type === 'equal' && valueObj.value === condition.value) {
            results += 1;
          }

          if (condition.type === 'notequal' && valueObj.value !== condition.value) {
            results += 1;
          }
        }

        if (type === 'dropdown') {
          valueObj = values[condition.triggerField];

          if (condition.type === 'equal' && valueObj.value === condition.value) {
            results += 1;
          }

          if (condition.type === 'notequal' && valueObj.value !== condition.value) {
            results += 1;
          }
        }

        if (type === 'scale') {
          valueObj = values[condition.triggerField];

          if (condition.type === 'equal' && String(valueObj.value) === String(condition.value)) {
            results += 1;
          }

          if (condition.type === 'notequal' && String(valueObj.value) !== String(condition.value)) {
            results += 1;
          }
        }

        if (type === 'checkbox') {
          valueObj = JSON.parse(values[condition.triggerField].value);

          if (condition.type === 'equal' && valueObj.values.indexOf(condition.value) !== -1) {
            results += 1;
          }

          if (condition.type === 'notequal' && valueObj.values.indexOf(condition.value) === -1) {
            results += 1;
          }
        }

        if (type === 'imageChoice') {
          valueObj = JSON.parse(values[condition.triggerField].value);

          if (condition.type === 'equal' && valueObj.values.indexOf(condition.value) !== -1) {
            results += 1;
          }

          if (condition.type === 'notequal' && valueObj.values.indexOf(condition.value) === -1) {
            results += 1;
          }
        }
      }
    }
  }

  if (calculationConditions && calculationConditions.length > 0) {
    for (let condition of calculationConditions) {
      variableAValue = (variables.find((v) => v._id === condition.variableA) || { value: null }).value;
      variableBValue = (variables.find((v) => v._id === condition.variableB) || { value: null }).value;

      if (variableAValue === null || (condition.compare === 'variable' && variableBValue === null)) continue;

      if (condition.compare === 'number' && actions[condition.type](variableAValue, condition.value)) {
        results += 1;
      }

      if (condition.compare === 'variable' && actions[condition.type](variableAValue, variableBValue)) {
        results += 1;
      }
    }
  }

  if (conditionsType === 'any') {
    result = results > 0;
  } else if (conditionsType === 'all') {
    result = results === (conditions || []).length + (calculationConditions || []).length;
  }

  return result;
}
