import styles from './WorkspaceUsers.module.css';

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ScrollArea from 'react-scrollbar';

import {
  hideWorkspaceUsersModal
} from 'store/ducks/dashboard.js';

import {
  getUsers
} from 'store/ducks/_users.js';

import {
  updateWorkspacePermissions, resetEndpoitState
} from 'store/ducks/_workspaces.js';

import { Button, Modal, Loader, Checkbox } from 'ui';

const userDefaultPermissions = { create: false, read: false, edit: false, delete: false };
const notUserDefaultPermissions = { create: true, read: true, edit: true, delete: true };

const WorkspaceUsers = () => {
  const dispatch = useDispatch();

  const users = useSelector(state => state._users.users);
  const workspace = useSelector(state => state.dashboard.workspaceUsersModal.workspace);
  const workspaces = useSelector(state => state._workspaces.workspaces);
  const show = useSelector(state => state.dashboard.workspaceUsersModal._show);
  const getUsersLoading = useSelector(state => state._users.getUsers.loading);
  const success = useSelector(state => state._workspaces.updateWorkspacePermissions.success);
  const error = useSelector(state => state._workspaces.updateWorkspacePermissions.error);
  const selectedWorkspaces = workspaces.find((item) => item._id === workspace);

  const [permissions, setPermissions] = useState([]);

  useEffect(() => {
    dispatch(getUsers());

    if (workspace) setPermissions(selectedWorkspaces.users);
  }, [workspace]);

  const cancel = () => {
    dispatch(resetEndpoitState('updateWorkspacePermissions'));
    dispatch(hideWorkspaceUsersModal());
  }

  const submit = () => {
    dispatch(updateWorkspacePermissions(workspace, permissions));
  }

  const updatePermission = (user, key, value) => {
    let permissionsCopy = [...permissions];
    let index = permissionsCopy.findIndex((p) => p.user === user);

    const removeAllFalse = (i, arr) => {
      if (!arr[i].create && !arr[i].read && !arr[i].edit && !arr[i].delete) {
        arr.splice(i, 1);
      }

      return arr;
    };

    if (index === -1) {
      permissionsCopy.push({
        ...{
          user,
          create: false,
          read: false,
          edit: false,
          delete: false
        }, [key]: value
      });

      permissionsCopy = removeAllFalse(permissionsCopy.length - 1, permissionsCopy);
    } else {
      permissionsCopy[index][key] = value;
      permissionsCopy = removeAllFalse(index, permissionsCopy);
    }

    setPermissions(permissionsCopy);
  }

  if (success || error) {
    cancel();
  }

  return (
    <>
      <Modal isOpen={show} onRequestClose={cancel} width={600} header="Modifying workspace user permissions" footer={<>
        <Button size="small" theme="white" onClick={cancel}>Cancel</Button>
        <Button size="small" theme="blue" onClick={submit} disabled={getUsersLoading} margin="0 0 0 15px">Apply Changes</Button>
      </>}>
        <div className={styles.content}>
          {getUsersLoading && <Loader size={30} />}

          {!getUsersLoading && <>
            <ul className={styles.users}>
              <li className={styles.usersHead}>
                <div>Name</div>
                <div>Create</div>
                <div>Read</div>
                <div>Edit</div>
                <div>Delete</div>
              </li>
            </ul>

            <ScrollArea speed={0.8}
              className={styles.scrollArea}
              contentClassName={styles.scrollAreaContent}
              stopScrollPropagation={true}
              horizontal={false}>
              <ul className={styles.users}>
                {users && users.map((u) => {
                  let permission = permissions.find((p) => p.user === u._id) || {};

                  permission = {
                    ...(u.role === 'user' ? userDefaultPermissions : notUserDefaultPermissions), ...permission
                  };

                  return <li className={styles.usersBody} key={u._id}>
                    <div style={{ color: u.role === 'user' ? '#000000' : '#949494' }}>{u.email}</div>
                    <div><Checkbox value={permission.create} disabled={u.role !== 'user'} onClick={() => u.role === 'user' && updatePermission(u._id, 'create', !permission.create)} /></div>
                    <div><Checkbox value={permission.read} disabled={u.role !== 'user'} onClick={() => u.role === 'user' && updatePermission(u._id, 'read', !permission.read)} /></div>
                    <div><Checkbox value={permission.edit} disabled={u.role !== 'user'} onClick={() => u.role === 'user' && updatePermission(u._id, 'edit', !permission.edit)} /></div>
                    <div><Checkbox value={permission.delete} disabled={u.role !== 'user'} onClick={() => u.role === 'user' && updatePermission(u._id, 'delete', !permission.delete)} /></div>
                  </li>
                })}
              </ul>
            </ScrollArea>
          </>}
        </div>
      </Modal>
    </>
  );
};

export default WorkspaceUsers;
