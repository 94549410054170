import styles from './../Edit.module.css';
import editStyles from 'components/Builder/Edit.module.css';

import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';

import { TextInput, SliderMultiple, Select } from 'ui';

const columnsOptions = [
  { label: '1', value: 1 },
  { label: '2', value: 2 },
  { label: '3', value: 3 },
  { label: '4', value: 4 }
];

const defaults = {
  1: [100],
  2: [50, 50],
  3: [33, 33, 33],
  4: [25, 25, 25, 25]
};

const sizeToColumnSlicer = (values, columns) => {
  let res = [];

  for (let index in values) {
    if (parseInt(index) === 0 && columns === 1) break;
    if (parseInt(index) === 1 && columns === 2) break;
    if (parseInt(index) === 2 && columns === 3) break;
    if (parseInt(index) === 3 && columns === 4) break;

    res.push(values[index] + (res[res.length - 1] || 0));
  }

  res = res.map((value) => value > 100 ? 100 : value);

  return res;
};

const sliderToColumnSize = (values, columns) => {
  if (columns === 1) {
    return [100];
  } else if (columns === 2) {
    return [values[0], 100 - values[0]];
  } else if (columns === 3) {
    return [values[0], values[1] - values[0], 100 - values[1]];
  } else if (columns === 4) {
    return [values[0], values[1] - values[0], values[2] - values[1], 100 - values[2]];
  }
};

class Section extends Component {
  render() {
    const {
      field, handleChange
    } = this.props;

    return (
      <>
        <div className={editStyles.row}>
          <label>
            <div>Section Title</div>
          </label>
          <TextInput value={field.label} onChange={(e) => handleChange({ label: e.target.value })} />
        </div>

        <div className={editStyles.row}>
          <label>Columns</label>
          <Select width={294} options={columnsOptions} value={
            { label: (columnsOptions.find((option) => option.value === field.columns) || { label: '1' }).label, value: field.columns || 1 }
          } onChange={(selected) => handleChange({
            columns: selected.value,
            columnSizes: defaults[selected.value]
          }, false)} />
        </div>

        {field.columns > 1 && <div className={editStyles.row}>
          <label>Column sizes</label>
          <SliderMultiple values={sizeToColumnSlicer(field.columnSizes, field.columns || 1)} step={1} min={0} max={100} onChange={(values) => handleChange({ columnSizes: sliderToColumnSize(values, field.columns || 1) }, false)} />

          {field.columnSizes.map((value) => <span style={{ width: `${value}%`, display: 'inline-block', textAlign: 'center' }}>{value}%</span>)}
        </div>}

        <ReactTooltip place="right" effect="solid" />
      </>
    );
  };
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Section);
