import editStyles from 'components/Builder/Edit.module.css';

import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';

import { MentionsInput } from 'ui';

import { ReactComponent as ReferToIcon } from 'assets/images/refer-to.svg';

class Title extends Component {
  onChange = (value) => {
    this.props.handleChange({ value: value });
  };

  render() {
    return (
      <>
        <div className={editStyles.row}>
          <label>
            <div>Text</div>
            <a href="https://support.questionscout.com/en/articles/3947231-how-to-use-refer-to" target="_blank" rel="noopener noreferrer"><ReferToIcon data-tip="Refer to is available on this input, click to learn more." /></a>
          </label>

          <MentionsInput menu="static"
            initialValue={this.props.field.value}
            onChange={this.onChange}
            data={this.props.mentionsData} />
        </div>

        <ReactTooltip place="right" effect="solid" />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Title);

