import styles from './CreateEditWorkspace.module.css';

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  hideCreateEditWorkspaceModal
} from 'store/ducks/dashboard.js';

import {
  createWorkspace, updateWorkspace, getWorkspaces, resetEndpoitState
} from 'store/ducks/_workspaces.js';

import { Button, Modal, FormRow, TextInput, Loader, ColorsPalette, Select } from 'ui';

const CreateEditWorkspace = () => {
  const dispatch = useDispatch();

  const workspace = useSelector(state => state.dashboard.createEditWorkspaceModal.workspace);
  const workspaces = useSelector(state => state._workspaces.workspaces);
  const show = useSelector(state => state.dashboard.createEditWorkspaceModal._show);
  const createLoading = useSelector(state => state._workspaces.createWorkspace.loading);
  const createSuccess = useSelector(state => state._workspaces.createWorkspace.success);
  const createError = useSelector(state => state._workspaces.createWorkspace.error);
  const updateLoading = useSelector(state => state._workspaces.updateWorkspace.loading);
  const updateSuccess = useSelector(state => state._workspaces.updateWorkspace.success);
  const updateError = useSelector(state => state._workspaces.updateWorkspace.error);
  const domains = useSelector(state => state._domains.domains);
  const selectedWorkspaces = workspaces.find((item) => item._id === workspace);

  const [formName, setformName] = useState('');
  const [formDomain, setFormDomain] = useState(null);
  const [formDomainEnabled, setFormDomainEnabled] = useState(typeof formDomain === 'string');
  const [formColor, setFormColor] = useState('#BFBFBF');

  useEffect(() => {
    let domainIndex;

    if (workspace) {
      domainIndex = domains.findIndex((d) => d._id === selectedWorkspaces.domain);

      setformName(selectedWorkspaces.name);
      setFormColor(selectedWorkspaces.color);
      if (domainIndex !== -1) setFormDomain(selectedWorkspaces.domain);
      setFormDomainEnabled(domainIndex !== -1 && typeof selectedWorkspaces.domain === 'string')
    } else {
      setformName('');
      setFormColor('#BFBFBF');
      setFormDomain(null);
      setFormDomainEnabled(false)
    }
  }, [workspace]);

  const handleEnterKeyPress = (e) => {
    if (formName.length === 0 || createLoading || updateLoading || e.key !== 'Enter') return;

    submit();
  }

  const cancel = () => {
    dispatch(resetEndpoitState('createWorkspace'));
    dispatch(resetEndpoitState('updateWorkspace'));
    dispatch(hideCreateEditWorkspaceModal());

    setformName('');
    setFormColor('#BFBFBF');
  }

  const submit = () => {
    if (workspace) {
      dispatch(updateWorkspace(workspace, {
        name: formName,
        color: formColor,
        domain: formDomain
      }));
    } else {
      dispatch(createWorkspace({
        name: formName,
        color: formColor,
        domain: formDomain
      }));
    }
  }

  if (createSuccess || updateSuccess) {
    dispatch(getWorkspaces());
    cancel();
  }

  if (createError || updateError) {
    cancel();
  }

  useEffect(() => {
    if (!formDomainEnabled) setFormDomain(null);
  }, [formDomainEnabled]);

  return (
    <Modal isOpen={show} onRequestClose={cancel} width={484} header={workspace ? 'Update Workspace' : 'Creating a new Workspace'} footer={<>
      <Button size="small" theme="white" onClick={cancel} margin="0 15px 0 0">Cancel</Button>
      <Button size="small" theme="blue" onClick={submit} disabled={formName.length === 0 || createLoading || updateLoading}>{workspace ? 'Update' : 'Create'} Workspace</Button>
    </>}>
      <div className={styles.content}>
        {(createLoading || updateLoading) && <Loader size={30} />}

        {(!createLoading || !updateLoading) && <>
          <FormRow label="Workspace Name">
            <TextInput value={formName} autoFocus={true} width={'100%'} onKeyPress={handleEnterKeyPress} onChange={(e) => setformName(e.target.value)} />
          </FormRow>

          <FormRow label="Workspace Color">
            <ColorsPalette value={formColor} onChange={(color) => setFormColor(color)} />
          </FormRow>

          <FormRow label="Workspace Domain"
            showToggle={true}
            toggleValue={formDomainEnabled}
            onLabelClick={() => setFormDomainEnabled(!formDomainEnabled)}
            description={formDomain && formDomainEnabled ? `All new forms created within this Workspace will automatically have this domain assigned to it. Note that only ${domains.find((d) => d._id === formDomain).hostname} and form.questionscout.com domains will be available with this option enabled within this Workspace.` : ''}>
            <Select filter={true} maxHeight={151} disabled={!formDomainEnabled} width={484}
              options={domains.map((f) => {
                return { label: f.hostname, value: f._id };
              })}
              value={{ label: domains.findIndex((d) => d._id === formDomain) !== -1 ? domains.find((d) => d._id === formDomain).hostname : '- Select -', value: formDomain }}
              onChange={(selected) => setFormDomain(selected.value)} />
          </FormRow>
        </>}
      </div>
    </Modal >
  );
};

export default CreateEditWorkspace;
