// import './LifeTime.scss';
import styles from './LifeTime.module.css';

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { countries as euVatCountries } from 'jsvat';
import { Redirect } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { getData } from 'country-list';

import Header from '../../Header/Header.js';

import { Loader, Button, BasicLoader, Select, FormRow, TextInput } from 'ui';

import {
  getMollieOrder, createMollieOrder
} from 'store/ducks/plan.js';

import { ReactComponent as CheckmarkIcon } from 'assets/images/checkmark.svg';
import ltdLogo from 'assets/images/ltd.png';

const countries = getData().map((obj) => {
  return {
    label: obj.name.replace('Taiwan, Province of China', 'Taiwan'),
    value: obj.code
  };
});

const usersOptions = [{
  label: '10 Seats',
  value: 10
}, {
  label: '11 Seats (+$20)',
  value: 11
}, {
  label: '12 Seats (+$40)',
  value: 12
}, {
  label: '13 Seats (+$60)',
  value: 13
}, {
  label: '14 Seats (+$80)',
  value: 14
}, {
  label: '15 Seats (+$100)',
  value: 15
}, {
  label: '16 Seats (+$120)',
  value: 16
}, {
  label: '17 Seats (+$140)',
  value: 17
}, {
  label: '18 Seats (+$160)',
  value: 18
}, {
  label: '19 Seats (+$180)',
  value: 19
}, {
  label: '20 Seats (+$200)',
  value: 20
}];

const euCountries = euVatCountries.map((country) => country.codes[0]);

const plans = {
  users: {
    solo: 2,
    agency: 5,
    business: 0
  },
  domains: {
    solo: 1,
    agency: 20,
    business: 'Unlimited'
  },
  filesStorage: {
    solo: '2.5',
    agency: '5',
    business: '10'
  }
};

const LifeTime = () => {
  const dispatch = useDispatch();

  const user = useSelector(state => state._users.user);
  const userHasPlan = user.lifeTime.enabled && user.lifeTime.paid && user.lifeTime.plan;
  const exitistingOrder = (user.lifeTime.orders || []).find((order) => order.order.status === 'created');

  const tmpLtdPlan = useSelector(state => state.plan.tmpLtdPlan);
  const tmpLtdUsers = useSelector(state => state.plan.tmpLtdUsers);

  const [loading, setLoading] = useState(false);

  const [exitistingOrderUrl, setExitistingOrderUrl] = useState(null);

  const [country, setCountry] = useState(null);
  const [streetAndNumber, setStreetAndNumber] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [city, setCity] = useState('');
  const [givenName, setGivenName] = useState('');
  const [familyName, setFamilyName] = useState('');

  const [planSelected, setPlanSelected] = useState(null);
  const [plan, setPlan] = useState(null);
  const [users, setUsers] = useState(10);

  useEffect(() => {
    if (user.lifeTime.billingAddress) {
      setCountry(user.lifeTime.billingAddress.country || (user.country || null));
      setStreetAndNumber(user.lifeTime.billingAddress.streetAndNumber || '');
      setPostalCode(user.lifeTime.billingAddress.postalCode || '');
      setCity(user.lifeTime.billingAddress.city || '');
      setGivenName(user.lifeTime.billingAddress.givenName || '');
      setFamilyName(user.lifeTime.billingAddress.familyName || '');
    } else {
      setCountry(user.country || null);
    }

    setPlanSelected(userHasPlan ? false : (tmpLtdPlan || null));
    setPlan(userHasPlan ? user.lifeTime.plan : (tmpLtdPlan || null));
    setUsers(userHasPlan ? (user.lifeTime.users + 10) : (tmpLtdUsers || 10));
  }, []);

  const vatRate = euCountries.indexOf(country) === -1 ? 0 : 21;

  const isBillingDetailsCompleted = () => {
    if (!country) return false;
    if (!streetAndNumber) return false;
    if (!postalCode) return false;
    if (!city) return false;
    if (!givenName) return false;
    if (!familyName) return false;

    return true;
  };

  const proceedToPayment = () => {
    setLoading(true);

    dispatch(createMollieOrder({
      plan,
      users: users - 10,
      userHasPlan,
      vatRate,
      billingAddress: {
        givenName, familyName, streetAndNumber, postalCode, city, country,
        email: user.email
      }
    })).then((res) => {
      window.location.replace(res.url);
    });
  };

  const selectPlan = (p) => {
    setPlanSelected(p !== null);
    setPlan(p);
  };

  let prices = {
    solo: 49,
    agency: 149,
    business: 249
  };

  if (userHasPlan) {
    if (user.lifeTime.plan === 'solo') {
      prices.agency = prices.agency - prices.solo;
      prices.business = (prices.business + ((users - 10) * 20)) - prices.solo;
    }

    if (user.lifeTime.plan === 'agency') {
      prices.business = (prices.business + ((users - 10) * 20)) - prices.agency;
    }

    if (user.lifeTime.plan === 'business') {
      prices.business = (prices.business + ((users - 10) * 20)) - (prices.business + (user.lifeTime.users * 20));
    }
  } else {
    prices.business = (prices.business + ((users - 10) * 20));
  }

  let price = prices[plan];

  price = price * (vatRate / 100 + 1); // vat

  const checkStatus = (status) => {
    const paid = ['completed', 'paid', 'shipping'].indexOf(status) !== -1;

    if (paid) window.location.replace('/?ltdsuccess=true');
  };

  const getExitistingOrder = (id) => {
    dispatch(getMollieOrder(id)).then((res) => {
      checkStatus(res.status)
      setExitistingOrderUrl(res.url);
    });
  };

  useEffect(() => {
    if (exitistingOrder) {
      getExitistingOrder(exitistingOrder._id);
    }
  }, [exitistingOrder]);

  if (user && user.lifeTime && (!user.lifeTime.enabled || user.lifeTime.manual)) {
    return <Redirect to="/" />;
  }

  return <Redirect to="/" />;;

  return <>
    <div className={styles.main}>
      <Header showAccount={true} center={false} showMenu={false} />

      <div className={styles.content}>
        {exitistingOrder && <div className={styles.head}>
          <img className={styles.logo} src={ltdLogo} alt="LTDFans" height="60px" />
          <div className={styles.mainTitle}>Your payment is being processed</div>

<p>Please wait a moment while your payment is being processed. {exitistingOrderUrl && <>If you would like to check up on the payment status, you can do that <a href={exitistingOrderUrl}>here.</a></>}</p>
          <p>If you have any questions, please reach out to us using the live chat bubble in the right under corner of your screen.</p>
        </div>}

        {!exitistingOrder && !planSelected && <>
          <div className={styles.head}>
            <img className={styles.logo} src={ltdLogo} alt="LTDFans" height="60px" />
            {!userHasPlan && <>
              <div className={styles.mainTitle}>Welcome LTDFan, you’re nearly there!</div>
              <div className={styles.mainSubTitle}>Select the plan you would like to continue with</div>
            </>}
            {userHasPlan && <>
              <div className={styles.mainTitle}>Select the plan you’d like to upgrade to</div>
            </>}
          </div>

          <div className={styles.table}>
            <div className={[styles.column, styles.legend].join(' ')}>
              <div className={styles.labelSection}>USAGE</div>
              <div className={styles.label}>Forms & Surveys</div>
              <div className={styles.labelLine} />
              <div className={styles.label}>Views per month</div>
              <div className={styles.labelLine} />
              <div className={styles.label}>Submissions per month</div>
              <div className={styles.labelLine} />
              <div className={styles.label}>File upload per month</div>
              <div className={styles.labelLine} />
              <div className={styles.label}>User Seats</div>
              <div className={styles.labelLine} />
              <div className={styles.label}>Teamwork Features</div>
              <div className={styles.labelLine} />
              <div className={styles.label}>Max file size per item</div>
              <div className={styles.labelLine} />
              <div className={styles.label}>Custom Domains</div>
              <div className={styles.labelLine} />
              <div className={styles.label}>Branding Removal</div>
              <div className={styles.labelLine} />

              <div className={styles.labelSection}>USAGE</div>
              <div className={styles.label}>Native SSL</div>
              <div className={styles.labelLine} />
              <div className={styles.label}>Branding removal on forms</div>
              <div className={styles.labelLine} />
              <div className={styles.label}>Form URL Customization</div>
              <div className={styles.labelLine} />
              <div className={styles.label}>Custom Domain Support</div>
              <div className={styles.labelLine} />
              <div className={styles.label}>Add Images to forms</div>
              <div className={styles.labelLine} />
              <div className={styles.label}>Answer Piping (Refer To)</div>
              <div className={styles.labelLine} />
              <div className={styles.label}>Conditional Logic</div>
              <div className={styles.labelLine} />
              <div className={styles.label}>Form Pages</div>
              <div className={styles.labelLine} />
              <div className={styles.label}>Conversational Forms</div>
              <div className={styles.labelLine} />
              <div className={styles.label}>Advanced Themes</div>
              <div className={styles.labelLine} />
              <div className={styles.label}>Templates</div>
              <div className={styles.labelLine} />
              <div className={styles.label}>15+ Question Types</div>
              <div className={styles.labelLine} />
              <div className={styles.label}>Save & Resume</div>
              <div className={styles.labelLine} />
              <div className={styles.label}>Partial Responses</div>
              <div className={styles.labelLine} />
              <div className={styles.label}>Thank You Pages + Logic</div>
              <div className={styles.labelLine} />
              <div className={styles.label}>User Notifications + Logic</div>
              <div className={styles.labelLine} />
              <div className={styles.label}>Respondent Notifications</div>
              <div className={styles.labelLine} />
              <div className={styles.label}>Webhooks</div>
              <div className={styles.labelLine} />
              <div className={styles.label}>Custom Exports</div>
              <div className={styles.labelLine} />
              <div className={styles.label}>Responses Summary</div>
              <div className={styles.labelLine} />
              <div className={styles.label}>Submission Redirects</div>
              <div className={styles.labelLine} />
              <div className={styles.label}>Immediate Responses</div>
              <div className={styles.labelLine} />
              <div className={styles.label}>Access to all new features*</div>
              <div className={styles.labelLine} />
            </div>
            <div className={[styles.column, styles.plan].join(' ')}>
              {userHasPlan && user.lifeTime.plan === 'solo' && <div className={styles.current}>Current Plan</div>}
              <div className={styles.name}>Solo</div>
              <hr />
              <div className={styles.priceDesc}>For a one-time purchase of</div>
              <div className={styles.price}>${prices.solo}</div>
              <hr />
              <div className={styles.buttonTop}>
                <Button theme="blue" size="medium" disabled={userHasPlan && (user.lifeTime.plan === 'solo' || user.lifeTime.plan === 'agency' || user.lifeTime.plan === 'business')} onClick={() => selectPlan('solo')} fullWidth={true}>{userHasPlan ? 'Upgrade to' : 'Start with'} Solo</Button>
                {userHasPlan && (user.lifeTime.plan === 'solo' || user.lifeTime.plan === 'agency' || user.lifeTime.plan === 'business') && <div className={styles.tip} data-tip="It is not possible to downgrade your plan" />}
              </div>

              <div className={styles.value}>Unlimited</div>
              <div className={styles.value}>Unlimited</div>
              <div className={styles.value}>Unlimited</div>
              <div className={styles.value}>2.5GB</div>
              <div className={styles.value}>2 Seats</div>
              <div className={styles.value}>Included</div>
              <div className={styles.value}>50MB</div>
              <div className={styles.value}>1</div>
              <div className={styles.value}>Removed on all forms</div>
              <div className={styles.valueSpace} />

              <div className={styles.checkmark}><CheckmarkIcon /></div>
              <div className={styles.checkmark}><CheckmarkIcon /></div>
              <div className={styles.checkmark}><CheckmarkIcon /></div>
              <div className={styles.checkmark}><CheckmarkIcon /></div>
              <div className={styles.checkmark}><CheckmarkIcon /></div>
              <div className={styles.checkmark}><CheckmarkIcon /></div>
              <div className={styles.checkmark}><CheckmarkIcon /></div>
              <div className={styles.checkmark}><CheckmarkIcon /></div>
              <div className={styles.checkmark}><CheckmarkIcon /></div>
              <div className={styles.checkmark}><CheckmarkIcon /></div>
              <div className={styles.checkmark}><CheckmarkIcon /></div>
              <div className={styles.checkmark}><CheckmarkIcon /></div>
              <div className={styles.checkmark}><CheckmarkIcon /></div>
              <div className={styles.checkmark}><CheckmarkIcon /></div>
              <div className={styles.checkmark}><CheckmarkIcon /></div>
              <div className={styles.checkmark}><CheckmarkIcon /></div>
              <div className={styles.checkmark}><CheckmarkIcon /></div>
              <div className={styles.checkmark}><CheckmarkIcon /></div>
              <div className={styles.checkmark}><CheckmarkIcon /></div>
              <div className={styles.checkmark}><CheckmarkIcon /></div>
              <div className={styles.checkmark}><CheckmarkIcon /></div>
              <div className={styles.checkmark}><CheckmarkIcon /></div>
              <div className={styles.checkmark}><CheckmarkIcon /></div>

              <div className={styles.buttonBottom}>
                <Button theme="blue" size="medium" disabled={userHasPlan && (user.lifeTime.plan === 'solo' || user.lifeTime.plan === 'agency' || user.lifeTime.plan === 'business')} onClick={() => selectPlan('solo')} fullWidth={true}>{userHasPlan ? 'Upgrade to' : 'Start with'} Solo</Button>
                {userHasPlan && (user.lifeTime.plan === 'solo' || user.lifeTime.plan === 'agency' || user.lifeTime.plan === 'business') && <div className={styles.tip} data-tip="It is not possible to downgrade your plan" />}
              </div>
            </div>
            <div className={[styles.column, styles.plan].join(' ')}>
              {userHasPlan && user.lifeTime.plan === 'agency' && <div className={styles.current}>Current Plan</div>}
              <div className={styles.name}>Agency</div>
              <hr />
              <div className={styles.priceDesc}>For a one-time purchase of</div>
              <div className={styles.price}>{userHasPlan && prices.agency !== 149 && <span>$149</span>}${prices.agency}</div>
              <hr />
              <div className={styles.buttonTop}>
                <Button theme="blue" size="medium" disabled={userHasPlan && (user.lifeTime.plan === 'agency' || user.lifeTime.plan === 'business')} onClick={() => selectPlan('agency')} fullWidth={true}>{userHasPlan ? 'Upgrade to' : 'Start with'} Agency</Button>
                {userHasPlan && (user.lifeTime.plan === 'agency' || user.lifeTime.plan === 'business') && <div className={styles.tip} data-tip="It is not possible to downgrade your plan" />}
              </div>
              <div className={styles.value}>Unlimited</div>
              <div className={styles.value}>Unlimited</div>
              <div className={styles.value}>Unlimited</div>
              <div className={styles.value}>5GB</div>
              <div className={styles.value}>5 Seats</div>
              <div className={styles.value}>Included</div>
              <div className={styles.value}>50MB</div>
              <div className={styles.value}>20</div>
              <div className={styles.value}>Removed on all forms</div>
              <div className={styles.valueSpace} />

              <div className={styles.checkmark}><CheckmarkIcon /></div>
              <div className={styles.checkmark}><CheckmarkIcon /></div>
              <div className={styles.checkmark}><CheckmarkIcon /></div>
              <div className={styles.checkmark}><CheckmarkIcon /></div>
              <div className={styles.checkmark}><CheckmarkIcon /></div>
              <div className={styles.checkmark}><CheckmarkIcon /></div>
              <div className={styles.checkmark}><CheckmarkIcon /></div>
              <div className={styles.checkmark}><CheckmarkIcon /></div>
              <div className={styles.checkmark}><CheckmarkIcon /></div>
              <div className={styles.checkmark}><CheckmarkIcon /></div>
              <div className={styles.checkmark}><CheckmarkIcon /></div>
              <div className={styles.checkmark}><CheckmarkIcon /></div>
              <div className={styles.checkmark}><CheckmarkIcon /></div>
              <div className={styles.checkmark}><CheckmarkIcon /></div>
              <div className={styles.checkmark}><CheckmarkIcon /></div>
              <div className={styles.checkmark}><CheckmarkIcon /></div>
              <div className={styles.checkmark}><CheckmarkIcon /></div>
              <div className={styles.checkmark}><CheckmarkIcon /></div>
              <div className={styles.checkmark}><CheckmarkIcon /></div>
              <div className={styles.checkmark}><CheckmarkIcon /></div>
              <div className={styles.checkmark}><CheckmarkIcon /></div>
              <div className={styles.checkmark}><CheckmarkIcon /></div>
              <div className={styles.checkmark}><CheckmarkIcon /></div>

              <div className={styles.buttonBottom}>
                <Button theme="blue" size="medium" disabled={userHasPlan && (user.lifeTime.plan === 'agency' || user.lifeTime.plan === 'business')} onClick={() => selectPlan('agency')} fullWidth={true}>{userHasPlan ? 'Upgrade to' : 'Start with'} Agency</Button>
                {userHasPlan && (user.lifeTime.plan === 'agency' || user.lifeTime.plan === 'business') && <div className={styles.tip} data-tip="It is not possible to downgrade your plan" />}
              </div>
            </div>
            <div className={[styles.column, styles.plan].join(' ')}>
              {userHasPlan && user.lifeTime.plan === 'business' && <div className={styles.current}>Current Plan</div>}
              <div className={styles.name}>Business</div>
              <hr />
              <div className={styles.priceDesc}>For a one-time purchase of</div>
              <div className={styles.price}>{userHasPlan && <span>${249 + ((users - 10) * 20)}</span>}${prices.business >= 0 ? prices.business : 0}</div>
              <hr />
              <div className={styles.buttonTop}>
                <Button theme="blue" size="medium" disabled={userHasPlan && user.lifeTime.plan === 'business' && (users - 10) <= user.lifeTime.users} onClick={() => selectPlan('business')} fullWidth={true}>{userHasPlan ? 'Upgrade to' : 'Start with'} Business</Button>
                {userHasPlan && user.lifeTime.plan === 'business' && (users - 10) <= user.lifeTime.users && <div className={styles.tip} data-tip="Add more users to your Business plan to continue" />}
              </div>
              <div className={styles.value}>Unlimited</div>
              <div className={styles.value}>Unlimited</div>
              <div className={styles.value}>Unlimited</div>
              <div className={styles.value}>10GB</div>
              <div className={[styles.value, styles.dropdown].join(' ')}>
                <Select width="100%" options={usersOptions} value={
                  { label: (usersOptions.find((user) => user.value === users) || { label: '- Select -' }).label, value: usersOptions }
                } onChange={(selected) => setUsers(selected.value)} />
              </div>
              <div className={styles.value}>Included</div>
              <div className={styles.value}>50MB</div>
              <div className={styles.value}>Unlimited</div>
              <div className={styles.value}>Removed on all forms</div>
              <div className={styles.valueSpace} />

              <div className={styles.checkmark}><CheckmarkIcon /></div>
              <div className={styles.checkmark}><CheckmarkIcon /></div>
              <div className={styles.checkmark}><CheckmarkIcon /></div>
              <div className={styles.checkmark}><CheckmarkIcon /></div>
              <div className={styles.checkmark}><CheckmarkIcon /></div>
              <div className={styles.checkmark}><CheckmarkIcon /></div>
              <div className={styles.checkmark}><CheckmarkIcon /></div>
              <div className={styles.checkmark}><CheckmarkIcon /></div>
              <div className={styles.checkmark}><CheckmarkIcon /></div>
              <div className={styles.checkmark}><CheckmarkIcon /></div>
              <div className={styles.checkmark}><CheckmarkIcon /></div>
              <div className={styles.checkmark}><CheckmarkIcon /></div>
              <div className={styles.checkmark}><CheckmarkIcon /></div>
              <div className={styles.checkmark}><CheckmarkIcon /></div>
              <div className={styles.checkmark}><CheckmarkIcon /></div>
              <div className={styles.checkmark}><CheckmarkIcon /></div>
              <div className={styles.checkmark}><CheckmarkIcon /></div>
              <div className={styles.checkmark}><CheckmarkIcon /></div>
              <div className={styles.checkmark}><CheckmarkIcon /></div>
              <div className={styles.checkmark}><CheckmarkIcon /></div>
              <div className={styles.checkmark}><CheckmarkIcon /></div>
              <div className={styles.checkmark}><CheckmarkIcon /></div>
              <div className={styles.checkmark}><CheckmarkIcon /></div>

              <div className={styles.buttonBottom}>
                <Button theme="blue" size="medium" disabled={userHasPlan && user.lifeTime.plan === 'business' && (users - 10) <= user.lifeTime.users} onClick={() => selectPlan('business')} fullWidth={true}>{userHasPlan ? 'Upgrade to' : 'Start with'} Business</Button>
                {userHasPlan && user.lifeTime.plan === 'business' && (users - 10) <= user.lifeTime.users && <div className={styles.tip} data-tip="Add more users to your Business plan to continue" />}
              </div>
            </div>
          </div>

          <div className={styles.faq}>* Any new feature that has additional costs associated are not included by default.</div>

          <ReactTooltip place="top" effect="solid" />
        </>}

        {!exitistingOrder && planSelected && <>
          <div className={styles.head} style={{ margin: '0 0 30px 0' }}>
            <div className={styles.mainTitle}>You’ve nearly {userHasPlan ? 'upgraded' : 'activated'} your <strong>Lifetime Deal!</strong></div>
            <div className={styles.mainSubTitle}>We just need a couple more details before you can get {userHasPlan ? 'going' : 'started'}</div>
          </div>

          <div className={styles.box}>
            <div className={styles.billingInfo}>
              <div className={styles.title}>Your billing details</div>
              <div className={styles.subTitle}>This information will be used on your invoice.</div>

              <div className={styles.formRow50}>
                <FormRow label="First Name" required={true}>
                  <TextInput value={givenName} name="First Name" width={'100%'} onChange={(e) => setGivenName(e.target.value)} />
                </FormRow>
                <FormRow label="Last Name" required={true}>
                  <TextInput value={familyName} name="Last Name" width={'100%'} onChange={(e) => setFamilyName(e.target.value)} />
                </FormRow>
              </div>

              <FormRow label="Street Address" required={true}>
                <TextInput value={streetAndNumber} name="Street Address" width={'100%'} onChange={(e) => setStreetAndNumber(e.target.value)} />
              </FormRow>

              <FormRow label="Postal Code" required={true}>
                <TextInput value={postalCode} name="Postal Code" width={'100%'} onChange={(e) => setPostalCode(e.target.value)} />
              </FormRow>

              <FormRow label="City" required={true}>
                <TextInput value={city} name="City" width={'100%'} onChange={(e) => setCity(e.target.value)} />
              </FormRow>

              <FormRow label="Country" required={true}>
                <Select width="100%" maxHeight={180} filter={true} options={countries} className={styles.country} value={
                  { label: (countries.find((c) => c.value === country) || { label: '- Select -' }).label, value: country }
                } onChange={(selected) => setCountry(selected.value)} />
              </FormRow>

              <Button theme="blue" size="medium" className={styles.payBtn} disabled={loading || !isBillingDetailsCompleted()} onClick={() => proceedToPayment()} fullWidth={true}>Proceed to payment</Button>

              {loading && <Loader className={styles.loader} size={50} background="rgba(255, 255, 255, 0.5)" />}
            </div>
            <div className={styles.summary}>
              <div className={styles.sticky}>

                <div className={styles.title}>
                  <span>{plan} LTDF Plan</span>
                  <Button size="small" theme="white" onClick={() => selectPlan(null)}>Edit</Button>
                </div>

                <ul>
                  <li>All Features</li>
                  <li>Unlimited Forms</li>
                  <li>Unlimited Responses</li>
                  <li>Unlimited Views</li>
                  <li>Unlimited Submissions</li>
                  <li>{plans.users[plan] + (plan === 'business' ? users : 0)} User Seats</li>
                  <li>{plans.domains[plan]} Custom Domains{plan !== 'solo' ? '' : 's'}</li>
                  <li>{plans.filesStorage[plan]}GB Monthly File Upload </li>
                </ul>

                <div className={styles.total}>
                  <div className={styles.totalLabel}>Total <span>{vatRate}% tax included</span></div>
                  <div className={styles.totalPrice}>${price.toFixed(2)}</div>
                </div>

                <div className={styles.description}>This payment is an exclusive, one-off lifetime deal offer, with a 30-day refund window.</div>

                {loading && <Loader className={styles.loader} size={30} background="rgba(255, 255, 255, 0.5)" />}
              </div>
            </div>
          </div>
        </>}
      </div>
    </div>
  </>;
};

export default LifeTime;
