import styles from './Menu.module.css';

import React from 'react';

import {
  TitleField, SizeWeightAndColorField, CheckboxField, SizeWithColorField,
  SizeWithTypeField, SizeField, SelectField, ColorField, GradientField,
  ToggleField, CopyField
} from './../ThemeMenuFields.js';

const typeOptions = [
  { label: '%', value: '%' },
  { label: 'px', value: 'px' }
];

const fontWeightOptions = [
  { label: 400, value: 400 },
  { label: 600, value: 600 }
];

const alignOptions = [
  { label: 'Left', value: 'left' },
  { label: 'Center', value: 'center' },
  { label: 'Right', value: 'right' }
];

const Rows = ({ title, subTitle, id, type, settings, colors, onChange, onNewColor, onCopy }) => {
  return <>
    <TitleField title={title} subTitle={subTitle} />

    {subTitle !== 'Idle' && <ToggleField label={`Different styling on ${subTitle}`}
      settings={settings}
      onChange={onChange}
      margin="25px 0 0 0"
      value={`${id}ButtonEnable`} />}

    {(subTitle === 'Idle' || settings[`${id}ButtonEnable`]) && <>
      {subTitle === 'Hover' && <CopyField onCopy={onCopy}
        margin="15px 0 0 0"
        id={id}
        values={['ButtonColor', 'ButtonBorderColor', 'ButtonBackground', 'ButtonGradient', 'ButtonSecondBackground', 'ButtonGradientDirection', 'ButtonShadow', 'ButtonShadowColor', 'ButtonShadowOffsetX', 'ButtonShadowOffsetY', 'ButtonShadowBlur', 'ButtonShadowSpread']}
        options={[
          { label: 'Idle', value: `${type}Idle` }
        ]} />}

      {subTitle === 'Active' && <CopyField onCopy={onCopy}
        margin="15px 0 0 0"
        id={id}
        values={['ButtonColor', 'ButtonBorderColor', 'ButtonBackground', 'ButtonGradient', 'ButtonSecondBackground', 'ButtonGradientDirection', 'ButtonShadow', 'ButtonShadowColor', 'ButtonShadowOffsetX', 'ButtonShadowOffsetY', 'ButtonShadowBlur', 'ButtonShadowSpread']}
        options={[
          { label: 'Idle', value: `${type}Idle` },
          { label: 'Hover', value: `${type}Hover` }
        ]} />}

      {subTitle === 'Idle' && <SizeWeightAndColorField title="Text"
        settings={settings}
        colors={colors}
        onChange={onChange}
        onNewColor={onNewColor}
        weights={fontWeightOptions}
        size={`${id}ButtonFontSize`}
        weight={`${id}ButtonWeight`}
        color={`${id}ButtonColor`} />}

      {subTitle !== 'Idle' && <ColorField title="Text color"
        settings={settings}
        colors={colors}
        onChange={onChange}
        onNewColor={onNewColor}
        margin="25px 0 0 0"
        color={`${id}ButtonColor`} />}

      {subTitle === 'Idle' && <SizeWithColorField title="Border"
        settings={settings}
        colors={colors}
        onChange={onChange}
        onNewColor={onNewColor}
        margin="10px 0 0 0"
        size={`${id}ButtonBorderSize`}
        color={`${id}ButtonBorderColor`} />}

      {subTitle !== 'Idle' && <ColorField title="Border color"
        settings={settings}
        colors={colors}
        onChange={onChange}
        onNewColor={onNewColor}
        margin="25px 0 0 0"
        color={`${id}ButtonBorderColor`} />}

      {subTitle === 'Idle' && <SizeWithTypeField title="Roundness"
        settings={settings}
        onChange={onChange}
        margin="25px 0 0 0"
        types={typeOptions}
        size={`${id}ButtonRoundness`}
        type={`${id}ButtonRoundnessType`} />}

      {subTitle === 'Idle' && <SizeField title="Height"
        settings={settings}
        onChange={onChange}
        margin="25px 0 0 0"
        size={`${id}ButtonHeight`} />}

      <ColorField title="Background color"
        settings={settings}
        colors={colors}
        onChange={onChange}
        onNewColor={onNewColor}
        margin="25px 0 0 0"
        color={`${id}ButtonBackground`} />

      <CheckboxField label="Use gradient as background"
        settings={settings}
        onChange={onChange}
        margin="25px 0 0 0"
        value={`${id}ButtonGradient`} />

      {settings[`${id}ButtonGradient`] && <>
        <ColorField title="Secondary background color"
          settings={settings}
          colors={colors}
          onChange={onChange}
          onNewColor={onNewColor}
          margin="10px 0 0 0"
          color={`${id}ButtonSecondBackground`} />

        <GradientField title="Gradient direction"
          settings={settings}
          onChange={onChange}
          margin="25px 0 0 0"
          direction={`${id}ButtonGradientDirection`} />
      </>}

      <CheckboxField label="Add shadows"
        settings={settings}
        onChange={onChange}
        margin="25px 0 0 0"
        value={`${id}ButtonShadow`} />

      {settings[`${id}ButtonShadow`] && <>
        <ColorField title="Shadow color"
          settings={settings}
          colors={colors}
          onChange={onChange}
          onNewColor={onNewColor}
          margin="10px 0 0 0"
          color={`${id}ButtonShadowColor`} />

        <SizeField title="Shadow offset left"
          settings={settings}
          onChange={onChange}
          margin="25px 0 0 0"
          size={`${id}ButtonShadowOffsetX`} />

        <SizeField title="Shadow offset top"
          settings={settings}
          onChange={onChange}
          margin="25px 0 0 0"
          size={`${id}ButtonShadowOffsetY`} />

        <SizeField title="Shadow blur"
          settings={settings}
          onChange={onChange}
          margin="25px 0 0 0"
          size={`${id}ButtonShadowBlur`} />

      <SizeField title="Input shadow spread"
          settings={settings}
          onChange={onChange}
          margin="25px 0 0 0"
          size={`${id}ButtonShadowSpread`} />
      </>}
    </>}
  </>;
};

const Buttons = ({ settings, colors, onChange, onNewColor, onCopy }) => {
  return (
    <>
      <TitleField title="General Field Settings" />

      <SizeWithTypeField title="Maximum field width"
        settings={settings}
        onChange={onChange}
        margin="25px 0 0 0"
        types={typeOptions}
        size="buttonsMaxWidth"
        type="buttonsMaxWidthType" />

      <SelectField title="Field alignment"
        settings={settings}
        onChange={onChange}
        options={alignOptions}
        margin="25px 0 0 0"
        value="buttonsAlignment" />

      <div className={styles.doubleRow}>
        <div className={styles.left}>
          <SizeField title="Field top margin"
            settings={settings}
            onChange={onChange}
            margin="25px 0 0 0"
            size={`buttonsTopMargin`} />
        </div>
        <div className={styles.right}>
          <SizeField title="Field bottom margin"
            settings={settings}
            onChange={onChange}
            margin="25px 0 0 0"
            size={`buttonsBottomMargin`} />
        </div>
      </div>

      {/* Submit Button */}
      <Rows title="Submit/Next Button Styling" subTitle="Idle" type="submit" id="submitIdle" settings={settings} colors={colors} onNewColor={onNewColor} onChange={onChange} onCopy={onCopy} />
      <Rows title="Submit/Next Button Styling" subTitle="Hover" type="submit" id="submitHover" settings={settings} colors={colors} onNewColor={onNewColor} onChange={onChange} onCopy={onCopy} />
      <Rows title="Submit/Next Button Styling" subTitle="Active" type="submit" id="submitActive" settings={settings} colors={colors} onNewColor={onNewColor} onChange={onChange} onCopy={onCopy} />

      {/* Previous Page Button */}
      <Rows title="Previous Page Buttons Styling" subTitle="Idle" type="prevPage" id="prevPageIdle" settings={settings} colors={colors} onNewColor={onNewColor} onChange={onChange} onCopy={onCopy} />
      <Rows title="Previous Page Buttons Styling" subTitle="Hover" type="prevPage" id="prevPageHover" settings={settings} colors={colors} onNewColor={onNewColor} onChange={onChange} onCopy={onCopy} />
      <Rows title="Previous Page Buttons Styling" subTitle="Active" type="prevPage" id="prevPageActive" settings={settings} colors={colors} onNewColor={onNewColor} onChange={onChange} onCopy={onCopy} />
    </>
  );
}

export default Buttons;
