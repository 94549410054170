import styles from './AddEditConnectedModal.module.css';

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import validator from 'validator';

import {
  hideAddEditConnectedModal
} from 'store/ducks/user.js';

import {
  createIntegration
} from 'store/ducks/_users.js';

import { Button, Modal, FormRow, TextInput, Loader, Select } from 'ui';

const servicesOptions = [
  { label: 'PayPal', value: 'paypal' },
  { label: 'Stripe', value: 'stripe' },
  { label: 'Mollie', value: 'mollie' },
  { label: 'Authorize.Net', value: 'authorizeNet' }
];

const AddEditConnectedModal = () => {
  const dispatch = useDispatch();

  const show = useSelector(state => state.user.addEditConnectedModal._show);
  const userId = useSelector(state => state.user.addEditConnectedModal.user);
  const users = useSelector(state => state._users.users);

  const user = useSelector(state => state._users.user);

  const createUserLoading = useSelector(state => state._users.createUser.loading);
  const createUserSuccess = useSelector(state => state._users.createUser.success);
  const createUserError = useSelector(state => state._users.createUser.error);

  const updateUserLoading = useSelector(state => state._users.updateUser.loading);
  const updateUserSuccess = useSelector(state => state._users.updateUser.success);
  const updateUserError = useSelector(state => state._users.updateUser.error);

  const [name, setName] = useState('');
  const [provider, setProvider] = useState(null);
  const [payPalEmail, setPayPalEmail] = useState('');
  const [stripePublishableKey, setStripePublishableKey] = useState('');
  const [mollieApiKey, setMollieApiKey] = useState('');
  const [authorizeNetApiLoginId, setAuthorizeNetApiLoginId] = useState('');
  const [authorizeNetApiTransactionKey, setAuthorizeNetApiTransactionKey] = useState('');

  const exceed = !userId && user.planUsage.users >= user.plan.users;

  useEffect(() => {

  }, []);

  const handleEnterKeyPress = (e) => {
    if (e.key !== 'Enter') return;

    submit();
  };

  const cancel = () => {
    // dispatch(resetEndpoitState('createUser'));
    dispatch(hideAddEditConnectedModal());
  };

  const submit = () => {

  };

  const connect = (value) => {
    let data = { name, provider: value, credentials: {} };

    switch (value) {
      case 'stripe':
        data.credentials.publishableKey = stripePublishableKey;
        break;
      case 'paypal':
        data.credentials.email = payPalEmail;
        break;
      case 'mollie':
        data.credentials.apiKey = mollieApiKey;
        break;
      case 'authorizeNet':
        data.credentials.apiLoginId = authorizeNetApiLoginId;
        data.credentials.apiTransactionKey = authorizeNetApiTransactionKey;
        break;
      default:
        break;
    }

    dispatch(createIntegration(data));
  };

  const isValid = (value) => {
    switch (value) {
      case 'stripe':
        return stripePublishableKey;
      case 'paypal':
        return payPalEmail;
      case 'mollie':
        return mollieApiKey;
      case 'authorizeNet':
        return authorizeNetApiLoginId && authorizeNetApiTransactionKey;
      default:
        return false;
    }
  };

  return (
    <>
      {!exceed && <Modal isOpen={show} onRequestClose={cancel} width={500} header="Adding a payment method account" footer={<>
        <Button size="small" theme="white" onClick={cancel} margin="0 15px 0 0">Cancel</Button>
        <Button size="small" theme="blue" onClick={submit} disabled={!isValid(provider)} onClick={() => connect(provider)}>Add Payment Account</Button>
      </>}>
        <div className={styles.content}>
          {(createUserLoading || updateUserLoading) && <Loader size={30} />}

          {!createUserLoading && !updateUserLoading && <>
            <FormRow label="Name">
              <TextInput value={name} autoFocus={true} onChange={(e) => setName(e.target.value)} />
            </FormRow>

            <FormRow label="Provider">
              <Select width={'100%'} options={servicesOptions} value={
                { label: (servicesOptions.find((option) => option.value === provider) || { label: '- Select -' }).label, value: provider || null }
              } onChange={(selected) => setProvider(selected.value)} />
            </FormRow>

            {provider && <hr />}

            {provider === 'stripe' && <>
              <FormRow label="Publishable key">
                <TextInput value={stripePublishableKey} type="email" placeholder="pk_" onChange={(e) => setStripePublishableKey(e.target.value)} />
              </FormRow>
            </>}

            {provider === 'paypal' && <>
              <FormRow label="Email">
                <TextInput value={payPalEmail} onChange={(e) => setPayPalEmail(e.target.value)} />
              </FormRow>
            </>}

            {provider === 'mollie' && <>
              <FormRow label="API key">
                <TextInput value={mollieApiKey} onChange={(e) => setMollieApiKey(e.target.value)} />
              </FormRow>
            </>}

            {provider === 'authorizeNet' && <>
              <FormRow label="API Login ID">
                <TextInput value={authorizeNetApiLoginId} onChange={(e) => setAuthorizeNetApiLoginId(e.target.value)} />
              </FormRow>

              <FormRow label="API Transaction Key">
                <TextInput value={authorizeNetApiTransactionKey} onChange={(e) => setAuthorizeNetApiTransactionKey(e.target.value)} />
              </FormRow>
            </>}
          </>}
        </div>
      </Modal>}
    </>
  );
};

export default AddEditConnectedModal;
