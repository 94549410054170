import styles from './Calculations.module.css';

import React, { Component } from 'react';
import { connect } from 'react-redux';

import Edit from './Edit.js';
import Preview from '../Preview.js';
import InputBox from './../Build/InputBox/InputBox.js';
import SectionBox from './../Build/SectionBox/SectionBox.js';

import {
  updateEditedFieldRef, toggleFormPreview
} from 'store/ducks/builder.js';

import { Button } from 'ui';

import { ReactComponent as LogicOnIcon } from 'assets/images/logic-on.svg';
import { ReactComponent as LogicOffIcon } from 'assets/images/logic-off.svg';

const empty = new Map([
  ['root', { _id: 'root', childrens: [] }]
]);

class Calculations extends Component {
  editField = (ref, type) => {
    if (this.props.selectedEmptyBox || type === 'submit') {
      return;
    }

    this.props.updateEditedFieldRef(this.props.editedFieldRef === ref ? null : ref);
  }

  getConditionsStats = (field) => {
    if (field.calculations.length === 0) {
      return false;
    }

    let results = { on: 0, off: 0 };
    let isNumberField = field.type === 'shortText' && field.format === 'number';

    for (let calculation of field.calculations) {
      if ((!isNumberField && calculation.option === null || calculation.target === null || calculation.value === null) || (isNumberField && calculation.target === null)) {
        results.off += 1;
      } else {
        results.on += 1;
      }
    }

    return results;
  }

  itemElements = (fields) => {
    const fieldsCopy = JSON.parse(JSON.stringify(fields));
    const rootChildrens = fieldsCopy.filter((field) => field.section === 'root').map((field) => field._id);
    const mapCopy = new Map(empty.entries());

    for (let field of fieldsCopy) {
      if (field.type !== 'section') field.childrens = undefined;

      mapCopy.set(field._id, field);
    }

    mapCopy.get('root').childrens = rootChildrens;

    const topLevelItems = mapCopy.get('root').childrens.map((itemId) => mapCopy.get(itemId));
    let rootIndex = 0;

    const createItemElement = (item) => {
      if (!item) return null;
      if (item.section === 'root') rootIndex += 1;

      const calculations = this.getConditionsStats(item);

      if (typeof item.childrens !== 'undefined') {
        const childItems = item.childrens.map((itemId) => mapCopy.get(itemId));
        const childItemElements = childItems.map(createItemElement);

        return <div className={styles.group}>
          <SectionBox field={item} index={rootIndex}>{childItemElements}</SectionBox>
          {item && item.type === 'pageBreak' && <>
            <div className={[
              styles.pageBreakHr
            ].join(' ')}></div>
            <div className={[
              styles.pageBreakText
            ].join(' ')}><span>START OF PAGE #{item.page + 1}</span></div>
          </>}
          {calculations && <div className={styles.calculationsIndicator} style={{ top: item.type === 'pageBreak' ? '30%' : '50%' }}>
            {calculations.on > 0 && <div className={styles.on}><LogicOnIcon /><span>{calculations.on}</span></div>}
            {calculations.off > 0 && <div className={styles.off}><LogicOffIcon style={{ top: 1 }} /><span>{calculations.off}</span></div>}
          </div>}
        </div>;
      }

      return <div className={styles.item}>
        <InputBox index={rootIndex} field={item} mode="calculations" />
        {item && item.type === 'pageBreak' && <>
          <div className={[
            styles.pageBreakHr
          ].join(' ')}></div>
          <div className={[
            styles.pageBreakText
          ].join(' ')}><span>START OF PAGE #{item.page + 1}</span></div>
        </>}
        {calculations && <div className={styles.calculationsIndicator} style={{ top: item.type === 'pageBreak' ? '30%' : '50%' }}>
          {calculations.on > 0 && <div className={styles.on}><LogicOnIcon /><span>{calculations.on}</span></div>}
          {calculations.off > 0 && <div className={styles.off}><LogicOffIcon style={{ top: 1 }} /><span>{calculations.off}</span></div>}
        </div>}
      </div>;
    };

    return topLevelItems.map(createItemElement);
  };

  openPreview = (value) => this.props.toggleFormPreview(value);

  render() {
    const {
      form, fields, showPreview
    } = this.props;

    const filtredFields = form.type === 'conversational' ? fields.filter((field) => ['pageBreak', 'divider'].indexOf(field.type) === -1) : fields;

    let page = 1;

    for (var f of fields) {
      f.page = page;

      if (f.type === 'pageBreak') {
        page += 1;
      }
    }

    let fieldsOptions = {};

    for (let f of fields) fieldsOptions[f._id] = f.options || [];

    return (
      <>
        {showPreview && <Preview />}

        <div className={styles.main}>
          <Edit />
          <div className={styles.content}>
            <Button theme="black" onClick={() => this.openPreview(!showPreview)} className={styles.previewBtn}>Preview</Button>

            <div className={styles.form}>
              {(filtredFields.length === 0) && <div className={styles.empty}>It’s looking a bit empty in here. Come back here when you'll add more fields to this form.</div>}

              {filtredFields.length > 0 && this.itemElements(filtredFields)}

              {form.type === 'classic' && <div className={styles.item}>
                <InputBox index={2048} field={{
                  ref: 'submit',
                  label: 'Submit',
                  type: 'submit',
                  section: 'root',
                  position: 2048
                }} />
              </div>}
            </div>
          </div>
        </div>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    fields: state.builder.fields,
    form: state.builder.form,
    showPreview: state.builder.showPreview
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateEditedFieldRef: (value) => dispatch(updateEditedFieldRef(value)),
    toggleFormPreview: (value) => dispatch(toggleFormPreview(value))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Calculations);
