import styles from './SectionBox.module.css';

import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { ReactComponent as EditIcon } from 'assets/images/edit.svg';
import { ReactComponent as TrashIcon } from 'assets/images/trash.svg';
import { ReactComponent as CopyIcon } from 'assets/images/copy.svg';
import { ReactComponent as DragIcon } from 'assets/images/drag.svg';

import {
  updateEditedFieldRef, updateFields, ioRemoveField, ioCopyFieldFile, updateField, ioUpdateField
} from 'store/ducks/builder.js';

export const SectionBox = React.forwardRef(({ field, index, DragHandle, children, onCopySection }, ref) => {
  const dispatch = useDispatch();

  let fieldsOptions = {};

  const fields = useSelector(state => state.builder.fields);
  const selectedEmptyBox = useSelector(state => state.builder.selectedEmptyBox);
  const selectedFieldOption = useSelector(state => state.builder.selectedFieldOption);
  const editedFieldRef = useSelector(state => state.builder.editedFieldRef);

  const removeChildrens = async (childrens, toRemove = []) => {
    for (let children of childrens) {
      const field = fields.find((f) => f._id === children);

      if (!field) continue;

      dispatch(ioRemoveField(field.ref));
      toRemove.push(field._id);
      if (field.type === 'section') toRemove.push(...(await removeChildrens(field.childrens, toRemove)));
    }

    return [...new Set(toRemove)];
  };

  const editSection = (ref) => dispatch(updateEditedFieldRef(editedFieldRef === ref ? null : ref));

  const removeSection = async (ref) => {
    let fieldsCopy = fields;
    const deletedIndex = fieldsCopy.findIndex((field) => field.ref === ref);

    if (typeof deletedIndex === 'undefined') return;

    let fieldsToRemove = [...await removeChildrens(fieldsCopy[deletedIndex].childrens), fieldsCopy[deletedIndex]._id];

    fieldsCopy.filter((field) => fieldsCopy[deletedIndex].section === field.section).map((field) => {
      if (field.position >= fieldsCopy[deletedIndex].position && fieldsCopy[deletedIndex].ref !== field.ref) {
        field.position -= 1;
      }

      return field;
    });

    fieldsCopy = fieldsCopy.filter((f) => fieldsToRemove.indexOf(f._id) === -1);

    dispatch(updateEditedFieldRef(null));
    dispatch(updateFields(fieldsCopy));
    dispatch(ioRemoveField(ref));
  };

  for (let f of fields) fieldsOptions[f._id] = f.options || [];

  return <div className={[
    styles.sectionBox,
    selectedEmptyBox !== null ? styles.selectonActive : '',
    editedFieldRef === field.ref ? styles.edited : ''
  ].join(' ')} style={{
    transform: field.section === 'root' && selectedEmptyBox !== null && selectedEmptyBox < index ? `translate(0, ${14 + (selectedFieldOption ? selectedFieldOption.height : 80)}px)` : 'none',
  }}>
    <div className={styles.label}>
      <div className={styles.text} onClick={() => editSection(field.ref)}>
        <span>{field.label} - {field.columns || 1} column{(field.columns || 1) === 1 ? '' : 's'}</span>
      </div>
      <div className={styles.actions}>
        <div className={styles.edit}><EditIcon onClick={() => editSection(field.ref, field.type)} /></div>
        <div className={styles.copy}><CopyIcon  onClick={() => onCopySection(field.ref)} /></div>
        <div className={styles.delete}><TrashIcon onClick={() => removeSection(field.ref)} /></div>
        {DragHandle && <DragHandle className={styles.drag}><div><DragIcon /></div></DragHandle>}
      </div>
    </div>

    {children}
  </div>;
});


export default SectionBox;
