/** @jsx jsx */
/* @jsxFrag React.Fragment */

import { jsx, css } from '@emotion/core';
import React, { useState, useLayoutEffect, useRef } from 'react';
import { LabelAtom, DescriptionAtom } from './atoms.js';
import isJsonString from 'helpers/isJsonString.js';
import getFieldLabel from 'helpers/getFieldLabel.js';

const alphabet = 'abcdefghijklmnopqrstuvwxyz'.split('');

const isLastInRow = (total, index) => {
  return index % total === 0;
};

const isInFirstRow = (total, index) => {
  return index <= total;
};

const getRowSize = (rows, width, mode, device, options) => {
  let size = rows || 4;

  if (options.length < size) size = options.length;

  if (width <= 700) {
    size = 2;
  }

  if (width <= 400) {
    size = 1;
  }

  if (mode === 'preview' && device === 'tablet') {
    size = 3;
  }

  if (mode === 'preview' && device === 'phone') {
    size = 1;
  }

  return size;
};


const useWindowSize = (id) => {
  const [size, setSize] = useState([0, 0]);

  useLayoutEffect(() => {
    function updateSize() {
      const size = document.getElementById(id).getBoundingClientRect();

      setSize([size.width, size.height]);
    }

    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  return size;
}

const ImageChoice = ({ form, field, theme, seed, value, values, onChange, onPartialResponse, mode, device, onNextClick, fieldsOptions, variables }) => {
  const [width] = useWindowSize(field._id);

  let options = field.options;
  const rowSize = getRowSize(field.imageChoiceRows, width, mode, device, options);

  const alignOptions = {
    left: 'flex-start',
    center: 'center',
    right: 'flex-end'
  };

  const mainStyle = css({
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: alignOptions[theme.imageChoiceAlignment],
    margin: `${theme.imageChoiceTopMargin || 0}px 0 ${theme.imageChoiceBottomMargin || 0}px 0`,
    '.ps > .ps__rail-x, .ps > .ps__rail-y': {
      opacity: 0.6
    }
  });

  const contentStyle = css({
    width: '100%',
    maxWidth: `${theme.imageChoiceWidth}${theme.imageChoiceWidthType}`
  });

  const optionsContainerStyle = css({
    listStyle: 'none',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    padding: 0,
    margin: 0
  });

  const optionsStyle = css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    alignItems: 'stretch',
    boxSizing: 'border-box',
    width: (() => {
      return {
        1: '100%',
        2: '50%',
        3: '33.3%',
        4: '25%',
        5: '20%',
        6: '16.6%'
      }[rowSize];
    })(),
  });

  const getAdditionalOptionsStyle = (index) => {
    let result = {
      paddingTop: 0,
      paddingRight: `${theme.imageChoiceIdleMargin}px`,
      paddingBottom: 0,
      paddingLeft: 0
    };

    if (rowSize === 1 || isLastInRow(rowSize, index + 1)) {
      result.paddingRight = 0;
    }

    if (!isInFirstRow(rowSize, index + 1)) {
      result.paddingTop = `${theme.imageChoiceIdleMargin}px`;
    }

    return result;
  };

  const optionsContentStyle = css({
    width: '100%',
    height: '100%',
    cursor: 'pointer',
    userSelect: 'none',
    boxSizing: 'border-box',
    position: 'relative',
    fontFamily: theme.typographyContentFontFamily,
    background: theme.imageChoiceIdleBackground,
    padding: `${theme.imageChoiceIdleVerticalPadding}px ${theme.imageChoiceIdleHorizontalPadding}px`,
    color: theme.imageChoiceIdleTextColor,
    fontWeight: theme.imageChoiceIdleTextWeight,
    fontSize: theme.imageChoiceIdleTextFontSize,
    borderRadius: `${theme.imageChoiceIdleRoundness}px`,
    border: `${theme.imageChoiceIdleBorderSize}px solid ${theme.imageChoiceIdleBorderColor}`,
    boxShadow: theme.imageChoiceIdleShadow ? `${theme.imageChoiceIdleShadowOffsetX}px ${theme.imageChoiceIdleShadowOffsetY}px ${theme.imageChoiceIdleShadowBlur}px ${theme.imageChoiceIdleShadowSpread}px ${theme.imageChoiceIdleShadowColor}` : 'none',

    '& .image': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      boxSizing: 'border-box',
      height: '128px'
    },

    '& .image img': {
      maxHeight: '100%',
      maxWidth: '100%'
    },

    '& .value': {
      lineHeight: '142%',
      boxSizing: 'border-box',
      marginTop: `${theme.imageChoiceIdleVerticalPadding}px`,
      marginBottom: `${theme.imageChoiceIdleVerticalPadding}px`
    },

    '& span': {
      position: 'absolute',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      lineHeight: '142%',
      boxSizing: 'border-box',
      fontSize: theme.imageChoiceIdleTextFontSize,
      fontWeight: '600',
      border: `${theme.imageChoiceIdleBadgeBorderSize}px solid ${theme.imageChoiceIdleBadgeBorderColor}`,
      background: theme.imageChoiceIdleBadgeBackground,
      color: theme.imageChoiceIdleBadgeTextColor,
      borderRadius: '2px',
      left: '7px',
      top: '7px',
      textAlign: 'center',
      width: `calc(1em + ${theme.imageChoiceIdleVerticalPadding}px)`
    },

    '&:hover, &.hover': (() => {
      const obj = {};

      if (theme.imageChoiceHoverEnable) {
        obj.background = theme.imageChoiceHoverBackground;
        obj.borderColor = theme.imageChoiceHoverBorderColor;
        obj.color = theme.imageChoiceHoverTextColor;
      }

      if (theme.imageChoiceHoverShadow && theme.imageChoiceHoverEnable) {
        obj.boxShadow = `${theme.imageChoiceHoverShadowOffsetX}px ${theme.imageChoiceHoverShadowOffsetY}px ${theme.imageChoiceHoverShadowBlur}px ${theme.imageChoiceHoverShadowSpread}px ${theme.imageChoiceHoverShadowColor}`;
      } else {
        obj.boxShadow = 'none';
      }

      obj['& span'] = {
        background: theme.imageChoiceHoverBadgeBackground,
        borderColor: theme.imageChoiceHoverBadgeBorderColor,
        color: theme.imageChoiceHoverBadgeTextColor
      };

      return obj;
    })(),
    '&:active, &.isActive': (() => {
      const obj = {};

      if (theme.imageChoiceActiveEnable) {
        obj.background = theme.imageChoiceActiveBackground;
        obj.borderColor = theme.imageChoiceActiveBorderColor;
        obj.color = theme.imageChoiceActiveTextColor;
      }

      if (theme.imageChoiceActiveShadow && theme.imageChoiceActiveEnable) {
        obj.boxShadow = `${theme.imageChoiceActiveShadowOffsetX}px ${theme.imageChoiceActiveShadowOffsetY}px ${theme.imageChoiceActiveShadowBlur}px ${theme.imageChoiceActiveShadowSpread}px ${theme.imageChoiceActiveShadowColor}`;
      } else {
        obj.boxShadow = 'none';
      }

      obj['& span'] = {
        background: theme.imageChoiceActiveBadgeBackground,
        borderColor: theme.imageChoiceActiveBadgeBorderColor,
        color: theme.imageChoiceActiveBadgeTextColor
      };

      return obj;
    })()
  });

  const contentRef = useRef();

  const handleOptionClick = (ref) => {
    if (field.readonly) return;

    let data = value == null ? { values: [] } : JSON.parse(value);
    let refIndex = data.values.indexOf(ref);

    if (refIndex !== -1) {
      data.values.splice(refIndex, 1);
    } else {
      data.values.push(ref);
    }

    if (field.selectionLimits && data.values.length > field.selectionLimitsMax) return;

    onChange({ [field._id]: JSON.stringify(data) });
    onPartialResponse(field._id);

    if ((field.selectionLimits && data.values.length === field.selectionLimitsMax) || (options.length === data.values.length)) {
      if (field.section === 'root' || !field.section) onNextClick(field);
    }
  };

  let onError = (e) => {
    e.target.src = e.target.src.replace(/hash=\d*/, `hash=${Date.now()}`);
  };

  return <>
    <div css={mainStyle}>
      <div css={contentStyle} ref={contentRef}>
        <LabelAtom required={field.required} error={field.error} theme={theme}>{getFieldLabel(field.label, values, 'jsx', fieldsOptions, variables)}</LabelAtom>

        <ul css={optionsContainerStyle}>
          {options.map((option, index) => {
            if (option.value === '') return;

            const optionValue = isJsonString(option.value) ? JSON.parse(option.value) : { text: '', url: null };

            if (!optionValue.url) return <></>;

            return <li key={option.ref}
              css={optionsStyle}
              style={getAdditionalOptionsStyle(index)}
              onClick={() => handleOptionClick(option.ref)}>
              <div css={optionsContentStyle} className={[
                value && JSON.parse(isJsonString(value) ? value : '{"values":[]}').values.indexOf(option.ref) !== -1 ? 'isActive' : '',
                field.highlighted === index ? 'hover' : ''
              ].join(' ')}>
                {form.type === 'conversational' && <span className="alphabet">{alphabet[index] ? alphabet[index].toUpperCase() : ''}</span>}

                <div className="image">
                  <img src={optionValue.url} onError={(e) => {
                    onError(e);
                    onError = () => { };
                  }} alt={optionValue.text} />
                </div>
                <div className="value">{optionValue.text}</div>
              </div>
            </li>;
          })}
        </ul>

        <DescriptionAtom theme={theme}>{getFieldLabel(field.description, values, 'jsx', fieldsOptions, variables)}</DescriptionAtom>
      </div>
    </div>
  </>;
};

export default ImageChoice;
