import React from 'react';

export default ({ theme, mode, device, placement, scrollbarWidth, userUiSettings, isTouchDevice }) => {
  let boxStyle = {
    position: 'fixed',
    width: '100%',
    height: '100vh',
    zIndex: 1,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    userSelect: 'none',
    overflow: 'hidden',
    boxSizing: 'border-box',
    backgroundColor: theme.backgroundColor
  };

  let imageStyle = {
    position: 'absolute',
    width: '100%',
    height: '100%',
    boxSizing: 'border-box',
    zIndex: 1,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0
  };

  if (mode === 'preview') {
    boxStyle.top = 0;
    boxStyle.left = 'auto';
    boxStyle.right = `${scrollbarWidth}px`;
    boxStyle.bottom = 'auto';

    if (placement === 'themes') {
      boxStyle.maxWidth = `calc(100% - 405px - ${scrollbarWidth}px)`;
    }

    if (placement === 'builder') {
      boxStyle.maxWidth = !userUiSettings.minimizeBuilderMenu ? `calc(100% - 490px - ${scrollbarWidth}px)` : `calc(100% - 405px - ${scrollbarWidth}px)`;
    }

    if (device === 'phone') {
      boxStyle.maxWidth = 400 - scrollbarWidth;
      boxStyle.right = 'auto';
    }

    if (device === 'tablet') {
      boxStyle.maxWidth = 800 - scrollbarWidth;
      boxStyle.right = 'auto';
    }
  }

  if (theme.backgroundImage && theme.backgroundUseImage) {
    imageStyle.backgroundImage = `url(${theme.backgroundImage})`;
    imageStyle.backgroundRepeat = 'no-repeat';
    imageStyle.backgroundSize = theme.backgroundImageLayout;
    imageStyle.backgroundPosition = theme.backgroundImagePosition;
    if (!isTouchDevice) imageStyle.backgroundAttachment = 'fixed';
    imageStyle.filter = `blur(${theme.backgroundImageBlur}px)`;
  }

  if (theme.backgroundImageBlur > 0) {
    imageStyle.transform = 'scale(1.05)';
  }

  imageStyle.opacity = theme.backgroundImageOpacity;

  return (
    <div style={boxStyle}>
      <div style={imageStyle} />
    </div>
  );
};
