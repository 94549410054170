import styles from './BuilderHeader.module.css';

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { HashLink as Link } from 'react-router-hash-link';
import debounce from 'lodash.debounce';
import scrollIntoView from 'scroll-into-view';

import {
  updateUser
} from 'store/ducks/_users.js';

import {
  ioUpdateForm, updateForm, updateEditedFieldRef
} from 'store/ducks/builder.js';

import { ReactComponent as MenuDashboardIcon } from 'assets/images/menu-dashboard.svg';
import { ReactComponent as MenuBuildIcon } from 'assets/images/menu-build.svg';
import { ReactComponent as MenuDesignIcon } from 'assets/images/menu-design.svg';
import { ReactComponent as MenuShareIcon } from 'assets/images/menu-publish.svg';
import { ReactComponent as MenuResultsIcon } from 'assets/images/menu-results.svg';
import { ReactComponent as MenuStatisticsIcon } from 'assets/images/menu-statistics.svg';
import { ReactComponent as MenuCalculationsIcon } from 'assets/images/menu-calculations.svg';
import { ReactComponent as MenuSettingsIcon } from 'assets/images/menu-settings.svg';
import { ReactComponent as MenuLogicIcon } from 'assets/images/menu-logic.svg';
import { ReactComponent as MenuMinimizeIcon } from 'assets/images/menu-minimize.svg';
import { ReactComponent as MenuMaximizeIcon } from 'assets/images/menu-maximize.svg';

class BuilderHeader extends Component {
  handleChange = (params) => {
    this.props.updateForm(params);

    this.ioUpdateForm(params);
  }

  ioUpdateForm = debounce((params) => {
    this.props.ioUpdateForm(params);
  }, 500);


  updateNameEditing = (value) => {
    this.props.updateNameEditing(value);
  }

  handleMenuClick = () => { }

  handleMinimize = () => {
    const current = this.props.user.uiSettings.minimizeBuilderMenu;

    this.props.updateUser({
      uiSettings: {
        ...this.props.user.uiSettings,
        minimizeBuilderMenu: !current
      }
    });
  }

  render() {
    const {
      id, page, tab
    } = this.props.match.params;

    const {
      user, permissions
    } = this.props;

    const minimize = user && user.uiSettings && user.uiSettings.minimizeBuilderMenu === true;

    return (
      <div className={styles.main}>

        <div className={[styles.nav, minimize ? styles.minimize : ''].join(' ')}>
          <div className={styles.top}>
            <Link to={`/`} className={[styles.button, styles.close].join(' ')}>
              <MenuDashboardIcon style={{ minHeight: 12, minWidth: 12 }} /><span>Dashboard</span>
            </Link>

            <Link to={`/builder/${id}/build`} data-not-outside="true" className={[styles.button, page === 'build' ? styles.active : '', !permissions.edit ? styles.disabled : ''].join(' ')} onClick={() => this.handleMenuClick()}>
              <MenuBuildIcon style={{ minHeight: 12, minWidth: 12 }} data-not-outside="true" /><span data-not-outside="true">Build</span>
            </Link>

            <Link to={`/builder/${id}/calculations`} data-not-outside="true" className={[styles.button, page === 'calculations' ? styles.active : '', !permissions.edit ? styles.disabled : ''].join(' ')} onClick={() => this.handleMenuClick()}>
              <MenuCalculationsIcon style={{ minHeight: 12, minWidth: 12 }} data-not-outside="true" /><span data-not-outside="true">Calculations</span>
            </Link>

            <Link to={`/builder/${id}/logic`} data-not-outside="true" className={[styles.button, page === 'logic' ? styles.active : '', !permissions.edit ? styles.disabled : ''].join(' ')} onClick={() => this.handleMenuClick()}>
              <MenuLogicIcon style={{ minHeight: 12, minWidth: 12 }} data-not-outside="true" /><span data-not-outside="true">Logic</span>
            </Link>      

            <Link to={`/builder/${id}/design`} className={[styles.button, page === 'design' ? styles.active : '', !permissions.edit ? styles.disabled : ''].join(' ')} onClick={() => this.handleMenuClick()}>
              <MenuDesignIcon style={{ minHeight: 10, minWidth: 12, marginLeft: 2 }} data-not-outside="true" /><span data-not-outside="true">Design</span>
            </Link>      
          </div>

          <div className={styles.bottom}>
            <Link to={`/builder/${id}/publish`} className={[styles.button, page === 'publish' ? styles.active : '', !permissions.edit ? styles.disabled : ''].join(' ')} onClick={() => this.handleMenuClick()}>
              <MenuShareIcon style={{ minHeight: 12, minWidth: 12, marginLeft: 1 }} /><span>Share</span>
            </Link>

            <Link to={`/builder/${id}/settings/general`} className={[styles.button, page === 'settings' ? styles.active : '', !permissions.edit ? styles.disabled : ''].join(' ')} onClick={() => this.handleMenuClick()}>
              <MenuSettingsIcon style={{ minHeight: 12, minWidth: 12, marginLeft: 1  }} /><span>Settings</span>
            </Link>

            {/* <Link to={`/builder/${id}/statistics`} className={[styles.button, page === 'statistics' ? styles.active : '', !permissions.read ? styles.disabled : ''].join(' ')} onClick={() => this.handleMenuClick()}>
              <MenuStatisticsIcon style={{ minHeight: 12, minWidth: 16 }} /><span>Statistics</span>
            </Link> */}

            <Link to={`/builder/${id}/results/responses`} className={[styles.button, page === 'results' ? styles.active : '', !permissions.read ? styles.disabled : ''].join(' ')} onClick={() => this.handleMenuClick()}>
              <MenuResultsIcon style={{ minHeight: 12, minWidth: 13, marginLeft: 1  }} /><span>Replies</span>
            </Link>

            <div className={[styles.button, styles.minimize].join(' ')} onClick={this.handleMinimize}>
              {!minimize && <MenuMinimizeIcon style={{ minHeight: 14, minWidth: 14 }} />}
              {minimize && <MenuMaximizeIcon style={{ minHeight: 14, minWidth: 14 }} />}
            </div>
          </div>
        </div>

        {page === 'statistics' && <div className={[styles.subnav].join(' ')}>
          <div className={styles.header}>Statistics</div>

          <div className={styles.dropdown}>
            <Link to={`/builder/${id}/statistics#overall-stats`}
              scroll={(el) => scrollIntoView(el, { align: { top: 0.1 } })}
              className={styles.link}
              onClick={() => this.handleMenuClick()}>
              Overall Stats
            </Link>
            {/* <Link to={`/builder/${id}/statistics#field-bottlenecks`}
              scroll={(el) => scrollIntoView(el, { align: { top: 0.1 } })}
              className={styles.link}
              onClick={() => this.handleMenuClick()}>
              Field Bottlenecks
            </Link> */}
            <Link to={`/builder/${id}/statistics#location-overview`}
              scroll={(el) => scrollIntoView(el, { align: { top: 0.1 } })}
              className={styles.link}
              onClick={() => this.handleMenuClick()}>
              Location Overview
            </Link>
            <Link to={`/builder/${id}/statistics#device-browser-types`}
              scroll={(el) => scrollIntoView(el, { align: { top: 0.1 } })}
              className={styles.link}
              onClick={() => this.handleMenuClick()}>
              Device & Browser Types
            </Link>
          </div>
        </div>}

        {page === 'results' && <div className={[styles.subnav].join(' ')}>
          <div className={styles.header}>Results</div>
          <div className={styles.dropdown}>
            <Link to={`/builder/${id}/results/responses`} className={[styles.link, tab === 'responses' ? styles.active : ''].join(' ')}>Responses</Link>
            <Link to={`/builder/${id}/results/summary`} className={[styles.link, tab === 'summary' ? styles.active : ''].join(' ')}>Summary</Link>
          </div>
        </div>}

        {page === 'settings' && <div className={[styles.subnav].join(' ')}>
          <div className={styles.header}>Settings</div>
          <div className={styles.dropdown}>
            <Link to={`/builder/${id}/settings/general`} className={[styles.link, tab === 'general' ? styles.active : ''].join(' ')}>General</Link>
            <Link to={`/builder/${id}/settings/welcome`} className={[styles.link, tab === 'welcome' ? styles.active : ''].join(' ')}>Welcome Page</Link>
            <Link to={`/builder/${id}/settings/thankyou`} className={[styles.link, tab === 'thankyou' ? styles.active : ''].join(' ')}>Thank You Pages</Link>
            <Link to={`/builder/${id}/settings/notifications`} className={[styles.link, tab === 'notifications' ? styles.active : ''].join(' ')}>Notifications</Link>
            <Link to={`/builder/${id}/settings/messaging`} className={[styles.link, tab === 'messaging' ? styles.active : ''].join(' ')}>Messaging</Link>
            <Link to={`/builder/${id}/settings/seo`} className={[styles.link, tab === 'seo' ? styles.active : ''].join(' ')}>SEO</Link>
            <Link to={`/builder/${id}/settings/integrations`} className={[styles.link, tab === 'integrations' ? styles.active : ''].join(' ')}>Integrations</Link>
            <Link to={`/builder/${id}/settings/domain`} className={[styles.link, tab === 'domain' ? styles.active : ''].join(' ')}>Domain</Link>
          </div>
        </div>}
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    user: state._users.user,
    permissions: state.builder.form.permissions
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateForm: (params) => dispatch(updateForm(params)),
    ioUpdateForm: (params) => dispatch(ioUpdateForm(params)),
    updateEditedFieldRef: (value) => dispatch(updateEditedFieldRef(value)),
    updateUser: (params, options) => dispatch(updateUser(params, options))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BuilderHeader);
