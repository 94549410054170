/** @jsx jsx */
/* @jsxFrag React.Fragment */

import { jsx, css } from '@emotion/core';
import React, { useState, useMemo, useRef } from 'react';
import { LabelAtom, DescriptionAtom } from './atoms.js';
import ShortText from './ShortText.js';
import isJsonString from 'helpers/isJsonString.js';
import getFieldLabel from 'helpers/getFieldLabel.js';
import Translate from '../messages/Translate.js';

const alphabet = 'abcdefghijklmnopqrstuvwxyz'.split('');

const Checkbox = ({ form, field, theme, seed, value, values, onChange, onPartialResponse, onNextClick, fieldsOptions, variables }) => {
  const translate = new Translate(form.messages);

  const [showOther, setShowOther] = useState(false);

  let options = field.options;

  const alignOptions = {
    left: 'flex-start',
    center: 'center',
    right: 'flex-end'
  };

  const mainStyle = css({
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: alignOptions[theme.singleMultipleChoiceAlignment],
    margin: `${theme.singleMultipleChoiceTopMargin || 0}px 0 ${theme.singleMultipleChoiceBottomMargin || 0}px 0`,
    '.ps > .ps__rail-x, .ps > .ps__rail-y': {
      opacity: 0.6
    }
  });

  const contentStyle = css({
    width: '100%',
    maxWidth: `${theme.singleMultipleChoiceWidth}${theme.singleMultipleChoiceWidthType}`
  });

  const optionsContainerStyle = css({
    listStyle: 'none',
    padding: 0,
    margin: 0
  });

  const optionsStyle = css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
    cursor: 'pointer',
    userSelect: 'none',
    boxSizing: 'border-box',
    fontFamily: theme.typographyContentFontFamily,
    background: theme.singleMultipleChoiceIdleBackground,
    padding: `${theme.singleMultipleChoiceIdleVerticalPadding}px ${theme.singleMultipleChoiceIdleHorizontalPadding}px`,
    margin: `${theme.singleMultipleChoiceIdleVerticalMargin}px 0`,
    color: theme.singleMultipleChoiceIdleTextColor,
    fontWeight: theme.singleMultipleChoiceIdleTextWeight,
    fontSize: theme.singleMultipleChoiceIdleTextFontSize,
    borderRadius: `${theme.singleMultipleChoiceIdleRoundness}px`,
    border: `${theme.singleMultipleChoiceIdleBorderSize}px solid ${theme.singleMultipleChoiceIdleBorderColor}`,
    boxShadow: theme.singleMultipleChoiceIdleShadow ? `${theme.singleMultipleChoiceIdleShadowOffsetX}px ${theme.singleMultipleChoiceIdleShadowOffsetY}px ${theme.singleMultipleChoiceIdleShadowBlur}px ${theme.singleMultipleChoiceIdleShadowSpread}px ${theme.singleMultipleChoiceIdleShadowColor}` : 'none',

    '&:first-of-type': {
      margin: `0 0 ${theme.singleMultipleChoiceIdleVerticalMargin}px 0`
    },

    '&:last-of-type': {
      margin: !showOther ? `${theme.singleMultipleChoiceIdleVerticalMargin}px 0 0 0` : `${theme.singleMultipleChoiceIdleVerticalMargin}px 0`
    },

    '& .value': {
      lineHeight: '142%',
      boxSizing: 'border-box'
    },

    '& span': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      lineHeight: '142%',
      boxSizing: 'border-box',
      fontSize: theme.singleMultipleChoiceIdleTextFontSize,
      fontWeight: '600',
      border: `${theme.singleMultipleChoiceIdleBadgeBorderSize}px solid ${theme.singleMultipleChoiceIdleBadgeBorderColor}`,
      background: theme.singleMultipleChoiceIdleBadgeBackground,
      color: theme.singleMultipleChoiceIdleBadgeTextColor,
      borderRadius: '2px',
      margin: '0 7px 0 0',
      textAlign: 'center',
      width: `calc(1em + ${theme.singleMultipleChoiceIdleVerticalPadding}px)`
    },

    '&:hover, &.hover': (() => {
      const obj = {};

      if (theme.singleMultipleChoiceHoverEnable) {
        obj.background = theme.singleMultipleChoiceHoverBackground;
        obj.borderColor = theme.singleMultipleChoiceHoverBorderColor;
        obj.color = theme.singleMultipleChoiceHoverTextColor;
      }

      if (theme.singleMultipleChoiceHoverShadow && theme.singleMultipleChoiceHoverEnable) {
        obj.boxShadow = `${theme.singleMultipleChoiceHoverShadowOffsetX}px ${theme.singleMultipleChoiceHoverShadowOffsetY}px ${theme.singleMultipleChoiceHoverShadowBlur}px ${theme.singleMultipleChoiceHoverShadowSpread}px ${theme.singleMultipleChoiceHoverShadowColor}`;
      } else {
        obj.boxShadow = 'none';
      }

      obj['& span'] = {
        background: theme.singleMultipleChoiceHoverBadgeBackground,
        borderColor: theme.singleMultipleChoiceHoverBadgeBorderColor,
        color: theme.singleMultipleChoiceHoverBadgeTextColor
      };

      return obj;
    })(),
    '&:active, &.isActive': (() => {
      const obj = {};

      if (theme.singleMultipleChoiceActiveEnable) {
        obj.background = theme.singleMultipleChoiceActiveBackground;
        obj.borderColor = theme.singleMultipleChoiceActiveBorderColor;
        obj.color = theme.singleMultipleChoiceActiveTextColor;
      }

      if (theme.singleMultipleChoiceActiveShadow && theme.singleMultipleChoiceActiveEnable) {
        obj.boxShadow = `${theme.singleMultipleChoiceActiveShadowOffsetX}px ${theme.singleMultipleChoiceActiveShadowOffsetY}px ${theme.singleMultipleChoiceActiveShadowBlur}px ${theme.singleMultipleChoiceActiveShadowSpread}px ${theme.singleMultipleChoiceActiveShadowColor}`;
      } else {
        obj.boxShadow = 'none';
      }

      obj['& span'] = {
        background: theme.singleMultipleChoiceActiveBadgeBackground,
        borderColor: theme.singleMultipleChoiceActiveBadgeBorderColor,
        color: theme.singleMultipleChoiceActiveBadgeTextColor
      };

      return obj;
    })()
  });

  const contentRef = useRef();

  const handleOptionClick = (ref) => {
    if (field.readonly) return;

    let data = value == null ? { values: [], other: '' } : JSON.parse(value);
    let refIndex = data.values.indexOf(ref);

    if (refIndex !== -1) {
      data.values.splice(refIndex, 1);
    } else {
      data.values.push(ref);
    }

    if (field.selectionLimits && data.values.length > field.selectionLimitsMax) return;

    onChange({ [field._id]: JSON.stringify(data) });
    onPartialResponse(field._id);

    if ((field.selectionLimits && data.values.length === field.selectionLimitsMax) || (options.length === data.values.length)) {
      if (field.section === 'root' || !field.section) onNextClick(field);
    }
  };

  const toggleOther = () => {
    setShowOther(!showOther);
  };

  const handleOtherChange = (otherValue) => {
    if (!showOther) return;

    let data = value == null ? { values: [], other: '' } : JSON.parse(value);

    data.other = otherValue;

    onChange({ [field._id]: JSON.stringify(data) });
  };

  const optionsList = useMemo(() => options.map((option, index) => {
    if (option.value === '') return;

    return <li key={option.ref}
      css={optionsStyle}
      onClick={() => handleOptionClick(option.ref)}
      className={[
        value && JSON.parse(isJsonString(value) ? value : '{"values":[],"other":null}').values.indexOf(option.ref) !== -1 ? 'isActive' : '',
        field.highlighted === index ? 'hover' : ''
      ].join(' ')}>
      {form.type === 'conversational' && <span className="alphabet">{alphabet[index] ? alphabet[index].toUpperCase() : ''}</span>}

      <div className="value">{option.value}</div>
    </li>;
  }), [options, value, field.highlighted]);

  return <>
    <div css={mainStyle}>
      <div css={contentStyle} ref={contentRef}>
        <LabelAtom required={field.required} error={field.error} theme={theme}>{getFieldLabel(field.label, values, 'jsx', fieldsOptions, variables)}</LabelAtom>

        <ul css={optionsContainerStyle}>
          {optionsList}

          {field.otherOption && <li onClick={() => toggleOther()} css={optionsStyle} className={showOther ? 'isActive' : ''}>
            {form.type === 'conversational' && <span className="alphabet">{alphabet[options.length] ? alphabet[options.length].toUpperCase() : ''}</span>}

            <div className="value">{translate.text('otherOption')}</div>
          </li>}
        </ul>

        {showOther && <ShortText field={{
          _id: 'other'
        }} form={form} theme={{
          ...theme, ...{ inputFieldsDropdownsWidth: 100, inputFieldsDropdownsWidthType: '%' }
        }} onChange={(obj) => handleOtherChange(obj.other)} autoFocus={true} onBlur={() => onPartialResponse(field._id)} onPartialResponse={() => { }} value={JSON.parse(isJsonString(value) ? value : '{"value":null,"other":null}').other || ''} />}

        <DescriptionAtom theme={theme}>{getFieldLabel(field.description, values, 'jsx', fieldsOptions, variables)}</DescriptionAtom>
      </div>
    </div>
  </>;
};

export default Checkbox;
