import styles from './Export.module.css';

import React, { Component } from 'react';
import { connect } from 'react-redux';
import ScrollArea from 'react-scrollbar';
import getFieldLabel from 'helpers/getFieldLabel.js';

import {
  hideExportModal, updateExportModalData, toggleColumn, deselectAllColumns, selectAllColumns, requestExport
} from 'store/ducks/results.js';

import { Button, Modal, Checkbox, Radio, Loader } from 'ui';

import { ReactComponent as CheckmarkCircle } from 'assets/images/checkmark-circle.svg';

class ExportModal extends Component {
  cancel = () => {
    this.props.hideExportModal();
    this.props.deselectAllColumns();
  }

  afterOpen = () => {
    this.props.selectAllColumns();
  }

  requestExport = () => {
    this.props.requestExport();
  }

  render() {
    const {
      show, exportAs, columns, exportDeleted, loading, success, structuralFields
    } = this.props;

    return (
      <>
        <Modal isOpen={show} onAfterOpen={this.afterOpen} onRequestClose={this.cancel} width={500} header="Request Data Export" footer={<>
          <Button size="small" theme="white" onClick={this.cancel} margin="0 15px 0 0">{success ? 'Close' : 'Cancel'}</Button>
          <Button size="small" theme="blue" onClick={this.requestExport} disabled={loading || success}>Request Export</Button>
        </>}>
          <div className={styles.content}>
            {loading && <Loader size={40} />}

            {!loading && success && <div className={styles.success}>
              <CheckmarkCircle />
              <p>Export request has been submitted successfully! You will receive an email with a download link shortly.</p>
            </div>}

            {!loading && !success && <>
              <div className={styles.fileType}>
                <div>
                  <Radio label="Export as a .CSV file" onChange={() => { }} onClick={() => this.props.updateExportModalData({ exportAs: 'csv' })} value={exportAs === 'csv'} />
                  <Radio label="Export as a .PDF file" onChange={() => { }} onClick={() => this.props.updateExportModalData({ exportAs: 'pdf' })} value={exportAs === 'pdf'} />
                </div>

                <div>
                  <Checkbox label="Include title and description fields" onChange={() => { }} onClick={() => this.props.updateExportModalData({ structuralFields: !structuralFields })} value={structuralFields} />
                </div>
              </div>
              <div className={styles.fields}>
                <span>Fields to export</span>
                <ScrollArea speed={0.8}
                  className={styles.scrollArea}
                  stopScrollPropagation={true}
                  contentClassName={styles.scrollAreaContent}>
                  <ul>
                    {columns.filter((c) => ['title', 'description', 'section'].indexOf(c.type) === -1).map((column) => {
                      return (<li key={column._id}>
                        <Checkbox label={getFieldLabel(column.label || column.placeholder || column.type)} onChange={() => { }} onClick={() => this.props.toggleColumn(column._id)} value={column.selected} />
                      </li>)
                    })}
                    {exportAs === 'csv' && <li className={styles.deleted}>
                      <Checkbox label="Deleted fields" onClick={() => this.props.updateExportModalData({ exportDeleted: !exportDeleted })} value={exportDeleted} />
                    </li>}
                  </ul>
                </ScrollArea>
              </div>
            </>}
          </div>
        </Modal>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    show: state.results.showExportModal,
    loading: state.results.showExportLoading,
    columns: state.results.columns,
    exportAs: state.results.exportModalData.exportAs,
    structuralFields: state.results.exportModalData.structuralFields,
    exportDeleted: state.results.exportModalData.exportDeleted,
    loading: state.results.showExportLoading,
    success: state.results.showExportSuccess
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    hideExportModal: () => dispatch(hideExportModal()),
    updateExportModalData: (params) => dispatch(updateExportModalData(params)),
    toggleColumn: (id) => dispatch(toggleColumn(id)),
    deselectAllColumns: () => dispatch(deselectAllColumns()),
    selectAllColumns: () => dispatch(selectAllColumns()),
    requestExport: () => dispatch(requestExport())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ExportModal);
