import styles from './Plan.module.css';

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link, Redirect } from 'react-router-dom';
import { Elements } from 'react-stripe-elements';
import Sticky from 'react-stickynode';
import { getFormsPrice, getSubmissionsPrice, getUsersPrice, getDomainsPrice, getFilesStoragePrice, getTaxRateForCountry } from 'misc/helpers.js';

import Header from '../../Header/Header.js';
import PlanSetup from 'components/shared/PlanSetup.js';
import PlanSummary from 'components/shared/PlanSummary.js';
import PlanUnlimited from 'components/shared/PlanUnlimited.js';

import { ReactComponent as CheckmarkIcon } from 'assets/images/checkmark-circle.svg';

import PlanForm from './PlanForm.js';

import {
  updatePlan, getBilling
} from 'store/ducks/user.js';

import {
  updateUser
} from 'store/ducks/_users.js';

import {
  updateSelectedPlanType, updateTab, saveTmpPlan, updateLoading, updateSelectedPlan, resetSelectedPlan, getCoupon, clearCoupon
} from 'store/ducks/plan.js';

class Plan extends Component {
  UNSAFE_componentWillMount = () => {
    this.props.getBilling();
  };

  componentWillUnmount = () => {
    this.props.resetSelectedPlan();
  }

  continueWithFree = () => {
    if (this.props.loading) return;

    this.props.updateUser({
      plan: {
        forms: 1,
        submissions: 50,
        users: 1,
        filesStorage: 100,
        domains: 0,
        brandingRemoval: 0
      },
      pro: false,
      planType: 'flex'
    });
  }

  updatePlanToTmp = () => {
    this.props.updateSelectedPlan('forms', this.props.tmpPlan.forms);
    this.props.updateSelectedPlan('submissions', this.props.tmpPlan.submissions);
    this.props.updateSelectedPlan('users', this.props.tmpPlan.users);
    this.props.updateSelectedPlan('filesStorage', this.props.tmpPlan.filesStorage);
    this.props.updateSelectedPlan('domains', this.props.tmpPlan.domains);
    this.props.updateSelectedPlan('brandingRemoval', this.props.tmpPlan.brandingRemoval);
    this.props.updateSelectedPlanType(this.props.tmpPlanType);

    return Promise.resolve();
  }

  selectPlanType = (type) => {
    this.props.updateSelectedPlan('forms', 1);
    this.props.updateSelectedPlan('submissions', 50);
    this.props.updateSelectedPlan('users', 1);
    this.props.updateSelectedPlan('filesStorage', 100);
    this.props.updateSelectedPlan('domains', 0);
    this.props.updateSelectedPlan('brandingRemoval', 0);
    this.props.updateSelectedPlanType(type);
  };

  validatePlan = (total) => {
    if (this.props.selectedPlanType === 'flex' && total >= 99) {
      this.props.updateSelectedPlanType('unlimited');
    }
  };

  getCoupon = (id) => {
    this.props.getCoupon(id);
  };

  render() {
    let {
      tab, loading, user, selectedPlan, form, selectedPlanType, coupon, billing
    } = this.props;

    if (user.authType === 'onetool') return <></>;

    let total = Math.round(((selectedPlan.brandingRemoval ? 5 : 0) + getDomainsPrice(selectedPlan.domains) + getFormsPrice(selectedPlan.forms) + getSubmissionsPrice(selectedPlan.submissions) + getUsersPrice(selectedPlan.users) + getFilesStoragePrice(selectedPlan.filesStorage)) * 100) / 100;
    const taxRate = getTaxRateForCountry(form.country, form.vatEu);

    total = (Math.round((total + (total / 100 * taxRate)) * 100) / 100);

    let isUnlimited = total >= 99 || selectedPlanType === 'unlimited';
    const isLTD = user && user.lifeTime && user.lifeTime.enabled;

    if (isLTD) return <Redirect to="/" />;
    if (user.hasSubscription) return <Redirect to="/" />;

    return (
      <div className={styles.main}>
        <Header showAccount={false} center={true} showMenu={false} />

        <div className={styles.content}>
          <div className={styles.headerText}>Welcome to QuestionScout <span>Pro</span></div>
          <div className={styles.headerDesc}>You’re nearly there! We’ll get you set up so you can start collecting data the way it was meant to.</div>

          <div className={styles.box}>
            <div className={styles.left}>
              {tab === 'form' && <Elements locale="en"><PlanForm /></Elements>}
              {tab === 'setup' && <>
                <div className={styles.types}>
                  <div className={[styles.type, styles.unlimited, selectedPlanType === 'unlimited' ? styles.active : ''].join(' ')} onClick={() => this.selectPlanType('unlimited')}>
                    <div className={styles.typeBadge}>All-Inclusive</div>
                    <div className={styles.typeText}>Unlimited data collection for your business.</div>
                    <div className={styles.typePrice}><strong>€99</strong>/month, all-inclusive</div>
                    {selectedPlanType === 'unlimited' && <CheckmarkIcon />}
                  </div>
                  <div className={[styles.type, styles.flex, selectedPlanType === 'flex' ? styles.active : ''].join(' ')} onClick={() => this.selectPlanType('flex')}>
                    <div className={styles.typeBadge}>Pay-as-you-go</div>
                    <div className={styles.typeText}>Create your own plan and only pay for what you need.</div>
                    <div className={styles.typePrice}>Starting at <strong>€1</strong>/month</div>
                    {selectedPlanType === 'flex' && <CheckmarkIcon />}
                  </div>
                </div>

                {selectedPlanType === 'unlimited' && <PlanUnlimited />}
                {selectedPlanType === 'flex' && <PlanSetup
                  plan={selectedPlan}
                  planType={selectedPlanType}
                  onChange={this.props.updateSelectedPlan}
                  loading={loading} />}
              </>}
            </div>

            <Sticky top={50} bottomBoundary={`.${styles.box}`} className={styles.sticky}>
              <div className={styles.right}>
                {user.plan && <PlanSummary
                  plan={selectedPlan}
                  edit={tab === 'form'}
                  showTax={true}
                  country={form.country}
                  vatEu={form.vatEu}
                  loading={loading}
                  type={selectedPlanType}
                  onGetCoupon={this.getCoupon}
                  onClearCoupon={this.props.clearCoupon}
                  coupon={coupon}
                  showCoupon={billing && billing.coupon === null}
                  editOnApply={() => {
                    this.validatePlan(total);
                    this.props.updateTab('form')}
                  }
                  editOnClick={() => this.props.saveTmpPlan().then(this.props.updateTab('setup'))}
                  editOnCancel={() => this.updatePlanToTmp().then(this.props.updateTab('form'))} />}

                {form && form.collectionMethod === 'charge_automatically' && <div className={styles.description}>Your subscription will be billed monthly. Upon starting your subscription, you will be billed for €{isUnlimited ? 99 : total}.</div>}
                {form && form.collectionMethod === 'send_invoice' && <div className={styles.description}>Your subscription will be billed monthly. Upon starting your subscription, you will have to pay €{isUnlimited ? 99 : total} manually within 7 days.</div>}

                <div className={styles.description}>You can always update or cancel your Pro account.</div>

                {!user.hasSubscription && !loading && <Link to={'/'} className={styles.continueWithFree} onClick={() => this.continueWithFree()}>Continue with a free plan instead</Link>}
              </div>
            </Sticky>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    form: state.plan.form,
    user: state._users.user,
    tab: state.plan.tab,
    loading: state.plan.loading,
    coupon: state.plan.coupon,
    tmpPlan: state.plan.tmpPlan,
    tmpPlanType: state.plan.tmpPlanType,
    selectedPlan: state.plan.selectedPlan,
    selectedPlanType: state.plan.selectedPlanType,
    billing: state.user.billing
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateUser: (params, options) => dispatch(updateUser(params, options)),
    updatePlan: (key, value) => dispatch(updatePlan(key, value)),
    updateTab: (tab) => dispatch(updateTab(tab)),
    saveTmpPlan: (tab) => dispatch(saveTmpPlan(tab)),
    updateLoading: (value) => dispatch(updateLoading(value)),
    updateSelectedPlan: (key, value) => dispatch(updateSelectedPlan(key, value)),
    resetSelectedPlan: () => dispatch(resetSelectedPlan()),
    updateSelectedPlanType: (type) => dispatch(updateSelectedPlanType(type)),
    getCoupon: (id) => dispatch(getCoupon(id)),
    clearCoupon: () => dispatch(clearCoupon()),
    getBilling: () => dispatch(getBilling())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Plan));
