import styles from './ThemesDashboard.module.css';

import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import WebFont from 'webfontloader';

import Header from '../Header/Header.js';
import ThemePreview from './ThemePreview.js';

import { Button, DropdownMenu, Loader } from 'ui';

import { ReactComponent as MoreIcon } from 'assets/images/more.svg';

import {
  showCreateThemeModal, showDeleteThemeModal
} from 'store/ducks/theme.js';

const getThemeItems = ({ id, type, renameOnClick, duplicateOnClick, deleteOnClick }) => {
  const params = [{
    type: 'label',
    text: 'ACTIONS'
  }];

  // if (type === 'custom') {
  //   params.push({
  //     key: 'rename',
  //     type: 'action',
  //     text: 'Rename',
  //     params: [id],
  //     onClick: renameOnClick
  //   });
  // }

  params.push({
    key: 'duplicate',
    type: 'action',
    text: 'Duplicate',
    params: [id],
    onClick: duplicateOnClick
  });

  if (type === 'custom') {
    params.push({
      key: 'beforeDeleteHr',
      type: 'hr'
    });

    params.push({
      key: 'delete',
      type: 'action',
      text: 'Delete',
      theme: 'red',
      params: [id],
      onClick: deleteOnClick
    });
  }

  return params;
};

const Theme = ({ id, name, type, colors, images, settings }) => {
  const dispatch = useDispatch();

  const [showMenu, setShowMenu] = useState(false);

  return <div className={styles.theme}>
    <div className={styles.themeContent}>
      <ThemePreview id={id} href={`/themes/${type}/${id}`} type={type} colors={colors} images={images} settings={settings} className={styles.preview} />

      <div className={styles.name}>{name}</div>

      <div className={styles.action}>
        <Button theme="white" width="190px" link={`/themes/${type}/${id}`}>
          {type === 'custom' && 'Customize Theme'}
          {type === 'stock' && 'Open Theme'}
        </Button>

        <div className={styles.more} onClick={() => setShowMenu(true)}><MoreIcon /></div>
      </div>

      {showMenu && <DropdownMenu onClose={() => setShowMenu(false)}
        position={{ top: 230, right: 20 }}
        width={246}
        items={getThemeItems({
          id, type,
          duplicateOnClick: (id) => {
            setShowMenu(false);
            dispatch(showCreateThemeModal(id));
          },
          deleteOnClick: (id) => {
            setShowMenu(false);
            dispatch(showDeleteThemeModal(id));
          }
        })} />}
    </div>
  </div>;
}

const ThemesDashboard = () => {
  let fontsToLoad = [];

  const dispatch = useDispatch();
  const [fontsLoaded, setFontsLoaded] = useState(false);

  const themes = useSelector(state => state._themes.themes);

  if (!fontsLoaded && themes.stock.length > 0) {
    for (let theme of themes.stock.concat(themes.custom)) {
      fontsToLoad = [...new Set([
        ...fontsToLoad,
        `${theme.settings.typographyLabelFontFamily}:400,600`,
        `${theme.settings.typographyContentFontFamily}:400,600`,
        `${theme.settings.typographyButtonsFontFamily}:400,600`
      ])];
    }

    WebFont.load({
      google: {
        families: fontsToLoad
      }
    });

    setFontsLoaded(true);
  }

  return (
    <>
      <Header showAccount={true} showMessages={true} showMenu={true} />

      <div className={styles.content}>
        <div className={styles.head}>
          <div className={styles.left}>
            <h1>Themes</h1>
          </div>
          <div className={styles.left}>
            <Button theme="blue" onClick={() => dispatch(showCreateThemeModal())}>Create Custom Theme</Button>
          </div>
        </div>

        <div className={styles.themes}>
          {themes.custom && themes.custom.length > 0 && <>
            <h2>Custom Themes</h2>

            <div className={styles.list}>
              {themes.custom.filter((theme) => theme).map((theme) => <Theme key={theme._id} id={theme._id} type="custom" name={theme.name} colors={theme.colors} images={theme.images} settings={theme.settings} />)}
            </div>
          </>}

          <h2>Stock Themes</h2>

          <div className={styles.list}>
            {themes.stock && themes.stock.filter((theme) => theme).map((theme) => <Theme key={theme._id} id={theme._id} type="stock" name={theme.name} colors={theme.colors} images={theme.images} settings={theme.settings} />)}
          </div>
        </div>

        {themes.stock.length === 0 && <div className={styles.loading}>
          <Loader size={50} />
        </div>}
      </div>
    </>
  );
}

export default ThemesDashboard;
