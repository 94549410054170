import styles from './Domains.module.css';

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  getDomains
} from 'store/ducks/_domains.js';

import {
  getUser
} from 'store/ducks/_users.js';

import AddEditDomainModal from './modals/AddEditDomainModal.js';
import DeleteDomainModal from './modals/DeleteDomainModal.js';

import { ReactComponent as RemoveIcon } from 'assets/images/trash.svg';

import { Card, Button, Loader } from 'ui';

const Domains = ({ }) => {
  const dispatch = useDispatch();

  const user = useSelector(state => state._users.user);
  const domains = useSelector(state => state._domains.domains);
  const loading = useSelector(state => state._domains.getDomains.loading);

  const [showAddEditUsersModal, setShowAddEditUsersModal] = useState(false);
  const [showDeleteUsersModal, setShowDeleteUsersModal] = useState(false);
  const [selectedDomain, setSelectedDomain] = useState(null);

  const refresh = () => {
    dispatch(getDomains());
    dispatch(getUser());
  };

  useEffect(() => {
    dispatch(getDomains());
  }, []);

  if (user.authType === 'onetool') return <></>;

  return (
    <>
      {showAddEditUsersModal && <AddEditDomainModal onClose={() => {
        refresh();
        setShowAddEditUsersModal(false);
      }} domain={selectedDomain} />}
      {showDeleteUsersModal && <DeleteDomainModal onClose={() => {
        refresh();
        setShowDeleteUsersModal(false);
      }} domain={selectedDomain} />}

      <Card className={styles.card}>
        <div className={styles.head}>
          <div className={styles.left}>
            <div className={styles.title}>Custom Domains</div>
            <Button theme="blue" disabled={user.planUsage.domains >= user.plan.domains} onClick={() => {
              setSelectedDomain(null);
              setShowAddEditUsersModal(true);
            }}>Add domain</Button>
            <a href="https://support.questionscout.com/en/articles/4230536-setting-up-a-custom-domain" target="_blank" rel="noopener noreferrer">How to set this up</a>
          </div>

          <div className={styles.right}>
            <div className={styles.seats}>
              {user.plan.domains < 1000 && <>
                {user.planUsage.domains} domain{user.planUsage.domains > 1 && 's'} used<span />{user.plan.domains - user.planUsage.domains} domain{(user.plan.domains - user.planUsage.domains) > 1 && 's'} available
              </>}

              {user.plan.domains >= 1000 && <>
                {user.planUsage.domains} domain{user.planUsage.domains > 1 && 's'} used<span />Unlimited available
              </>}
            </div>
          </div>
        </div>

        {loading && <div className={styles.loading}>
          <Loader size={30} />
        </div>}

        {!loading && <ul className={styles.users}>
          <li className={styles.usersHead}>
            <div>Hostname</div>
            <div>Actions</div>
          </li>

          {domains && domains.map((domain) => <li className={styles.usersBody} key={domain._id}>
            <div>{domain.hostname}</div>
            <div className={styles.actions}>
              <span onClick={() => {
                setSelectedDomain(domain);
                setShowAddEditUsersModal(true);
              }}>Edit</span>
              <div onClick={() => {
                setSelectedDomain(domain);
                setShowDeleteUsersModal(true);
              }}><RemoveIcon /></div>
            </div>
          </li>)}
        </ul>}
      </Card>
    </>
  );
}

export default Domains;
