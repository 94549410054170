import styles from './Login.module.css';

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import GoogleLogin from 'react-google-login';

import { ReactComponent as LogoIcon } from 'assets/images/logo-icon-bw.svg';
import { ReactComponent as GoogleIcon } from 'assets/images/login-google.svg';
import { ReactComponent as FacebookIcon } from 'assets/images/login-facebook.svg';

import {
  getUser, login, loginGoogle, loginFacebook
} from 'store/ducks/_users.js';

import { Button, TextInput, PasswordInput, Loader, FormRow } from 'ui';

let gettingUser = false;

const Login = () => {
  const dispatch = useDispatch();

  const loading = useSelector(state => state._users.login.loading);
  const error = useSelector(state => state._users.login.error);
  const success = useSelector(state => state._users.login.success);
  const successGoogle = useSelector(state => state._users.loginGoogle.success);
  const successFacebook = useSelector(state => state._users.loginFacebook.success);
  const tokenType = useSelector(state => state._users.tokenType);

  const [formEmail, setFormEmail] = useState('');
  const [formPassword, setFormPassword] = useState('');
  const [redirecting, setRedirecting] = useState(false);

  useEffect(() => {
    document.title = 'Log In - QuestionScout';
  }, []);

  const handleSubmit = () => {
    if (formEmail && formPassword) {
      dispatch(login({
        email: formEmail,
        password: formPassword
      }));
    }
  }

  const handleEnterKeyPress = (e) => {
    if (e.key !== 'Enter') return;

    handleSubmit();
  }

  const googleLoginSuccess = (data) => {
    dispatch(loginGoogle({ tokenId: data.tokenId }));
  }

  const facebookLoginSuccess = (data) => {
    dispatch(loginFacebook({ accessToken: data.accessToken }));
  }

  if (success || successGoogle || successFacebook) {
    if (tokenType === 'auth' && !gettingUser) {
      dispatch(getUser());

      gettingUser = true;
    } else if (!redirecting && tokenType === 'otp') {
      setRedirecting(true);

      window.location.replace('/login/2fa');
    }
  }

  return (
    <div className={styles.main}>
      <header className={styles.header}>
        <a href="https://questionscout.com" className={styles.logo}>
          <LogoIcon />
        </a>

        <div className={styles.account}>
          <Link to="/login" title="Sign In" className={styles.signInBtn}>Log In</Link>
          <Link to="/signup" title="Create an account" className={styles.getStartedBtn}>Sign Up</Link>
        </div>
      </header>

      <div className={styles.content}>
        <div className={styles.text}>
          <div className={styles.title}>Welcome back to QuestionScout</div>
          <div className={styles.subtitle}>It’s nice to see you again!</div>
        </div>

        <div className={styles.form}>
          {(loading || redirecting) && <Loader size={40} />}
          {error && <div className={styles.error}>Incorrect email and/or password</div>}

          <FormRow label="Email Address">
            <TextInput name="email" type="email" autoFocus={true} value={formEmail} onChange={(e) => setFormEmail(e.target.value)} onKeyPress={handleEnterKeyPress} error={error} />
          </FormRow>

          <FormRow label="Password">
            <PasswordInput name="password" value={formPassword} onChange={(e) => setFormPassword(e.target.value)} onKeyPress={handleEnterKeyPress} error={error} />
          </FormRow>

          <Button fullWidth={true} theme="blue" onClick={handleSubmit} className={styles.submit} disabled={!formPassword || !formPassword || loading}>Sign In</Button>

          <div className={styles.or}><span>OR</span></div>

          <div className={styles.social}>
            <GoogleLogin clientId="454161137540-8u87841b6qvv2ncqof3o6uprkecrpa3f.apps.googleusercontent.com"
              onSuccess={googleLoginSuccess}
              onFailure={() => { }}
              cookiePolicy={'single_host_origin'}
              render={renderProps => (
                <button onClick={renderProps.onClick} disabled={renderProps.disabled} className={styles.googleLogin}><GoogleIcon />Sign in with Google</button>
              )} />

            <FacebookLogin appId="481165379133345"
              autoLoad={false}
              callback={facebookLoginSuccess}
              fields="name,email"
              render={renderProps => (
                <button onClick={renderProps.onClick} disabled={renderProps.disabled} className={styles.facebookLogin}><FacebookIcon />Sign in with Facebook</button>
              )} />
          </div>

          <Link to="/forgotpassword" className={styles.forgot}>I forgot my password</Link>
        </div>
      </div>

      <div className={styles.footer}>
        <span>Don’t have an account yet?</span>
        <Link to="/signup">Create an account now, for free!</Link>
      </div>

      <div className={styles.rights}>
        <p>2020 QuestionScout</p>
        <p>All rights reserved</p>
      </div>
    </div>
  );
};

export default Login;
