import styles from './Settings.module.css';

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import { verifyPermission } from 'misc/helpers.js';

import {
  clear
} from 'store/ducks/settings.js';

import SettingsGeneral from './SettingsGeneral.js';
import SettingsMessaging from './SettingsMessaging.js';
import SettingsThankYouPage from './SettingsThankYouPage.js';
import SettingsNotifications from './SettingsNotifications.js';
import SettingsIntegrations from './SettingsIntegrations.js';
import SettingsWelcomePage from './SettingsWelcomePage.js';
import SettingsDomain from './SettingsDomain.js';
import Integrations from './Integrations';
import SettingsSeo from './SettingsSeo.js';

import { Button } from 'ui';

import { ReactComponent as ArrowBackIcon } from 'assets/images/arrow-back.svg';

class Settings extends Component {
  componentWillUnmount = () => {
    this.props.clear();
  }

  render() {
    const {
      user, deleted
    } = this.props;

    const {
      id, tab, subPage
    } = this.props.match.params;

    if (deleted) return <Redirect to="/" />;
    
    return (
      <div className={styles.main}>
        <div className={styles.header}>
          {tab === 'general' && <>Form Settings</>}
          {tab === 'messaging' && <>Default Messaging</>}
          {tab === 'thankyou' && <>Thank You Pages</>}
          {tab === 'welcome' && <>Welcome Page</>}
          {tab === 'domain' && <>Domain</>}
          {tab === 'notifications' && <>Notifications</>}
          {tab === 'seo' && <>Search Engine Optimization</>}
          {tab === 'integrations' && <>
            {!subPage && 'Integrations'}
            {subPage && <Button theme="white" icon={<ArrowBackIcon />} link={`/builder/${id}/settings/integrations`}>Return to all integrations</Button>}
          </>}
        </div>

        <div className={styles.content}>
          <div className={styles.section}>
            <Switch>
              <Route exact path="/builder/:id/settings/general" component={SettingsGeneral} />
              <Route exact path="/builder/:id/settings/messaging" component={SettingsMessaging} />
              <Route exact path="/builder/:id/settings/domain" component={SettingsDomain} />
              <Route exact path="/builder/:id/settings/thankyou" component={SettingsThankYouPage} />
              <Route exact path="/builder/:id/settings/welcome" component={SettingsWelcomePage} />
              <Route exact path="/builder/:id/settings/notifications" component={SettingsNotifications} />
              <Route exact path="/builder/:id/settings/integrations" component={SettingsIntegrations} />
              <Route exact path="/builder/:id/settings/integrations/:subPage?" component={Integrations} />
              <Route exact path="/builder/:id/settings/seo" component={SettingsSeo} />
            </Switch>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    form: state.builder.form,
    user: state._users.user,
    deleteConfirmation: state.settings.deleteConfirmation,
    deleted: state.settings.deleted
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    clear: () => dispatch(clear())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
