import styles from './Signup.module.css';

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ReCaptcha } from 'react-recaptcha-v3';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import GoogleLogin from 'react-google-login';
import PasswordStrengthBar from 'react-password-strength-bar';

import { ReactComponent as GoogleIcon } from 'assets/images/login-google.svg';
import { ReactComponent as FacebookIcon } from 'assets/images/login-facebook.svg';
import { ReactComponent as LogoIcon } from 'assets/images/logo-icon-bw.svg';

// import ltdLogo from 'assets/images/ltd.png';

import {
  signup, getUser, loginGoogle, loginFacebook
} from 'store/ducks/_users.js';

import {
  updateTmpLtdPlan, updateTmpLtdUsers
} from 'store/ducks/plan.js';

import { Button, TextInput, PasswordInput, Loader, FormRow } from 'ui';

let recaptchaInterval = null;
let recaptchaRef;

const Signup = () => {
  const dispatch = useDispatch();

  const loading = useSelector(state => state._users.signup.loading);
  const errors = useSelector(state => state._users.signup.errors);
  const success = useSelector(state => state._users.signup.success);
  const successGoogle = useSelector(state => state._users.loginGoogle.success);
  const successFacebook = useSelector(state => state._users.loginFacebook.success);

  const [formEmail, setFormEmail] = useState('');
  const [formName, setFormName] = useState('');
  const [formPassword, setFormPassword] = useState('');
  const [reCaptchaToken, setReCaptchaToken] = useState('');
  const [referral, setReferral] = useState(null);
  const [showPasswordStrengthBar, setShowPasswordStrengthBar] = useState(false);

  const urlParams = new URLSearchParams(window.location.search);

  if (success || successGoogle || successFacebook) {
    dispatch(getUser());
  }

  useEffect(() => {
    const email = urlParams.get('email');

    window.addEventListener('Rewardful.tracked', () => {
      setReferral(window.Rewardful.referral);
    });

    if (email) setFormEmail(email);
    if (urlParams.get('plan')) dispatch(updateTmpLtdPlan(urlParams.get('plan')));
    if (urlParams.get('users')) dispatch(updateTmpLtdUsers(parseInt(urlParams.get('users'), 10)));

    document.title = 'Sign Up - QuestionScout';

    recaptchaInterval = setInterval(() => {
      recaptchaRef && recaptchaRef.execute();
    }, 90 * 1000);

    return () => clearInterval(recaptchaInterval); // unmount
  }, []);

  const handleSubmit = () => {
    dispatch(signup({
      email: formEmail,
      name: formName,
      password: formPassword,
      reCaptchaToken,
      planType: urlParams.get('planType') || 'flex',
      lifeTime: false,
      referral
    }, {
      forms: parseInt(urlParams.get('forms'), 10),
      submissions: parseInt(urlParams.get('submissions'), 10),
      users: parseInt(urlParams.get('users'), 10),
      filesStorage: parseInt(urlParams.get('filesStorage'), 10),
      domains: parseInt(urlParams.get('domains'), 10),
      brandingRemoval: urlParams.get('brandingRemoval') === 'true' ? 1 : 0
    }));

    recaptchaRef && recaptchaRef.execute();
  }

  const handleEnterKeyPress = (e) => {
    if (e.key !== 'Enter') return;

    handleSubmit();
  }

  const googleLoginSuccess = (data) => {
    dispatch(loginGoogle({
      tokenId: data.tokenId,
      planType: urlParams.get('planType') || 'flex',
      lifeTime: false,
      referral
    }, {
      forms: parseInt(urlParams.get('forms'), 10),
      submissions: parseInt(urlParams.get('submissions'), 10),
      users: parseInt(urlParams.get('users'), 10),
      filesStorage: parseInt(urlParams.get('filesStorage'), 10),
      domains: parseInt(urlParams.get('domains'), 10),
      brandingRemoval: urlParams.get('brandingRemoval') === 'true' ? 1 : 0
    }));
  }

  const facebookLoginSuccess = (data) => {
    dispatch(loginFacebook({
      accessToken: data.accessToken,
      planType: urlParams.get('planType') || 'flex',
      lifeTime: false,
      referral
    }, {
      forms: parseInt(urlParams.get('forms'), 10),
      submissions: parseInt(urlParams.get('submissions'), 10),
      users: parseInt(urlParams.get('users'), 10),
      filesStorage: parseInt(urlParams.get('filesStorage'), 10),
      domains: parseInt(urlParams.get('domains'), 10),
      brandingRemoval: urlParams.get('brandingRemoval') === 'true' ? 1 : 0
    }));
  }

  return (
    <div className={styles.main}>
      <ReCaptcha sitekey="6LelHqkUAAAAAEZ8OOKqrVEZenT2Jo-mt1Dcj97r"
        ref={(ref) => recaptchaRef = ref}
        action="signup"
        verifyCallback={setReCaptchaToken} />

      <header className={styles.header}>
        <a href="https://questionscout.com" className={styles.logo}>
          <LogoIcon />
        </a>

        <div className={styles.account}>
          <Link to="/login" title="Sign In" className={styles.signInBtn}>Log In</Link>
          <Link to="/signup" title="Create an account" className={styles.getStartedBtn}>Sign Up</Link>
        </div>
      </header>

      <div className={styles.content}>
        {/* {urlParams.get('life') === 'true' && <img className={styles.ltd} src={ltdLogo} alt="LTDFans" height="40px" />} */}

        <div className={styles.text}>
          <div className={styles.title}>Let’s get your account set up!</div>
          <div className={styles.subtitle}>This should only take a second.</div>
        </div>

        <div className={styles.form}>
          <div className={styles.social}>
            {urlParams.get('life') !== 'true' && <GoogleLogin clientId="454161137540-8u87841b6qvv2ncqof3o6uprkecrpa3f.apps.googleusercontent.com"
              onSuccess={googleLoginSuccess}
              onFailure={() => { }}
              cookiePolicy={'single_host_origin'}
              render={renderProps => (
                <button onClick={renderProps.onClick} disabled={renderProps.disabled} className={styles.googleLogin}><GoogleIcon />Sign Up with Google</button>
              )} />}

            <FacebookLogin appId="481165379133345"
              autoLoad={false}
              callback={facebookLoginSuccess}
              fields="name,email"
              render={renderProps => (
                <button onClick={renderProps.onClick} disabled={renderProps.disabled} className={styles.facebookLogin}><FacebookIcon />Sign Up with Facebook</button>
              )} />
          </div>

          <div className={styles.or}><span>OR</span></div>

          {loading && <Loader size={40} />}

          {errors.find((error) => !error.field) && <div className={styles.error}>{errors.find((error) => !error.field).message}</div>}

          <FormRow label="Your email" required={true} errorMessage={errors.find((error) => error.field === 'email') ? errors.find((error) => error.field === 'email').message : null}>
            <TextInput type="email" name="email" autoFocus={true} value={formEmail} onChange={(e) => setFormEmail(e.target.value)} onKeyPress={handleEnterKeyPress} error={errors.find((error) => error.field === 'email')} />
          </FormRow>

          <FormRow label="Your name?" required={true}>
            <TextInput name="name" value={formName} onChange={(e) => setFormName(e.target.value)} onKeyPress={handleEnterKeyPress} />
          </FormRow>

          <FormRow label="Your password" required={true} style={{ margin: 0 }} errorMessage={errors.find((error) => error.field === 'password') ? errors.find((error) => error.field === 'password').message : null}>
            <PasswordInput onFocus={() => setShowPasswordStrengthBar(true)} onBlur={() => setShowPasswordStrengthBar(false)} name="password" value={formPassword} onChange={(e) => setFormPassword(e.target.value)} onKeyPress={handleEnterKeyPress} error={errors.find((error) => error.field === 'password')} />
            {showPasswordStrengthBar ? <PasswordStrengthBar className={styles.passwordStrength} password={formPassword} minLength={6} /> : <div className={styles.passwordStrength} />}
          </FormRow>

          <Button fullWidth={true} theme="blue" onClick={handleSubmit} className={styles.submit} disabled={formPassword.length < 6 || !formEmail || loading}>Sign Up</Button>
        </div>

        <div className={styles.agree}>
          <span>By clicking "Sign Up" you agree to QuestionScout’s <a href="https://questionscout.com/terms-of-service" target="_blank" rel="noopener noreferrer">Terms of Service</a> and <a href="https://questionscout.com/privacy-policy" target="_blank" rel="noopener noreferrer">Privacy Policy</a></span>
        </div>
      </div>

      <div className={styles.rights}>
        <p>2020 QuestionScout</p>
        <p>All rights reserved</p>
      </div>
    </div>
  );
}

export default Signup;
