import styles from './Menu.module.css';
import React from 'react';

import {
  TitleField, SizeWeightAndColorField, CheckboxField, SizeWithColorField,
  SizeWithTypeField, SizeField, SelectField, ColorField, GradientField,
  InputStyleField, ToggleField, CopyField
} from '../ThemeMenuFields.js';

const typeOptions = [
  { label: '%', value: '%' },
  { label: 'px', value: 'px' }
];

const fontWeightOptions = [
  { label: 400, value: 400 },
  { label: 600, value: 600 }
];

const alignOptions = [
  { label: 'Left', value: 'left' },
  { label: 'Center', value: 'center' },
  { label: 'Right', value: 'right' }
];

const States = ({ title, subTitle, id, settings, colors, onChange, onNewColor, onCopy }) => {
  return <>
    <TitleField title={title} subTitle={subTitle} />

    {subTitle !== 'Idle' && <ToggleField label={`Different styling on ${subTitle}`}
      settings={settings}
      onChange={onChange}
      margin="25px 0 0 0"
      value={`${id}Enable`} />}

    {(subTitle === 'Idle' || settings[`${id}Enable`]) && <>
      {subTitle === 'Hover' && <CopyField onCopy={onCopy}
        margin="15px 0 0 0"
        id={id}
        values={['TextColor', 'Background', 'BorderColor', 'Shadow', 'ShadowColor', 'ShadowOffsetX', 'ShadowOffsetY', 'ShadowBlur', 'ShadowSpread']}
        options={[
          { label: 'Idle', value: 'imageChoiceIdle' }
        ]} />}

      {subTitle === 'Active' && <CopyField onCopy={onCopy}
        margin="15px 0 0 0"
        id={id}
        values={['TextColor', 'Background', 'BorderColor', 'Shadow', 'ShadowColor', 'ShadowOffsetX', 'ShadowOffsetY', 'ShadowBlur', 'ShadowSpread']}
        options={[
          { label: 'Idle', value: 'imageChoiceIdle' },
          { label: 'Hover', value: 'imageChoiceHover' }
        ]} />}

      {subTitle === 'Idle' && <SizeWeightAndColorField title="Option text"
        settings={settings}
        colors={colors}
        onChange={onChange}
        onNewColor={onNewColor}
        weights={fontWeightOptions}
        margin="25px 0 0 0"
        size={`${id}TextFontSize`}
        weight={`${id}TextWeight`}
        color={`${id}TextColor`} />}

      {subTitle !== 'Idle' && <ColorField title="Option text color"
        settings={settings}
        colors={colors}
        onChange={onChange}
        onNewColor={onNewColor}
        margin="25px 0 0 0"
        color={`${id}TextColor`} />}

      <ColorField title="Option background"
        settings={settings}
        colors={colors}
        onChange={onChange}
        onNewColor={onNewColor}
        margin="25px 0 0 0"
        color={`${id}Background`} />

      {subTitle === 'Idle' && <SizeWithColorField title="Option border"
        settings={settings}
        colors={colors}
        onChange={onChange}
        onNewColor={onNewColor}
        margin="25px 0 0 0"
        size={`${id}BorderSize`}
        color={`${id}BorderColor`} />}

      {subTitle !== 'Idle' && <ColorField title="Option border color"
        settings={settings}
        colors={colors}
        onChange={onChange}
        onNewColor={onNewColor}
        margin="25px 0 0 0"
        color={`${id}BorderColor`} />}

      {subTitle === 'Idle' && <SizeField title="Option roundness"
        settings={settings}
        onChange={onChange}
        margin="25px 0 0 0"
        size={`${id}Roundness`} />}

      {subTitle === 'Idle' && <SizeField title="Option margin"
        settings={settings}
        onChange={onChange}
        margin="25px 0 0 0"
        size={`${id}Margin`} />}

      {subTitle === 'Idle' && <SizeField title="Option horizontal padding"
        settings={settings}
        onChange={onChange}
        margin="25px 0 0 0"
        size={`${id}HorizontalPadding`} />}

      {subTitle === 'Idle' && <SizeField title="Option vertical padding"
        settings={settings}
        onChange={onChange}
        margin="25px 0 0 0"
        size={`${id}VerticalPadding`} />}

      {subTitle === 'Idle' && <SizeWithColorField title="Option badge border"
        settings={settings}
        colors={colors}
        onChange={onChange}
        onNewColor={onNewColor}
        margin="25px 0 0 0"
        size={`${id}BadgeBorderSize`}
        color={`${id}BadgeBorderColor`} />}

      {subTitle !== 'Idle' && <ColorField title="Option badge border color"
        settings={settings}
        colors={colors}
        onChange={onChange}
        onNewColor={onNewColor}
        margin="25px 0 0 0"
        color={`${id}BadgeBorderColor`} />}

      <ColorField title="Option badge background"
        settings={settings}
        colors={colors}
        onChange={onChange}
        onNewColor={onNewColor}
        margin="25px 0 0 0"
        color={`${id}BadgeBackground`} />

      <ColorField title="Option badge text color"
        settings={settings}
        colors={colors}
        onChange={onChange}
        onNewColor={onNewColor}
        margin="25px 0 0 0"
        color={`${id}BadgeTextColor`} />

      <CheckboxField label="Add shadows"
        settings={settings}
        onChange={onChange}
        margin="25px 0 0 0"
        value={`${id}Shadow`} />

      {settings[`${id}Shadow`] && <>
        <ColorField title="Option shadow color"
          settings={settings}
          colors={colors}
          onChange={onChange}
          onNewColor={onNewColor}
          margin="10px 0 0 0"
          color={`${id}ShadowColor`} />

        <SizeField title="Option shadow horizontal length"
          settings={settings}
          onChange={onChange}
          margin="25px 0 0 0"
          size={`${id}ShadowOffsetX`} />

        <SizeField title="Option shadow vertical length"
          settings={settings}
          onChange={onChange}
          margin="25px 0 0 0"
          size={`${id}ShadowOffsetY`} />

        <SizeField title="Option shadow blur"
          settings={settings}
          onChange={onChange}
          margin="25px 0 0 0"
          size={`${id}ShadowBlur`} />

        <SizeField title="Option shadow spread"
          settings={settings}
          onChange={onChange}
          margin="25px 0 0 0"
          size={`${id}ShadowSpread`} />
      </>}
    </>}
  </>
};

const ImageChoice = ({ settings, colors, onChange, onNewColor, onCopy }) => {
  return (
    <>
      <TitleField title="General Field Settings" />

      <SizeWithTypeField title="Maximum Field Width"
        settings={settings}
        onChange={onChange}
        margin="25px 0 0 0"
        types={typeOptions}
        size="imageChoiceWidth"
        type="imageChoiceWidthType" />

      <SelectField title="Field Alignment"
        settings={settings}
        onChange={onChange}
        options={alignOptions}
        margin="25px 0 0 0"
        value="imageChoiceAlignment" />

      <div className={styles.doubleRow}>
        <div className={styles.left}>
          <SizeField title="Field top margin"
            settings={settings}
            onChange={onChange}
            margin="25px 0 0 0"
            size={`imageChoiceTopMargin`} />
        </div>
        <div className={styles.right}>
          <SizeField title="Field bottom margin"
            settings={settings}
            onChange={onChange}
            margin="25px 0 0 0"
            size={`imageChoiceBottomMargin`} />
        </div>
      </div>

      {/* <SelectField title="Options per row"
        settings={settings}
        onChange={onChange}
        options={optionsPerRonOptions}
        margin="25px 0 0 0"
        value="imageChoiceOptionsPerRow" /> */}

      <States title="Field Styling" subTitle="Idle" id="imageChoiceIdle" settings={settings} colors={colors} onChange={onChange} onNewColor={onNewColor} onCopy={onCopy} />
      <States title="Field Styling" subTitle="Hover" id="imageChoiceHover" settings={settings} colors={colors} onChange={onChange} onNewColor={onNewColor} onCopy={onCopy} />
      <States title="Field Styling" subTitle="Active" id="imageChoiceActive" settings={settings} colors={colors} onChange={onChange} onNewColor={onNewColor} onCopy={onCopy} />
    </>
  );
}

export default ImageChoice;
