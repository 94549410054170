import styles from './SettingsWelcomePage.module.css';

import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import debounce from 'lodash.debounce';
import { useDropzone } from 'react-dropzone';
import { v4 as uuid } from 'uuid';

import Preview from './../Preview.js';

import { TextInput, MentionsInput, Toggle, Loader, Card, CardRow, CardRowLabel, CardRowContent, Button } from 'ui';

import {
  ioUpdateForm, updateForm, uploadImage, removeImage, toggleFormPreview
} from 'store/ducks/builder.js';

function UploadArea({ id, uploads, value, onRemove }) {
  const dispatch = useDispatch();

  const file = uploads.find((file) => file.ref === id) || null;

  const { getRootProps, getInputProps, open } = useDropzone({
    accept: 'image/jpeg, image/png, image/gif',
    multiple: false,
    noClick: true,
    noKeyboard: true,
    onDrop: (acceptedFiles) => dispatch(uploadImage(id, acceptedFiles, { type: 'welcome' }))
  });

  return (
    <div className={styles.imageUpload}>
      {!value && <div {...getRootProps({ className: styles.dropzone })}>
        <input {...getInputProps()} />
        {(!file) && <>
          <p>Drop your image here</p>
          <p>or</p>
          <span onClick={open}>click to upload</span>
        </>}
        {file && file.loaded !== true && <Loader size={30} />}
      </div>}

      {value && <div className={styles.uploaded}>
        <div className={styles.preview}>
          <img src={value} alt="" />
        </div>
        <span className={styles.removeImage} onClick={onRemove}>Remove current image</span>
      </div>}
    </div>
  );
};

const SettingsWelcomePage = ({ }) => {
  const dispatch = useDispatch();

  const form = useSelector(state => state.builder.form);
  const uploads = useSelector(state => state.builder.uploads);
  const showPreview = useSelector(state => state.builder.showPreview);
  const calculationVariables = useSelector(state => state.builder.form.calculationVariables);

  const welcomePage = form.welcomePage || {};

  let mentionsData = form.fields.filter((f) => ['longText', 'shortText', 'scale', 'radio', 'checkbox', 'dropdown'].indexOf(f.type) !== -1).map((f) => ({
    _id: f._id,
    type: f.type,
    format: f.format,
    label: f.label
  }));

  if (calculationVariables && calculationVariables.length !== 0) mentionsData = [...mentionsData, ...calculationVariables.map((variable) => ({
    _id: variable._id,
    type: 'variable',
    format: null,
    label: variable.name
  }))];

  const ioUpdateFormWithDelay = debounce((params) => {
    dispatch(ioUpdateForm(params));
  }, 500);

  const handleChange = (params, delay = false) => {
    const welcomePageCopy = { ...welcomePage, ...params };

    dispatch(updateForm({ welcomePage: welcomePageCopy }));

    if (delay) {
      ioUpdateFormWithDelay({ welcomePage: welcomePageCopy });
    } else {
      dispatch(ioUpdateForm({ welcomePage: welcomePageCopy }));
    }
  };

  const openPreview = (value) => dispatch(toggleFormPreview(value));

  return <>
    {showPreview && <Preview hideWelcome={false} />}

    <Button theme="black" onClick={() => openPreview(!showPreview)} className={styles.previewBtn}>Preview</Button>

    <div className={styles.statusValue} onClick={() => handleChange({ enabled: !welcomePage.enabled })}>
      <Toggle checked={welcomePage.enabled} readOnly={true} onChange={() => { }} />
      <div className={styles.statusValueText}>This form has welcome page <span>{welcomePage.enabled ? 'turned on' : 'turned off'}</span></div>
    </div>

    <Card className={styles.card} disabled={!welcomePage.enabled}>
      <CardRow>
        <CardRowLabel title="Page Image" subTitle="This image will be displayed at the top of the welcome page." />

        <CardRowContent>
          <UploadArea id={uuid()} value={welcomePage.image} uploads={uploads} onRemove={() => {
            dispatch(removeImage(null, welcomePage.image));
            handleChange({ image: null });
          }} />
        </CardRowContent>
      </CardRow>

      <CardRow>
        <CardRowLabel title="Page Title" subTitle="This title will be displayed underneath the page image." />

        <CardRowContent>
          <TextInput value={welcomePage.title} onChange={(e) => handleChange({ title: e.target.value })} />
        </CardRowContent>
      </CardRow>

      <CardRow>
        <CardRowLabel title="Page Description" subTitle="This description will be displayed underneath the page title. This is best used to give a proper introduction to your form or survey." />

        <CardRowContent>
          <MentionsInput menu="static"
            initialValue={welcomePage.description}
            onChange={(value) => handleChange({ description: value })}
            data={mentionsData} />
        </CardRowContent>
      </CardRow>

      <CardRow>
        <CardRowLabel title="Start Button" subTitle="The text in the button to start the form or survey." />

        <CardRowContent>
          <TextInput value={welcomePage.button} onChange={(e) => handleChange({ button: e.target.value })} />
        </CardRowContent>
      </CardRow>
    </Card>
  </>;
}

export default SettingsWelcomePage;
