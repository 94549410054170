import styles from './../Edit.module.css';
import editStyles from 'components/Builder/Edit.module.css';

import React, { Component } from 'react';
import ReactTooltip from 'react-tooltip';
import { connect } from 'react-redux';

import { MentionsInput, TextInput, TextArea, Toggle, Select } from 'ui';

import { ReactComponent as ReferToIcon } from 'assets/images/refer-to.svg';

const formatOptions = [
  { label: 'Text', value: null },
  { label: 'Number', value: 'number' },
  { label: 'Email', value: 'email' },
  { label: 'Phone number', value: 'phone' },
];

const types = {
  number: 'number',
  email: 'email',
  phone: 'text'
};

class ShortText extends Component {
  render() {
    const {
      field, fields, handleChange, mentionsData
    } = this.props;

    return (
      <>
        <div className={editStyles.row}>
          <label>Field Format</label>
          <Select width={294} options={formatOptions} value={
            { label: formatOptions.find((option) => option.value === field.format).label, value: field.format }
          } onChange={(selected) => handleChange({ format: selected.value }, false)} />
        </div>

        <div className={editStyles.row}>
          <label>
            <div>Field Title</div>
            <a href="https://support.questionscout.com/en/articles/3947231-how-to-use-refer-to" target="_blank" rel="noopener noreferrer"><ReferToIcon data-tip="Refer to is available on this input, click to learn more." /></a>
          </label>
          <MentionsInput menu="tooltip"
            initialValue={field.label}
            onChange={(value) => handleChange({ label: value })}
            data={mentionsData}
          />
        </div>

        <div className={editStyles.row}>
          <label>Placeholder Content</label>
          <TextInput value={field.placeholder} onChange={(e) => handleChange({ placeholder: e.target.value })} />
        </div>

        <div className={editStyles.row}>
          <label>Pre-filled Content <span>{(field.value || '').length}/{field.valueMaxLength}</span></label>
          <TextInput type={types[field.format] || 'text'} value={field.value} onChange={(e) => handleChange({ value: e.target.value })} />
        </div>

        <div className={editStyles.row}>
          <label>
            <div>Supporting Text</div>
            <a href="https://support.questionscout.com/en/articles/3947231-how-to-use-refer-to" target="_blank" rel="noopener noreferrer"><ReferToIcon data-tip="Refer to is available on this input, click to learn more." /></a>
          </label>
          <MentionsInput menu="tooltip"
            initialValue={field.description}
            onChange={(value) => handleChange({ description: value })}
            data={mentionsData}
          />
        </div>

        <div className={[editStyles.row].join(' ')}>
          <div className={editStyles.toggle50} onClick={() => handleChange({ required: !field.required }, false)}>
            <Toggle checked={field.required} readOnly={true} /> <span>Required</span>
          </div>
          <div className={editStyles.toggle50} onClick={() => handleChange({ readonly: !field.readonly }, false)}>
            <Toggle checked={field.readonly} readOnly={true} /> <span>Read-Only</span>
          </div>
          <div className={editStyles.toggle50} onClick={() => handleChange({ hidden: !field.hidden }, false)}>
            <Toggle checked={field.hidden} readOnly={true} /> <span>Hidden</span>
          </div>
          <div style={{ clear: 'both' }}></div>
        </div>

        <ReactTooltip place="right" effect="solid" />
      </>
    );
  };
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ShortText);
