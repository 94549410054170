import styles from './BillingLifeTime.module.css';

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { format, getDate } from 'date-fns';
import easyinvoice from 'easyinvoice';
import { getData as getCountries } from 'country-list';

import { Card, Loader } from 'ui';

import { ReactComponent as CheckmarkIcon } from 'assets/images/checkmark-circle.svg';
import ltdLogo from 'assets/images/ltd.png';

import {
  getLtdInvoice
} from 'store/ducks/user.js';

const countries = getCountries().map((obj) => {
  return {
    label: obj.name.replace('Taiwan, Province of China', 'Taiwan'),
    value: obj.code
  };
});

const usdEur = {
  3: 0.8501,
  4: 0.8472,
  5: 0.8429,
  6: 0.8420,
  7: 0.8483,
  8: 0.8483,
  9: 0.8483,
  10: 0.8518,
  11: 0.8517,
  12: 0.8485,
  13: 0.8463,
  14: 0.8443,
  15: 0.8443,
  16: 0.8443,
  17: 0.8424,
  18: 0.8381,
  19: 0.8447,
  20: 0.8430
};

const getData = (order) => {
  const ratio = usdEur[getDate(new Date(order.mollie.createdAt)) - 1] || 0.8501;

  let props = {
    'documentTitle': 'Invoice',
    'currency': 'EUR',
    'taxNotation': 'vat',
    'marginTop': 25,
    'marginRight': 25,
    'marginLeft': 25,
    'marginBottom': 25,
    'logo': 'https://formsquare.s3.amazonaws.com/logobw.png',
    'sender': {
      'company': 'Kinaesthic',
      'address': 'Stadhuispassage 64',
      'zip': '6001KK',
      'city': 'Weert',
      'country': 'The Netherlands',
      'custom1': 'Chamber of Commerce: 58650903',
      'custom2': 'Vat EU: NL002343417B30',
      'custom3': 'IBAN: NL06INGB0751182753'
    },
    'client': {
      'company': `${order.mollie.billingAddress.givenName} ${order.mollie.billingAddress.familyName}`,
      'address': order.mollie.billingAddress.streetAndNumber,
      'zip': order.mollie.billingAddress.postalCode,
      'city': order.mollie.billingAddress.city,
      'country': countries.find((c) => c.value === order.mollie.billingAddress.country).label
    },
    'invoiceNumber': order.mollie.orderNumber,
    'invoiceDate': format(new Date(order.mollie.createdAt), 'dd MMM yyyy hh:mma'),
    'products': order.mollie.lines.map((line) => {
      let tax = order.vat ? parseInt(line.vatRate) : 0;
      let price = (ratio * (parseFloat(line.unitPrice.value)) - (parseFloat(line.vatAmount.value) / line.quantity));

      if (order.vatRateUpdate) tax = order.vatRateUpdate;

      return {
        'quantity': line.quantity,
        'description': line.name,
        'tax': tax,
        'price': price
      };
    }),
    'bottomNotice': `${ratio} USD/EUR exchange ratio. For customer service, billing, technical enquiries and payment please email jordy@questionscout.com`
  };

  if (order.vatEu) {
    props.client.custom1 = `Vat EU: ${order.vatEu}`;
  }

  return props;
}

const BillingLifeTime = ({ onInit }) => {
  const dispatch = useDispatch();

  const user = useSelector(state => state._users.user);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const getInvoice = async (item) => {
    if (loading) return;
    let order;

    setLoading(true);
    setError(null);

    try {
      order = await dispatch(getLtdInvoice(item._id));
    } catch (e) {
      setError(e.message);
      setLoading(false);
      return;
    }

    const data = getData(order);
    const result = await easyinvoice.createInvoice(data);

    easyinvoice.download(`${order.qs.id}.pdf`, result.pdf);

    setLoading(false);
  };

  return <>
    <div className={styles.main}>
      <div className={styles.left}>
        <Card className={[styles.card, styles.summary].join(' ')}>
          <label>A summary of your {user.lifeTime.plan} LTDF Plan:</label>

          <ul>
            <li>
              <CheckmarkIcon />
              <span>Unlimited Forms</span>
              <p>Unlimited forms and surveys, no questions asked.</p>
            </li>
            <li>
              <CheckmarkIcon />
              <span>Unlimited Responses</span>
              <p>Remove all limits from the number of responses you collect.</p>
            </li>
            <li>
              <CheckmarkIcon />
              <span>Up to {user.plan.users} user{(user.plan.users) === 1 ? '' : 's'}</span>
              <p>Teamwork doesn't have to be expensive. Add up to {user.plan.users} user{user.plan.users === 1 ? '' : 's'} to work together!</p>
            </li>
            <li>
              <CheckmarkIcon />
              <span>{user.plan.filesStorage / 1000}GB Monthly Upload</span>
              <p>Collect up to {user.plan.filesStorage / 1000}GB Monthly in file uploads using your forms & surveys.</p>
            </li>
            <li>
              <CheckmarkIcon />
              <span>{user.plan.domains >= 1000 ? 'Unlimited' : `Up to ${user.plan.domains}`} Custom Domains</span>
              <p>Add your own domain to all of your forms and surveys and give your respondents a truly white-labelled experience.</p>
            </li>
            <li>
              <CheckmarkIcon />
              <span>All features included</span>
              <p>All existing & upcoming features are included, free of charge.</p>
            </li>
            <li>
              <CheckmarkIcon />
              <span>Priority Support</span>
              <p>Live-chat support from QuestionScout staff whenever you need it.</p>
            </li>
            <li>
              <CheckmarkIcon />
              <span>Branding Removal</span>
              <p>Remove all QuestionScout related branding on all of your forms and surveys.</p>
            </li>
            <li>
              <CheckmarkIcon />
              <span>Client Teamwork</span>
              <p>Work together with clients in a single account.</p>
            </li>
          </ul>
        </Card>
      </div>

      <div className={styles.right}>
        <Card className={[styles.card, styles.hello].join(' ')}>
          <img className={styles.headerLogo} src={ltdLogo} alt="LTDFans" height="60px" />

          <label>You are on a {user.lifeTime.plan} LTDF Plan</label>
          <p>We’re glad to have you on board! If you ever have any questions, please reach out to us by using the chat bubble in the right under corner of your screen.</p>
        </Card>

        <Card className={[styles.card, styles.payments].join(' ')} style={{ margin: '20px 0 0 0' }}>
          <label>Payments</label>

          {error && <div className={styles.error}>{error}</div>}

          <ul>
            {user.lifeTime.orders.map((item) => {
              let amount = (user.lifeTime.vatEu ? (parseFloat(item.order.amount) - parseFloat(item.order.vatAmount)) : parseFloat(item.order.amount));

              return <li key={item._id}>
                <div className={styles.top}>
                  <div className={styles.amount}>${(amount || 0).toFixed(2)}</div>
                  <div className={styles.status}>{item.order.status === 'paid' ? <span className={styles.invoice} onClick={() => getInvoice(item)}>Invoice</span> : item.order.status}</div>
                </div>
                <div className={styles.createdAt}>{format(new Date(item.order.createdAt), 'dd MMM yyyy hh:mma')}</div>
              </li>
            })}
          </ul>
        </Card>

        <a href={`https://form.questionscout.com/5f1ea44c6b3ea844b53857c1?id=${user._id}`} rel="noopener noreferrer" target="_blank" className={styles.cancel}>Cancel account & request refund</a>
      </div>

      {loading && <Loader size={40} className={styles.loader} background="rgba(255, 255, 255, 0.5)" />}
    </div>
  </>;
};

export default BillingLifeTime;
