/** @jsx jsx */
/* @jsxFrag React.Fragment */

import { jsx, css } from '@emotion/core';
import Color from 'color';
import React from 'react';

const Divider = ({ form, theme, fieldsOptions, variables }) => {
  const colorArr = Color(theme.dividerColor).color;

  const styleOptions = {
    solid: {
      borderBottom: `${theme.dividerHeight}px solid ${theme.dividerColor}`
    },
    dotted: {
      borderBottom: `${theme.dividerHeight}px dotted ${theme.dividerColor}`
    },
    dashed: {
      borderBottom: `${theme.dividerHeight}px dashed ${theme.dividerColor}`
    },
    double: {
      borderBottom: `${theme.dividerHeight}px double ${theme.dividerColor}`
    },
    shadow: {
      height: theme.dividerHeight,
      backgroundImage: `linear-gradient(to right, rgba(${colorArr[0]}, ${colorArr[1]}, ${colorArr[2]}, 0), rgba(${colorArr[0]}, ${colorArr[1]}, ${colorArr[2]}, 0.75), rgba(${colorArr[0]}, ${colorArr[1]}, ${colorArr[2]}, 0))`
    }
  };

  const alignOptions = {
    left: 'flex-start',
    center: 'center',
    right: 'flex-end'
  };

  const mainStyle = css({
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: alignOptions[theme.dividerAlignment],
    margin: `${theme.dividerTopMargin || 0}px 0 ${theme.dividerBottomMargin || 0}px 0`
  });

  const hrStyle = {
    width: '100%',
    maxWidth: `${theme.dividerWidth}${theme.dividerWidthType}`,
    boxSize: 'border-box',
    ...styleOptions[theme.dividerStyle]
  };

  return <>
    <div css={mainStyle}>
      <div css={hrStyle} />
    </div>
  </>;
};

export default Divider;
