import styles from './Affiliate.module.css';

import React from 'react';

import { Card, Button, CardRow, CardRowLabel, CardRowContent } from 'ui';

const Affiliate = () => {
  return <>
    <Card className={styles.card}>
      <CardRow>
        <CardRowLabel title="Get started with QuestionScout Affiliate" subTitle="Invite people you know to QuestionScout, and if they start using a paid plan you will receive a 50% commision on each month for up to 6 months from the moment they have started their paid plan." />

        <CardRowContent>
          <Button theme="blue" href="https://questionscout.getrewardful.com/signup" target="_blank" rel="noopener noreferrer">Sign up for QuestionScout Affiliate</Button>
        </CardRowContent>
      </CardRow>

      <CardRow>
        <CardRowLabel title="Already a QuestionScout Affiliate partner?" subTitle="" />

        <CardRowContent>
          <Button theme="white" href="https://questionscout.getrewardful.com/login" target="_blank" rel="noopener noreferrer">Go to your dashboard</Button>
        </CardRowContent>
      </CardRow>
    </Card>
  </>;
}

export default Affiliate;
