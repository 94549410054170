import axios from 'axios';

import config from 'config.js';

// Constants
const CLEAR = 'settings/CLEAR';
const UPDATE_DELETE_CONFIRMATION = 'settings/UPDATE_DELETE_CONFIRMATION';
const UPDATE_ADD_WEBHOOK_FORM = 'settings/UPDATE_ADD_WEBHOOK_FORM';

const DELETE_FORM_REQUEST = 'settings/DELETE_FORM_REQUEST';
const DELETE_FORM_SUCCESS = 'settings/DELETE_FORM_SUCCESS';
const DELETE_FORM_FAILURE = 'settings/DELETE_FORM_FAILURE';

const CREATE_WEBHOOK_REQUEST = 'settings/CREATE_WEBHOOK_REQUEST';
const CREATE_WEBHOOK_SUCCESS = 'settings/CREATE_WEBHOOK_SUCCESS';
const CREATE_WEBHOOK_FAILURE = 'settings/CREATE_WEBHOOK_FAILURE';

const DELETE_WEBHOOK_REQUEST = 'settings/DELETE_WEBHOOK_REQUEST';
const DELETE_WEBHOOK_SUCCESS = 'settings/DELETE_WEBHOOK_SUCCESS';
const DELETE_WEBHOOK_FAILURE = 'settings/DELETE_WEBHOOK_FAILURE';

const SHOW_ADD_WEBHOOK_MODAL = 'settings/SHOW_ADD_WEBHOOK_MODAL';
const HIDE_ADD_WEBHOOK_MODAL = 'settings/HIDE_ADD_WEBHOOK_MODAL';

const ADD_URL_PARAM_REQUEST = 'settings/ADD_URL_PARAM_REQUEST';
const ADD_URL_PARAM_SUCCESS = 'settings/ADD_URL_PARAM_SUCCESS';
const ADD_URL_PARAM_FAILURE = 'settings/ADD_URL_PARAM_FAILURE';

const DELETE_URL_PARAM_REQUEST = 'settings/DELETE_URL_PARAM_REQUEST';
const DELETE_URL_PARAM_SUCCESS = 'settings/DELETE_URL_PARAM_SUCCESS';
const DELETE_URL_PARAM_FAILURE = 'settings/DELETE_URL_PARAM_FAILURE';

// Initiual State
const initialState = {
  deleteConfirmation: false,
  deleted: false,

  showAddWebhookModal: false,
  addWebhookForm: {
    url: ''
  },
  addWebhookFormLoading: false,

  urlParamsLoading: false
};

// Reducer
export default function reducer(state = {...initialState}, action = {}) {
  if (action.type === CLEAR) {
    state = { ...initialState };

    return { ...state };
  }

  if (action.type === UPDATE_DELETE_CONFIRMATION) {
    state.deleteConfirmation = action.payload.value;

    return { ...state };
  }

  if (action.type === DELETE_FORM_REQUEST) {
    state.deleteConfirmation = false;

    return { ...state };
  }

  if (action.type === DELETE_FORM_SUCCESS) {
    state.deleted = true;

    return { ...state };
  }

  if (action.type === SHOW_ADD_WEBHOOK_MODAL) {
    state.showAddWebhookModal = true;

    return { ...state };
  }

  if (action.type === HIDE_ADD_WEBHOOK_MODAL) {
    state.showAddWebhookModal = false;
    state.addWebhookFormLoading = false;
    state.addWebhookForm = {
      url: ''
    };

    return { ...state };
  }

  if (action.type === UPDATE_ADD_WEBHOOK_FORM) {
    state.addWebhookForm[action.payload.name] = action.payload.value;
    state.signupForm = { ...state.signupForm };

    return { ...state };
  }

  if (action.type === CREATE_WEBHOOK_REQUEST) {
    state.addWebhookFormLoading = true;

    return { ...state };
  }

  if (action.type === CREATE_WEBHOOK_FAILURE) {
    state.showAddWebhookModal = false;
    state.addWebhookFormLoading = false;
    state.addWebhookForm = {
      url: ''
    };

    return { ...state };
  }

  if (action.type === DELETE_URL_PARAM_REQUEST || action.type === ADD_URL_PARAM_REQUEST) {
    state.urlParamsLoading = true;

    return { ...state };
  }

  if (action.type === DELETE_URL_PARAM_SUCCESS || action.type === ADD_URL_PARAM_SUCCESS) {
    state.urlParamsLoading = false;

    return { ...state };
  }

  if (action.type === DELETE_URL_PARAM_FAILURE || action.type === ADD_URL_PARAM_FAILURE) {
    state.urlParamsLoading = false;

    return { ...state };
  }

  return state;
}

// Action Creators
export function clear() {
  return (dispatch) => {
    dispatch({ type: CLEAR });
  };
}

export function updateDeleteConfirmation(value) {
  return (dispatch) => {
    dispatch({ type: UPDATE_DELETE_CONFIRMATION, payload: { value } });
  };
}

export function showAddWebhookModal() {
  return (dispatch) => {
    dispatch({ type: SHOW_ADD_WEBHOOK_MODAL });
  };
}

export function hideAddWebhookModal() {
  return (dispatch) => {
    dispatch({ type: HIDE_ADD_WEBHOOK_MODAL });
  };
}

export function updateWebhookForm(name, value) {
  return (dispatch) => {
    dispatch({ type: UPDATE_ADD_WEBHOOK_FORM, payload: { name, value } });
  };
}

export function deleteForm() {
  const request = () => { return { type: DELETE_FORM_REQUEST } };
  const success = () => { return { type: DELETE_FORM_SUCCESS } };
  const failure = () => { return { type: DELETE_FORM_FAILURE } };

  return async (dispatch, getState) => {
    dispatch(request());

    const state = getState();
    const response = await services.deleteForm(state._users.token, state._users.user.permissions, state.builder.formId);

    if (response) {
      dispatch(success());
    } else {
      dispatch(failure());
    }
  };
}

export function addUrlParam() {
  const request = () => { return { type: ADD_URL_PARAM_REQUEST } };
  const success = (res) => { return { type: ADD_URL_PARAM_SUCCESS, payload: res } };
  const failure = () => { return { type: ADD_URL_PARAM_FAILURE } };

  return async (dispatch, getState) => {
    dispatch(request());

    const state = getState();
    const response = await services.addUrlParam(state._users.token, state._users.user.permissions, state.builder.formId, '', '');

    if (response) {
      dispatch(success(response));
    } else {
      dispatch(failure());
    }
  };
}

export function deleteUrlParam(id) {
  const request = (id) => { return { type: DELETE_URL_PARAM_REQUEST, payload: { id } } };
  const success = () => { return { type: DELETE_URL_PARAM_SUCCESS } };
  const failure = () => { return { type: DELETE_URL_PARAM_FAILURE } };

  return async (dispatch, getState) => {
    dispatch(request(id));

    const state = getState();
    const response = await services.deleteUrlParam(state._users.token, state._users.user.permissions, state.builder.formId, id);

    if (response) {
      dispatch(success());
    } else {
      dispatch(failure());
    }
  };
}

export function createWebhook() {
  const request = () => { return { type: CREATE_WEBHOOK_REQUEST } };
  const success = () => { return { type: CREATE_WEBHOOK_SUCCESS } };
  const failure = () => { return { type: CREATE_WEBHOOK_FAILURE } };

  return async (dispatch, getState) => {
    dispatch(request());

    const state = getState();
    const response = await services.createWebhook(state._users.token, state._users.user.permissions, state.builder.formId, state.settings.addWebhookForm.url);

    if (response) {
      return dispatch(success());
    } else {
      return dispatch(failure());
    }
  };
}

export function deleteWebhook(id) {
  const request = (id) => { return { type: DELETE_WEBHOOK_REQUEST, payload: { id } } };
  const success = () => { return { type: DELETE_WEBHOOK_SUCCESS } };
  const failure = () => { return { type: DELETE_WEBHOOK_FAILURE } };

  return async (dispatch, getState) => {
    dispatch(request(id));

    const state = getState();
    const response = await services.deleteWebhook(state._users.token, state._users.user.permissions, state.builder.formId, id);

    if (response) {
      dispatch(success());
    } else {
      dispatch(failure());
    }
  };
}

const services = {
  deleteForm: async (token, permissions, id) => {
    try {
      const response = await axios({
        method: 'DELETE',
        url: `${config.apiUrl}/forms/${id}`,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
          'Permissions': permissions
        }
      });

      return response.data;
    } catch (e) {
      return false;
    }
  },
  createWebhook: async (token, permissions, formId, url) => {
    try {
      const response = await axios({
        method: 'POST',
        url: `${config.apiUrl}/forms/${formId}/webhooks`,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
          'Permissions': permissions
        },
        data: {
          url
        }
      });

      return response.data;
    } catch (e) {
      return false;
    }
  },
  deleteWebhook: async (token, permissions, formId, id) => {
    try {
      const response = await axios({
        method: 'DELETE',
        url: `${config.apiUrl}/forms/${formId}/webhooks/${id}`,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
          'Permissions': permissions
        }
      });

      return response.data;
    } catch (e) {
      return false;
    }
  },
  addUrlParam: async (token, permissions, formId, key, value) => {
    try {
      const response = await axios({
        method: 'POST',
        url: `${config.apiUrl}/forms/${formId}/urlparams`,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
          'Permissions': permissions
        },
        data: {
          key, value
        }
      });

      return response.data;
    } catch (e) {
      return false;
    }
  },
  deleteUrlParam: async (token, permissions, formId, id) => {
    try {
      const response = await axios({
        method: 'DELETE',
        url: `${config.apiUrl}/forms/${formId}/urlparams/${id}`,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
          'Permissions': permissions
        }
      });

      return response.data;
    } catch (e) {
      return false;
    }
  },
};
