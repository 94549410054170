import styles from './ThemeHeader.module.css';

import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';

import { ReactComponent as MenuDashboardIcon } from 'assets/images/menu-dashboard.svg';

const ThemeHeader = () => {
  return (
    <div className={styles.main}>
      <div className={styles.nav}>
        <div className={styles.top}>
          <Link to={`/themes`} className={[styles.button, styles.close].join(' ')}>
            <MenuDashboardIcon style={{ minHeight: 12, minWidth: 12 }} />
          </Link>
        </div>
      </div>
    </div>
  );
}

export default ThemeHeader;
