import styles from './PlanSetup.module.css';

import React, { useState, useEffect } from 'react';
import { Slider, Loader, Toggle } from 'ui';
import { formatBytes } from 'misc/helpers.js';

const formsValues = [...Array(100).keys()].map((i) => i + 1);
const submissionsValues = [...Array(1000).keys()].map((i) => (i + 1) * 10).slice(4, 1000);
const usersValues = [...Array(20).keys()].map((i) => i + 1);
const filesStorageValues = [100, 200, 300, 400, 500, 1000, 2000, 3000, 4000, 5000, 10000];
const domainsValues = [...Array(11).keys()].map((i) => i);

const PlanSetup = ({ plan, planType, loading, onChange }) => {
  let planKeys = {
    forms: formsValues.indexOf(plan.forms),
    submissions: submissionsValues.indexOf(plan.submissions),
    users: usersValues.indexOf(plan.users),
    filesStorage: filesStorageValues.indexOf(plan.filesStorage),
    domains: domainsValues.indexOf(plan.domains)
  };

  if (planKeys.domains === -1 && plan.domains === 100) planKeys.domains = domainsValues.length - 1;
  if (planKeys.domains === -1 && plan.domains !== 100) planKeys.domains = 0;

  const toggleBrandingRemoval = (e) => {
    if (e.target.tagName === 'INPUT') return;

    onChange('brandingRemoval', plan.brandingRemoval ? 0 : 1)
  };

  const priceDecrease = (key) => {
    let index, value;

    if ((key === 'forms' && formsValues.indexOf(plan[key]) <= 0) ||
      (key === 'submissions' && submissionsValues.indexOf(plan[key]) <= 0) ||
      (key === 'users' && usersValues.indexOf(plan[key]) <= 0) ||
      (key === 'filesStorage' && filesStorageValues.indexOf(plan[key]) <= 0) ||
      (key === 'domains' && domainsValues.indexOf(plan[key]) <= 0)) return;

    if (key === 'forms') {
      index = formsValues.indexOf(plan.forms);
      value = formsValues[index - 1];
    } else if (key === 'submissions') {
      index = submissionsValues.indexOf(plan.submissions);
      value = submissionsValues[index - 1];
    } else if (key === 'users') {
      index = usersValues.indexOf(plan.users);
      value = usersValues[index - 1];
    } else if (key === 'filesStorage') {
      index = filesStorageValues.indexOf(plan.filesStorage);
      value = filesStorageValues[index - 1];
    } else if (key === 'domains') {
      index = domainsValues.indexOf(plan.domains);
      value = domainsValues[index - 1];
    }

    onChange(key, value);
  };

  const priceIncrease = (key) => {
    let index, value;

    if (key === 'forms' && plan[key] >= formsValues[formsValues.length - 1]) {
      return;
    }

    if (key === 'submissions' && plan[key] >= submissionsValues[submissionsValues.length - 1]) {
      return;
    }

    if (key === 'users' && plan[key] >= usersValues[usersValues.length - 1]) {
      return;
    }

    if (key === 'filesStorage' && plan[key] >= filesStorageValues[filesStorageValues.length - 1]) {
      return;
    }

    if (key === 'domains' && plan[key] > domainsValues[domainsValues.length - 1]) {
      return;
    }

    if (key === 'forms') {
      index = formsValues.indexOf(plan.forms);
      value = formsValues[index + 1];
    } else if (key === 'submissions') {
      index = submissionsValues.indexOf(plan.submissions);
      value = submissionsValues[index + 1];
    } else if (key === 'users') {
      index = usersValues.indexOf(plan.users);
      value = usersValues[index + 1];
    } else if (key === 'domains') {
      index = domainsValues.indexOf(plan.domains);
      value = domainsValues[index + 1];
    } else if (key === 'filesStorage') {
      index = filesStorageValues.indexOf(plan.filesStorage);
      value = filesStorageValues[index + 1];
    }

    onChange(key, value);
  };

  return <>
    <ul className={styles.setup}>
      <li>
        <h3>Total Forms/Surveys<span className={styles.priceBadge}>dynamic - refer to plan summary</span></h3>

        <div className={styles.top}>
          <div className={styles.description}>The maximum number of forms and surveys you can use at once.</div>
          <div className={styles.input}>
            <span>{plan.forms}</span>
            <div onClick={() => priceDecrease('forms')}>-</div>
            <div onClick={() => priceIncrease('forms')}>+</div>
          </div>
        </div>

        <Slider value={planKeys.forms} values={formsValues} onChange={(index) => onChange('forms', formsValues[index])} />

        <div className={styles.bottom}>
          <div className={styles.min}>1 form</div>
          <div className={styles.max}>100 forms</div>
        </div>
      </li>

      <li>
        <h3>Monthly Responses<span className={styles.priceBadge}>€0.01/response/mo</span></h3>

        <div className={styles.top}>
          <div className={styles.description}>The maximum amount of responses you can collect per month.</div>
          <div className={styles.input}>
            <span>{plan.submissions}</span>
            <div onClick={() => priceDecrease('submissions')}>-</div>
            <div onClick={() => priceIncrease('submissions')}>+</div>
          </div>
        </div>

        <Slider value={planKeys.submissions} values={submissionsValues} onChange={(index) => onChange('submissions', submissionsValues[index])} />

        <div className={styles.bottom}>
          <div className={styles.min}>50 responses</div>
          <div className={styles.max}>unlimited responses</div>
        </div>
      </li>

      <li>
        <h3>Users<span className={styles.priceBadge}>€5/user/mo</span></h3>

        <div className={styles.top}>
          <div className={styles.description}>The number of people you can collaborate with on the same account.</div>
          <div className={styles.input}>
            <span>{plan.users}</span>
            <div onClick={() => priceDecrease('users')}>-</div>
            <div onClick={() => priceIncrease('users')}>+</div>
          </div>
        </div>

        <Slider value={planKeys.users} values={usersValues} onChange={(index) => onChange('users', usersValues[index])} />

        <div className={styles.bottom}>
          <div className={styles.min}>1 user</div>
          <div className={styles.max}>unlimited users</div>
        </div>
      </li>

      <li>
        <h3>Monthly File Upload<span className={styles.priceBadge}>dynamic - refer to plan summary</span></h3>

        <div className={styles.top}>
          <div className={styles.description}>The maximum amount of file uploads you can view per month.</div>
          <div className={styles.input}>
            <span>{plan.filesStorage / 1000}GB</span>
            <div onClick={() => priceDecrease('filesStorage')}>-</div>
            <div onClick={() => priceIncrease('filesStorage')}>+</div>
          </div>
        </div>

        <Slider value={planKeys.filesStorage} values={filesStorageValues} onChange={(index) => onChange('filesStorage', filesStorageValues[index])} />

        <div className={styles.bottom}>
          <div className={styles.min}>0.5 GB</div>
          <div className={styles.max}>10 GB</div>
        </div>
      </li>

      <li>
        <h3>Custom Domains<span className={styles.priceBadge}>€10/domain/mo</span></h3>

        <div className={styles.top}>
          <div className={styles.description}>The number of custom domains you would like to add. Each domain can be used unlimited times on your forms.</div>
          <div className={styles.input}>
            <span>{plan.domains}</span>
            <div onClick={() => priceDecrease('domains')}>-</div>
            <div onClick={() => priceIncrease('domains')}>+</div>
          </div>
        </div>

        <Slider value={planKeys.domains} values={domainsValues} onChange={(index) => onChange('domains', domainsValues[index])} />

        <div className={styles.bottom}>
          <div className={styles.min}>0 domains</div>
          <div className={styles.max}>unlimited domains</div>
        </div>
      </li>

      <li className={styles.toggle} onClick={(e) => toggleBrandingRemoval(e)}>
        <div className={styles.toggleLeft}>
          <h3 className={styles.toggleTitle}>Branding Removal<span className={styles.priceBadge}>€5/mo</span></h3>
          <div className={styles.description}>Remove branding from all of your forms.</div>
        </div>

        <Toggle className={styles.toggleInput} readOnly={true} checked={!!plan.brandingRemoval} onChange={() => { }} />
      </li>

    </ul>

    {loading && <Loader size="50" background="rgba(255, 255, 255, 0.5)" />}
  </>;
};

export default PlanSetup;
