import styles from './ThemeMenu.module.css';

import React, { useEffect } from 'react';
import ScrollArea from 'react-scrollbar';
import { useSelector, useDispatch } from 'react-redux';
import { HashLink as Link } from 'react-router-hash-link';

import demoTheme from './DemoTheme.json';

import { ReactComponent as PhoneIcon } from 'assets/images/phone.svg';
import { ReactComponent as TabletIcon } from 'assets/images/tablet.svg';
import { ReactComponent as MonitorIcon } from 'assets/images/monitor.svg';
import { ReactComponent as DropdowArrowSmall } from 'assets/images/dropdow-arrow-small.svg';

import ImageChoice from './Menu/ImageChoice.js';
import Buttons from './Menu/Buttons.js';
import Titles from './Menu/Titles.js';
import Colors from './Menu/Colors.js';
import Dividers from './Menu/Dividers.js';
import Signatures from './Menu/Signatures.js';
import Descriptions from './Menu/Descriptions.js';
import FileUploads from './Menu/FileUploads.js';
import Background from './Menu/Background.js';
import DisplaySettings from './Menu/DisplaySettings.js';
import GeneralFieldsSettings from './Menu/GeneralFieldsSettings.js';
import TypographyFields from './Menu/TypographyFields.js';
import InputFieldsDropdowns from './Menu/InputFieldsDropdowns.js';
import SingleMultipleChoice from './Menu/SingleMultipleChoice.js';
import Images from './Menu/Images.js';
import Scales from './Menu/Scales.js';
import ThankYouScreen from './Menu/ThankYouScreen.js';
import WelcomeScreen from './Menu/WelcomeScreen.js';
import ProgressBar from './Menu/ProgressBar.js';

import { TextInput, FormRow, Loader, Select } from 'ui';

import {
  getForm
} from 'store/ducks/_forms.js';

import {
  updatePreviewForm, getFormValues, showCreateThemeModal, toggleMenuLoading, updateDevice
} from 'store/ducks/theme.js';

import {
  uploadImage, removeImage,
  updateThemeSettings, ioUpdateThemeSettings,
  ioUpdateThemeColors,
  updateThemeImages, ioUpdateThemeImages,
  updateThemeName, ioUpdateThemeName
} from 'store/ducks/_themes.js';

const ThemeMenu = ({ history, match }) => {
  const dispatch = useDispatch();

  let scrollAreaRef = React.createRef();

  const device = useSelector((state) => state.theme.device);
  const previewForm = useSelector(state => state.theme.previewForm);
  const forms = useSelector(state => state._forms.forms);
  const menuLoading = useSelector((state) => state.theme.menuLoading);
  const themes = useSelector(state => state._themes.themes);

  let theme = (themes[match.params.type] || []).find((t) => t._id === match.params.id);

  theme = theme ? JSON.parse(JSON.stringify(theme)) : { settings: {}, colors: [], images: {} };

  const settings = { ...(theme || { settings: {} }).settings };
  const colors = [...(theme || { colors: [] }).colors];
  const images = { ...(theme || { images: {} }).images };

  const formOptions = [
    { label: 'Demo form', value: null },
    ...forms.map((f) => {
      return { label: f.name, value: f._id };
    })
  ];

  const clearBackground = () => {
    if (images.background.type === 'upload' && images.background.url) {
      dispatch(removeImage(match.params.id, 'background', images.background.url));
    }

    images.background.authorName = null;
    images.background.authorUrl = null;
    images.background.thumb = null;
    images.background.type = null;
    images.background.url = null;

    dispatch(updateThemeImages(match.params.type, match.params.id, images));
    dispatch(ioUpdateThemeImages(match.params.type, match.params.id, images));
  }

  const handleChange = (key, value) => {
    if (match.params.type === 'stock') {
      dispatch(showCreateThemeModal(match.params.id));
    }

    if (match.params.type === 'custom') {
      if (typeof value === 'object') {
        value = `$color:${value._id}`;
      }

      if (key === 'backgroundUseImage' || key === 'backgroundImageSource') {
        clearBackground();
      }

      dispatch(updateThemeSettings(match.params.type, match.params.id, key, value));
      dispatch(ioUpdateThemeSettings(match.params.type, match.params.id, key, value));
    }
  };

  const handleNameChange = (name) => {
    if (match.params.type === 'stock') {
      dispatch(showCreateThemeModal(match.params.id));
    }

    if (match.params.type === 'custom') {
      dispatch(updateThemeName(match.params.id, name));
      dispatch(ioUpdateThemeName(match.params.id, name));
    }
  };

  const handleColorChange = (colors, showLoader, removedColor) => {
    const settingsCopy = { ...theme.settings };

    if (match.params.type === 'stock') {
      dispatch(showCreateThemeModal(match.params.id));
    }

    if (match.params.type === 'custom') {
      if (showLoader) dispatch(toggleMenuLoading(true));
      dispatch(ioUpdateThemeColors(match.params.type, match.params.id, colors));

      if (!removedColor) return;

      Object.keys(settingsCopy).map((key) => {
        if (typeof settingsCopy[key] === 'string' && settingsCopy[key] === `$color:${removedColor._id}`) {
          dispatch(updateThemeSettings(match.params.type, match.params.id, key, removedColor.value));
          dispatch(ioUpdateThemeSettings(match.params.type, match.params.id, key, removedColor.value));
        }
      });
    }
  };

  const handleNewColor = () => {
    if (match.params.type === 'stock') {
      dispatch(showCreateThemeModal(match.params.id));
    }

    history.push(`/themes/custom/${match.params.id}/colors`);
  };

  const handleUnsplashChange = (key, value) => {
    if (match.params.type === 'stock') {
      dispatch(showCreateThemeModal(match.params.id));
    }

    if (match.params.type === 'custom') {
      images.background.authorName = value.authorName;
      images.background.authorUrl = value.authorUrl;
      images.background.thumb = value.thumb;
      images.background.type = 'unsplash';
      images.background.url = value.thumb.replace('&w=200', '&w=1920');

      dispatch(updateThemeImages(match.params.type, match.params.id, images));
      dispatch(ioUpdateThemeImages(match.params.type, match.params.id, images));
    }
  };

  const handleUrlChange = (key, value) => {
    if (match.params.type === 'stock') {
      dispatch(showCreateThemeModal(match.params.id));
    }

    if (match.params.type === 'custom') {
      images.background.authorName = null;
      images.background.authorUrl = null;
      images.background.thumb = value;
      images.background.type = 'url';
      images.background.url = value;

      dispatch(updateThemeImages(match.params.type, match.params.id, images));
      dispatch(ioUpdateThemeImages(match.params.type, match.params.id, images));
    }
  };

  const handleBackgroundUpload = (file) => {
    if (match.params.type === 'stock') {
      dispatch(showCreateThemeModal(match.params.id));
    }

    if (match.params.type === 'custom') {
      dispatch(uploadImage(match.params.type, match.params.id, 'background', file));
    }
  };

  const handleRemoveBackgroundClick = () => {
    if (match.params.type === 'stock') {
      dispatch(showCreateThemeModal(match.params.id));
    }

    if (match.params.type === 'custom') {
      dispatch(removeImage(match.params.id, 'background', images.background.url));

      clearBackground();
    }
  };

  const handleNavigationChange = () => {
    scrollAreaRef.current.scrollArea.scrollTop();
  };

  const handleCopy = (values) => {
    let val;

    for (let arr of values) {
      val = settings[arr[0]];

      if (typeof val === 'object') val = `$color:${val._id}`;

      dispatch(updateThemeSettings(match.params.type, match.params.id, arr[1], val));
      dispatch(ioUpdateThemeSettings(match.params.type, match.params.id, arr[1], val));
    }
  };

  const handleFormChange = (id) => {
    dispatch(updatePreviewForm(id));

    if (id === null) return dispatch(getFormValues(demoTheme.fields));

    dispatch(getForm(id)).then((f) => dispatch(getFormValues(f.fields)));
  };

  if (!theme) return <></>;

  Object.keys(settings).map((key) => {
    if (typeof settings[key] !== 'string') return;

    const colorId = settings[key].replace('$color:', '');
    const colorObject = colors.find((c) => c._id === colorId);

    settings[key] = typeof colorObject !== 'undefined' ? colorObject : (settings[key].indexOf('$color:') === -1 ? settings[key] : 'rgba(255, 0, 0, 1)');
  });

  return (
    <div className={styles.main}>
      <div className={styles.header}>
        <ul className={styles.resize}>
          <li className={device === 'phone' ? styles.active : ''} onClick={() => dispatch(updateDevice('phone'))}><PhoneIcon /></li>
          <li className={device === 'tablet' ? styles.active : ''} onClick={() => dispatch(updateDevice('tablet'))}><TabletIcon /></li>
          <li className={device === 'desktop' ? styles.active : ''} onClick={() => dispatch(updateDevice('desktop'))}><MonitorIcon /></li>
        </ul>

        <Select width={150} options={formOptions} value={
          { label: formOptions.find((f) => f.value === previewForm).label, value: previewForm }
        } onChange={(selection) => handleFormChange(selection.value)} />
      </div>

      {match.params.page && <div className={styles.sectionHead}>
        <Link to={`/themes/${match.params.type}/${match.params.id}`} className={styles.back} onClick={handleNavigationChange}>
          <DropdowArrowSmall />Back
        </Link>
        <div className={styles.title}>
          {/* GENERAL SETTINGS */}
          {match.params.page === 'display-settings' && 'Display Settings'}
          {match.params.page === 'background' && 'Background'}
          {match.params.page === 'typography' && 'Typography'}
          {match.params.page === 'colors' && 'Predefined Colors'}
          {match.params.page === 'progress-bar' && 'Progress Bar'}

          {/* FIELDS SETTINGS */}
          {match.params.page === 'general-fields-settings' && 'General Fields Settings'}
          {match.params.page === 'input-fields-dropdowns' && 'Input Fields & Dropdowns'}
          {match.params.page === 'single-multiple-choice' && 'Single/Multiple Choice'}
          {match.params.page === 'image-choice' && 'Image Choice'}
          {match.params.page === 'file-upload' && 'File Upload'}
          {match.params.page === 'date-time' && 'Date-Time'}
          {match.params.page === 'signatures' && 'Signatures'}
          {match.params.page === 'titles' && 'Titles'}
          {match.params.page === 'descriptions' && 'Descriptions'}
          {match.params.page === 'scales' && 'Scales'}
          {match.params.page === 'dividers' && 'Dividers'}
          {match.params.page === 'images' && 'Images'}

          {match.params.page === 'buttons' && 'Buttons'}

          {/* PAGES */}
          {match.params.page === 'welcome-screen' && 'Welcome Screen'}
          {match.params.page === 'thank-you-screen' && 'Thank You Screen'}
        </div>
      </div>}

      <ScrollArea speed={0.8}
        ref={scrollAreaRef}
        className={styles.scrollArea}
        contentClassName={styles.scrollAreaContent}
        stopScrollPropagation={true}
        horizontal={false}>

        {!match.params.page && <>
          <div className={styles.menu}>
            <div className={styles.label}>GENERAL SETTINGS</div>
            <ul>
              <li><Link to={`/themes/${match.params.type}/${match.params.id}/display-settings`} onClick={handleNavigationChange}>Display Settings</Link></li>
              <li><Link to={`/themes/${match.params.type}/${match.params.id}/general-fields-settings`} onClick={handleNavigationChange}>Fields Settings</Link></li>
              <li><Link to={`/themes/${match.params.type}/${match.params.id}/background`} onClick={handleNavigationChange}>Background</Link></li>
              <li><Link to={`/themes/${match.params.type}/${match.params.id}/typography`} onClick={handleNavigationChange}>Typography</Link></li>
              <li><Link to={`/themes/${match.params.type}/${match.params.id}/colors`} onClick={handleNavigationChange}>Predefined Colors</Link></li>
              <li><Link to={`/themes/${match.params.type}/${match.params.id}/progress-bar`} onClick={handleNavigationChange}>Progress Bar</Link></li>
            </ul>

            <div className={styles.label}>FIELDS SETTINGS</div>
            <ul>
              <li><Link to={`/themes/${match.params.type}/${match.params.id}/input-fields-dropdowns`} onClick={handleNavigationChange}>Input Fields & Dropdowns</Link></li>
              <li><Link to={`/themes/${match.params.type}/${match.params.id}/single-multiple-choice`} onClick={handleNavigationChange}>Single/Multiple Choice</Link></li>
              <li><Link to={`/themes/${match.params.type}/${match.params.id}/image-choice`} onClick={handleNavigationChange}>Image Choice</Link></li>
              <li><Link to={`/themes/${match.params.type}/${match.params.id}/scales`} onClick={handleNavigationChange}>Scales</Link></li>
              <li><Link to={`/themes/${match.params.type}/${match.params.id}/file-upload`} onClick={handleNavigationChange}>File Upload</Link></li>
              <li><Link to={`/themes/${match.params.type}/${match.params.id}/signatures`} onClick={handleNavigationChange}>Signatures</Link></li>
              <li><Link to={`/themes/${match.params.type}/${match.params.id}/titles`} onClick={handleNavigationChange}>Titles</Link></li>
              <li><Link to={`/themes/${match.params.type}/${match.params.id}/descriptions`} onClick={handleNavigationChange}>Descriptions</Link></li>
              <li><Link to={`/themes/${match.params.type}/${match.params.id}/dividers`} onClick={handleNavigationChange}>Dividers</Link></li>
              <li><Link to={`/themes/${match.params.type}/${match.params.id}/images`} onClick={handleNavigationChange}>Images</Link></li>
              <li><Link to={`/themes/${match.params.type}/${match.params.id}/buttons`} onClick={handleNavigationChange}>Buttons</Link></li>
            </ul>

            <div className={styles.label}>PAGES SETTINGS</div>
            <ul>
              <li><Link to={`/themes/${match.params.type}/${match.params.id}/welcome-screen`} onClick={handleNavigationChange}>Welcome Screen</Link></li>
              <li><Link to={`/themes/${match.params.type}/${match.params.id}/thank-you-screen`} onClick={handleNavigationChange}>Thank You Screen</Link></li>
              {/* <li><Link to={`/themes/${match.params.type}/${match.params.id}/review-screen`} onClick={handleNavigationChange}>Review Screen</Link></li> */}
            </ul>
          </div>

          <div className={styles.info}>
            <div className={styles.label}>Theme information</div>

            <FormRow label="Theme Name" style={{ margin: '20px 0 15px 0' }}>
              <TextInput value={theme.name} width={'100%'} disabled={match.params.type === 'stock'} onChange={(e) => handleNameChange(e.target.value)} />
            </FormRow>
          </div>
        </>}

        {match.params.page && Object.keys(settings).length > 0 && <div className={styles.options}>
          {/* GENERAL SETTINGS */}
          {match.params.page === 'display-settings' && <DisplaySettings settings={settings} colors={colors} onChange={handleChange} onNewColor={handleNewColor} />}
          {match.params.page === 'background' && <Background settings={settings} colors={colors} images={images} onChange={handleChange} onUrlChange={handleUrlChange} onUnsplashChange={handleUnsplashChange} onBackgroundUpload={handleBackgroundUpload} onRemoveBackgroundlick={handleRemoveBackgroundClick} />}
          {match.params.page === 'typography' && <TypographyFields settings={settings} colors={colors} onChange={handleChange} />}
          {match.params.page === 'colors' && <Colors settings={settings} colors={colors} onChange={handleColorChange} />}
          {match.params.page === 'progress-bar' && <ProgressBar settings={settings} colors={colors} onChange={handleChange} onNewColor={handleNewColor} />}

          {/* FIELDS SETTINGS */}
          {match.params.page === 'general-fields-settings' && <GeneralFieldsSettings settings={settings} colors={colors} onChange={handleChange} onNewColor={handleNewColor} onCopy={handleCopy} />}
          {match.params.page === 'input-fields-dropdowns' && <InputFieldsDropdowns settings={settings} colors={colors} onChange={handleChange} onNewColor={handleNewColor} onCopy={handleCopy} />}
          {match.params.page === 'single-multiple-choice' && <SingleMultipleChoice settings={settings} colors={colors} onChange={handleChange} onNewColor={handleNewColor} onCopy={handleCopy} />}
          {match.params.page === 'image-choice' && <ImageChoice settings={settings} colors={colors} onChange={handleChange} onNewColor={handleNewColor} onCopy={handleCopy} />}
          {match.params.page === 'file-upload' && <FileUploads settings={settings} colors={colors} onChange={handleChange} onNewColor={handleNewColor} onCopy={handleCopy} />}
          {match.params.page === 'signatures' && <Signatures settings={settings} colors={colors} onChange={handleChange} onNewColor={handleNewColor} onCopy={handleCopy} />}
          {match.params.page === 'titles' && <Titles settings={settings} colors={colors} onChange={handleChange} onNewColor={handleNewColor} onCopy={handleCopy} />}
          {match.params.page === 'descriptions' && <Descriptions settings={settings} colors={colors} onChange={handleChange} onNewColor={handleNewColor} onCopy={handleCopy} />}
          {match.params.page === 'dividers' && <Dividers settings={settings} colors={colors} onChange={handleChange} onNewColor={handleNewColor} onCopy={handleCopy} />}
          {match.params.page === 'images' && <Images settings={settings} colors={colors} onChange={handleChange} onNewColor={handleNewColor} onCopy={handleCopy} />}
          {match.params.page === 'scales' && <Scales settings={settings} colors={colors} onChange={handleChange} onNewColor={handleNewColor} onCopy={handleCopy} />}

          {match.params.page === 'buttons' && <Buttons settings={settings} colors={colors} onChange={handleChange} onNewColor={handleNewColor} onCopy={handleCopy} />}

          {/* PAGES */}
          {match.params.page === 'welcome-screen' && <WelcomeScreen settings={settings} colors={colors} onChange={handleChange} onNewColor={handleNewColor} onCopy={handleCopy} />}
          {match.params.page === 'thank-you-screen' && <ThankYouScreen settings={settings} colors={colors} onChange={handleChange} onNewColor={handleNewColor} onCopy={handleCopy} />}
        </div>}
      </ScrollArea>

      {menuLoading && <div className={styles.loader}>
        <Loader size={40} />
      </div>}
    </div>
  );
}

export default ThemeMenu;
