import styles from './Integrations.module.css';

import React from 'react';

import { Button, Card, CardRow, CardRowContent } from 'ui';

import logoIcon from 'assets/images/integrations/integrately-small.svg';

const Integrately = () => {
  return <>
    <div className={styles.head}>
      <img src={logoIcon} width="32px" alt="logo" className={styles.logo} />
      <div className={styles.label}>Integrately</div>
      <div className={styles.desc}>Integrate your form with Integrately and push data you collect on QuestionScout to hundreds of external services.</div>

      <div className={styles.statusLabel}>Integration Status</div>
      <div className={styles.statusValue}>
        <div className={styles.statusValueText}>This integration must be enabled through Integrately.com and cannot be controlled here.</div>
      </div>
    </div>

    <Card className={styles.card}>
      <CardRow>
        <CardRowContent>
          <Button theme="white" href="https://integrately.com" target="_blank" rel="noopener noreferrer">Go to Integrately.com</Button>
        </CardRowContent>
      </CardRow>
    </Card>
  </>;
}

export default Integrately;
