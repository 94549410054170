import styles from './SettingsSeo.module.css';

import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import debounce from 'lodash.debounce';
import { useDropzone } from 'react-dropzone';
import { v4 as uuid } from 'uuid';

import { TextInput, TextArea, Card, CardRow, CardRowLabel, CardRowContent, Select, Loader, FormRow } from 'ui';

import {
  ioUpdateForm, uploadImage, removeImage, updateForm
} from 'store/ducks/builder.js';

function UploadArea({ id, uploads, value, onRemove, disabled }) {
  const dispatch = useDispatch();

  const file = uploads.find((file) => file.ref === id) || null;

  const { getRootProps, getInputProps, open } = useDropzone({
    accept: 'image/png, image/jpg, image/jpeg, image/gif',
    multiple: false,
    noClick: true,
    noKeyboard: true,
    disabled,
    onDrop: (acceptedFiles) => dispatch(uploadImage(id, acceptedFiles, { type: 'ogimage' }))
  });

  return (
    <div className={styles.imageUpload}>
      {!value && <div {...getRootProps({ className: [styles.dropzone, disabled ? styles.disabled : ''].join(' ') })}>
        <input {...getInputProps()} />
        {(!file) && <>
          <p>Drop your image here</p>
          <p>or</p>
          <span onClick={open}>click to upload</span>
        </>}
        {file && file.loaded !== true && <Loader size={30} />}
      </div>}

      {value && <div className={[styles.uploaded, disabled ? styles.disabled : ''].join(' ')}>
        <div className={styles.preview}>
          <img src={value} alt="" />
        </div>
        <span className={styles.removeImage} onClick={onRemove}>Remove current image</span>
      </div>}
    </div>
  );
};

const SettingsSeo = ({ match }) => {
  const dispatch = useDispatch();

  const form = useSelector(state => state.builder.form);
  const uploads = useSelector(state => state.builder.uploads);

  const seo = form.seo ? form.seo : {};

  const ioUpdateFormWithDelay = debounce((params) => {
    dispatch(ioUpdateForm(params));
  }, 500);

  const handleChange = (params, delay = false) => {
    let copy = { ...form.seo, ...params };

    dispatch(updateForm({ seo: copy }));

    if (delay) {
      ioUpdateFormWithDelay({ seo: copy });
    } else {
      dispatch(ioUpdateForm({ seo: copy }));
    }
  };

  return <>
    <Card className={styles.card}>
      <CardRow>
        <CardRowLabel title="Meta Settings" subTitle="Modify the way your QuestionScout form links appear on search engines, social media, messengers and more." />

        <CardRowContent>
          <FormRow label="Meta Image" description="This image should be 1200px by 630px.">
            <UploadArea id={uuid()} value={seo.image} uploads={uploads} onRemove={() => {
              dispatch(removeImage(null, seo.image));
              handleChange({ image: null });
            }} />
          </FormRow>

          <FormRow label="Meta Title">
            <TextInput value={seo.title} width={'100%'} onChange={(e) => handleChange({ title: e.target.value }, true)} />
          </FormRow>

          <FormRow label="Meta Description">
            <TextArea value={seo.description} onChange={(e) => handleChange({ description: e.target.value }, true)} />
          </FormRow>
        </CardRowContent>
      </CardRow>
    </Card>
  </>;
}

export default SettingsSeo;
