import styles from './Menu.module.css';

import React from 'react';

import {
  TitleField, SizeWeightAndColorField, CheckboxField, SizeWithColorField,
  SizeWithTypeField, SizeField, SelectField, ColorField, GradientField,
  InputStyleField, ToggleField
} from '../ThemeMenuFields.js';

const fontWeightOptions = [
  { label: 400, value: 400 },
  { label: 600, value: 600 }
];

const requireIconOptions = [
  { label: '*', value: '*' }
];

const GeneralFieldsSettings = ({ settings, colors, onChange, onNewColor }) => {
  return (
    <>
      <TitleField title="Field" />

      <SizeField title="Margin between label and field content"
        settings={settings}
        onChange={onChange}
        margin="25px 0 0 0"
        size={`generalFieldsSettingsLabelMargin`} />

      <SizeField title="Margin between supporting text and field content"
        settings={settings}
        onChange={onChange}
        margin="25px 0 0 0"
        size={`generalFieldsSettingsSupportingTextMargin`} />

      <TitleField title="Label and Supporting Text Styling" />

      <SizeWeightAndColorField title="Field label text"
        settings={settings}
        colors={colors}
        onChange={onChange}
        onNewColor={onNewColor}
        weights={fontWeightOptions}
        size="generalFieldsSettingsTextIdleLabelFontSize"
        weight="generalFieldsSettingsTextIdleLabelWeight"
        color="generalFieldsSettingsTextIdleLabelColor" />

      <SizeWeightAndColorField title="Supporting text"
        settings={settings}
        colors={colors}
        onChange={onChange}
        onNewColor={onNewColor}
        weights={fontWeightOptions}
        margin="25px 0 0 0"
        size="generalFieldsSettingsTextIdleSupportingFontSize"
        weight="generalFieldsSettingsTextIdleSupportingWeight"
        color="generalFieldsSettingsTextIdleSupportingColor" />

      {/* <TitleField title="Text Styling" subTitle="Hover" />

      <ToggleField label="Different styling on hover"
        settings={settings}
        onChange={onChange}
        margin="25px 0 0 0"
        value="generalFieldsSettingsTextHoverEnable" />

      {settings.generalFieldsSettingsTextHoverEnable && <>
        <SizeWeightAndColorField title="Field label text"
          settings={settings}
          colors={colors}
          onChange={onChange}
          onNewColor={onNewColor}
          weights={fontWeightOptions}
          margin="10px 0 0 0"
          size="generalFieldsSettingsTextHoverLabelFontSize"
          weight="generalFieldsSettingsTextHoverLabelWeight"
          color="generalFieldsSettingsTextHoverLabelColor" />

        <SizeWeightAndColorField title="Supporting text"
          settings={settings}
          colors={colors}
          onChange={onChange}
          onNewColor={onNewColor}
          weights={fontWeightOptions}
          margin="25px 0 0 0"
          size="generalFieldsSettingsTextHoverSupportingFontSize"
          weight="generalFieldsSettingsTextHoverSupportingWeight"
          color="generalFieldsSettingsTextHoverSupportingColor" />
      </>} */}

      {/* <TitleField title="Text Styling" subTitle="Active" />

      <ToggleField label="Different styling on active"
        settings={settings}
        onChange={onChange}
        margin="25px 0 0 0"
        value="generalFieldsSettingsTextActiveEnable" />

      {settings.generalFieldsSettingsTextActiveEnable && <>
        <SizeWeightAndColorField title="Field label text"
          settings={settings}
          colors={colors}
          onChange={onChange}
          onNewColor={onNewColor}
          weights={fontWeightOptions}
          margin="10px 0 0 0"
          size="generalFieldsSettingsTextActiveLabelFontSize"
          weight="generalFieldsSettingsTextActiveLabelWeight"
          color="generalFieldsSettingsTextActiveLabelColor" />

        <SizeWeightAndColorField title="Supporting text"
          settings={settings}
          colors={colors}
          onChange={onChange}
          onNewColor={onNewColor}
          weights={fontWeightOptions}
          margin="25px 0 0 0"
          size="generalFieldsSettingsTextActiveSupportingFontSize"
          weight="generalFieldsSettingsTextActiveSupportingWeight"
          color="generalFieldsSettingsTextActiveSupportingColor" />
      </>} */}

      <TitleField title="Required Fields" />

      <SelectField title="Icon"
        settings={settings}
        onChange={onChange}
        options={requireIconOptions}
        margin="25px 0 0 0"
        value="generalFieldsSettingsRequiredIcon" />

      <ColorField title="Color"
        settings={settings}
        colors={colors}
        onChange={onChange}
        onNewColor={onNewColor}
        margin="25px 0 0 0"
        color="generalFieldsSettingsRequiredColor" />
    </>
  );
}

export default GeneralFieldsSettings;
