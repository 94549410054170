import styles from './Values.module.css';
import ClampLines from 'react-clamp-lines';
import { formatBytes, isJsonString } from 'misc/helpers.js';
import getFieldLabel from 'helpers/getFieldLabel.js';
import { format } from 'date-fns';
import React from 'react';

export const Title = ({ columnObj, values, options, variables, textOnly }) => {
  return (
    <>
      {!textOnly && <>
        {getFieldLabel(columnObj.label, values, 'jsx', options, variables)}
        {getFieldLabel(columnObj.description, values, 'jsx', options, variables)}
      </>}
      {textOnly && <>
        {getFieldLabel(columnObj.label, values, 'text', options, variables)}
        {getFieldLabel(columnObj.description, values, 'text', options, variables)}
      </>}
    </>
  );
};

export const Description = ({ valueObj, textOnly, values, options, variables }) => {
  return (
    <>
      {(valueObj.value && !textOnly) && getFieldLabel(valueObj.value, values, 'jsx', options, variables)}
      {(valueObj.value && textOnly) && getFieldLabel(valueObj.value, values, 'text', options, variables)}
    </>
  );
};


export const ShortText = ({ valueObj, textOnly }) => {
  return (
    <>
      {(valueObj.value && !textOnly) && <ClampLines text={valueObj.value} lines={4} buttons={true} moreText="Show More" lessText="Show Less" />}
      {(valueObj.value && textOnly) && <>{valueObj.value}</>}
    </>
  );
};

export const LongText = ({ valueObj, textOnly }) => {
  return (
    <>
      {(valueObj.value && !textOnly) && <ClampLines text={valueObj.value} lines={4} buttons={true} moreText="Show More" lessText="Show Less" />}
      {(valueObj.value && textOnly) && <>{valueObj.value}</>}
    </>
  );
};

export const Dropdown = ({ valueObj, options }) => {
  return (
    <>
      {(valueObj.value && options.length > 0) && (options.find((option) => option.ref === valueObj.value) || { value: null }).value}
    </>
  );
};

export const Radio = ({ valueObj, options }) => {
  const value = JSON.parse(valueObj.value);

  if (value === null || typeof value === 'undefined') return <></>;

  return (
    <>
      {(value.other && !value.value) && <><strong>Other:</strong> {value.other}</>}
      {(!value.other && value.value && options.length > 0) && (options.find((option) => option.ref === value.value) || { value: null }).value}
    </>
  );
};

export const Checkbox = ({ valueObj, options, textOnly }) => {
  const value = JSON.parse(valueObj.value);

  if (value === null || typeof value === 'undefined') return <></>;

  return (
    <>
      {!textOnly && <ul>
        {(value.values && options.length > 0) && options.map((option) => {
          if (!value.values.find((ref) => ref === option.ref)) return;

          return (
            <li key={option._id}>{option.value}</li>
          )
        })}
        {value.other && <li><strong>Other:</strong> {value.other}</li>}
      </ul>}

      {textOnly && value.values && options.length > 0 && <>
        {options.filter((option) => value.values.find((ref) => ref === option.ref)).map((option) => option.value).join(', ')}
        {value.other && <> & {value.other}</>}
      </>}
    </>
  );
};

export const Datetime = ({ valueObj, textOnly, dateTimeMode }) => {
  let value;
  let rawObject;
  let dateObject;

  const options = {
    datetime: (obj) => format(obj, 'Pp'),
    date: (obj) => format(obj, 'P'),
    time: (obj) => format(obj, 'p')
  };

  if (!dateTimeMode) return <></>;

  if (isJsonString(valueObj.value)) {
    rawObject = JSON.parse(valueObj.value);
    dateObject = new Date(rawObject.year || 0, rawObject.month ? rawObject.month - 1 : 0, rawObject.day || 0, rawObject.hour || 0, rawObject.minute || 0, 0, 0);

    value = options[dateTimeMode](dateObject);
  } else {
    value = valueObj.value;
  }

  return (
    <>
      {value}
    </>
  );
};

export const Signature = ({ valueObj, textOnly }) => {
  return (
    <>
      <img src={valueObj.value} alt="Signature" width="345" height="150" />
    </>
  );
};

export const Scale = ({ valueObj, textOnly }) => {
  return (
    <>
      {valueObj.value}
    </>
  );
};

export const FileUpload = ({ valueObj, textOnly, handleDownload }) => {
  const files = valueObj.value;

  return (
    <>
      {!textOnly && <ul className={styles.fileUpload}>
        {files.length > 0 && files.map((file) => <li key={file._id}>
          {!file.hidden && <span className={styles.name} onClick={() => handleDownload(file._id)}>{file.name}</span>}
          {file.hidden && <span className={[styles.name, styles.hidden].join(' ')}>{`${Math.random().toString(36).substring(2, 15)}-${Math.random().toString(36).substring(2, 15)}.xyz`}</span>}
          <span className={styles.size}>{formatBytes(file.size * 1000000)}</span>
        </li>)}
      </ul>}

      {textOnly && <>{files.length} file{files.length === 1 ? '' : 's'}</>}
    </>
  );
};

export const ImageChoice = ({ valueObj, options, textOnly }) => {
  const value = JSON.parse(valueObj.value);
  let objValue = {};

  if (value === null || typeof value === 'undefined') return <></>;

  return (
    <>
      {!textOnly && <ul className={styles.imageChoice}>
        {(value.values && options.length > 0) && options.map((option) => {
          if (!value.values.find((ref) => ref === option.ref)) return;

          objValue = JSON.parse(option.value);

          return (
            <li key={option._id}>
              <div className={styles.imageChoiceImg}>
                <img src={objValue.url} alt={objValue.text} />
              </div>
              <span>{objValue.text}</span>
            </li>
          )
        })}
      </ul>}
    </>
  );
};