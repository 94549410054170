import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import debounce from 'lodash.debounce';

import GoogleTagManager from './GoogleTagManager.js';
import FacebookPixel from './FacebookPixel.js';
import GoogleAnalytics from './GoogleAnalytics.js';
import Zapier from './Zapier.js';
import Integrately from './Integrately.js';

import {
  ioUpdateForm, updateForm
} from 'store/ducks/builder.js';

const Integrations = ({ match }) => {
  const dispatch = useDispatch();

  const integrations = useSelector(state => state.builder.form.integrations);

  const ioUpdateFormDebounce = debounce((params) => {
    dispatch(ioUpdateForm(params));
  }, 500);

  const handleChange = (key, value, delay) => {
    const params = {
      integrations: {
        ...integrations,
        [match.params.subPage]: {
          ...integrations[match.params.subPage] || {},
          [key]: value
        }
      }
    };

    dispatch(updateForm(params));

    if (delay) {
      ioUpdateFormDebounce(params);
    } else {
      dispatch(ioUpdateForm(params));
    }
  };

  const params = {
    integration: integrations[match.params.subPage] || {},
    onChange: handleChange
  };

  return <>
    {match.params.subPage === 'googleTagManager' && <GoogleTagManager {...params} />}
    {match.params.subPage === 'facebookPixel' && <FacebookPixel {...params} />}
    {match.params.subPage === 'googleAnalytics' && <GoogleAnalytics {...params} />}
    {match.params.subPage === 'zapier' && <Zapier {...params} />}
    {match.params.subPage === 'integrately' && <Integrately {...params} />}
  </>;
}

export default Integrations;
