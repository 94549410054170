import styles from './Edit.module.css';
import editStyles from 'components/Builder/Edit.module.css';

import React, { Component } from 'react';
import { connect } from 'react-redux';
import OutsideClickHandler from 'react-outside-click-handler';
import getFieldLabel from 'helpers/getFieldLabel.js';
import _range from 'lodash/range';
import ObjectID from 'bson-objectid';
import _debounce from 'lodash.debounce';

import { Select, Select2, TextInput } from 'ui';

import { ReactComponent as CloseIcon } from 'assets/images/close.svg';
import { ReactComponent as RemoveIcon } from 'assets/images/trash.svg';
import { ReactComponent as ShareIcon } from 'assets/images/menu-publish.svg';

import {
  updateEditedFieldRef, updateField, ioUpdateField
} from 'store/ducks/builder.js';

const typesLabels = {
  equal: 'Is',
  notequal: 'Is not'
};

const conditionsTypeLabels = {
  all: 'All',
  any: 'Any'
};

const conditionsStateLabels = {
  hide: 'Hide',
  show: 'Show'
};

const calculationTypeOptions = [
  { label: '= equal to', value: '=' },
  { label: '!= not equal to', value: '!=' },
  { label: '< less than', value: '<' },
  { label: '> more than', value: '>' },
  { label: '>= more or equal to', value: '>=' },
  { label: '<= less or equal to', value: '<=' },
];

const conditionsCompareOptions = [
  { label: '(number)', value: 'number' },
  { label: '(variable)', value: 'variable' },
];

class Edit extends Component {
  close = () => {
    this.props.updateEditedFieldRef(null);
  }

  handleOutsideClick = (e) => {
    try {
      if (e.target.getAttribute('data-not-outside') !== 'true') {
        this.close();
      }
    } catch (e) { }
  }

  ioUpdateFieldWithDelay = _debounce((ref, params) => {
    this.props.ioUpdateField(ref, params);
  }, 500)

  addEmptyLogic = (conditions) => {
    const props = {
      _id: ObjectID(),
      triggerField: null,
      type: 'equal',
      value: null
    };

    this.props.updateField(this.props.editedFieldRef, { conditions: [...conditions, props] });
    this.props.ioUpdateField(this.props.editedFieldRef, { conditions: [...conditions, props] });
  }

  addCalculationEmptyLogic = (calculationConditions) => {
    const props = {
      _id: ObjectID(),
      variableA: null,
      variableB: null,
      compare: 'number',
      type: '=',
      value: 0
    };

    this.props.updateField(this.props.editedFieldRef, { calculationConditions: [...calculationConditions, props] });
    this.props.ioUpdateField(this.props.editedFieldRef, { calculationConditions: [...calculationConditions, props] });
  }

  removeLogic = (id, conditions) => {
    const upadtedConditions = conditions.filter((condition) => condition._id !== id);

    this.props.updateField(this.props.editedFieldRef, { conditions: upadtedConditions });
    this.props.ioUpdateField(this.props.editedFieldRef, { conditions: upadtedConditions });
  }

  removeCalculationLogic = (id, calculationConditions) => {
    const upadtedConditions = calculationConditions.filter((condition) => condition._id !== id);

    this.props.updateField(this.props.editedFieldRef, { calculationConditions: upadtedConditions });
    this.props.ioUpdateField(this.props.editedFieldRef, { calculationConditions: upadtedConditions });
  }

  updateCondition = (id, props, conditions) => {
    const fieldIndex = conditions.findIndex((condition) => condition._id === id);

    conditions[fieldIndex] = { ...conditions[fieldIndex], ...props };

    this.props.updateField(this.props.editedFieldRef, { conditions });
    this.props.ioUpdateField(this.props.editedFieldRef, { conditions });
  }

  updateCalculationCondition = (id, props, calculationConditions, delay = false) => {
    const calculationConditionsCopy = [...calculationConditions];
    const fieldIndex = calculationConditionsCopy.findIndex((condition) => condition._id === id);

    calculationConditionsCopy[fieldIndex] = { ...calculationConditionsCopy[fieldIndex], ...props };

    this.props.updateField(this.props.editedFieldRef, { calculationConditions: calculationConditionsCopy });

    if (delay) {
      this.ioUpdateFieldWithDelay(this.props.editedFieldRef, { calculationConditions: calculationConditionsCopy });
    } else {
      this.props.ioUpdateField(this.props.editedFieldRef, { calculationConditions: calculationConditionsCopy });
    }
  }

  updateFieldConditionsType = (value) => {
    this.props.updateField(this.props.editedFieldRef, {
      conditionsType: value
    });

    this.props.ioUpdateField(this.props.editedFieldRef, {
      conditionsType: value
    });
  }

  updateFieldConditionsState = (value) => {
    this.props.updateField(this.props.editedFieldRef, {
      conditionsState: value
    });

    this.props.ioUpdateField(this.props.editedFieldRef, {
      conditionsState: value
    });
  }

  updateFieldConditionsPage = (value) => {
    this.props.updateField(this.props.editedFieldRef, {
      conditionsPage: value
    });

    this.props.ioUpdateField(this.props.editedFieldRef, {
      conditionsPage: value
    });
  }

  fieldOptionLabel = (field) => {
    return <div style={{
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      width: '100%'
    }}>
      <div style={{
        backgroundColor: this.props.columnOptions[field.type].backgroundColor,
        width: 22,
        height: 22,
        marginRight: 10,
        borderRadius: 2,
        boxSizing: 'border-box',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}>
        <img src={this.props.columnOptions[field.type].icon} width="14px" alt="" />
      </div>
      <div style={{
        width: 'calc(100% - 48px)',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap'
      }}>{getFieldLabel(field.label || field.placeholder || field.type, this.props.values)}</div>
    </div>;
  }

  fieldValueLabel = (option, field) => {
    if (!option) return '- Select -';
    if (field.type !== 'imageChoice') return option.value;

    const parsedValue = option.value ? JSON.parse(option.value) : {};

    return <div style={{
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      width: '100%',
      height: '100%'
    }}>
      <div style={{
        width: 22,
        height: 22,
        marginRight: 10,
        borderRadius: 2,
        boxSizing: 'border-box',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}>
        <img src={parsedValue.url} width="20px" alt="" />
      </div>
      <div style={{
        width: 'calc(100% - 48px)',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap'
      }}>{parsedValue.text}</div>
    </div>;
  };

  render() {
    const {
      editedFieldRef, fields, values, calculationVariables
    } = this.props;

    let page = 1;

    for (var f of fields) {
      f.page = page;

      if (f.type === 'pageBreak') {
        page += 1;
      }
    }

    const field = fields.find((field) => field.ref === editedFieldRef) || {};
    const pages = [...new Set(fields.filter((f) => field.page < f.page).map((f) => f.page))].map((page) => {
      return { label: page, value: page };
    });

    const isEmpty = field && ((field.conditions || []).length + (field.calculationConditions || []).length) === 0;

    return (
      <OutsideClickHandler onOutsideClick={(e) => this.handleOutsideClick(e)}>
        {!editedFieldRef && <>
          <div className={[styles.main, styles.empty].join(' ')}>
            <div className={styles.emptyTitle}>Conditional Logic</div>

            <hr />

            <div className={styles.emptyHead}>Adding Field Logic to your form</div>
            <div className={styles.emptyDesc}>To add Field logic, select one of the form fields to access the Field Logic settings.</div>
            <a className={styles.learnMore} href="https://support.questionscout.com/en/articles/3374693-how-to-use-field-logic" target="_blank" rel="noopener noreferrer" >Learn more about Field Logic<ShareIcon /></a>

            <div className={styles.emptyHead} style={{ margin: '40px 0 15px 0' }}>Adding Skip Logic to your form</div>
            <div className={styles.emptyDesc}>To add Skip Logic, select one of the page breaks to access the Skip Logic settings.</div>
            <a className={styles.learnMore} href="https://support.questionscout.com/en/articles/3512233-how-to-use-skip-logic" target="_blank" rel="noopener noreferrer" >Learn more about Skip Logic<ShareIcon /></a>
          </div>
        </>}

        {editedFieldRef && <div className={styles.main}>
          <div className={styles.header}>
            <div className={styles.headerText}>
              <div>Applying {field.type === 'pageBreak' ? 'skip ' : ''}logic to:</div>
              {field && <span>
                {getFieldLabel(field.label || field.placeholder, values)}
              </span>}
            </div>

            <CloseIcon onClick={() => this.close()} />
          </div>

          <div className={styles.scrollArea}>

            <div className={styles.content}>
              {field && isEmpty && <>
                {field.type !== 'pageBreak' && <div className={styles.placeholder}>
                  <span>Field Logic lets you create question paths based on how people answer.</span>
                  <div className={styles.addLogic} onClick={() => this.addEmptyLogic(field.conditions)}>Add field logic rule</div>
                  <div className={styles.addLogic} onClick={() => this.addCalculationEmptyLogic(field.calculationConditions)}>Add calculation rule</div>
                  <a className={styles.learnMore} href="https://support.questionscout.com/en/articles/3374693-how-to-use-field-logic" target="_blank" rel="noopener noreferrer" >Learn more about Field Logic<ShareIcon /></a>
                </div>}

                {field.type === 'pageBreak' && <div className={styles.placeholder}>
                  <span>Skip Logic allows the respondent to skip to a specific page depending on the selection that was made.</span>
                  <div className={styles.addLogic} onClick={() => this.addEmptyLogic(field.conditions)}>Add field logic rule</div>
                  <div className={styles.addLogic} onClick={() => this.addCalculationEmptyLogic(field.calculationConditions)}>Add calculation rule</div>
                  <a className={styles.learnMore} href="https://support.questionscout.com/en/articles/3512233-how-to-use-skip-logic" target="_blank" rel="noopener noreferrer" >Learn more about Field Logic<ShareIcon /></a>
                </div>}
              </>}

              {field && !isEmpty && <>
                {field.type !== 'pageBreak' && <div className={styles.info}>
                  <Select width={80} size="small" options={[
                    { label: 'Hide', value: 'hide' },
                    { label: 'Show', value: 'show' }
                  ]} value={{ label: conditionsStateLabels[field ? field.conditionsState : 0], value: field ? field.conditionsState : null }} onChange={(selected) => this.updateFieldConditionsState(selected.value)} />
                  <span>this field when</span>
                  <Select width={70} size="small" options={[
                    { label: 'All', value: 'all' },
                    { label: 'Any', value: 'any' }
                  ]} value={{ label: conditionsTypeLabels[field ? field.conditionsType : 0], value: field ? field.conditionsType : null }} onChange={(selected) => this.updateFieldConditionsType(selected.value)} />
                  <span>of the following logic matches:</span>
                </div>}

                {field.type === 'pageBreak' && <div className={styles.info}>
                  <span>Skip to page</span>
                  <Select width={70} size="small" options={pages} value={{ label: field.conditionsPage || (pages[0] || { label: '' }).label, value: field.conditionsPage || (pages[0] || { value: null }).value }} onChange={(selected) => this.updateFieldConditionsPage(selected.value)} />
                  <span>when</span>
                  <Select width={70} size="small" options={[
                    { label: 'All', value: 'all' },
                    { label: 'Any', value: 'any' }
                  ]} value={{ label: conditionsTypeLabels[field ? field.conditionsType : 0], value: field ? field.conditionsType : null }} onChange={(selected) => this.updateFieldConditionsType(selected.value)} />
                  <span>of the following logic matches:</span>
                </div>}

                {field && field.conditions.map((condition, index) => {
                  const selectedTriggerField = fields.find((field) => field._id === condition.triggerField);
                  let fieldSelectOptions = fields.filter((f) => f._id !== field._id && ['radio', 'checkbox', 'dropdown', 'imageChoice', 'scale'].indexOf(f.type) !== -1);
                  let selectedTriggerFieldOptions = selectedTriggerField ? selectedTriggerField.options : [];

                  if (field.type === 'pageBreak') {
                    fieldSelectOptions = fieldSelectOptions.filter((f) => field.position > f.position);
                  }

                  if (selectedTriggerField && selectedTriggerField.type === 'scale') {
                    selectedTriggerFieldOptions = _range(selectedTriggerField.scaleRange[0], selectedTriggerField.scaleRange[1] + 1).map((option) => {
                      return {
                        ref: option,
                        value: option
                      };
                    });
                  }

                  return <div key={condition._id}>
                    <div className={styles.condition}>
                      <div className={styles.conditionHeader}>
                        <span>{field.type === 'pageBreak' ? 'Skip ' : 'Field '}Logic #{index + 1}</span><RemoveIcon onClick={() => this.removeLogic(condition._id, field.conditions)} />
                      </div>
                      <div className={styles.conditionContent}>
                        {fieldSelectOptions.length > 0 && <>
                          <Select2 options={fieldSelectOptions.map((f) => {
                            return { label: this.fieldOptionLabel(f) || f.placeholder, value: f._id };
                          })} value={{ label: !selectedTriggerField ? '- Select -' : this.fieldOptionLabel(selectedTriggerField), value: condition.triggerField }} onChange={(selected) => this.updateCondition(condition._id, {
                            triggerField: selected.value,
                            value: null
                          }, field.conditions)} />

                          <Select2 options={[
                            { label: 'Is', value: 'equal' },
                            { label: 'Is not', value: 'notequal' }
                          ]} value={{ label: typesLabels[condition.type], value: condition.type }} disabled={!selectedTriggerField} onChange={(selected) => this.updateCondition(condition._id, {
                            type: selected.value
                          }, field.conditions)} />

                          <Select2 filter={true} disabled={!selectedTriggerField} options={!selectedTriggerField ? [] : selectedTriggerFieldOptions.map((option) => {
                            return { label: this.fieldValueLabel(option, selectedTriggerField), value: option.ref };
                          })} value={{ label: !selectedTriggerField ? '- Select -' : this.fieldValueLabel(selectedTriggerFieldOptions.find((f) => String(f.ref) === String(condition.value)), selectedTriggerField), value: condition.value }} onChange={(selected) => {
                            this.updateCondition(condition._id, {
                              value: selected.value
                            }, field.conditions);

                            this.updateFieldConditionsPage(field.conditionsPage || (typeof pages[0] !== 'undefined' ? pages[0].value : null));
                          }} />
                        </>}

                        {field.type !== 'pageBreak' && fieldSelectOptions.length === 0 && <span>Start by adding a Single Choice, Multiple Choice, Image Choice, Scale, or a Select List to enable logic.</span>}
                        {field.type === 'pageBreak' && fieldSelectOptions.length === 0 && <span>Start by adding a Single Choice, Multiple Choice, Image Choice, Scale, or a Select List to enable skip logic.</span>}
                      </div>
                    </div>

                    {(index !== (field.conditions.length - 1) || field.calculationConditions.length >= 1) && <div className={styles.conditionSeparator}><span>{field.conditionsType === 'all' ? 'AND' : 'OR'}</span></div>}
                  </div>;
                })}

                {field && field.calculationConditions.map((condition, index) => {
                  return <div key={condition._id}>
                    <div className={styles.condition}>
                      <div className={styles.conditionHeader}>
                        <span>Calculation Logic #{index + 1}</span><RemoveIcon onClick={() => this.removeCalculationLogic(condition._id, field.calculationConditions)} />
                      </div>
                      <div className={styles.conditionContent}>
                        <Select2 options={calculationVariables.map((f) => {
                          return { label: f.name, value: f._id };
                        })} value={{ label: (calculationVariables.find((option) => option._id === condition.variableA) || { name: '- Select -' }).name, value: condition.variableA }}
                          onChange={(selected) => this.updateCalculationCondition(condition._id, {
                            variableA: selected.value
                          }, field.calculationConditions)} />

                        <div className={styles.row}>
                          <div style={{ width: 'calc(55% - 5px)' }}>
                            <Select2 options={calculationTypeOptions} value={
                              { label: calculationTypeOptions.find((option) => option.value === condition.type).label, value: condition.type }
                            } onChange={(selected) => this.updateCalculationCondition(condition._id, {
                              type: selected.value
                            }, field.calculationConditions)} disabled={!condition.variableA} />
                          </div>
                          <div style={{ width: 'calc(45% - 5px)' }}>
                            <Select2 options={conditionsCompareOptions} value={
                              { label: conditionsCompareOptions.find((option) => option.value === condition.compare).label, value: condition.compare }
                            } onChange={(selected) => this.updateCalculationCondition(condition._id, {
                              compare: selected.value
                            }, field.calculationConditions)} disabled={!condition.variableA} />
                          </div>
                        </div>

                        {condition.compare === 'variable' && <Select options={calculationVariables.filter((f) => f._id !== condition.variableA).map((f) => {
                          return { label: f.name, value: f._id };
                        })} value={{ label: (calculationVariables.find((option) => option._id === condition.variableB) || { name: '- Select -' }).name, value: condition.variableB }}
                          onChange={(selected) => this.updateCalculationCondition(condition._id, {
                            variableB: selected.value
                          }, field.calculationConditions)} disabled={!condition.variableA} />}

                        {condition.compare === 'number' && <TextInput type="number" step="0.001" value={condition.value} onChange={(e) => this.updateCalculationCondition(condition._id, {
                          value: parseFloat(e.target.value)
                        }, field.calculationConditions, true)} disabled={!condition.variableA} />}
                      </div>
                    </div>

                    {index !== (field.calculationConditions.length - 1) && <div className={styles.conditionSeparator}><span>{field.conditionsType === 'all' ? 'AND' : 'OR'}</span></div>}
                  </div>;
                })}

                <div className={styles.addLogicButtons} >
                  <div className={styles.addLogic} onClick={() => this.addEmptyLogic(field.conditions)}>Add additional field logic rule</div>
                  <div className={styles.addLogic} onClick={() => this.addCalculationEmptyLogic(field.calculationConditions)}>Add additional calculation rule</div>
                </div>
              </>}
            </div>
          </div>
        </div>}
      </OutsideClickHandler>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    editedFieldRef: state.builder.editedFieldRef,
    fields: state.builder.fields,
    values: state.builder.values,
    columnOptions: state.results.columnOptions,
    calculationVariables: state.builder.form.calculationVariables
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateEditedFieldRef: (value) => dispatch(updateEditedFieldRef(value)),
    updateField: (ref, params) => dispatch(updateField(ref, params)),
    ioUpdateField: (ref, params) => dispatch(ioUpdateField(ref, params))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Edit);
