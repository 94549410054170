import styles from './ChangeIndicator.module.css';

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ReactComponent as SavedIcon } from 'assets/images/change/saved.svg';
import { ReactComponent as SavingIcon } from 'assets/images/change/saving.svg';

import {
  setChangeIndicatorStatus, setIntercomIsReady
} from 'store/ducks/main.js';

let changeIndicatorTimeout = null;

const ChangeIndicator = () => {
  const dispatch = useDispatch();

  const status = useSelector(state => state.main.changeIndicatorStatus);
  const intercomIsReady = useSelector(state => state.main.intercomIsReady);

  useEffect(() => {
    if (window.Intercom) window.Intercom('onUnreadCountChange', function() {
      dispatch(setIntercomIsReady());
    });
  }, []);

  useEffect(() => {
    clearTimeout(changeIndicatorTimeout);

    changeIndicatorTimeout = setTimeout(() => {
      dispatch(setChangeIndicatorStatus('saved'));
    }, 600);
  }, [status]);

  return (
    <div className={[styles.main, intercomIsReady ? styles.withIntercom : ''].join(' ')}>
     {status === 'saving' && <span className={styles.loader}></span>}
     {status === 'saved' && <SavedIcon />}
    </div>
  );
};

export default ChangeIndicator;
