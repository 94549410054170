import styles from './DeleteTheme.module.css';

import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router';

import {
  hideDeleteThemeModal
} from 'store/ducks/theme.js';

import {
  deleteTheme, resetEndpoitState
} from 'store/ducks/_themes.js';

import { Button, Modal, Loader } from 'ui';

const DeleteTheme = () => {
  const dispatch = useDispatch();

  const theme = useSelector(state => state.theme.deleteThemeModal.theme);
  const show  = useSelector(state => state.theme.deleteThemeModal._show);
  const loading = useSelector(state => state._themes.deleteTheme.loading);
  const success = useSelector(state => state._themes.deleteTheme.success);
  const error = useSelector(state => state._themes.deleteTheme.error);

  const cancel = () => {
    dispatch(resetEndpoitState('deleteTheme'));
    dispatch(hideDeleteThemeModal());
  }

  const submit = () => {
    dispatch(deleteTheme(theme));
  }

  if (success || error) {
    cancel();
  }

  return (
    <>
      <Modal isOpen={show} onRequestClose={cancel} width={320} header="Deleting Theme" footer={<>
        <Button size="small" theme="white" onClick={cancel} margin="0 15px 0 0">Cancel</Button>
        <Button size="small" theme="red" onClick={submit} disabled={loading}>Yes, delete theme</Button>
      </>}>
        <div className={styles.content}>
          {loading && <Loader size={30} />}

          {!loading && <p>Are you sure you want to delete this theme?</p>}
        </div>
      </Modal>
    </>
  );
};

export default withRouter(DeleteTheme);
