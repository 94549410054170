import styles from './Summary.module.css';

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import getFieldLabel from 'helpers/getFieldLabel.js';

import {
  getSummary
} from 'store/ducks/results.js';

import { Loader } from 'ui';

const emptyImg = require('assets/images/no-forms.png');

const Summary = () => {
  const dispatch = useDispatch();

  const loading = useSelector(state => state.results.getSummary.loading);
  const summary = useSelector(state => state.results.summary);
  const columnOptions = useSelector(state => state.results.columnOptions);

  useEffect(() => {
    dispatch(getSummary());
  }, []);

  return (
    <div className={styles.main}>
      <div className={styles.header}>
        <div className={styles.title}>Summary</div>
        <div className={styles.desc}>A summary of all responses you’ve received on this form.</div>
      </div>

      <div className={styles.content}>
        <div className={styles.section}>
          {loading && <div className={styles.loading}>
            <Loader size={50} />
          </div>}

          {!loading && <>
            {Object.keys(summary).length === 0 && <div className={styles.empty}>
              <img src={emptyImg} alt="" width="100" />
              <p>Your form is missing fields to generate a summary</p>
              <span>To start generating a responses summary, your form must include one of the following field types:</span>
              <ul>
                <li>
                  <span style={{ backgroundColor: columnOptions.scale.backgroundColor }}>
                    <img src={columnOptions.scale.icon} alt="" />
                  </span>Scale
                </li>
                <li>
                  <span style={{ backgroundColor: columnOptions.dropdown.backgroundColor }}>
                    <img src={columnOptions.dropdown.icon} alt="" />
                  </span>Dropdown
                </li>
                <li>
                  <span style={{ backgroundColor: columnOptions.radio.backgroundColor }}>
                    <img src={columnOptions.radio.icon} alt="" />
                  </span>Single Choice
                </li>
                <li>
                  <span style={{ backgroundColor: columnOptions.checkbox.backgroundColor }}>
                    <img src={columnOptions.checkbox.icon} alt="" />
                  </span>Multiple Choice
                </li>
              </ul>
            </div>}

            {Object.keys(summary).length > 0 && Object.keys(summary).map((id) => <div key={id} className={styles.card}>
              <div className={styles.head}>
                <div className={styles.type}>
                  <span style={{ backgroundColor: columnOptions[summary[id].type].backgroundColor }}>
                    <img src={columnOptions[summary[id].type].icon} alt="" />
                  </span>
                </div>
                <div className={styles.info}>
                  <div className={styles.label}>{getFieldLabel(summary[id].label)}</div>
                  <div className={styles.stats}>{summary[id].filled} out of {summary[id].total} people answered this question ({summary[id].filled === 0 ? 0 : parseInt(summary[id].filled * 100 / summary[id].total)}%)</div>
                </div>
              </div>

              <div className={styles.chart}>
                {summary[id].options.map((option) => <div className={styles.bar} key={option.ref}>
                  <div className={styles.percentage}>{parseInt(!option.count ? 0 : (option.count * 100 / summary[id].filled))}%</div>
                  <div className={styles.graph}>
                    <div className={styles.graphValue} style={{ width: `${!option.count ? 0 : (option.count * 100 / summary[id].filled)}%` }} />
                    <div className={styles.graphLabel}>{option.label}</div>
                    <div className={styles.graphCount}>{option.count} response{option.count === 1 ? '' : 's'}</div>
                  </div>
                </div>)}
              </div>
            </div>)}
          </>}
        </div>
      </div>
    </div>
  );
}

export default Summary;
