import styles from './Input.module.css';

import React, { Component } from 'react';
import ReactTooltip from 'react-tooltip';
import { CardElement } from 'react-stripe-elements';

import { ReactComponent as ViewOn } from 'assets/images/view-on.svg';
import { ReactComponent as ViewOff } from 'assets/images/view-off.svg';
import { ReactComponent as Search } from 'assets/images/search.svg';
import { ReactComponent as Question } from 'assets/images/question.svg';

export class CreditCardInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      focused: false
    };
  }

  onFocus = () => {
    this.setState({ focused: true });
  }

  onBlur = () => {
    this.setState({ focused: false });
  }

  render() {
    const {
      focused
    } = this.state;

    const {
      error, onChange
    } = this.props;

    return (
      <div className={[styles.creditCard, error ? styles.error : ''].join(' ')}>
        <div className={[
          styles.creditCardInputBackground,
          focused ? styles.active : ''
        ].join(' ')}></div>
        <div className={[
          styles.creditCardInput,
          focused ? styles.active : ''
        ].join(' ')}>
          <CardElement onFocus={() => this.onFocus()} onBlur={() => this.onBlur()} onChange={onChange} style={{
            base: {
              fontSize: '13px',
              color: '#687680'
            }
          }} />
        </div>
      </div>
    )
  }
}

export const TextInput = React.forwardRef(({ name, onChange, type, className, placeholder, margin, disabled, value, width, error, selectAllOnFocus, onKeyPress, autoFocus, onFocus, onBlur, prefix, suffix, min, max, step, pattern }, ref) => {
  const handleFocus = (e) => e.target.select();

  let classList = [
    styles.textInput,
    className,
    disabled ? styles.disabled : '',
    prefix ? styles.withPrefix : '',
    suffix ? styles.withSuffix : ''
  ];

  if (error) {
    classList.push(styles.error);
  }

  return (
    <div className={classList.join(' ')} style={{ width, margin }}>
      {suffix && <div className={styles.suffix}>{suffix}</div>}
      <input name={name}
        autoFocus={autoFocus}
        disabled={disabled}
        type={type || 'text'}
        ref={ref}
        min={min}
        max={max}
        step={step}
        pattern={pattern}
        value={(!value && value !== 0) ? '' : value}
        placeholder={placeholder}
        onKeyPress={onKeyPress}
        onFocus={selectAllOnFocus ? handleFocus : onFocus}
        onBlur={onBlur}
        onChange={onChange} />
      {prefix && <div className={styles.prefix}>{prefix}</div>}
    </div>
  );
});

export class PasswordInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showPassword: false
    };
  }

  toggleShowPassword = () => {
    this.setState({
      showPassword: !this.state.showPassword
    });
  }

  render() {
    let classList = [styles.passwordBox];

    const {
      name, onChange, placeholder, disabled, value, width, autoFocus, error, onKeyPress, onFocus, onBlur,
    } = this.props;

    if (error) {
      classList.push(styles.error);
    }

    return (
      <div className={classList.join(' ')} style={{ maxWidth: width }}>
        <input type={this.state.showPassword ? 'text' : 'password'}
          name={name}
          className={styles.passwordInput}
          disabled={disabled}
          value={(!value && value !== 0) ? '' : value}
          placeholder={placeholder}
          autoFocus={autoFocus}
          onFocus={onFocus}
          onBlur={onBlur}
          onKeyPress={onKeyPress}
          onChange={onChange} />

        <div onClick={this.toggleShowPassword} className={styles.showPasswordBtn}>
          {this.state.showPassword ? <ViewOff /> : <ViewOn />}
        </div>
      </div>
    )
  }
}

export class SearchInput extends Component {
  render() {
    const {
      name, onChange, placeholder, disabled, value, width
    } = this.props;

    return (
      <div className={styles.searchInputBox} style={{ maxWidth: width }}>
        <input type="text"
          name={name}
          className={styles.searchInput}
          disabled={disabled}
          value={(!value && value !== 0) ? '' : value}
          placeholder={placeholder}
          onChange={onChange} />

        <div className={styles.searchInputBtn}>
          <Search />
        </div>
      </div>
    )
  }
}

export const TextArea = ({ name, onChange, className, placeholder, disabled, value, width, error, onKeyPress, rows, onFocus }) => {
  let classList = [styles.textArea, className];

  if (error) {
    classList.push(styles.error);
  }

  return (
    <textarea name={name}
      className={classList.join(' ')}
      disabled={disabled}
      value={(!value && value !== 0) ? '' : value}
      placeholder={placeholder}
      style={{ maxWidth: width }}
      onKeyPress={onKeyPress}
      rows={rows || 3}
      onFocus={onFocus}
      onChange={onChange} />
  );
};

export const Radio = ({ label, onClick, disabled, value, error, tooltip, margin, badge }) => {
  let classList = [
    styles.radio,
    disabled ? styles.disabled : '',
    value ? styles.checked : '',
    disabled ? styles.disabled : '',
    !label ? styles.noText : ''
  ];

  if (error) {
    classList.push(styles.error);
  }

  return (
    <div className={classList.join(' ')} style={{ margin }} onClick={onClick}>
      <div className={styles.radioElem}></div>
      {label && <div className={styles.label}>
        {label}{badge && <span>{badge}</span>}
      </div>}
      {tooltip && <>
        <Question data-tip={tooltip} />
        <ReactTooltip place="right" effect="solid" />
      </>}
    </div>
  );
};

export const Checkbox = ({ label, onClick, disabled, value, error, tooltip }) => {
  let classList = [
    styles.checkbox,
    disabled ? styles.disabled : '',
    value ? styles.checked : '',
    !label ? styles.noText : ''
  ];

  if (error) {
    classList.push(styles.error);
  }

  return (
    <div className={classList.join(' ')} onClick={onClick}>
      <div className={styles.checkboxElem}></div>
      {label && <div className={styles.label}>{label}</div>}
      {tooltip && <>
        <Question data-tip={tooltip} />
        <ReactTooltip place="right" effect="solid" />
      </>}

    </div>
  );
};
