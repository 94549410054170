import styles from './DeleteUser.module.css';

import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  hideDeleteUsersModal
} from 'store/ducks/user.js';

import {
  getUsers, deleteUser, resetEndpoitState
} from 'store/ducks/_users.js';

import { Button, Modal, Loader } from 'ui';

const DeleteUser = () => {
  const dispatch = useDispatch();
  
  const show = useSelector(state => state.user.deleteUsersModal._show);
  const user = useSelector(state => state.user.deleteUsersModal.user);
  const loading = useSelector(state => state._users.deleteUser.loading);
  const success = useSelector(state => state._users.deleteUser.success);
  const error = useSelector(state => state._users.deleteUser.error);

  const cancel = () => {
    dispatch(resetEndpoitState('deleteUser'));
    dispatch(hideDeleteUsersModal());
  }

  const submit = () => {
    dispatch(deleteUser(user, null));
  }

  if (success) {
    dispatch(getUsers());
    cancel();
  }

  if (error) {
    cancel();
  }

  return (
    <>
      <Modal isOpen={show} onRequestClose={cancel} width={320} header="Deleting User" footer={<>
        <Button size="small" theme="white" onClick={cancel} margin="0 15px 0 0">Cancel</Button>
        <Button size="small" theme="red" onClick={submit} disabled={loading}>Yes, delete user</Button>
      </>}>
        <div className={styles.content}>
          {loading && <Loader size={30} />}

          {!loading && <p>Are you sure you want to delete this user?</p>}
        </div>
      </Modal>
    </>
  );
};

export default DeleteUser;
