import styles from './Join.module.css';

import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';

import {
  impersonateUser, getUser
} from 'store/ducks/_users.js';

import { Loader } from 'ui';

const Impersonate = ({ match, history }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(impersonateUser(match.params.id)).then(() => {
      return dispatch(getUser());
    }).then(() => {
      history.push('/');
    });
  }, []);

  return (
    <Loader size={40} />
  );
}

export default withRouter(Impersonate);
