import styles from './../Edit.module.css';
import editStyles from 'components/Builder/Edit.module.css';

import React, { Component } from 'react';
import ReactTooltip from 'react-tooltip';
import { connect } from 'react-redux';
import { v4 as uuid } from 'uuid';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import { MentionsInput, TextInput, TextArea, Toggle, Radio as RadioUI, Select } from 'ui';

import { ReactComponent as RemoveIcon } from 'assets/images/remove.svg';
import { ReactComponent as DragIcon } from 'assets/images/drag.svg';
import { ReactComponent as InfoIcon } from 'assets/images/info.svg';
import { ReactComponent as ReferToIcon } from 'assets/images/refer-to.svg';

import predefinedOptions from 'assets/predefinedOptions.json';

const predefinedOptionsList = Object.keys(predefinedOptions).map((option) => {
  return { label: option, value: option };
});

const reorder = (list, startIndex, endIndex) => {
  let result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);

  result.splice(endIndex, 0, removed);

  return result.filter((element) => typeof element !== 'undefined');
};

class Radio extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentInputLenght: 0,
      showInputLenght: false
    };
  }

  onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) return;

    const options = reorder(
      this.props.field.options,
      result.source.index,
      result.destination.index
    );

    this.props.handleChange({ options }, false);
  };

  addOption = () => {
    const options = this.props.field.options;

    options.push({
      ref: uuid(),
      value: ''
    });

    this.props.handleChange({ options }, false);
  }

  removeOption = (ref) => {
    const changes = {};
    const optionIndex = this.props.field.options.findIndex((option) => option.ref === ref);

    if (optionIndex === -1) return;

    if (this.props.field.options.length === 0 || JSON.parse(this.props.field.value).value === this.props.field.options[optionIndex].ref) {
      changes.value = JSON.stringify({ value: null, other: null });
    }

    this.props.field.options.splice(optionIndex, 1);

    changes.options = this.props.field.options;

    this.props.handleChange(changes, false);
  }

  handleOptionChange = (ref, value) => {
    const optionIndex = this.props.field.options.findIndex((option) => option.ref === ref);

    if (optionIndex === -1) return;

    const valueLength = (value || '').length;

    this.setState({
      currentInputLenght: valueLength >= this.props.field.valueMaxLength ? this.props.field.valueMaxLength : valueLength
    });

    this.props.field.options[optionIndex].value = value;

    this.props.handleChange({ options: this.props.field.options });
  }

  onValueBlur = () => {
    this.setState({
      showInputLenght: false
    });
  }

  onValueFocus = (value) => {
    this.setState({
      showInputLenght: true,
      currentInputLenght: (value || '').length
    });
  }

  clearAll = () => {
    this.props.handleChange({
      options: [{
        ref: uuid(),
        value: ''
      }]
    }, false);
  }

  populateWithPredefined = (key) => {
    this.props.handleChange({
      options: predefinedOptions[key].map((option) => {
        return {
          ref: uuid(),
          value: option
        };
      })
    }, false);
  };

  render() {
    const {
      field, fields, handleChange, mentionsData
    } = this.props;

    const {
      showInputLenght, currentInputLenght
    } = this.state;

    return (
      <>
        <div className={editStyles.row}>
          <label>
            <div>Field Title</div>
            <a href="https://support.questionscout.com/en/articles/3947231-how-to-use-refer-to" target="_blank" rel="noopener noreferrer"><ReferToIcon data-tip="Refer to is available on this input, click to learn more." /></a>
          </label>
          <MentionsInput menu="tooltip"
            initialValue={field.label}
            onChange={(value) => handleChange({ label: value })}
            data={mentionsData}
          />
        </div>

        <div className={editStyles.row}>
          <label>
            <div>Supporting Text</div>
            <a href="https://support.questionscout.com/en/articles/3947231-how-to-use-refer-to" target="_blank" rel="noopener noreferrer"><ReferToIcon data-tip="Refer to is available on this input, click to learn more." /></a>
          </label>
          <MentionsInput menu="tooltip"
            initialValue={field.description}
            onChange={(value) => handleChange({ description: value })}
            data={mentionsData}
          />
        </div>

        <div className={[editStyles.row].join(' ')}>
          <div className={editStyles.toggle50} onClick={() => handleChange({ required: !field.required }, false)}>
            <Toggle checked={field.required} readOnly={true} /> <span>Required</span>
          </div>
          <div className={editStyles.toggle50} onClick={() => handleChange({ readonly: !field.readonly }, false)}>
            <Toggle checked={field.readonly} readOnly={true} /> <span>Read-Only</span>
          </div>
          <div className={editStyles.toggle50} onClick={() => handleChange({ hidden: !field.hidden }, false)}>
            <Toggle checked={field.hidden} readOnly={true} /> <span>Hidden</span>
          </div>
          <div style={{ clear: 'both' }}></div>
        </div>

        <div className={editStyles.hr}></div>

        <div className={editStyles.row}>
          <label>
            Options
            {showInputLenght && <span>{currentInputLenght || 0}/{field.valueMaxLength}</span>}
            {!showInputLenght && <span className={styles.clearAll} onClick={() => this.clearAll()}>Clear All</span>}
          </label>

          <DragDropContext onDragEnd={this.onDragEnd}>
            <Droppable droppableId="optionsDroppableBox">
              {(provided, snapshot) => (
                <div className={styles.options} ref={provided.innerRef} {...provided.droppableProps}>
                  {field.options.map((option, index) => {
                    return <Draggable draggableId={option.ref} key={option.ref} index={index}>
                      {(provided, snapshot) => (
                        <div className={styles.option} ref={provided.innerRef} {...provided.draggableProps}>
                          <RadioUI value={(field.value || '').search(option.ref) !== -1} onClick={() => handleChange({ value: JSON.stringify({ value: option.ref, other: null }) }, false)} onChange={() => { }} />
                          <div className={styles.drag} {...provided.dragHandleProps} tabIndex="-1"><DragIcon /></div>
                          <TextInput value={option.value} width={220} onChange={(e) => this.handleOptionChange(option.ref, e.target.value)} onFocus={() => this.onValueFocus(option.value)} onBlur={() => this.onValueBlur()} />
                          <RemoveIcon className={styles.remove} onClick={() => this.removeOption(option.ref)} />
                        </div>
                      )}
                    </Draggable>;
                  })}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>

          <div className={styles.addOption} onClick={() => this.addOption()}>Add Option</div>

          <div className={editStyles.row}>
            <Select width={294} size="small" options={predefinedOptionsList} value={
              { label: 'Add from a pre-defined list', value: null }
            } onChange={(selected) => this.populateWithPredefined(selected.value)} />
            <div className={editStyles.description}><InfoIcon />Adding items from a pre-defined list replaces all of your current options.</div>
          </div>

          <div className={editStyles.hr}></div>

          <div className={editStyles.toggle100} onClick={() => handleChange({ value: (field.value !== JSON.stringify({ value: null, other: null }) && field.value !== null) ? JSON.stringify({ value: null, other: null }) : JSON.stringify({ value: (field.options.length > 0 ? field.options[0].ref : null), other: null }) }, false)}>
            <Toggle checked={field.value !== JSON.stringify({ value: null, other: null }) && field.value !== null} disabled={field.options.length === 0} readOnly={true} /> <span>Assign Automatically Selected Option</span>
          </div>

          <div className={editStyles.toggle100} onClick={() => handleChange({ otherOption: !field.otherOption }, false)}>
            <Toggle checked={field.otherOption} readOnly={true} /> <span>Add option to select “Other”</span>
          </div>

          <div className={editStyles.toggle100} onClick={() => handleChange({ randomizeOptions: !field.randomizeOptions }, false)}>
            <Toggle checked={field.randomizeOptions} readOnly={true} /> <span>Randomize Options</span>
          </div>
        </div>

        <ReactTooltip place="right" effect="solid" />
      </>
    );
  };
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Radio);
