import 'normalize.css';
import 'react-perfect-scrollbar/dist/css/styles.css';

import styles from './App.module.css';

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import { loadReCaptcha } from 'react-recaptcha-v3';
import { verifyPermissionAndRender } from 'misc/helpers.js';

import { appIsReady, ping } from 'store/ducks/main.js';

import {
  getUser
} from 'store/ducks/_users.js';

import RouterWatch from './RouterWatch';
import ChangeIndicator from './../ChangeIndicator/ChangeIndicator';

import Signup from './../User/Signup';
import Login from './../User/Login';
import Otp from './../User/Otp';
import Join from './../User/Join';
import Impersonate from './../User/Impersonate';
import ForgotPassword from './../User/ForgotPassword';
import Plan from './../User/Plan/Plan';
import LifeTime from './../User/LifeTime/LifeTime';
import LifeTimeOrder from './../User/LifeTime/LifeTimeOrder';
import LifeTimeJoin from './../User/LifeTime/LifeTimeJoin';
import EmailVerification from './../User/EmailVerification';
import Builder from './../Builder/Builder';
import FromScratch from './../CreateForm/FromScratch';
import FromTemplate from './../CreateForm/FromTemplate';
import Themes from './../Themes/Themes';
import Dashboard from './../Dashboard/Dashboard';
import OnetoolLogin from './../Onetool/Login';
import UserSettings from '../User/Settings/Settings';
import BillingUpdate from '../User/Settings/Billing/BillingUpdate/BillingUpdate';
import MolliePaymentSuccess from '../User/Mollie/MolliePaymentSuccess';

import { BasicLoader, Button } from 'ui';

import { ReactComponent as LogoSuprisedIcon } from 'assets/images/logo-suprised.svg';
import { ReactComponent as DiamondIcon } from 'assets/images/diamond.svg';

let maintenanceTimeout;

const LtdSuccess = ({ plan }) => {
  const [show, setShow] = useState(true);

  const close = () => {
    setShow(false);
    window.history.pushState({}, document.title, '/');
  };

  if (!show) return <></>;

  return <div className={styles.ltdSuccess}>
    <div className={styles.ltdSuccessModal}>
      <div className={styles.ltdSuccessModalIcon}><DiamondIcon /></div>

      <div className={styles.ltdSuccessModalTitle}>You are now a proud owner of a shiny, brand new <span>{plan}</span> plan</div>
      <div className={styles.ltdSuccessModalSubTitle}>Jokes aside — We’re very glad to have you on-board with us. As a bootstrapped company, your purchase contributes to making QuestionScout an even better platform than it is today. We’re looking forward to having you for a long time to come!</div>
      <div className={styles.ltdSuccessModalSubSubTitle}>If you ever need any help or have any questions for us, we’re here to help! Our live chat is available from 9AM until 11PM CET. After this time, you can leave a message and we’ll get back to you as soon as we can.</div>

      <Button theme="blue" fullWidth={true} onClick={close}>Get Started</Button>
    </div>
  </div>;
};

const App = () => {
  const dispatch = useDispatch();

  const urlParams = new URLSearchParams(window.location.search);

  const user = useSelector(state => state._users.user);
  const ready = useSelector(state => state.main.ready);
  const userChecked = useSelector(state => state._users.userChecked);
  const isAuthenticated = useSelector(state => state._users.isAuthenticated);
  const maintenance = useSelector(state => state.main.maintenance);

  const initIntercom = ({ _id, name, email, createdAt }) => {
    window.Intercom('boot', {
      app_id: 'l683c8wm',
      user_id: _id,
      name: name,
      email: email,
      created_at: parseInt(new Date(createdAt).getTime() / 1000)
    });
  }

  const isOnetoolLoginPage = () => {
    return window.location.pathname.indexOf('/onetool/login') === 0;
  };

  const isLoginPage = () => {
    return window.location.pathname.indexOf('/login') === 0;
  };

  const isSignup = () => {
    return window.location.pathname.indexOf('/signup') === 0;
  };

  const isJoin = () => {
    return window.location.pathname.indexOf('/join') === 0;
  };

  const isPlan = () => {
    return window.location.pathname.indexOf('/plan') === 0;
  };

  const isLife = () => {
    return window.location.pathname.indexOf('/life') === 0;
  };

  const isForgotPassword = () => {
    return window.location.pathname.indexOf('/forgotpassword') === 0;
  };

  const isEmailVerification = () => {
    return window.location.pathname.indexOf('/user/emailverification') === 0;
  };

  const isLtdSuccess = () => {
    return urlParams.get('ltdsuccess') === 'true';
  };

  const isMollieOrder = () => {
    return window.location.pathname.indexOf('/mollie/order') === 0;
  };
  
  useEffect(() => {
    loadReCaptcha('6LelHqkUAAAAAEZ8OOKqrVEZenT2Jo-mt1Dcj97r');

    dispatch(getUser()).then((res) => {
      setTimeout(() => {
        dispatch(appIsReady());

        initIntercom({
          _id: res.payload._id,
          name: res.payload.name,
          email: res.payload.email,
          createdAt: res.payload.createdAt
        });
      });
    });
  }, []);

  useEffect(() => {
    if (!maintenance) {
      if (maintenanceTimeout) {
        clearInterval(maintenanceTimeout);
        maintenanceTimeout = undefined;
        window.location.reload();
      }
    } else {
      dispatch(ping());
      maintenanceTimeout = setInterval(() => dispatch(ping()), 3000);
    }
  }, [maintenance]);

  return <Router>
    <>
      <RouterWatch />

      {ready && maintenance && <div className={styles.maintenance}>
        <LogoSuprisedIcon />
        <div className={styles.maintenanceTitle}>We’re unpacking some new features</div>
        <div className={styles.maintenanceSubTitle}>QuestionScout will be available again in just a moment.</div>
        <BasicLoader size={30} />
      </div>}

      {ready && !isAuthenticated && !maintenance && <>
        {(!isOnetoolLoginPage() && !isLoginPage() && !isSignup() && !isJoin() && !isEmailVerification() && !isForgotPassword() && !isMollieOrder()) && <Redirect to={'/login'} />}

        <Switch>
          <Route path="/login" exact component={Login} />
          <Route path="/login/2fa" exact component={Otp} />
          <Route path="/signup" exact component={Signup} />
          <Route path="/join/:token" exact component={Join} />
          <Route path="/forgotpassword/:code?" exact component={ForgotPassword} />
          <Route path="/onetool/login/:token" exact component={OnetoolLogin} />
          <Route path="/mollie/order/:id" exact component={MolliePaymentSuccess} />
          <Route path="/user/emailverification/:code" exact component={EmailVerification} />
        </Switch>
      </>}

      {ready && userChecked && isAuthenticated && !maintenance && <>
        {(isOnetoolLoginPage() || isLoginPage() || isSignup() || isJoin() || isForgotPassword()) && <Redirect to={'/'} />}
        {user && !user.pro && (!user.lifeTime || !user.lifeTime.enabled) && isSignup() && urlParams.get('life') === 'true' && <Redirect to="/life/join" />}
        {/* {user && user.lifeTime && user.lifeTime.enabled && !user.lifeTime.paid && !user.lifeTime.manual && !isLife() && <Redirect to="/life" />} */}
        {user && !user.lifeTime && user.pro && !user.customizedFree && !user.hasSubscription && user.authType !== 'onetool' && user.role === 'holder' && !isPlan() && <Redirect to="/plan" />}

        <ChangeIndicator />
        {/* {isLtdSuccess() && user && user.lifeTime && user.lifeTime.enabled && !user.lifeTime.manual  && user.lifeTime.paid && <LtdSuccess plan={user.lifeTime.plan} />} */}

        <Switch>
          <Route path="/plan" exact component={Plan} />
          <Route path="/life" exact component={LifeTime} />
          <Route path="/life/order/:id" exact component={LifeTimeOrder} />
          <Route path="/life/join" exact component={LifeTimeJoin} />
          <Route path="/user/billing/:action" exact component={verifyPermissionAndRender(BillingUpdate, 'accessBilling', user)} />
          <Route path="/user/:page" exact component={UserSettings} />
          <Route path="/impersonate/:id" exact component={Impersonate} />
          <Route path="/user/emailverification/:code" exact component={EmailVerification} />
          <Route path="/" exact component={Dashboard} />

          <Route path="/form/create/scratch/:workspace?" exact component={FromScratch} />
          <Route path="/form/create/template/:workspace?" exact component={FromTemplate} />
          <Route path="/builder/:id/:page?/:tab?/:subPage?" exact component={Builder} />
          <Route path="/themes/:type?/:id?/:page?" exact component={Themes} />
          <Route path="/onetool/login/:token" exact component={OnetoolLogin} />
          <Route path="/mollie/order/:id" exact component={MolliePaymentSuccess} />
        </Switch>
      </>}
    </>
  </Router>;
};

export default App;
