import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import QuestionscoutFormEngine from 'form';
import { getData } from 'country-list';

import {
  getFormValues, updateFormValue, updatePreviewPage
} from 'store/ducks/theme.js';

import { Loader } from 'ui';

const countries = getData().map((obj) => {
  return {
    label: obj.name.replace('Taiwan, Province of China', 'Taiwan'),
    value: obj.code
  };
});

const FormPreview = ({ themeId, themeType, device, form, placement }) => {
  const dispatch = useDispatch();

  const themes = useSelector(state => state._themes.themes);
  const loading = useSelector(state => state._themes.getThemes.loading);
  const user = useSelector(state => state._users.user);
  const values = useSelector(state => state.theme.values);
  const previewPage = useSelector(state => state.theme.previewPage);

  const [files, setFiles] = useState([]);
  const [submitted, setSubmitted] = useState(false);
  const [loadingValues, setLoadingValues] = useState(true);

  useEffect(() => {
    dispatch(getFormValues(form.fields)).then(() => setLoadingValues(false));
  }, []);

  let theme;

  // check if themes was not theme was removed
  if (Array.isArray(themes[themeType]) && themes[themeType].length !== 0 && themes[themeType].findIndex((t) => t._id === themeId) !== -1) {
    theme = (themes[themeType] || []).find((t) => t._id === themeId);
  } else {
    theme = (themes.stock || []).find((t) => t._id === 'default-light');
  }

  theme = theme ? JSON.parse(JSON.stringify(theme)) : { settings: {}, colors: [], images: {} };

  const themeSettings = { ...(theme || { settings: [] }).settings };
  const colors = (theme || { colors: [] }).colors;
  const images = (theme || { images: {} }).images;
  const selectedForm = { ...form };

  selectedForm.page = previewPage;

  if (!selectedForm.owner) {
    selectedForm.owner = {
      _id: null,
      hideBranding: user.hideBranding,
      pro: user.pro,
      country: user.country
    };
  }

  if (!selectedForm.owner.country) selectedForm.owner.country = user.country;

  if (selectedForm.type === 'conversational') {
    selectedForm.fields = selectedForm.fields.filter((field) => ['pageBreak', 'divider'].indexOf(field.type) === -1);
  }

  const deleteFile = ({ ref, fileId }) => {
    const filesCopy = [...files];
    const index = files.findIndex((f) => {
      if (ref) return f.ref === ref;
      if (fileId) return f._id === fileId;

      return false;
    });

    if (index !== -1) {
      filesCopy.splice(index, 1);

      setFiles(filesCopy);
    }
  };

  const handleSubmit = () => {
    setSubmitted(true);
  };

  const handleChange = (valueObject) => {
    dispatch(updateFormValue(valueObject));
  };

  const handleNextPage = () => {
    dispatch(updatePreviewPage(previewPage + 1));
  };

  const handlePreviousPage = () => {
    dispatch(updatePreviewPage(previewPage - 1));
  };

  const handleCancel = (ref) => {
    deleteFile({ ref });
  };

  const handleDelete = (fileId, fieldId) => {
    deleteFile({ fileId });
  };

  const handleUpload = (newFiles, fieldId) => {
    const filesToAdd = [];

    for (let f of newFiles) {
      filesToAdd.push({
        _id: Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 5),
        ref: Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 5),
        size: f.size,
        loaded: 0,
        name: f.name,
        type: f.type,
        field: fieldId
      })
    }

    setFiles([...files, ...filesToAdd]);
  };

  // fill colors
  Object.keys(themeSettings).map((key) => {
    if (colors === null || typeof themeSettings[key] !== 'string') return key;

    const colorId = themeSettings[key].replace('$color:', '');
    const colorObject = colors.find((c) => c._id === colorId);

    if (colorObject) {
      themeSettings[key] = colorObject.value;
    }

    return key;
  });

  // fill images
  Object.keys(themeSettings).map((key) => {
    if (images === null || typeof themeSettings[key] !== 'string') return key;

    const imageId = themeSettings[key].replace('$image:', '');
    const imageObject = images[imageId];

    if (imageObject) {
      themeSettings[key] = images[imageId].url;
    }

    return key;
  });

  return <>
    {(loading || loadingValues) && <Loader size={50} />}
    {!loading && !loadingValues && <QuestionscoutFormEngine
      form={selectedForm}
      theme={themeSettings}
      seed={1024}
      mode={'preview'}
      placement={placement}
      status={'live'}
      submitted={submitted}
      device={device}
      files={files}
      values={values}
      inIframe={false}
      backupExist={false}
      country={{
        code: user.country || null,
        name: (countries.find((c) => c.value === user.country) || { label: null }).label
      }}
      submitIsDisabled={() => false}
      userUiSettings={user.uiSettings}
      showProgressBar={selectedForm.progressBar || false}
      handleSubmit={handleSubmit}
      onChange={handleChange}
      handleUpload={handleUpload}
      thankYouPages={selectedForm.thankYouPages || []}
      welcomePage={selectedForm.welcomePage || {}}
      showWelcomePage={false}
      handleWelcomePageClick={() => { }}
      defaultThankYouPage={selectedForm.defaultThankYouPage || {
        conditionsType: 'all',
        content: 'Your form is successfully submitted.'
      }}
      updateFieldHighlighted={() => { }}
      handleDelete={handleDelete}
      handleCancel={handleCancel}
      handleSubmitAgain={() => { }}
      handleNextPage={handleNextPage}
      handlePreviousPage={handlePreviousPage}
      handleHighlightField={() => { }}
      handlePartialResponses={() => { }}
      handleRestoreBackup={() => { }}
      handleClearBackup={() => { }}
      variables={[]}
    />}
  </>;
};

export default FormPreview;
