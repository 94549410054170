import styles from './Users.module.css';

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  showAddEditUsersModal, showDeleteUsersModal
} from 'store/ducks/user.js';

import {
  getUsers
} from 'store/ducks/_users.js';

import AddEditUsersModal from './modals/AddEditUsers.js';
import DeleteUsersModal from './modals/DeleteUser.js';

import { ReactComponent as RemoveIcon } from 'assets/images/trash.svg';

import { Card, Button, Loader } from 'ui';

const roles = {
  holder: 'Account Holder',
  admin: 'Administrator',
  mod: 'Moderator',
  user: 'User'
};

const Users = () => {
  const dispatch = useDispatch();
  
  const user = useSelector(state => state._users.user);
  const users = useSelector(state => state._users.users);
  const loading = useSelector(state => state._users.getUsers.loading);

  useEffect(() => {
    dispatch(getUsers());
  }, []);

  if (user.authType === 'onetool') return <></>;

  return (
    <>
      <AddEditUsersModal />
      <DeleteUsersModal />

      <Card className={styles.card}>
        <div className={styles.head}>
          <div className={styles.left}>
            <div className={styles.title}>Users</div>
            <Button theme="blue" onClick={() => dispatch(showAddEditUsersModal())}>Add new user</Button>
          </div>

          <div className={styles.right}>
            <div className={styles.seats}>
              {user.planUsage.users} seat{user.planUsage.users > 1 && 's'} occupied<span />{user.plan.users - user.planUsage.users} seat{(user.plan.users - user.planUsage.users) > 1 && 's'} available
            </div>
          </div>
        </div>

        {loading && <div className={styles.loading}>
          <Loader size={30} />
        </div>}

        {!loading && <ul className={styles.users}>
          <li className={styles.usersHead}>
            <div>Name</div>
            <div>Status</div>
            <div>Role</div>
            <div>Actions</div>
          </li>

          {users && users.map((u) => <li className={styles.usersBody} key={u._id}>
            <div>
              <p>{u.name}</p>
              <span>{u.email}</span>
            </div>
            <div><span className={u.active ? styles.active : ''} />{u.active ? 'Active' : 'Pending'}</div>
            <div>{roles[u.role]}</div>
            <div className={styles.actions}>
              {u.role !== 'holder' && u._id !== user._id && <>
                <span onClick={() => dispatch(showAddEditUsersModal(u._id))}>Edit</span>
                <div onClick={() => dispatch(showDeleteUsersModal(u._id))}><RemoveIcon /></div>
              </>}
            </div>
          </li>)}
        </ul>}
      </Card>
    </>
  );
};

export default Users;
