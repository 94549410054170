import styles from './Theme.module.css';

import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Route } from 'react-router-dom';
import WebFont from 'webfontloader';

import ThemeHeader from './ThemeHeader.js';
import ThemeMenu from './ThemeMenu.js';
import ThemeContent from './ThemeContent.js';

import CreateThemeModal from './../modals/CreateTheme.js';

const Theme = ({ match }) => {
  const fonts = useSelector((state) => state.theme.fonts);

  useEffect(() => {
    WebFont.load({
      google: {
        families: fonts.map((font) => `${font.value}:400,600`)
      }
    });
  }, []);

  return (
    <>
      <CreateThemeModal />

      <div className={styles.main} >
        <Route path="/themes/:type?/:id?/:page?" component={ThemeHeader} />

        <div className={styles.content}>
          <Route path="/themes/:type?/:id?/:page?" component={ThemeMenu} />
          <Route path="/themes/:type?/:id?/:page?" component={ThemeContent} />
        </div>
      </div>
    </>
  );
}

export default Theme;
