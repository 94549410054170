/** @jsx jsx */

import { jsx, css } from '@emotion/core';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import ReactFullpage from '@fullpage/react-fullpage';
import checkRequired from 'helpers/checkRequired.js';
import isRequired from 'helpers/isRequired.js';
import getRootSectionValueObj from 'helpers/getRootSectionValueObj.js';
import config from '../config.js';

import Submit from './fields/Submit.js';

import Background from './components/Background.js';
import Backup from './components/Backup.js';
import Paused from './components/Paused.js';
import Footer from './components/Footer.js';
import Submitted from './components/Submitted.js';
import RequiredHelper from './components/RequiredHelper.js';
import FormWrapper from './components/FormWrapper.js';
import WelcomePage from './components/WelcomePage.js';
import ProgressBar from './components/ProgressBar.js';

const keyCodeToAlphabetIndex = [65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90];

const alignOptions = {
  left: 'flex-start',
  center: 'center',
  right: 'flex-end'
};

let nextClickDisabled = false;

const getScrollbarWidth = () => {
  const div = document.createElement('div');

  document.body.appendChild(div);

  div.style.width = '100px';
  div.style.height = '100px';
  div.style.overflow = 'scroll';
  div.style.opacity = '0';
  div.style.position = 'absolute';
  div.style.top = '-9999px';

  setTimeout(() => document.body.removeChild(div), 1000);

  return div.offsetWidth - div.clientWidth;
};

const isTouchDevice = typeof window !== 'undefined' && ((('ontouchstart' in (window)) || (navigator.MaxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0)));

const getFormPadding = (form, theme, mode, device, withBottomBar) => {
  const bottomExtraMargin = withBottomBar ? 50 : 0;

  const options = {
    desktop: {
      top: `${form.type === 'conversational' ? 0 : (theme.displaySettingsFormDisplayDesktopVerticalMargin || 0)}px`,
      right: `${theme.displaySettingsFormDisplayDesktopHorizontalMargin || 0}px`,
      bottom: `${form.type === 'conversational' ? 50 : (theme.displaySettingsFormDisplayBoxMinimumHeight !== 'window' ? (theme.displaySettingsFormDisplayDesktopVerticalMargin || 0) + bottomExtraMargin : (theme.displaySettingsFormDisplayDesktopVerticalMargin || 0))}px`,
      left: `${theme.displaySettingsFormDisplayDesktopHorizontalMargin || 0}px`
    },
    tablet: {
      top: `${form.type === 'conversational' ? 0 : (theme.displaySettingsFormDisplayTabletVerticalMargin || 0)}px`,
      right: `${theme.displaySettingsFormDisplayTabletHorizontalMargin || 0}px`,
      bottom: `${form.type === 'conversational' ? 50 : (theme.displaySettingsFormDisplayBoxMinimumHeight !== 'window' ? (theme.displaySettingsFormDisplayTabletVerticalMargin || 0) + bottomExtraMargin : (theme.displaySettingsFormDisplayTabletVerticalMargin || 0))}px`,
      left: `${theme.displaySettingsFormDisplayTabletHorizontalMargin || 0}px`
    },
    phone: {
      top: `${form.type === 'conversational' ? 0 : (theme.displaySettingsFormDisplayMobileVerticalMargin || 0)}px `,
      right: `${theme.displaySettingsFormDisplayMobileHorizontalMargin || 0}px`,
      bottom: `${form.type === 'conversational' ? 50 : (theme.displaySettingsFormDisplayBoxMinimumHeight !== 'window' ? (theme.displaySettingsFormDisplayMobileVerticalMargin || 0) + bottomExtraMargin : (theme.displaySettingsFormDisplayMobileVerticalMargin || 0))}px`,
      left: `${theme.displaySettingsFormDisplayMobileHorizontalMargin || 0}px`
    }
  };

  if (mode === 'preview') return options[device];

  if (window.screen.width <= 700) {
    return options.phone;
  } else if (isTouchDevice) {
    return options.tablet;
  } else if (!isTouchDevice) {
    return options.desktop;
  }
};

const getScaleArray = (range) => {
  const arr = [];

  for (let i = range[0]; i <= range[1]; i++) {
    arr.push(i);
  }

  return arr;
};

let fullpage;

const QuestionscoutFormEngine = ({
  form, theme, values, inIframe, seed, files, device, mode, status, submitted, onChange, placement,
  waitingForSubmissionId,
  handleUpload, handleDelete, handleCancel,
  submitIsDisabled, handleHighlightField,
  handleNextPage, handlePreviousPage, handleSubmit, handleSubmitAgain,
  updateFieldHighlighted,
  userUiSettings,
  handlePartialResponses,
  backupExist, handleRestoreBackup, handleClearBackup,
  thankYouPages, defaultThankYouPage,
  welcomePage, showWelcomePage, handleWelcomePageClick,
  showProgressBar, country, variables
}) => {
  const [scrollbarWidth, setScrollbarWidth] = useState(null);
  const [fullpageReady, setFullpageReady] = useState(false);

  const [allowGoUp, setAllowGoUp] = useState(false);
  const [allowGoDown, setAllowGoDown] = useState(false);

  const [activeFieldIndex, setActiveFieldIndex] = useState(0);

  const scrollToField = useSelector(state => state.form ? state.form.scrollToField : null);

  if (scrollbarWidth === null) {
    setScrollbarWidth(getScrollbarWidth());
  }

  const previewWidth = {
    desktop: `${theme.displaySettingsFormDisplayDesktopMaxWidth}${theme.displaySettingsFormDisplayDesktopMaxWidthType}`,
    tablet: `${theme.displaySettingsFormDisplayTabletMaxWidth}${theme.displaySettingsFormDisplayTabletMaxWidthType}`,
    phone: `${theme.displaySettingsFormDisplayMobileMaxWidth}${theme.displaySettingsFormDisplayMobileMaxWidthType}`
  };

  const styles = {
    container: css({
      position: 'relative'
    }),
    main: css({
      width: '100%',
      minHeight: theme.displaySettingsFormDisplayBoxMinimumHeight === 'window' ? '100vh' : 'auto',
      padding: (() => {
        const withBottomBar = (!form.owner.pro) || (theme.backgroundImage && theme.backgroundUseImage && theme.backgroundImageSource === 'unsplash') || (!(form.owner ? form.owner.hideBranding : false)) || form.type === 'conversational';
        const { top, right, bottom, left } = getFormPadding(form, theme, mode, device, withBottomBar);

        return `${top} ${right} ${bottom} ${left}`;
      })(),
      display: 'flex',
      boxSizing: 'border-box',
      flexDirection: 'row',
      justifyContent: alignOptions[theme.displaySettingsFormDisplayAlignment],
      zIndex: 2,
      position: 'relative',
      '& *': {
        WebkitTapHighlightColor: 'rgba(0,0,0,0)',
        WebkitTapHighlightColor: 'transparent'
      }
    }),
    content: mode === 'preview' ? css({
      boxSizing: 'border-box',
      width: previewWidth[device],
      height: theme.displaySettingsFormDisplayBoxMinimumHeight === 'window' ? '100%' : 'auto',
      minHeight: theme.displaySettingsFormDisplayBoxMinimumHeight === 'window' ? '100vh' : 'auto',
    }) : css({
      boxSizing: 'border-box',
      height: theme.displaySettingsFormDisplayBoxMinimumHeight === 'window' ? '100%' : 'auto',
      width: `${theme.displaySettingsFormDisplayDesktopMaxWidth}${theme.displaySettingsFormDisplayDesktopMaxWidthType}`,
      '@media (max-width: 800px)': {
        width: `${theme.displaySettingsFormDisplayTabletMaxWidth}${theme.displaySettingsFormDisplayTabletMaxWidthType}`
      },
      '@media (max-width: 600px)': {
        width: `${theme.displaySettingsFormDisplayMobileMaxWidth}${theme.displaySettingsFormDisplayMobileMaxWidthType}`
      }
    }),
    form: css({
      height: theme.displaySettingsFormDisplayBoxMinimumHeight === 'window' ? '100%' : 'auto',
      minHeight: theme.displaySettingsFormDisplayBoxMinimumHeight === 'window' ? '100vh' : 'auto',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      boxSizing: 'border-box',
      position: 'relative',
      zIndex: 2,
      padding: (() => {
        if (form.type === 'conversational') {
          return 0;
        } else {
          return theme.displaySettingsFormDisplayBox ? `${theme.displaySettingsFormDisplayBoxVerticalPadding || 0}px ${theme.displaySettingsFormDisplayBoxHorizontalPadding || 0}px` : 0;
        }
      })(),
      paddingBottom: form.type === 'conversational' ? 0 : (theme.displaySettingsFormDisplayBoxMinimumHeight === 'window' ? (theme.displaySettingsFormDisplayBoxVerticalPadding || 0) + 50 : (theme.displaySettingsFormDisplayBoxVerticalPadding || 0)),
      borderRadius: form.type === 'conversational' ? 0 : (theme.displaySettingsFormDisplayBox ? `${theme.displaySettingsFormDisplayBoxRoundness || 0}${theme.displaySettingsFormDisplayBoxRoundnessType}` : 0),
      background: form.type === 'conversational' ? 'none' : (theme.displaySettingsFormDisplayBox ? theme.displaySettingsFormDisplayBoxBackground : 'transparent'),
      boxShadow: form.type === 'conversational' ? 'none' : (theme.displaySettingsFormDisplayBox && theme.displaySettingsFormDisplayBoxShadow ? `${theme.displaySettingsFormDisplayBoxShadowOffsetX}px ${theme.displaySettingsFormDisplayBoxShadowOffsetY}px ${theme.displaySettingsFormDisplayBoxShadowBlur}px ${theme.displaySettingsFormDisplayBoxShadowSpread}px ${theme.displaySettingsFormDisplayBoxShadowColor}` : 'none'),
    }),
    // conversational
    conversationalFieldMain: css({
      display: 'flex',
      opacity: fullpageReady ? 1 : 0,
      boxSizing: 'border-box',
      flexDirection: 'row',
      justifyContent: alignOptions[theme.displaySettingsFormDisplayAlignment],
      zIndex: 2,
      position: 'relative',
    }),
    conversationalFieldBox: css({
      padding: `${(theme.displaySettingsFormDisplayBoxVerticalPadding || 70)}px ${theme.displaySettingsFormDisplayBoxHorizontalPadding || 0}px`,
      paddingBottom: theme.displaySettingsFormDisplayBoxVerticalPadding < 100 ? 100 : `${theme.displaySettingsFormDisplayBoxVerticalPadding}px`,
      height: '100%',
      width: `calc(100% - ${(theme.displaySettingsFormDisplayMobileHorizontalMargin || 0) * 2}px)`,
      width: `${theme.displaySettingsFormDisplayDesktopMaxWidth}${theme.displaySettingsFormDisplayDesktopMaxWidthType}`,
      '@media (max-width: 800px)': {
        width: `${theme.displaySettingsFormDisplayTabletMaxWidth}${theme.displaySettingsFormDisplayTabletMaxWidthType}`
      },
      '@media (max-width: 600px)': {
        width: `${theme.displaySettingsFormDisplayMobileMaxWidth}${theme.displaySettingsFormDisplayMobileMaxWidthType}`
      },
      position: 'fixed',
      zIndex: -1,
      top: 0,
      right: (() => {
        if (theme.displaySettingsFormDisplayAlignment === 'right') {
          return 0;
        }

        if (theme.displaySettingsFormDisplayAlignment === 'left') {
          return 'auto';
        }

        if (theme.displaySettingsFormDisplayAlignment === 'center') {
          return '50%';
        }
      })(),
      bottom: 0,
      transform: theme.displaySettingsFormDisplayAlignment === 'center' ? 'translate(-50%, 0)' : 'none',
      left: (() => {
        if (theme.displaySettingsFormDisplayAlignment === 'left') {
          return 0;
        }

        if (theme.displaySettingsFormDisplayAlignment === 'right') {
          return 'auto';
        }

        if (theme.displaySettingsFormDisplayAlignment === 'center') {
          return '50%';
        }
      })(),
      background: theme.displaySettingsFormDisplayBoxBackground,
      boxShadow: theme.displaySettingsFormDisplayBoxShadow ? `${theme.displaySettingsFormDisplayBoxShadowOffsetX}px ${theme.displaySettingsFormDisplayBoxShadowOffsetY}px ${theme.displaySettingsFormDisplayBoxShadowBlur}px ${theme.displaySettingsFormDisplayBoxShadowSpread}px ${theme.displaySettingsFormDisplayBoxShadowColor}` : 'none'
    }),
    conversationalFieldContent: css({
      margin: (() => {
        const options = {
          desktop: `70px ${theme.displaySettingsFormDisplayDesktopHorizontalMargin || 50}px 70px ${theme.displaySettingsFormDisplayDesktopHorizontalMargin || 50}px`,
          tablet: `70px ${theme.displaySettingsFormDisplayTabletHorizontalMargin || 30}px 70px ${theme.displaySettingsFormDisplayTabletHorizontalMargin || 30}px`,
          phone: `70px ${theme.displaySettingsFormDisplayMobileHorizontalMargin || 20}px 70px ${theme.displaySettingsFormDisplayMobileHorizontalMargin || 20}px`
        };

        if (theme.displaySettingsFormDisplayBox) {
          return `${(theme.displaySettingsFormDisplayBoxVerticalPadding || 50)}px ${theme.displaySettingsFormDisplayBoxHorizontalPadding || 0}px`;
        } else {
          if (window.screen.width <= 700) {
            return options.phone;
          } else if (isTouchDevice) {
            return options.tablet;
          } else if (!isTouchDevice) {
            return options.desktop;
          }
        }
      })(),
      marginBottom: theme.displaySettingsFormDisplayBoxVerticalPadding < 100 ? 100 : `${theme.displaySettingsFormDisplayBoxVerticalPadding}px`,
      transition: 'opacity 0.4s ease-in-out',
      boxSizing: 'border-box',
      position: 'relative',
      height: '100%',
      width: `calc(100% - ${(theme.displaySettingsFormDisplayMobileHorizontalMargin || 0) * 2}px)`,
      width: `${theme.displaySettingsFormDisplayDesktopMaxWidth}${theme.displaySettingsFormDisplayDesktopMaxWidthType}`,
      '@media (max-width: 800px)': {
        width: `${theme.displaySettingsFormDisplayTabletMaxWidth}${theme.displaySettingsFormDisplayTabletMaxWidthType}`
      },
      '@media (max-width: 600px)': {
        width: `${theme.displaySettingsFormDisplayMobileMaxWidth}${theme.displaySettingsFormDisplayMobileMaxWidthType}`
      }
    })
  };

  const scrollIfNeeded = (activeField) => {
    if (form.type === 'classic') return;

    const activeValueObj = getRootSectionValueObj(activeField._id, values);
    let activeSection = null;

    if (activeValueObj.type === 'section') activeSection = form.fields.find((f) => f._id === activeValueObj._id);

    const move = () => setTimeout(() => {
      if (fullpage) fullpage.moveSectionDown();
    }, 300);

    if (!activeField) return;

    if (isRequired(activeField._id, form.fields) && !checkRequired(form.page, [(activeSection || activeField)], { [(activeSection || activeField)._id]: values[(activeSection || activeField)._id] }, {
      ignorePages: form.type === 'conversational'
    }, {
      fields: form.fields,
      values: values
    }).valid) return;

    if (['title', 'image', 'title', 'checkbox', 'imageChoice', 'description', 'fileUpload', 'signature', 'datetime', 'longText', 'shortText', 'radio', 'dropdown', 'scale', 'section'].indexOf(activeField.type) !== -1) {
      move();
    }
  };

  const handleKeydown = (e) => {
    if (!form.type === 'classic' || !fullpage || submitted) return;

    let data, ref, index;
    const activeSection = fullpage.getActiveSection();
    const activeField = form.fields.find((f) => f._id === activeSection.item.id);

    if (!activeField) return;

    if (e.keyCode === 13 && getRootSectionValueObj(activeField._id, values).isLast && !submitIsDisabled()) {
      handleSubmit();

      return;
    }

    if (['title', 'image', 'title', 'datetime', 'description', 'shortText', 'fileUpload', 'signature', 'dropdown', 'checkbox', 'radio', 'imageChoice', 'scale', 'section'].indexOf(activeField.type) !== -1 && e.keyCode === 13) {
      e.preventDefault();
      e.target && e.target.blur();
      scrollIfNeeded(activeField);

      return;
    }

    if (activeField.type === 'longText' && e.keyCode === 13 && !e.shiftKey && !isTouchDevice) {
      e.preventDefault();
      e.target && e.target.blur();
      scrollIfNeeded(activeField);

      return true;
    }

    if (activeField.type === 'checkbox' && keyCodeToAlphabetIndex.indexOf(e.keyCode) !== -1 && document.activeElement.tagName !== 'INPUT') {
      data = (!values[activeField._id] || values[activeField._id].value == null) ? { values: [], other: '' } : JSON.parse(values[activeField._id].value);
      index = keyCodeToAlphabetIndex.indexOf(e.keyCode);

      if (index >= activeField.options.length) return;

      ref = activeField.options[index].ref;

      if (data.values.indexOf(ref) !== -1) {
        data.values.splice(data.values.indexOf(ref), 1);
      } else {
        data.values.push(ref);
      }

      if (activeField.selectionLimits) {
        if (data.values.length === activeField.selectionLimitsMax) scrollIfNeeded(activeField);
        if (data.values.length > activeField.selectionLimitsMax) return;
      }

      onChange({ [activeField._id]: JSON.stringify(data) });
    }

    if (activeField.type === 'imageChoice' && keyCodeToAlphabetIndex.indexOf(e.keyCode) !== -1 && document.activeElement.tagName !== 'INPUT') {
      data = (!values[activeField._id] || values[activeField._id].value == null) ? { values: [] } : JSON.parse(values[activeField._id].value);
      index = keyCodeToAlphabetIndex.indexOf(e.keyCode);

      if (index >= activeField.options.length) return;

      ref = activeField.options[index].ref;

      if (data.values.indexOf(ref) !== -1) {
        data.values.splice(data.values.indexOf(ref), 1);
      } else {
        data.values.push(ref);
      }

      if (activeField.selectionLimits) {
        if (data.values.length === activeField.selectionLimitsMax) scrollIfNeeded(activeField);
        if (data.values.length > activeField.selectionLimitsMax) return;
      }

      onChange({ [activeField._id]: JSON.stringify(data) });
    }

    if (activeField.type === 'radio' && keyCodeToAlphabetIndex.indexOf(e.keyCode) !== -1 && document.activeElement.tagName !== 'INPUT') {
      data = (!values[activeField._id] || values[activeField._id].value == null) ? { value: null, other: null } : JSON.parse(values[activeField._id].value);
      index = keyCodeToAlphabetIndex.indexOf(e.keyCode);

      if (index >= activeField.options.length) return;

      data.value = activeField.options[index].ref;
      data.other = null;

      onChange({ [activeField._id]: JSON.stringify(data) });
      scrollIfNeeded(activeField);
    }

    if (activeField.type === 'scale') {
      if (e.keyCode === 39 && (activeField.highlighted || 0) < (activeField.scaleRange[1] - activeField.scaleRange[0])) {
        e.preventDefault();
        updateFieldHighlighted(activeField._id, typeof activeField.highlighted === 'undefined' ? 0 : activeField.highlighted + 1);
      }

      if (e.keyCode === 37 && (activeField.highlighted > 0 || typeof activeField.highlighted === 'undefined')) {
        e.preventDefault();
        updateFieldHighlighted(activeField._id, typeof activeField.highlighted === 'undefined' ? (activeField.scaleRange[1] - activeField.scaleRange[0]) : activeField.highlighted - 1);
      }

      onChange({ [activeField._id]: getScaleArray(activeField.scaleRange)[activeField.highlighted] });
    }
  };

  const handleChange = (obj, isValid) => {
    onChange(obj, isValid);

    const activeField = form.fields.find((f) => f._id === Object.keys(obj)[0]);
    const activeValueObj = getRootSectionValueObj(activeField._id, values);
    let activeSection = null;

    if (activeValueObj.type === 'section') activeSection = form.fields.find((f) => f._id === activeValueObj._id);

    if (activeField && fullpage && ['radio', 'checkbox', 'imageChoice'].indexOf(activeField.type) !== -1) {
      fullpage.reBuild();
    }

    const valid = isRequired(activeField._id, form.fields) && !checkRequired(form.page, [(activeSection || activeField)], { [(activeSection || activeField)._id]: values[(activeSection || activeField)._id] }, {
      ignorePages: form.type === 'conversational'
    }, {
      fields: form.fields,
      values: values
    }).valid ? false : true;

    if (getRootSectionValueObj(activeField._id, values).isLast) {
      setAllowGoDown(false);
    } else if (valid) {
      setAllowGoDown(valid);
    }
  };

  const handleOnNextClick = (field, e) => {
    if (nextClickDisabled) return;

    nextClickDisabled = true;
    setTimeout(() => nextClickDisabled = false, 500);

    scrollIfNeeded(field);
  };

  const handleOnPrevClick = () => {
    if (fullpage) fullpage.moveSectionUp();
  };

  const handleGoDown = () => {
    const activeSection = fullpage.getActiveSection();
    const activeField = form.fields.find((f) => f._id === activeSection.item.id);

    scrollIfNeeded(activeField);
  };

  const handleGoUp = () => {
    setTimeout(() => {
      if (fullpage) fullpage.moveSectionUp();
    }, 300);
  };

  const getNearestVisibleSectionId = (destination, direction) => {
    const step = direction === 'up' ? -1 : 1;
    const currentField = form.fields.find((field) => field._id === destination.anchor);

    let nextField;

    let firstFieldId = null;
    let lastFieldId = null;

    for (const [key] of Object.entries(values)) {
      if (values[key].isLast) lastFieldId = key;
      if (values[key].isFirst) firstFieldId = key;
    }

    if (!currentField && direction === 'down') return lastFieldId;
    if (!currentField && direction === 'up') return firstFieldId;

    nextField = form.fields.find((field) => field.position === currentField.position + step);
    if (!nextField) nextField = form.fields.find((field) => field.position === currentField.position + step + step);
    if (!nextField) nextField = form.fields.find((field) => field.position === currentField.position + step + step + step);

    if (!nextField && direction === 'down') {
      return lastFieldId;
    } else if (nextField) {
      return nextField._id;
    }
  };

  const isVisible = (destination) => {
    return destination.getAttribute('data-hidden') === 'false';
  };

  useEffect(() => {
    if (form.type === 'conversational') {
      let interval;
      let loops = 0;
      let goDownAllowed;

      const firstField = form.fields.filter((f) => f.section === 'root' || !f.section)[0];
      const activeValueObj = getRootSectionValueObj(firstField._id, values);
      let activeSection = null;

      if (activeValueObj.type === 'section') activeSection = form.fields.find((f) => f._id === activeValueObj._id);

      interval = setInterval(() => {
        loops += 1;

        if (loops >= 500) clearInterval(interval);
        if (!fullpage) return;

        if (firstField) {
          goDownAllowed = isRequired(firstField._id, form.fields) && !checkRequired(form.page, [(activeSection || firstField)], { [(activeSection || firstField)._id]: values[(activeSection || firstField)._id] }, {
            ignorePages: form.type === 'conversational'
          }, {
            fields: form.fields,
            values: values
          }).valid ? false : true;

          setAllowGoUp(false);
          if (form.fields.length > 1 && goDownAllowed) setAllowGoDown(true);
          fullpage.setKeyboardScrolling(goDownAllowed, 'down');
        }

        clearInterval(interval);
      }, loops === 0 ? 0 : 5);

      document.addEventListener('keyup', handleKeydown, false);

      return () => document.removeEventListener('keyup', handleKeydown, false);
    }
  }, []);

  useEffect(() => {
    if (scrollToField === null) return;

    try {
      const { _id } = getRootSectionValueObj(scrollToField, values);

      if (fullpage) fullpage.moveTo(_id);
    } catch (e) { }
  }, [scrollToField]);

  let fieldsOptions = {};

  for (let f of form.fields) fieldsOptions[f._id] = f.options || [];

  return (
    <div css={styles.container}>
      <Background theme={theme} mode={mode} device={device} placement={placement} isTouchDevice={isTouchDevice} scrollbarWidth={form.type === 'conversational' ? 0 : scrollbarWidth} userUiSettings={userUiSettings || {}} />

      {showWelcomePage && status !== 'paused' && !submitted && !backupExist && <WelcomePage {...welcomePage}
        onClick={handleWelcomePageClick}
        form={form}
        theme={theme}
        formType={form.type}
        inIframe={inIframe}
        values={values}
        fieldsOptions={fieldsOptions}
        variables={variables}
        mainStyles={styles} />}

      {form.type === 'conversational' && !showWelcomePage && !submitted && !backupExist && status !== 'paused' && <div css={styles.form}>
        {!fullpageReady && <div className="spinnerServer" />}

        <ReactFullpage
          licenseKey={config.fullpageLicense}
          scrollingSpeed={1000}
          controlArrows={false}
          bigSectionsDestination={'top'}
          touchSensitivity={isTouchDevice ? 30 : 15}
          fitToSection={true}
          dragAndMove="fingersonly"
          lockAnchors={true}
          scrollOverflow={true}
          scrollOverflowOptions={{
            bounce: false,
            click: false,
            tap: false
          }}
          paddingTop={0}
          paddingBottom={0}
          onLeave={(origin, destination, direction) => {
            if ('activeElement' in document) document.activeElement.blur();

            const nearestVisibleSectionId = getNearestVisibleSectionId(destination, direction);
            const originContent = origin.item.querySelector('.sectionContent');
            const originInput = origin.item.querySelector('input, textarea');

            if (nearestVisibleSectionId === origin.anchor) return false; // stay here if no need to move

            setActiveFieldIndex(destination.index);

            if (originContent) originContent.style.opacity = 0;
            if (originInput) originInput.blur();

            ((item) => {
              setTimeout(() => {
                if (item) item.style.opacity = 1;
              }, 1200);
            })(originContent);

            if (!isVisible(destination.item) && nearestVisibleSectionId) {
              if (fullpage) fullpage.moveTo(nearestVisibleSectionId);

              return false;
            }
          }}
          afterLoad={(origin, destination, direction) => {
            const destinationInput = destination.item.querySelector('input, textarea');
            const originInput = origin.item.querySelector('input, textarea');
            const activeField = form.fields.find((f) => f._id === destination.anchor);

            if (!activeField) return;

            const activeValueObj = getRootSectionValueObj(activeField._id, values);
            let activeSection = null;

            if (activeValueObj.type === 'section') activeSection = form.fields.find((f) => f._id === activeValueObj._id);

            let fullpageInterval;
            let fullpageLoops = 0;

            if (activeField && fullpage && ['radio', 'checkbox', 'imageChoice'].indexOf(activeField.type) !== -1) {
              fullpage.reBuild();
            }

            if (originInput) originInput.blur();
            if (destinationInput && !isTouchDevice && activeField.type !== 'section') destinationInput.focus();

            fullpageInterval = setInterval(() => {
              fullpageLoops += 1;

              if (fullpageLoops >= 200) clearInterval(fullpageInterval);
              if (!fullpage) return;
              clearInterval(fullpageInterval);
              if (!activeField) {
                setAllowGoDown(false);
                return;
              };

              const allowScroll = getRootSectionValueObj(destination.anchor, values).isLast ? false : (isRequired(activeField._id, form.fields) && !checkRequired(form.page, [(activeSection || activeField)], { [(activeSection || activeField)._id]: values[(activeSection || activeField)._id] }, {
                ignorePages: form.type === 'conversational'
              }, {
                fields: form.fields,
                values: values
              }).valid ? false : true);

              setAllowGoDown(allowScroll);
              setAllowGoUp(!getRootSectionValueObj(destination.anchor, values).isFirst);

              fullpage.setKeyboardScrolling(allowScroll, allowScroll ? 'down, up' : 'down');
            }, fullpageLoops === 0 ? 0 : 10);
          }}
          render={({ fullpageApi }) => {
            fullpage = fullpageApi;

            if (fullpage && !fullpageReady) {
              fullpage.setAllowScrolling(false, 'down, up');
              setTimeout(() => setFullpageReady(true));
            }

            return (
              <ReactFullpage.Wrapper>
                <FormWrapper
                  form={form}
                  fields={form.fields.filter((field) => field.section === 'root' || !field.section)}
                  values={values}
                  styles={styles}
                  theme={theme}
                  device={device}
                  isTouchDevice={isTouchDevice}
                  mode={mode}
                  seed={seed}
                  variables={variables}
                  country={country}
                  fieldsOptions={fieldsOptions}
                  submitIsDisabled={submitIsDisabled}
                  handleSubmit={handleSubmit}
                  handleChange={handleChange}
                  waitingForSubmissionId={waitingForSubmissionId}
                  handlePartialResponses={handlePartialResponses}
                  handleOnNextClick={handleOnNextClick}
                  handleOnPrevClick={handleOnPrevClick}
                  handleNextPage={handleNextPage}
                  handlePreviousPage={handlePreviousPage}
                  handleUpload={handleUpload}
                  handleDelete={handleDelete}
                  handleCancel={handleCancel}
                  files={files}
                />

                <div data-anchor="submit" className="section" data-hidden="true" />
              </ReactFullpage.Wrapper>
            );
          }}
        />
      </div>}

      {!submitted && status !== 'paused' && !showWelcomePage && !backupExist && form.type === 'classic' && <div css={styles.main}>
        <div css={styles.content}>
          <div css={styles.form}>
            <FormWrapper
              form={form}
              fields={form.fields.filter((field) => field.section === 'root' || !field.section)}
              values={values}
              styles={styles}
              theme={theme}
              device={device}
              isTouchDevice={isTouchDevice}
              mode={mode}
              seed={seed}
              variables={variables}
              country={country}
              fieldsOptions={fieldsOptions}
              submitIsDisabled={submitIsDisabled}
              handleSubmit={handleSubmit}
              handleChange={handleChange}
              waitingForSubmissionId={waitingForSubmissionId}
              handlePartialResponses={handlePartialResponses}
              handleOnNextClick={handleOnNextClick}
              handleOnPrevClick={handleOnPrevClick}
              handleNextPage={handleNextPage}
              handlePreviousPage={handlePreviousPage}
              handleUpload={handleUpload}
              handleDelete={handleDelete}
              handleCancel={handleCancel}
              files={files}
            />

            {form.page === form.pages && <div id="submit" css={styles.submit} style={{ zIndex: 2, position: 'relative' }}>
              <Submit onSubmit={handleSubmit} onPreviousPage={handlePreviousPage} form={form} theme={theme} loading={waitingForSubmissionId} disabled={submitIsDisabled() || waitingForSubmissionId} />
            </div>}
          </div>
        </div>
      </div>}


      {!submitted && status !== 'paused' && <RequiredHelper inIframe={inIframe}
        fullpage={fullpage}
        messages={form.messages}
        formType={form.type}
        values={values}
        isTouchDevice={isTouchDevice}
        highlightField={handleHighlightField}
        fields={form.fields.filter((field) => values && values[field._id] && values[field._id].visible && field.error && field.page === form.page)} />}

      {status === 'paused' && <div css={styles.main}>
        <div css={styles.content}>
          <Paused messages={form.messages} hideBranding={form.owner ? form.owner.hideBranding : false} />
        </div>
      </div>}

      {submitted && <div css={styles.main}>
        <div css={styles.content}>
          <Submitted inIframe={inIframe}
            messages={form.messages}
            submittedText={form.submittedText}
            respondentLimits={form.respondentLimits}
            respondentLimitsNumber={form.respondentLimitsNumber}
            thankYouPages={thankYouPages}
            defaultThankYouPage={defaultThankYouPage}
            fields={form.fields}
            values={values}
            fieldsOptions={fieldsOptions}
            mode={mode}
            onSubmitAgain={handleSubmitAgain}
            theme={theme}
            variables={variables}
            hideBranding={form.owner ? form.owner.hideBranding : false} />
        </div>
      </div>}

      {backupExist && !submitted && status === 'live' && <div css={styles.main}>
        <div css={styles.content}>
          <Backup messages={form.messages}
            onRestoreBackup={handleRestoreBackup}
            onClearBackup={handleClearBackup}
            theme={theme}
            hideBranding={form.owner ? form.owner.hideBranding : false} />
        </div>
      </div>}

      {!showWelcomePage && <Footer inIframe={inIframe}
        messages={form.messages}
        submitted={submitted}
        status={status}
        mode={mode}
        ownerIsPro={form.owner.pro}
        isTouchDevice={isTouchDevice}
        form={form}
        allowGoUp={allowGoUp}
        allowGoDown={allowGoDown}
        onGoDown={() => handleGoDown()}
        onGoUp={() => handleGoUp()}
        userUiSettings={userUiSettings || {}}
        device={device}
        placement={placement}
        scrollbarWidth={form.type === 'conversational' ? 0 : scrollbarWidth}
        theme={theme}
        hideBranding={form.owner ? form.owner.hideBranding : false} />}

      {showProgressBar && !showWelcomePage && !submitted && !backupExist && status !== 'paused' && <ProgressBar theme={theme}
        fields={form.fields}
        activeFieldIndex={activeFieldIndex}
        messages={form.messages}
        formType={form.type}
        totalPages={form.pages}
        activePageNumber={form.page}
        values={values}
        mode={mode}
        device={device}
        placement={placement}
        userUiSettings={userUiSettings || {}}
        scrollbarWidth={form.type === 'conversational' ? 0 : scrollbarWidth} />}
    </div>
  );
};

export default QuestionscoutFormEngine;
