import styles from './Build.module.css';
import 'react-sortable-tree/style.css';

import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import arrayMove from 'array-move';
import { v4 as uuid } from 'uuid';
import ObjectID from 'bson-objectid';
import sortFieldsByPosition from 'helpers/sortFieldsByPosition.js';
import { Item, List, DragHandleComponent } from 'react-sortful';
import ioTasks from 'helpers/ioTasks.js';

import InputBox from './InputBox/InputBox.js';
import EmptyBox, { fieldPreviews } from './EmptyBox/EmptyBox.js';
import SectionBox from './SectionBox/SectionBox.js';
import FieldsList from './FieldsList/FieldsList.js';
import Edit from './Edit/Edit.js';
import Preview from '../Preview.js';

import { ReactComponent as WeirdArrowIcon } from 'assets/images/weird-arrow.svg';

import {
  updateFields, ioUpdateField, getFields, ioReorderFields, toggleFormPreview, updateDraggedFieldRef, ioCopyFieldFile, ioAddField, updateEditedFieldRef
} from 'store/ducks/builder.js';

import { Button, Loader } from 'ui';

const empty = new Map([
  ['root', { _id: 'root', childrens: [] }]
]);

const renderDropLineElement = (injectedProps) => (
  <div ref={injectedProps.ref} className={styles.dropLine} style={injectedProps.style} />
);

let scrollInterval;

const Build = () => {
  const dispatch = useDispatch();

  const contentRef = useRef(null);

  const showPreview = useSelector(state => state.builder.showPreview);
  const fields = useSelector(state => state.builder.fields);
  const form = useSelector(state => state.builder.form);
  const selectedEmptyBox = useSelector(state => state.builder.selectedEmptyBox);

  const [itemEntitiesMapState, setItemEntitiesMapState] = useState(empty);
  const [dragActive, setDragActive] = useState(false);
  const [loading, setLoading] = useState(false);

  const openPreview = (value) => dispatch(toggleFormPreview(value));

  useEffect(() => {
    const fieldsCopy = JSON.parse(JSON.stringify(fields));
    const rootChildrens = fieldsCopy.filter((field) => field.section === 'root' || !field.section).map((field) => field._id);
    const mapCopy = new Map(empty.entries());

    for (let field of fieldsCopy) {
      if (field.type !== 'section') field.childrens = undefined;

      mapCopy.set(field._id, field);
    }

    mapCopy.get('root').childrens = rootChildrens;

    setItemEntitiesMapState(mapCopy);
  }, [fields]);

  const copyField = async (refOrId, section = null, tasks = []) => {
    const fieldsCopy = [...fields];
    const copiedIndex = fieldsCopy.findIndex((field) => field && (field._id === refOrId || field.ref === refOrId));
    let newField = {};
    const promises = [];

    let oldRef;

    if (typeof copiedIndex === 'undefined') return;

    if (copiedIndex >= 0 && fieldsCopy[copiedIndex]) {
      newField = { ...fieldsCopy[copiedIndex] };
      oldRef = newField.ref;

      newField._id = String(ObjectID());
      newField.ref = uuid();
      if (!section) newField.position += 1;
      if (section) newField.section = section;

      if (newField.options && Array.isArray(newField.options)) {
        newField.options.map((option) => {
          if (newField.type === 'imageChoice') option.oldRef = option.ref;
          option.ref = uuid();

          return option;
        });
      }

      fieldsCopy.splice(copiedIndex, 0, newField);

      if (newField.type === 'image') {
        promises.push(dispatch(ioCopyFieldFile({
          fieldRef: newField.ref,
          type: newField.type,
          optionRef: null,
          oldUrl: newField.value,
          newUrl: newField.value.replace(oldRef.replace(/[^a-zA-Z0-9]/g, ''), newField.ref.replace(/[^a-zA-Z0-9]/g, ''))
        })));
      }

      if (newField.type === 'imageChoice' && newField.options && Array.isArray(newField.options)) {
        newField.options.map((option) => {
          try {
            const optionObject = JSON.parse(option.value);

            if (optionObject.url) {
              promises.push(dispatch(ioCopyFieldFile({
                fieldRef: newField.ref,
                type: newField.type,
                optionRef: option.ref,
                oldUrl: optionObject.url,
                newUrl: optionObject.url.replace(/(https:\/\/\w*\.\w*\.\w*\/forms\-images\/\w*\/)\w*(\.\w*\?hash=\d*)/g, `$1${option.ref.replace(/[^a-zA-Z0-9]/g, '')}$2`)
              })));
            }
          } catch (e) { }

          delete option.oldRef;

          return option;
        });
      }
    }

    fieldsCopy.filter((field) => fieldsCopy[copiedIndex].section === field.section).map((field) => {
      if (field.position >= fieldsCopy[copiedIndex].position && fieldsCopy[copiedIndex].ref !== field.ref) {
        field.position += 1;
      }

      return field;
    });

    // update parent section childrens
    if (newField.section !== 'root' && !section) {
      fieldsCopy[fieldsCopy.findIndex((f) => f._id === newField.section)].childrens = sortFieldsByPosition(fieldsCopy).filter((field) => field && field._id && newField.section === field.section).map((field) => field._id);
    }

    const responses = await Promise.all(promises);

    let index, optionIndex, optionValueObject;
    let now = Date.now();

    for (let res of responses) {
      index = fieldsCopy.findIndex((field) => field.ref === res.payload.fieldRef);

      if (res.payload.type === 'image') {
        if (index >= 0) fieldsCopy[index].value = res.payload.newUrl.replace(/hash=\d*/, `hash=${now}`);

        newField.value = res.payload.newUrl.replace(/hash=\d*/, `hash=${now}`);
      }

      if (res.payload.type === 'imageChoice') {
        if (index >= 0) {
          optionIndex = fieldsCopy[index].options.findIndex((option) => option.ref === res.payload.optionRef);

          if (optionIndex >= 0) {
            optionValueObject = JSON.parse(fieldsCopy[index].options[optionIndex].value);

            optionValueObject.url = res.payload.newUrl.replace(/hash=\d*/, `hash=${now}`);

            fieldsCopy[index].options[optionIndex].value = JSON.stringify(optionValueObject);
            newField.options[optionIndex].value = JSON.stringify(optionValueObject);
          }
        }
      }
    }

    if (!section) await dispatch(updateFields(fieldsCopy));
    if (!section && ['divider', 'pageBreak'].indexOf(newField.type) === -1) await dispatch(updateEditedFieldRef(newField.ref));

    const ioTask = await dispatch(ioAddField(newField, { updatePosition: !section }));

    tasks.push(ioTask.taskId);

    return { tasks, field: newField };
  }

  const copySection = async (refOrId) => {
    setLoading(true);
    const { tasks, field } = await copySectionRecursive(refOrId, null, fields);

    await ioTasks.wait(tasks);
    await dispatch(getFields());
    await dispatch(updateEditedFieldRef(field.ref));
    setLoading(false);
  };

  const copySectionRecursive = async (refOrId, section, fieldsUpdated, tasks = []) => {
    const fieldsCopy = JSON.parse(JSON.stringify(fieldsUpdated));
    const copiedIndex = fieldsCopy.findIndex((field) => field && (field._id === refOrId || field.ref === refOrId));
    let newSection = {};
    let childrenField;
    let copied;
    let ioUpdateTask, ioAddTask;
    let parentChildrens = [];

    if (typeof copiedIndex === 'undefined' || copiedIndex === -1 || !fieldsCopy[copiedIndex]) return;

    newSection = JSON.parse(JSON.stringify(fieldsCopy[copiedIndex]));

    newSection._id = String(ObjectID());
    newSection.ref = uuid();
    if (!section) newSection.position += 1;
    if (section) newSection.section = section;

    const childrens = await Promise.all(newSection.childrens.map(async (children) => {
      childrenField = fieldsCopy.find((f) => String(f._id) === children);

      if (childrenField.type === 'section') {
        copied = await copySectionRecursive(childrenField.ref, newSection._id, fieldsCopy, tasks);
      } else {
        copied = await copyField(childrenField.ref, newSection._id, tasks);
      }

      tasks = copied.tasks;
      fieldsCopy.push(copied.field);

      return copied.field._id;
    }));

    newSection.childrens = childrens;

    if (section === null && (newSection.section !== 'root' || !newSection.section)) {
      parentChildrens = fieldsCopy.find((f) => String(f._id) === String(newSection.section)).childrens || [];
      parentChildrens.push(newSection._id);

      ioUpdateTask = await dispatch(ioUpdateField(fieldsCopy.find((f) => String(f._id) === String(newSection.section)).ref, { 
        childrens: parentChildrens
      }));

      tasks.push(ioUpdateTask.taskId);
    }

    ioAddTask = await dispatch(ioAddField(newSection, { updatePosition: !section }));

    tasks.push(ioAddTask.taskId);

    return { tasks, field: newSection };
  };

  const itemElements = React.useMemo(() => {
    const topLevelItems = itemEntitiesMapState.get('root').childrens.map((itemId) => itemEntitiesMapState.get(itemId));
    let rootIndex = 0;

    const createItemElement = (item, index) => {
      if (!item) return null;
      if (item.section === 'root') rootIndex += 1;

      if (typeof item.childrens !== 'undefined') {
        const childItems = item.childrens.map((itemId) => itemEntitiesMapState.get(itemId));
        const childItemElements = childItems.map(createItemElement);

        return <>
          <Item key={item._id} identifier={item._id} index={index} isUsedCustomDragHandlers isGroup>
            <div className={styles.group}>
              <SectionBox onCopySection={copySection} field={item} index={rootIndex} DragHandle={DragHandleComponent}>{childItemElements}</SectionBox>
            </div>
          </Item>
          {item.section === 'root' && <EmptyBox index={rootIndex} />}
        </>;
      }

      return <>
        <Item key={item._id} identifier={item._id} index={index} isUsedCustomDragHandlers>
          <div className={styles.item}>
            <InputBox onCopyField={copyField} index={rootIndex} field={item} mode="build" DragHandle={DragHandleComponent} />
          </div>
        </Item>
        {item.section === 'root' && <EmptyBox index={rootIndex} />}
      </>;
    };

    return topLevelItems.map(createItemElement);
  }, [itemEntitiesMapState, fields]);

  const renderGhostElement = React.useCallback(({ identifier, isGroup }) => {
    const item = itemEntitiesMapState.get(identifier);
    if (typeof item === 'undefined') return;

    if (isGroup) {
      return <div className={[styles.group, styles.ghost].join(' ')}>
        <SectionBox field={item} DragHandle={DragHandleComponent}></SectionBox>
      </div>;
    }

    return <div className={[styles.item, styles.ghost].join(' ')}>
      <InputBox index={2048} field={item} mode="build" DragHandle={DragHandleComponent} />
    </div>;
  }, [itemEntitiesMapState, fields]);

  const renderPlaceholderElement = React.useCallback((injectedProps, { identifier, isGroup }) => {
    const item = itemEntitiesMapState.get(identifier);
    const className = [isGroup ? styles.group : styles.item, styles.placeholder].join(' ');
    const children = isGroup ? <SectionBox field={item} DragHandle={DragHandleComponent} /> : <InputBox index={232} field={item} mode="build" DragHandle={DragHandleComponent} />;

    return <div className={className} style={injectedProps.style}>{children}</div>;
  }, [itemEntitiesMapState, fields]);

  const renderStackedGroupElement = React.useCallback((injectedProps, { identifier }) => {
    const item = itemEntitiesMapState.get(identifier);

    return <div className={styles.stacked} style={injectedProps.style}>
      {item.type === 'shortText' && <fieldPreviews.shortText field={item} />}
      {item.type === 'longText' && <fieldPreviews.longText field={item} />}
      {item.type === 'dropdown' && <fieldPreviews.dropdown field={item} />}
      {item.type === 'radio' && <fieldPreviews.radio field={item} />}
      {item.type === 'checkbox' && <fieldPreviews.checkbox field={item} />}
      {item.type === 'title' && <fieldPreviews.title field={item} />}
      {item.type === 'description' && <fieldPreviews.description field={item} />}
      {item.type === 'datetime' && <fieldPreviews.datetime field={item} />}
      {item.type === 'fileUpload' && <fieldPreviews.fileUpload field={item} />}
      {item.type === 'signature' && <fieldPreviews.signature field={item} />}
      {item.type === 'image' && <fieldPreviews.image />}
      {item.type === 'scale' && <fieldPreviews.scale field={item} />}
      {item.type === 'divider' && <fieldPreviews.divider field={item} />}
      {item.type === 'imageChoice' && <fieldPreviews.imageChoice field={item} />}
      {item.type === 'section' && <fieldPreviews.section field={item} />}
      {item.type === 'pageBreak' && <fieldPreviews.pageBreak field={item} fields={fields} form={form} selectedEmptyBox={selectedEmptyBox} />}
    </div>;
  }, [itemEntitiesMapState, fields]);

  const onDragStart = React.useCallback(({ identifier }) => {
    setDragActive(true);
    if (identifier) dispatch(updateDraggedFieldRef(identifier));
  });

  const onDragEnd = React.useCallback((meta) => {
    setDragActive(false);
    dispatch(updateDraggedFieldRef(null));

    let fieldsToUpdate = {
      positions: {},
      childrens: {},
      sections: {}
    };

    if (meta.groupIdentifier === meta.nextGroupIdentifier && meta.index === meta.nextIndex) return;

    const newMap = new Map(itemEntitiesMapState.entries());
    const item = newMap.get(meta.identifier);

    if (typeof item === 'undefined') return;
    const groupItem = newMap.get(meta.groupIdentifier ?? 'root');

    if (typeof groupItem === 'undefined') return;
    if (typeof groupItem.childrens === 'undefined') return;

    if (meta.groupIdentifier === meta.nextGroupIdentifier) {
      const nextIndex = meta.nextIndex ?? groupItem.childrens?.length ?? 0;
      groupItem.childrens = arrayMove(groupItem.childrens, meta.index, nextIndex);
    } else {
      const nextGroupItem = newMap.get(meta.nextGroupIdentifier ?? 'root');

      if (typeof nextGroupItem === 'undefined') return;
      if (typeof nextGroupItem.childrens === 'undefined') return;

      groupItem.childrens.splice(meta.index, 1);

      if (typeof meta.nextIndex === 'undefined') {
        // Inserts an item to a group which has no items.
        nextGroupItem.childrens.push(meta.identifier);
      } else {
        // Insets an item to a group.
        nextGroupItem.childrens.splice(meta.nextIndex, 0, item._id);
      }

      newMap.set(meta.identifier, { ...newMap.get(meta.identifier), section: nextGroupItem.type || 'root' });
    }

    [...newMap].map(([, field]) => {
      if (Array.isArray(field.childrens)) {
        field.childrens = field.childrens.filter((id) => newMap.get(id));

        field.childrens.map((id, index) => {
          fieldsToUpdate.positions[id] = index + 1;
          fieldsToUpdate.sections[id] = field._id;

          return id;
        });
      }

      if (field.type === 'section') fieldsToUpdate.childrens[field._id] = field.childrens;

      return field;
    });

    const fieldsCopy = [...newMap].map(([, field]) => field).filter((field) => field._id !== 'root');

    for (let field of fieldsCopy) {
      field.position = parseInt(fieldsToUpdate.positions[field._id]);
      field.childrens = fieldsToUpdate.childrens[field._id] || [];
      field.section = fieldsToUpdate.sections[field._id] || 'root';
    }

    dispatch(ioReorderFields(fieldsToUpdate));
    dispatch(updateFields(fieldsCopy));
  }, [itemEntitiesMapState, fields]);

  const startScroll = (direction) => {
    const content = contentRef.current;
    const jump = direction === 'top' ? -5 : 5;

    scrollInterval = setInterval(() => {
      content.scrollTop = content.scrollTop + jump;
    }, 30);
  };

  const stopScroll = () => {
    clearInterval(scrollInterval);
    scrollInterval = undefined;
  };

  return <>
    {showPreview && <Preview hideWelcome={true} />}

    <div className={styles.main}>
      <Edit />

      <div className={styles.content} ref={contentRef} id="builderBuildContent">
        {loading && <div className={styles.loading}>
          <Loader size={50} />
        </div>}

        {dragActive && <div className={[styles.scrollEdge, styles.top].join(' ')}
          onMouseEnter={() => startScroll('top')}
          onMouseLeave={() => stopScroll()} />}
        <Button theme="black" onClick={() => openPreview(!showPreview)} className={styles.previewBtn}>Preview</Button>

        {fields.length === 0 && <div className={[
          styles.intro,
          fields.length > 0 ? styles.hidden : ''
        ].join(' ')}>
          <div className={styles.introTitle}>Start by adding a question</div>
          <p>Clicking the plus icon opens the question library.</p>
          <p>This is where you will find all of the question types to build your form or survey.</p>
          <WeirdArrowIcon />
        </div>}

        <FieldsList />

        <div className={styles.form}>
          <List renderDropLine={renderDropLineElement}
            renderGhost={renderGhostElement}
            renderPlaceholder={renderPlaceholderElement}
            renderStackedGroup={renderStackedGroupElement}
            draggingCursorStyle="grabbing"
            onDragStart={onDragStart}
            onDragEnd={onDragEnd}
            itemSpacing={12}>
            <EmptyBox index={-1024} />
            {itemElements}
            {form.type === 'classic' && <div className={styles.item}>
              <InputBox index={2048} field={{
                ref: 'submit',
                label: 'Submit',
                type: 'submit',
                section: 'root',
                position: 2048
              }} mode="build" />
            </div>}
          </List>
        </div>

        {dragActive && <div className={[styles.scrollEdge, styles.bottom].join(' ')}
          onMouseEnter={() => startScroll('bottom')}
          onMouseLeave={() => stopScroll()} />}
      </div>
    </div>
  </>;
}

export default Build;
