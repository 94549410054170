import styles from './ThemeContent.module.css';

import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import demoTheme from './DemoTheme.json';
import FormPreview from 'components/Themes/FormPreview.js';

import {
  getForms
} from 'store/ducks/_forms.js';

import { Loader } from 'ui';

const ThemeContent = ({ match }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getForms());
  }, []);

  const user = useSelector(state => state._users.user);
  const device = useSelector(state => state.theme.device);
  const previewForm = useSelector(state => state.theme.previewForm);
  const form = useSelector(state => state._forms.form);
  const loading = useSelector(state => state._forms.getForm.loading);
  const previewPage = useSelector(state => state.theme.previewPage);

  const selectedForm = { ...(previewForm ? form : demoTheme) };

  selectedForm.page = previewPage;

  if (!selectedForm.owner) {
    selectedForm.owner = {
      _id: null,
      hideBranding: user.hideBranding,
      pro: user.pro
    };
  }

  return (
    <div className={styles.main}>
      <div className={[styles.scrollArea, styles[device]].join(' ')} style={selectedForm.type === 'classic' ? { overflowY: 'scroll' } : { overflow: 'hidden' }}>
        {loading && <Loader size={50} />}
        {!loading && <FormPreview themeId={match.params.id} themeType={match.params.type} device={device} form={selectedForm} placement={'themes'}  />}
      </div>
    </div>
  );
}

export default ThemeContent;
