import styles from './SettingsDomain.module.css';

import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import debounce from 'lodash.debounce';
import { useDropzone } from 'react-dropzone';
import { v4 as uuid } from 'uuid';

import { TextInput, Toggle, Card, CardRow, CardRowLabel, CardRowContent, Select, Loader } from 'ui';

import {
  ioUpdateForm, uploadImage, removeImage, updateForm
} from 'store/ducks/builder.js';

function UploadArea({ id, uploads, value, onRemove, disabled }) {
  const dispatch = useDispatch();

  const file = uploads.find((file) => file.ref === id) || null;

  const { getRootProps, getInputProps, open } = useDropzone({
    accept: 'image/x-icon, image/vnd.microsoft.icon, image/ico, image/icon, text/ico, application/ico, image/png, image/gif',
    multiple: false,
    noClick: true,
    noKeyboard: true,
    disabled,
    onDrop: (acceptedFiles) => dispatch(uploadImage(id, acceptedFiles, { type: 'favicon' }))
  });

  return (
    <div className={styles.imageUpload}>
      {!value && <div {...getRootProps({ className: [styles.dropzone, disabled ? styles.disabled : ''].join(' ') })}>
        <input {...getInputProps()} />
        {(!file) && <>
          <p>Drop your image here</p>
          <p>or</p>
          <span onClick={open}>click to upload</span>
        </>}
        {file && file.loaded !== true && <Loader size={30} />}
      </div>}

      {value && <div className={[styles.uploaded, disabled ? styles.disabled : ''].join(' ')}>
        <div className={styles.preview}>
          <img src={value} alt="" />
        </div>
        <span className={styles.removeImage} onClick={onRemove}>Remove current favicon</span>
      </div>}
    </div>
  );
};

const SettingsDomain = ({ }) => {
  const dispatch = useDispatch();

  const form = useSelector(state => state.builder.form);
  const uploads = useSelector(state => state.builder.uploads);
  const user = useSelector(state => state._users.user);

  const domain = form.domain || {};
  const alias = form.alias || {};
  const favicon = form.favicon || {};

  useEffect(() => {
    if (favicon.enabled && !user.hideBranding) {
      handleChange('favicon', { enabled: false })
    }
  }, []);

  const ioUpdateFormWithDelay = debounce((params) => {
    dispatch(ioUpdateForm(params));
  }, 500);

  const handleChange = (key, params, delay = false) => {
    let copy = {};

    if (key === 'domain') copy = { ...domain, ...params };
    if (key === 'alias') copy = { ...alias, ...params };
    if (key === 'favicon') copy = { ...favicon, ...params };

    dispatch(updateForm({ [key]: copy }));

    if (delay) {
      ioUpdateFormWithDelay({ [key]: copy });
    } else {
      dispatch(ioUpdateForm({ [key]: copy }));
    }
  };

  return <>
    <div className={styles.statusValue} onClick={() => {
      if (domain.enabled) {
        handleChange('alias', { enabled: false });
        handleChange('favicon', { enabled: false });
      }

      handleChange('domain', { enabled: !domain.enabled, value: null });
    }}>
      <Toggle checked={domain.enabled} readOnly={true} onChange={() => { }} />
      <div className={styles.statusValueText}>This form <span>{domain.enabled ? 'is' : 'is not'}</span> using a custom domain</div>
    </div>

    <Card className={styles.card} disabled={!domain.enabled}>
      <CardRow>
        <CardRowLabel title="Custom Domain" subTitle="This is the domain that is connected with the form." />

        <CardRowContent style={{ height: 32, width: 350 }}>
          <Select width={350} options={form.domains.map((f) => {
            return { label: f.hostname, value: f._id };
          })} value={{ label: form.domains.findIndex((d) => d._id === domain.value) !== -1 ? form.domains.find((d) => d._id === domain.value).hostname : '- Select -', value: domain.value }} onChange={(selected) => handleChange('domain', { value: selected.value })} />
        </CardRowContent>
      </CardRow>

      <CardRow disabled={!domain.value}>
        <CardRowLabel title="Form Alias" subTitle="This is the link your respondents will go to in order to access the form." showToggle={true} toggleValue={alias.enabled} onClick={() => handleChange('alias', { enabled: !alias.enabled })} />

        <CardRowContent>
          <TextInput width="80%" value={alias.enabled ? alias.value : form._id} placeholder="your-unique-form-alias" onChange={(e) => handleChange('alias', { value: e.target.value.replace(/\s/gi, '').replace(/[^\w\s-]/gi, '') })} disabled={!alias.enabled || form.domains.findIndex((d) => d._id === domain.value) === -1} suffix={form.domains.findIndex((d) => d._id === domain.value) !== -1 ? `https://${form.domains.find((d) => d._id === domain.value).hostname}/` : 'https://form.questionscout.com/'} />
          {form.domains.findIndex((d) => d._id === domain.value) === -1 && <div className={styles.noAlias}>You can only adjust the form alias on a custom domian.</div>}
        </CardRowContent>
      </CardRow>

      <CardRow disabled={!domain.value}>
        <CardRowLabel title="Favicon" subTitle="32x32 (ico, png or gif)" disableToggle={!user.hideBranding} showToggle={true} toggleValue={favicon.enabled} onClick={() => handleChange('favicon', { enabled: user.hideBranding ? !favicon.enabled : false })} />

        <CardRowContent>
          <UploadArea disabled={!favicon.enabled || !user.hideBranding} id={uuid()} value={favicon.value} uploads={uploads} onRemove={() => {
            dispatch(removeImage(null, favicon.value));
            handleChange('favicon', { value: null });
          }} />
          {!user.hideBranding && <div className={styles.noAlias}>Available only for accounts with Branding Removal.</div>}
        </CardRowContent>
      </CardRow>
    </Card>
  </>;
}

export default SettingsDomain;
