import styles from './Menu.module.css';

import React from 'react';

import {
  TitleField, SizeWeightAndColorField, CheckboxField, SizeWithColorField,
  SizeWithTypeField, SizeField, SelectField, ColorField, GradientField,
  InputStyleField, ToggleField, CopyField
} from '../ThemeMenuFields.js';

const typeOptions = [
  { label: '%', value: '%' },
  { label: 'px', value: 'px' }
];

const fontWeightOptions = [
  { label: 400, value: 400 },
  { label: 600, value: 600 }
];

const alignOptions = [
  { label: 'Left', value: 'left' },
  { label: 'Center', value: 'center' },
  { label: 'Right', value: 'right' }
];

const States = ({ title, subTitle, id, settings, colors, onChange, onNewColor, onCopy }) => {
  return (
    <>
      <TitleField title={title} subTitle={subTitle} />

      {subTitle !== 'Idle' && <ToggleField label={`Different styling on ${subTitle}`}
        settings={settings}
        onChange={onChange}
        margin="25px 0 0 0"
        value={`${id}Enable`} />}

      {(subTitle === 'Idle' || settings[`${id}Enable`]) && <>
        {subTitle === 'Hover' && <CopyField onCopy={onCopy}
          margin="15px 0 0 0"
          id={id}
          values={['TextColor', 'Background', 'OutsideBorderColor', 'InsideBorderColor', 'Shadow', 'ShadowColor', 'ShadowOffsetX', 'ShadowOffsetY', 'ShadowBlur', 'ShadowSpread']}
          options={[
            { label: 'Idle', value: 'scalesIdle' }
          ]} />}

        {subTitle === 'Active' && <CopyField onCopy={onCopy}
          margin="15px 0 0 0"
          id={id}
          values={['TextColor', 'Background', 'OutsideBorderColor', 'InsideBorderColor', 'Shadow', 'ShadowColor', 'ShadowOffsetX', 'ShadowOffsetY', 'ShadowBlur', 'ShadowSpread']}
          options={[
            { label: 'Idle', value: 'scalesIdle' },
            { label: 'Hover', value: 'scalesHover' }
          ]} />}

        {subTitle === 'Idle' && <SizeWeightAndColorField title="Option text"
          settings={settings}
          colors={colors}
          onChange={onChange}
          onNewColor={onNewColor}
          weights={fontWeightOptions}
          margin="10px 0 0 0"
          size={`${id}TextFontSize`}
          weight={`${id}TextWeight`}
          color={`${id}TextColor`} />}

        {subTitle !== 'Idle' && <ColorField title="Option text color"
          settings={settings}
          colors={colors}
          onChange={onChange}
          onNewColor={onNewColor}
          margin="25px 0 0 0"
          color={`${id}TextColor`} />}

        {subTitle === 'Idle' && <SizeWeightAndColorField title="Option bottom labels text"
          settings={settings}
          colors={colors}
          onChange={onChange}
          onNewColor={onNewColor}
          weights={fontWeightOptions}
          margin="25px 0 0 0"
          size={`${id}LabelsTextFontSize`}
          weight={`${id}LabelsTextWeight`}
          color={`${id}LabelsTextColor`} />}

        <ColorField title="Option background"
          settings={settings}
          colors={colors}
          onChange={onChange}
          margin="25px 0 0 0"
          color={`${id}Background`} />

        {subTitle === 'Idle' && <SizeWithColorField title="Option outside border"
          settings={settings}
          colors={colors}
          onChange={onChange}
          onNewColor={onNewColor}
          margin="25px 0 0 0"
          size={`${id}OutsideBorderSize`}
          color={`${id}OutsideBorderColor`} />}

        {subTitle !== 'Idle' && <ColorField title="Option outside border color"
          settings={settings}
          colors={colors}
          onChange={onChange}
          onNewColor={onNewColor}
          margin="25px 0 0 0"
          color={`${id}OutsideBorderColor`} />}

        {subTitle === 'Idle' && <SizeWithColorField title="Option inside border"
          settings={settings}
          colors={colors}
          onChange={onChange}
          onNewColor={onNewColor}
          margin="25px 0 0 0"
          size={`${id}InsideBorderSize`}
          color={`${id}InsideBorderColor`} />}

        {subTitle !== 'Idle' && <ColorField title="Option inside border color"
          settings={settings}
          colors={colors}
          onChange={onChange}
          onNewColor={onNewColor}
          margin="25px 0 0 0"
          color={`${id}InsideBorderColor`} />}

        {subTitle === 'Idle' && <SizeField title="Field roundness"
          settings={settings}
          onChange={onChange}
          margin="25px 0 0 0"
          size={`${id}Roundness`} />}

        {subTitle === 'Idle' && <SizeField title="Field height"
          settings={settings}
          onChange={onChange}
          margin="25px 0 0 0"
          size={`${id}Height`} />}

        <CheckboxField label="Add shadows"
          settings={settings}
          onChange={onChange}
          margin="25px 0 0 0"
          value={`${id}Shadow`} />

        {settings[`${id}Shadow`] && <>
          <ColorField title="Shadow color"
            settings={settings}
            colors={colors}
            onChange={onChange}
            onNewColor={onNewColor}
            margin="10px 0 0 0"
            color={`${id}ShadowColor`} />

          <SizeField title="Shadow horizontal length"
            settings={settings}
            onChange={onChange}
            margin="25px 0 0 0"
            size={`${id}ShadowOffsetX`} />

          <SizeField title="Shadow vertical length"
            settings={settings}
            onChange={onChange}
            margin="25px 0 0 0"
            size={`${id}ShadowOffsetY`} />

          <SizeField title="Shadow blur"
            settings={settings}
            onChange={onChange}
            margin="25px 0 0 0"
            size={`${id}ShadowBlur`} />

          <SizeField title="Shadow spread"
            settings={settings}
            onChange={onChange}
            margin="25px 0 0 0"
            size={`${id}ShadowSpread`} />
        </>}
      </>}
    </>
  );
};

const Scales = ({ settings, colors, onChange, onNewColor, onCopy }) => {
  return (
    <>
      <TitleField title="General Field Settings" />

      <SizeWithTypeField title="Maximum field width"
        settings={settings}
        onChange={onChange}
        margin="25px 0 0 0"
        types={typeOptions}
        size="scalesWidth"
        type="scalesWidthType" />

      <SelectField title="Field alignment"
        settings={settings}
        onChange={onChange}
        options={alignOptions}
        margin="25px 0 0 0"
        value="scalesAlignment" />

      <div className={styles.doubleRow}>
        <div className={styles.left}>
          <SizeField title="Field top margin"
            settings={settings}
            onChange={onChange}
            margin="25px 0 0 0"
            size={`scalesTopMargin`} />
        </div>
        <div className={styles.right}>
          <SizeField title="Field bottom margin"
            settings={settings}
            onChange={onChange}
            margin="25px 0 0 0"
            size={`scalesBottomMargin`} />
        </div>
      </div>

      <States title="Field Styling" subTitle="Idle" id="scalesIdle" settings={settings} colors={colors} onChange={onChange} onNewColor={onNewColor} onCopy={onCopy} />
      <States title="Field Styling" subTitle="Hover" id="scalesHover" settings={settings} colors={colors} onChange={onChange} onNewColor={onNewColor} onCopy={onCopy} />
      <States title="Field Styling" subTitle="Active" id="scalesActive" settings={settings} colors={colors} onChange={onChange} onNewColor={onNewColor} onCopy={onCopy} />
    </>
  );
}

export default Scales;
