/** @jsx jsx */
/* @jsxFrag React.Fragment */

import { jsx, css } from '@emotion/core';
import React, { useState } from 'react';
import getFieldLabel from 'helpers/getFieldLabel.js';

import { LabelAtom, DescriptionAtom } from './atoms.js';

const formats = {
  phone: 'text',
  number: 'number'
};

const LongText = ({ form, field, theme, value, values, onChange, onPartialResponse, fieldsOptions, variables }) => {
  const [active, setActive] = useState(false);

  const alignOptions = {
    left: 'flex-start',
    center: 'center',
    right: 'flex-end'
  };

  const inputContainerStyleTypes = {
    style1: {
      borderBottom: `${theme.inputFieldsIdleBorderSize}px solid ${theme.inputFieldsIdleBorderColor}`,
    },
    style2: {
      border: 'none'
    },
    style3: {
      border: `${theme.inputFieldsIdleBorderSize}px solid ${theme.inputFieldsIdleBorderColor}`,
    },
  }

  const mainStyle = css({
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: alignOptions[theme.inputFieldsDropdownsAlignment],
    margin: `${theme.inputFieldsDropdownsTopMargin || 0}px 0 ${theme.inputFieldsDropdownsBottomMargin || 0}px 0`
  });

  const contentStyle = css({
    width: '100%',
    maxWidth: `${theme.inputFieldsDropdownsWidth}${theme.inputFieldsDropdownsWidthType}`
  });

  const inputContainerStyle = css({
    background: theme.inputFieldsIdleBackground,
    minHeight: theme.inputFieldsIdleHeight,
    lineHeight: '142%',
    color: theme.inputFieldsIdleTextColor,
    fontWeight: theme.inputFieldsIdleTextWeight,
    fontFamily: theme.typographyContentFontFamily,
    fontSize: theme.inputFieldsIdleTextFontSize,
    borderRadius: `${theme.inputFieldsIdleRoundness}px`,
    boxShadow: theme.inputFieldsIdleShadow ? `${theme.inputFieldsIdleShadowOffsetX}px ${theme.inputFieldsIdleShadowOffsetY}px ${theme.inputFieldsIdleShadowBlur}px ${theme.inputFieldsHoverShadowSpread}px ${theme.inputFieldsIdleShadowColor}` : 'none',
    ...inputContainerStyleTypes[theme.inputFieldsDropdownsType],
    '& textarea': {
      display: 'block',
      boxSizing: 'border-box',
      width: '100%',
      minWidth: '100%',
      maxWidth: '100%',
      minHeight: theme.inputFieldsIdleHeight,
      lineHeight: '142%',
      color: theme.inputFieldsIdleTextColor,
      fontWeight: theme.inputFieldsIdleTextWeight,
      fontSize: theme.inputFieldsIdleTextFontSize,
      padding: `${theme.inputFieldsIdleVerticalPadding || 0}px ${theme.inputFieldsIdleHorizontalPadding || 0}px`
    },
    '& textarea::placeholder': {
      color: theme.inputFieldsIdlePlaceholderColor,
      fontWeight: theme.inputFieldsIdleTextWeight,
      fontSize: theme.inputFieldsIdlePlaceholderFontSize,
    },
    '&:hover': (() => {
      if (!theme.inputFieldsHoverEnable || active) return {};

      const obj = {
        background: theme.inputFieldsHoverBackground,
        borderColor: theme.inputFieldsHoverBorderColor,
        color: theme.inputFieldsHoverTextColor,
        '& textarea': {
          color: theme.inputFieldsHoverTextColor
        },
        '& textarea::placeholder': {
          color: theme.inputFieldsHoverPlaceholderColor
        },
      };

      if (theme.inputFieldsHoverShadow) {
        obj.boxShadow = `${theme.inputFieldsHoverShadowOffsetX}px ${theme.inputFieldsHoverShadowOffsetY}px ${theme.inputFieldsHoverShadowBlur}px ${theme.inputFieldsHoverShadowSpread}px ${theme.inputFieldsHoverShadowColor}`;
      } else {
        obj.boxShadow = 'none';
      }

      return obj;
    })(),
    '&.isActive': (() => {
      if (!theme.inputFieldsActiveEnable) return {};

      const obj = {
        background: theme.inputFieldsActiveBackground,
        borderColor: theme.inputFieldsActiveBorderColor,
        color: theme.inputFieldsActiveTextColor,
        '& textarea': {
          color: theme.inputFieldsActiveTextColor
        },
        '& textarea::placeholder': {
          color: theme.inputFieldsActivePlaceholderColor
        },
      };

      if (theme.inputFieldsActiveShadow) {
        obj.boxShadow = `${theme.inputFieldsActiveShadowOffsetX}px ${theme.inputFieldsActiveShadowOffsetY}px ${theme.inputFieldsActiveShadowBlur}px ${theme.inputFieldsActiveShadowSpread}px ${theme.inputFieldsActiveShadowColor}`;
      } else {
        obj.boxShadow = 'none';
      }

      return obj;
    })()
  });

  return <>
    <div css={mainStyle}>
      <div css={contentStyle}>
        <LabelAtom required={field.required} error={field.error} theme={theme}>{getFieldLabel(field.label, values, 'jsx', fieldsOptions, variables)}</LabelAtom>

        <div css={inputContainerStyle} className={active ? 'isActive' : ''}>
          <textarea type={formats[field.format] || 'text'} autocomplete="chrome-off" rows={field.longTextRows || 3} value={value || ''} onChange={(e) => onChange({ [field._id]: e.target.value })} placeholder={field.placeholder || ''} readOnly={field.readonly} onBlur={() => { setActive(false); onPartialResponse(field._id); }} onFocus={() => setActive(true)} />
        </div>

        <DescriptionAtom theme={theme}>{getFieldLabel(field.description, values, 'jsx', fieldsOptions, variables)}</DescriptionAtom>
      </div>
    </div>
  </>;
};

export default LongText;
