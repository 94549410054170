import styles from './Logic.module.css';

import React from 'react';
import { useSelector } from 'react-redux';
import getFieldLabel from 'helpers/getFieldLabel.js';
import _range from 'lodash/range';

import { Button, Select, TextInput } from 'ui';

import { ReactComponent as RemoveIcon } from 'assets/images/trash.svg';

const typeOptions = [
  { label: 'Is', value: 'equal' },
  { label: 'Is not', value: 'notequal' },
];

const calculationTypeOptions = [
  { label: '= equal to', value: '=' },
  { label: '!= not equal to', value: '!=' },
  { label: '< less than', value: '<' },
  { label: '> more than', value: '>' },
  { label: '>= more or equal to', value: '>=' },
  { label: '<= less or equal to', value: '<=' },
];

const conditionsCompareOptions = [
  { label: '(number)', value: 'number' },
  { label: '(variable)', value: 'variable' },
];

const conditionsTypeOptions = [
  { label: 'All', value: 'all' },
  { label: 'Any', value: 'any' },
];

const fieldOptionLabel = (field, columnOptions, values) => {
  return <div style={{
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%'
  }}>
    <div style={{
      backgroundColor: columnOptions[field.type].backgroundColor,
      width: 22,
      height: 22,
      marginRight: 10,
      borderRadius: 2,
      boxSizing: 'border-box',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }}>
      <img src={columnOptions[field.type].icon} width="15px" alt="" />
    </div>
    <div style={{
      width: 'calc(100% - 48px)',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap'
    }}>{getFieldLabel(field.label || field.placeholder || field.type, values)}</div>
  </div>;
};

const fieldValueLabel = (option, field) => {
  if (!option) return '- Select -';
  if (field.type !== 'imageChoice') return option.value;

  const parsedValue = option.value ? JSON.parse(option.value) : {};

  return <div style={{
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
    height: '100%'
  }}>
    <div style={{
      width: 22,
      height: 22,
      marginRight: 10,
      borderRadius: 2,
      boxSizing: 'border-box',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }}>
      <img src={parsedValue.url} width="20px" alt="" />
    </div>
    <div style={{
      width: 'calc(100% - 48px)',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap'
    }}>{parsedValue.text}</div>
  </div>;
};

const Logic = ({ conditionsType, fieldConditions = [], calculationConditions = [], onChange, alwaysOne, conditionTypePrefix }) => {
  const fields = useSelector(state => state.builder.form.fields);
  const columnOptions = useSelector(state => state.results.columnOptions);
  const values = useSelector(state => state.builder.values);
  const calculationVariables = useSelector(state => state.builder.form.calculationVariables);

  const fieldSelectOptions = fields.filter((f) => ['radio', 'checkbox', 'dropdown', 'imageChoice', 'scale'].indexOf(f.type) !== -1);

  const totalLength = fieldConditions.length + calculationConditions.length;

  // Field
  const updateFieldCondition = ({ index, key, value }) => {
    const conditionsCopy = [...fieldConditions];

    conditionsCopy[index][key] = value;

    onChange({
      conditions: conditionsCopy
    }, false);
  };

  const removeFieldCondition = (index) => {
    if (alwaysOne && totalLength <= 1) return;

    const conditionsCopy = [...fieldConditions];

    conditionsCopy.splice(index, 1);

    onChange({
      conditions: conditionsCopy
    }, false);
  };

  const addEmptyFieldRule = () => {
    const conditionsCopy = [...fieldConditions];

    conditionsCopy.push({
      triggerField: null,
      type: 'equal',
      value: null
    });

    onChange({
      conditions: conditionsCopy
    }, false);
  };

  // Calculations
  const updateCalculationsCondition = ({ index, key, value }) => {
    const conditionsCopy = [...calculationConditions];

    conditionsCopy[index][key] = value;

    onChange({
      calculationConditions: conditionsCopy
    }, false);
  };

  const addEmptyCalculationsRule = () => {
    const conditionsCopy = [...calculationConditions];

    conditionsCopy.push({
      variableA: null,
      variableB: null,
      compare: 'number',
      type: '=',
      value: 0
    });

    onChange({
      calculationConditions: conditionsCopy
    }, false);
  };

  const removeCalculationsCondition = (index) => {
    if (alwaysOne && totalLength <= 1) return;

    const conditionsCopy = [...calculationConditions];

    conditionsCopy.splice(index, 1);

    onChange({
      calculationConditions: conditionsCopy
    }, false);
  };

  return <div className={styles.logic}>
    <div className={styles.type}>
      <span>{conditionTypePrefix}</span>
      <Select width={80} options={conditionsTypeOptions} margin="0 5px 0 5px" value={
        { label: conditionsTypeOptions.find((option) => option.value === conditionsType).label, value: conditionsType }
      } onChange={(selected) => onChange({
        conditionsType: selected.value
      }, false)} />
      <span>of the following rules match:</span>
    </div>

    <hr />

    <div className={styles.content}>
      {fieldSelectOptions.length === 0 && <div className={styles.empty}>
        <p>Your form is missing fields to create a logic.</p>
        <span>To start creating a logic, your form must include one of the following field types:</span>

        <ul>
          <li>
            <span style={{ backgroundColor: columnOptions.dropdown.backgroundColor }}>
              <img src={columnOptions.dropdown.icon} width="12px" alt="" />
            </span>Dropdown
          </li>
          <li>
            <span style={{ backgroundColor: columnOptions.radio.backgroundColor }}>
              <img src={columnOptions.radio.icon} width="12px" alt="" />
            </span>Single Choice
          </li>
          <li>
            <span style={{ backgroundColor: columnOptions.checkbox.backgroundColor }}>
              <img src={columnOptions.checkbox.icon} width="12px" alt="" />
            </span>Multiple Choice
          </li>
          <li>
            <span style={{ backgroundColor: columnOptions.checkbox.backgroundColor }}>
              <img src={columnOptions.imageChoice.icon} width="12px" alt="" />
            </span>Image Choice
          </li>
          <li>
            <span style={{ backgroundColor: columnOptions.scale.backgroundColor }}>
              <img src={columnOptions.scale.icon} width="12px" alt="" />
            </span>Scale
          </li>
        </ul>
      </div>}

      {fieldSelectOptions.length > 0 && <>
        <ul className={styles.options}>
          {fieldConditions && fieldConditions.map((condition, index) => {
            const selectedTriggerField = fields.find((field) => field._id === condition.triggerField);
            let selectedTriggerFieldOptions = selectedTriggerField ? selectedTriggerField.options : [];

            if (selectedTriggerField && selectedTriggerField.type === 'scale') {
              selectedTriggerFieldOptions = _range(selectedTriggerField.scaleRange[0], selectedTriggerField.scaleRange[1] + 1).map((option) => {
                return {
                  ref: option,
                  value: option
                };
              });
            }

            return <li key={index}>
              <Select width="calc(45% - 10px)" options={fieldSelectOptions.map((f) => {
                return { label: fieldOptionLabel(f, columnOptions, values) || f.placeholder, value: f._id };
              })} value={{ label: !selectedTriggerField ? '- Select -' : fieldOptionLabel(selectedTriggerField, columnOptions, values), value: condition.triggerField }}
                onChange={(selected) => updateFieldCondition({
                  index,
                  key: 'triggerField',
                  value: selected.value
                })} />

              <Select width="calc(15% - 10px)" options={typeOptions} value={
                { label: typeOptions.find((option) => option.value === condition.type).label, value: condition.type }
              } onChange={(selected) => updateFieldCondition({
                index,
                key: 'type',
                value: selected.value
              })} disabled={!selectedTriggerField} />

              <Select width="calc(40% - 20px)" disabled={!selectedTriggerField} options={!selectedTriggerField ? [] : selectedTriggerFieldOptions.map((option) => {
                return { label: fieldValueLabel(option, selectedTriggerField), value: option.ref };
              })} value={{ label: !selectedTriggerField ? '- Select -' : fieldValueLabel(selectedTriggerFieldOptions.find((f) => f.ref === condition.value), selectedTriggerField), value: condition.value }}
                onChange={(selected) => updateFieldCondition({
                  index,
                  key: 'value',
                  value: selected.value
                })} />

              <RemoveIcon style={{ margin: '0 0 0 10px' }} onClick={() => removeFieldCondition(index)} className={alwaysOne && totalLength <= 1 ? styles.disabled : ''} />
            </li>;
          })}

          {calculationConditions && calculationConditions.map((condition, index) => {
            return <li key={index}>
              <Select width="calc(35% - 10px)" options={calculationVariables.map((f) => {
                return { label: f.name, value: f._id };
              })} value={{ label: (calculationVariables.find((option) => option._id === condition.variableA) || { name: '- Select -' }).name, value: condition.variableA }}
                onChange={(selected) => updateCalculationsCondition({
                  index,
                  key: 'variableA',
                  value: selected.value
                })} />

              <Select width="calc(14% - 10px)" options={calculationTypeOptions} value={
                { label: calculationTypeOptions.find((option) => option.value === condition.type).label, value: condition.type }
              } onChange={(selected) => updateCalculationsCondition({
                index,
                key: 'type',
                value: selected.value
              })} disabled={!condition.variableA} />

              <Select width="calc(16% - 10px)" options={conditionsCompareOptions} value={
                { label: conditionsCompareOptions.find((option) => option.value === condition.compare).label, value: condition.compare }
              } onChange={(selected) => updateCalculationsCondition({
                index,
                key: 'compare',
                value: selected.value
              })} disabled={!condition.variableA} />

              {condition.compare === 'variable' && <Select width="calc(35% - 10px)" options={calculationVariables.filter((f) => f._id !== condition.variableA).map((f) => {
                return { label: f.name, value: f._id };
              })} value={{ label: (calculationVariables.find((option) => option._id === condition.variableB) || { name: '- Select -' }).name, value: condition.variableB }}
                onChange={(selected) => updateCalculationsCondition({
                  index,
                  key: 'variableB',
                  value: selected.value
                })} disabled={!condition.variableA} />}

              {condition.compare === 'number' && <TextInput type="number" step="0.001" width="calc(35% - 10px)" value={condition.value} onChange={(e) => updateCalculationsCondition({
                index,
                key: 'value',
                value: parseFloat(e.target.value)
              })} disabled={!condition.variableA} />}

              <RemoveIcon style={{ margin: '0 0 0 10px' }} onClick={() => removeCalculationsCondition(index)} className={alwaysOne && totalLength <= 1 ? styles.disabled : ''} />
            </li>;
          })}
        </ul>

        <div className={styles.buttons}>
          <Button theme="white" onClick={addEmptyFieldRule} margin="0 5px 0 0">Add new field rule</Button>
          <Button theme="white" onClick={addEmptyCalculationsRule}>Add new calculation rule</Button>
        </div>
      </>}
    </div>
  </div>;
}

export default Logic;
