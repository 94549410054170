/** @jsx jsx */
/* @jsxFrag React.Fragment */

import { jsx, css } from '@emotion/core';
import React, { useState } from 'react';
import Translate from '../messages/Translate.js';

const ProgressBar = ({ messages, theme, fields, formType, activePageNumber, activeFieldIndex, totalPages, device, placement, mode, scrollbarWidth, userUiSettings, values }) => {
  const translate = new Translate(messages);
  const fieldsCopy = fields.filter((field) => field.section === 'root' || !field.section);

  let activeIndex = activeFieldIndex;
  const totalFields = Object.keys(values).filter((key) => (values[key].section === 'root'  || !values[key].section) && values[key].visible && !values[key].hidden).length;

  if (formType === 'classic' && totalPages === 1) return <></>;

  if (formType === 'conversational') {
    for (let field of fieldsCopy) {
      if (values[field._id] && !values[field._id].visible && fieldsCopy[activeFieldIndex] && field.position < fieldsCopy[activeFieldIndex].position) {
        activeIndex -= 1;
      }
    }
  
    if (fieldsCopy.length === activeFieldIndex) {
      activeIndex = totalFields;
    }
  }

  const width = formType === 'conversational' ? activeIndex / totalFields * 100 : activePageNumber / totalPages * 100;

  const styles = {
    main: css({
      width: '100%',
      height: `${theme.progressBarHeight}px`,
      position: 'fixed',
      top: 0,
      right: 0,
      zIndex: 1024,
      background: theme.progressBarBackgroundColor,
      boxSizing: 'border-box',
      '&': (() => {
        const obj = {};

        if (mode === 'preview') {
          obj.right = 'auto';

          if (placement === 'themes') {
            obj.maxWidth = `calc(100% - 405px - ${scrollbarWidth}px)`;
          }

          if (placement === 'builder') {
            obj.maxWidth = !userUiSettings.minimizeBuilderMenu ? `calc(100% - 490px - ${scrollbarWidth}px)` : `calc(100% - 405px - ${scrollbarWidth}px)`;
          }

          if (device === 'phone') {
            obj.maxWidth = 400 - scrollbarWidth;
          }

          if (device === 'tablet') {
            obj.maxWidth = 800 - scrollbarWidth;
          }
        }

        return obj;
      })()
    }),
    bar: css({
      width: `${width}%`,
      transition: 'width 0.9s ease',
      height: `${theme.progressBarHeight}px`,
      background: theme.progressBarColor
    }),
    textParent: css({
      boxSizing: 'border-box',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-start',
      justifyContent: 'flex-end',
      position: 'fixed',
      zIndex: 1024,
      top: 0,
      right: 0,
      width: '100%',
      '&': (() => {
        const obj = {};

        if (mode === 'preview') {
          obj.right = 'auto';

          if (placement === 'themes') {
            obj.maxWidth = `calc(100% - 405px - ${scrollbarWidth}px)`;
          }

          if (placement === 'builder') {
            obj.maxWidth = !userUiSettings.minimizeBuilderMenu ? `calc(100% - 490px - ${scrollbarWidth}px)` : `calc(100% - 405px - ${scrollbarWidth}px)`;
          }

          if (device === 'phone') {
            obj.maxWidth = 400 - scrollbarWidth;
          }

          if (device === 'tablet') {
            obj.maxWidth = 800 - scrollbarWidth;
          }
        }

        return obj;
      })()
    }),
    text: css({
      padding: `${theme.progressBarBadgeHorizontalPadding}px ${theme.progressBarBadgeVerticalPadding}px`,
      position: 'fixed',
      fontFamily: 'Roboto',
      lineHeight: '142%',
      fontWeight: theme.progressBarBadgeWeight,
      fontSize: `${theme.progressBarBadgeFontSize}px`,
      top: `${theme.progressBarHeight + 5}px`,
      right: '5px',
      zIndex: 1024,
      background: theme.progressBarBadgeBackgroundColor,
      color: theme.progressBarBadgeColor,
      borderRadius: `${theme.progressBarBadgeRoundness}px`,
      border: `${theme.progressBarBadgeBorderSize}px solid ${theme.progressBarBadgeBorderColor}`,
      '&': (() => {
        const obj = {};

        if (mode === 'preview') {
          obj.position = 'relative';
        }

        return obj;
      })()
    })
  };

  const TextParent = ({ children }) => {
    if (mode === 'preview') return <div css={styles.textParent}>{children}</div>;

    return <>{children}</>;
  };

  return <>
    <div css={styles.main}>
      <div css={styles.bar} />
    </div>

    <TextParent>
      <div css={styles.text}>
        {formType === 'conversational' && translate.text('progressBarConversational', {
          currentField: { value: activeIndex || '0' },
          totalFields: { value: totalFields }
        })}
        {formType === 'classic' && translate.text('progressBarTraditional', {
          currentPage: { value: activePageNumber },
          totalPages: { value: totalPages }
        })}
      </div>
    </TextParent>
  </>;
};

export default ProgressBar;
