import React, { Component } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import {
  verifyEmail
} from 'store/ducks/user.js';

import { Loader } from 'ui';

class EmailVerification extends Component {
  constructor(props) {
    super(props);

    this.props.verifyEmail(props.match.params.code).then(() => {
      window.location.replace('https://admin.questionscout.com');
    }, () => window.location.replace('https://admin.questionscout.com')).catch(() => {
      window.location.replace('https://admin.questionscout.com');
    });
  }

  render() {
    return <Loader size={40} />;
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    verifyEmail: (code) => dispatch(verifyEmail(code))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(EmailVerification));
