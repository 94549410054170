import styles from './Login.module.css';

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Loader } from 'ui';

import {
  getUser, loginOnetool
} from 'store/ducks/_users.js';

let gettingUser = false;

const Login = ({ match }) => {
  const dispatch = useDispatch();

  const success = useSelector(state => state._users.loginOnetool.success);

  useEffect(() => {
    dispatch(loginOnetool(match.params.token));
  }, []);

  if (success && !gettingUser) {
    dispatch(getUser());

    gettingUser = true;
  }

  return (
    <div className={styles.main}>
      <Loader size={50} />
    </div>
  );
}

export default Login;
