import styles from './InputBox.module.css';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
  updateEditedFieldRef, updateFields, ioRemoveField, ioCopyFieldFile, updateField, ioUpdateField
} from 'store/ducks/builder.js';

import { ReactComponent as EditIcon } from 'assets/images/edit.svg';
import { ReactComponent as TrashIcon } from 'assets/images/trash.svg';
import { ReactComponent as CopyIcon } from 'assets/images/copy.svg';
import { ReactComponent as DragIcon } from 'assets/images/drag.svg';

import { ShortText, LongText, Dropdown, Radio, Checkbox, Divider, Title, Submit, Description, Datetime, FileUpload, Signature, PageBreak, Image, Scale, ImageChoice, Section } from './../../Fields.js';

class InputBox extends Component {
  removeField = (ref) => {
    const fields = this.props.fields;
    const deletedIndex = fields.findIndex((field) => field.ref === ref);

    if (typeof deletedIndex === 'undefined') return;

    fields.filter((field) => fields[deletedIndex].section === field.section).map((field) => {
      if (field.position >= fields[deletedIndex].position && fields[deletedIndex].ref !== field.ref) {
        field.position -= 1;
      }

      return field;
    });

    if (deletedIndex >= 0) {
      fields.splice(deletedIndex, 1);
    }

    this.props.updateEditedFieldRef(null);
    this.props.updateFields(fields);
    this.props.ioRemoveField(ref);
  }

  editField = (ref, type) => {
    const allowes = this.props.mode === 'logic' ? ['divider', 'submit'] : ['divider', 'pageBreak', 'submit'];

    if (this.props.selectedEmptyBox || allowes.indexOf(type) !== -1) {
      return;
    }

    this.props.updateEditedFieldRef(this.props.editedFieldRef === ref ? null : ref);
  }

  render() {
    const {
      index, field, values, DragHandle,
      form, selectedEmptyBox, inputBoxAnimation, draggedFieldRef, selectedFieldOption, editedFieldRef, fields, children
    } = this.props;

    let fieldsOptions = {};

    for (let f of fields) fieldsOptions[f._id] = f.options || [];

    return (
      <>
        <div style={{
          transform: selectedEmptyBox !== null && field.section === 'root' && selectedEmptyBox < index ? `translateY(${14 + (selectedFieldOption ? selectedFieldOption.height : 80)}px)` : 'none',
        }} className={[
          styles.inputBox,
          (this.props.mode === 'logic' ? ['divider', 'submit'] : ['divider', 'pageBreak', 'submit']).indexOf(field.type) !== -1 ? styles.hoverDisabled : '',
          field.type === 'submit' ? styles.submitBox : '',
          selectedEmptyBox !== null ? styles.selectonActive : '',
          inputBoxAnimation ? styles.animation : '',
          draggedFieldRef && draggedFieldRef !== field.ref ? styles.dragging : '',
          draggedFieldRef ? styles.draggingActive : '',
          editedFieldRef ? styles.editingActive : '',
          editedFieldRef === field.ref ? styles.edited : '',
        ].join(' ')}>
          <div className={styles.field} onClick={() => this.editField(field.ref, field.type)}>
            {field && field.type === 'shortText' && <ShortText editedFieldRef={editedFieldRef} field={field} values={values} fieldsOptions={fieldsOptions} />}
            {field && field.type === 'longText' && <LongText editedFieldRef={editedFieldRef} field={field} values={values} fieldsOptions={fieldsOptions} />}
            {field && field.type === 'dropdown' && <Dropdown editedFieldRef={editedFieldRef} field={field} values={values} fieldsOptions={fieldsOptions} />}
            {field && field.type === 'radio' && <Radio editedFieldRef={editedFieldRef} field={field} values={values} fieldsOptions={fieldsOptions} />}
            {field && field.type === 'checkbox' && <Checkbox editedFieldRef={editedFieldRef} field={field} values={values} fieldsOptions={fieldsOptions} />}
            {field && field.type === 'divider' && <Divider editedFieldRef={editedFieldRef} field={field} values={values} fieldsOptions={fieldsOptions} />}
            {field && field.type === 'title' && <Title editedFieldRef={editedFieldRef} field={field} values={values} fieldsOptions={fieldsOptions} />}
            {field && field.type === 'description' && <Description editedFieldRef={editedFieldRef} field={field} values={values} fieldsOptions={fieldsOptions} />}
            {field && field.type === 'datetime' && <Datetime editedFieldRef={editedFieldRef} field={field} values={values} fieldsOptions={fieldsOptions} />}
            {field && field.type === 'fileUpload' && <FileUpload editedFieldRef={editedFieldRef} field={field} values={values} fieldsOptions={fieldsOptions} />}
            {field && field.type === 'signature' && <Signature editedFieldRef={editedFieldRef} field={field} values={values} fieldsOptions={fieldsOptions} />}
            {field && field.type === 'image' && <Image editedFieldRef={editedFieldRef} field={field} values={values} fieldsOptions={fieldsOptions} />}
            {field && field.type === 'scale' && <Scale editedFieldRef={editedFieldRef} field={field} values={values} fieldsOptions={fieldsOptions} />}
            {field && field.type === 'imageChoice' && <ImageChoice editedFieldRef={editedFieldRef} field={field} values={values} fieldsOptions={fieldsOptions} />}
            {field && field.type === 'pageBreak' && <PageBreak editedFieldRef={editedFieldRef} field={field} fields={fields} form={form} values={values} selectedEmptyBox={selectedEmptyBox} selectedFieldOption={selectedFieldOption} />}
            {field && field.type === 'section' && <Section editedFieldRef={editedFieldRef} field={field} values={values} fieldsOptions={fieldsOptions}>{children}</Section>}

            {field && field.type === 'submit' && <Submit editedFieldRef={editedFieldRef} form={form} fields={fields} selectedEmptyBox={selectedEmptyBox} selectedFieldOption={selectedFieldOption} />}
          </div>

          {field.type !== 'submit' && <div ref="actions" className={[
            styles.actions,
            selectedEmptyBox !== null ? styles.selectonActive : ''
          ].join(' ')}>
            {['divider', 'pageBreak'].indexOf(field.type) === -1 && <div className={styles.edit}><EditIcon onClick={() => this.editField(field.ref, field.type)} /></div>}
            <div className={styles.copy}><CopyIcon  onClick={() => this.props.onCopyField(field.ref)} /></div>
            <div className={styles.delete}><TrashIcon onClick={() => this.removeField(field.ref)} /></div>
            {DragHandle && <DragHandle className={styles.drag}><div><DragIcon /></div></DragHandle>}
          </div>}
        </div>

        {field && field.type === 'pageBreak' && <>
          <div style={{
            transform: selectedEmptyBox !== null && field.section === 'root' && selectedEmptyBox < index ? `translate(0, ${14 + (selectedFieldOption ? selectedFieldOption.height : 80)}px)` : 'translate(0, 0)',
          }} className={[
            styles.pageBreakHr,
            selectedEmptyBox !== null ? styles.selectonActive : '',
            editedFieldRef ? styles.editingActive : '',
            editedFieldRef === field.ref ? styles.edited : ''
          ].join(' ')}></div>
          <div style={{
            transform: selectedEmptyBox !== null && field.section === 'root' && selectedEmptyBox < index ? `translate(0, ${14 + (selectedFieldOption ? selectedFieldOption.height : 80)}px)` : 'none',
          }} className={[
            styles.pageBreakText,
            selectedEmptyBox !== null ? styles.selectonActive : '',
            editedFieldRef ? styles.editingActive : '',
            editedFieldRef === field.ref ? styles.edited : ''
          ].join(' ')}><span>PAGE BREAK</span></div>
        </>}
      </>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    fields: state.builder.fields,
    form: state.builder.form,
    values: state.builder.values,
    selectedEmptyBox: state.builder.selectedEmptyBox,
    inputBoxAnimation: state.builder.inputBoxAnimation,
    draggedFieldRef: state.builder.draggedFieldRef,
    selectedFieldOption: state.builder.selectedFieldOption,
    editedFieldRef: state.builder.editedFieldRef
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateEditedFieldRef: (value) => dispatch(updateEditedFieldRef(value)),
    updateFields: (value) => dispatch(updateFields(value)),
    ioRemoveField: (ref) => dispatch(ioRemoveField(ref)),
    ioCopyFieldFile: (props) => dispatch(ioCopyFieldFile(props)),
    updateField: (ref, params) => dispatch(updateField(ref, params)),
    ioUpdateField: (ref, params) => dispatch(ioUpdateField(ref, params))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(InputBox);

