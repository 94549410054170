import styles from './DeleteWorkspace.module.css';

import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router';

import {
  hideDeleteWorkspaceModal
} from 'store/ducks/dashboard.js';

import {
  deleteWorkspace, resetEndpoitState
} from 'store/ducks/_workspaces.js';

import {
  getForms
} from 'store/ducks/_forms.js';

import { Button, Modal, Radio, Select, Loader } from 'ui';

const DeleteWorkspace = () => {
  const dispatch = useDispatch();

  const workspace = useSelector(state => state.dashboard.deleteWorkspaceModal.workspace);
  const workspaces = [{
    label: 'Uncategorized', value: null
  }, ...useSelector(state => state._workspaces.workspaces).filter((item) => item._id !== workspace).map((item) => { return { label: item.name, value: item._id } })];
  const formsNumber = useSelector(state => state._forms.forms).filter((form) => form.workspace === workspace).length;
  const show = useSelector(state => state.dashboard.deleteWorkspaceModal._show);
  const loading = useSelector(state => state._workspaces.deleteWorkspace.loading);
  const success = useSelector(state => state._workspaces.deleteWorkspace.success);
  const error = useSelector(state => state._workspaces.deleteWorkspace.error);
  const getFormsSuccess = useSelector(state => state._forms.getForms.success);
  const getFormsLoading = useSelector(state => state._forms.getForms.loading);

  const [formsWorkspaceToMove, setFormsWorkspaceToMove] = useState(null);
  const [formsChoice, setFormsChoice] = useState('move');

  const cancel = () => {
    dispatch(resetEndpoitState('deleteWorkspace'));
    dispatch(hideDeleteWorkspaceModal());

    setFormsWorkspaceToMove(null);
    setFormsChoice('move');
  }

  const submit = () => {
    dispatch(deleteWorkspace(workspace, formsChoice, formsWorkspaceToMove)).then(() => {
      dispatch(getForms());
    });
  }

  if ((getFormsSuccess && success) || error) {
    cancel();
  }

  return (
    <>
      <Modal isOpen={show} onRequestClose={cancel} width={340} header="Deleting Workspace" footer={<>
        <Button size="small" theme="white" onClick={cancel} margin="0 15px 0 0">Cancel</Button>
        <Button size="small" theme="red" onClick={submit} disabled={loading || getFormsLoading}>Delete Workspace</Button>
      </>}>
        {formsNumber === 0 && <div className={styles.content}>
          {(loading || getFormsLoading) && <Loader size={30} />}

          <p>Are you sure you want to delete this workspace? </p>
        </div>}

        {formsNumber > 0 && <div className={styles.content}>
          {(loading || getFormsLoading) && <Loader size={30} />}

          <p>There are {formsNumber} forms inside of this Workspace.</p>

          <hr />

          <Radio value={formsChoice === 'move'} label="I want to move forms to a different Workspace" onClick={() => setFormsChoice('move')} margin="0 0 5px 0" />
          {formsChoice === 'move' && <Select width={340} options={workspaces} maxHeight={200} margin="0 0 10px 0" value={
            { label: workspaces.find((option) => option.value === formsWorkspaceToMove).label, value: formsWorkspaceToMove }
          } onChange={(selected) => setFormsWorkspaceToMove(selected.value)} />}
          <Radio value={formsChoice === 'delete'} label="I want to delete all forms in this Workspace" onClick={() => setFormsChoice('delete')} />
        </div>}
      </Modal>
    </>
  );
};

export default withRouter(DeleteWorkspace);
