import styles from './../Edit.module.css';
import editStyles from 'components/Builder/Edit.module.css';

import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';

import { MentionsInput, TextInput, TextArea, Toggle, Select } from 'ui';

import { ReactComponent as ReferToIcon } from 'assets/images/refer-to.svg';

class Signature extends Component {
  render() {
    const {
      field, fields, handleChange, mentionsData
    } = this.props;

    // this.emailFields = this.props.fields.filter((field) => field.type === 'shortText' && field.format === 'email').map((field) => {
    //   return { label: field.label || field.placeholder || field.type, value: field._id };
    // });

    // this.selectedEmailField = this.emailFields.find((option) => option.value === this.props.field.signatureEmailValidationField) || { label: '- Select -', value: null };

    return (
      <>

        <div className={editStyles.row}>
          <label>
            <div>Field Title</div>
            <a href="https://support.questionscout.com/en/articles/3947231-how-to-use-refer-to" target="_blank" rel="noopener noreferrer"><ReferToIcon data-tip="Refer to is available on this input, click to learn more." /></a>
          </label>
          <MentionsInput menu="tooltip"
            initialValue={field.label}
            onChange={(value) => handleChange({ label: value })}
            data={mentionsData}
          />
        </div>

        <div className={editStyles.row}>
          <label>
            <div>Supporting Text</div>
            <a href="https://support.questionscout.com/en/articles/3947231-how-to-use-refer-to" target="_blank" rel="noopener noreferrer"><ReferToIcon data-tip="Refer to is available on this input, click to learn more." /></a>
          </label>
          <MentionsInput menu="tooltip"
            initialValue={field.description}
            onChange={(value) => handleChange({ description: value })}
            data={mentionsData}
          />
        </div>

        <div className={[editStyles.row].join(' ')}>
          <div className={editStyles.toggle50} onClick={() => handleChange({ required: !field.required }, false)}>
            <Toggle checked={field.required} readOnly={true} /> <span>Required</span>
          </div>
          <div style={{ clear: 'both' }}></div>
        </div>

        <div className={editStyles.hr}></div>

        {/* <div className={editStyles.row}>
          <div className={editStyles.toggle100} onClick={() => handleChange({ signatureEmailValidation: !field.signatureEmailValidation, signatureEmailValidationField: null }, false)}>
            <Toggle checked={field.signatureEmailValidation} readOnly={true} /> <span>Validate signature via an Short Answer field with email format</span>
            <Question width={20} data-tip={'The submitter will have to verify their signature through an automated email after submitting the form.'} />
          </div>
        </div>

        {field.signatureEmailValidation && <div className={editStyles.row}>
          <label>Email Short Answer field</label>
          <Select width={294} options={this.emailFields} value={{
            label: this.selectedEmailField.label || this.selectedEmailField.placeholder || this.selectedEmailField.type,
            value: field.signatureEmailValidationField
          }} onChange={(selected) => handleChange({ signatureEmailValidationField: selected.value }, false)} />
        </div>} */}

        <div className={editStyles.row}>
          <div className={editStyles.toggle100} onClick={() => handleChange({ signatureAllowTyping: !field.signatureAllowTyping }, false)}>
            <Toggle checked={field.signatureAllowTyping} readOnly={true} /> <span>Allow Typing</span>
          </div>
        </div>

        <ReactTooltip place="right" effect="solid" />
      </>
    );
  };
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Signature);
