import styles from './Menu.module.css';

import React from 'react';

import {
  TitleField, SizeWeightAndColorField, CheckboxField, SizeWithColorField,
  SizeWithTypeField, SizeField, SelectField, ColorField, GradientField,
  UploadBackgroundField, UnsplashBackgroundField, SliderField, ToggleField,
  UrlBackgroundField
} from './../ThemeMenuFields.js';

const layoutOptions = [
  { label: 'Initial', value: 'initial' },
  { label: 'Cover', value: 'cover' },
  { label: 'Contain', value: 'contain' }
];

const sourceOptions = [
  { label: 'Unsplash', value: 'unsplash' },
  { label: 'Upload', value: 'upload' },
  { label: 'Url', value: 'url' }
];

const positionOptions = [
  { label: 'Left', value: 'left' },
  { label: 'Right', value: 'right' },
  { label: 'Center', value: 'center' },
  { label: 'Top', value: 'top' },
  { label: 'Bottom', value: 'bottom' },
];

const Background = ({ settings, colors, images, onChange, onUnsplashChange, onUrlChange, onBackgroundUpload, onRemoveBackgroundlick }) => {
  return (
    <>
      <ColorField title="Background Color"
        settings={settings}
        colors={colors}
        onChange={onChange}
        margin="25px 0 0 0"
        color="backgroundColor" />

      <ToggleField label="Use background image"
        settings={settings}
        onChange={onChange}
        margin="25px 0 0 0"
        value="backgroundUseImage" />

      {settings.backgroundUseImage && <>
        <SelectField title="Background image source"
          settings={settings}
          onChange={onChange}
          options={sourceOptions}
          margin="10px 0 0 0"
          value="backgroundImageSource" />

        {settings.backgroundImageSource === 'upload' && <UploadBackgroundField title="Upload background image"
          settings={settings}
          colors={colors}
          onBackgroundUpload={onBackgroundUpload}
          onRemoveBackgroundlick={onRemoveBackgroundlick}
          images={images}
          margin="25px 0 0 0" />}

        {settings.backgroundImageSource === 'unsplash' && <UnsplashBackgroundField title="Unsplash background image"
          settings={settings}
          colors={colors}
          onChange={onUnsplashChange}
          images={images}
          margin="25px 0 0 0" />}

        {settings.backgroundImageSource === 'url' && <UrlBackgroundField title="Background image url"
          settings={settings}
          colors={colors}
          onChange={onUrlChange}
          images={images}
          margin="25px 0 0 0" />}

        <SelectField title="Image layout"
          settings={settings}
          onChange={onChange}
          options={layoutOptions}
          margin="25px 0 0 0"
          value="backgroundImageLayout" />

        <SelectField title="Background image position"
          settings={settings}
          onChange={onChange}
          options={positionOptions}
          margin="10px 0 0 0"
          value="backgroundImagePosition" />

        <SliderField title="Background image blur"
          settings={settings}
          options={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20]}
          onChange={onChange}
          margin="25px 0 0 0"
          value="backgroundImageBlur" />

        <SliderField title="Background image opacity"
          settings={settings}
          options={[0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1]}
          onChange={onChange}
          margin="25px 0 0 0"
          value="backgroundImageOpacity" />
      </>}
    </>
  );
}

export default Background;
