import styles from './BillingUpdate.module.css';

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Elements } from 'react-stripe-elements';

import Header from 'components/Header/Header.js';

import BillingUpdatePaymentMethod from './BillingUpdatePaymentMethod.js';
import BillingUpdatePlan from './BillingUpdatePlan.js';

import {
  updatePlan, clear
} from 'store/ducks/user.js';

class BillingUpdate extends Component {
  render() {
    const {
      user
    } = this.props;

    let {
      action
    } = this.props.match.params;

    if (user && !user.hasSubscription) return <Redirect to="/user/billing" />;

    return (
      <div className={styles.main}>
        <Header showAccount={false} center={true} showMenu={false} />

        <div className={styles.content}>
          {action === 'paymentmethod' && <div className={styles.headerText}>Let’s change your QuestionScout <span>Pro</span> payment information</div>}
          {action === 'plan' && <div className={styles.headerText}>Let’s update your QuestionScout <span>Pro</span> plan</div>}

          <div className={styles.box}>
            {action === 'paymentmethod' && <Elements locale="en"><BillingUpdatePaymentMethod /></Elements>}
            {action === 'plan' && <BillingUpdatePlan />}
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    user: state._users.user
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updatePlan: (key, value) => dispatch(updatePlan(key, value)),
    clear: () => dispatch(clear())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BillingUpdate);
