/** @jsx jsx */
/* @jsxFrag React.Fragment */

import React from 'react';
import { jsx, css } from '@emotion/core';
import Translate from '../messages/Translate.js';

import { ReactComponent as LinkArrowIcon } from 'assets/images/link-arrow.svg';
import { ReactComponent as ReturnIcon } from 'assets/images/keyboard-return.svg';

const alignOptions = {
  left: 'flex-start',
  center: 'center',
  right: 'flex-end'
};

const Backup = ({ inIframe, messages, hideBranding, onRestoreBackup, onClearBackup, theme }) => {
  const translate = new Translate(messages);

  const styles = {
    main: css({
      width: '100%',
      maxWidth: '600px',
      margin: '50px auto 0 auto',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: alignOptions[theme.thankYouScreenAlignment],
      fontFamily: theme.typographyContentFontFamily
    }),
    content: css({
      width: '100%',
      maxWidth: `${theme.thankYouScreenWidth}${theme.thankYouScreenWidthType}`
    }),
    message: css({
      backgroundColor: theme.thankYouScreenBackground,
      borderRadius: `${theme.thankYouScreenRoundness}px`,
      boxShadow: theme.thankYouScreenShadow ? `${theme.thankYouScreenShadowOffsetX}px ${theme.thankYouScreenShadowOffsetY}px ${theme.thankYouScreenShadowBlur}px ${theme.thankYouScreenShadowSpread}px ${theme.thankYouScreenShadowColor}` : 'none',
      border: `${theme.thankYouScreenBorderSize}px solid ${theme.thankYouScreenBorderColor}`,
      width: '100%',
      padding: '30px 20px 0 20px',
      boxSizing: 'border-box',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      '.title': {
        color: theme.thankYouScreenTextColor,
        fontSize: `${theme.thankYouScreenTextFontSize}px`,
        fontWeight: theme.thankYouScreenTextWeight,
        lineHeight: '142%',
        textAlign: theme.thankYouScreenTextAlignment,
        display: 'block',
        marginTop: '25px',
        width: '100%',
      },
      '.subTitle': {
        color: theme.thankYouScreenTextColor,
        fontSize: `${theme.thankYouScreenTextFontSize - 4}px`,
        fontWeight: theme.thankYouScreenTextWeight,
        lineHeight: '142%',
        textAlign: theme.thankYouScreenTextAlignment,
        display: 'block',
        marginTop: '15px',
        width: '100%',
      }
    }),
    checkmark: css({
      display: 'block',
      'path': {
        fill: theme.thankYouScreenIconColor
      }
    }),
    actions: css({
      width: '100%',
      margin: '20px 0 20px 0',
      boxSizing: 'border-box',
      textAlign: 'center',
      display: 'flex',
      boxSizing: 'border-box',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      'div': {
        color: theme.thankYouScreenLinksColor,
        fontSize: '18px',
        fontWeight: '500',
        lineHeight: '142%',
        cursor: 'pointer',
        textAlign: 'center',
        margin: '10px 0 0 0',
        display: 'inline-block',
        border: `1px solid ${theme.thankYouScreenLinksColor}`,
        padding: '3px 15px',
        borderRadius: '3px'
      },
      'span': {
        color: theme.thankYouScreenTextColor,
        fontSize: '14px',
        fontWeight: '400',
        lineHeight: '142%',
        textAlign: 'center',
        cursor: 'pointer',
        margin: '15px 0 0 0',
        display: 'inline-block',
        textDecoration: 'underline'
      }
    }),
    submittedLink: css({
      backgroundColor: theme.thankYouScreenBackground,
      borderRadius: `${theme.thankYouScreenRoundness}px`,
      boxShadow: theme.thankYouScreenShadow ? `${theme.thankYouScreenShadowOffsetX}px ${theme.thankYouScreenShadowOffsetY}px ${theme.thankYouScreenShadowBlur}px ${theme.thankYouScreenShadowSpread}px ${theme.thankYouScreenShadowColor}` : 'none',
      border: `${theme.thankYouScreenBorderSize}px solid ${theme.thankYouScreenBorderColor}`,
      width: '100%',
      padding: '15px 20px',
      boxSizing: 'border-box',
      marginTop: '20px',
      'span': {
        color: theme.thankYouScreenTextColor,
        fontSize: '14px',
        fontWeight: '400',
        lineHeight: '142%',
        textAlign: 'center',
        display: 'block'
      },
      'a': {
        display: 'block',
        color: theme.thankYouScreenLinksColor,
        fontSize: '13px',
        fontWeight: '500',
        lineHeight: '142%',
        textAlign: 'center',
        marginTop: '5px',
        WebkitTapHighlightColor: 'rgba(0, 0, 0, 0)',
        WebkitTapHighlightColor: 'transparent'
      },
      'a svg': {
        marginLeft: '2px'
      },
      'a svg *': {
        fill: theme.thankYouScreenLinksColor
      }
    })
  };

  return <>
    <div css={styles.main} className="qs_backup">
      <div css={styles.content}>
        <div css={styles.message}>
          <ReturnIcon css={styles.checkmark} />
          <div className="title" >{translate.text('backupTitle')}</div>
          <div className="subTitle">{translate.text('backupDescription')}</div>

          <div css={styles.actions}>
            <div onClick={onRestoreBackup}>{translate.text('backupContinueBtn')}</div>
            <span onClick={onClearBackup}>{translate.text('backupResetBtn')}</span>
          </div>
        </div>

        {!hideBranding && <div css={styles.submittedLink}>
          <span>This form was built for free using QuestionScout</span>
          <a href="https://questionscout.com" target="_blank" rel="noopener noreferrer">Start building your own form now <LinkArrowIcon /></a>
        </div>}
      </div>
    </div>
  </>;
};

export default Backup;
