import styles from './Changelog.module.css';

import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';
import { connect, useSelector, useDispatch } from 'react-redux';

import { Button, Modal, Checkbox } from 'ui';

import { ReactComponent as LinkIcon } from 'assets/images/link.svg';

import {
  updateUser
} from 'store/ducks/_users.js';

const badges = {
  newFeature: 'NEW FEATURE',
  upgrade: 'UPGRADE',
  change: 'CHANGE'
};

const colors = {
  newFeature: '#2B41D8',
  upgrade: '#0D9D9D',
  change: '#E7AE18'
};

const Changelog = ({ data }) => {
  const dispatch = useDispatch();

  const [show, setShow] = useState(true);
  const [disabled, setDisabled] = useState(false);

  const cancel = () => setShow(false);
  const disable = (value) => {
    setDisabled(value);

    dispatch(updateUser({
      disableChangelog: value
    }, {
      updateLocal: false,
      updateApi: true
    }));
  };

  return <Modal isOpen={show} onRequestClose={cancel} width={520} header="Changes since you’ve last logged in" footer={<div className={styles.footer}>
    <Checkbox label="Don’t show this anymore" value={disabled} onClick={() => disable(!disabled)} />
    <Button size="small" theme="white" onClick={cancel}>Close</Button>
  </div>}>
    <div className={styles.content}>
      <ul>
        {data.map((change) => <li key={change._id}>
          <div className={styles.name}>
            {change.name}
            {change.link && <a href={change.link} target="_blank" rel="noopener noreferrer">
              <LinkIcon />
            </a>}
          </div>

          {change.description && <div className={styles.description}>{change.description}</div>}

          <div className={styles.info}>
            {change.badge ? <div className={styles.badge} style={{ background: colors[change.badge]}}>{badges[change.badge]}</div> : <div />}
            <div className={styles.date}>{format(new Date(change.createdAt), 'dd MMM yyyy')}</div>
          </div>
        </li>)}
      </ul>
    </div>
  </Modal>;
}

export default Changelog;
