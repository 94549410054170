import styles from './Billing.module.css';

import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
  getBilling, getBillingInvoice
} from 'store/ducks/user.js';

import BillingPlan from './BillingPlan.js';
import BillingProfile from './BillingProfile.js';
import BillingHistory from './BillingHistory.js';
import BillingFree from './BillingFree.js';
import BillingLifeTime from './BillingLifeTime.js';

class Billing extends Component {
  UNSAFE_componentWillMount = () => {
    this.props.getBilling();
    this.props.getBillingInvoice();
  }

  render() {
    const {
      user
    } = this.props;

    if (user.authType === 'onetool') return <></>;

    return <>
      {(!user.lifeTime || !user.lifeTime.enabled) && <>
        {!user.hasSubscription && <>
          <BillingFree />
        </>}

        {user.hasSubscription && <>
          <BillingPlan />
          <BillingProfile />
        </>}

        <BillingHistory />
      </>}

      {user.lifeTime && user.lifeTime.enabled && <>
        <BillingLifeTime />
      </>}
    </>
  }
}

const mapStateToProps = (state) => {
  return {
    user: state._users.user
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getBilling: () => dispatch(getBilling()),
    getBillingInvoice: () => dispatch(getBillingInvoice())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Billing);
