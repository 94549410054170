/** @jsx jsx */
/* @jsxFrag React.Fragment */

import { jsx, css, ThemeContext } from '@emotion/core';
import React, { useState } from 'react';

const alignOptions = {
  left: 'flex-start',
  center: 'center',
  right: 'flex-end'
};

const arrowUp = <svg width="11" height="6" xmlns="http://www.w3.org/2000/svg"><path d="M5.509.002c.183 0 .36.07.502.197l4.706 4.284c.333.302.379.843.102 1.207a.74.74 0 01-1.106.112L5.509 1.964l-4.204 3.7a.74.74 0 01-1.106-.128.909.909 0 01-.195-.651.879.879 0 01.304-.6L5.014.149A.735.735 0 015.51.002z" fill="#FFF" fillRule="nonzero" /></svg>;
const arrowDown = <svg width="11" height="6" xmlns="http://www.w3.org/2000/svg"><path d="M5.491 5.998a.745.745 0 01-.502-.197L.283 1.517A.913.913 0 01.181.31.74.74 0 011.287.198l4.204 3.838 4.204-3.7a.74.74 0 011.106.128.909.909 0 01.195.651.879.879 0 01-.304.6L5.986 5.851a.735.735 0 01-.495.146z" fill="#FFF" fillRule="nonzero" /></svg>;

const Footer = ({ inIframe, mode, device, submitted, status, hideBranding, theme, placement, scrollbarWidth, ownerIsPro, userUiSettings, isTouchDevice, form, allowGoUp, allowGoDown, onGoDown, onGoUp }) => {
  const [showUnsplash, setShowUnsplash] = useState(false);
  const [showReportAbuse, setShowReportAbuse] = useState(false);

  const styles = {
    main: css({
      width: '100%',
      position: 'fixed',
      fontFamily: 'Roboto',
      bottom: 0,
      left: 0,
      zIndex: 1024,
      boxSizing: 'border-box',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '0 20px',
      '&': (() => {
        const obj = {};

        if (!ownerIsPro || !hideBranding || (theme.backgroundImage && theme.backgroundUseImage && theme.backgroundImageSource === 'unsplash')) {
          obj.backdropFilter = !inIframe ? 'blur(2px)' : 'none';
          obj.backgroundColor = !inIframe ? theme.displaySettingsFooterBackground : 'transparent';
        }

        if (mode === 'preview') {
          obj.top = 'auto';
          obj.left = 'auto';
          obj.right = `${scrollbarWidth}px`;
          obj.bottom = 0;

          if (placement === 'themes') {
            obj.maxWidth = `calc(100% - 405px - ${scrollbarWidth}px)`;
          }

          if (placement === 'builder') {
            obj.maxWidth = !userUiSettings.minimizeBuilderMenu ? `calc(100% - 490px - ${scrollbarWidth}px)` : `calc(100% - 405px - ${scrollbarWidth}px)`;
          }

          if (device === 'phone') {
            obj.maxWidth = 400 - scrollbarWidth;
            obj.right = 'auto';
          }

          if (device === 'tablet') {
            obj.maxWidth = 800 - scrollbarWidth;
            obj.right = 'auto';
          }
        }

        if (inIframe && form.type !== 'conversational') {
          obj.position = 'absolute';
          obj.bottom = 0;
          obj.left = 0;
          obj.justifyContent = alignOptions[theme.displaySettingsFormDisplayAlignment];
        }

        return obj;
      })()
    }),
    left: css({
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      height: '50px'
    }),
    badge: css({
      backgroundColor: 'rgba(0, 0, 0, 0.45)',
      border: '1px solid rgba(255, 255, 255, 0.15)',
      borderRadius: '2px',
      height: '30px',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'relative',
      boxSizing: 'border-box',
      '&:not(:last-child)': {
        margin: '0 10px 0 0'
      },
      'span': {
        lineHeight: '30px',
        color: '#FFFFFF',
        fontSize: '12px',
        fontWeight: '400',
        textAlign: 'center',
        display: 'block',
        margin: '0 15px',
        boxSizing: 'border-box'
      }
    }),
    unsplashAuthorName: css({
      position: 'absolute',
      backgroundColor: 'rgba(0, 0, 0, 0.7)',
      border: '1px solid rgba(255, 255, 255, 0.15)',
      borderRadius: '2px',
      left: '0',
      top: '-30px',
      color: '#FFFFFF',
      fontSize: '12px',
      fontWeight: '400',
      padding: '5px 10px',
      whiteSpace: 'nowrap'
    }),
    reportAbuse: css({
      position: 'absolute',
      backgroundColor: 'rgba(0, 0, 0, 0.7)',
      border: '1px solid rgba(255, 255, 255, 0.15)',
      borderRadius: '2px',
      left: '0',
      top: '-30px',
      color: '#FFFFFF',
      fontSize: '12px',
      fontWeight: '400',
      padding: '5px 10px',
      whiteSpace: 'nowrap'
    }),
    right: css({
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-end',
      height: '50px',
      position: 'absolute',
      right: '20px',
      bottom: '0'
    }),
    up: css({
      backgroundColor: 'rgba(0, 0, 0, 0.45)',
      border: '1px solid rgba(255, 255, 255, 0.15)',
      borderRadius: '2px',
      height: '30px',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'relative',
      boxSizing: 'border-box',
      padding: '0 15px',
      margin: '0 2px 0 0',
      cursor: 'pointer',
      opacity: allowGoUp ? 1 : '0.5',
      pointerEvents: allowGoUp ? 'auto' : 'none',
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.50)',
      },
      '&:active': {
        backgroundColor: 'rgba(0, 0, 0, 0.55)',
      }
    }),
    down: css({
      backgroundColor: 'rgba(0, 0, 0, 0.45)',
      border: '1px solid rgba(255, 255, 255, 0.15)',
      borderRadius: '2px',
      height: '30px',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'relative',
      boxSizing: 'border-box',
      padding: '0 15px',
      cursor: 'pointer',
      opacity: allowGoDown ? 1 : '0.5',
      pointerEvents: allowGoDown ? 'auto' : 'none',
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.50)',
      },
      '&:active': {
        backgroundColor: 'rgba(0, 0, 0, 0.55)',
      }
    })
  };

  return <>
    {!submitted && status !== 'paused' && <div css={styles.main}>
      {(!ownerIsPro) || (theme.backgroundImage && theme.backgroundUseImage && theme.backgroundImageSource === 'unsplash') || (!hideBranding) && <div css={styles.left}>
        {!ownerIsPro && <a css={styles.badge} onMouseOver={() => mode !== 'preview' && setShowReportAbuse(true)} onMouseOut={() => setShowReportAbuse(false)} style={{ width: '30px' }} href={mode === 'preview' ? '#' : `https://form.questionscout.com/5dd8344059951e432b9394d6?form=${window.location.href}`} target={mode === 'preview' ? '' : '_blank'} rel="noopener noreferrer">
          {showReportAbuse && <div css={styles.reportAbuse}>Report Abuse</div>}
          <svg xmlns="http://www.w3.org/2000/svg" width="16px" viewBox="0 0 24 24"><g data-name="Layer 2"><g data-name="flag"><path fill="white" d="M19.27 4.68a1.79 1.79 0 0 0-1.6-.25 7.53 7.53 0 0 1-2.17.28 8.54 8.54 0 0 1-3.13-.78A10.15 10.15 0 0 0 8.5 3c-2.89 0-4 1-4.2 1.14a1 1 0 0 0-.3.72V20a1 1 0 0 0 2 0v-4.3a6.28 6.28 0 0 1 2.5-.41 8.54 8.54 0 0 1 3.13.78 10.15 10.15 0 0 0 3.87.93 7.66 7.66 0 0 0 3.5-.7 1.74 1.74 0 0 0 1-1.55V6.11a1.77 1.77 0 0 0-.73-1.43z" /></g></g></svg>
        </a>}

        {theme.backgroundImage && theme.backgroundUseImage && theme.backgroundImageSource === 'unsplash' && <a style={{ width: '30px' }} onMouseOver={() => mode !== 'preview' && setShowUnsplash(true)} onMouseOut={() => setShowUnsplash(false)} css={styles.badge} href={mode === 'preview' ? '#' : `${theme.backgroundImageAuthorUrl}?utm_source=formsquare&utm_medium=referral`} target={mode === 'preview' ? '' : '_blank'} rel="noopener noreferrer">
          {showUnsplash && <div css={styles.unsplashAuthorName}>Photo by {theme.backgroundImageAuthorName} on Unsplash</div>}
          <svg xmlns="http://www.w3.org/2000/svg" width="16px" viewBox="0 0 512 512"><path fill="white" d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z" /></svg>
        </a>}

        {!hideBranding && <a css={styles.badge} href={mode === 'preview' ? '#' : 'https://questionscout.com?ref=questionscout-form'} target={mode === 'preview' ? '' : '_blank'} rel="noopener noreferrer">
          <span>Powered by <strong>QuestionScout</strong></span>
        </a>}
      </div>}

      {form.type === 'conversational' && <div css={styles.right}>
        <div css={styles.up} onClick={onGoUp}>{arrowUp}</div>
        <div css={styles.down} onClick={onGoDown}>{arrowDown}</div>
      </div>}
    </div>}
  </>;
};

export default Footer;
