/** @jsx jsx */
/* @jsxFrag React.Fragment */
import 'react-intl-tel-input/dist/main.css';

import { jsx, css } from '@emotion/core';
import MaskedInput from 'react-text-mask';
import isEmail from 'validator/lib/isEmail';
import IntlTelInput from 'react-intl-tel-input';
import React, { useState, useEffect } from 'react';
import getFieldLabel from 'helpers/getFieldLabel.js';

import { LabelAtom, DescriptionAtom } from './atoms.js';

const formats = {
  phone: 'tel',
  number: 'number',
  email: 'email'
};

const ShortText = ({ field, theme, value, values, autoFocus, onChange, onBlur, onPartialResponse, country, owner, fieldsOptions, variables }) => {
  const [active, setActive] = useState(false);

  if (!onBlur) onBlur = () => { };

  const alignOptions = {
    left: 'flex-start',
    center: 'center',
    right: 'flex-end'
  };

  const inputContainerStyleTypes = {
    style1: {
      borderBottom: `${theme.inputFieldsIdleBorderSize}px solid ${theme.inputFieldsIdleBorderColor}`,
    },
    style2: {
      border: 'none'
    },
    style3: {
      border: `${theme.inputFieldsIdleBorderSize}px solid ${theme.inputFieldsIdleBorderColor}`,
    },
  };

  const mainStyle = css({
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: alignOptions[theme.inputFieldsDropdownsAlignment],
    margin: `${theme.inputFieldsDropdownsTopMargin || 0}px 0 ${theme.inputFieldsDropdownsBottomMargin || 0}px 0`
  });

  const contentStyle = css({
    width: '100%',
    maxWidth: `${theme.inputFieldsDropdownsWidth}${theme.inputFieldsDropdownsWidthType}`
  });

  const inputContainerStyle = css({
    background: theme.inputFieldsIdleBackground,
    fontFamily: theme.typographyContentFontFamily,
    lineHeight: '142%',
    color: theme.inputFieldsIdleTextColor,
    fontWeight: theme.inputFieldsIdleTextWeight,
    fontSize: theme.inputFieldsIdleTextFontSize,
    borderRadius: `${theme.inputFieldsIdleRoundness}px`,
    boxShadow: theme.inputFieldsIdleShadow ? `${theme.inputFieldsIdleShadowOffsetX}px ${theme.inputFieldsIdleShadowOffsetY}px ${theme.inputFieldsIdleShadowBlur}px ${theme.inputFieldsIdleShadowSpread}px ${theme.inputFieldsIdleShadowColor}` : 'none',
    ...inputContainerStyleTypes[theme.inputFieldsDropdownsType],
    '& input': {
      width: '100%',
      lineHeight: '142%',
      color: theme.inputFieldsIdleTextColor,
      fontWeight: theme.inputFieldsIdleTextWeight,
      fontSize: theme.inputFieldsIdleTextFontSize,
      padding: `${theme.inputFieldsIdleVerticalPadding || 0}px ${theme.inputFieldsIdleHorizontalPadding || 0}px`,
      boxSizing: 'border-box'
    },
    '& input::placeholder': {
      color: theme.inputFieldsIdlePlaceholderColor,
      fontWeight: theme.inputFieldsIdleTextWeight,
      fontSize: theme.inputFieldsIdlePlaceholderFontSize,
    },
    'input:-webkit-autofill::first-line, input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active': {
      transition: 'background-color 5000s ease-in-out 0s',
      fontFamily: theme.typographyContentFontFamily,
      color: theme.inputFieldsIdleTextColor,
      fontWeight: theme.inputFieldsIdleTextWeight,
      fontSize: theme.inputFieldsIdleTextFontSize,
    },
    '&:hover': (() => {
      if (!theme.inputFieldsHoverEnable || active) return {};

      const obj = {
        background: theme.inputFieldsHoverBackground,
        borderColor: theme.inputFieldsHoverBorderColor,
        color: theme.inputFieldsHoverTextColor,
        '& input': {
          color: theme.inputFieldsHoverTextColor
        },
        '& input::placeholder': {
          color: theme.inputFieldsHoverPlaceholderColor
        },
      };

      if (theme.inputFieldsHoverShadow) {
        obj.boxShadow = `${theme.inputFieldsHoverShadowOffsetX}px ${theme.inputFieldsHoverShadowOffsetY}px ${theme.inputFieldsHoverShadowBlur}px ${theme.inputFieldsHoverShadowSpread}px ${theme.inputFieldsHoverShadowColor}`;
      } else {
        obj.boxShadow = 'none';
      }

      return obj;
    })(),
    '&.isActive': (() => {
      if (!theme.inputFieldsActiveEnable) return {};

      const obj = {
        background: theme.inputFieldsActiveBackground,
        borderColor: theme.inputFieldsActiveBorderColor,
        color: theme.inputFieldsActiveTextColor,
        '& input': {
          color: theme.inputFieldsActiveTextColor
        },
        '& input::placeholder': {
          color: theme.inputFieldsActivePlaceholderColor
        },
      };

      if (theme.inputFieldsActiveShadow) {
        obj.boxShadow = `${theme.inputFieldsActiveShadowOffsetX}px ${theme.inputFieldsActiveShadowOffsetY}px ${theme.inputFieldsActiveShadowBlur}px ${theme.inputFieldsActiveShadowSpread}px ${theme.inputFieldsActiveShadowColor}`;
      } else {
        obj.boxShadow = 'none';
      }

      return obj;
    })()
  });

  const phoneContainerStyle = css({
    '.phone-container': {
      width: '100%',
      background: theme.inputFieldsIdleBackground,
      fontFamily: theme.typographyContentFontFamily,
      borderRadius: `${theme.inputFieldsIdleRoundness}px`,
      boxShadow: theme.inputFieldsIdleShadow ? `${theme.inputFieldsIdleShadowOffsetX}px ${theme.inputFieldsIdleShadowOffsetY}px ${theme.inputFieldsIdleShadowBlur}px ${theme.inputFieldsIdleShadowSpread}px ${theme.inputFieldsIdleShadowColor}` : 'none',
      ...inputContainerStyleTypes[theme.inputFieldsDropdownsType],
      boxSizing: 'border-box',
      padding: `${theme.inputFieldsIdleVerticalPadding || 0}px ${theme.inputFieldsIdleHorizontalPadding || 0}px`,
      '.country-list': {
        zIndex: 12
      }
    },
    '.phone-input': {
      width: '100%',
      lineHeight: '142%',
      color: theme.inputFieldsIdleTextColor,
      fontWeight: theme.inputFieldsIdleTextWeight,
      fontSize: theme.inputFieldsIdleTextFontSize,
      boxSizing: 'border-box'
    },
    '.phone-input::placeholder': {
      color: theme.inputFieldsIdlePlaceholderColor,
      fontWeight: theme.inputFieldsIdleTextWeight,
      fontSize: theme.inputFieldsIdlePlaceholderFontSize,
    },
    '.phone-input:-webkit-autofill::first-line, .phone-input:-webkit-autofill, .phone-input:-webkit-autofill:hover, .phone-input:-webkit-autofill:focus, .phone-input:-webkit-autofill:active': {
      transition: 'background-color 5000s ease-in-out 0s',
      fontFamily: theme.typographyContentFontFamily,
      color: theme.inputFieldsIdleTextColor,
      fontWeight: theme.inputFieldsIdleTextWeight,
      fontSize: theme.inputFieldsIdleTextFontSize,
    },
    '.selected-flag': {
      background: 'transparent !important',
      opacity: !value ? 0 : 1
    },
    '&:hover .phone-container': (() => {
      if (!theme.inputFieldsHoverEnable || active) return {};

      const obj = {
        background: theme.inputFieldsHoverBackground,
        borderColor: theme.inputFieldsHoverBorderColor,
        '& .phone-input': {
          color: theme.inputFieldsHoverTextColor
        },
        '& .phone-input::placeholder': {
          color: theme.inputFieldsHoverPlaceholderColor
        },
        '& .iti-arrow:not(.up)': {
          borderTop: `4px solid ${theme.inputFieldsHoverBorderColor}`
        },
        '& .iti-arrow.up': {
          borderBottom: `4px solid ${theme.inputFieldsHoverBorderColor}`
        }
      };

      if (theme.inputFieldsHoverShadow) {
        obj.boxShadow = `${theme.inputFieldsHoverShadowOffsetX}px ${theme.inputFieldsHoverShadowOffsetY}px ${theme.inputFieldsHoverShadowBlur}px ${theme.inputFieldsHoverShadowSpread}px ${theme.inputFieldsHoverShadowColor}`;
      } else {
        obj.boxShadow = 'none';
      }

      return obj;
    })(),
    '&.isActive .phone-container': (() => {
      if (!theme.inputFieldsActiveEnable) return {};

      const obj = {
        background: theme.inputFieldsActiveBackground,
        borderColor: theme.inputFieldsActiveBorderColor,
        '& .phone-input': {
          color: theme.inputFieldsActiveTextColor
        },
        '& .phone-input::placeholder': {
          color: theme.inputFieldsActivePlaceholderColor
        },
        '& .iti-arrow': {
          borderTop: `4px solid ${theme.inputFieldsActiveBorderColor}`
        },
        '& .iti-arrow.up': {
          borderBottom: `4px solid ${theme.inputFieldsActiveBorderColor}`
        }
      };

      if (theme.inputFieldsActiveShadow) {
        obj.boxShadow = `${theme.inputFieldsActiveShadowOffsetX}px ${theme.inputFieldsActiveShadowOffsetY}px ${theme.inputFieldsActiveShadowBlur}px ${theme.inputFieldsActiveShadowSpread}px ${theme.inputFieldsActiveShadowColor}`;
      } else {
        obj.boxShadow = 'none';
      }

      return obj;
    })()
  });

  const handlePhoneChange = (valid, value) => {
    onChange({ [field._id]: value }, valid);
  };

  useEffect(() => {
    if (typeof value === 'string' && field.format === 'email' && !isEmail(value)) {
      handlePhoneChange(false, '');
    }
  }, []);

  const labelJsx = getFieldLabel(field.label, values, 'jsx', fieldsOptions, variables);
  const labelText = getFieldLabel(field.label, values, 'text', fieldsOptions, variables);
  const rand = Math.random().toString(36).substring(7);

  return <>
    <div css={mainStyle}>
      <div css={contentStyle}>
        <LabelAtom required={field.required} error={field.error} theme={theme}>{labelJsx}</LabelAtom>

        {field.format === 'phone' && <div css={phoneContainerStyle} className={active ? 'isActive' : ''}>
          <IntlTelInput autoFocus={autoFocus || false}
            containerClassName="intl-tel-input phone-container"
            inputClassName="form-control phone-input"
            onPhoneNumberChange={handlePhoneChange}
            onPhoneNumberBlur={() => {
              setActive(false);
              onPartialResponse(field._id);
              onBlur();
            }}
            customPlaceholder={field.placeholder ? () => field.placeholder : (typeof country.code === 'string' ? null : () => '')}
            onPhoneNumberFocus={() => setActive(true)}
            autoHideDialCode={false}
            allowDropdown={false}
            formatOnInit={false}
            readOnly={field.readonly}
            defaultCountry={typeof country.code === 'string' ? country.code.toLowerCase() : (owner.country || '').toLowerCase()}
            initialCountry={typeof country.code === 'string' ? country.code.toLowerCase() : (owner.country || '').toLowerCase()}
            value={value || ''}
            fieldName={`${labelText}-${rand}`} />
        </div>}

        {field.format !== 'phone' && <>
          <div css={inputContainerStyle} className={active ? 'isActive' : ''}>
            <input type={formats[field.format] || 'text'} autocomplete="chrome-off" name={`${labelText}-${rand}`} autoFocus={autoFocus || false} value={value || ''} onChange={(e) => onChange({ [field._id]: e.target.value })} placeholder={field.placeholder || ''} readOnly={field.readonly} onBlur={() => {
              setActive(false);
              onPartialResponse(field._id);
              onBlur();
            }} onFocus={() => setActive(true)} />
          </div>
        </>}

        <DescriptionAtom theme={theme}>{getFieldLabel(field.description, values, 'jsx', fieldsOptions, variables)}</DescriptionAtom>
      </div>
    </div>
  </>;
};

export default ShortText;
