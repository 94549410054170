import styles from './Menu.module.css';

import React from 'react';

import {
  TitleField, SizeWeightAndColorField, SelectField, SizeWithTypeField, SizeField
} from './../ThemeMenuFields.js';

const typeOptions = [
  { label: '%', value: '%' },
  { label: 'px', value: 'px' }
];

const fontWeightOptions = [
  { label: 400, value: 400 },
  { label: 600, value: 600 }
];

const alignOptions = [
  { label: 'Left', value: 'left' },
  { label: 'Center', value: 'center' },
  { label: 'Right', value: 'right' }
];

const Titles = ({ settings, colors, onChange, onNewColor }) => {
  return (
    <>
      <TitleField title="General Field Settings" />

      <SizeWithTypeField title="Maximum field width"
        settings={settings}
        onChange={onChange}
        margin="25px 0 0 0"
        types={typeOptions}
        size="titlesWidth"
        type="titlesWidthType" />

      <SelectField title="Field alignment"
        settings={settings}
        onChange={onChange}
        options={alignOptions}
        margin="25px 0 0 0"
        value="titlesAlignment" />

      <div className={styles.doubleRow}>
        <div className={styles.left}>
          <SizeField title="Field top margin"
            settings={settings}
            onChange={onChange}
            margin="25px 0 0 0"
            size={`titlesTopMargin`} />
        </div>
        <div className={styles.right}>
          <SizeField title="Field bottom margin"
            settings={settings}
            onChange={onChange}
            margin="25px 0 0 0"
            size={`titlesBottomMargin`} />
        </div>
      </div>

      <TitleField title="Text Styling" />

      <SizeWeightAndColorField title="Title text"
        settings={settings}
        colors={colors}
        onChange={onChange}
        onNewColor={onNewColor}
        weights={fontWeightOptions}
        margin="25px 0 0 0"
        size="titlesMainFontSize"
        weight="titlesMainWeight"
        color="titlesMainColor" />

      <SizeWeightAndColorField title="Description text"
        settings={settings}
        colors={colors}
        onChange={onChange}
        onNewColor={onNewColor}
        weights={fontWeightOptions}
        margin="25px 0 0 0"
        size="titlesDescriptionFontSize"
        weight="titlesDescriptionWeight"
        color="titlesDescriptionColor" />

      <SelectField title="Text alignment"
        settings={settings}
        onChange={onChange}
        options={alignOptions}
        margin="25px 0 0 0"
        value="titlesTextAlignment" />
    </>
  );
}

export default Titles;
