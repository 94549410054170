import styles from './Publish.module.css';

import config from 'config.js';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Select } from 'ui';
import QRCode from 'qrcode.react';
import { getUrlWithParameters } from 'misc/helpers.js';

const options = [
  { label: '128x128px', value: 128 },
  { label: '254x254px', value: 254 },
  { label: '512x512px', value: 512 },
  { label: '1024x1024px', value: 1024 },
  { label: '2048x2048px', value: 2048 }
];

const PublishQr = () => {
  const [size, setSize] = useState(512);

  const form = useSelector(state => state.builder.form);
  const formId = useSelector(state => state.builder.formId);
  const domains = useSelector(state =>state.builder.form.domains);
  const urlParams = useSelector(state => state.builder.form.urlParams);

  const domain = form.domain || {};
  const alias = form.alias || {};
  const customDomain = domains.find((d) => d._id === domain.value);
  const customAlias = alias.enabled && alias.value ? alias.value : formId;

  const url = getUrlWithParameters(`${domain.enabled && customDomain ? `https://${customDomain.hostname}` : config.formUrl}/${customAlias}`, urlParams);

  const refCallback = (node) => {
    if (node === null) return;

    const canvas = document.querySelector('#qr canvas');

    node.href = canvas.toDataURL();
    node.download = `qs-form-${formId}.png`;
  };

  return <div id="qr">
    <div className={styles.card}>
      <div className={styles.title}>QR Code</div>
      <div className={styles.subTitle}>Share your surveys via QR codes. Your respondents just need to scan the code to access your form.</div>

      <div className={styles.qrCode}>
        <div className={styles.left}>
          <QRCode value={url} size={size} />
        </div>

        <div className={styles.right}>
          <div className={styles.title}>QR Code Image Size</div>
          <Select width={240} options={options} margin="0 0 15px 0" value={
            { label: options.find((option) => option.value === size).label, value: size }
          } onChange={(selected) => setSize(selected.value)} />

          <Button theme="white" width={240} ref={refCallback} href="#">Download Generated QR Code</Button>
        </div>
      </div>
    </div>
  </div>;
};

export default PublishQr;
