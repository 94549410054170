import styles from './DeleteAccount.module.css';

import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
  hideDeleteAccountModal, updateDeleteAccountForm
} from 'store/ducks/user.js';

import {
  deleteUser
} from 'store/ducks/_users.js';

import {
  logout
} from 'store/ducks/_users.js';

import { Button, Modal, FormRow, Checkbox, PasswordInput, Loader } from 'ui';

class DeleteAccount extends Component {
  cancel = () => {
    this.props.hideDeleteAccountModal();
  }

  delete = () => {
    this.props.deleteUser('self', this.props.password).then(() => {
      this.props.logout();
    });
  }

  render() {
    const {
      user, show, password, loading, error, confirm
    } = this.props;

    return (
      <Modal isOpen={show} onRequestClose={this.cancel} width={450} header="Deleting your QuestionScout account" footer={<>
        <Button size="small" theme="white" onClick={this.cancel} margin="0 15px 0 0">Cancel</Button>
        {user.authType === 'local' && <Button size="small" theme="red" onClick={this.delete} disabled={password.length < 6}>Yes, delete account</Button>}
        {user.authType !== 'local' && <Button size="small" theme="red" onClick={this.delete} disabled={!confirm}>Yes, delete account</Button>}
      </>}>
        <div className={styles.content}>
          {loading && <Loader size={30} />}

          {!loading && <>
            <p className={styles.p}>Please be aware that deleting your account will result in all of your forms, responses, and all other data associated with your account to be <strong>permanently deleted</strong>.</p>

            {user.authType === 'local' && <FormRow label="Type your password to delete your account">
              <PasswordInput name="password" value={password} error={error} width={'100%'} onChange={(e) => this.props.updateDeleteAccountForm(e.target.name, e.target.value)} />
            </FormRow>}

            {user.authType !== 'local' && <Checkbox label="I understand, delete my account anyway" value={confirm} onChange={() => { }} onClick={() => this.props.updateDeleteAccountForm('confirm', !confirm)} />}
          </>}
        </div>
      </Modal>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    user: state._users.user,
    show: state.user.showDeleteAccountModal,
    password: state.user.deleteAccountForm.password,
    confirm: state.user.deleteAccountForm.confirm,
    loading: state._users.deleteUser.loading,
    error: state._users.deleteUser.error
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    deleteUser: (id, password) => dispatch(deleteUser(id, password)),
    logout: () => dispatch(logout()),
    updateDeleteAccountForm: (name, value) => dispatch(updateDeleteAccountForm(name, value)),
    hideDeleteAccountModal: () => dispatch(hideDeleteAccountModal())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DeleteAccount);
