import styles from './Menu.module.css';

import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { ChromePicker } from 'react-color';
import debounce from 'lodash.debounce';
import { v4 as uuid } from 'uuid';
import Color from 'color';

import {
  TitleField
} from './../ThemeMenuFields.js';

import { ReactComponent as RemoveIcon } from 'assets/images/trash.svg';

import { Button, TextInput } from 'ui';

const ColorPicker = ({ color, settings, onColorChange, onColorRemove, onColorTitleChange }) => {
  let usage = 0;

  const [showPicker, setShowPicker] = useState(false);
  const [name, setName] = useState(color.name);

  Object.keys(settings).map((key) => {
    if (!color || !settings[key] || typeof settings[key] !== 'object') return;

    if (settings[key]._id === color._id) usage = usage + 1;
  });

  const onColorChangeComplete = (c) => {
    onColorChange(color._id, `rgba(${c.rgb.r}, ${c.rgb.g}, ${c.rgb.b}, ${c.rgb.a})`, color.name);
  };

  return <div className={styles.colorRow} margin="25px 0 0 0">
    <div className={styles.colorBg}>
      <div onClick={() => setShowPicker(true)} className={styles.color} style={{
        background: color.value,
        border: `1px solid ${Color(color.value).darken(0.2).hex()}`
      }}>
        <svg width="14" height="14" xmlns="http://www.w3.org/2000/svg">
          <path d="M13.167 3.117L10.883.833A1.667 1.667 0 0 0 8.667.775l-7.5 7.5c-.27.272-.437.628-.475 1.008l-.359 3.475a.833.833 0 0 0 .834.909h.075l3.475-.317c.38-.038.736-.206 1.008-.475l7.5-7.5a1.6 1.6 0 0 0-.058-2.258zM10.333 5.9L8.1 3.667 9.725 2 12 4.275 10.333 5.9z" fill={Color(color.value).darken(0.3).hex()} fillRule="nonzero" />
        </svg>
      </div>
    </div>

    {showPicker && <div className={styles.colorPopover}>
      <div className={styles.colorCover} onClick={() => setShowPicker(false)} />
      <ChromePicker color={color.value} onChangeComplete={onColorChangeComplete} />
    </div>}

    <div className={styles.content}>
      <div className={styles.info}>
        <TextInput onChange={(e) => { setName(e.target.value); onColorTitleChange(color._id, e.target.value); }} value={name} width="100%" />
        {usage > 0 && <div className={styles.usage}>Used {usage} time{usage === 1 ? '' : 's'} in this theme</div>}
        {usage === 0 && <div className={styles.usage}>Not in use</div>}
      </div>
      <div className={styles.remove} onClick={() => onColorRemove(color._id)}><RemoveIcon /></div>
    </div>
  </div>;
};

const Colors = ({ settings, colors, onChange }) => {
  const handleColorChange = (id, color) => {
    const newColors = [...colors];
    const index = newColors.findIndex((color) => color._id === id);

    newColors[index].value = color;

    onChange(newColors, false);
  };

  const handleColorRemove = (id) => {
    const newColors = [...colors];
    const index = newColors.findIndex((color) => color._id === id);
    const removedColor = newColors[index];

    newColors.splice(index, 1);

    onChange(newColors, true, removedColor);
  };

  const addColor = () => {
    const newColors = [{
      name: '',
      value: 'rgba(255, 255, 255, 1)'
    }, ...colors];

    onChange(newColors, true);
  };

  const handleColorTitleChange = debounce((id, value) => {
    const newColors = [ ...colors ];
    const index = newColors.findIndex((color) => color._id === id);

    newColors[index].name = value;

    onChange(newColors, false);
  }, 300);

  return (
    <>
      <Button theme="black" width="calc(100% - 40px)" margin="25px 20px 0 20px" onClick={addColor}>Add Color</Button>

      <TitleField title="Color Palette" />

      {colors.map((color) => <ColorPicker key={color._id} settings={settings} color={color} onColorChange={handleColorChange} onColorRemove={handleColorRemove} onColorTitleChange={handleColorTitleChange} />)}
    </>
  );
};

export default Colors;
