import styles from './Menu.module.css';

import React from 'react';

import {
  TitleField, CheckboxField, SizeWithTypeField, SizeField,
  SelectField, ColorField, ToggleField
} from './../ThemeMenuFields.js';

const typeOptions = [
  { label: '%', value: '%' },
  { label: 'px', value: 'px' }
];

const progressTypeOptions = [
  { label: 'Percentage', value: 'percentage' },
  { label: 'Proportion', value: 'proportion' }
];

const alignOptions = [
  { label: 'Left', value: 'left' },
  { label: 'Center', value: 'center' },
  { label: 'Right', value: 'right' }
];

const boxHeightOptions = [
  { label: 'Scale to browser height', value: 'window' },
  { label: 'Adjust to content', value: 'content' }
];

const DisplaySettings = ({ settings, colors, onChange, onNewColor }) => {
  return (
    <>
      <TitleField title="Form Properties" subTitle="Desktop" />

      <SizeWithTypeField title="Maximum form width"
        settings={settings}
        onChange={onChange}
        margin="25px 0 0 0"
        types={typeOptions}
        size="displaySettingsFormDisplayDesktopMaxWidth"
        type="displaySettingsFormDisplayDesktopMaxWidthType" />

      <SizeField title="Form horizontal margin"
        settings={settings}
        onChange={onChange}
        margin="25px 0 0 0"
        size={`displaySettingsFormDisplayDesktopHorizontalMargin`} />

      <SizeField title="Form vertical margin"
        settings={settings}
        onChange={onChange}
        margin="25px 0 0 0"
        size={`displaySettingsFormDisplayDesktopVerticalMargin`} />

      <TitleField title="Form Properties" subTitle="Tablet" />

      <SizeWithTypeField title="Maximum form width"
        settings={settings}
        onChange={onChange}
        margin="25px 0 0 0"
        types={typeOptions}
        size="displaySettingsFormDisplayTabletMaxWidth"
        type="displaySettingsFormDisplayTabletMaxWidthType" />

      <SizeField title="Form horizontal margin"
        settings={settings}
        onChange={onChange}
        margin="25px 0 0 0"
        size={`displaySettingsFormDisplayTabletHorizontalMargin`} />

      <SizeField title="Form vertical margin"
        settings={settings}
        onChange={onChange}
        margin="25px 0 0 0"
        size={`displaySettingsFormDisplayTabletVerticalMargin`} />

      <TitleField title="Form Properties" subTitle="Mobile" />

      <SizeWithTypeField title="Maximum form width"
        settings={settings}
        onChange={onChange}
        margin="25px 0 0 0"
        types={typeOptions}
        size="displaySettingsFormDisplayMobileMaxWidth"
        type="displaySettingsFormDisplayMobileMaxWidthType" />

      <SizeField title="Form horizontal margin"
        settings={settings}
        onChange={onChange}
        margin="25px 0 0 0"
        size={`displaySettingsFormDisplayMobileHorizontalMargin`} />

      <SizeField title="Form vertical margin"
        settings={settings}
        onChange={onChange}
        margin="25px 0 0 0"
        size={`displaySettingsFormDisplayMobileVerticalMargin`} />

      <TitleField title="Form Properties" />

      <SelectField title="Form alignment"
        settings={settings}
        onChange={onChange}
        options={alignOptions}
        margin="25px 0 0 0"
        value="displaySettingsFormDisplayAlignment" />

      <hr className={styles.hr} />

      {/* <ToggleField label="Use progress bar"
        settings={settings}
        onChange={onChange}
        margin="25px 0 0 0"
        value="displaySettingsFormDisplayProgressBar" />

      {settings.displaySettingsFormDisplayProgressBar && <>
        <SelectField title="Progress bar type"
          settings={settings}
          onChange={onChange}
          onNewColor={onNewColor}
          options={progressTypeOptions}
          margin="10px 0 0 0"
          value="displaySettingsFormDisplayProgressBarType" />

        <ColorField title="Progress bar elements color"
          settings={settings}
          colors={colors}
          onChange={onChange}
          onNewColor={onNewColor}
          margin="25px 0 0 0"
          color={'displaySettingsFormDisplayProgressBarColor'} />

        <ColorField title="Progress bar background color"
          settings={settings}
          colors={colors}
          onChange={onChange}
          onNewColor={onNewColor}
          margin="25px 0 0 0"
          color="displaySettingsFormDisplayProgressBarBackground" />
      </>}

      <hr className={styles.hr} /> */}

      <ToggleField label="Use box behind form content"
        settings={settings}
        onChange={onChange}
        margin="25px 0 0 0"
        value="displaySettingsFormDisplayBox" />

      {settings.displaySettingsFormDisplayBox && <>
        <ColorField title="Background"
          settings={settings}
          colors={colors}
          onChange={onChange}
          margin="10px 0 0 0"
          color="displaySettingsFormDisplayBoxBackground" />

        <SizeWithTypeField title="Box roundness"
          settings={settings}
          onChange={onChange}
          margin="25px 0 0 0"
          types={typeOptions}
          size="displaySettingsFormDisplayBoxRoundness"
          type="displaySettingsFormDisplayBoxRoundnessType" />

        <SelectField title="Minimum height"
          settings={settings}
          onChange={onChange}
          options={boxHeightOptions}
          margin="25px 0 0 0"
          value="displaySettingsFormDisplayBoxMinimumHeight" />

        <SizeField title="Box horizontal padding"
          settings={settings}
          onChange={onChange}
          margin="25px 0 0 0"
          size={`displaySettingsFormDisplayBoxHorizontalPadding`} />

        <SizeField title="Box vertical padding"
          settings={settings}
          onChange={onChange}
          margin="25px 0 0 0"
          size={`displaySettingsFormDisplayBoxVerticalPadding`} />

        <CheckboxField label="Add box shadows"
          settings={settings}
          onChange={onChange}
          margin="25px 0 0 0"
          value="displaySettingsFormDisplayBoxShadow" />

        {settings.displaySettingsFormDisplayBoxShadow && <>
          <ColorField title="Shadow color"
            settings={settings}
            colors={colors}
            onChange={onChange}
            onNewColor={onNewColor}
            margin="10px 0 0 0"
            color="displaySettingsFormDisplayBoxShadowColor" />

          <SizeField title="Shadow horizontal length"
            settings={settings}
            onChange={onChange}
            margin="25px 0 0 0"
            size="displaySettingsFormDisplayBoxShadowOffsetX" />

          <SizeField title="Shadow vertical length"
            settings={settings}
            onChange={onChange}
            margin="25px 0 0 0"
            size="displaySettingsFormDisplayBoxShadowOffsetY" />

          <SizeField title="Shadow blur"
            settings={settings}
            onChange={onChange}
            margin="25px 0 0 0"
            size="displaySettingsFormDisplayBoxShadowBlur" />

          <SizeField title="Shadow spread"
            settings={settings}
            onChange={onChange}
            margin="25px 0 0 0"
            size="displaySettingsFormDisplayBoxShadowSpread" />
        </>}
      </>}

      <TitleField title="Footer Styling" />

      <ColorField title="Background"
        settings={settings}
        colors={colors}
        onChange={onChange}
        onNewColor={onNewColor}
        margin="10px 0 0 0"
        color="displaySettingsFooterBackground" />

      {/* <TitleField title="Field Properties" /> */}

      {/* <ToggleField label="Keyboard control"
        settings={settings}
        onChange={onChange}
        margin="25px 0 0 0"
        value="displaySettingsFieldPropertiesKeyboardControll" /> */}
    </>
  );
}

export default DisplaySettings;
