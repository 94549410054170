import styles from './Menu.module.css';

import React from 'react';

import {
  TitleField, FontField
} from './../ThemeMenuFields.js';

const TypographyFields = ({ settings, onChange }) => {
  return (
    <>
      <FontField title="Titles font"
        settings={settings}
        onChange={onChange}
        margin="25px 0 0 0"
        value="typographyTitlesFontFamily" />

      <FontField title="Supporting text font"
        settings={settings}
        onChange={onChange}
        margin="25px 0 0 0"
        value="typographySupportingFontFamily" />

      <FontField title="Label font"
        settings={settings}
        onChange={onChange}
        margin="25px 0 0 0"
        value="typographyLabelFontFamily" />

      <FontField title="Content font"
        settings={settings}
        onChange={onChange}
        margin="25px 0 0 0"
        value="typographyContentFontFamily" />

      <FontField title="Buttons font"
        settings={settings}
        onChange={onChange}
        margin="25px 0 0 0"
        value="typographyButtonsFontFamily" />
    </>
  );
}

export default TypographyFields;
