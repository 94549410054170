import styles from './ForgotPassword.module.css';

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PasswordStrengthBar from 'react-password-strength-bar';

import { ReactComponent as LogoIcon } from 'assets/images/logo-icon-bw.svg';

import {
  forgotPassword, getUser
} from 'store/ducks/_users.js';

import { Button, TextInput, PasswordInput, Loader, FormRow } from 'ui';

const ForgotPassword = ({ match }) => {
  const dispatch = useDispatch();

  const loading = useSelector(state => state._users.forgotPassword.loading);
  const errors = useSelector(state => state._users.forgotPassword.errors);
  const success = useSelector(state => state._users.forgotPassword.success);

  const [formEmail, setFormEmail] = useState('');
  const [formPassword, setFormPassword] = useState('');
  const [showPasswordStrengthBar, setShowPasswordStrengthBar] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const step = !match.params.code ? 'first' : 'second';

  useEffect(() => {
    document.title = 'Forgot Password - QuestionScout';
  }, []);

  const handleFirstStepSubmit = () => {
    dispatch(forgotPassword({
      email: formEmail
    }));
  };

  const handleSecondStepSubmit = () => {
    dispatch(forgotPassword({
      code: match.params.code,
      password: formPassword
    }));
  }

  const handleEnterKeyPress = (e) => {
    if (e.key !== 'Enter') return;
    if (step === 'first' && !formEmail || loading) return;
    if (step === 'second' && formPassword.length < 6 || loading) return;

    if (step === 'first') handleFirstStepSubmit();
    if (step === 'second') handleSecondStepSubmit();
  };

  if (success && !submitted) {
    setSubmitted(true);

    if (step === 'second') {
      dispatch(getUser());
    }
  }

  return (
    <div className={styles.main}>
      <header className={styles.header}>
        <a href="https://questionscout.com" className={styles.logo}>
          <LogoIcon />
        </a>
      </header>

      <div className={styles.content}>
        <div className={styles.text}>
          <div className={styles.title}>Let’s get you back up on your feet</div>
          {step === 'first' && <div className={styles.subtitle}>We will send you an email with instructions on how to reset your password.</div>}          
        </div>

        <div className={styles.form}>
          {!submitted && <>
            {loading && <Loader size={40} />}

            {errors.find((error) => !error.field) && <div className={styles.error}>{errors.find((error) => !error.field).message}</div>}

            {step === 'first' && <FormRow label="Your email" required={true} errorMessage={errors.find((error) => error.field === 'email') ? errors.find((error) => error.field === 'email').message : null}>
              <TextInput type="email" name="email" autoFocus={true} value={formEmail} onChange={(e) => setFormEmail(e.target.value)} onKeyPress={handleEnterKeyPress} error={errors.find((error) => error.field === 'email')} />
            </FormRow>}

            {step === 'second' && <FormRow label="Your new password" required={true} style={{ margin: 0 }} errorMessage={errors.find((error) => error.field === 'password') ? errors.find((error) => error.field === 'password').message : null}>
              <PasswordInput onFocus={() => setShowPasswordStrengthBar(true)} onBlur={() => setShowPasswordStrengthBar(false)} name="password" value={formPassword} onChange={(e) => setFormPassword(e.target.value)} onKeyPress={handleEnterKeyPress} error={errors.find((error) => error.field === 'password')} />
              {showPasswordStrengthBar ? <PasswordStrengthBar className={styles.passwordStrength} password={formPassword} minLength={6} /> : <div className={styles.passwordStrength} />}
            </FormRow>}

            {step === 'first' && <Button fullWidth={true} theme="blue" onClick={handleFirstStepSubmit} className={styles.submit} disabled={!formEmail || loading}>Email Me</Button>}
            {step === 'second' && <Button fullWidth={true} theme="blue" onClick={handleSecondStepSubmit} className={styles.submit} disabled={formPassword.length < 6 || loading}>Change Password</Button>}
          </>}

          {submitted && <>
            {step === 'first' && <>
              <div className={styles.submittedTitle}>Email Sent</div>
              <div className={styles.submittedSubTitle}>An email with instructions on how to reset your password has been sent. Check your spam or junk folder if you don’t see the email in your inbox. If you’ve signed up using Google or Facebook, you should sign in by using their associated sign in buttons.</div>
            </>}

            {step === 'second' && <>
              <div className={styles.submittedTitle}>Success!</div>
            </>}
          </>}
        </div>
      </div>

      <div className={styles.rights}>
        <p>2020 QuestionScout</p>
        <p>All rights reserved</p>
      </div>
    </div>
  );
}

export default ForgotPassword;
