import isEmail from 'validator/lib/isEmail';
import isDecimal from 'validator/lib/isDecimal';
import isFieldEmpty from './isFieldEmpty.js';

export default function checkRequired(page, fields, values) {
  let allGood = true;
  let field;
  let setRequired = [];
  let removeRequired = [];
  let valueObject;
  let count;

  for (let [id, object] of Object.entries(values)) {
    field = fields.find((field) => field._id === id);

    valueObject = {};
    count = 0;

    if (!field || !object) continue;
    if (page && field.page !== page) continue;

    if (field.type === 'checkbox') {
      valueObject = JSON.parse(object.value);
      count = valueObject.values.length;

      if (valueObject.other) count += 1;
    } else if (field.type === 'radio') {
      valueObject = JSON.parse(object.value);
    }

    if (field.required && object.visible && field.type === 'checkbox' && !field.selectionLimits && count === 0 && !valueObject.other) {
      setRequired.push(id);

      allGood = false;
    } else if (field.required && object.visible && field.type === 'checkbox' && field.selectionLimits && count > field.selectionLimitsMax) {
      setRequired.push(id);

      allGood = false;
    } else if (field.required && object.visible && field.type === 'checkbox' && field.selectionLimits && count < field.selectionLimitsMin) {
      setRequired.push(id);

      allGood = false;
    } else if (field.required && object.visible && field.type === 'radio' && !valueObject.value && !valueObject.other) {
      setRequired.push(id);

      allGood = false;
    } else if (field.required && object.visible && field.format === 'number' && !isDecimal(object.value || '')) {
      setRequired.push(id);

      allGood = false;
    } else if (field.required && object.visible && field.format === 'email' && !isEmail(object.value || '')) {
      setRequired.push(id);

      allGood = false;
    } else if (field.required && object.visible && field.format === 'phone' && (typeof object.valid === 'boolean' && object.valid === false)) {
      setRequired.push(id);

      allGood = false;
    } else if (field.required && object.visible && isFieldEmpty(object.value, object.type)) {
      setRequired.push(id);

      allGood = false;
    } else if (field.required && object.visible && field.type === 'datetime' && (isFieldEmpty(object.value, object.type) || (typeof object.valid === 'boolean' && object.valid === false))) {
      setRequired.push(id);

      allGood = false;
    } else {
      removeRequired.push(id);
    }
  }

  return {
    valid: allGood,
    toSet: setRequired,
    toRemove: removeRequired
  };
}