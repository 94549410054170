import styles from './ConnectedServices.module.css';

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import AddEditConnectedModal from './modals/AddEditConnectedModal.js';

import {
  showAddEditConnectedModal
} from 'store/ducks/user.js';

import {
  getIntegrations, getUsers
} from 'store/ducks/_users.js';

import { Card, Button, CardRow, CardRowLabel, CardRowContent, Loader } from 'ui';

import { ReactComponent as RemoveIcon } from 'assets/images/trash.svg';

const servicesIcons = {
  paypal: require('assets/images/services/paypal.png'),
  stripe: require('assets/images/services/stripe.png'),
  mollie: require('assets/images/services/mollie.png'),
  authorizeNet: require('assets/images/services/authorizeNet.png')
};

const ConnectedServices = () => {
  const dispatch = useDispatch();

  const user = useSelector(state => state._users.user);
  const integrations = useSelector(state => state._users.integrations);
  const users = useSelector(state => state._users.users);
  const loading = useSelector(state => state._users.getUsers.loading);

  useEffect(() => {
    dispatch(getIntegrations());
    dispatch(getUsers());
  }, []);

  if (user.authType === 'onetool') return <></>;

  return <>
    <AddEditConnectedModal />

    <Card className={styles.card}>
      <div className={styles.head}>
        <div className={styles.left}>
          <div className={styles.title}>Payment Accounts</div>
          <Button theme="blue" onClick={() => dispatch(showAddEditConnectedModal())}>Add payment account</Button>
        </div>
      </div>

      {loading && <div className={styles.loading}>
        <Loader size={30} />
      </div>}

      {!loading && <ul className={styles.users}>
        <li className={styles.usersHead}>
          <div>Name</div>
          <div>Provider</div>
          <div>Creator</div>
          <div>Actions</div>
        </li>

        {integrations && integrations.map((integration) => {
          const owner = users.find((user) => user._id === integration.owner);

          return <li className={styles.usersBody} key={integration._id}>
            <div>{integration.name || '-'}</div>
            <div><img src={servicesIcons[integration.type]} height="14px" /></div>
            <div>{owner ? owner.email : '-'}</div>
            <div className={styles.actions}>
              {integration.role !== 'holder' && integration._id !== user._id && <>
                <span onClick={() => { }}>Edit</span>
                <div onClick={() => { }}><RemoveIcon /></div>
              </>}
            </div>
          </li>;
        })}
      </ul>}
    </Card>
  </>;
}

export default ConnectedServices;
