import React, { Component } from 'react';
import Script from 'react-load-script';

const SDK_URL = 'https://cdn.nolt.io/widgets.js';
const BOARD_URL = 'https://questionscout.nolt.io';

class NoltWidget extends Component {
  handleScriptLoad = () => {
    const { nolt } = window;

    nolt('init', { selector: '.noltBtn', url: BOARD_URL });
  }

  render() {
    const { children, onClick } = this.props;

    return (
      <>
        <span className="noltBtn" onClick={onClick} href={BOARD_URL}>{children}</span>

        <Script url={SDK_URL} onLoad={this.handleScriptLoad} />
      </>
    );
  }
}

export default NoltWidget;
