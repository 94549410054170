import styles from './Join.module.css';

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import jwtDecode from 'jwt-decode';
import { ReCaptcha } from 'react-recaptcha-v3';
import PasswordStrengthBar from 'react-password-strength-bar';

import { ReactComponent as LogoIcon } from 'assets/images/logo-icon-bw.svg';

import {
  join, getUser
} from 'store/ducks/_users.js';

import { Button, TextInput, PasswordInput, Loader, FormRow } from 'ui';

let recaptchaInterval = null;
let recaptchaRef;

const Join = (props) => {
  const dispatch = useDispatch();

  const loading = useSelector(state => state._users.join.loading);
  const errors = useSelector(state => state._users.join.errors);
  const success = useSelector(state => state._users.join.success);
  const token = jwtDecode(props.match.params.token);

  const [formEmail, setFormEmail] = useState('');
  const [formName, setFormName] = useState('');
  const [formPassword, setFormPassword] = useState('');
  const [showPasswordStrengthBar, setShowPasswordStrengthBar] = useState(false);
  const [reCaptchaToken, setReCaptchaToken] = useState('');

  if (success) {
    dispatch(getUser());
  }

  useEffect(() => {
    setFormEmail(token.data.email);

    document.title = 'Join - QuestionScout';

    recaptchaInterval = setInterval(() => {
      recaptchaRef && recaptchaRef.execute();
    }, 90 * 1000);

    return () => clearInterval(recaptchaInterval); // unmount
  }, []);

  const handleSubmit = () => {
    dispatch(join({
      verificationCode: token.sub,
      name: formName,
      password: formPassword,
      reCaptchaToken
    }));

    recaptchaRef && recaptchaRef.execute();
  }

  const handleEnterKeyPress = (e) => {
    if (e.key !== 'Enter') return;

    handleSubmit();
  }

  return (
    <div className={styles.main}>
      <ReCaptcha sitekey="6LelHqkUAAAAAEZ8OOKqrVEZenT2Jo-mt1Dcj97r"
        ref={(ref) => recaptchaRef = ref}
        action="signup"
        verifyCallback={setReCaptchaToken} />

      <header className={styles.header}>
        <a href="https://questionscout.com" className={styles.logo}>
          <LogoIcon />
        </a>
      </header>

      <div className={styles.content}>
        <div className={styles.text}>
          <div className={styles.title}>You have been invited to join {token.data.ownerName || token.data.ownerEmail} account!</div>
        </div>

        <div className={styles.form}>
          {loading && <Loader size={40} />}

          {errors.find((error) => !error.field) && <div className={styles.error}>{errors.find((error) => !error.field).message}</div>}

          <FormRow label="Your email" required={true}>
            <TextInput type="email" name="email" disabled={true} value={formEmail} onChange={() => { }} />
          </FormRow>

          <FormRow label="Your name" required={true}>
            <TextInput name="name" value={formName} autoFocus={true} onChange={(e) => setFormName(e.target.value)} onKeyPress={handleEnterKeyPress} />
          </FormRow>

          <FormRow label="Your password" required={true} style={{ margin: 0 }} errorMessage={errors.find((error) => error.field === 'password') ? errors.find((error) => error.field === 'password').message : null}>
            <PasswordInput onFocus={() => setShowPasswordStrengthBar(true)} onBlur={() => setShowPasswordStrengthBar(false)} name="password" value={formPassword} onChange={(e) => setFormPassword(e.target.value)} onKeyPress={handleEnterKeyPress} error={errors.find((error) => error.field === 'password')} />
            {showPasswordStrengthBar ? <PasswordStrengthBar className={styles.passwordStrength} password={formPassword} minLength={6} /> : <div className={styles.passwordStrength} />}
          </FormRow>

          <Button fullWidth={true} theme="blue" onClick={handleSubmit} className={styles.submit} disabled={formPassword.length < 6 || !formEmail || loading}>Sign Up</Button>
        </div>

        <div className={styles.agree}>
          <span>By clicking "Sign Up" you agree to QuestionScout’s <a href="https://questionscout.com/terms-of-service" target="_blank" rel="noopener noreferrer">Terms of Service</a> and <a href="https://questionscout.com/privacy-policy" target="_blank" rel="noopener noreferrer">Privacy Policy</a></span>
        </div>
      </div>

      <div className={styles.rights}>
        <p>2020 QuestionScout</p>
        <p>All rights reserved</p>
      </div>
    </div>
  );
}

export default Join;
