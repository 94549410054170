import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import rootReducer from './ducks';
import setupSocketIoMiddleware from './socketIoMiddleware.js';

export const store = createStore(
  rootReducer,
  applyMiddleware(
    thunkMiddleware, setupSocketIoMiddleware
  )
);
