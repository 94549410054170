import styles from './MolliePaymentSuccess.module.css';

import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { BasicLoader } from 'ui';

import {
  publicGetMollieOrder
} from 'store/ducks/plan.js';

import { ReactComponent as Logo } from 'assets/images/logo-icon-bw.svg';

const MolliePaymentSuccess = ({ match }) => {
  const dispatch = useDispatch();

  const [status, setStatus] = useState(null);
  const [redirecting, setRedirecting] = useState(false);
  
  const checkStatus = (status) => {
    const paid = ['completed', 'paid', 'shipping'].indexOf(status) !== -1;

    if (paid && !redirecting) {
      setTimeout(() => window.location.replace('https://admin.questionscout.com?ltdsuccess=true'), 1000);
      setRedirecting(true);
    }
  };

  const getOrder = (id) => {
    dispatch(publicGetMollieOrder(id)).then((res) => {
      checkStatus(res.status);
      setStatus(res.status);
    });
  }

  useEffect(() => {
    getOrder(match.params.id);

    setInterval(() => {
      getOrder(match.params.id);
    }, 3000);
  }, []);

  return <div className={styles.main}>
    <Logo className={styles.logo} />
    <BasicLoader className={styles.loader} size={30} />
    {status && <p>{`${status}`}</p>}
  </div>;
};

export default MolliePaymentSuccess;
