import styles from './DeleteDomainModal.module.css';

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Modal, Loader } from 'ui';

import {
  deleteDomain
} from 'store/ducks/_domains.js';

const DeleteDomainModal = ({ onClose, domain }) => {
  const dispatch = useDispatch();

  const loading = useSelector(state => state._domains.deleteDomain.loading);

  const submit = () => {
    dispatch(deleteDomain(domain._id)).then(() => onClose());
  };

  return <>
    <Modal isOpen={true} onRequestClose={onClose} width={320} header="Deleting Custom Domain" footer={<>
      <Button size="small" theme="white" onClick={onClose} margin="0 15px 0 0">Cancel</Button>
      <Button size="small" theme="red" onClick={submit} disabled={loading}>Yes, delete domain</Button>
    </>}>
      <div className={styles.content}>
        {loading && <Loader size={30} />}

        {!loading && <p>Are you sure you want to delete this domain?</p>}
      </div>
    </Modal>
  </>;
}

export default DeleteDomainModal;
