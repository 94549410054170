/** @jsx jsx */
/* @jsxFrag React.Fragment */

import { jsx, css } from '@emotion/core';
import React from 'react';
import getFieldLabel from 'helpers/getFieldLabel.js';

const Title = ({ form, field, theme, values, fieldsOptions, variables }) => {
  const alignOptions = {
    left: 'flex-start',
    center: 'center',
    right: 'flex-end'
  };

  const mainStyle = css({
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: alignOptions[theme.titlesAlignment],
    margin: `${theme.titlesTopMargin || 0}px 0 ${theme.titlesBottomMargin || 0}px 0`
  });

  const contentStyle = css({
    width: '100%',
    maxWidth: `${theme.titlesWidth}${theme.titlesWidthType}`,
  });

  const titleStyle = {
    textAlign: theme.titlesTextAlignment,
    fontSize: `${theme.titlesMainFontSize}px`,
    lineHeight: '142%',
    fontWeight: theme.titlesMainWeight,
    color: theme.titlesMainColor,
    fontFamily: theme.typographyTitlesFontFamily,
    boxSizing: 'border-box',
    wordBreak: 'break-word',
    'p:first-of-type': {
      marginTop: 0
    },
    'p:last-of-type': {
      marginBottom: 0
    }
  };

  const descriptionStyle = {
    textAlign: theme.titlesTextAlignment,
    fontSize: `${theme.titlesDescriptionFontSize}px`,
    lineHeight: '142%',
    fontWeight: theme.titlesDescriptionWeight,
    color: theme.titlesDescriptionColor,
    fontFamily: theme.typographySupportingFontFamily,
    boxSizing: 'border-box',
    margin: '15px 0 0 0',
    wordBreak: 'break-word',
    'p:first-of-type': {
      marginTop: 0
    },
    'p:last-of-type': {
      marginBottom: 0
    }
  };

  return <>
    <div css={mainStyle}>
      <div css={contentStyle}>
        <div css={titleStyle}>{getFieldLabel(field.label, values, 'jsx', fieldsOptions, variables)}</div>
        {field.description && <div css={descriptionStyle}>{getFieldLabel(field.description, values, 'jsx', fieldsOptions, variables)}</div>}
      </div>
    </div>
  </>;
};

export default Title;
