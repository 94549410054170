/** @jsx jsx */
/* @jsxFrag React.Fragment */

import { jsx, css } from '@emotion/core';
import Color from 'color';
import React from 'react';
import Translate from '../messages/Translate.js';

import { LoaderAtom } from './atoms.js';

const Submit = ({ form, theme, disabled, loading, onSubmit, onPreviousPage, fieldsOptions, variables }) => {
  const translate = new Translate(form.messages);

  const getBackground = (gradient, gradientDirection, background, secondBackground, darken) => {
    if (gradient) {
      if (gradientDirection === 'left-right') {
        return `linear-gradient(225deg, ${Color(background).darken(darken)} 0%, ${Color(secondBackground).darken(darken)} 100%)`;
      } else if (gradientDirection === 'right-left') {
        return `linear-gradient(25deg, ${Color(background).darken(darken)} 0%, ${Color(secondBackground).darken(darken)} 100%)`;
      } else if (gradientDirection === 'top-bottom') {
        return `linear-gradient(180deg, ${Color(background).darken(darken)} 0%, ${Color(secondBackground).darken(darken)} 100%)`;
      } else if (gradientDirection === 'bottom-top') {
        return `linear-gradient(0deg, ${Color(background).darken(darken)} 0%, ${Color(secondBackground).darken(darken)} 100%)`;
      }
    } else if (darken === 0) {
      return background;
    } else {
      return Color(background).darken(darken);
    }
  };

  const alignOptions = {
    left: 'flex-start',
    center: 'center',
    right: 'flex-end'
  };

  const mainStyle = css({
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: alignOptions[theme.buttonsAlignment],
    fontFamily: theme.typographyButtonsFontFamily,
    margin: `${theme.buttonsTopMargin || 0}px 0 ${theme.buttonsBottomMargin || 0}px 0`
  });

  const buttonsStyle = css({
    width: '100%',
    maxWidth: `${theme.buttonsMaxWidth}${theme.buttonsMaxWidthType}`,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    'div': (() => {
      if (!disabled) return;

      return {
        opacity: '0.7',
        cursor: 'default',
        pointerEvents: 'none'
      };
    })
  });

  const submitButtonStyle = css({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: theme.submitIdleButtonWeight,
    width: form.pages === 1 ? '100%' : '65%',
    padding: '0 20px',
    opacity: !disabled ? 1 : 0.7,
    cursor: !disabled ? 'pointer' : 'default',
    pointerEvents: !disabled ? 'auto' : 'none',
    fontSize: `${theme.submitIdleButtonFontSize}px`,
    lineHeight: `142%`,
    fontFamily: theme.typographyButtonsFontFamily,
    color: theme.submitIdleButtonColor,
    textAlign: 'center',
    boxSizing: 'border-box',
    userSelect: 'none',
    minHeight: theme.submitIdleButtonHeight,
    borderRadius: `${theme.submitIdleButtonRoundness}${theme.submitIdleButtonRoundnessType}`,
    border: `${theme.submitIdleButtonBorderSize}px solid ${theme.submitIdleButtonBorderColor}`,
    boxShadow: theme.submitIdleButtonShadow ? `${theme.submitIdleButtonShadowOffsetX}px ${theme.submitIdleButtonShadowOffsetY}px ${theme.submitIdleButtonShadowBlur}px ${theme.submitIdleButtonShadowSpread}px ${theme.submitIdleButtonShadowColor}` : '',
    background: getBackground(theme.submitIdleButtonGradient, theme.submitIdleButtonGradientDirection, theme.submitIdleButtonBackground, theme.submitIdleButtonSecondBackground, 0),

    '&:hover': (() => {
      if (!theme.submitHoverButtonEnable) return {};

      const obj = {
        color: theme.submitHoverButtonColor,
        borderColor: theme.submitHoverButtonBorderColor,
        boxShadow: theme.submitHoverButtonShadow ? `${theme.submitHoverButtonShadowOffsetX}px ${theme.submitHoverButtonShadowOffsetY}px ${theme.submitHoverButtonShadowBlur}px ${theme.submitHoverButtonShadowSpread}px ${theme.submitHoverButtonShadowColor}` : '',
        background: getBackground(theme.submitHoverButtonGradient, theme.submitHoverButtonGradientDirection, theme.submitHoverButtonBackground, theme.submitHoverButtonSecondBackground, 0),
      };

      return obj;
    })(),
    '&:active': (() => {
      if (!theme.submitActiveButtonEnable) return {};

      const obj = {
        color: theme.submitActiveButtonColor,
        borderColor: theme.submitActiveButtonBorderColor,
        boxShadow: theme.submitActiveButtonShadow ? `${theme.submitActiveButtonShadowOffsetX}px ${theme.submitActiveButtonShadowOffsetY}px ${theme.submitActiveButtonShadowBlur}px ${theme.submitActiveButtonShadowSpread}px ${theme.submitActiveButtonShadowColor}` : '',
        background: getBackground(theme.submitActiveButtonGradient, theme.submitActiveButtonGradientDirection, theme.submitActiveButtonBackground, theme.submitActiveButtonSecondBackground, 0),
      };

      return obj;
    })()
  });

  const previousButtonStyle = css({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: theme.prevPageIdleButtonWeight,
    width: '30%',
    padding: '0 20px',
    opacity: !disabled ? 1 : 0.7,
    cursor: !disabled ? 'pointer' : 'default',
    pointerEvents: !disabled ? 'auto' : 'none',
    fontSize: `${theme.prevPageIdleButtonFontSize}px`,
    lineHeight: `142%`,
    color: theme.prevPageIdleButtonColor,
    textAlign: 'center',
    boxSizing: 'border-box',
    userSelect: 'none',
    minHeight: theme.prevPageIdleButtonHeight,
    borderRadius: `${theme.prevPageIdleButtonRoundness}${theme.prevPageIdleButtonRoundnessType}`,
    border: `${theme.prevPageIdleButtonBorderSize}px solid ${theme.prevPageIdleButtonBorderColor}`,
    minHeight: theme.prevPageIdleButtonHeight,
    boxShadow: theme.prevPageIdleButtonShadow ? `${theme.prevPageIdleButtonShadowOffsetX}px ${theme.prevPageIdleButtonShadowOffsetY}px ${theme.prevPageIdleButtonShadowBlur}px ${theme.prevPageIdleButtonShadowSpread}px ${theme.prevPageIdleButtonShadowColor}` : '',
    background: getBackground(theme.prevPageIdleButtonGradient, theme.prevPageIdleButtonGradientDirection, theme.prevPageIdleButtonBackground, theme.prevPageIdleButtonSecondBackground, 0),

    '&:hover': (() => {
      if (!theme.prevPageHoverButtonEnable) return {};

      const obj = {
        color: theme.prevPageHoverButtonColor,
        borderColor: theme.prevPageHoverButtonBorderColor,
        boxShadow: theme.prevPageHoverButtonShadow ? `${theme.prevPageHoverButtonShadowOffsetX}px ${theme.prevPageHoverButtonShadowOffsetY}px ${theme.prevPageHoverButtonShadowBlur}px ${theme.prevPageHoverButtonShadowSpread}px ${theme.prevPageHoverButtonShadowColor}` : '',
        background: getBackground(theme.prevPageoverButtonGradient, theme.prevPageHoverButtonGradientDirection, theme.prevPageHoverButtonBackground, theme.prevPageHoverButtonSecondBackground, 0),
      };

      return obj;
    })(),
    '&:active': (() => {
      if (!theme.prevPageActiveButtonEnable) return {};

      const obj = {
        color: theme.prevPageActiveButtonColor,
        borderColor: theme.prevPageActiveButtonBorderColor,
        boxShadow: theme.prevPageActiveButtonShadow ? `${theme.prevPageActiveButtonShadowOffsetX}px ${theme.prevPageActiveButtonShadowOffsetY}px ${theme.prevPageActiveButtonShadowBlur}px ${theme.prevPageActiveButtonShadowSpread}px ${theme.prevPageActiveButtonShadowColor}` : '',
        background: getBackground(theme.prevPageActiveButtonGradient, theme.prevPageActiveButtonGradientDirection, theme.prevPageActiveButtonBackground, theme.prevPageActiveButtonSecondBackground, 0),
      };

      return obj;
    })()
  });

  const neverSubmitPasswords = css({
    textAlign: 'left',
    fontSize: `${theme.generalFieldsSettingsTextIdleSupportingFontSize}px`,
    lineHeight: '142%',
    fontWeight: theme.generalFieldsSettingsTextIdleSupportingWeight,
    color: theme.generalFieldsSettingsTextIdleSupportingColor,
    boxSizing: 'border-box',
    fontFamily: theme.typographySupportingFontFamily,
    wordBreak: 'break-word',
    margin: '20px 0 0 0'
  });

  return (
    <>
      <div css={mainStyle}>
        <div css={buttonsStyle}>
          {form.pages > 1 && <div css={previousButtonStyle} onClick={() => onPreviousPage(null)}>{translate.text('pageBreakReturn')}</div>}
          <div css={submitButtonStyle} onClick={() => !disabled && onSubmit()} className="qs_submitBtn">
            {loading ? <LoaderAtom /> : translate.text('submit')}
          </div>
        </div>
      </div>

      {form.type === 'classic' && !form.owner.pro && <div css={neverSubmitPasswords}>
        Never submit passwords through QuestionScout forms!
      </div>}
    </>
  );
};

export default Submit;
