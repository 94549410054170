import styles from './PlanUnlimited.module.css';

import React, { useEffect } from 'react';

import { ReactComponent as CheckmarkIcon } from 'assets/images/checkmark-circle.svg';

const PlanUnlimited = ({ onInit }) => {
  useEffect(() => {
    async function fetchInit() {
      await onInit();
    }

    if (onInit) fetchInit();
  }, []);

  return <div className={styles.main}>
    <div className={styles.box}>
      <CheckmarkIcon />
      <h3>All features included</h3>
      <div>All existing &amp; upcoming features are included, free of charge.</div>
    </div>
    <div className={styles.box}>
      <CheckmarkIcon />
      <h3>Unlimited Forms</h3>
      <div>Unlimited forms and surveys, no questions asked.</div>
    </div>
    <div className={styles.box}>
      <CheckmarkIcon />
      <h3>Unlimited Responses</h3>
      <div>Remove all limits from the number of responses you collect.</div>
    </div>
    <div className={styles.box}>
      <CheckmarkIcon />
      <h3>Unlimited Users</h3>
      <div>Teamwork doesn't have to be expensive. Add as many users as you want.</div>
    </div>
    <div className={styles.box}>
      <CheckmarkIcon />
      <h3>Unlimited Clients</h3>
      <div>Work with as many clients as you would like on a single account.</div>
    </div>
    <div className={styles.box}>
      <CheckmarkIcon />
      <h3>Unlimited Custom Domains</h3>
      <div>Add your own domain to all of your forms and surveys and give your respondents a truly white-labelled experience.</div>
    </div>
    <div className={styles.box}>
      <CheckmarkIcon />
      <h3>Priority  Support</h3>
      <div>Live-chat support from QuestionScout staff whenever you need it.</div>
    </div>
    <div className={styles.box}>
      <CheckmarkIcon />
      <h3>10GB&nbsp;Monthly Upload</h3>
      <div>Collect up to 10GB&nbsp;Monthly in file uploads using your forms &amp; Surveys.</div>
    </div>
    <div className={styles.box}>
      <CheckmarkIcon />
      <h3>Branding Removal</h3>
      <div>Remove all QuestionScout related branding. Make all forms and surveys truly yours.</div>
    </div>
  </div>;
};

export default PlanUnlimited;
