import styles from './../Edit.module.css';
import editStyles from 'components/Builder/Edit.module.css';

import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';

import { MentionsInput, TextInput, TextArea, Toggle, Radio, Select } from 'ui';

import { ReactComponent as ReferToIcon } from 'assets/images/refer-to.svg';

const formatOptions = [
  { label: 'day.month.year hour:minute', value: 'day.month.year hour:minute' },
  { label: 'month.day.year hour:minute', value: 'month.day.year hour:minute' },
  { label: 'year.month.day hour:minute', value: 'year.month.day hour:minute' },
];

const dateSeparatorOptions = [
  { label: 'Dot .', value: '.' },
  { label: 'Dash -', value: '-' },
  { label: 'Slash /', value: '/' }
];

const hourModeOptions = [
  { label: '12-hour clock', value: '12' },
  { label: '24-hour clock', value: '24' }
];

class Datetime extends Component {
  render() {
    const {
      field, fields, handleChange, mentionsData
    } = this.props;

    return (
      <>
        <div className={editStyles.row}>
          <label>
            <div>Field Title</div>
            <a href="https://support.questionscout.com/en/articles/3947231-how-to-use-refer-to" target="_blank" rel="noopener noreferrer"><ReferToIcon data-tip="Refer to is available on this input, click to learn more." /></a>
          </label>
          <MentionsInput menu="tooltip"
            initialValue={field.label}
            onChange={(value) => handleChange({ label: value })}
            data={mentionsData}
          />
        </div>

        {/* <div className={editStyles.row}>
          <label>Placeholder Content</label>
          <TextInput value={field.placeholder} onChange={(e) => handleChange({ placeholder: e.target.value })} />
        </div> */}

        {/* <div className={editStyles.row}>
          <label>Pre-filled Content <span>{(field.value || '').length}/{field.valueMaxLength}</span></label>
          <TextArea value={field.value} onChange={(e) => handleChange({ value: e.target.value })} />
        </div> */}

        <div className={editStyles.row}>
          <label>
            <div>Supporting Text</div>
            <a href="https://support.questionscout.com/en/articles/3947231-how-to-use-refer-to" target="_blank" rel="noopener noreferrer"><ReferToIcon data-tip="Refer to is available on this input, click to learn more." /></a>
          </label>
          <MentionsInput menu="tooltip"
            initialValue={field.description}
            onChange={(value) => handleChange({ description: value })}
            data={mentionsData}
          />
        </div>

        <div className={[editStyles.row, styles.pt15].join(' ')} style={{ height: 75 }}>
          <div className={editStyles.toggle50} onClick={() => handleChange({ required: !field.required }, false)}>
            <Toggle checked={field.required} readOnly={true} /> <span>Required</span>
          </div>
          <div className={editStyles.toggle50} onClick={() => handleChange({ readonly: !field.readonly }, false)}>
            <Toggle checked={field.readonly} readOnly={true} /> <span>Read-Only</span>
          </div>
          <div className={editStyles.toggle50} onClick={() => handleChange({ hidden: !field.hidden }, false)}>
            <Toggle checked={field.hidden} readOnly={true} /> <span>Hidden</span>
          </div>
          <div style={{ clear: 'both' }}></div>
        </div>

        <div className={styles.header}>
          <span>Advanced Options</span>
        </div>

        <ul className={editStyles.select}>
          <li onClick={() => handleChange({ dateTimeMode: 'datetime' }, false)}><Radio value={field.dateTimeMode === 'datetime'} label="Date & Time Selection" onChange={() => { }} /></li>
          <li onClick={() => handleChange({ dateTimeMode: 'date' }, false)}><Radio value={field.dateTimeMode === 'date'} label="Date Selection" onChange={() => { }} /></li>
          <li onClick={() => handleChange({ dateTimeMode: 'time' }, false)}><Radio value={field.dateTimeMode === 'time'} label="Time Selection" onChange={() => { }} /></li>
        </ul>

        {field.dateTimeMode !== 'time' && <div className={editStyles.row}>
          <label>Format</label>
          <Select width={294} options={formatOptions} value={
            { label: formatOptions.find((option) => option.value === field.dateTimeFormat).label, value: field.dateTimeFormat }
          } onChange={(selected) => handleChange({ dateTimeFormat: selected.value }, false)} />
        </div>}

        {field.dateTimeMode !== 'time' && <div className={editStyles.row}>
          <label>Date separator</label>
          <Select width={294} options={dateSeparatorOptions} value={
            { label: dateSeparatorOptions.find((option) => option.value === field.dateTimeDateSeparator).label, value: field.dateTimeDateSeparator }
          } onChange={(selected) => handleChange({ dateTimeDateSeparator: selected.value }, false)} />
        </div>}

        {field.dateTimeMode !== 'date' && <div className={editStyles.row}>
          <label>Hour mode</label>
          <Select width={294} options={hourModeOptions} value={
            { label: hourModeOptions.find((option) => option.value === field.dateTimeHourMode).label, value: field.dateTimeHourMode }
          } onChange={(selected) => handleChange({ dateTimeHourMode: selected.value }, false)} />
        </div>}

        {/* {field.dateTimeMode !== 'date' && <div className={editStyles.row}>
          <label>Minutes increment</label>
          <TextInput value={field.dateTimeTimeIncrement} onChange={(e) => handleChange({ dateTimeTimeIncrement: e.target.value })} prefix={`minute${field.dateTimeTimeIncrement === '1' ? '' : 's'}`} />
        </div>} */}

        <ReactTooltip place="right" effect="solid" />
      </>
    );
  };
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Datetime);
