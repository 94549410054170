import styles from './Menu.module.css';

import React from 'react';

import {
  TitleField, SizeWeightAndColorField, CheckboxField, SizeWithColorField,
  SizeWithTypeField, SizeField, SelectField, ColorField, GradientField,
  InputStyleField, ToggleField
} from '../ThemeMenuFields.js';

const typeOptions = [
  { label: '%', value: '%' },
  { label: 'px', value: 'px' }
];

const fontWeightOptions = [
  { label: 400, value: 400 },
  { label: 600, value: 600 }
];

const alignOptions = [
  { label: 'Left', value: 'left' },
  { label: 'Center', value: 'center' },
  { label: 'Right', value: 'right' }
];

const ThankYouScreen = ({ settings, colors, onChange, onNewColor }) => {
  return (
    <>
      <TitleField title="General Screen Settings" />

      <SizeWithTypeField title="Maximum width"
        settings={settings}
        onChange={onChange}
        margin="25px 0 0 0"
        types={typeOptions}
        size="thankYouScreenWidth"
        type="thankYouScreenWidthType" />

      <SelectField title="Screen alignment"
        settings={settings}
        onChange={onChange}
        options={alignOptions}
        margin="25px 0 0 0"
        value="thankYouScreenAlignment" />

      <TitleField title="Screen Styling" />

      <SizeWeightAndColorField title="Text"
        settings={settings}
        colors={colors}
        onChange={onChange}
        onNewColor={onNewColor}
        weights={fontWeightOptions}
        margin="25px 0 0 0"
        size={`thankYouScreenTextFontSize`}
        weight={`thankYouScreenTextWeight`}
        color={`thankYouScreenTextColor`} />

      <SelectField title="Text alignment"
        settings={settings}
        onChange={onChange}
        options={alignOptions}
        margin="25px 0 0 0"
        value="thankYouScreenTextAlignment" />

      <ColorField title="Icon color"
        settings={settings}
        colors={colors}
        onChange={onChange}
        onNewColor={onNewColor}
        margin="25px 0 0 0"
        color={`thankYouScreenIconColor`} />

      <ColorField title="Links color"
        settings={settings}
        colors={colors}
        onChange={onChange}
        onNewColor={onNewColor}
        margin="25px 0 0 0"
        color={`thankYouScreenLinksColor`} />
      
      <ColorField title="Background"
        settings={settings}
        colors={colors}
        onChange={onChange}
        onNewColor={onNewColor}
        margin="25px 0 0 0"
        color={`thankYouScreenBackground`} />

      <SizeWithColorField title="Border"
        settings={settings}
        colors={colors}
        onChange={onChange}
        onNewColor={onNewColor}
        margin="25px 0 0 0"
        size={`thankYouScreenBorderSize`}
        color={`thankYouScreenBorderColor`} />

      <SizeField title="Roundness"
        settings={settings}
        onChange={onChange}
        margin="25px 0 0 0"
        size={`thankYouScreenRoundness`} />
      
      <CheckboxField label="Add shadows"
        settings={settings}
        onChange={onChange}
        margin="25px 0 0 0"
        value="thankYouScreenShadow" />

      {settings.thankYouScreenShadow && <>
        <ColorField title="Shadow color"
          settings={settings}
          colors={colors}
          onChange={onChange}
          onNewColor={onNewColor}
          margin="10px 0 0 0"
          color="thankYouScreenShadowColor" />

        <SizeField title="Shadow horizontal length"
          settings={settings}
          onChange={onChange}
          margin="25px 0 0 0"
          size="thankYouScreenShadowOffsetX" />

        <SizeField title="Shadow vertical length"
          settings={settings}
          onChange={onChange}
          margin="25px 0 0 0"
          size="thankYouScreenShadowOffsetY" />

        <SizeField title="Shadow blur"
          settings={settings}
          onChange={onChange}
          margin="25px 0 0 0"
          size="thankYouScreenShadowBlur" />

        <SizeField title="Shadow spread"
          settings={settings}
          onChange={onChange}
          margin="25px 0 0 0"
          size="thankYouScreenShadowSpread" />
      </>}
    </>
  );
}

export default ThankYouScreen;
