import styles from './Responses.module.css';

import React, { Component } from 'react';
import { connect } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroller';
import { format, formatRelative } from 'date-fns';
import scrollIntoView from 'scroll-into-view';
import PerfectScrollbar from 'react-perfect-scrollbar';
import getFieldLabel from 'helpers/getFieldLabel.js';
import ReactCountryFlag from 'react-country-flag';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import ExportModal from './modals/Export.js';
import DeleteModal from './modals/Delete.js';

import {
  getFields, getSubmissions, updateSubmissionsPagination, updateShowSelectMainColumnId,
  updateMainColumnId, updateSelectedSubmissions, clear, showExportModal, showDeleteModal,
  selectAllColumns, downloadFile
} from 'store/ducks/results.js';

import { ShortText, LongText, Dropdown, Radio, Checkbox, Datetime, FileUpload, Signature, Scale, ImageChoice, Title, Description } from './Values.js';

import { Button, BasicLoader, Select, Checkbox as CheckboxUI } from 'ui';

import { ReactComponent as CopyIcon } from 'assets/images/copy.svg';

const emptyImg = require('assets/images/no-forms.png');

const MainColumnTitle = ({ submission, columns, mainColumnId }) => {
  mainColumnId = mainColumnId || columns[0]._id;

  const mainColumnSubmissionValue = (submission.values.find((item) => item.field === (mainColumnId || columns[0]._id)) || {});
  const mainColumnSubmission = (columns.find((column) => column._id === mainColumnSubmissionValue.field) || {});

  if (submission.hidden) {
    return <span className={styles.hidden}>&nbsp;{submission.form.substring(1, submission._id.replace(/[^0-9]/g, '').length)}</span>;
  }

  return (
    <>
      {mainColumnSubmission.type === 'checkbox' && <Checkbox textOnly={true} valueObj={mainColumnSubmissionValue} options={mainColumnSubmission.options} />}
      {mainColumnSubmission.type === 'shortText' && <ShortText textOnly={true} valueObj={mainColumnSubmissionValue} />}
      {mainColumnSubmission.type === 'scale' && <Scale textOnly={true} valueObj={mainColumnSubmissionValue} />}
      {mainColumnSubmission.type === 'longText' && <LongText textOnly={true} valueObj={mainColumnSubmissionValue} />}
      {mainColumnSubmission.type === 'dropdown' && <Dropdown textOnly={true} valueObj={mainColumnSubmissionValue} options={mainColumnSubmission.options} />}
      {mainColumnSubmission.type === 'radio' && <Radio textOnly={true} valueObj={mainColumnSubmissionValue} options={mainColumnSubmission.options} />}
      {mainColumnSubmission.type === 'datetime' && <Datetime textOnly={true} valueObj={mainColumnSubmissionValue} dateTimeMode={mainColumnSubmission.dateTimeMode} />}
      {mainColumnSubmission.type === 'fileUpload' && <FileUpload textOnly={true} valueObj={mainColumnSubmissionValue} />}
    </>
  );
};

const getSubmissionStatus = (submitted, active) => {
  if (typeof submitted === 'undefined') return 'submitted';
  if (submitted) return 'submitted';
  if (!submitted && active) return 'live';
  if (!submitted && !active) return 'abandoned';
};

class Responses extends Component {
  constructor(props) {
    super(props);

    this.submissionsLoading = false;
    this.state = {
      loadingMoreOnListReachEnd: false
    };
  }

  UNSAFE_componentWillMount = () => {
    this.submissionsLoading = true;

    this.props.getFields().then(this.props.getSubmissions()).then(() => {
      this.submissionsLoading = false;
    });
  }

  componentWillUnmount = () => {
    this.props.clear();
  }

  loadMoreSubmissions = () => {
    if (this.submissionsLoading) return;

    this.submissionsLoading = true;

    this.props.updateSubmissionsPagination(this.props.skip + this.props.limit);

    this.props.getSubmissions().then(() => {
      this.submissionsLoading = false;
    });
  }

  handleOptionsSelectClick = (value) => {
    this.props.updateShowSelectMainColumnId(value);
  }

  handleMainColumnIdChange = (id) => {
    this.props.updateMainColumnId(id);
    this.props.updateShowSelectMainColumnId(false);
  }

  onListReachEnd = () => {
    if (this.submissionsLoading || !this.props.hasMore) return;

    this.submissionsLoading = true;
    this.setState({ loadingMoreOnListReachEnd: true });

    this.props.updateSubmissionsPagination(this.props.skip + this.props.limit);

    this.props.getSubmissions().then(() => {
      this.submissionsLoading = false;
      this.setState({ loadingMoreOnListReachEnd: false });
    });
  }

  scrollToSubmission = (id) => {
    scrollIntoView(document.getElementById(id), {
      time: 300
    });
  }

  toggleSubmission = (id) => {
    const list = this.props.selectedSubmissions;
    const index = list.indexOf(id);

    if (index !== -1) {
      list.splice(index, 1);
    } else {
      list.push(id);
    }

    this.props.updateSelectedSubmissions(list);
  }

  selectAllSubmissions = () => {
    const list = [];

    if (this.props.submissions.length !== this.props.selectedSubmissions.length) {
      for (let submission of this.props.submissions) {
        list.push(submission._id);
      }
    }

    this.props.updateSelectedSubmissions(list);
  }

  confirmExportSubmissions = () => {
    this.props.selectAllColumns();
    this.props.showExportModal();
  }

  confirmDeleteSubmissions = () => {
    this.props.showDeleteModal();
  }

  renderColumns = (columns, submission, section) => {
    const { columnOptions } = this.props;
  
    return columns.filter((f) => f.section === section).map((column) => {
      let valueObj = (submission.values.find((item) => item.field === column._id) || null);
      let fieldsOptions = {};

      if (valueObj && valueObj.visible === false) return <div key={column._id}></div>;
      if (valueObj && !valueObj.type) valueObj.type = column.type;

      for (let f of columns) fieldsOptions[f._id] = f.options || [];

      return (
        <div key={column._id} className={styles.td}>
          <div className={styles.label}>
            <span>
              {columnOptions[column.type] && <img height="15px" src={columnOptions[column.type].icon} alt="" />}
            </span>
            {getFieldLabel(column.label || column.placeholder || column.type, submission.values, 'jsx', fieldsOptions, submission.calculationVariables)}
          </div>

          {!submission.hidden && <div className={styles.value}>
            {valueObj && column.type === 'shortText' && <ShortText valueObj={valueObj} />}
            {valueObj && column.type === 'longText' && <LongText valueObj={valueObj} />}
            {valueObj && column.type === 'dropdown' && <Dropdown valueObj={valueObj} options={column.options} />}
            {valueObj && column.type === 'radio' && <Radio valueObj={valueObj} options={column.options} />}
            {valueObj && column.type === 'checkbox' && <Checkbox valueObj={valueObj} options={column.options} />}
            {valueObj && column.type === 'imageChoice' && <ImageChoice valueObj={valueObj} options={column.options} />}
            {valueObj && column.type === 'datetime' && <Datetime valueObj={valueObj} dateTimeMode={column.dateTimeMode} />}
            {valueObj && column.type === 'fileUpload' && <FileUpload valueObj={valueObj} handleDownload={this.props.downloadFile} />}
            {valueObj && column.type === 'signature' && <Signature valueObj={valueObj} />}
            {valueObj && column.type === 'scale' && <Scale valueObj={valueObj} />}
            {valueObj && column.type === 'title' && <Title columnObj={column} values={submission.values} variables={submission.calculationVariables} options={fieldsOptions} />}
            {valueObj && column.type === 'description' && <Description valueObj={valueObj} values={submission.values} variables={submission.calculationVariables} options={fieldsOptions} />}

            {column.type === 'section' && <div className={styles.section}>{this.renderColumns(columns, submission, column._id)}</div>}
          </div>}

          {submission.hidden && <div className={[styles.value, styles.hidden].join(' ')}>
            {Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)}
          </div>}
        </div>
      )
    })
  }

  render() {
    const {
      submissions, hasMore, columns, columnOptions, mainColumnId, submissionsTotal, selectedSubmissions, user
    } = this.props;

    const {
      loadingMoreOnListReachEnd
    } = this.state;

    const selectedColumn = columns.find((column) => column._id === mainColumnId) || columns[0];

    return (
      <div className={styles.main}>
        {columns.length !== 0 && <ExportModal />}
        {columns.length !== 0 && <DeleteModal />}

        <div className={styles.list}>
          <div className={styles.header}>
            {selectedColumn && <Select width={150} options={columns.filter((c) => ['title', 'description', 'section'].indexOf(c.type) === -1).map((column) => {
              return { label: getFieldLabel(column.label), value: column._id };
            })} value={{ label: getFieldLabel(selectedColumn.label), value: selectedColumn._id }} onChange={(selected) => this.handleMainColumnIdChange(selected.value)} />}
          </div>

          <div className={styles.content}>
            <div className={styles.listHeader}>
              <div className={styles.listHeaderTitle}>
                <CheckboxUI value={selectedSubmissions.length === submissions.length && submissions.length !== 0} onChange={() => { }} onClick={() => this.selectAllSubmissions()} />
                {columns.length > 0 && getFieldLabel((columns.find((column) => column._id === mainColumnId) || columns[0]).label)}
              </div>

              <div className={styles.listHeaderColumnName}></div>
            </div>

            <PerfectScrollbar className={styles.scrollArea} options={{
              wheelPropagation: false
            }} onYReachEnd={(props) => this.onListReachEnd(props)}>
              {(columns.length > 0 && submissions.length > 0) && submissions.filter((f) => f.type !== 'section').map((submission) => {
                return (
                  <div key={submission._id} className={styles.listItem}>
                    <CheckboxUI value={selectedSubmissions.indexOf(submission._id) !== -1} onChange={() => { }} onClick={() => this.toggleSubmission(submission._id)} />

                    <div onClick={() => this.scrollToSubmission(submission._id)} className={styles.listItemLink}>
                      <div className={styles.listItemLeft}>
                        <div className={styles.listItemName}>
                          <MainColumnTitle submission={submission} columns={columns} mainColumnId={mainColumnId} />
                        </div>
                        <div className={styles.listItemDate}>{format(new Date(submission.createdAt), 'dd-MM-yyyy hh:mma')}</div>
                      </div>

                      {/* {submission.number && <div className={styles.listItemRight}>#{submission.number}</div>} */}
                    </div>
                  </div>
                )
              })}

              {loadingMoreOnListReachEnd && <div className={styles.scrollLoading}>
                <BasicLoader size={30} />
              </div>}
            </PerfectScrollbar>

            <div className={styles.deleteSubmissions}>
              <Button disabled={submissions.length === 0} size="small" theme="blue" width={145} onClick={this.confirmExportSubmissions}>{`Export ${selectedSubmissions.length === 0 ? 'All' : selectedSubmissions.length} ${selectedSubmissions.length === 1 ? 'Reply' : 'Replies'}`}</Button>
              <Button disabled={submissions.length === 0} size="small" theme="red" width={145} onClick={this.confirmDeleteSubmissions}>{`Delete ${selectedSubmissions.length === 0 ? 'All' : selectedSubmissions.length} ${selectedSubmissions.length === 1 ? 'Reply' : 'Replies'}`}</Button>
            </div>
          </div>
        </div>

        <div className={styles.submissions}>
          <div className={styles.header}>
            <div>Responses</div>
            {user.plan.submissions < 10000 && <span>{user.planUsage.submissions}/{user.plan.submissions} collected this month</span>}
            {user.plan.submissions >= 10000 && <span>{user.planUsage.submissions} collected this month</span>}
          </div>

          <InfiniteScroll
            pageStart={0}
            loadMore={() => this.loadMoreSubmissions()}
            initialLoad={false}
            hasMore={hasMore}
            useWindow={false}
            threshold={500}
            className={styles.content}
            loader={<BasicLoader key="0" size={30} />}>

            <div className={styles.table}>
              {submissionsTotal === 0 && <div className={styles.empty}>
                <img src={emptyImg} alt="" width="100" />
                <p>You haven’t received any replies yet</p>
                <span>Start collecting replies by sharing your form. As soon as you’ve received replies, you will find them here!</span>
              </div>}

              {(columns.length > 0 && submissions.length > 0) && submissions.map((submission) => {
                return (
                  <div id={submission._id} key={submission._id} className={styles.tr}>
                    <div className={styles.trHeader}>
                      <div className={styles.location}>
                        Person from {submission.country ? (submission.country.name || '').replace('Taiwan, Province of China', 'Taiwan') : 'unknown location'}
                        {submission.country && <ReactCountryFlag countryCode={submission.country.code} style={{
                          fontSize: '1em',
                          lineHeight: '1em'
                        }} />}
                      </div>

                      <div className={styles.time}>
                        {formatRelative(new Date(submission.createdAt), new Date())}
                      </div>
                    </div>

                    {this.renderColumns(columns, submission, 'root')}

                    {!submission.hidden && submission.calculationVariables && submission.calculationVariables.length > 0 && <div className={styles.urlParams}>
                      <span>Calculation Variables</span>
                      <ul>
                        {submission.calculationVariables.map((param, index) => <li key={index}><strong>{param.name}:</strong> {param.value}</li>)}
                      </ul>
                    </div>}

                    {!submission.hidden && submission.urlParams && submission.urlParams.length > 0 && <div className={styles.urlParams}>
                      <span>URL Parameters</span>
                      <ul>
                        {submission.urlParams.map((param, index) => <li key={index}><strong>{param.key}:</strong> {param.value}</li>)}
                      </ul>
                    </div>}

                    <div className={styles.details}>
                      <ul>
                        <li>
                          <div className={styles.title}>Response Status:</div>
                          <span className={[styles.badge, styles[getSubmissionStatus(submission.submitted, submission.active)]].join(' ')}>{getSubmissionStatus(submission.submitted, submission.active)}</span>
                        </li>
                        <li>
                          <div className={styles.title}>Device:</div>
                          <span className={styles.text}>{submission.mobile ? 'Mobile' : 'Desktop'}</span>
                        </li>
                        {/* {submission.number && <li>
                          <div className={styles.title}>Response Number:</div>
                          <div className={styles.text}>#{submission.number}</div>
                        </li>} */}
                        {submission.referrer && <li>
                          <div className={styles.title}>Referrer:</div>
                          <span className={styles.text}>
                            <div>{submission.referrer}</div>
                            <CopyToClipboard text={submission.referrer}>
                              <CopyIcon />
                            </CopyToClipboard>
                          </span>
                        </li>}
                      </ul>
                    </div>
                  </div>
                )
              })}

            </div>
          </InfiniteScroll>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    user: state._users.user,
    submissions: state.results.submissions,
    columns: state.results.columns,
    skip: state.results.submissionsSkip,
    limit: state.results.submissionsLimit,
    hasMore: state.results.submissionsHasMore,
    submissionsTotal: state.results.submissionsTotal,
    columnOptions: state.results.columnOptions,
    selectedSubmissions: state.results.selectedSubmissions,

    mainColumnId: state.results.mainColumnId,
    showSelectMainColumnId: state.results.showSelectMainColumnId
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    clear: () => dispatch(clear()),
    getFields: () => dispatch(getFields()),
    getSubmissions: () => dispatch(getSubmissions()),
    selectAllColumns: () => dispatch(selectAllColumns()),
    updateSubmissionsPagination: (skip) => dispatch(updateSubmissionsPagination(skip)),
    updateShowSelectMainColumnId: (value) => dispatch(updateShowSelectMainColumnId(value)),
    updateMainColumnId: (id) => dispatch(updateMainColumnId(id)),
    updateSelectedSubmissions: (value) => dispatch(updateSelectedSubmissions(value)),
    showExportModal: () => dispatch(showExportModal()),
    showDeleteModal: () => dispatch(showDeleteModal()),
    downloadFile: (id) => dispatch(downloadFile(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Responses);
