import styles from './Dashboard.module.css';

import React, { Component, useState } from 'react';
import { Link } from 'react-router-dom';
import { connect, useSelector, useDispatch } from 'react-redux';
import { formatDistanceToNow } from 'date-fns';
import ClampLines from 'react-clamp-lines';
import _remove from 'lodash/remove';
import { verifyPermission } from 'misc/helpers.js';

import { Button, Loader, DropdownMenu, Avatar } from 'ui';

import CreateEditWorkspaceModal from './modals/CreateEditWorkspace.js';
import DeleteWorkspaceModal from './modals/DeleteWorkspace.js';
import DeleteFormModal from './modals/DeleteForm.js';
import WorkspaceUsersModal from './modals/WorkspaceUsers.js';
import Changelog from './Changelog.js';
import Header from '../Header/Header.js';

import {
  clear, showCreateEditWorkspaceModal,
  showDeleteWorkspaceModal, showDeleteFormModal, showWorkspaceUsersModal
} from 'store/ducks/dashboard.js';

import {
  getWorkspaces, deleteWorkspace
} from 'store/ducks/_workspaces.js';

import {
  getForms, updateForm, duplicateForm
} from 'store/ducks/_forms.js';

import {
  getDomains
} from 'store/ducks/_domains.js';

import {
  updateUser
} from 'store/ducks/_users.js';

import {
  getChangelog
} from 'store/ducks/_changelog.js';

import { ReactComponent as DropdowArrowSmall } from 'assets/images/dropdow-arrow-small.svg';
import { ReactComponent as MoreIcon } from 'assets/images/more.svg';
import { ReactComponent as Minimize } from 'assets/images/minimize.svg';
import { ReactComponent as MenuSettingsIcon } from 'assets/images/menu-settings.svg';

import traditionalFormPreviewIcon from 'assets/images/traditional-form-preview.png'; // preload
import conversationalFormPreviewIcon from 'assets/images/conversational-form-preview.png'; // preload

const noFormsImg = require('assets/images/no-forms.png');

const getWorkspaceItems = ({ workspaceId, deleteOnClick, editOnClick }) => {
  return [{
    type: 'label',
    text: 'ACTIONS'
  }, {
    type: 'action',
    text: 'Edit',
    params: [workspaceId],
    onClick: editOnClick,
    disabled: !workspaceId
  }, {
    type: 'hr'
  }, {
    type: 'action',
    text: 'Delete',
    theme: 'red',
    params: [workspaceId],
    onClick: deleteOnClick,
    disabled: !workspaceId
  }];
}

const getFormItems = ({ user, formId, formStatus, workspaceId, workspaces, deleteOnClick, duplicateOnClick, workspaceOnClick }) => {
  const selectedWorkspaceObj = workspaces.find((w) => w._id === workspaceId) || null;

  const params = [{
    key: 'goToLabel',
    type: 'label',
    text: 'GO TO'
  }, {
    key: 'build',
    type: 'link',
    text: 'Edit',
    link: `/builder/${formId}/build`
  }, {
    key: 'publish',
    type: 'link',
    text: 'Share',
    link: `/builder/${formId}/publish`
  }, {
    key: 'statistics',
    type: 'link',
    text: 'Statistics',
    link: `/builder/${formId}/results/statistics`
  }, {
    key: 'replies',
    type: 'link',
    text: 'Replies',
    link: `/builder/${formId}/results/responses`
  }, {
    key: 'goToHr',
    type: 'hr'
  }, {
    type: 'label',
    text: 'ACTIONS'
  }, {
    key: 'move',
    type: 'list',
    text: 'Move to workspace',
    id: 'workspaces',
    onClick: workspaceOnClick,
    items: [{
      _id: null,
      name: 'Unassigned',
    }, ...(workspaces.filter((workspace) => {
      if (user.role !== 'user') return true;

      const permissions = workspace.users.find((u) => u.user === user._id);

      return permissions && permissions.edit;
    }) || [])],
    selected: workspaceId,
    params: [formId]
  }, {
    key: 'duplicate',
    type: 'action',
    text: 'Duplicate',
    params: [formId],
    onClick: duplicateOnClick
  }, {
    key: 'openForm',
    type: 'link',
    text: 'Open Form',
    link: `https://form.questionscout.com/${formId}`,
    outside: true
  }, {
    key: 'beforeDeleteHr',
    type: 'hr'
  }, {
    key: 'delete',
    type: 'action',
    text: 'Delete',
    theme: 'red',
    params: [formId],
    onClick: deleteOnClick
  }];

  let permissions;

  if (formStatus === 'draft' || formStatus === 'disabled') {
    params.find((p) => p.key === 'openForm').hidden = true;
  }

  if (user.role === 'user' && workspaceId !== null && selectedWorkspaceObj) {
    permissions = selectedWorkspaceObj.users.find((u) => u.user === user._id);

    if (permissions && !permissions.read) {
      params.find((p) => p.key === 'statistics').hidden = true;
      params.find((p) => p.key === 'replies').hidden = true;
    }

    if (permissions && !permissions.edit) {
      params.find((p) => p.key === 'build').hidden = true;
      params.find((p) => p.key === 'publish').hidden = true;
      params.find((p) => p.key === 'move').hidden = true;
    }

    if (permissions && !permissions.delete) {
      params.find((p) => p.key === 'beforeDeleteHr').hidden = true;
      params.find((p) => p.key === 'delete').hidden = true;
    }

    if (permissions && !permissions.create) {
      params.find((p) => p.key === 'duplicate').hidden = true;
    }

    if (params.find((p) => p.key === 'build').hidden &&
      params.find((p) => p.key === 'publish').hidden &&
      params.find((p) => p.key === 'statistics').hidden &&
      params.find((p) => p.key === 'replies').hidden) {

      params.find((p) => p.key === 'goToHr').hidden = true;
      params.find((p) => p.key === 'goToLabel').hidden = true;
    }
  }

  return params;
};

const FolderIcon = ({ color, className }) => {
  return (
    <svg className={className} width="20" height="18" xmlns="http://www.w3.org/2000/svg"><path d="M17.5 17.5h-15A2.47 2.47 0 0 1 0 15.07V2.93A2.47 2.47 0 0 1 2.5.5h4.6a1 1 0 0 1 .77.37l2.6 3.18h7A2.47 2.47 0 0 1 20 6.48v8.59a2.47 2.47 0 0 1-2.5 2.43z" fill={color} fillRule="nonzero" /></svg>
  );
};

const CreateNewFormButton = ({ workspaceId }) => {
  const user = useSelector(state => state._users.user);
  const workspaces = useSelector(state => state._workspaces.workspaces);
  const workspace = workspaces.find((w) => w._id === workspaceId) || null;

  let permissions;

  if (user.role === 'user' && workspaceId !== null && workspace) {
    permissions = workspace.users.find((u) => u.user === user._id);

    if (permissions && !permissions.create) {
      return <></>;
    }
  }

  return <Button theme="blue" link={`/form/create/template/${workspaceId || ''}`}>Create New Form</Button>;
};

const Form = ({ form, workspaces, workspace }) => {
  const user = useSelector(state => state._users.user);

  const [showMenu, setShowMenu] = useState(false);

  const dispatch = useDispatch();

  const permissions = (workspace.users || []).find((u) => u.user === user._id);

  const formLink = (() => {
    if (user.role === 'user' && workspace._id !== null) {
      if (permissions && permissions.edit) {
        return `/builder/${form._id}/build`;
      } else if (permissions && permissions.read) {
        return `/builder/${form._id}/results/responses`;
      } else {
        return null;
      }
    } else {
      return `/builder/${form._id}/build`;
    }
  })();

  const actionButton = (() => {
    if (user.role === 'user' && workspace._id !== null) {
      if (permissions && permissions.edit) {
        return <Button theme="white" width="190px" link={`/builder/${form._id}/build`}>Edit form</Button>;
      } else if (permissions && permissions.read) {
        return <Button theme="white" width="190px" link={`/builder/${form._id}/results/responses`}>View responses</Button>;
      } else {
        return <Button theme="white" width="190px" href={`https://form.questionscout.com/${form._id}`}>Open Form</Button>;
      }
    } else {
      return <Button theme="white" width="190px" link={`/builder/${form._id}/build`}>Edit form</Button>;
    }
  })();

  return (
    <div key={form._id} className={styles.form}>
      <div className={styles.formContent}>
        <Link to={formLink || ''} className={[styles.title, !formLink ? styles.noHref : ''].join(' ')}>
          <div className={styles.background} />
          <span><ClampLines text={form.name || ''} lines={3} buttons={false} /></span>
        </Link>

        {form.unreadedSubmissionsCount > 0 && <div className={styles.status}><span></span>{form.unreadedSubmissionsCount} new response{form.unreadedSubmissionsCount !== 1 && 's'}</div>}
        {form.unreadedSubmissionsCount === 0 && <div className={styles.status}>No new responses</div>}

        {!form.lastSubmission && <div className={styles.lastSubmission}>This form hasn’t received responses yet.</div>}
        {form.lastSubmission && <div className={styles.lastSubmission}>Last response was {formatDistanceToNow(new Date(form.lastSubmission))} ago.</div>}

        <div className={styles.action}>
          {actionButton}

          <div className={styles.more} onClick={() => setShowMenu(true)}><MoreIcon /></div>
        </div>

        <div className={styles.badges}>
          <span className={styles[form.status]}>{form.status}</span>
          <span className={styles[form.type]}>{{classic: 'traditional', conversational: 'conversational'}[form.type]}</span>
        </div>

        {showMenu && <DropdownMenu onClose={() => setShowMenu(false)}
          position={{ top: 210, right: 20 }}
          width={246}
          items={getFormItems({
            user,
            formId: form._id,
            formStatus: form.status,
            workspaceId: workspace._id,
            workspaces,
            deleteOnClick: (id) => {
              setShowMenu(false);
              dispatch(showDeleteFormModal(id));
            },
            duplicateOnClick: (id) => {
              setShowMenu(false);
              dispatch(duplicateForm(id)).then((id) => {
                if (id) window.location.replace(`/builder/${id}/build`);
              });
            },
            workspaceOnClick: (formId, workspaceId) => {
              setShowMenu(false);
              dispatch(updateForm(formId, {
                workspace: workspaceId
              }));
            }
          })} />}
      </div>
    </div>
  );
}

const Workspace = ({ workspaces, workspace, forms, loading, minimized, toggleWorkspace }) => {
  const dispatch = useDispatch();

  const user = useSelector(state => state._users.user);
  const users = useSelector(state => state._users.users);

  const [showMenu, setShowMenu] = useState(false);

  let workspaceUsers = [];

  if (workspace !== null) {
    workspaceUsers = users.filter((u) => u.role !== 'user' || (workspace.users || []).findIndex((wu) => wu.user === u._id) !== -1);
  }

  return (
    <div className={styles.workspace}>
      <div className={styles.head}>
        <div className={styles.left}>
          <div className={[styles.name, minimized ? styles.minimized : ''].join(' ')} onClick={() => toggleWorkspace(workspace._id ? workspace._id : 'unassigned')}>
            <DropdowArrowSmall className={styles.arrow} />
            <FolderIcon color={workspace.color} className={styles.folderIcon} />
            <ClampLines text={workspace.name} lines={1} buttons={false} />
          </div>
          <div className={styles.formsNumber}>{forms.length} form{forms.length === 1 ? '' : 's'}</div>
          {verifyPermission('editWorkspace', user) && workspace._id !== null && <div className={styles.settings}>
            <div className={styles.button} onClick={() => setShowMenu(true)}>
              <MenuSettingsIcon /><span>Settings</span>
            </div>

            {showMenu && <DropdownMenu onClose={() => setShowMenu(false)}
              position={{ top: 25, left: 0 }}
              width={200}
              items={getWorkspaceItems({
                workspaceId: workspace._id,
                deleteOnClick: (id) => {
                  dispatch(showDeleteWorkspaceModal(id));
                  setShowMenu(false);
                },
                editOnClick: (id) => {
                  dispatch(showCreateEditWorkspaceModal(id));
                  setShowMenu(false);
                }
              })} />}
          </div>}
        </div>
        <div className={styles.right} onClick={() => (user.role !== 'user' && workspace._id !== null) && dispatch(showWorkspaceUsersModal(workspace._id))}>
          {verifyPermission('manageWorkspaceUsers', user) && workspace._id !== null && <div className={styles.addUser}><Minimize /></div>}

          {workspace._id === null && <>
            <div className={styles.users}>
              {users.length > 0 && users.slice(0, 10).map((u) => <Avatar key={u._id} name={u.name} email={u.email} size={33} tooltip={true} clickable={user.role !== 'user' && workspace._id !== null} border={true} />)}
            </div>
            {users.length > 10 && <div className={[styles.more, styles.notClickable].join(' ')}>+{users.length - 10}</div>}
          </>}

          {workspace._id !== null && <>
            <div className={styles.users}>
              {workspaceUsers.length > 0 && workspaceUsers.slice(0, 10).map((u) => <Avatar key={u._id} name={u.name} email={u.email} size={33} tooltip={true} clickable={user.role !== 'user' && workspace._id !== null} border={true} />)}
            </div>
            {workspaceUsers.length > 10 && <div className={styles.more}>+{workspaceUsers.length - 10}</div>}
          </>}
        </div>
      </div>

      {minimized && forms.length === 0 && !loading && <div className={styles.empty}>
        {workspace._id === null && <img src={noFormsImg} alt="" width="100" />}
        <p>You haven’t added any forms to this Workspace yet.</p>
        <CreateNewFormButton workspaceId={workspace._id} />
      </div>}

      {minimized && <div className={styles.forms}>
        {forms.map((form) => <Form key={form._id} form={form} workspaces={workspaces} workspace={workspace} />)}
      </div>}
    </div>
  );
}

class Dashboard extends Component {
  componentWillUnmount = () => {
    this.props.clear();
  }

  UNSAFE_componentWillMount = () => {
    document.title = 'QuestionScout';

    this.props.getWorkspaces();
    this.props.getForms();
    this.props.getDomains();
    this.props.getChangelog();

    new Image().src = traditionalFormPreviewIcon;
    new Image().src = conversationalFormPreviewIcon;
  }

  toggleWorkspace = (id) => {
    let minimizedWorkspaces = [...this.props.user.uiSettings.minimizedWorkspaces];

    const index = minimizedWorkspaces.indexOf(id);

    if (index !== -1) {
      minimizedWorkspaces.splice(index, 1);
    } else {
      minimizedWorkspaces = [...minimizedWorkspaces, id];
    }

    this.props.updateUser({
      uiSettings: { ...this.props.user.uiSettings, minimizedWorkspaces }
    });
  }

  toggleHiddenWorkspaces = (id) => {
    let hiddenWorkspaces = [...this.props.user.uiSettings.hiddenWorkspaces];
    const index = hiddenWorkspaces.indexOf(id);

    if (index === -1) {
      hiddenWorkspaces = [...hiddenWorkspaces, id];
    } else {
      hiddenWorkspaces.splice(hiddenWorkspaces.indexOf(id), 1)
    }

    this.props.updateUser({
      uiSettings: { ...this.props.user.uiSettings, hiddenWorkspaces }
    });
  }

  render() {
    const {
      workspaces, forms, user, loadingGetWorkspaces, loadingGetForms, loadingDuplicateForm, changelog
    } = this.props;

    return (
      <>
        <DeleteWorkspaceModal />
        <CreateEditWorkspaceModal />
        <DeleteFormModal />
        <WorkspaceUsersModal />

        {changelog.length > 0 && <Changelog data={changelog} />}

        <Header showAccount={true} showMessages={true} showMenu={true} />

        <div className={styles.content}>
          <div className={styles.head}>
            <div className={styles.left}>
              <h1>Workspaces</h1>
              {verifyPermission('createWorkspace', user) && <div className={styles.createWorkspace} onClick={() => this.props.showCreateEditWorkspaceModal()}><Minimize /></div>}
            </div>
            <div className={styles.left}>
              <CreateNewFormButton workspaceId={null} />
            </div>
          </div>

          <div className={styles.workspaces}>
            <Workspace workspaces={workspaces}
              forms={forms.filter((form) => form.workspace === null)}
              loading={loadingGetWorkspaces || loadingGetForms}
              minimized={user && user.uiSettings && user.uiSettings.minimizedWorkspaces ? user.uiSettings.minimizedWorkspaces.indexOf('unassigned') === -1 : false}
              toggleWorkspace={this.toggleWorkspace}
              workspace={{
                _id: null,
                name: 'Uncategorized',
                color: '#BFBFBF'
              }} />

            {workspaces.map((workspace) => <Workspace key={workspace._id}
              workspaces={workspaces}
              loading={loadingGetWorkspaces || loadingGetForms}
              workspace={workspace}
              forms={forms.filter((form) => form.workspace === workspace._id)}
              minimized={user && user.uiSettings && user.uiSettings.minimizedWorkspaces ? user.uiSettings.minimizedWorkspaces.indexOf(workspace._id) === -1 : false}
              toggleWorkspace={this.toggleWorkspace} />)}
          </div>

          {(loadingGetWorkspaces || loadingGetForms || loadingDuplicateForm) && <div className={styles.loading}>
            <Loader size={50} />
          </div>}
        </div>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    workspaces: state._workspaces.workspaces,
    forms: state._forms.forms,
    user: state._users.user,
    changelog: state._changelog.changelog,
    loadingGetWorkspaces: state._workspaces.getWorkspaces.loading,
    loadingGetForms: state._forms.getForms.loading,
    loadingDuplicateForm: state._forms.duplicateForm.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    clear: () => dispatch(clear()),
    updateUser: (data) => dispatch(updateUser(data)),
    getWorkspaces: () => dispatch(getWorkspaces()),
    getForms: () => dispatch(getForms()),
    getDomains: () => dispatch(getDomains()),
    deleteWorkspace: (id) => dispatch(deleteWorkspace(id)),
    showCreateEditWorkspaceModal: () => dispatch(showCreateEditWorkspaceModal()),
    showDeleteWorkspaceModal: (workspace) => dispatch(showDeleteWorkspaceModal(workspace)),
    getChangelog: () => dispatch(getChangelog()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
