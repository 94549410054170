import React, { useEffect }  from 'react';
import { withRouter } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';

import ThemesDashboard from './ThemesDashboard.js';
import Theme from './Theme/Theme.js';

import CreateThemeModal from './modals/CreateTheme.js';
import DeleteThemeModal from './modals/DeleteTheme.js';

import {
  getThemes
} from 'store/ducks/_themes.js';

const Themes = ({ match }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getThemes());
  }, []);

  return (
    <>
      <CreateThemeModal />
      <DeleteThemeModal />

      <Switch>
        <Route path="/themes" exact component={ThemesDashboard} />
        <Route path="/themes/:type?/:id?/:page?" exact component={Theme} />
      </Switch>
    </>
  );
}

export default withRouter(Themes);
