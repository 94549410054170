import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { store } from './store';
import * as Sentry from '@sentry/browser';
import { StripeProvider } from 'react-stripe-elements';

import './index.scss';
import config from 'config.js';
import App from './components/App/App.js';
import * as serviceWorker from './serviceWorker';

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({ dsn: 'https://9066de05eb724c4c9912e2ce6bd9234e@sentry.io/1492827' });
}

// Clear ioTasks list
localStorage.setItem('ioTasks', '[]');

ReactDOM.render(
  <StripeProvider apiKey={config.stripePublicKey}>
    <Provider store={store}>
      <App />
    </Provider>
  </StripeProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

window['__react-beautiful-dnd-disable-dev-warnings'] = true;