import styles from './OtpCreateModal.module.css';

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import QRCode from 'qrcode.react';
import ReactCodeInput from 'react-verification-code-input';

import { hideOtpCreateModal } from 'store/ducks/user.js';
import { getUser, initOtp, enableOtp } from 'store/ducks/_users.js';

import checkmarkCircle from 'assets/images/checkmark-circle.svg';

import { Button, Modal, Loader } from 'ui';

const OtpCreateModal = () => {
  const dispatch = useDispatch();

  const show = useSelector(state => state.user.showOtpCreateModal);

  const [qrUrl, setQrUrl] = useState(null);
  const [code, setCode] = useState('');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const init = async () => {
    setLoading(true);
    const res = await dispatch(initOtp());

    setQrUrl(res && res.otpAuth ? res.otpAuth : null);
    setLoading(false);
  };

  const cancel = () => {
    dispatch(hideOtpCreateModal());
    setQrUrl(null);
    setError(null);
    setLoading(false);
    setSuccess(false);
  };

  const save = async (value) => {
    setError(null);
    setLoading(true);

    try {
      const res = await dispatch(enableOtp(value));
      await dispatch(getUser());

      if (res.success) setSuccess(true);

      setLoading(false);
    } catch (e) {
      if (e.message) setError(e.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (show) init();
  }, [show]);

  return <>
    <Modal isOpen={show} onRequestClose={cancel} width={450} header="Enabling Two-Factor Authentication" footer={<>
      <Button size="small" theme="white" onClick={cancel}>{success ? 'Close' : 'Cancel'}</Button>
      {!success && <Button size="small" theme="blue" onClick={() => save(code)} margin="0 0 0 15px" disabled={loading || code.length !== 6}>Enable</Button>}
    </>}>
      <div className={styles.content}>
        {loading && <Loader size={40} />}
        {!loading && success && <div className={styles.success}>
          <img src={checkmarkCircle} width="50px" />
          <p>Nice! You've added two-factor authentication to your account!</p>
        </div>}
        {!loading && !success && <>
          <QRCode value={qrUrl || ''} size={150} />

          <p><strong>Step one:</strong> Install the Google Authenticator, Authy or any other compatible 2FA app on your mobile device if you don’t already have one installed.</p>
          <p><strong>Step two:</strong> Scan QR code with the mobile authenticator app.</p>

          <hr />

          <div className={styles.digitsTitle}>Insert your 6-digit code here</div>
          <ReactCodeInput type="number" onChange={(value) => setCode(value)} onComplete={(c) => save(c)} fields={6} placeholder={['0', '0', '0', '0', '0', '0']} className={styles.digits} fieldWidth={25} fieldHeight={40} />
          {error && <div className={styles.error}>{error}</div>}
        </>}
      </div>
    </Modal>
  </>;
}

export default OtpCreateModal;
