/** @jsx jsx */
/* @jsxFrag React.Fragment */

import { jsx } from '@emotion/core';
import React from 'react';

import FormWrapper from 'form/components/FormWrapper.js';

const Section = (props) => {
  const formCopy = { ...props.form, type: 'classic'};

  return <FormWrapper {...props} form={formCopy} />;
};

export default Section;
