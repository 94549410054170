import styles from './PlanForm.module.css';

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getData } from 'country-list';
import { withRouter } from 'react-router-dom';
import { injectStripe } from 'react-stripe-elements';
import { checkVAT, countries as euVatCountries } from 'jsvat';

import { vatEuCountryCodes } from 'misc/helpers.js';

import { Button, TextInput, Select, CreditCardInput, FormRow, Loader } from 'ui';

import {
  updateUser, getUser
} from 'store/ducks/_users.js';

import {
  updateBilling
} from 'store/ducks/user.js';

import {
  updateForm, assignSubscription, saveUserSource, saveCustomerAddress, updateCollectionMethod, updateLoading, updateCreditCardErrorMessage, updateFormErrors
} from 'store/ducks/plan.js';

const countries = getData().map((obj) => {
  return {
    label: obj.name.replace('Taiwan, Province of China', 'Taiwan'),
    value: obj.code
  };
});

class PlanForm extends Component {
  componentDidMount = () => {
    this.props.updateForm('email', this.props.user.email, false);
    this.props.updateForm('country', this.props.user.country, false);
  };

  afterSubmit = () => {
    let selectedPlan = { ...this.props.selectedPlan };

    if (this.props.selectedPlanType === 'unlimited') {
      selectedPlan = {
        forms: 1000,
        submissions: 1000000,
        users: 1000,
        filesStorage: 10000,
        domains: 10000,
        brandingRemoval: 1
      };
    }

    this.props.saveCustomerAddress()
      .then(() => {
        if (!this.props.coupon.id) return Promise.resolve();

        return this.props.updateBilling({
          coupon: this.props.coupon.id
        });
      })
      .then(() => this.props.updateUser({
        plan: selectedPlan,
        planType: this.props.selectedPlanType
      }, {
        updateLocal: false
      }))
      .then(() => this.props.assignSubscription())
      .then(() => this.props.getUser())
      .then(() => this.props.updateLoading(false));
  };

  handleSubmit = async (e) => {
    e.preventDefault();

    let formErrors = false;

    this.props.updateLoading(true);

    if (!this.props.form.country) {
      this.props.updateFormErrors('country', true);
      formErrors = true;
    }

    if (!this.props.form.name) {
      this.props.updateFormErrors('name', true);
      formErrors = true;
    }

    if (!this.props.form.email) {
      this.props.updateFormErrors('email', true);
      formErrors = true;
    }

    if (this.props.form.vatEu && !checkVAT(this.props.form.vatEu, euVatCountries).isValid) {
      this.props.updateFormErrors('vatEu', true);
      formErrors = true;
    }

    const { error } = await this.props.stripe.createToken();

    if (error && this.props.form.collectionMethod === 'charge_automatically') {
      this.props.updateCreditCardErrorMessage(error.message);
    }

    if ((error  && this.props.form.collectionMethod === 'charge_automatically') || formErrors) {
      this.props.updateLoading(false);

      return;
    }
      
    this.props.updateCreditCardErrorMessage(null);

    if (this.props.form.collectionMethod === 'charge_automatically') {
      this.props.stripe.createSource({
        type: 'card',
        usage: 'reusable',
        owner: {
          email: this.props.form.email
        }
      }).then((res) => {
        this.props.saveUserSource(res.source.id).then(this.afterSubmit);
      });
    } else {
      this.afterSubmit();
    }
  };

  render() {
    const {
      user, form, creditCardErrorMessage, formErrors, loading
    } = this.props;

    if (user.authType === 'onetool') return <></>;

    return (
      <form className={styles.form} onSubmit={this.handleSubmit}>
        <div className={styles.headText} style={{ margin: '0 0 20px 0'}}>Your payment information</div>
        {/* <div className={styles.headDesc}>Choose whether you want to be billed automatically or pay your invoices manually.</div> */}

        {/* <ul className={styles.paymentSelect}>
          <li className={form.collectionMethod === 'charge_automatically' ? styles.active : ''} onClick={() => this.props.updateForm('collectionMethod', 'charge_automatically').then(this.props.updateCollectionMethod)}>
            <span>Automatic payments</span>
            <p>Have your bill paid automatically with your credit/debit card.</p>
          </li>
          <li className={form.collectionMethod === 'send_invoice' ? styles.active : ''} onClick={() => this.props.updateForm('collectionMethod', 'send_invoice').then(this.props.updateCollectionMethod)}>
            <span>Manual payments</span>
            <p>Manually pay for your bill using credit/debit card or other international payment methods.</p>
          </li>
        </ul> */}

        <FormRow label="Card information" required={true} errorMessage={creditCardErrorMessage}>
          <CreditCardInput error={!!creditCardErrorMessage} onChange={() => this.props.updateCreditCardErrorMessage(null)} />
        </FormRow>

        <div className={styles.headText} style={{ margin: '30px 0 10px 0'}}>Your invoice information</div>
        <div className={styles.headDesc} style={{ margin: '0 0 20px 0'}}>This information is displayed on your invoices, and used to contact you incase there is something wrong with your billing.</div>

        <FormRow label="Your full name or company name" required={true}>
          <TextInput value={form.name} width={'100%'} onChange={(e) => this.props.updateForm('name', e.target.value, true)} error={formErrors.name} />
        </FormRow>

        <FormRow label="Country of billing address" required={true}>
          <Select width="100%" options={countries} value={
            { label: (countries.find((country) => country.value === form.country) || { label: '- Select -' }).label, value: form.country }
          } onChange={(selected) => this.props.updateForm('country', selected.value, true)} error={formErrors.country} />
        </FormRow>

        <FormRow label="Billing email" required={true}>
          <TextInput type="email" value={form.email} width={'100%'} onChange={(e) => this.props.updateForm('email', e.target.value, true)} error={formErrors.email} />
        </FormRow>

        {vatEuCountryCodes.indexOf(form.country) !== -1 && <FormRow label="Vat EU">
          <TextInput type="text" value={form.vatEu} width={'100%'} onChange={(e) => this.props.updateForm('vatEu', e.target.value, true)} error={formErrors.vatEu} />
        </FormRow>}

        <FormRow label="Address Line 1" required={false}>
          <TextInput value={form.address1} width={'100%'} onChange={(e) => this.props.updateForm('address1', e.target.value)} />
        </FormRow>

        <FormRow label="Address Line 2" required={false}>
          <TextInput value={form.address2} width={'100%'} onChange={(e) => this.props.updateForm('address2', e.target.value)} />
        </FormRow>

        <FormRow label="Postal Code" required={false}>
          <TextInput value={form.postal} width={'100%'} onChange={(e) => this.props.updateForm('postal', e.target.value)} />
        </FormRow>

        <FormRow label="City" required={false}>
          <TextInput value={form.city} width={'100%'} onChange={(e) => this.props.updateForm('city', e.target.value)} />
        </FormRow>

        <Button type="submit" size="large" theme="blue" fullWidth={true} margin="20px 0 0 0">Start using QuestionScout Pro</Button>

        {loading && <Loader size="50" background="rgba(255, 255, 255, 0.5)" />}
      </form>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    form: state.plan.form,
    user: state._users.user,
    coupon: state.plan.coupon,
    creditCardErrorMessage: state.plan.creditCardErrorMessage,
    formErrors: state.plan.formErrors,
    selectedPlan: state.plan.selectedPlan,
    loading: state.plan.loading,
    selectedPlanType: state.plan.selectedPlanType
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateCollectionMethod: () => dispatch(updateCollectionMethod()),
    saveCustomerAddress: () => dispatch(saveCustomerAddress()),
    saveUserSource: (sourceId) => dispatch(saveUserSource(sourceId)),
    updateForm: (key, value, validate) => dispatch(updateForm(key, value, validate)),
    assignSubscription: () => dispatch(assignSubscription()),
    updateBilling: (obj) => dispatch(updateBilling(obj)),
    updateLoading: (value) => dispatch(updateLoading(value)),
    updateCreditCardErrorMessage:  (value) => dispatch(updateCreditCardErrorMessage(value)),
    updateFormErrors: (key, value) => dispatch(updateFormErrors(key, value)),
    updateUser: (params, options) => dispatch(updateUser(params, options)),
    getUser: () => dispatch(getUser())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(injectStripe(PlanForm)));
