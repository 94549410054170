import styles from './Menu.module.css';

import React from 'react';

import {
  TitleField, SizeWeightAndColorField, CheckboxField, SizeWithColorField,
  SizeWithTypeField, SizeField, SelectField, ColorField, GradientField,
  InputStyleField, ToggleField, CopyField
} from '../ThemeMenuFields.js';

const typeOptions = [
  { label: '%', value: '%' },
  { label: 'px', value: 'px' }
];

const alignOptions = [
  { label: 'Left', value: 'left' },
  { label: 'Center', value: 'center' },
  { label: 'Right', value: 'right' }
];

const States = ({ title, subTitle, id, settings, colors, onChange, onNewColor, onCopy }) => {
  return (
    <>
      <TitleField title={title} subTitle={subTitle} />

      {subTitle === 'Idle' && <SizeWithColorField title="Border"
        settings={settings}
        colors={colors}
        onChange={onChange}
        onNewColor={onNewColor}
        margin="25px 0 0 0"
        size={`${id}BorderSize`}
        color={`${id}BorderColor`} />}

      {subTitle !== 'Idle' && <ToggleField label={`Different styling on ${subTitle}`}
        settings={settings}
        onChange={onChange}
        margin="25px 0 0 0"
        value={`${id}Enable`} />}

      {(subTitle === 'Idle' || settings[`${id}Enable`]) && <>
        {subTitle !== 'Idle' && <CopyField onCopy={onCopy}
          margin="15px 0 0 0"
          id={id}
          values={['BorderColor']}
          options={[
            { label: 'Idle', value: 'signatureIdle' }
          ]} />}

        {subTitle !== 'Idle' && <ColorField title="Border color"
          settings={settings}
          colors={colors}
          onChange={onChange}
          onNewColor={onNewColor}
          margin="25px 0 0 0"
          color={`${id}BorderColor`} />}

        <CheckboxField label="Add shadows"
          settings={settings}
          onChange={onChange}
          margin="25px 0 0 0"
          value={`${id}Shadow`} />

        {settings[`${id}Shadow`] && <>
          <ColorField title="Option shadow color"
            settings={settings}
            colors={colors}
            onChange={onChange}
            onNewColor={onNewColor}
            margin="10px 0 0 0"
            color={`${id}ShadowColor`} />

          <SizeField title="Option shadow horizontal length"
            settings={settings}
            onChange={onChange}
            margin="25px 0 0 0"
            size={`${id}ShadowOffsetX`} />

          <SizeField title="Option shadow vertical length"
            settings={settings}
            onChange={onChange}
            margin="25px 0 0 0"
            size={`${id}ShadowOffsetY`} />

          <SizeField title="Option shadow blur"
            settings={settings}
            onChange={onChange}
            margin="25px 0 0 0"
            size={`${id}ShadowBlur`} />

          <SizeField title="Option shadow spread"
            settings={settings}
            onChange={onChange}
            margin="25px 0 0 0"
            size={`${id}ShadowSpread`} />
        </>}
      </>}
    </>
  )
};

const Signature = ({ settings, colors, onChange, onNewColor, onCopy }) => {
  return (
    <>
      <TitleField title="General Field Settings" />

      <SizeWithTypeField title="Maximum field width"
        settings={settings}
        onChange={onChange}
        margin="25px 0 0 0"
        types={typeOptions}
        size="signatureWidth"
        type="signatureWidthType" />

      <SelectField title="Field alignment"
        settings={settings}
        onChange={onChange}
        options={alignOptions}
        margin="25px 0 0 0"
        value="signatureAlignment" />

      <div className={styles.doubleRow}>
        <div className={styles.left}>
          <SizeField title="Field top margin"
            settings={settings}
            onChange={onChange}
            margin="25px 0 0 0"
            size={`signatureTopMargin`} />
        </div>
        <div className={styles.right}>
          <SizeField title="Field bottom margin"
            settings={settings}
            onChange={onChange}
            margin="25px 0 0 0"
            size={`signatureBottomMargin`} />
        </div>
      </div>

      <States title="Field Styling" subTitle="Idle" id="signatureIdle" settings={settings} colors={colors} onChange={onChange} onNewColor={onNewColor} onCopy={onCopy} />
      <States title="Field Styling" subTitle="Hover" id="signatureHover" settings={settings} colors={colors} onChange={onChange} onNewColor={onNewColor} onCopy={onCopy} />

      <TitleField title="Tabs Styling" />

      <ColorField title="Idle tab color"
        settings={settings}
        colors={colors}
        onChange={onChange}
        onNewColor={onNewColor}
        margin="25px 0 0 0"
        color="signatureIdleOptionColor" />

      <ColorField title="Active tab color"
        settings={settings}
        colors={colors}
        onChange={onChange}
        onNewColor={onNewColor}
        margin="25px 0 0 0"
        color="signatureActiveOptionColor" />

      <ColorField title="Background"
        settings={settings}
        colors={colors}
        onChange={onChange}
        onNewColor={onNewColor}
        margin="25px 0 0 0"
        color="signatureBackground" />
    </>
  );
}

export default Signature;
