import styles from './SettingsGeneral.module.css';

import React, { Component } from 'react';
import { connect } from 'react-redux';
import debounce from 'lodash.debounce';
import { getUrlWithParameters } from 'misc/helpers.js';
import getFieldLabel from 'helpers/getFieldLabel.js';

import { Button, TextInput, Toggle, Radio, Loader } from 'ui';

import {
  ioUpdateForm, updateForm, updateFormUrlParameter, ioUpdateFormUrlParameter, getForm, builderReloading
} from 'store/ducks/builder.js';

import {
  updateDeleteConfirmation, deleteForm, showAddWebhookModal, deleteWebhook, addUrlParam, deleteUrlParam
} from 'store/ducks/settings.js';

import {
  getUsers
} from 'store/ducks/_users.js';

import AddWebhookModal from './modals/AddWebhookModal.js';

import { ReactComponent as WarningIcon } from 'assets/images/warning.svg';
import { ReactComponent as RemoveIcon } from 'assets/images/trash.svg';

class SettingsGeneral extends Component {
  componentDidMount = () => {
    this.props.getUsers();

    if (!this.props.fields.find((field) => field._id === this.props.form.confirmationEmailField)) {
      this.handleChange({ confirmationEmailField: null }, true);
    }

    if (!Number.isInteger(this.props.form.respondentLimitsNumber)) {
      this.handleChange({ respondentLimitsNumber: 1 }, true);
    }
  };

  // toggleNotifications = () => {
  //   const param = {
  //     notificationsEmail: !this.props.form.notificationsEmail
  //   };

  //   this.props.updateForm(param);
  //   this.props.ioUpdateForm(param);
  // }

  toggleRespondentLimits = () => {
    const param = {
      respondentLimits: !this.props.form.respondentLimits
    };

    this.props.updateForm(param);
    this.props.ioUpdateForm(param);
  }

  // toggleConfirmationEmail = () => {
  //   const options = this.props.fields.filter((f) => f.type === 'shortText' && f.format === 'email');

  //   const param = {
  //     confirmationEmail: !this.props.form.confirmationEmail
  //   };

  //   if (!param.confirmationEmail) {
  //     param.confirmationEmailField = null;
  //   } else if (options.length === 1) {
  //     param.confirmationEmailField = options[0]._id;
  //   } else {
  //     param.confirmationEmailField = null;
  //   }

  //   this.props.updateForm(param);
  //   this.props.ioUpdateForm(param);
  // }

  // toggleNotificationsUser = (id) => {
  //   const copy = this.props.form.notificationsEmailUsers;
  //   const index = copy.indexOf(id);

  //   if (index === -1) {
  //     copy.push(id);
  //   } else {
  //     copy.splice(index, 1);
  //   }

  //   this.props.updateForm({ notificationsEmailUsers: copy });
  //   this.props.ioUpdateForm({ notificationsEmailUsers: copy });
  // }

  handleChange = (params, delay = false) => {
    this.props.updateForm(params);

    if (delay) {
      this.ioUpdateForm(params);
    } else {
      this.props.ioUpdateForm(params);
    }
  }

  ioUpdateForm = debounce((params) => {
    this.props.ioUpdateForm(params);
  }, 500);

  handleUrlParamChange = (id, key, value) => {
    key = key.replace(/\s/g, '').toLowerCase();
    value = value.replace(/\s/g, '');

    this.props.updateFormUrlParameter({ id, key, value });
    this.ioUpdateFormUrlParameter({ id, key, value });
  }

  ioUpdateFormUrlParameter = debounce((params) => {
    this.props.ioUpdateFormUrlParameter(params);
  }, 500);

  fieldOptionLabel = (field) => {
    if (!field) return null;

    return <div style={{
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      width: '100%'
    }}>
      <div style={{
        backgroundColor: this.props.columnOptions[field.type].backgroundColor,
        width: 22,
        height: 22,
        marginRight: 10,
        borderRadius: 2,
        boxSizing: 'border-box',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}>
        <img src={this.props.columnOptions[field.type].icon} width="15px" alt="" />
      </div>
      <div style={{
        width: 'calc(100% - 48px)',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap'
      }}>{getFieldLabel(field.label || field.placeholder)}</div>
    </div>;
  }

  handleFormTypeChange = (type) => {
    if (this.props.form.type === type) return;

    this.props.builderReloading();
    this.handleChange({ type });

    setTimeout(() => window.location.reload(false));
  }

  render() {
    const {
      form, deleteConfirmation, urlParamsLoading, formId
    } = this.props;

    if (!form) return <></>;

    // const notificationsUsers = users.filter((u) => u.role !== 'user' || (form.workspace.users || []).findIndex((wu) => wu.user === u._id && wu.read) !== -1);
    // const emailFieldsOptions = fields.filter((f) => f.type === 'shortText' && f.format === 'email');

    return (
      <>
        <AddWebhookModal />

        <div className={styles.card}>
          <h2>Basic Settings</h2>

          <div className={styles.row}>
            <div className={styles.rowLabel}>
              <div className={styles.title}>Form name</div>
            </div>

            <div className={styles.rowContent}>
              <TextInput type="text" width={350} value={form.name || ''} onChange={(e) => this.handleChange({ name: e.target.value })} />
            </div>
          </div>

          <div className={styles.row}>
            <div className={styles.rowLabel}>
              <div className={styles.title}>Form Type</div>
              <div className={styles.subTitle}>This settings controls the way your form is being displayed to the respondent.</div>
            </div>

            <div className={styles.rowContent}>
              <Radio label="Traditional Form" value={form.type === 'classic'} onChange={() => { }} onClick={() => this.handleFormTypeChange('classic')} tooltip="Gather data using a more traditional interface, showing multiple questions at a time." />
              <Radio label="Conversational Form" badge="BETA" value={form.type === 'conversational'} onChange={() => { }} onClick={() => this.handleFormTypeChange('conversational')} tooltip="Collect responses using a conversational interface, showing a single question at a time." />
            </div>
          </div>

          <div className={styles.row}>
            <div className={styles.rowLabel}>
              <div className={styles.title}>Partial Responses</div>
              <div className={styles.subTitle}>Partial Responses allows you to collect information that is filled out on a form when the user doesn't click the submit button. Keep in mind that each partial response counts towards your monthly responses limit.</div>
            </div>

            <div className={styles.rowContent}>
              <Radio label="Don’t collect Partial Responses" value={!form.partialResponses} onChange={() => { }} onClick={() => this.handleChange({ partialResponses: false })} />
              <Radio label="Collect Partial Responses" badge="BETA" value={form.partialResponses} onChange={() => { }} onClick={() => this.handleChange({ partialResponses: true })} />
            </div>
          </div>

          <div className={styles.row}>
            <div className={styles.rowLabel}>
              <div className={styles.title}>Progress Bar</div>
              <div className={styles.subTitle}>On Traditional forms, this displays the page number you're on and how many pages are remaining. On Conversational forms, this displays the current question number you're on and how many questions are remaining.</div>
            </div>

            <div className={styles.rowContent}>
              <Radio label="Show Progress Bar" value={form.progressBar} onChange={() => { }} onClick={() => this.handleChange({ progressBar: true })} />
              <Radio label="Hide Progress Bar" value={!form.progressBar} onChange={() => { }} onClick={() => this.handleChange({ progressBar: false })} />
            </div>
          </div>

          {/* <div className={[styles.row, styles.notifications].join(' ')}>
            <div className={styles.rowLabel}>
              <div className={styles.title} onClick={() => this.toggleNotifications()} >
                <Toggle checked={form.notificationsEmail} readOnly={true} onChange={() => { }} />
                <span>User Notifications</span>
              </div>
              <div className={styles.subTitle}>User Notifications are sent to yourself and/or your team members to notify you when your form has received new responses</div>
            </div>

            <div className={styles.rowContent}>
              <div className={styles.number}>
                <span>Send a notification after every</span>
                <TextInput type="text" width={50} margin="0 5px" value={form.notificationsEmailNumber} disabled={!form.notificationsEmail} onChange={(e) => this.handleChange({ notificationsEmailNumber: parseInt(e.target.value, 10) }, true)} />
                <span>submissions.</span>
              </div>

              <div className={styles.rowContentTitle} style={{ margin: '20px 0 10px 0' }}>Send notification emails to:</div>
              <ul>
                {notificationsUsers.map((user) => <li key={user._id}>
                  <Checkbox label={user.email} value={form.notificationsEmailUsers.indexOf(user._id) !== -1} disabled={!form.notificationsEmail} onChange={() => { }} onClick={() => this.toggleNotificationsUser(user._id)} />
                </li>)}
              </ul>
            </div>
          </div> */}

          {/* <div className={[styles.row, styles.notifications].join(' ')}>
            <div className={styles.rowLabel}>
              <div className={styles.title} onClick={() => this.toggleConfirmationEmail()} >
                <Toggle checked={form.confirmationEmail} readOnly={true} onChange={() => { }} />
                <span>Respondent Notifications</span>
              </div>
              <div className={styles.subTitle}>Respondent Notifications are sent to the respondent after they have submit the form.</div>
              <div className={styles.subTitle}>The respondent notification email contains everything the respondent has submitted on the form.</div>
            </div>

            <div className={styles.rowContent}>
              <div className={styles.rowContentTitle} style={{ margin: '0 0 10px 0' }}>Send confirmation email to:</div>

              <Select width={350} disabled={!form.confirmationEmail || emailFieldsOptions.length === 0} options={emailFieldsOptions.map((f) => {
                return { label: this.fieldOptionLabel(f) || f.placeholder, value: f._id };
              })} value={{ label: (!form.confirmationEmailField || emailFieldsOptions.length === 0) ? '- Select -' : this.fieldOptionLabel(emailFieldsOptions.find((ff) => ff._id === form.confirmationEmailField)), value: form.confirmationEmailField }} onChange={(selected) => this.handleChange({ confirmationEmailField: selected.value }, true)} />
              {emailFieldsOptions.length === 0 && <div className={styles.rowContentDescription}>You must add an email field to your form to send confirmation emails.</div>}
              {emailFieldsOptions.length > 0 && <div className={styles.rowContentDescription}>Email from selected field will receive the notification message.</div>}
            </div>
          </div> */}

          <div className={[styles.row, styles.notifications].join(' ')}>
            <div className={styles.rowLabel}>
              <div className={styles.title} onClick={() => this.toggleRespondentLimits()} >
                <Toggle checked={form.respondentLimits} readOnly={true} onChange={() => { }} />
                <span>Respondent Limits</span>
              </div>
              <div className={styles.subTitle}>How many times would you like to have each individual respondent be able to submit your form?</div>
            </div>

            <div className={styles.rowContent}>
              <div className={styles.rowContentTitle} style={{ margin: '0 0 10px 0' }}>Respondent Limit:</div>
              <div className={styles.number}>
                <TextInput type="text" width={50} margin="0 5px 0 0" value={form.respondentLimitsNumber} disabled={!form.respondentLimits} onChange={(e) => this.handleChange({ respondentLimitsNumber: parseInt(e.target.value, 10) }, true)} />
                <span>time{form.respondentLimitsNumber === 1 ? '' : 's'}</span>
              </div>
            </div>
          </div>
        </div >

        <div className={styles.card}>
          <h2>Webhooks</h2>

          <div className={[styles.row, styles.webhooks].join(' ')}>
            <div className={styles.rowLabel}>
              <div className={styles.title}>Webhooks</div>
              <div className={styles.subTitle}>Notify external services when someone submits your form.</div>
            </div>

            <div className={styles.rowContent}>
              <ul>
                {form.webHooks.map((webhook) => <li key={webhook._id}>
                  <div><span>POST</span>{webhook.url}</div>
                  <Button theme="white" onClick={() => this.props.deleteWebhook(webhook._id)}>Delete</Button>
                </li>)}
              </ul>

              <Button theme="blue" onClick={() => this.props.showAddWebhookModal()} margin="15px 0 0 0" disabled={form.webHooks.length >= 5}>Add a webhook</Button>
            </div>
          </div>
        </div>

        <div className={[styles.card, styles.urlParams].join(' ')}>
          <h2>URL Parameters</h2>

          <div className={styles.row}>
            <div className={styles.rowLabel}>
              <div className={styles.title}>Parameters</div>
              <div className={styles.subTitle}>Pass information you already know into your form via the URL.</div>
            </div>

            <div className={styles.rowContent}>
              <ul>
                {form.urlParams.map((param) => <li key={param._id}>
                  <TextInput type="text" value={param.key} placeholder="key, required" width="40%" margin="0 10px 0 0" onChange={(e) => this.handleUrlParamChange(param._id, e.target.value, param.value)} />
                  <TextInput type="text" value={param.value} placeholder="value, not required (can be dynamic)" width="calc(60% - 30px)" onChange={(e) => this.handleUrlParamChange(param._id, param.key, e.target.value)} />
                  <RemoveIcon onClick={() => this.props.deleteUrlParam(param._id)} />
                </li>)}
              </ul>

              <Button theme="blue" onClick={() => this.props.addUrlParam()} margin="15px 0 0 0" disabled={form.urlParams.length >= 50}>Add a parameter</Button>

              {urlParamsLoading && <div className={styles.loader}>
                <Loader size={30} />
              </div>}
            </div>
          </div>

          <div className={styles.row}>
            <div className={styles.rowLabel}>
              <div className={styles.title}>URL Preview</div>
              <div className={styles.subTitle}>This is a preview of your form URL including the parameters.</div>
            </div>

            <div className={styles.rowContent}>
              <div className={styles.urlPreview}>{getUrlWithParameters(`https://form.questionscout.com/${formId}`, form.urlParams)}</div>
            </div>
          </div>

          <div className={styles.row}>
            <div className={styles.rowLabel}>
              <div className={styles.title}><WarningIcon className={styles.warningIcon} />Disable saving responses in QuestionScout</div>
              <div className={styles.subTitle}>Enabling this setting will prevent responses from being saved on QuestionScout database and instead have them only sent through integrations or webhooks.</div>
            </div>

            <div className={styles.rowContent}>
              <Radio label="No" value={!form.disableSave} onChange={() => { }} onClick={() => this.handleChange({ disableSave: false })} />
              <Radio label="Yes" value={form.disableSave} onChange={() => { }} onClick={() => this.handleChange({ disableSave: true })} />
            </div>
          </div>
        </div>

        {
          form.permissions.delete && <div className={[
            styles.card,
            styles.danger,
            deleteConfirmation ? styles.delete : ''
          ].join(' ')}>
            {!deleteConfirmation && <>
              <div className={styles.title}>Delete Form</div>
              <div className={styles.subTitle}>This will permanently delete this form along with all submissions.</div>

              <Button theme="red" onClick={() => this.props.updateDeleteConfirmation(true)}>Delete this form</Button>
            </>}

            {deleteConfirmation && <>
              <div className={styles.title}>Are you sure you want to delete this form?</div>
              <div className={styles.subTitle}>If you need to hide form from visitors, you may consider pausing it instead.</div>

              <div>
                <div className={styles.deleteTextCancel} onClick={() => this.props.updateDeleteConfirmation(false)}>Cancel</div>
                <div className={styles.deleteBtnConfirm} onClick={() => this.props.deleteForm()}>Delete Form</div>
              </div>
            </>}
          </div>
        }
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    form: state.builder.form,
    fields: state.builder.fields,
    deleteConfirmation: state.settings.deleteConfirmation,
    user: state._users.user,
    users: state._users.users,
    formId: state.builder.formId,
    urlParamsLoading: state.settings.urlParamsLoading,
    columnOptions: state.results.columnOptions
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateDeleteConfirmation: (value) => dispatch(updateDeleteConfirmation(value)),
    deleteForm: () => dispatch(deleteForm()),
    builderReloading: () => dispatch(builderReloading()),
    getForm: () => dispatch(getForm()),
    getUsers: () => dispatch(getUsers()),
    addUrlParam: () => dispatch(addUrlParam()),
    deleteWebhook: (id) => dispatch(deleteWebhook(id)),
    deleteUrlParam: (id) => dispatch(deleteUrlParam(id)),
    updateForm: (params) => dispatch(updateForm(params)),
    ioUpdateFormUrlParameter: (params) => dispatch(ioUpdateFormUrlParameter(params)),
    ioUpdateForm: (params) => dispatch(ioUpdateForm(params)),
    showAddWebhookModal: () => dispatch(showAddWebhookModal()),
    updateFormUrlParameter: (id, key, value) => dispatch(updateFormUrlParameter(id, key, value)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SettingsGeneral);
