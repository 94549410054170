import io from 'socket.io-client';
import createSocketIoMiddleware from 'redux-socket.io';
import config from 'config.js';

let connected = false;
let socket;
let initedMiddleware;

const setupSocketIoMiddleware = (store) => (next) => (action) => {
  const state = store.getState();
  const token = state._users.token;
  const locationWithIo = state.main.locationWithIo;

  if (token && !connected && locationWithIo) {
    connected = true;

    socket = io.connect(config.socketUrl, {
      query: `token=${token}`
    });

    socket.on('disconnect', (reason) => store.dispatch({ type: 'main/IO_DISCONNECT', payload: { reason } }));

    initedMiddleware = createSocketIoMiddleware(socket, 'io/')(store);
  }

  if (token && connected && socket && !locationWithIo) {
    connected = false;

    socket.disconnect(true);

    socket = undefined;
    initedMiddleware = undefined;
  }

  if (connected) {
    return initedMiddleware(next)(action);
  }

  return next(action);
}

export default setupSocketIoMiddleware;
