import styles from './Design.module.css';

import React from 'react';

import Edit from './Edit.js';
import Preview from './Preview.js';

const Design = () => {
  return <>
    <div className={styles.main}>
      <Edit />
      <Preview />
    </div>
  </>;
}

export default Design;
