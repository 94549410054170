import axios from 'axios';
import _remove from 'lodash/remove';

import config from 'config.js';

// Constants
const GET_WORKSPACES_REQUEST = '_workspaces/GET_WORKSPACES_REQUEST';
const GET_WORKSPACES_SUCCESS = '_workspaces/GET_WORKSPACES_SUCCESS';
const GET_WORKSPACES_FAILURE = '_workspaces/GET_WORKSPACES_FAILURE';

const UPDATE_WORKSPACE_REQUEST = '_forms/UPDATE_WORKSPACE_REQUEST';
const UPDATE_WORKSPACE_SUCCESS = '_forms/UPDATE_WORKSPACE_SUCCESS';
const UPDATE_WORKSPACE_FAILURE = '_forms/UPDATE_WORKSPACE_FAILURE';

const CREATE_WORKSPACE_REQUEST = '_workspaces/CREATE_WORKSPACE_REQUEST';
const CREATE_WORKSPACE_SUCCESS = '_workspaces/CREATE_WORKSPACE_SUCCESS';
const CREATE_WORKSPACE_FAILURE = '_workspaces/CREATE_WORKSPACE_FAILURE';

const DELETE_WORKSPACE_REQUEST = '_workspaces/DELETE_WORKSPACE_REQUEST';
const DELETE_WORKSPACE_SUCCESS = '_workspaces/DELETE_WORKSPACE_SUCCESS';
const DELETE_WORKSPACE_FAILURE = '_workspaces/DELETE_WORKSPACE_FAILURE';

const UPDATE_WORKSPACE_USERS_REQUEST = '_workspaces/UPDATE_WORKSPACE_USERS_REQUEST';
const UPDATE_WORKSPACE_USERS_SUCCESS = '_workspaces/UPDATE_WORKSPACE_USERS_SUCCESS';
const UPDATE_WORKSPACE_USERS_FAILURE = '_workspaces/UPDATE_WORKSPACE_USERS_FAILURE';

const RESET_ENDPOINT_STATE = '_workspaces/RESET_ENDPOINT_STATE';

const endpoitInitialStatus = {
  loading: false,
  error: false,
  success: false
};

// Initiual State
const initialState = {
  workspaces: [],

  getWorkspaces: { ...endpoitInitialStatus },
  createWorkspace: { ...endpoitInitialStatus },
  updateWorkspace: { ...endpoitInitialStatus },
  deleteWorkspace: { ...endpoitInitialStatus },
  updateWorkspacePermissions: { ...endpoitInitialStatus }
};

const getEndpointStatus = (type) => {
  const types = {
    request: {
      loading: true,
      error: false,
      success: false
    },
    success: {
      loading: false,
      error: false,
      success: true
    },
    failure: {
      loading: false,
      error: true,
      success: false
    },
  };

  return types[type];
};

// Reducer
export default function reducer(state = JSON.parse(JSON.stringify(initialState)), action = {}) {
  if (action.type === RESET_ENDPOINT_STATE) {
    state[action.payload.name] = JSON.parse(JSON.stringify(endpoitInitialStatus));

    return { ...state };
  }

  // getWorkspaces
  if (action.type === GET_WORKSPACES_REQUEST) {
    state.getWorkspaces = getEndpointStatus('request');

    return { ...state };
  }

  if (action.type === GET_WORKSPACES_SUCCESS) {
    state.getWorkspaces = getEndpointStatus('success');

    state.workspaces = [...action.payload];

    return { ...state };
  }

  if (action.type === GET_WORKSPACES_FAILURE) {
    state.getWorkspaces = getEndpointStatus('failure');

    return { ...state };
  }

  // updateWorkspace
  if (action.type === UPDATE_WORKSPACE_REQUEST) {
    state.updateWorkspace = getEndpointStatus('request');

    return { ...state };
  }

  if (action.type === UPDATE_WORKSPACE_SUCCESS) {
    const index = state.workspaces.findIndex((workspace) => workspace._id === action.payload.data._id);

    state.updateWorkspace = getEndpointStatus('success');
    state.workspaces[index] = { ...state.workspaces[index], ...action.payload.data };
    state.workspaces = [...state.workspaces];

    return { ...state };
  }

  if (action.type === UPDATE_WORKSPACE_FAILURE) {
    state.updateWorkspace = getEndpointStatus('failure');

    return { ...state };
  }

  // updateWorkspacePermissions
  if (action.type === UPDATE_WORKSPACE_USERS_REQUEST) {
    state.updateWorkspacePermissions = getEndpointStatus('request');

    return { ...state };
  }

  if (action.type === UPDATE_WORKSPACE_USERS_SUCCESS) {
    state.updateWorkspacePermissions = getEndpointStatus('success');
    state.workspaces.find((workspace) => workspace._id === action.payload.id).users = action.payload.permissions;
    state.workspaces = [ ...state.workspaces ];

    return { ...state };
  }

  if (action.type === UPDATE_WORKSPACE_USERS_FAILURE) {
    state.updateWorkspacePermissions = getEndpointStatus('failure');

    return { ...state };
  }

  // createWorkspace
  if (action.type === CREATE_WORKSPACE_REQUEST) {
    state.createWorkspace = getEndpointStatus('request');

    return { ...state };
  }

  if (action.type === CREATE_WORKSPACE_SUCCESS) {
    state.createWorkspace = getEndpointStatus('success');

    return { ...state };
  }

  if (action.type === CREATE_WORKSPACE_FAILURE) {
    state.createWorkspace = getEndpointStatus('failure');

    return { ...state };
  }

  // deleteWorkspace
  if (action.type === DELETE_WORKSPACE_REQUEST) {
    state.deleteWorkspace = getEndpointStatus('request');

    return { ...state };
  }

  if (action.type === DELETE_WORKSPACE_SUCCESS) {
    state.deleteWorkspace = getEndpointStatus('success');
    state.workspaces = _remove(state.workspaces, (workspace) => workspace._id !== action.payload.id);

    return { ...state };
  }

  if (action.type === DELETE_WORKSPACE_FAILURE) {
    state.deleteWorkspace = getEndpointStatus('failure');

    return { ...state };
  }
  return state;
}

// Action Creators
export function resetEndpoitState(name) {
  return (dispatch) => {
    dispatch({ type: RESET_ENDPOINT_STATE, payload: { name } });
  };
}

export function getWorkspaces() {
  const request = () => { return { type: GET_WORKSPACES_REQUEST } };
  const success = (data) => { return { type: GET_WORKSPACES_SUCCESS, payload: data } };
  const failure = () => { return { type: GET_WORKSPACES_FAILURE } };

  return async (dispatch, getState) => {
    dispatch(request());

    const state = getState();
    const response = await services.getWorkspaces(state._users.token);

    if (response) {
      dispatch(success(response));
    } else {
      dispatch(failure());
    }
  };
}

export function createWorkspace(data) {
  const request = () => { return { type: CREATE_WORKSPACE_REQUEST } };
  const success = () => { return { type: CREATE_WORKSPACE_SUCCESS } };
  const failure = () => { return { type: CREATE_WORKSPACE_FAILURE } };

  return async (dispatch, getState) => {
    dispatch(request());

    const state = getState();
    const response = await services.createWorkspace(state._users.token, data);

    if (response) {
      dispatch(success());
    } else {
      dispatch(failure());
    }
  };
}

export function updateWorkspace(id, data) {
  const request = () => { return { type: UPDATE_WORKSPACE_REQUEST } };
  const success = (data) => { return { type: UPDATE_WORKSPACE_SUCCESS, payload: { data } } };
  const failure = () => { return { type: UPDATE_WORKSPACE_FAILURE } };

  return async (dispatch, getState) => {
    dispatch(request());

    const state = getState();
    const response = await services.updateWorkspace(state._users.token, id, data);

    if (response) {
      return dispatch(success(response));
    } else {
      return dispatch(failure());
    }
  };
}

export function deleteWorkspace(id, formsChoice = 'move', formsWorkspaceToMove = null) {
  const request = () => { return { type: DELETE_WORKSPACE_REQUEST } };
  const success = (id) => { return { type: DELETE_WORKSPACE_SUCCESS, payload: { id } } };
  const failure = () => { return { type: DELETE_WORKSPACE_FAILURE } };

  return async (dispatch, getState) => {
    dispatch(request(id));

    const state = getState();
    const response = await services.deleteWorkspace(state._users.token, id, formsChoice, formsWorkspaceToMove);

    if (response) {
      dispatch(success(id));
    } else {
      dispatch(failure());
    }
  };
}

export function updateWorkspacePermissions(id, permissions) {
  const request = () => { return { type: UPDATE_WORKSPACE_USERS_REQUEST } };
  const success = (id, permissions) => { return { type: UPDATE_WORKSPACE_USERS_SUCCESS, payload: { id, permissions } } };
  const failure = () => { return { type: UPDATE_WORKSPACE_USERS_FAILURE } };

  return async (dispatch, getState) => {
    dispatch(request());

    const state = getState();
    const response = await services.updateWorkspacePermissions(state._users.token, id, permissions);

    if (response) {
      return dispatch(success(id, response));
    } else {
      return dispatch(failure());
    }
  };
}


const services = {
  getWorkspaces: async (token) => {
    try {
      const response = await axios({
        method: 'GET',
        url: `${config.apiUrl}/workspaces`,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });

      return response.data;
    } catch (e) {
      return false;
    }
  },
  updateWorkspace: async (token, id, data) => {
    try {
      const response = await axios({
        method: 'PUT',
        url: `${config.apiUrl}/workspaces/${id}`,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        data
      });

      return response.data;
    } catch (e) {
      return false;
    }
  },
  createWorkspace: async (token, data) => {
    try {
      const response = await axios({
        method: 'POST',
        url: `${config.apiUrl}/workspaces`,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        data
      });

      return response.data;
    } catch (e) {
      return false;
    }
  },
  deleteWorkspace: async (token, id, formsChoice, formsWorkspaceToMove) => {
    try {
      const response = await axios({
        method: 'DELETE',
        url: `${config.apiUrl}/workspaces/${id}`,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        data: {
          formsChoice, formsWorkspaceToMove
        }
      });

      return response.data;
    } catch (e) {
      return false;
    }
  },
  updateWorkspacePermissions: async (token, id, permissions) => {
    try {
      const response = await axios({
        method: 'PUT',
        url: `${config.apiUrl}/workspaces/${id}/permissions`,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        data: permissions
      });

      return response.data;
    } catch (e) {
      return false;
    }
  },
};
