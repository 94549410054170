import styles from './Preview.module.css';

import React from 'react';
import { useSelector } from 'react-redux';

import FormPreview from 'components/Themes/FormPreview.js';

const Preview = () => {
  const form = useSelector(state => state.builder.form);
  const themeId = useSelector(state => state.builder.form.themeId || 'default-light');
  const themeType = useSelector(state => state.builder.form.themeType || 'stock');
  const previewDevice = useSelector((state) => state.builder.previewDevice);

  return <div className={styles.main}>
    <div className={[styles.preview, styles[previewDevice]].join(' ')} style={form.type === 'classic' ? { overflowY: 'scroll' } : { overflow: 'hidden' }}>
      <FormPreview themeId={themeId} themeType={themeType} device={previewDevice} form={form} placement={'builder'} />
    </div>
  </div>;
}

export default Preview;
