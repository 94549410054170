import styles from './Edit.module.css';

import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import WebFont from 'webfontloader';
import PerfectScrollbar from 'react-perfect-scrollbar';

import ThemePreview from 'components/Themes/ThemePreview.js';
import CreateThemeModal from 'components/Themes/modals/CreateTheme.js';

import {
  updatePreviewDevice, ioUpdateForm, updateForm
} from 'store/ducks/builder.js';

import {
  showCreateThemeModal
} from 'store/ducks/theme.js';

import { DropdownMenu, Loader, Button } from 'ui';

import { ReactComponent as PhoneIcon } from 'assets/images/phone.svg';
import { ReactComponent as TabletIcon } from 'assets/images/tablet.svg';
import { ReactComponent as MonitorIcon } from 'assets/images/monitor.svg';
import { ReactComponent as MoreIcon } from 'assets/images/more.svg';

const getDropdowItems = ({ id, type }) => {
  const params = [{
    type: 'label',
    text: 'ACTIONS'
  }];

  if (type === 'stock') {
    params.push({
      key: 'view',
      type: 'link',
      text: 'View',
      link: `/themes/stock/${id}`
    });
  }

  if (type === 'custom') {
    params.push({
      key: 'edit',
      type: 'link',
      text: 'Edit',
      link: `/themes/custom/${id}`
    });
  }

  return params;
};

const Card = ({ theme, active, selectTheme }) => {
  const [showMenu, setShowMenu] = useState(false);

  return <li className={[styles.card, active ? styles.active : ''].join(' ')}>
    <ThemePreview id={theme._id} type={theme._type} colors={theme.colors} images={theme.images} settings={theme.settings} className={styles.preview} onClick={() => selectTheme(theme._id, theme.type)} />

    <div className={styles.info}>
      <span className={styles.type} onClick={() => selectTheme(theme._id, theme.type)}>{theme.type} Theme</span>
      <div className={styles.more} onClick={() => setShowMenu(true)}><MoreIcon /></div>
    </div>
    <div className={styles.name} onClick={() => selectTheme(theme._id, theme.type)}>{theme.name}</div>

    {showMenu && <DropdownMenu onClose={() => setShowMenu(false)}
      position={{ top: 120, right: 10 }}
      width={125}
      items={getDropdowItems({
        id: theme._id, type: theme.type
      })} />}
  </li>;
};

const Edit = () => {
  let fontsToLoad = ['Roboto:400,600'];
  const dispatch = useDispatch();

  const previewDevice = useSelector((state) => state.builder.previewDevice);
  const form = useSelector(state => state.builder.form);
  const themes = useSelector(state => state._themes.themes);
  const themeId = useSelector(state => state.builder.form.themeId || 'default-light');

  const [fontsLoaded, setFontsLoaded] = useState(false);

  if (!fontsLoaded && themes.stock.length > 0) {
    for (let theme of themes.stock.concat(themes.custom)) {
      fontsToLoad = [...new Set([
        ...fontsToLoad,
        `${theme.settings.typographyLabelFontFamily}:400,600`,
        `${theme.settings.typographyContentFontFamily}:400,600`,
        `${theme.settings.typographyButtonsFontFamily}:400,600`
      ])];
    }

    WebFont.load({
      google: {
        families: fontsToLoad
      }
    });

    setFontsLoaded(true);
  }

  const handleSelectTheme = (id, type) => {
    dispatch(updateForm({
      themeType: type,
      themeId: id
    }));

    dispatch(ioUpdateForm({
      themeType: type,
      themeId: id
    }));
  };

  themes.custom = [...themes.custom.map((theme) => {
    theme.type = 'custom';

    return theme;
  })];

  themes.stock = [...themes.stock.map((theme) => {
    theme.type = 'stock';

    return theme;
  })];

  return <>
    <CreateThemeModal />

    <div className={styles.main}>
      <div className={styles.header}>
        <ul className={styles.resize}>
          <li className={previewDevice === 'phone' ? styles.active : ''} onClick={() => dispatch(updatePreviewDevice('phone'))}><PhoneIcon /></li>
          <li className={previewDevice === 'tablet' ? styles.active : ''} onClick={() => dispatch(updatePreviewDevice('tablet'))}><TabletIcon /></li>
          <li className={previewDevice === 'desktop' ? styles.active : ''} onClick={() => dispatch(updatePreviewDevice('desktop'))}><MonitorIcon /></li>
        </ul>

        <Button theme="blue" size="small" onClick={() => dispatch(showCreateThemeModal(themeId, form._id))}>Create New Theme</Button>
      </div>

      {themes.stock.length === 0 && <div className={styles.loading}>
        <Loader size={40} />
      </div>}

      <PerfectScrollbar options={{ wheelPropagation: false }}>
        <ul className={styles.themes}>
          {themes.custom.concat(themes.stock).map((theme) => <Card theme={theme} active={theme._id === themeId} selectTheme={handleSelectTheme} key={theme._id} />)}
        </ul>
      </PerfectScrollbar>
    </div>
  </>;
}

export default Edit;
