/** @jsx jsx */
/* @jsxFrag React.Fragment */

import { jsx, css } from '@emotion/core';
import Color from 'color';
import React from 'react';
import checkRequired from 'helpers/checkRequired.js';
import isRequired from 'helpers/isRequired.js';
import getRootSectionValueObj from 'helpers/getRootSectionValueObj.js';
import Translate from '../messages/Translate.js';
import { LoaderAtom } from './../fields/atoms.js';

import { ReactComponent as LinkForwardIcon } from 'assets/images/arrow-forward.svg';
import { ReactComponent as KeyboardArrowLeftIcon } from 'assets/images/keyboard-arrow-left.svg';
import { ReactComponent as KeyboardArrowRightIcon } from 'assets/images/keyboard-arrow-right.svg';

const ConversationalBottomHelper = ({ field, theme, form, value, values, onNextClick, isTouchDevice, messages, submitLoading, submitDisabled, onSubmit }) => {
  const translate = new Translate(messages);
  const isLast = value ? getRootSectionValueObj(field._id, values).isLast : false;
  let topHelper = null;

  const valid = ['pageBreak', 'submit'].indexOf(field.type) === -1 ? checkRequired(form.page, [field], { [field._id]: value }, {
    ignorePages: form.type === 'conversational'
  }, {
    fields: form.fields,
    values: values
  }).valid : true;

  const getBackground = (gradient, gradientDirection, background, secondBackground, darken) => {
    if (gradient) {
      if (gradientDirection === 'left-right') {
        return `linear-gradient(225deg, ${Color(background).darken(darken)} 0%, ${Color(secondBackground).darken(darken)} 100%)`;
      } else if (gradientDirection === 'right-left') {
        return `linear-gradient(25deg, ${Color(background).darken(darken)} 0%, ${Color(secondBackground).darken(darken)} 100%)`;
      } else if (gradientDirection === 'top-bottom') {
        return `linear-gradient(180deg, ${Color(background).darken(darken)} 0%, ${Color(secondBackground).darken(darken)} 100%)`;
      } else if (gradientDirection === 'bottom-top') {
        return `linear-gradient(0deg, ${Color(background).darken(darken)} 0%, ${Color(secondBackground).darken(darken)} 100%)`;
      }
    } else if (darken === 0) {
      return background;
    } else {
      return Color(background).darken(darken);
    }
  };

  const styles = {
    main: css({}),
    topHelper: css({
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
      whiteSpace: 'nowrap',
      fontSize: '14px',
      color: theme.generalFieldsSettingsTextIdleSupportingColor,
      lineHeight: '142%',
      fontFamily: theme.typographySupportingFontFamily,
      margin: '0 0 15px 0',
      'svg path': {
        fill: theme.generalFieldsSettingsTextIdleSupportingColor
      },
      'svg rect': {
        stroke: theme.generalFieldsSettingsTextIdleSupportingColor
      },
    }),
    next: css({
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      opacity: 1,
      transition: 'opacity 0.2s ease-in-out',
      '&.hidden': {
        opacity: 0,
        pointerEvents: 'none'
      }
    }),
    nextButton: css({
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      fontWeight: theme.submitIdleButtonWeight,
      padding: '0 15px',
      margin: '0 15px 0 0',
      cursor: 'pointer',
      pointerEvents: 'auto',
      fontSize: `${theme.submitIdleButtonFontSize}px`,
      lineHeight: `142%`,
      fontFamily: theme.typographyButtonsFontFamily,
      color: theme.submitIdleButtonColor,
      textAlign: 'center',
      boxSizing: 'border-box',
      userSelect: 'none',
      minHeight: theme.submitIdleButtonHeight,
      borderRadius: `${theme.submitIdleButtonRoundness}${theme.submitIdleButtonRoundnessType}`,
      border: `${theme.submitIdleButtonBorderSize}px solid ${theme.submitIdleButtonBorderColor}`,
      boxShadow: theme.submitIdleButtonShadow ? `${theme.submitIdleButtonShadowOffsetX}px ${theme.submitIdleButtonShadowOffsetY}px ${theme.submitIdleButtonShadowBlur}px ${theme.submitIdleButtonShadowSpread}px ${theme.submitIdleButtonShadowColor}` : '',
      background: getBackground(theme.submitIdleButtonGradient, theme.submitIdleButtonGradientDirection, theme.submitIdleButtonBackground, theme.submitIdleButtonSecondBackground, 0),
      'svg': {
        margin: '0 0 0 10px'
      },
      'svg *': {
        fill: theme.submitIdleButtonColor
      },
      '&:hover': (() => {
        if (!theme.submitHoverButtonEnable) return {};

        const obj = {
          color: theme.submitHoverButtonColor,
          borderColor: theme.submitHoverButtonBorderColor,
          boxShadow: theme.submitHoverButtonShadow ? `${theme.submitHoverButtonShadowOffsetX}px ${theme.submitHoverButtonShadowOffsetY}px ${theme.submitHoverButtonShadowBlur}px ${theme.submitHoverButtonShadowSpread}px ${theme.submitHoverButtonShadowColor}` : '',
          background: getBackground(theme.submitHoverButtonGradient, theme.submitHoverButtonGradientDirection, theme.submitHoverButtonBackground, theme.submitHoverButtonSecondBackground, 0),
        };

        return obj;
      })(),
      '&:active': (() => {
        if (!theme.submitActiveButtonEnable) return {};

        const obj = {
          color: theme.submitActiveButtonColor,
          borderColor: theme.submitActiveButtonBorderColor,
          boxShadow: theme.submitActiveButtonShadow ? `${theme.submitActiveButtonShadowOffsetX}px ${theme.submitActiveButtonShadowOffsetY}px ${theme.submitActiveButtonShadowBlur}px ${theme.submitActiveButtonShadowSpread}px ${theme.submitActiveButtonShadowColor}` : '',
          background: getBackground(theme.submitActiveButtonGradient, theme.submitActiveButtonGradientDirection, theme.submitActiveButtonBackground, theme.submitActiveButtonSecondBackground, 0),
        };

        return obj;
      })()
    }),
    nextText: css({
      color: theme.generalFieldsSettingsTextIdleSupportingColor,
      lineHeight: '142%',
      fontFamily: theme.typographySupportingFontFamily,
    }),
    neverSubmitPasswords: css({
      textAlign: 'left',
      fontSize: `${theme.generalFieldsSettingsTextIdleSupportingFontSize}px`,
      lineHeight: '142%',
      fontWeight: theme.generalFieldsSettingsTextIdleSupportingWeight,
      color: theme.generalFieldsSettingsTextIdleSupportingColor,
      boxSizing: 'border-box',
      fontFamily: theme.typographySupportingFontFamily,
      wordBreak: 'break-word',
      margin: '20px 0 0 0'
    }),
    submitButtonStyle: css({
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      fontWeight: theme.submitIdleButtonWeight,
      padding: '0 15px',
      margin: '0 15px 0 0',
      fontSize: `${theme.submitIdleButtonFontSize}px`,
      lineHeight: `142%`,
      fontFamily: theme.typographyButtonsFontFamily,
      color: theme.submitIdleButtonColor,
      textAlign: 'center',
      boxSizing: 'border-box',
      userSelect: 'none',
      minHeight: theme.submitIdleButtonHeight,
      borderRadius: `${theme.submitIdleButtonRoundness}${theme.submitIdleButtonRoundnessType}`,
      border: `${theme.submitIdleButtonBorderSize}px solid ${theme.submitIdleButtonBorderColor}`,
      boxShadow: theme.submitIdleButtonShadow ? `${theme.submitIdleButtonShadowOffsetX}px ${theme.submitIdleButtonShadowOffsetY}px ${theme.submitIdleButtonShadowBlur}px ${theme.submitIdleButtonShadowSpread}px ${theme.submitIdleButtonShadowColor}` : '',
      background: getBackground(theme.submitIdleButtonGradient, theme.submitIdleButtonGradientDirection, theme.submitIdleButtonBackground, theme.submitIdleButtonSecondBackground, 0),
      opacity: !submitDisabled ? 1 : 0.7,
      cursor: !submitDisabled ? 'pointer' : 'default',
      pointerEvents: !submitDisabled ? 'auto' : 'none',

      '&:hover': (() => {
        if (!theme.submitHoverButtonEnable) return {};

        const obj = {
          color: theme.submitHoverButtonColor,
          borderColor: theme.submitHoverButtonBorderColor,
          boxShadow: theme.submitHoverButtonShadow ? `${theme.submitHoverButtonShadowOffsetX}px ${theme.submitHoverButtonShadowOffsetY}px ${theme.submitHoverButtonShadowBlur}px ${theme.submitHoverButtonShadowSpread}px ${theme.submitHoverButtonShadowColor}` : '',
          background: getBackground(theme.submitHoverButtonGradient, theme.submitHoverButtonGradientDirection, theme.submitHoverButtonBackground, theme.submitHoverButtonSecondBackground, 0),
        };

        return obj;
      })(),
      '&:active': (() => {
        if (!theme.submitActiveButtonEnable) return {};

        const obj = {
          color: theme.submitActiveButtonColor,
          borderColor: theme.submitActiveButtonBorderColor,
          boxShadow: theme.submitActiveButtonShadow ? `${theme.submitActiveButtonShadowOffsetX}px ${theme.submitActiveButtonShadowOffsetY}px ${theme.submitActiveButtonShadowBlur}px ${theme.submitActiveButtonShadowSpread}px ${theme.submitActiveButtonShadowColor}` : '',
          background: getBackground(theme.submitActiveButtonGradient, theme.submitActiveButtonGradientDirection, theme.submitActiveButtonBackground, theme.submitActiveButtonSecondBackground, 0),
        };

        return obj;
      })()
    })
  };

  if (field.type === 'checkbox' || field.type === 'imageChoice') {
    if (field.selectionLimits && field.selectionLimitsMin === field.selectionLimitsMax) {
      topHelper = translate.text('checkboxSelectionExactly', { minimumOptions: { value: field.selectionLimitsMin } });
    } else if (field.selectionLimits && field.selectionLimitsMin !== field.selectionLimitsMax) {
      topHelper = translate.text('checkboxSelectionAtLeast', { minimumOptions: { value: field.selectionLimitsMin }, maximumOptions: { value: field.selectionLimitsMax } });
    } else {
      topHelper = translate.text('checkboxDefault');
    }
  }

  if (field.type === 'radio') {
    topHelper = translate.text('radioHint');
  }

  if (field.type === 'scale' && !isTouchDevice) {
    topHelper = translate.jsx('scaleArrowsHint', {
      arrowLeft: { value: <KeyboardArrowLeftIcon style={{ margin: '0 2px 0 4px' }} /> },
      arrowRight: { value: <KeyboardArrowRightIcon style={{ margin: '0 4px 0 2px' }} /> },
    });
  }

  if (field.type === 'longText' && !isTouchDevice) {
    topHelper = translate.text('linebreakHint');
  }

  return <div css={styles.main}>
    {topHelper && <div css={styles.topHelper}>{topHelper}</div>}

    <div css={styles.next} className={valid || !isRequired(field._id, form.fields) ? '' : 'hidden'}>
      {!isLast && ['pageBreak', 'submit'].indexOf(field.type) === -1 && <button css={styles.nextButton} onClick={(e) => onNextClick(field, e)}>
        {translate.jsx('nextBtn', {
          rightArrow: { value: <LinkForwardIcon /> }
        })}
      </button>}

      {isLast && <button css={styles.submitButtonStyle} onClick={() => !submitDisabled && onSubmit()} className="qs_submitBtn">
        {submitLoading ? <LoaderAtom /> : translate.text('submit')}
      </button>}

      {!isTouchDevice && <div css={styles.nextText}>{translate.jsx('nextHint')}</div>}
    </div>

    {isLast && !form.owner.pro && <div css={styles.neverSubmitPasswords}>
      Never submit passwords through QuestionScout forms!
    </div>}
  </div>;
};

export default ConversationalBottomHelper;
