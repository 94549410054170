/** @jsx jsx */
/* @jsxFrag React.Fragment */

import { jsx, css } from '@emotion/core';
import Color from 'color';
import React from 'react';
import isJsonString from 'helpers/isJsonString.js';
import getFieldLabel from 'helpers/getFieldLabel.js';
import Translate from '../messages/Translate.js';

const getBackground = (gradient, gradientDirection, background, secondBackground, darken) => {
  if (gradient) {
    if (gradientDirection === 'left-right') {
      return `linear-gradient(225deg, ${Color(background).darken(darken)} 0%, ${Color(secondBackground).darken(darken)} 100%)`;
    } else if (gradientDirection === 'right-left') {
      return `linear-gradient(25deg, ${Color(background).darken(darken)} 0%, ${Color(secondBackground).darken(darken)} 100%)`;
    } else if (gradientDirection === 'top-bottom') {
      return `linear-gradient(180deg, ${Color(background).darken(darken)} 0%, ${Color(secondBackground).darken(darken)} 100%)`;
    } else if (gradientDirection === 'bottom-top') {
      return `linear-gradient(0deg, ${Color(background).darken(darken)} 0%, ${Color(secondBackground).darken(darken)} 100%)`;
    }
  } else if (darken === 0) {
    return background;
  } else {
    return Color(background).darken(darken);
  }
};

const alignOptions = {
  left: 'flex-start',
  center: 'center',
  right: 'flex-end'
};

export default ({ theme, mainStyles, title, description, image, button, onClick, form, formType, inIframe, values, fieldsOptions, variables }) => {
  const translate = new Translate(form.messages);

  const styles = {
    main: css({
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      boxSizing: 'border-box',
      alignItems: alignOptions[theme.welcomeScreenAlignment],
      maxWidth: `${theme.welcomeScreenWidth}${theme.welcomeScreenWidthType}`,
      width: '100%',
      margin: `${theme.welcomeTopMargin || 0}px 0 ${theme.welcomeBottomMargin || 0}px 0`,
      '&': (() => {
        const obj = {};

        if (formType === 'conversational') {
          obj.minHeight = `calc(100vh - 50px - ${theme.welcomeTopMargin || 0}px - ${theme.welcomeBottomMargin || 0}px)`;
        }

        return obj;
      })()
    }),
    content: css({
      display: 'flex',
      boxSizing: 'border-box',
      flexDirection: 'row',
      justifyContent: alignOptions[theme.displaySettingsFormDisplayAlignment]
    }),
    titleContainer: css({
      margin: `${theme.titlesTopMargin || 0}px 0 ${theme.titlesBottomMargin || 0}px 0`
    }),
    image: css({
      zIndex: 2,
      position: 'relative',
      maxWidth: `${theme.imagesWidth}${theme.imagesWidthType}`,
      margin: `${theme.imagesTopMargin || 0}px 0 ${theme.imagesBottomMargin || 0}px 0`,
      '& img': {
        maxWidth: '100%',
        maxHeight: '100%'
      }
    }),
    title: css({
      fontSize: `${theme.titlesMainFontSize}px`,
      lineHeight: '142%',
      fontWeight: theme.titlesMainWeight,
      color: theme.titlesMainColor,
      fontFamily: theme.typographyTitlesFontFamily,
      boxSizing: 'border-box',
      wordBreak: 'break-word',
      textAlign: theme.welcomeScreenAlignment,
      '& a': {
        color: theme.descriptionsLinkColor,
        textDecoration: 'underline'
      },
    }),
    description: css({
      fontSize: `${theme.titlesDescriptionFontSize}px`,
      lineHeight: '142%',
      fontWeight: theme.titlesDescriptionWeight,
      color: theme.titlesDescriptionColor,
      fontFamily: theme.typographySupportingFontFamily,
      boxSizing: 'border-box',
      margin: '15px 0 0 0',
      wordBreak: 'break-word',
      textAlign: theme.welcomeScreenAlignment,
      '& a': {
        color: theme.titlesDescriptionColor,
        textDecoration: 'underline'
      },
    }),
    button: css({
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      fontWeight: theme.submitIdleButtonWeight,
      padding: '0 15px',
      margin: '0',
      cursor: 'pointer',
      pointerEvents: 'auto',
      fontSize: `${theme.submitIdleButtonFontSize}px`,
      lineHeight: `142%`,
      fontFamily: theme.typographyButtonsFontFamily,
      color: theme.submitIdleButtonColor,
      textAlign: 'center',
      boxSizing: 'border-box',
      userSelect: 'none',
      minHeight: theme.submitIdleButtonHeight,
      borderRadius: `${theme.submitIdleButtonRoundness}${theme.submitIdleButtonRoundnessType}`,
      border: `${theme.submitIdleButtonBorderSize}px solid ${theme.submitIdleButtonBorderColor}`,
      boxShadow: theme.submitIdleButtonShadow ? `${theme.submitIdleButtonShadowOffsetX}px ${theme.submitIdleButtonShadowOffsetY}px ${theme.submitIdleButtonShadowBlur}px ${theme.submitIdleButtonShadowSpread}px ${theme.submitIdleButtonShadowColor}` : '',
      background: getBackground(theme.submitIdleButtonGradient, theme.submitIdleButtonGradientDirection, theme.submitIdleButtonBackground, theme.submitIdleButtonSecondBackground, 0),
      'svg': {
        margin: '0 0 0 10px'
      },
      'svg *': {
        fill: theme.submitIdleButtonColor
      },
      '&:hover': (() => {
        if (!theme.submitHoverButtonEnable) return {};

        const obj = {
          color: theme.submitHoverButtonColor,
          borderColor: theme.submitHoverButtonBorderColor,
          boxShadow: theme.submitHoverButtonShadow ? `${theme.submitHoverButtonShadowOffsetX}px ${theme.submitHoverButtonShadowOffsetY}px ${theme.submitHoverButtonShadowBlur}px ${theme.submitHoverButtonShadowSpread}px ${theme.submitHoverButtonShadowColor}` : '',
          background: getBackground(theme.submitHoverButtonGradient, theme.submitHoverButtonGradientDirection, theme.submitHoverButtonBackground, theme.submitHoverButtonSecondBackground, 0),
        };

        return obj;
      })(),
      '&:active': (() => {
        if (!theme.submitActiveButtonEnable) return {};

        const obj = {
          color: theme.submitActiveButtonColor,
          borderColor: theme.submitActiveButtonBorderColor,
          boxShadow: theme.submitActiveButtonShadow ? `${theme.submitActiveButtonShadowOffsetX}px ${theme.submitActiveButtonShadowOffsetY}px ${theme.submitActiveButtonShadowBlur}px ${theme.submitActiveButtonShadowSpread}px ${theme.submitActiveButtonShadowColor}` : '',
          background: getBackground(theme.submitActiveButtonGradient, theme.submitActiveButtonGradientDirection, theme.submitActiveButtonBackground, theme.submitActiveButtonSecondBackground, 0),
        };

        return obj;
      })()
    })
  };

  return <div css={mainStyles.main} className="qs_welcomePage">
    <div css={mainStyles.content}>
      <div css={styles.content}>
        <div css={styles.main}>
          {image && <div css={styles.image}>
            <img src={image} alt="" />
          </div>}
          {(title || description) && <div css={styles.titleContainer}>
            {title && <div css={styles.title}>{title}</div>}
            {description && <>
              {isJsonString(description)
                ? <div css={styles.description}>{getFieldLabel(description, values, 'jsx', fieldsOptions, variables)}</div>
                : <div css={styles.description} dangerouslySetInnerHTML={{ __html: description }} />}
            </>}
          </div>}
          <button css={styles.button} onClick={onClick}>{button || translate.text('pageBreakNext')}</button>
        </div>
      </div>
    </div>
  </div>;
};
