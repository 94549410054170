import styles from './Publish.module.css';

import React, { Component } from 'react';
import { connect } from 'react-redux';

import PublishUrl from './PublishUrl.js';
import PublishEmbed from './PublishEmbed.js';
import PublishQr from './PublishQr.js';

import {
  ioUpdateForm, updateForm
} from 'store/ducks/builder.js';

import {
  updateUser
} from 'store/ducks/_users.js';

import { ReactComponent as StarsIcon } from 'assets/images/stars.svg';
import { ReactComponent as WarningIcon } from 'assets/images/warning.svg';

import { Button, Radio, Card, CardRow, CardRowLabel, CardRowContent } from 'ui';

class Publish extends Component {
  setStatus = (status) => {
    const param = { status };
    const getFormsUsage = (s) => {
      if (s === 'disabled') return this.props.user.planUsage.forms - 1;

      if (s === 'live' && this.props.form.status === 'disabled') return this.props.user.planUsage.forms + 1;
      if (s === 'paused' && this.props.form.status === 'disabled') return this.props.user.planUsage.forms + 1;
      if (s === 'live' && this.props.form.status === 'draft') return this.props.user.planUsage.forms + 1;

      return this.props.user.planUsage.forms;
    };

    this.props.updateForm(param);
    this.props.ioUpdateForm(param);

    this.props.updateUser({
      planUsage: {
        ...this.props.user.planUsage,
        forms: getFormsUsage(status)
      }
    }, {
      updateApi: false,
      updateLocal: true
    });
  }

  render() {
    const {
      user, form
    } = this.props;

    const exceed = user.planUsage.forms >= user.plan.forms;

    return (
      <div className={styles.main}>
        <div className={styles.header}>
          {form.status !== 'draft' && <p>Publish Form</p>}

          {!user.verified && <div className={styles.notVerified}>
            <WarningIcon className={styles.warningIcon} />
            <span>As a security measure, changing status of your forms is disabled until you have verified your email.</span>
          </div>}
        </div>

        <div className={styles.content}>
          <div className={styles.section}>
            {form.status !== 'draft' && <>
              <Card className={styles.card}>
                <CardRow>
                  <CardRowLabel title="Form Status" subTitle="With Form Status, you decide whether your form or survey is accessible to respondents (or not)." />

                  <CardRowContent>
                    <ul className={styles.statuses}>
                      <li>
                        <Radio label="Disabled" disabled={!user.verified} value={form.status === 'disabled'} onClick={() => this.setStatus('disabled')} />
                        <span>A disabled form cannot be accessed by anyone, and doesn’t count towards your forms limit.</span>
                      </li>
                      <li>
                        <Radio label="Paused" disabled={!user.verified || (exceed && form.status !== 'live')} value={form.status === 'paused'} onClick={() => this.setStatus('paused')} />
                        <span>A paused form is still accessible via the URL, but respondents cannot view or submit the form.</span>
                      </li>
                      <li>
                        <Radio label="Live" disabled={!user.verified || (exceed && form.status !== 'paused')} value={form.status === 'live'} onClick={() => this.setStatus('live')} />
                        <span>A live form can be accessed by respondents, and can be submitted.</span>
                      </li>
                    </ul>
                  </CardRowContent>
                </CardRow>
              </Card>

              <PublishUrl />
              <PublishEmbed />
              <PublishQr />
            </>}

            {form.status === 'draft' && <div className={[styles.draft, !user.verified ? styles.hidden : ''].join(' ')}>
              <div className={styles.subTitle}>You’re about to set this one free..</div>
              <div className={styles.title}>Ready to publish this form?</div>

              <Button theme="blue" size="large" width="200px" onClick={() => this.setStatus('live')} disabled={!user.verified || exceed}>Publish Now</Button>

              {!exceed && <div className={styles.desc}>Publishing this form will allow you to share your form and collect responses.</div>}
              {exceed && <div className={styles.desc}>Oh no.. It looks like you’ve exceeded your form limit.. <br />Consider increasing your form limit or free up some space by disabling other forms.</div>}

              {exceed && <div className={styles.upgrade}>
                <span>Considering publishing more forms and surveys?</span>

                {!user.customizedFree && !user.hasSubscription && user.role === 'holder' && <p>Your current free plan allows you to publish a single form. If you would like to publish more, head over to your billing page and configure your Pro plan which start at prices as low as $1.</p>}
                {!user.customizedFree && user.hasSubscription && user.role === 'holder' && <p>Your account has reached maximum limit of forms. If you would like to publish more, head over to your billing and increase your total form count.</p>}

                {user.customizedFree && user.role === 'holder' && <p>Your current Customized Free plan allows you to publish {user.plan.forms} form{user.plan.forms === 1 ? '' : 's'}. If you would like to publish more, head over to your billing page and configure your Pro plan.</p>}
                {user.customizedFree && user.role !== 'holder' && <p>Your current Customized Free plan allows you to publish {user.plan.forms} form{user.plan.forms === 1 ? '' : 's'}. Please contact your account administrator to increase the form limits.</p>}

                {!user.customizedFree && user.role !== 'holder' && <p>Your account has reached maximum limit of live forms. Please contact your account administrator to increase the form limits.</p>}

                {user.role === 'holder' && user.verified && <Button link="/user/billing/plan" theme="orange" margin="15px 0 0 0" size="medium" icon={<StarsIcon />}>Upgrade Now</Button>}
              </div>}
            </div>}
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    user: state._users.user,
    form: state.builder.form,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateForm: (params) => dispatch(updateForm(params)),
    ioUpdateForm: (params) => dispatch(ioUpdateForm(params)),
    updateUser: (data, options) => dispatch(updateUser(data, options)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Publish);
