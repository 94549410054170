import styles from './BillingFree.module.css';

import React, { useEffect, useState } from 'react';
import { formatBytes } from 'misc/helpers.js';
import { useSelector, useDispatch } from 'react-redux';
import Sticky from 'react-stickynode';

import { Card, Loader, Button } from 'ui';

import {
  updateSelectedPlanType, updateSelectedPlan, resetSelectedPlan
} from 'store/ducks/plan.js';

import PlanSetup from 'components/shared/PlanSetup.js';
import PlanUnlimited from 'components/shared/PlanUnlimited.js';
import PlanSummary from 'components/shared/PlanSummary.js';

import { ReactComponent as CheckmarkIcon } from 'assets/images/checkmark-circle.svg';

const BillingFree = () => {
  const dispatch = useDispatch();

  const user = useSelector(state => state._users.user);
  const selectedPlan = useSelector(state => state.plan.selectedPlan);
  const billing = useSelector(state => state.user.billing);
  const billingLoading = useSelector(state => state.user.billingLoading);
  const planUsage = useSelector(state => state._users.user.planUsage);

  const [type, setType] = useState('flex');

  useEffect(() => {
    dispatch(resetSelectedPlan());
  }, []);

  const selectPlanType = (type) => {
    setType(type);
    dispatch(resetSelectedPlan());
    dispatch(updateSelectedPlanType(type));
  };

  const updatePlan = (key, value) => dispatch(updateSelectedPlan(key, value));

  const handleTotalChange = (value) => {
    if (value === 99) {
      dispatch(updateSelectedPlanType('unlimited'));
    } else {
      dispatch(updateSelectedPlanType('flex'));
    }
  };
  
  const updatePlanType = () => {
    dispatch(updateSelectedPlanType(type));
  };

  return <>
    <div className={styles.header}>
      <h1>You're currently on a <span>{user.customizedFree && 'Customized'} Free</span> QuestionScout plan</h1>
    </div>

    {user.customizedFree && <Card className={styles.enhanced}>
      {!billingLoading && <ul>
        <li>
          <div>All Features</div>
          <div style={{ color: '#24B47E' }}>Included</div>
        </li>
        <li>
          <div>{billing.plan.forms} total forms <span>({planUsage.forms || 0} in use)</span></div>
          <div>€0</div>
        </li>
        <li>
          <div>{billing.plan.submissions} monthly responses <span>({planUsage.submissions || 0} used this month)</span></div>
          <div>€0</div>
        </li>
        <li>
          <div>{billing.plan.users} user{billing.plan.users > 1 && 's'} <span>({planUsage.users || 1} in use)</span></div>
          <div>€0</div>
        </li>
        <li>
          <div>{formatBytes((billing.plan.filesStorage || 0) * 1000000)} file storage <span>({formatBytes((planUsage.filesStorage || 0) * 1000000)} used this month)</span></div>
          <div>€0</div>
        </li>
        <li>
          <div>{billing.plan.domains} domain{billing.plan.domains > 1 && 's'} <span>({planUsage.domains || 1} in use)</span></div>
          <div>€0</div>
        </li>
        <li>
          <div>Branding Removal <span>(excluded)</span></div>
          <div>€0</div>
        </li>
      </ul>}

      {!billingLoading && <div className={styles.tax}>
        <div>Tax</div>
        <div>€0</div>
      </div>}

      {!billingLoading && <div className={styles.total}>
        <div>Total Monthly Bill</div>
        <div>€0</div>
      </div>}

      {billingLoading && <Loader size={30} />}
    </Card>}

    <Card className={styles.main}>
      <div className={styles.calculator}>
        {!user.customizedFree && <h2>Get started using QuestionScout <span>Pro</span></h2>}
        {user.customizedFree && <h2>Start using QuestionScout <span>Pro</span></h2>}
        <span>We don’t do plans. Instead, we like to make things affordable and transparent. Configure your plan and get started using QuestionScout Pro.</span>

        <div className={styles.box}>
          <div className={[styles.left, type === 'flex' ? styles.flex : ''].join(' ')}>
            <div className={styles.types}>
              <div className={[styles.type, styles.unlimited, type === 'unlimited' ? styles.active : ''].join(' ')} onClick={() => selectPlanType('unlimited')}>
                <div className={styles.typeBadge}>All-Inclusive</div>
                <div className={styles.typeText}>Unlimited data collection for your business.</div>
                <div className={styles.typePrice}><strong>€99</strong>/month, all-inclusive</div>
                {type === 'unlimited' && <CheckmarkIcon />}
              </div>
              <div className={[styles.type, styles.flex, type === 'flex' ? styles.active : ''].join(' ')} onClick={() => selectPlanType('flex')}>
                <div className={styles.typeBadge}>Pay-as-you-go</div>
                <div className={styles.typeText}>Create your own plan and only pay for what you need.</div>
                <div className={styles.typePrice}>Starting at <strong>€1</strong>/month</div>
                {type === 'flex' && <CheckmarkIcon />}
              </div>
            </div>

            {type === 'unlimited' && <PlanUnlimited />}
            {type === 'flex' && <PlanSetup plan={selectedPlan} onChange={updatePlan} />}
          </div>

          <Sticky top={50} bottomBoundary={`.${styles.calculator}`} className={styles.sticky}>
            <div className={styles.right}>
              <PlanSummary plan={selectedPlan} buttonText={'Start using Pro'} beforeRedirect={updatePlanType} buttonTo={'/plan'} onTotalChange={handleTotalChange} type={type} />

              <div className={styles.info}>We've estimated your monthly cost based on the options you've chosen.</div>
            </div>
          </Sticky>
        </div>
      </div>
    </Card>
  </>;
};

export default BillingFree;