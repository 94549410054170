import styles from './LifeTimeOrder.module.css';

import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { BasicLoader, Button } from 'ui';

import {
  getMollieOrder
} from 'store/ducks/plan.js';

import ltdLogo from 'assets/images/ltd.png';

const LifeTimeOrder = ({ match }) => {
  const dispatch = useDispatch();

  const [url, setUrl] = useState(null);
  const [status, setStatus] = useState(null);
  const [redirecting, setRedirecting] = useState(false);
  
  const checkStatus = (status) => {
    const paid = ['completed', 'paid', 'shipping'].indexOf(status) !== -1;

    if (paid && !redirecting) {
      setTimeout(() => window.location.replace('/?ltdsuccess=true'), 1000);
      setRedirecting(true);
    }
  };

  const getOrder = (id) => {
    dispatch(getMollieOrder(id)).then((res) => {
      checkStatus(res.status);
      setStatus(res.status);
      setUrl(res.url);
    });
  }

  useEffect(() => {
    getOrder(match.params.id);

    setInterval(() => {
      getOrder(match.params.id);
    }, 3000);
  }, []);

  return <div className={styles.main}>
    <img className={styles.logo} src={ltdLogo} alt="LTDFans" height="60px" />
    <BasicLoader className={styles.loader} size={30} />
    {status && <p>{`Payment Status: ${status}`}</p>}
    {url && <Button className={styles.button} href={url} theme="white" size="small">Open Checkout Page</Button>}
  </div>;
};

export default LifeTimeOrder;
