import styles from './BillingUpdatePaymentMethod.module.css';

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { injectStripe } from 'react-stripe-elements';

import { Button, CreditCardInput, FormRow, Loader } from 'ui';

import {
  saveUserSource, updateCollectionMethod, updateLoading, updateCreditCardErrorMessage, updateForm
} from 'store/ducks/plan.js';

import {
  getBilling
} from 'store/ducks/user.js';


class BillingUpdatePaymentMethod extends Component {
  UNSAFE_componentWillMount = () => {
    this.props.updateForm('collectionMethod', 'charge_automatically');
    this.props.getBilling();
  }

  handleSubmit = async (e) => {
    e.preventDefault();

    this.props.updateLoading(true);

    if (this.props.form.collectionMethod === 'charge_automatically') {
      const { error } = await this.props.stripe.createToken();

      if (error) {
        this.props.updateCreditCardErrorMessage(error.message);
        this.props.updateLoading(false);

        return;
      } else {
        this.props.updateCreditCardErrorMessage(null);
      }

      this.props.updateCollectionMethod().then(() => {
        return this.props.stripe.createSource({
          type: 'card',
          usage: 'reusable'
        });
      }).then((res) => {
        return this.props.saveUserSource(res.source.id);
      }).then(() => {
        this.props.history.push('/user/billing');
        this.props.updateLoading(false);
      });
    } else {
      this.props.updateCollectionMethod().then(() => {
        this.props.history.push('/user/billing');
        this.props.updateLoading(false);
      });
    }
  };

  render() {
    const {
      form, creditCardErrorMessage, loading
    } = this.props;

    return (
      <form className={styles.form} onSubmit={this.handleSubmit}>
        {/* <ul className={styles.paymentSelect}>
          <li className={form.collectionMethod === 'charge_automatically' ? styles.active : ''} onClick={() => this.props.updateForm('collectionMethod', 'charge_automatically')}>
            <span>Automatic payments</span>
            <p>Have your bill paid automatically with your credit/debit card.</p>
          </li>
          <li className={form.collectionMethod === 'send_invoice' ? styles.active : ''} onClick={() => this.props.updateForm('collectionMethod', 'send_invoice')}>
            <span>Manual payments</span>
            <p>Manually pay for your bill using credit/debit card or other international payment methods.</p>
          </li>
        </ul> */}

        <FormRow label="Card information" required={true} hide={form.collectionMethod === 'send_invoice'} errorMessage={creditCardErrorMessage}>
          <CreditCardInput error={!!creditCardErrorMessage} onChange={() => this.props.updateCreditCardErrorMessage(null)} />
        </FormRow>

        <Button type="submit" size="large" theme="blue" fullWidth={true} margin="20px 0 0 0">Update payment information</Button>

        <Link to="/user/billing" className={styles.cancel}>Cancel</Link>

        {loading && <Loader size={40} background="rgba(255, 255, 255, 0.5)" />}
      </form>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    form: state.plan.form,
    user: state._users.user,
    billing: state.user.billing,
    creditCardErrorMessage: state.plan.creditCardErrorMessage,
    loading: state.plan.loading
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateCollectionMethod: () => dispatch(updateCollectionMethod()),
    saveUserSource: (sourceId) => dispatch(saveUserSource(sourceId)),
    updateLoading: (value) => dispatch(updateLoading(value)),
    updateForm: (key, value) => dispatch(updateForm(key, value)),
    updateCreditCardErrorMessage:  (value) => dispatch(updateCreditCardErrorMessage(value)),
    getBilling: () => dispatch(getBilling())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(injectStripe(BillingUpdatePaymentMethod)));
