import styles from './Profile.module.css';

import React, { Component } from 'react';
import { connect } from 'react-redux';
import debounce from 'lodash.debounce';
import { verifyPermission } from 'misc/helpers.js';

import DeleteAccountModal from './modals/DeleteAccount.js';
import OtpCreateModal from './modals/OtpCreateModal.js';
import OtpRemoveModal from './modals/OtpRemoveModal.js';

import {
  clearSettings, toggleChangePassword, submitUpdatePassword, updateOldPassword, updateNewPassword,
  showDeleteAccountModal, showOtpCreateModal, showOtpRemoveModal
} from 'store/ducks/user.js';

import {
  updateUser
} from 'store/ducks/_users.js';

import { Button, PasswordInput, TextInput, Loader, Card, FormRow, CardRow, CardRowLabel, CardRowContent } from 'ui';

class Profile extends Component {
  componentWillUnmount = () => {
    this.props.clear();
  }

  handleChange = (params) => {
    this.props.updateUser(params, {
      updateApi: false
    });

    this.apiUpdateUser(params);
  }

  apiUpdateUser = debounce((params) => {
    this.props.updateUser(params, {
      updateLocal: false
    });
  }, 500);

  render() {
    const {
      user, showChangePassword, oldPassword, newPassword, changePasswordLoading, changePasswordError, changePasswordSuccess
    } = this.props;

    if (user.authType === 'onetool') return <></>;

    return (
      <>
        <DeleteAccountModal />
        <OtpCreateModal />
        <OtpRemoveModal />

        <Card className={styles.card}>
          <CardRow>
            <CardRowLabel title="Personal Information" subTitle="We’ll use this information to get in touch with you when we need to, and we obviously like to call you by your name!" />

            <CardRowContent>
              <FormRow label="Your name">
                <TextInput value={user.name} width={350} onChange={(e) => this.handleChange({ name: e.target.value })} />
              </FormRow>

              <FormRow label="Your email">
                <TextInput value={user.email} width={350} onChange={() => { }} disabled={true} />
              </FormRow>
            </CardRowContent>
          </CardRow>

          <CardRow>
            <CardRowLabel title="Company Information" />

            <CardRowContent>
              <FormRow label="Your company name">
                <TextInput value={user.companyName} width={350} disabled={!verifyPermission('editCompanyName', user)} onChange={(e) => this.handleChange({ companyName: e.target.value })} />
              </FormRow>
            </CardRowContent>
          </CardRow>

          {user.authType === 'local' && <CardRow>
            <CardRowLabel title="Password" />

            <CardRowContent>
              {!showChangePassword && !changePasswordSuccess && <Button theme="white" onClick={() => this.props.toggleChangePassword()}>Change Password</Button>}
              {changePasswordSuccess && <div className={styles.changePasswordSuccess}>Your password has been changed successfully!</div>}

              {showChangePassword && <div className={styles.changePassword}>
                {changePasswordLoading && <Loader size={40} />}

                <CardRowContent>
                  <FormRow label="Current password">
                    <PasswordInput name="oldPassword" value={oldPassword} width={350} disabled={changePasswordLoading} onKeyPress={(e) => {
                      if (e.key === 'Enter' && newPassword.length >= 6 && oldPassword.length >= 6 && !changePasswordLoading) this.props.submitUpdatePassword();
                    }} onChange={(e) => this.props.updateOldPassword(e.target.value)} />
                    {changePasswordError && <div className={styles.error}>Incorrect password</div>}
                  </FormRow>

                  <FormRow label="New password">
                    <PasswordInput name="newPassword" value={newPassword} width={350} disabled={changePasswordLoading} onKeyPress={(e) => {
                      if (e.key === 'Enter' && newPassword.length >= 6 && oldPassword.length >= 6 && !changePasswordLoading) this.props.submitUpdatePassword();
                    }} onChange={(e) => this.props.updateNewPassword(e.target.value)} />
                  </FormRow>
                </CardRowContent>

                <Button theme="blue" disabled={newPassword.length < 6 || oldPassword.length < 6 || changePasswordLoading} onClick={() => this.props.submitUpdatePassword()}>Update Password</Button>
              </div>}
            </CardRowContent>
          </CardRow>}

          <CardRow>
            <CardRowLabel title="Two-factor Authentication" subTitle="Add an additional layer of protection on your account using the two-factor authentication. This is required to use some QuestionScout features." />

            <CardRowContent>
              {(!user.otp || !user.otp.enabled) && <Button theme="white" onClick={this.props.showOtpCreateModal}>Enable Two-Factor Authentication</Button>}
              {user.otp && user.otp.enabled && <Button theme="red" onClick={this.props.showOtpRemoveModal}>Disable Two-Factor Authentication</Button>}
            </CardRowContent>
          </CardRow>

          <CardRow>
            <CardRowLabel title="Data Processing Agreement" subTitle="A downloadable data processing agreement for those within the EU that require it." />

            <CardRowContent>
              <Button theme="white" href="https://formsquare.s3.amazonaws.com/QS_GDPR_DPA_AUG2020.pdf" target="_blank" rel="noopener noreferrer">Download DPA</Button>
              <div className={styles.desc}>Last revised on August 19th, 2020.</div>
            </CardRowContent>
          </CardRow>
        </Card>

        <button className={styles.deleteAccount} onClick={this.props.showDeleteAccountModal}>Delete your account</button>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    user: state._users.user,
    showChangePassword: state.user.showChangePassword,
    oldPassword: state.user.oldPassword,
    newPassword: state.user.newPassword,
    changePasswordLoading: state.user.changePasswordLoading,
    changePasswordError: state.user.changePasswordError,
    changePasswordSuccess: state.user.changePasswordSuccess
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateUser: (params, options) => dispatch(updateUser(params, options)),
    toggleChangePassword: () => dispatch(toggleChangePassword()),
    clear: () => dispatch(clearSettings()),
    submitUpdatePassword: () => dispatch(submitUpdatePassword()),
    updateOldPassword: (value) => dispatch(updateOldPassword(value)),
    updateNewPassword: (value) => dispatch(updateNewPassword(value)),
    showDeleteAccountModal: () => dispatch(showDeleteAccountModal()),
    showOtpCreateModal: () => dispatch(showOtpCreateModal()),
    showOtpRemoveModal: () => dispatch(showOtpRemoveModal())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
