/** @jsx jsx */
/* @jsxFrag React.Fragment */

import { jsx, css } from '@emotion/core';
import React, { useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import Color from 'color';
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import getFieldLabel from 'helpers/getFieldLabel.js';
import Translate from '../messages/Translate.js';

import { LabelAtom, DescriptionAtom, LoaderAtom } from './atoms.js';

const fileExtensions = {
  'application/pdf': '.pdf',
  'application/msword': '.doc',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': '.docx',
  'text/plain': '.txt',
  'application/rtf': '.rtf',
  'application/vnd.oasis.opendocument.text': '.odt',
  'application/vnd.ms-powerpoint': '.ppt',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation': '.pptx',
  'application/vnd.oasis.opendocument.presentation': '.odp',
  'application/vnd.oasis.opendocument.spreadsheet': '.ods',
  'text/csv': '.csv',
  'application/vnd.ms-excel': '.xls',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': '.xlsx',
  'application/x-iwork-numbers-sffnumbers': '.numbers',
  'application/x-iwork-keynote-sffke': '.key',
  'image/png': '.png',
  'image/jpeg': '.jpg',
  'image/gif': '.gif',
  'application/json': '.json',
  'text/xml': '.xml',
  'application/zip': '.zip',
  'application/x-rar-compressed': '.rar',
  'audio/mpeg': '.mp3',
  'audio/wav': '.wav',
  'audio/aiff': '.aiff'
};

const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

const UploadIcon = () => <svg width="21" height="19" xmlns="http://www.w3.org/2000/svg"><g><path d="M10.71 9.29a1 1 0 0 0-1.4 0l-3 2.9a1 1 0 1 0 1.38 1.44L9 12.36V18a1 1 0 0 0 2 0v-5.59l1.29 1.3a1 1 0 0 0 1.42 0 1 1 0 0 0 0-1.42l-3-3z" /><path d="M15.67 5A6 6 0 0 0 4.33 5a5 5 0 0 0-3.08 8.27A1 1 0 1 0 2.75 12 3 3 0 0 1 5 7h.1a1 1 0 0 0 1-.8 4 4 0 0 1 7.84 0 1 1 0 0 0 1 .8H15a3 3 0 0 1 2.25 5 1 1 0 0 0 .09 1.42 1 1 0 0 0 1.41-.09A5 5 0 0 0 15.67 5z" /></g></svg>;

const FileUpload = ({ values, form, field, theme, files, value, handleUpload, handleDelete, handleCancel, fieldsOptions, variables }) => {
  const translate = new Translate(form.messages);

  const alignOptions = {
    left: 'flex-start',
    center: 'center',
    right: 'flex-end'
  };

  const mainStyle = css({
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: alignOptions[theme.fileUploadsAlignment],
    fontFamily: theme.typographySupportingFontFamily,
    margin: `${theme.fileUploadsTopMargin || 0}px 0 ${theme.fileUploadsBottomMargin || 0}px 0`
  });

  const contentStyle = css({
    width: '100%',
    maxWidth: `${theme.fileUploadsWidth}${theme.fileUploadsWidthType}`
  });

  const dropzoneStyle = css({
    background: theme.fileUploadsIdleBackground,
    padding: `0 ${theme.fileUploadsIdleHorizontalPadding}px`,
    height: theme.fileUploadsIdleHeight,
    maxHeight: theme.fileUploadsIdleHeight,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    lineHeight: '142%',
    userSelect: 'none',
    borderRadius: `${theme.fileUploadsIdleRoundness}px`,
    border: `${theme.fileUploadsIdleBorderSize}px ${theme.fileUploadsIdleBorderStyle} ${theme.fileUploadsIdleBorderColor}`,
    boxShadow: theme.fileUploadsIdleShadow ? `${theme.fileUploadsIdleShadowOffsetX}px ${theme.fileUploadsIdleShadowOffsetY}px ${theme.fileUploadsIdleShadowBlur}px ${theme.fileUploadsIdleShadowSpread}px ${theme.fileUploadsIdleShadowColor}` : 'none',
    span: {
      color: theme.fileUploadsIdleMainTextColor,
      fontWeight: theme.fileUploadsIdleMainTextWeight,
      fontSize: theme.fileUploadsIdleMainTextFontSize,
    },
    p: {
      color: theme.fileUploadsIdleHelpTextColor,
      fontWeight: theme.fileUploadsIdleHelpTextWeight,
      fontSize: theme.fileUploadsIdleHelpTextFontSize,
    },
    'svg *': {
      fill: theme.fileUploadsIdleMainTextColor
    },
    '&:hover': (() => {
      if (!theme.fileUploadsHoverEnable) return {};

      const obj = {
        span: {
          color: theme.fileUploadsHoverMainTextColor
        },
        p: {
          color: theme.fileUploadsHoverHelpTextColor
        },
        'svg *': {
          fill: theme.fileUploadsHoverMainTextColor
        },
        background: theme.fileUploadsHoverBackground,
        border: `${theme.fileUploadsIdleBorderSize}px ${theme.fileUploadsHoverBorderStyle} ${theme.fileUploadsHoverBorderColor}`
      };

      if (theme.fileUploadsHoverShadow) {
        obj.boxShadow = `${theme.fileUploadsHoverShadowOffsetX}px ${theme.fileUploadsHoverShadowOffsetY}px ${theme.fileUploadsHoverShadowBlur}px ${theme.fileUploadsHoverShadowSpread}px ${theme.fileUploadsHoverShadowColor}`;
      } else {
        obj.boxShadow = 'none';
      }

      return obj;
    })()
  });

  const textStyle = css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    svg: {
      margin: '0 0 10px 0'
    }
  });

  const mainTextStyle = css({
    textAlign: 'center',
    'span:first-of-type': {
      textDecoration: 'underline',
      cursor: 'pointer'
    }
  });

  const helpTextStyle = css({
    textAlign: 'center',
    p: {
      padding: 0,
      margin: 0
    },
    'p:first-of-type': {
      margin: '0 0 5px 0'
    }
  });

  const filesStyle = css({
    listStyle: 'none',
    padding: 0,
    margin: 0,
    'li': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      lineHeight: '142%',
      background: theme.fileUploadsFileBackground,
      padding: `0 ${theme.fileUploadsFileHorizontalPadding}px`,
      margin: `${theme.fileUploadsFileVerticalMargin}px 0`,
      minHeight: theme.fileUploadsFileHeight,
      color: theme.fileUploadsFileTextColor,
      fontWeight: theme.fileUploadsFileTextWeight,
      fontSize: `${theme.fileUploadsFileTextFontSize}px`,
      borderRadius: `${theme.fileUploadsFileRoundness}px`,
      border: `${theme.fileUploadsFileBorderSize}px ${theme.fileUploadsFileBorderStyle} ${theme.fileUploadsFileBorderColor}`,
      boxShadow: theme.fileUploadsFileShadow ? `${theme.fileUploadsFileShadowOffsetX}px ${theme.fileUploadsFileShadowOffsetY}px ${theme.fileUploadsFileShadowBlur}px ${theme.fileUploadsFileShadowSpread}px ${theme.fileUploadsFileShadowColor}` : 'none',
    },
    'li:first-of-type': {
      margin: `0 0 ${theme.fileUploadsFileVerticalMargin}px 0`,
    },
    'svg:first-of-type': {
      width: theme.fileUploadsFileHeight / 2,
      height: theme.fileUploadsFileHeight / 2,
      maxHeight: '25px'
    }
  });

  const leftStyle = css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  });

  const nameStyle = css({
    padding: '5px 0',
    'span': {
      color: Color(theme.fileUploadsFileTextColor).isLight() ? '#000' : '#FFF',
      background: theme.fileUploadsFileTextColor,
      borderRadius: 2,
      padding: '0 7px',
      margin: '0 0 0 7px',
      fontSize: `${theme.fileUploadsFileTextFontSize - 2}px`,
      display: 'inline-block'
    }
  });

  const loaderStyle = css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    minWidth: (theme.fileUploadsFileHeight / 2) + 50,
    'div.circularProgressbarWithChildren': {
      height: theme.fileUploadsFileHeight / 2
    },
    'span': {
      boxSizing: 'border-box',
      display: 'block',
      margin: '0 0 0 5px'
    }
  });

  const cancelDeleteStyle = css({
    cursor: 'pointer',
    width: 100,
    textAlign: 'right'
  });

  const { getRootProps, getInputProps, open } = useDropzone({
    noClick: true,
    noKeyboard: true,
    multiple: true,
    disabled: files.length >= field.fileUploadMaxFiles,
    preventDropOnDocument: true,
    maxSize: 50000000, // Maximum file size (in bytes) / 50MB
    accept: field.fileUploadExtensions.join(', '),
    onDrop: (droppedFiles) => {
      const slicedFiles = droppedFiles.slice(0, field.fileUploadMaxFiles - files.length);

      handleUpload(slicedFiles, field._id);
    }
  });

  return <>
    <div css={mainStyle}>
      <div css={contentStyle}>
        <LabelAtom required={field.required} error={field.error} theme={theme}>{getFieldLabel(field.label, values, 'jsx', fieldsOptions, variables)}</LabelAtom>

        {field.fileUploadMaxFiles !== files.length && <div css={dropzoneStyle} {...getRootProps()}>
          <input {...getInputProps()} />

          <div css={textStyle} onClick={open}>
            <UploadIcon />

            <div css={mainTextStyle}>
              <span>{translate.text('uploadText')}</span>
            </div>
          </div>

          <div css={helpTextStyle}>
            <p>{translate.text('uploadInstructions', {
              maxFiles: { value: field.fileUploadMaxFiles }
            })}</p>
            <p>{field.fileUploadExtensions.map((mimeType) => fileExtensions[mimeType]).join(', ')}</p>
          </div>
        </div>}

        <ul css={filesStyle}>
          {files.map((file) => <li key={file.ref}>
            <div css={leftStyle}>

              {file.loaded !== true && <div css={loaderStyle}>
                {file.loaded !== 100 && <>
                  <CircularProgressbarWithChildren className="circularProgressbarWithChildren" value={file.loaded} styles={buildStyles({
                    pathColor: '#162D5A',
                    textColor: theme.fieldsLabelColor,
                    trailColor: '#BFBFBF'
                  })} />
                  <span>{file.loaded}%</span>
                </>}

                {file.loaded === 100 && <LoaderAtom />}
              </div>}

              <div css={nameStyle}>
                {file.name}<span>{formatBytes(file.size)}</span>
              </div>
            </div>

            {file.loaded <= 95 && file.loaded !== true && <div css={cancelDeleteStyle} onClick={() => handleCancel(file.ref)}>{translate.text('uploadCancel')}</div>}
            {file.loaded === true && <div css={cancelDeleteStyle} onClick={() => handleDelete(file._id, field._id)}>{translate.text('uploadDelete')}</div>}
          </li>)}
        </ul>

        <DescriptionAtom theme={theme}>{getFieldLabel(field.description, values, 'jsx', fieldsOptions, variables)}</DescriptionAtom>
      </div>
    </div>
  </>;
};

export default FileUpload;
