import styles from './Settings.module.css';

import React, { Component } from 'react';
import { Route, Switch, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { verifyPermissionAndRender, verifyPermission } from 'misc/helpers.js';

import Header from '../../Header/Header.js';
import Billing from './Billing/Billing.js';
import Users from './Users/Users.js';
import Profile from './Profile.js';
import Integrations from './Integrations.js';
import Onetool from './Onetool/Onetool.js';
import Domains from './Domains/Domains.js';
import Affiliate from './Affiliate.js';
import ConnectedServices from './ConnectedServices/ConnectedServices.js';

class Settings extends Component {
  render() {
    const {
      user
    } = this.props;

    const {
      page
    } = this.props.match.params;

    const isLTD = user && user.lifeTime && user.lifeTime.enabled;

    return (
      <div className={styles.main}>
        <Header showAccount={true} showMenu={true} />

        <div className={styles.content}>
          <div className={styles.header}>Manage Account</div>

          <div className={styles.section}>
            <ul className={styles.menu}>
              {user.authType !== 'onetool' && <li className={page === 'profile' ? styles.active : ''}>
                <Link to={`/user/profile`}>Profile</Link>
              </li>}
              {user.authType !== 'onetool' && verifyPermission('accessBilling', user) && <li className={page === 'billing' ? styles.active : ''}>
                <Link to={`/user/billing`}>{!isLTD ? 'Billing' : 'LTD Plan'}</Link>
              </li>}
              {user.authType !== 'onetool' && verifyPermission('accessUsers', user) && <li className={page === 'users' ? styles.active : ''}>
                <Link to={`/user/users`}>Users</Link>
              </li>}
              {user.authType !== 'onetool' && verifyPermission('accessDomains', user) && <li className={page === 'domains' ? styles.active : ''}>
                <Link to={`/user/domains`}>Custom Domains</Link>
              </li>}
              {user.authType !== 'onetool' && <li className={page === 'affiliate' ? styles.active : ''}>
                <Link to={`/user/affiliate`}>Affiliate</Link>
              </li>}
              {/* {user.authType !== 'onetool' && verifyPermission('accessConnectedServices', user) && <li className={page === 'connected' ? styles.active : ''}>
                <Link to={`/user/accounts`}>Connected Services</Link>
              </li>} */}
              {user.authType === 'onetool' && <li className={page === 'onetool' ? styles.active : ''}>
                <Link to={`/user/onetool`}>Onetool</Link>
              </li>}
              {/* <li className={page === 'integrations' ? styles.active : ''}>
                <Link to={`/user/integrations`}>Integrations</Link>
              </li> */}
            </ul>

            <div className={styles.page}>
              <Switch>
                {user.authType !== 'onetool' && <Route path="/user/profile" component={Profile} />}
                {user.authType !== 'onetool' && <Route path="/user/billing" component={verifyPermissionAndRender(Billing, 'accessBilling', user)} />}
                {user.authType !== 'onetool' && <Route path="/user/users" component={verifyPermissionAndRender(Users, 'accessUsers', user)} />}
                {user.authType !== 'onetool' && <Route path="/user/domains" component={verifyPermissionAndRender(Domains, 'accessUsers', user)} />}
                {user.authType !== 'onetool' && <Route path="/user/affiliate" component={Affiliate} />}
                {user.authType !== 'onetool' && <Route path="/user/connected" component={ConnectedServices} />}
                {user.authType === 'onetool' && <Route path="/user/onetool" component={Onetool} />}
                <Route path="/user/integrations" component={Integrations} />
              </Switch>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    user: state._users.user
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
