import styles from './Fields.module.css';

import React, { Component } from 'react';

import { ReactComponent as HiddenIcon } from 'assets/images/hidden.svg';
import { ReactComponent as ClockIcon } from 'assets/images/clock.svg';
import { ReactComponent as CalendarIcon } from 'assets/images/calendar.svg';
import { ReactComponent as UploadIcon } from 'assets/images/upload.svg';
import { ReactComponent as PenIcon } from 'assets/images/pen.svg';
import { ReactComponent as KeyboardIcon } from 'assets/images/keyboard.svg';
import { ReactComponent as EraserIcon } from 'assets/images/eraser.svg';
import { ReactComponent as ImageEmptyIcon } from 'assets/images/image-empty.svg';

import { isJsonString } from 'misc/helpers.js';
import getFieldLabel from 'helpers/getFieldLabel.js';

const imageAlignOptions = {
  left: 'flex-start',
  center: 'center',
  right: 'flex-end'
};

/*
 * Signature Component
 */
export class Signature extends Component {
  shouldComponentUpdate = (nextProps) => {
    if (JSON.stringify(this.props.values) !== JSON.stringify(nextProps.values)) return true;

    return this.props.field.ref === nextProps.editedFieldRef;
  }

  render() {
    const { values, field, fieldsOptions } = this.props;

    return <>
      <div className={styles.signatureBox}>
        {(!field.label && field.required) && <div className={styles.requiredStandalone}></div>}

        {field.label && <div className={[
          styles.label
        ].join(' ')}>
          {getFieldLabel(field.label, values, 'jsx', fieldsOptions)}
          {field.required && <span className={styles.required}>*</span>}
        </div>}

        <div className={[
          styles.signature,
          field.readonly ? styles.readonly : '',
          (!field.label && field.required) ? styles.required : '',
          field.error ? styles.error : ''
        ].join(' ')}>
          <ul className={styles.signatureHeader}>
            <li className={styles.active}>
              <PenIcon /><div>DRAW</div>
            </li>
            {field.signatureAllowTyping && <li>
              <KeyboardIcon /><div>TYPE</div>
            </li>}
          </ul>

          <div className={styles.signatureDrawCanvas}></div>

          <div className={styles.signatureFooter}>
            <div className={styles.signatureClear}><EraserIcon />Clear Signature</div>
          </div>
        </div>

        {field.description && <div className={styles.description}>
          {getFieldLabel(field.description, values, 'jsx', fieldsOptions)}
        </div>}
      </div>
    </>;
  }
}

/*
 * ShortText Component
 */
export class ShortText extends Component {
  shouldComponentUpdate = (nextProps) => {
    if (JSON.stringify(this.props.values) !== JSON.stringify(nextProps.values)) return true;

    return this.props.field.ref === nextProps.editedFieldRef;
  }

  render() {
    const { values, field, fieldsOptions } = this.props;

    return <>
      <div className={styles.shortTextBox}>
        {field.label && <div className={[
          styles.label
        ].join(' ')}>
          {getFieldLabel(field.label, values, 'jsx', fieldsOptions)}
          {field.required && <span className={styles.required}>*</span>}
        </div>}

        <div className={styles.fieldInputBox}>
          {(!field.label && field.required) && <div className={styles.requiredStandalone}></div>}
          {field.hidden && <HiddenIcon className={styles.hidden} />}
          <input type="text" className={[
            styles.textInput,
            field.readonly ? styles.readonly : '',
            (!field.label && field.required) ? styles.required : '',
            field.hidden ? styles.hidden : ''
          ].join(' ')} disabled="disabled" value={field.value || ''} placeholder={field.placeholder || ''} />
        </div>

        {field.description && <div className={styles.description}>
          {getFieldLabel(field.description, values, 'jsx', fieldsOptions)}
        </div>}
      </div>
    </>;
  }
}

/*
 * LongText Component
 */
export class LongText extends Component {
  shouldComponentUpdate = (nextProps) => {
    if (JSON.stringify(this.props.values) !== JSON.stringify(nextProps.values)) return true;

    return this.props.field.ref === nextProps.editedFieldRef;
  }

  render() {
    const { values, field, fieldsOptions } = this.props;

    return <>
      <div className={styles.longTextBox}>
        {field.label && <div className={[
          styles.label
        ].join(' ')}>
          {getFieldLabel(field.label, values, 'jsx', fieldsOptions)}
          {field.required && <span className={styles.required}>*</span>}
        </div>}

        <div className={styles.fieldInputBox}>
          {(!field.label && field.required) && <div className={styles.requiredStandalone}></div>}
          {field.hidden && <HiddenIcon className={styles.hidden} />}
          <textarea className={[
            styles.textarea,
            field.readonly ? styles.readonly : '',
            (!field.label && field.required) ? styles.required : '',
            field.hidden ? styles.hidden : ''
          ].join(' ')} rows={field.longTextRows || 3} disabled="disabled" value={field.value || ''} placeholder={field.placeholder || ''} />
        </div>

        {field.description && <div className={styles.description}>
          {getFieldLabel(field.description, values, 'jsx', fieldsOptions)}
        </div>}
      </div>
    </>;
  }
}


/*
 * Dropdown Component
 */
export class Dropdown extends Component {
  shouldComponentUpdate = (nextProps) => {
    if (JSON.stringify(this.props.values) !== JSON.stringify(nextProps.values)) return true;

    return this.props.field.ref === nextProps.editedFieldRef;
  }

  render() {
    const { values, field, fieldsOptions } = this.props;

    let selectedOption = field.options.find((option) => option.ref === field.value);

    return <>
      <div className={styles.dropdownBox}>
        {field.label && <div className={[
          styles.label
        ].join(' ')}>
          {getFieldLabel(field.label, values, 'jsx', fieldsOptions)}
          {field.required && <span className={styles.required}>*</span>}
        </div>}

        <div className={styles.fieldInputBox}>
          {(!field.label && field.required) && <div className={styles.requiredStandalone}></div>}
          {field.hidden && <HiddenIcon className={styles.hidden} />}
          <input type="text" className={[
            styles.dropdownInput,
            field.readonly ? styles.readonly : '',
            (!field.label && field.required) ? styles.required : '',
            field.hidden ? styles.hidden : ''
          ].join(' ')} disabled="disabled" value={selectedOption ? selectedOption.value : ''} placeholder={field.placeholder || ''} />
        </div>

        {field.description && <div className={styles.description}>
          {getFieldLabel(field.description, values, 'jsx', fieldsOptions)}
        </div>}
      </div>
    </>;
  }
}

/*
 * Radio Component
 */
export class Radio extends Component {
  shouldComponentUpdate = (nextProps) => {
    if (JSON.stringify(this.props.values) !== JSON.stringify(nextProps.values)) return true;

    return this.props.field.ref === nextProps.editedFieldRef;
  }

  render() {
    const { values, field, fieldsOptions } = this.props;

    return <>
      <div className={styles.radioBox}>
        {field.label && <div className={[
          styles.label
        ].join(' ')}>
          {getFieldLabel(field.label, values, 'jsx', fieldsOptions)}
          {field.required && <span className={styles.required}>*</span>}
        </div>}

        <div className={styles.fieldInputBox}>
          {(!field.label && field.required) && <div className={styles.requiredStandalone}></div>}
          {field.hidden && <HiddenIcon className={styles.hidden} />}

          <div className={[
            styles.radioList,
            (!field.label && field.required) ? styles.required : '',
            field.hidden ? styles.hidden : ''
          ].join(' ')}>
            {field.options.map((option, index) =>
              <div className={[
                styles.radio,
                field.readonly ? styles.readonly : '',
              ].join(' ')} key={option.ref}>
                <div>
                  {field.value && option.ref === JSON.parse(isJsonString(field.value) ? field.value : '{"value":null,"other":null}').value && <span className={styles.circle}></span>}
                </div><span>{option.value}</span>
              </div>
            )}

            {field.otherOption && <div className={[
              styles.radio,
              field.readonly ? styles.readonly : '',
            ].join(' ')}>
              <div></div><span>Other</span>
            </div>}
          </div>
        </div>

        {field.description && <div className={styles.description}>
          {getFieldLabel(field.description, values, 'jsx', fieldsOptions)}
        </div>}
      </div>
    </>;
  }
}

/*
 * Checkbox Component
 */
export class Checkbox extends Component {
  shouldComponentUpdate = (nextProps) => {
    if (JSON.stringify(this.props.values) !== JSON.stringify(nextProps.values)) return true;

    return this.props.field.ref === nextProps.editedFieldRef;
  }

  render() {
    const { values, field, fieldsOptions } = this.props;

    return <>
      <div className={styles.checkboxBox}>
        {field.label && <div className={[
          styles.label
        ].join(' ')}>
          {getFieldLabel(field.label, values, 'jsx', fieldsOptions)}
          {field.required && <span className={styles.required}>*</span>}
        </div>}

        <div className={styles.fieldInputBox}>
          {(!field.label && field.required) && <div className={styles.requiredStandalone}></div>}
          {field.hidden && <HiddenIcon className={styles.hidden} />}

          <div className={[
            styles.checkboxList,
            (!field.label && field.required) ? styles.required : '',
            field.hidden ? styles.hidden : ''
          ].join(' ')}>
            {field.options.map((option) =>
              <div className={[
                styles.checkbox,
                field.readonly ? styles.readonly : '',
              ].join(' ')} key={option.ref}>
                <div>
                  {field.value && JSON.parse(isJsonString(field.value) ? field.value : '{"values":[],"other":null}').values.indexOf(option.ref) !== -1 && <span className={styles.checkmark}></span>}
                </div><span>{option.value}</span>
              </div>
            )}

            {field.otherOption && <div className={[
              styles.checkbox,
              field.readonly ? styles.readonly : '',
            ].join(' ')}>
              <div></div>Other
          </div>}
          </div>
        </div>

        {field.description && <div className={styles.description}>
          {getFieldLabel(field.description, values, 'jsx', fieldsOptions)}
        </div>}
      </div>
    </>;
  }
}

/*
 * Divider Component
 */
export class Divider extends Component {
  shouldComponentUpdate = (nextProps) => {
    if (JSON.stringify(this.props.values) !== JSON.stringify(nextProps.values)) return true;

    return this.props.field.ref === nextProps.editedFieldRef;
  }

  render() {
    return <>
      <div className={styles.divider} />
    </>;
  }
}

/*
 * Section Component
 */
export class Section extends Component {
  shouldComponentUpdate = (nextProps) => {
    if (JSON.stringify(this.props.values) !== JSON.stringify(nextProps.values)) return true;

    return this.props.field.ref === nextProps.editedFieldRef;
  }

  render() {
    return <>
      <div className={styles.section}>{this.props.children}</div>
    </>;
  }
}

/*
 * Title Component
 */
export class Title extends Component {
  shouldComponentUpdate = (nextProps) => {
    if (JSON.stringify(this.props.values) !== JSON.stringify(nextProps.values)) return true;

    return this.props.field.ref === nextProps.editedFieldRef;
  }

  render() {
    const { values, field, fieldsOptions } = this.props;

    return <>
      <div className={styles.title}>
        {getFieldLabel(field.label, values, 'jsx', fieldsOptions)}
      </div>

      {field.description && <div className={styles.description}>
        {getFieldLabel(field.description, values, 'jsx', fieldsOptions)}
      </div>}
    </>;
  }
}

/*
 * Submit Component
 */
export class Submit extends Component {
  shouldComponentUpdate = (nextProps) => {
    // watch fields?
    return true;
  }

  render() {
    const { form, fields, selectedEmptyBox, selectedFieldOption } = this.props;

    let withPageBreak = typeof fields.find((f) => f.type === 'pageBreak') !== 'undefined';

    if (selectedEmptyBox && (selectedFieldOption || {}).type === 'pageBreak') {
      withPageBreak = true;
    }

    if (form.type !== 'classic') {
      withPageBreak = false;
    }

    return <>
      <div className={[styles.actionButtonsBox, withPageBreak ? styles.withBackButton : ''].join(' ')}>
        {withPageBreak && <button className={styles.submitButton}>Return</button>}
        <button className={styles.submitButton}>Submit</button>
      </div>
    </>;
  }
}

/*
 * Description Component
 */
export class Description extends Component {
  shouldComponentUpdate = (nextProps) => {
    if (JSON.stringify(this.props.values) !== JSON.stringify(nextProps.values)) return true;

    return this.props.field.ref === nextProps.editedFieldRef;
  }

  render() {
    const { values, field, fieldsOptions } = this.props;

    return <>
      {isJsonString(field.value)
        ? <div className={styles.descriptionField}>{getFieldLabel(field.value, values, 'jsx', fieldsOptions)}</div>
        : <div className={styles.descriptionField} dangerouslySetInnerHTML={{ __html: field.value }} />}
    </>;
  }
}

/*
 * Datetime Component
 */
export class Datetime extends Component {
  shouldComponentUpdate = (nextProps) => {
    if (JSON.stringify(this.props.values) !== JSON.stringify(nextProps.values)) return true;

    return this.props.field.ref === nextProps.editedFieldRef;
  }

  render() {
    const { values, field, fieldsOptions } = this.props;

    return <>
      <div className={styles.dateTimeBox}>
        {field.label && <div className={[
          styles.label
        ].join(' ')}>
          {getFieldLabel(field.label, values, 'jsx', fieldsOptions)}
          {field.required && <span className={styles.required}>*</span>}
        </div>}

        <div className={styles.fieldInputBox}>
          {(!field.label && field.required) && <div className={styles.requiredStandalone}></div>}
          {field.hidden && <HiddenIcon className={styles.hidden} />}
          <input type="text" className={[
            styles.textInput,
            field.readonly ? styles.readonly : '',
            (!field.label && field.required) ? styles.required : '',
            field.hidden ? styles.hidden : ''
          ].join(' ')} disabled="disabled" value={field.value || ''} placeholder={field.placeholder || ''} />
          {field.dateTimeMode !== 'time' && <CalendarIcon className={styles.inputIcon} />}
          {field.dateTimeMode === 'time' && <ClockIcon className={styles.inputIcon} />}
        </div>

        {field.description && <div className={styles.description}>
          {getFieldLabel(field.description, values, 'jsx', fieldsOptions)}
        </div>}
      </div>
    </>;
  }
}

/*
 * FileUpload Component
 */
export class FileUpload extends Component {
  shouldComponentUpdate = (nextProps) => {
    if (JSON.stringify(this.props.values) !== JSON.stringify(nextProps.values)) return true;

    return this.props.field.ref === nextProps.editedFieldRef;
  }

  render() {
    const { values, field, fieldsOptions } = this.props;

    return <>
      <div className={styles.fileUploadBox}>
        {field.label && <div className={[
          styles.label
        ].join(' ')}>
          {getFieldLabel(field.label, values, 'jsx', fieldsOptions)}
          {field.required && <span className={styles.required}>*</span>}
        </div>}

        <div className={styles.fieldInputBox}>
          {(!field.label && field.required) && <div className={styles.requiredStandalone}></div>}

          <div className={[
            styles.fileUploadArea,
            (!field.label && field.required) ? styles.required : ''
          ].join(' ')}>
            <UploadIcon />

            <p>Drop your file here</p>
            <p>or</p>
            <span>click to upload</span>
          </div>
        </div>

        {field.description && <div className={styles.description}>
          {getFieldLabel(field.description, values, 'jsx', fieldsOptions)}
        </div>}
      </div>
    </>;
  }
}

/*
 * PageBreak Component
 */
export class PageBreak extends Component {
  shouldComponentUpdate = (nextProps) => {
    if (JSON.stringify(this.props.values) !== JSON.stringify(nextProps.values)) return true;

    return this.props.field.ref === nextProps.editedFieldRef;
  }

  render() {
    const { field, fields, selectedEmptyBox, selectedFieldOption } = this.props;

    let isFirst = typeof fields.find((f) => f.type === 'pageBreak' && f.position < field.position) === 'undefined';

    if (selectedEmptyBox !== null && field.position >= selectedEmptyBox - 1 && (selectedFieldOption || {}).type === 'pageBreak') {
      isFirst = false;
    }

    return <>
      <div className={[styles.actionButtonsBox, styles.pageBreakBox, !isFirst ? styles.withBackButton : ''].join(' ')}>
        {!isFirst && <button className={styles.submitButton}>Return</button>}
        <button className={styles.submitButton}>Next Page</button>
      </div>
    </>;
  }
}

/*
 * Image Component
 */
export class Image extends Component {
  shouldComponentUpdate = (nextProps) => {
    if (JSON.stringify(this.props.values) !== JSON.stringify(nextProps.values)) return true;

    return this.props.field.ref === nextProps.editedFieldRef;
  }

  onError = (e) => {
    e.target.src = e.target.src.replace(/hash=\d*/, `hash=${Date.now()}`);
    e.target.onerror = null;
  }

  render() {
    const { field, fieldsOptions } = this.props;

    return <>
      {!field.value && <div className={[styles.imageBox, styles.empty].join(' ')}>
        <ImageEmptyIcon />
        <span>No image uploaded yet</span>
      </div>}

      {field.value && <div className={[styles.imageBox, styles.value].join(' ')} style={{ justifyContent: imageAlignOptions[field.imageAlign] }}>
        <img src={field.value} onError={(e) => {
          this.onError(e);
          this.onError = () => { };
        }} alt="" style={{ width: `${field.imageScale}%` }} />
      </div>}
    </>;
  }
}

/*
 * ImageChoice Component
 */
export class ImageChoice extends Component {
  shouldComponentUpdate = (nextProps) => {
    if (JSON.stringify(this.props.values) !== JSON.stringify(nextProps.values)) return true;

    return this.props.field.ref === nextProps.editedFieldRef;
  }

  onError = (e) => {
    e.target.src = e.target.src.replace(/hash=\d*/, `hash=${Date.now()}`);
  }

  render() {
    const { values, field, fieldsOptions } = this.props;

    return <>
      <div className={styles.imageChoiceBox}>
        {field.label && <div className={[
          styles.label
        ].join(' ')}>
          {getFieldLabel(field.label, values, 'jsx', fieldsOptions)}
          {field.required && <span className={styles.required}>*</span>}
        </div>}

        <div className={styles.fieldInputBox}>
          {(!field.label && field.required) && <div className={styles.requiredStandalone}></div>}
          {field.hidden && <HiddenIcon className={styles.hidden} />}

          <div className={[
            styles.imageChoiceList,
            (!field.label && field.required) ? styles.required : '',
            field.hidden ? styles.hidden : ''
          ].join(' ')}>
            {field.options.map((option) => {
              const value = isJsonString(option.value) ? JSON.parse(option.value) : { text: '', url: null };

              return <div className={[
                styles.imageChoice,
                field.readonly ? styles.readonly : '',
              ].join(' ')} key={option.ref}>
                <div>
                  {value.url ? <img src={value.url} onError={(e) => {
                    this.onError(e);
                    this.onError = () => { };
                  }} alt="" /> : <ImageEmptyIcon />}
                </div>
                <span>{value.text}</span>
              </div>;
            })}
          </div>
        </div>

        {field.description && <div className={styles.description}>
          {getFieldLabel(field.description, values, 'jsx', fieldsOptions)}
        </div>}
      </div>
    </>;
  }
}

/*
 * Scale Component
 */
export class Scale extends Component {
  shouldComponentUpdate = (nextProps) => {
    if (JSON.stringify(this.props.values) !== JSON.stringify(nextProps.values)) return true;

    return this.props.field.ref === nextProps.editedFieldRef;
  }

  render() {
    const { values, field, fieldsOptions } = this.props;

    const options = [];

    for (let i = field.scaleRange[0]; i <= field.scaleRange[1]; i++) {
      options.push(i);
    }

    return <>
      <div className={styles.shortTextBox}>
        {field.label && <div className={[
          styles.label
        ].join(' ')}>
          {getFieldLabel(field.label, values, 'jsx', fieldsOptions)}
          {field.required && <span className={styles.required}>*</span>}
        </div>}

        <div className={styles.fieldScaleBox}>
          {(!field.label && field.required) && <div className={styles.requiredStandalone}></div>}

          <div className={[
            styles.scale,
            (!field.label && field.required) ? styles.required : '',
          ].join(' ')}>
            <ul className={styles.scaleGrid}>
              {options.map((option) => <li key={option} style={{ width: `${100 / options.length}%` }}>{option}</li>)}
            </ul>

            {(field.scaleTextLeft || field.scaleTextCenter || field.scaleTextRight) && <ul className={styles.scaleInfo}>
              <li style={{ width: '32%', textAlign: 'left' }}>{field.scaleTextLeft}</li>
              <li style={{ width: '32%', textAlign: 'center' }}>{field.scaleTextCenter}</li>
              <li style={{ width: '32%', textAlign: 'right' }}>{field.scaleTextRight}</li>
            </ul>}
          </div>
        </div>

        {field.description && <div className={styles.description}>
          {getFieldLabel(field.description, values, 'jsx', fieldsOptions)}
        </div>}
      </div>
    </>;
  }
}
