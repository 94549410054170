import styles from './Edit.module.css';

import React, { Component } from 'react';
import { connect } from 'react-redux';
import debounce from 'lodash.debounce';
import OutsideClickHandler from 'react-outside-click-handler';
import isHtml from 'is-html';

import ShortText from './fields/ShortText.js';
import LongText from './fields/LongText.js';
import Dropdown from './fields/Dropdown.js';
import Radio from './fields/Radio.js';
import Checkbox from './fields/Checkbox.js';
import Title from './fields/Title.js';
import Description from './fields/Description.js';
import Datetime from './fields/Datetime.js';
import FileUpload from './fields/FileUpload.js';
import Signature from './fields/Signature.js';
import Image from './fields/Image.js';
import Scale from './fields/Scale.js';
import ImageChoice from './fields/ImageChoice.js';
import Section from './fields/Section.js';

import {
  updateFields, ioRemoveField, updateEditedFieldRef, updateField, ioUpdateField
} from 'store/ducks/builder.js';

import { ReactComponent as CloseIcon } from 'assets/images/close.svg';

class Edit extends Component {
  handleChange = (params, delay = true) => {
    const field = this.props.fields.find((field) => field.ref === this.props.editedFieldRef);

    if (!field) return;

    if ((field.type === 'shortText' || field.type === 'longText') && Object.keys(params)[0] === 'value') {
      params[Object.keys(params)[0]] = params[Object.keys(params)[0]].substring(0, field.valueMaxLength);
    }

    if ((field.type === 'radio' || field.type === 'dropdown' || field.type === 'checkbox') && Object.keys(params)[0] === 'options') {
      params[Object.keys(params)[0]] = params[Object.keys(params)[0]].map((option) => {
        option.value = option.value.substring(0, field.valueMaxLength);

        return option;
      });
    }

    if (typeof params[Object.keys(params)[0]] === 'string' && isHtml(params[Object.keys(params)[0]]) && (field.type !== 'description' && Object.keys(params)[0] !== 'value')) {
      params[Object.keys(params)[0]] = params[Object.keys(params)[0]].replace(/<(?:.|\n)*?>/gm, '');
    }

    if (typeof params[Object.keys(params)[0]] === 'string') {
      params[Object.keys(params)[0]] = params[Object.keys(params)[0]].replace(/<script[^>]*>.*?<\/script>/gi, '');
    }

    this.props.updateField(field.ref, params);

    if (delay) {
      this.ioUpdateField(field.ref, params);
    } else {
      this.props.ioUpdateField(field.ref, params);
    }
  }

  ioUpdateField = debounce((ref, params) => {
    this.props.ioUpdateField(ref, params);
  }, 500);

  close = () => {
    this.props.updateEditedFieldRef(null);
  }

  handleOutsideClick = (e) => {
    const tagExistInParents = (el, tags) => {
      while (el.parentNode) {
        el = el.parentNode;

        if (el.tagName && tags.indexOf(el.tagName) !== -1) return true;
      }

      return false;
    }

    try {
      if (this.props.editedFieldRef !== null && !tagExistInParents(e.target, ['GRAMMARLY-POPUPS', 'GRAMMARLY-CARD']) && e.target.getAttribute('data-not-outside') !== 'true' && (typeof e.target.className === 'string' && e.target.className.search('FormFields_') === -1)) {
        this.close();
      }
    } catch (e) { }
  }

  render() {
    const {
      editedFieldRef, fields, calculationVariables
    } = this.props;

    const field = fields.find((field) => field.ref === editedFieldRef);
    let mentionsData = [];

    if (field) {
      mentionsData = fields.filter((f) => f._id !== field._id && ['longText', 'shortText', 'scale', 'radio', 'checkbox', 'dropdown'].indexOf(f.type) !== -1).map((f) => ({
        _id: f._id,
        type: f.type,
        format: f.format,
        label: f.label
      }));

      if (calculationVariables && calculationVariables.length !== 0) mentionsData = [...mentionsData, ...calculationVariables.map((variable) => ({
        _id: variable._id,
        type: 'variable',
        format: null,
        label: variable.name
      }))];
    }

    return (
      <OutsideClickHandler onOutsideClick={(e) => this.handleOutsideClick(e)}>
        {!editedFieldRef && <div className={[styles.main, styles.empty].join(' ')}>
          {fields.length > 0 && <>
            <div className={styles.emptyHead}>Modifying your form fields</div>
            <div className={styles.emptyDesc}>Select one of the form fields you’ve added to access the field settings.</div>
          </>}
        </div>}

        {editedFieldRef && <div className={[styles.main, styles[field ? field.type : '']].join(' ')}>
          <div className={styles.header}>
            <span>Basic Options</span>
            <CloseIcon onClick={() => this.close()} />
          </div>

          <div className={styles.scrollArea}>
            <div className={styles.content}>
              {field && field.type === 'shortText' && <ShortText field={field} fields={fields} handleChange={this.handleChange} mentionsData={mentionsData} />}
              {field && field.type === 'longText' && <LongText field={field} fields={fields} handleChange={this.handleChange} mentionsData={mentionsData} />}
              {field && field.type === 'dropdown' && <Dropdown field={field} fields={fields} handleChange={this.handleChange} mentionsData={mentionsData} />}
              {field && field.type === 'radio' && <Radio field={field} fields={fields} handleChange={this.handleChange} mentionsData={mentionsData} />}
              {field && field.type === 'checkbox' && <Checkbox field={field} fields={fields} handleChange={this.handleChange} mentionsData={mentionsData} />}
              {field && field.type === 'title' && <Title field={field} fields={fields} handleChange={this.handleChange} mentionsData={mentionsData} />}
              {field && field.type === 'description' && <Description field={field} fields={fields} handleChange={this.handleChange} mentionsData={mentionsData} />}
              {field && field.type === 'datetime' && <Datetime field={field} fields={fields} handleChange={this.handleChange} mentionsData={mentionsData} />}
              {field && field.type === 'fileUpload' && <FileUpload field={field} fields={fields} handleChange={this.handleChange} mentionsData={mentionsData} />}
              {field && field.type === 'signature' && <Signature field={field} fields={fields} handleChange={this.handleChange} mentionsData={mentionsData} />}
              {field && field.type === 'image' && <Image field={field} fields={fields} handleChange={this.handleChange} mentionsData={mentionsData} />}
              {field && field.type === 'scale' && <Scale field={field} fields={fields} handleChange={this.handleChange} mentionsData={mentionsData} />}
              {field && field.type === 'imageChoice' && <ImageChoice field={field} fields={fields} handleChange={this.handleChange} mentionsData={mentionsData} />}
              {field && field.type === 'section' && <Section field={field} fields={fields} handleChange={this.handleChange} mentionsData={mentionsData} />}
            </div>
          </div>
        </div>}
      </OutsideClickHandler>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    calculationVariables: state.builder.form.calculationVariables,
    fields: state.builder.fields,
    editedFieldRef: state.builder.editedFieldRef
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateFields: (value) => dispatch(updateFields(value)),
    ioRemoveField: (ref) => dispatch(ioRemoveField(ref)),
    updateEditedFieldRef: (value) => dispatch(updateEditedFieldRef(value)),
    updateField: (ref, params) => dispatch(updateField(ref, params)),
    ioUpdateField: (ref, params) => dispatch(ioUpdateField(ref, params))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Edit);
