import styles from './Preview.module.css';

import config from 'config.js';
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { HashLink as Link } from 'react-router-hash-link';

import {
  toggleFormPreview
} from 'store/ducks/builder.js';

import { ReactComponent as CloseIcon } from 'assets/images/close.svg';

const Preview = ({ hideWelcome }) => {
  const dispatch = useDispatch();

  const previewRef = React.createRef();

  const form = useSelector(state => state.builder.form);

  const close = () => dispatch(toggleFormPreview(false));

  useEffect(() => {
    const box = previewRef.current.getBoundingClientRect();

    (function (a, b, c, e, f, x) {
      let s = a.createElement('script');
      s.src = b; s.setAttribute('data-form-id', e);
      s.setAttribute('data-runner-id', c);
      s.setAttribute('data-url-params', f);
      s.setAttribute('data-preview-token', x);
      s.setAttribute('data-size', `${box.width}x${box.height}`);
      a.head.appendChild(s);
    })(window.document, `${config.formUrl}/qs-form-script.min.js`, 'cqcjbhnrrsrdnkq', form._id, `[{"key":"qs__hidewelcome","value":${hideWelcome ? 'true' : 'false'}}, {"key":"qs__ghost","value":"true"}]`, form.previewToken);
  }, []);

  return <div className={styles.main}>
    <div className={styles.topBar}>
      This is a preview of a {form.status} form.
      {form.status === 'draft' && <Link to={`/builder/${form._id}/publish`} onClick={close}>Publish this form</Link>}
    </div>

    <div className={styles.close} onClick={close}>Close Preview<CloseIcon /></div>

    <div ref={previewRef} className={[styles.preview].join(' ')} style={form.type === 'classic' ? { overflowY: 'hidden' } : { overflow: 'hidden' }}>
      <script id="cqcjbhnrrsrdnkq" />
    </div>
  </div>;
}

export default Preview;
