export default {
  apiUrl: process.env.REACT_APP_API_URL,
  formUrl: process.env.REACT_APP_FORM_URL,
  socketUrl: process.env.REACT_APP_SOCKET_URL,
  unsplashApiKey: process.env.REACT_APP_UNSPLASH_API_KEY,
  stripePublicKey: process.env.REACT_APP_STRIPE_PUBLIC_KEY,
  fullpageLicense: process.env.REACT_APP_FULLPAGE_LICENSE,

  permissions: {
    accessBilling: ['holder'],
    accessUsers: ['holder', 'admin'],
    accessDomains: ['holder', 'admin', 'mod'],
    accessConnectedServices: ['holder', 'admin', 'mod', 'user'],

    generateApiKey: ['holder'],
    editCompanyName: ['holder'],
    editWorkspace: ['holder', 'admin', 'mod'],
    createWorkspace: ['holder', 'admin', 'mod'],
    manageWorkspaceUsers: ['holder', 'admin', 'mod']
  }
};
