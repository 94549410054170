import styles from './EmptyBox.module.css';
import fieldsStyles from './../FormFields.module.css';

import React, { Component } from 'react';
import { connect } from 'react-redux';
import getFieldLabel from 'helpers/getFieldLabel.js';

import {
  updateSelectedEmptyBox, updateActiveButtonHover, updateSelectedFieldOption, updateFirstHoverOnFieldOption,
  updateFieldsListPosition
} from 'store/ducks/builder.js';

import { ReactComponent as CalendarIcon } from 'assets/images/calendar.svg';
import { ReactComponent as UploadIcon } from 'assets/images/upload.svg';
import { ReactComponent as PenIcon } from 'assets/images/pen.svg';
import { ReactComponent as KeyboardIcon } from 'assets/images/keyboard.svg';
import { ReactComponent as EraserIcon } from 'assets/images/eraser.svg';
import { ReactComponent as ImageEmptyIcon } from 'assets/images/image-empty.svg';

let timeout = null;

export const fieldPreviews = {
  shortText: React.memo(({ field }) => {
    return (
      <div className={styles.fieldPreview}>
        <div className={fieldsStyles.label}>{field.label}</div>
        <input type="text" className={fieldsStyles.textInput} placeholder={field.placeholder} />
      </div>
    );
  }),
  longText: React.memo(({ field }) => {
    return (
      <div className={styles.fieldPreview}>
        <div className={fieldsStyles.label}>{field.label}</div>
        <textarea className={fieldsStyles.textarea} rows="3" placeholder={field.placeholder} />
      </div>
    );
  }),
  dropdown: React.memo(({ field }) => {
    return (
      <div className={styles.fieldPreview}>
        <div className={fieldsStyles.label}>{field.label}</div>
        <select className={fieldsStyles.dropdownInput}></select>
      </div>
    );
  }),
  radio: React.memo(({ field }) => {
    return (
      <div className={styles.fieldPreview}>
        <div className={fieldsStyles.label}>{field.label}</div>
        <div className={fieldsStyles.radio}><input type="radio" /><div></div>Option 1</div>
        <div className={fieldsStyles.radio}><input type="radio" /><div></div>Option 2</div>
        <div className={fieldsStyles.radio}><input type="radio" /><div></div>Option 3</div>
      </div>
    );
  }),
  checkbox: React.memo(({ field }) => {
    return (
      <div className={styles.fieldPreview}>
        <div className={fieldsStyles.label}>{field.label}</div>
        <div className={fieldsStyles.checkbox}><input type="checkbox" /><div></div>Option 1</div>
        <div className={fieldsStyles.checkbox}><input type="checkbox" /><div></div>Option 2</div>
        <div className={fieldsStyles.checkbox}><input type="checkbox" /><div></div>Option 3</div>
      </div>
    );
  }),
  divider: React.memo(({ field }) => {
    return (
      <div className={styles.fieldPreview}>
        <div className={fieldsStyles.divider} />
      </div>
    );
  }),
  section: React.memo(({ field }) => {
    return (
      <div className={styles.fieldPreview}>
        <div className={styles.section}>
          <div className={styles.label}>
            <div className={styles.text}>
              <span>{field.label} - {field.columns || 1} column{(field.columns || 1) === 1 ? '' : 's'}</span>
            </div>
          </div>
        </div>
      </div>
    );
  }),
  title: React.memo(({ field }) => {
    return (
      <div className={styles.fieldPreview}>
        <div className={fieldsStyles.title}>{field.type === 'title' ? field.label : ''}</div>
      </div>
    );
  }),
  description: React.memo(({ field }) => {
    return (
      <div className={styles.fieldPreview}>
        <div className={fieldsStyles.descriptionField} dangerouslySetInnerHTML={{ __html: field.value }}></div>
      </div>
    );
  }),
  datetime: React.memo(({ field }) => {
    return (
      <div className={styles.fieldPreview}>
        <div className={fieldsStyles.label}>{field.label}</div>
        <div className={fieldsStyles.datetimeInput}><CalendarIcon className={fieldsStyles.datetimeInputIcon} /></div>
      </div>
    );
  }),
  fileUpload: React.memo(({ field }) => {
    return (
      <div className={styles.fieldPreview}>
        <div className={fieldsStyles.label}>{field.label}</div>
        <div className={fieldsStyles.fileUploadArea}>
          <UploadIcon />

          <p>Drop your file here</p>
          <p>or</p>
          <span>click to upload</span>
        </div>
      </div>
    );
  }),
  signature: React.memo(({ field }) => {
    return (
      <div className={styles.fieldPreview}>
        <div className={fieldsStyles.label}>{field.label}</div>
        <div className={fieldsStyles.signature}>
          <ul className={fieldsStyles.signatureHeader}>
            <li className={fieldsStyles.active}>
              <PenIcon /><div>DRAW</div>
            </li>
            <li>
              <KeyboardIcon /><div>TYPE</div>
            </li>
          </ul>

          <div className={fieldsStyles.signatureDrawCanvas}></div>

          <div className={fieldsStyles.signatureFooter}>
            <div className={fieldsStyles.signatureClear}><EraserIcon />Clear Signature</div>
          </div>
        </div>
      </div>
    );
  }),
  pageBreak: React.memo(({ field, fields, form, selectedEmptyBox }) => {
    const firstPageBreak = fields.sort((a, b) => a.position - b.position).find((f) => f.type === 'pageBreak') || null;
    const isFirst = firstPageBreak === null ? true : firstPageBreak.position >= selectedEmptyBox - 1;

    return (
      <>
        <div className={[
          styles.fieldPreview,
          styles.pageBreakBox,
          !isFirst ? styles.withBackButton : ''
        ].join(' ')}>
          {!isFirst && <button className={styles.submitButton}>Return</button>}
          <button className={styles.submitButton}>Next Page</button>
        </div>

        <div className={[
          styles.pageBreakHr
        ].join(' ')}></div>
        <div className={[
          styles.pageBreakText
        ].join(' ')}><span>PAGE BREAK</span></div>
      </>
    );
  }),
  image: React.memo(() => {
    return (
      <div className={[styles.fieldPreview, styles.imageBox].join(' ')}>
        <ImageEmptyIcon />
        <span>No image uploaded yet</span>
      </div>
    );
  }),
  scale: React.memo(({ field }) => {
    return (
      <div className={styles.fieldPreview}>
        <div className={fieldsStyles.label}>{field.label}</div>
        <div className={fieldsStyles.scale}>
          <ul className={fieldsStyles.scaleGrid}>
            <li style={{ width: '10%' }}>0</li>
            <li style={{ width: '10%' }}>1</li>
            <li style={{ width: '10%' }}>2</li>
            <li style={{ width: '10%' }}>3</li>
            <li style={{ width: '10%' }}>4</li>
            <li style={{ width: '10%' }}>5</li>
            <li style={{ width: '10%' }}>6</li>
            <li style={{ width: '10%' }}>7</li>
            <li style={{ width: '10%' }}>8</li>
            <li style={{ width: '10%' }}>9</li>
            <li style={{ width: '10%' }}>10</li>
          </ul>

          <ul className={fieldsStyles.scaleInfo}>
            <li style={{ width: '33%', textAlign: 'left' }}>Least Likely</li>
            <li style={{ width: '33%', textAlign: 'center' }}></li>
            <li style={{ width: '33%', textAlign: 'right' }}>Most Likely</li>
          </ul>
        </div>
      </div>
    );
  }),
  imageChoice: React.memo(({ field }) => {
    return (
      <div className={styles.fieldPreview}>
        <div className={fieldsStyles.label}>{field.label}</div>
        <div className={fieldsStyles.imageChoice}>
          <ImageEmptyIcon />
        </div>
      </div>
    );
  })
};

class EmptyBox extends Component {
  componentDidMount = () => {
    if (this.props.selectedEmptyBox === null) {
      this.props.updateActiveButtonHover(this.props.fields.length + 1);
    }
  }

  componentWillUnmount = () => {
    clearTimeout(timeout);
  }

  onMouseLeaveEmptyBoxButton = (selected) => {
    clearTimeout(timeout);
    timeout = null;

    if (selected) return;

    timeout = setTimeout(() => {
      this.props.updateActiveButtonHover(this.props.fields.length + 1);
    }, 500);
  }

  select = (e, index) => {
    const buttonPosition = e.target.getBoundingClientRect();
    const content = document.getElementById('builderBuildContent');

    if (this.props.selectedEmptyBox === index) { // close
      this.props.updateSelectedEmptyBox(null);
      this.props.updateSelectedFieldOption(null);
      this.props.updateFirstHoverOnFieldOption(true);
    } else {
      this.props.updateSelectedEmptyBox(index);
      this.props.updateFieldsListPosition({
        top: content.scrollTop + buttonPosition.top - 25,
        left: (content.offsetWidth - 500) / 2 - 260
      });
    }
  }

  render() {
    const {
      index,
      selectedEmptyBox, selectedFieldOption, emptyBoxAnimation, draggedFieldRef, fields, form
    } = this.props;

    return (
      <div className={[
        styles.emptyBox,
        selectedEmptyBox === index ? styles.selected : '',
        selectedEmptyBox >= index ? styles.before : styles.after,
        emptyBoxAnimation ? styles.animation : '',
        draggedFieldRef ? styles.draggingActive : ''
      ].join(' ')}>
        <div className={styles.emptyBoxContent} style={{
          height: selectedFieldOption ? selectedFieldOption.height : (selectedEmptyBox ? 80 : 0)
        }}>
          {!selectedFieldOption && <div className={styles.fieldOptionPlaceholder}>Select a form field from the field panel</div>}
          {selectedFieldOption && <>
            {selectedFieldOption.type === 'shortText' && <fieldPreviews.shortText field={selectedFieldOption} />}
            {selectedFieldOption.type === 'longText' && <fieldPreviews.longText field={selectedFieldOption} />}
            {selectedFieldOption.type === 'dropdown' && <fieldPreviews.dropdown field={selectedFieldOption} />}
            {selectedFieldOption.type === 'radio' && <fieldPreviews.radio field={selectedFieldOption} />}
            {selectedFieldOption.type === 'checkbox' && <fieldPreviews.checkbox field={selectedFieldOption} />}
            {selectedFieldOption.type === 'title' && <fieldPreviews.title field={selectedFieldOption} />}
            {selectedFieldOption.type === 'description' && <fieldPreviews.description field={selectedFieldOption} />}
            {selectedFieldOption.type === 'datetime' && <fieldPreviews.datetime field={selectedFieldOption} />}
            {selectedFieldOption.type === 'fileUpload' && <fieldPreviews.fileUpload field={selectedFieldOption} />}
            {selectedFieldOption.type === 'signature' && <fieldPreviews.signature field={selectedFieldOption} />}
            {selectedFieldOption.type === 'image' && <fieldPreviews.image />}
            {selectedFieldOption.type === 'scale' && <fieldPreviews.scale field={selectedFieldOption} />}
            {selectedFieldOption.type === 'divider' && <fieldPreviews.divider field={selectedFieldOption} />}
            {selectedFieldOption.type === 'imageChoice' && <fieldPreviews.imageChoice field={selectedFieldOption} />}
            {selectedFieldOption.type === 'section' && <fieldPreviews.section field={selectedFieldOption} />}
            {selectedFieldOption.type === 'pageBreak' && <fieldPreviews.pageBreak field={selectedFieldOption} fields={fields} form={form} selectedEmptyBox={selectedEmptyBox} />}
          </>}
        </div>
        <div className={[
          styles.emptyBoxButton,
          selectedEmptyBox !== null ? styles.active : '',
          selectedEmptyBox === index ? styles.selected : '',
          fields.length === 0 ? styles.emptyForm : ''
        ].join(' ')}
          onClick={(e) => this.select(e, index)}>
          <span className="emptyBoxButtonSpan" />
        </div>
      </div>
    );
  }
};

const mapStateToProps = (state) => {
  return {
    fields: state.builder.fields,
    form: state.builder.form,
    selectedEmptyBox: state.builder.selectedEmptyBox,
    selectedFieldOption: state.builder.selectedFieldOption,
    emptyBoxAnimation: state.builder.emptyBoxAnimation,
    draggedFieldRef: state.builder.draggedFieldRef
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateSelectedEmptyBox: (value) => dispatch(updateSelectedEmptyBox(value)),
    updateSelectedFieldOption: (value) => dispatch(updateSelectedFieldOption(value)),
    updateFirstHoverOnFieldOption: (value) => dispatch(updateFirstHoverOnFieldOption(value)),
    updateFieldsListPosition: (value) => dispatch(updateFieldsListPosition(value)),
    updateActiveButtonHover: (value) => dispatch(updateActiveButtonHover(value))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EmptyBox);
