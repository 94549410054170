/** @jsx jsx */
/* @jsxFrag React.Fragment */

import { jsx, css } from '@emotion/core';
import React from 'react';

export const LabelAtom = ({ children, theme, required, error }) => {
  const mainStyle = css({
    margin: `0 0 ${theme.generalFieldsSettingsLabelMargin}px 0`
  });

  const contentStyle = css({

  });

  const labelStyle = css({
    textAlign: 'left',
    fontSize: `${theme.generalFieldsSettingsTextIdleLabelFontSize}px`,
    lineHeight: '142%',
    fontWeight: theme.generalFieldsSettingsTextIdleLabelWeight,
    color: theme.generalFieldsSettingsTextIdleLabelColor,
    boxSizing: 'border-box',
    fontFamily: theme.typographyLabelFontFamily,
    wordBreak: 'break-word',
    position: 'relative',
    '& a': {
      color: theme.generalFieldsSettingsTextIdleLabelColor,
      textDecoration: 'underline'
    },
    'p:first-of-type': {
      marginTop: 0
    },
    'p:last-of-type': {
      marginBottom: 0
    }
  });

  const requiredStyle = css({
    color: theme.generalFieldsSettingsRequiredColor,
    fontSize: `${theme.generalFieldsSettingsTextIdleLabelFontSize}px`,
    fontWeight: 400,
    margin: '0 0 0 2px',
    lineHeight: '142%',
    position: 'absolute',
    left: '-0.8em',
    top: '0'
  });

  return <>
    {children && <div css={mainStyle}>
      <div css={contentStyle}>
        <div css={labelStyle}>
          {children}
          {required && <span css={requiredStyle}>{theme.generalFieldsSettingsRequiredIcon}</span>}</div>
      </div>
    </div>}
  </>;
};

export const DescriptionAtom = ({ children, theme }) => {
  const mainStyle = css({
    margin: `${theme.generalFieldsSettingsSupportingTextMargin}px 0 0 0`
  });

  const contentStyle = css({

  });

  const descriptionStyle = css({
    textAlign: 'left',
    fontSize: `${theme.generalFieldsSettingsTextIdleSupportingFontSize}px`,
    lineHeight: '142%',
    fontWeight: theme.generalFieldsSettingsTextIdleSupportingWeight,
    color: theme.generalFieldsSettingsTextIdleSupportingColor,
    boxSizing: 'border-box',
    fontFamily: theme.typographySupportingFontFamily,
    wordBreak: 'break-word',
    '& a': {
      textDecoration: 'underline'
    },
    'p:first-of-type': {
      marginTop: 0
    },
    'p:last-of-type': {
      marginBottom: 0
    }
  });

  return <>
    {children && <div css={mainStyle}>
      <div css={contentStyle}>
        <div css={descriptionStyle}>{children}</div>
      </div>
    </div>}
  </>;
};

export const LoaderAtom = ({ size }) => {
  const spinnerStyle = css({
    '@keyframes rotate': {
      '100%': {
        transform: 'rotate(360deg)'
      }
    },
    height: 0,
    width: 0,
    padding: size || 8,
    border: '1.5px solid #BFBFBF',
    borderRightColor: '#162D5A',
    borderRadius: '100%',
    animation: 'rotate 1.5s infinite linear'
  });

  return (
    <div css={spinnerStyle}></div>
  );
};
