import styles from './Edit.module.css';
import editStyles from 'components/Builder/Edit.module.css';

import React, { Component } from 'react';
import { connect } from 'react-redux';
import OutsideClickHandler from 'react-outside-click-handler';
import getFieldLabel from 'helpers/getFieldLabel.js';
import _range from 'lodash/range';
import _debounce from 'lodash.debounce';
import ObjectID from 'bson-objectid';
import ReactTooltip from 'react-tooltip';

import { MentionsInput, Select2, TextInput } from 'ui';

import { ReactComponent as CloseIcon } from 'assets/images/close.svg';
import { ReactComponent as RemoveIcon } from 'assets/images/trash.svg';
import { ReactComponent as ShareIcon } from 'assets/images/menu-publish.svg';
import { ReactComponent as MathIcon } from 'assets/images/math.svg';

import {
  updateEditedFieldRef, updateField, ioUpdateField, ioUpdateForm, updateForm
} from 'store/ducks/builder.js';

const actionOptions = [
  { label: 'Add', value: '+' },
  { label: 'Subtract', value: '-' },
  { label: 'Multiply', value: '*' },
  { label: 'Divide', value: '/' }
];

const actions = {
  '+': 'add',
  '-': 'subtract',
  '*': 'multiply',
  '/': 'divide'
};

class Edit extends Component {
  close = () => {
    this.props.updateEditedFieldRef(null);
  }

  handleOutsideClick = (e) => {
    try {
      if (e.target.getAttribute('data-not-outside') !== 'true') {
        this.close();
      }
    } catch (e) { }
  }

  ioUpdateForm = _debounce((params) => {
    this.props.ioUpdateForm(params);
  }, 500);

  // Variables
  addEmptyCalculationVariables = (calculationVariables = []) => {
    const id = ObjectID().toHexString();

    const props = {
      calculationVariables: [...calculationVariables, { _id: id, name: `Variable #${calculationVariables.length + 1}`, initialValue: 0, modifier: `[{"type":"paragraph","children":[{"text":""},{"type":"mention","_id":"${id}","origin":"calculations","children":[{"text":""}]},{"text":""}]}]` }],
    };

    this.props.ioUpdateForm(props);
    this.props.updateForm(props);
  }

  updateVariable = (id, props, calculationVariables = []) => {
    const fieldIndex = calculationVariables.findIndex((calculation) => calculation._id === id);

    calculationVariables[fieldIndex] = { ...calculationVariables[fieldIndex], ...props };

    this.ioUpdateForm({ calculationVariables });
    this.props.updateForm({ calculationVariables });
  }

  removeVariable = (id, calculationVariables = []) => {
    const variables = [...calculationVariables];
    const upadtedCalculationVariables = variables.filter((variable) => String(variable._id) !== String(id));

    this.ioUpdateForm({ calculationVariables: upadtedCalculationVariables });
    this.props.updateForm({ calculationVariables: upadtedCalculationVariables });
  }

  // Calculations
  addEmptyCalculation = (calculations) => {
    const props = {
      calculations: [...calculations, { _id: ObjectID().toHexString(), option: null, action: '+', target: null, value: 0 }],
    };

    this.props.ioUpdateField(this.props.editedFieldRef, props);
    this.props.updateField(this.props.editedFieldRef, props);
  }

  removeCalculation = (id, calculations) => {
    const upadtedCalculations = calculations.filter((calculation) => String(calculation._id) !== String(id));

    this.props.ioUpdateField(this.props.editedFieldRef, { calculations: upadtedCalculations });
    this.props.updateField(this.props.editedFieldRef, { calculations: upadtedCalculations });
  }

  updateCalculation = (id, props, calculations) => {
    const fieldIndex = calculations.findIndex((calculation) => String(calculation._id) === String(id));

    if (fieldIndex === -1) return;

    calculations[fieldIndex] = { ...calculations[fieldIndex], ...props };

    this.props.ioUpdateField(this.props.editedFieldRef, { calculations });
    this.props.updateField(this.props.editedFieldRef, { calculations });
  }

  fieldValueLabel = (option, field) => {
    if (!option) return '- Select -';
    if (field.type !== 'imageChoice') return option.value;

    const parsedValue = option.value ? JSON.parse(option.value) : {};

    return <div style={{
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      width: '100%',
      height: '100%'
    }}>
      <div style={{
        width: 22,
        height: 22,
        marginRight: 10,
        borderRadius: 2,
        boxSizing: 'border-box',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}>
        <img src={parsedValue.url} width="20px" alt="" />
      </div>
      <div style={{
        width: 'calc(100% - 48px)',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap'
      }}>{parsedValue.text}</div>
    </div>;
  };

  render() {
    const {
      editedFieldRef, fields, values, form, calculationVariables
    } = this.props;

    const field = fields.find((field) => field.ref === editedFieldRef) || {};
    let allowed = ['radio', 'checkbox', 'dropdown', 'imageChoice', 'scale', 'shortText'].indexOf(field.type) !== -1;
    let isNumberField = field.type === 'shortText' && field.format === 'number';
    let mentionsData = [];

    if (field.type === 'shortText' && field.format !== 'number') allowed = false;

    let fieldOptions = field.options;

    if (field.type === 'scale') {
      fieldOptions = _range(field.scaleRange[0], field.scaleRange[1] + 1).map((option) => {
        return {
          ref: option,
          value: option
        };
      });
    }

    if (calculationVariables && calculationVariables.length !== 0) mentionsData = calculationVariables.map((variable) => ({
      _id: variable._id,
      type: 'variable',
      format: null,
      label: variable.name
    }));

    return (
      <OutsideClickHandler onOutsideClick={(e) => this.handleOutsideClick(e)}>
        {!editedFieldRef && <div className={styles.main}>
          <div className={styles.scrollArea}>
            <div className={styles.content}>
              <div className={styles.emptyTitle}>Calculation Editor</div>

              <div className={styles.emptyHead}>Select a field to start</div>
              <div className={styles.emptyDesc}>Select one of your fields to the right to enter the calculation configuration for that specific field.</div>

              <div className={styles.emptyHead} style={{ margin: '40px 0 15px 0' }}>Calculator Variables</div>
              <div className={styles.emptyDesc} style={{ margin: '0 0 15px 0' }}>These variables are the end result of the calculation, such as score or price.</div>

              {form && form.calculationVariables && form.calculationVariables.map((variable, index) => {
                return <React.Fragment key={variable._id}>
                  <div key={variable._id} className={styles.variable}>
                    <div className={styles.variableTop}>
                      <TextInput width={270} placeholder="Variable name" value={variable.name} onChange={(e) => this.updateVariable(variable._id, { name: e.target.value }, form.calculationVariables)} />
                      <RemoveIcon onClick={() => this.removeVariable(variable._id, form.calculationVariables)} />
                    </div>
                    <div className={styles.variableBottom}>
                      <span>Initial value:</span>
                      <TextInput type="number" step="0.01" value={variable.initialValue} onChange={(e) => this.updateVariable(variable._id, { initialValue: e.target.value }, form.calculationVariables)} />
                    </div>
                    <div className={styles.modifier}>
                      <span>Modifier:</span>
                      <div>
                        <MentionsInput menu={false}
                          initialValue={variable.modifier}
                          onChange={(value) => this.updateVariable(variable._id, { modifier: value }, form.calculationVariables)}
                          data={mentionsData} />
                        <a href="https://support.questionscout.com/en/articles/4356922-mathematical-expressions" target="_blank" rel="noopener noreferrer"><MathIcon data-tip="Mathematical expressions and variables refers are available on this input, click to learn more." /></a>
                      </div>

                      {(variable.modifier || '').indexOf(variable._id) === -1 && <span className={styles.error}>You must have the @{variable.name} variable within this modifier.</span>}
                    </div>
                  </div>

                  {index !== (form.calculationVariables.length - 1) && <div className={styles.variableSeparator} />}
                </React.Fragment>;
              })}

              <div className={styles.addCalculation} onClick={() => this.addEmptyCalculationVariables(form.calculationVariables)}>Add variable</div>
            </div>
          </div>
        </div>}

        {editedFieldRef && <div className={styles.main}>
          <div className={styles.header}>
            <div className={styles.headerText}>
              <div>Applying {field.type === 'pageBreak' ? 'skip ' : ''}calculations to:</div>
              {field && <span>
                {getFieldLabel(field.label || field.placeholder, values)}
              </span>}
            </div>

            <CloseIcon onClick={() => this.close()} />
          </div>

          <div className={styles.scrollArea}>

            <div className={styles.content}>
              {!allowed && <>
                <div className={styles.placeholder}>
                  <span>Adding calculations is only possible on our Single Choice, Multiple Choice, Image Choice, Scale, Dropdown and Number fields.</span>
                </div>
              </>}
              {field && allowed && field.calculations.length === 0 && <>
                <div className={styles.placeholder}>
                  <span>Use calculations on this field to calculate a score, a price or anything else you'd like!</span>
                  <div className={styles.addCalculation} onClick={() => this.addEmptyCalculation(field.calculations)}>Add a calculation to this field</div>
                  <a className={styles.learnMore} href="https://support.questionscout.com/en/articles/4345599-using-calculations" target="_blank" rel="noopener noreferrer" >Learn more about calculations<ShareIcon /></a>
                </div>
              </>}

              {field && allowed && field.calculations.length > 0 && <>
                {field && field.calculations.map((calculation, index) => {
                  return <div key={index}>
                    <div className={styles.calculation}>
                      <div className={styles.calculationHeader}>
                        <span>Calculation rule #{index + 1}</span><RemoveIcon onClick={() => this.removeCalculation(calculation._id, field.calculations)} />
                      </div>
                      <div className={styles.calculationContent}>
                        {isNumberField && <>

                          {['+', '-'].indexOf(calculation.action) !== -1 && <>
                            <div className={styles.row}>
                              <div style={{ width: '118px' }}>
                                <Select2 options={actionOptions} value={{ label: !calculation.action ? '+' : actionOptions.find((opt) => opt.value === calculation.action).label, value: calculation.action }} onChange={(selected) => this.updateCalculation(calculation._id, {
                                  action: selected.value
                                }, field.calculations)} />
                              </div>
                              <span style={{ margin: '0 0 0 8px' }}>the value of this field</span>
                            </div>
                            <div className={styles.row}>
                              <span>to the</span>
                              <div style={{ width: '216px' }}>
                                <Select2 filter={true} options={form.calculationVariables.map((option) => {
                                  return { label: option.name, value: option._id };
                                })} value={{ label: !calculation.target ? '- Select -' : (form.calculationVariables.find((f) => String(f._id) === String(calculation.target)) || { name: '- Select -' }).name, value: calculation.target }} onChange={(selected) => this.updateCalculation(calculation._id, {
                                  target: selected.value
                                }, field.calculations)} />
                              </div>
                            </div>
                          </>}

                          {['*', '/'].indexOf(calculation.action) !== -1 && <>
                            <div className={styles.row}>
                              <div style={{ width: '100px' }}>
                                <Select2 options={actionOptions} value={{ label: !calculation.action ? '+' : actionOptions.find((opt) => opt.value === calculation.action).label, value: calculation.action }} onChange={(selected) => this.updateCalculation(calculation._id, {
                                  action: selected.value
                                }, field.calculations)} />
                              </div>
                              <span style={{ margin: '0 8px' }}>the</span>
                              <div style={{ width: '126px' }}>
                                <Select2 filter={true} options={form.calculationVariables.map((option) => {
                                  return { label: option.name, value: option._id };
                                })} value={{ label: !calculation.target ? '- Select -' : (form.calculationVariables.find((f) => String(f._id) === String(calculation.target)) || { name: '- Select -' }).name, value: calculation.target }} onChange={(selected) => this.updateCalculation(calculation._id, {
                                  target: selected.value
                                }, field.calculations)} />
                              </div>
                            </div>
                            <div className={styles.row}>
                              <span style={{ margin: '0' }}>by the value of this field.</span>
                            </div>
                          </>}

                          {!isNumberField && <span>Start by adding a Single Choice, Multiple Choice, Image Choice, Scale, Number or a Select List to enable calculations.</span>}
                        </>}

                        {fieldOptions.length > 0 && <>
                          <div className={styles.row}>
                            <span>If the answer is</span>
                            <div className={styles.option}>
                              <Select2 filter={true} options={fieldOptions.map((option) => {
                                return { label: this.fieldValueLabel(option, field), value: option.ref };
                              })} value={{ label: !calculation.option ? '- Select -' : this.fieldValueLabel(fieldOptions.find((f) => String(f.ref) === String(calculation.option)), field), value: calculation.option }} onChange={(selected) => this.updateCalculation(calculation._id, {
                                option: selected.value
                              }, field.calculations)} />
                            </div>
                          </div>

                          <div className={styles.row}>
                            <span>then</span>
                            <div className={[styles.action, styles[actions[calculation.action]]].join(' ')}>
                              <Select2 options={actionOptions} value={{ label: !calculation.action ? '+' : actionOptions.find((opt) => opt.value === calculation.action).label, value: calculation.action }} onChange={(selected) => this.updateCalculation(calculation._id, {
                                action: selected.value
                              }, field.calculations)} />
                            </div>
                            {['+', '-'].indexOf(calculation.action) !== -1 && <div className={styles.value}>
                              <TextInput type="number" step="0.01" value={calculation.value} onChange={(e) => this.updateCalculation(calculation._id, {
                                value: e.target.value
                              }, field.calculations)} />
                            </div>}
                          </div>

                          <div className={styles.row}>
                            {calculation.action === '+' && <span>to the</span>}
                            {calculation.action === '-' && <span>from the</span>}
                            {calculation.action === '*' && <span>the</span>}
                            {calculation.action === '/' && <span>the</span>}

                            <div className={[styles.target, styles[actions[calculation.action]]].join(' ')}>
                              <Select2 filter={true} options={form.calculationVariables.map((option) => {
                                return { label: option.name, value: option._id };
                              })} value={{ label: !calculation.target ? '- Select -' : (form.calculationVariables.find((f) => String(f._id) === String(calculation.target)) || { name: '- Select -' }).name, value: calculation.target }} onChange={(selected) => this.updateCalculation(calculation._id, {
                                target: selected.value
                              }, field.calculations)} />
                            </div>

                            {calculation.action === '*' && <span>by</span>}
                            {calculation.action === '/' && <span>by</span>}

                            {['*', '/'].indexOf(calculation.action) !== -1 && <div className={styles.value}>
                              <TextInput type="number" step="0.01" value={calculation.value} onChange={(e) => this.updateCalculation(calculation._id, {
                                value: e.target.value
                              }, field.calculations)} />
                            </div>}
                          </div>

                          {fieldOptions.length === 0 && <span>Start by adding a Single Choice, Multiple Choice, Image Choice, Scale, Number or a Select List to enable calculations.</span>}
                        </>}
                      </div>
                    </div>

                    {index !== (field.calculations.length - 1) && <div className={styles.calculationSeparator} />}
                  </div>;
                })}

                <div className={styles.addCalculation} onClick={() => this.addEmptyCalculation(field.calculations)}>Add additional calculation rule</div>
              </>}
            </div>
          </div>
        </div>}

        <ReactTooltip place="right" effect="solid" />
      </OutsideClickHandler>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    editedFieldRef: state.builder.editedFieldRef,
    form: state.builder.form,
    fields: state.builder.fields,
    values: state.builder.values,
    columnOptions: state.results.columnOptions,
    calculationVariables: state.builder.form.calculationVariables
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateEditedFieldRef: (value) => dispatch(updateEditedFieldRef(value)),
    updateField: (ref, params) => dispatch(updateField(ref, params)),
    ioUpdateField: (ref, params) => dispatch(ioUpdateField(ref, params)),
    ioUpdateForm: (params) => dispatch(ioUpdateForm(params)),
    updateForm: (params) => dispatch(updateForm(params))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Edit);
