import styles from './Delete.module.css';

import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
  deleteSelectedSubmissions, hideDeleteModal
} from 'store/ducks/results.js';

import { Button, Modal } from 'ui';

class DeleteModal extends Component {
  cancel = () => {
    this.props.hideDeleteModal();
  }

  delete = () => {
    this.props.deleteSelectedSubmissions().then(this.cancel)
  }

  render() {
    const {
      show,
    } = this.props;

    return (
      <>
        <Modal isOpen={show}onRequestClose={this.cancel} width={330} header="Deleting Replies" footer={<>
          <Button size="small" theme="white" onClick={this.cancel} margin="0 15px 0 0">Cancel</Button>
          <Button size="small" theme="red" onClick={this.delete}>Yes, delete replies</Button>
        </>}>
          <div className={styles.content}>
            Are you sure you want to delete these replies? Once you’ve deleted them, they cannot be recovered!
          </div>
        </Modal>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    show: state.results.showDeleteModal
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    deleteSelectedSubmissions: () => dispatch(deleteSelectedSubmissions()),
    hideDeleteModal: () => dispatch(hideDeleteModal())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DeleteModal);
