import styles from './OtpRemoveModal.module.css';

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactCodeInput from 'react-verification-code-input';

import { hideOtpRemoveModal } from 'store/ducks/user.js';
import { getUser, disableOtp } from 'store/ducks/_users.js';

import { Button, Modal, Loader } from 'ui';

const OtpRemoveModal = () => {
  const dispatch = useDispatch();

  const show = useSelector(state => state.user.showOtpRemoveModal);

  const [code, setCode] = useState('');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const cancel = () => {
    dispatch(hideOtpRemoveModal());
    setError(null);
    setLoading(false);
  };

  const save = async (value) => {
    setError(null);
    setLoading(true);

    try {
      const res = await dispatch(disableOtp(value));
      await dispatch(getUser());

      if (res.success) cancel();
    } catch (e) {
      if (e.message) setError(e.message);
      setLoading(false);
    }
  };

  return <>
    <Modal isOpen={show} onRequestClose={cancel} width={450} header="Disabling Two-Factor Authentication" footer={<>
      <Button size="small" theme="white" onClick={cancel}>Cancel</Button>
      <Button size="small" theme="blue" onClick={() => save(code)} margin="0 0 0 15px" disabled={loading || code.length !== 6}>Disable</Button>
    </>}>
      <div className={styles.content}>
        {loading && <Loader size={40} />}
        {!loading && <>
          <div className={styles.digitsTitle}>Enter your authentication code to confirm</div>
          <ReactCodeInput type="number" onChange={(value) => setCode(value)} onComplete={(c) => save(c)} fields={6} placeholder={['0', '0', '0', '0', '0', '0']} className={styles.digits} fieldWidth={25} fieldHeight={40} />
          {error && <div className={styles.error}>{error}</div>}
        </>}
      </div>
    </Modal>
  </>;
}

export default OtpRemoveModal;
