import styles from './Card.module.css';

import React from 'react';

import { Toggle } from 'ui';

export const Card = ({ children, className, disabled, style }) => {
  return (
    <div className={[
      styles.card,
      className
    ].join(' ')} style={style}>
      {children}
      {disabled && <div className={styles.disabled} />}
    </div>
  );
};

export const CardRow = ({ children, className, disabled, style }) => {
  return (
    <div className={[styles.row, className].join(' ')} style={style}>
      {children}
      {disabled && <div className={styles.disabled} />}
    </div>
  );
};

export const CardRowLabel = ({ className, title, subTitle, style, showToggle, disableToggle, toggleValue, onClick }) => {
  return (
    <div className={styles.label} style={style}>
      <div className={[styles.title, showToggle ? styles.withToggle : ''].join(' ')} onClick={onClick}>
        {showToggle && <Toggle checked={toggleValue} disabled={disableToggle || false} readOnly={true} onChange={() => { }} />}
        <span>{title}</span>
      </div>
      <div className={styles.subTitle}>{subTitle}</div>
    </div>
  );
};

export const CardRowContent = ({ children, className, style }) => {
  return (
    <div className={styles.content} style={style}>{children}</div>
  );
};
