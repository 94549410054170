import styles from './Integrations.module.css';

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { verifyPermission } from 'misc/helpers.js';

import {
  updateApiKeys
} from 'store/ducks/user.js';

import { ReactComponent as CopyIcon } from 'assets/images/copy.svg';

import { Button, Card, CardRow, CardRowLabel, CardRowContent } from 'ui';

class Profile extends Component {
  render() {
    const {
      user
    } = this.props;

    return (
      <Card className={styles.card}>
        <CardRow>
          <CardRowLabel title="Your API Key" subTitle="Your API key to authenticate other web services, such as Zapier." />

          <CardRowContent>
            <div style={{ margin: '0 0 20px 0' }}>
              <label>API key</label>
              <div className={styles.copy}>
                <input type="text" value={user.apiKey || ''} ref="apiKey" onChange={() => { }} onFocus={(e) => e.target.select()} />
                <CopyToClipboard text={user.apiKey || ''} >
                  <div><CopyIcon />Copy</div>
                </CopyToClipboard>
              </div>
            </div>

            {verifyPermission('generateApiKey', user) && <Button theme="white" onClick={() => this.props.updateApiKeys()}>Generate new API key</Button>}
          </CardRowContent>
        </CardRow>
      </Card>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    user: state._users.user
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateApiKeys: () => dispatch(updateApiKeys())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
