/** @jsx jsx */
/* @jsxFrag React.Fragment */

import { jsx, css } from '@emotion/core';
import React from 'react';
import Translate from '../messages/Translate.js';

import { ReactComponent as LinkArrowIcon } from 'assets/images/link-arrow.svg';
import { ReactComponent as PausedIcon } from 'assets/images/paused.svg';

const Paused = ({ messages, hideBranding }) => {
  const translate = new Translate(messages);

  const styles = {
    paused: css({
      maxWidth: '410px',
      width: '100%',
      margin: '50px auto 0 auto',
      boxSizing: 'border-box',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'relative'
    }),
    pausedMessage: css({
      backgroundColor: '#FFFFFF',
      borderRadius: '16px',
      boxShadow: '0 25px 50px 0 rgba(0, 0, 0, 0.1)',
      width: '100%',
      padding: '30px 20px',
      boxSizing: 'border-box',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      'div': {
        color: '#202020',
        fontFamily: 'Roboto',
        fontSize: '24px',
        fontWeight: '700',
        lineHeight: '29px',
        textAlign: 'center',
        margin: '15px 0 0 0',
      },
      'span': {
        color: '#202020',
        fontFamily: 'Roboto',
        fontSize: '15px',
        fontWeight: '400',
        lineHeight: '18px',
        textAlign: 'center',
        display: 'block',
        marginTop: '25px',
        width: '100%',
      }
    }),
    submittedLink: css({
      backgroundColor: '#FFFFFF',
      borderRadius: '16px',
      boxShadow: '0 25px 50px 0 rgba(0, 0, 0, 0.1)',
      width: '100%',
      padding: '15px 20px',
      boxSizing: 'border-box',
      marginTop: '20px',
      'span': {
        color: '#949494',
        fontFamily: 'Roboto',
        fontSize: '14px',
        fontWeight: '400',
        lineHeight: '17px',
        textAlign: 'center',
        display: 'block'
      },
      'a': {
        display: 'block',
        color: '#0064F6',
        fontFamily: 'Roboto',
        fontSize: '13px',
        fontWeight: '500',
        lineHeight: '16px',
        textAlign: 'center',
        marginTop: '5px',
        '-webkit-tap-highlight-color': 'rgba(0, 0, 0, 0)',
        '-webkit-tap-highlight-color': 'transparent'
      },
      'a svg': {
        marginLeft: '2px'
      }
    })
  };

  return <section css={styles.paused} className="qs_paused">
    <div css={styles.pausedMessage}>
      <PausedIcon />
      <div>{translate.text('pausedTitle')}</div>
      <span>{translate.text('pausedDescription')}</span>
    </div>

    {!hideBranding && <div css={styles.submittedLink}>
      <span>This form was built for free using QuestionScout</span>
      <a href="https://questionscout.com?ref=questionscout-form" target="_blank" rel="noopener noreferrer">Start building your own form now <LinkArrowIcon /></a>
    </div>}
  </section>;
};

export default Paused;
