import React from 'react';
import { useSelector } from 'react-redux';
import getFieldLabel from 'helpers/getFieldLabel.js';
import _range from 'lodash/range';

const typeOptions = {
  equal: 'is',
  notequal: 'is not'
};

const calculationTypeOptions = {
  '=': 'equal to',
  '!=':'not equal to',
  '<': 'less than',
  '>': 'more than',
  '>=': 'more or equal to',
  '<=': 'less or equal to'
};

const fieldValueLabel = (option, field) => {
  if (field.type !== 'imageChoice') return option ? option.value : '';

  const parsedValue = (option && option.value) ? JSON.parse(option.value) : {};

  return <div style={{
    display: 'inline-flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexWrap: 'nowrap',
    lineHeight: '18px',
    height: '18px'
  }}>
    <div style={{
      width: '18px',
      height: '18px',
      marginRight: 5,
      borderRadius: 2,
      boxSizing: 'border-box',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }}>
      <img style={{
        maxWidth: '18px',
        maxHeight: '18px'
      }} src={parsedValue.url} alt="" />
    </div>
    <div style={{
      lineHeight: '18px',
      whiteSpace: 'nowrap',
      height: '18px'
    }}>{parsedValue.text}</div>
  </div>;
};

const LogicSummary = ({ conditionsType, fieldConditions = [], calculationConditions = [], fields, values, prefixText, emptyMessage }) => {
  const calculationVariables = useSelector(state => state.builder.form.calculationVariables);

  const arr = [];
  let field = null;
  let options = [];

  for (let condition of fieldConditions) {
    field = fields.find((f) => f._id === condition.triggerField);

    if (!field) continue;
    if (!condition.value) continue;

    options = field.options;

    if (field && field.type === 'scale') {
      options = _range(field.scaleRange[0], field.scaleRange[1] + 1).map((option) => {
        return {
          ref: option,
          value: option
        };
      });
    }

    arr.push(<React.Fragment key={condition._id}>
      <div style={{ fontWeight: 500, whiteSpace: 'nowrap', height: '18px' }}>{getFieldLabel(field.label || field.placeholder || field.type, values)}</div>
      <div style={{ fontWeight: 300, whiteSpace: 'nowrap' }}>{'\u00A0'}{typeOptions[condition.type]}{'\u00A0'}</div>
      <div style={{ fontWeight: 500, whiteSpace: 'nowrap', height: '18px' }}>{fieldValueLabel(options.find((f) => String(f.ref) === String(condition.value)), field)}</div>
    </React.Fragment>);
  }

  for (let condition of calculationConditions) {
    const variableAName = (calculationVariables.find((calc) => calc._id === condition.variableA) || { name: '' }).name;
    const variableBName = (calculationVariables.find((calc) => calc._id === condition.variableB) || { name: '' }).name;

    arr.push(<React.Fragment key={condition._id}>
      <div style={{ fontWeight: 500, whiteSpace: 'nowrap', height: '18px' }}>{variableAName}</div>
      <div style={{ fontWeight: 300, whiteSpace: 'nowrap' }}>{'\u00A0'}{calculationTypeOptions[condition.type]}{'\u00A0'}</div>
      {condition.compare === 'variable' && <div style={{ fontWeight: 500, whiteSpace: 'nowrap', height: '18px' }}>{variableBName}</div>}
      {condition.compare === 'number' && <div style={{ fontWeight: 500, whiteSpace: 'nowrap', height: '18px' }}>{condition.value}</div>}
    </React.Fragment>);
  }

  if (arr.length === 0) return <div>{emptyMessage || 'Logic not configured'}</div>;

  return <>
    {prefixText && <div style={{ fontWeight: 300, whiteSpace: 'nowrap' }}>{prefixText}{'\u00A0'}</div>}

    {arr.map((item, index) => {
      return <React.Fragment key={index}>
        {index !== 0 && index !== arr.length && <div>
          {'\u00A0'}{conditionsType === 'all' ? 'and' : 'or'}{'\u00A0'}
        </div>}
        {item}
      </React.Fragment>;
    })}
  </>;
}

export default LogicSummary;
