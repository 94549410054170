import styles from './AddEditDomainModal.module.css';

import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Modal, FormRow, TextInput, Loader } from 'ui';

import {
  createDomain, updateDomain
} from 'store/ducks/_domains.js';

const AddEditDomainModal = ({ onClose, domain }) => {
  const dispatch = useDispatch();

  const createDomainLoading = useSelector(state => state._domains.createDomain.loading);
  const updateDomainLoading = useSelector(state => state._domains.updateDomain.loading);

  const createDomainErrors = useSelector(state => state._domains.createDomain.errors);
  const updateDomainErrors = useSelector(state => state._domains.updateDomain.errors);

  const [hostname, setHostname] = useState(domain && domain.hostname ? domain.hostname : '');

  const isEdit = domain && domain._id;

  const handleEnterKeyPress = (e) => {
    if (createDomainLoading || updateDomainLoading || e.key !== 'Enter') return;

    submit();
  };

  const submit = () => {
    if (isEdit) {
      dispatch(updateDomain(domain._id, { hostname })).then((res) => {
        if (!res.payload || !res.payload.message) onClose();
      });
    } else {
      dispatch(createDomain({ hostname })).then((res) => {
        if (!res.payload || !res.payload.message) onClose();
      });
    }
  };

  return <>
    <Modal isOpen={true} onRequestClose={onClose} width={600} header={isEdit ? 'Editing a Custom Domain' : 'Adding a new Custom Domain'} footer={<>
      <Button size="small" theme="white" onClick={onClose} margin="0 15px 0 0">Cancel</Button>
      <Button size="small" theme="blue" onClick={submit} disabled={createDomainLoading || updateDomainLoading}>{isEdit ? 'Edit your Custom Domain' : 'Add Custom Domain'}</Button>
    </>}>
      <div className={styles.content}>
        {(createDomainLoading || updateDomainLoading) && <Loader size={30} />}

        {!createDomainLoading && !updateDomainLoading && <>
          <p>Access your forms and surveys on your own domain. You will need to <a href="https://support.questionscout.com/en/articles/4230536-setting-up-a-custom-domain" target="_blank" rel="noopener noreferrer">update your DNS</a> with the correct CNAME record pointing to the records shown underneath for this to start working.</p>
          <FormRow label="Domain Name" errorMessage={isEdit ? updateDomainErrors[0] : createDomainErrors[0]}>
            <TextInput value={hostname} autoFocus={true} width={'100%'} placeholder="form.questionscout.com" onKeyPress={handleEnterKeyPress} onChange={(e) => setHostname(e.target.value)} />
          </FormRow>

          <hr />

          <label>Have SSL?<span className={styles.badge}>Best Performance</span></label>
          <p>Point the correct CNAME record to <strong>form.questionscout.com</strong></p>

          <label>Don’t have SSL?</label>
          <p>Point the correct CNAME record to <strong>ssl.form.questionscout.com</strong>. On the first request to your form, we will automatically provision an free SSL certificate, handled by Let's Encrypt. Note that the first open could take slightly longer than normal.</p>

          <label>Not sure if you have SSL?</label>
          <p>Please <span className={styles.link} onClick={() => window.Intercom('showMessages')}>get in touch with us</span> so we can review your domain for you!</p>
        </>}
      </div>
    </Modal>
  </>;
}

export default AddEditDomainModal;
