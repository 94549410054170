import axios from 'axios';
import Storage from './../storage.js';
import config from 'config.js';

const storage = new Storage('user');

// Constants
const VALIDATE_OTP_SUCCESS = '_users/VALIDATE_OTP_SUCCESS';

const LOGIN_REQUEST = '_users/LOGIN_REQUEST';
const LOGIN_SUCCESS = '_users/LOGIN_SUCCESS';
const LOGIN_FAILURE = '_users/LOGIN_FAILURE';

const SIGNUP_REQUEST = '_users/SIGNUP_REQUEST';
const SIGNUP_SUCCESS = '_users/SIGNUP_SUCCESS';
const SIGNUP_FAILURE = '_users/SIGNUP_FAILURE';

const LOGIN_ONETOOL_REQUEST = '_users/LOGIN_ONETOOL_REQUEST';
const LOGIN_ONETOOL_SUCCESS = '_users/LOGIN_ONETOOL_SUCCESS';
const LOGIN_ONETOOL_FAILURE = '_users/LOGIN_ONETOOL_FAILURE';

const LOGIN_GOOGLE_REQUEST = '_users/LOGIN_GOOGLE_REQUEST';
const LOGIN_GOOGLE_SUCCESS = '_users/LOGIN_GOOGLE_SUCCESS';
const LOGIN_GOOGLE_FAILURE = '_users/LOGIN_GOOGLE_FAILURE';

const LOGIN_FACEBOOK_REQUEST = '_users/LOGIN_FACEBOOK_REQUEST';
const LOGIN_FACEBOOK_SUCCESS = '_users/LOGIN_FACEBOOK_SUCCESS';
const LOGIN_FACEBOOK_FAILURE = '_users/LOGIN_FACEBOOK_FAILURE';

const CREATE_USER_REQUEST = '_users/CREATE_USER_REQUEST';
const CREATE_USER_SUCCESS = '_users/CREATE_USER_SUCCESS';
const CREATE_USER_FAILURE = '_users/CREATE_USER_FAILURE';

const GET_USER_REQUEST = '_users/GET_USER_REQUEST';
const GET_USER_SUCCESS = '_users/GET_USER_SUCCESS';
const GET_USER_FAILURE = '_users/GET_USER_FAILURE';

const FORGOT_PASSWORD_REQUEST = '_users/FORGOT_PASSWORD_REQUEST';
const FORGOT_PASSWORD_SUCCESS = '_users/FORGOT_PASSWORD_SUCCESS';
const FORGOT_PASSWORD_FAILURE = '_users/FORGOT_PASSWORD_FAILURE';

const GET_USERS_REQUEST = '_users/GET_USERS_REQUEST';
const GET_USERS_SUCCESS = '_users/GET_USERS_SUCCESS';
const GET_USERS_FAILURE = '_users/GET_USERS_FAILURE';

const UPDATE_USER_REQUEST = '_users/UPDATE_USER_REQUEST';
const UPDATE_USER_SUCCESS = '_users/UPDATE_USER_SUCCESS';
const UPDATE_USER_FAILURE = '_users/UPDATE_USER_FAILURE';

const DELETE_USER_REQUEST = '_users/DELETE_USER_REQUEST';
const DELETE_USER_SUCCESS = '_users/DELETE_USER_SUCCESS';
const DELETE_USER_FAILURE = '_users/DELETE_USER_FAILURE';

const JOIN_REQUEST = '_users/JOIN_REQUEST';
const JOIN_SUCCESS = '_users/JOIN_SUCCESS';
const JOIN_FAILURE = '_users/JOIN_FAILURE';

const IMPERSONATE_USER_REQUEST = '_users/IMPERSONATE_USER_REQUEST';
const IMPERSONATE_USER_SUCCESS = '_users/IMPERSONATE_USER_SUCCESS';
const IMPERSONATE_USER_FAILURE = '_users/IMPERSONATE_USER_FAILURE';

const CREATE_INTEGRATION_REQUEST = '_users/CREATE_INTEGRATION_REQUEST';
const CREATE_INTEGRATION_SUCCESS = '_users/CREATE_INTEGRATION_SUCCESS';
const CREATE_INTEGRATION_FAILURE = '_users/CREATE_INTEGRATION_FAILURE';

const GET_INTEGRATIONS_REQUEST = '_users/GET_INTEGRATIONS_REQUEST';
const GET_INTEGRATIONS_SUCCESS = '_users/GET_INTEGRATIONS_SUCCESS';
const GET_INTEGRATIONS_FAILURE = '_users/GET_INTEGRATIONS_FAILURE';

const LOGOUT = '_users/LOGOUT';

const RESET_ENDPOINT_STATE = '_users/RESET_ENDPOINT_STATE';

// user.js
const UPDATE_API_KEYS_SUCCESS = 'user/UPDATE_API_KEYS_SUCCESS';
const UPDATE_PLAN = 'user/UPDATE_PLAN';

// _forms.js
const CREATE_FORM_SUCCESS = '_forms/CREATE_FORM_SUCCESS';
const DELETE_FORM_SUCCESS = '_forms/DELETE_FORM_SUCCESS';

const endpoitInitialStatus = {
  loading: false,
  error: false,
  success: false,
  errors: []
};

// Initiual State
const initialState = {
  user: {},
  token: storage.get('token') || null,
  tokenOtp: storage.get('tokenOtp') || null,
  tokenType: (() => {
    if (storage.get('tokenOtp')) return 'otp';
    if (storage.get('token')) return 'auth';

    return null;
  })(),

  users: [],
  integrations: [],

  userChecked: false,
  isAuthenticated: false,

  createUser: { ...endpoitInitialStatus },
  updateUser: { ...endpoitInitialStatus },
  getUser: { ...endpoitInitialStatus },
  getUsers: { ...endpoitInitialStatus },
  deleteUser: { ...endpoitInitialStatus },
  signup: { ...endpoitInitialStatus },
  login: { ...endpoitInitialStatus },
  join: { ...endpoitInitialStatus },
  loginGoogle: { ...endpoitInitialStatus },
  loginFacebook: { ...endpoitInitialStatus },
  forgotPassword: { ...endpoitInitialStatus },
  loginOnetool: { ...endpoitInitialStatus },
  impersonateUser: { ...endpoitInitialStatus },
  createIntegration: { ...endpoitInitialStatus },
  getIntegrations: { ...endpoitInitialStatus }
};

const getEndpointStatus = (type, errors = []) => {
  const types = {
    request: {
      loading: true,
      error: false,
      success: false,
      errors: []
    },
    success: {
      loading: false,
      error: false,
      success: true,
      errors: []
    },
    failure: {
      loading: false,
      error: true,
      success: false,
      errors
    },
  };

  return types[type];
};

// Reducer
export default function reducer(state = JSON.parse(JSON.stringify(initialState)), action = {}) {
  if (action.type === RESET_ENDPOINT_STATE) {
    state[action.payload.name] = JSON.parse(JSON.stringify(endpoitInitialStatus));

    return { ...state };
  }

  // logout
  if (action.type === LOGOUT) {
    state = JSON.parse(JSON.stringify(initialState));
    storage.clear();
    window.location.replace(window.location.origin);
    
    return { ...state };
  }

  // User actions
  if (action.type === UPDATE_API_KEYS_SUCCESS) {
    state.user.apiKey = action.payload.apiKey;
    state.user = { ...state.user };

    return { ...state };
  }

  if (action.type === UPDATE_PLAN) {
    state.user.plan[action.payload.key] = Math.round((action.payload.value) * 100) / 100;
    state.user = { ...state.user };

    return { ...state };
  }

  if (action.type === CREATE_FORM_SUCCESS) {
    state.user = { ...state.user };

    return { ...state };
  }

  if (action.type === DELETE_FORM_SUCCESS) {
    if (action.payload.status === 'live' || action.payload.status === 'paused') {
      state.user.planUsage.forms = state.user.planUsage.forms - 1;
      state.user = { ...state.user };
    }

    return { ...state };
  }

  // signup
  if (action.type === SIGNUP_REQUEST) {
    state.signup = getEndpointStatus('request');

    return { ...state };
  }

  if (action.type === SIGNUP_SUCCESS) {
    state.signup = getEndpointStatus('success');

    state.token = action.payload.token;
    storage.set('token', action.payload.token);

    return { ...state };
  }

  if (action.type === SIGNUP_FAILURE) {
    state.signup = getEndpointStatus('failure', [action.payload]);

    return { ...state };
  }

  // forgotPassword
  if (action.type === FORGOT_PASSWORD_REQUEST) {
    state.forgotPassword = getEndpointStatus('request');

    return { ...state };
  }

  if (action.type === FORGOT_PASSWORD_SUCCESS) {
    state.forgotPassword = getEndpointStatus('success');

    if (action.payload.token) {
      state.token = action.payload.token;
      storage.set('token', action.payload.token);
    }

    return { ...state };
  }

  if (action.type === FORGOT_PASSWORD_FAILURE) {
    state.forgotPassword = getEndpointStatus('failure', [action.payload]);

    return { ...state };
  }

  // loginGoogle
  if (action.type === LOGIN_GOOGLE_REQUEST) {
    state.loginGoogle = getEndpointStatus('request');

    return { ...state };
  }

  if (action.type === LOGIN_GOOGLE_SUCCESS) {
    state.loginGoogle = getEndpointStatus('success');

    state.tokenType = action.payload.type;

    if (action.payload.type === 'otp') {
      state.tokenOtp = action.payload.token;
      storage.set('tokenOtp', action.payload.token);
    } else {
      state.token = action.payload.token;
      storage.set('token', action.payload.token);
    }

    return { ...state };
  }

  if (action.type === LOGIN_GOOGLE_FAILURE) {
    state.loginGoogle = getEndpointStatus('failure');

    return { ...state };
  }

  // loginOnetool
  if (action.type === LOGIN_ONETOOL_REQUEST) {
    state.loginOnetool = getEndpointStatus('request');

    return { ...state };
  }

  if (action.type === LOGIN_ONETOOL_SUCCESS) {
    state.loginOnetool = getEndpointStatus('success');

    state.token = action.payload.token;
    storage.set('token', action.payload.token);

    return { ...state };
  }

  if (action.type === LOGIN_ONETOOL_FAILURE) {
    state.loginOnetool = getEndpointStatus('failure');

    return { ...state };
  }

  // loginFacebook
  if (action.type === LOGIN_FACEBOOK_REQUEST) {
    state.loginFacebook = getEndpointStatus('request');

    return { ...state };
  }

  if (action.type === LOGIN_FACEBOOK_SUCCESS) {
    state.loginFacebook = getEndpointStatus('success');

    state.tokenType = action.payload.type;

    if (action.payload.type === 'otp') {
      state.tokenOtp = action.payload.token;
      storage.set('tokenOtp', action.payload.token);
    } else {
      state.token = action.payload.token;
      storage.set('token', action.payload.token);
    }

    return { ...state };
  }

  if (action.type === LOGIN_FACEBOOK_FAILURE) {
    state.loginFacebook = getEndpointStatus('failure');

    return { ...state };
  }

  // validate otp
  if (action.type === VALIDATE_OTP_SUCCESS) {
    state.tokenType = 'auth';
    state.token = action.payload.token;
    state.tokenOtp = null;

    storage.remove('tokenOtp');
    storage.set('token', action.payload.token);

    return { ...state };
  }

  // login
  if (action.type === LOGIN_REQUEST) {
    state.login = getEndpointStatus('request');

    return { ...state };
  }

  if (action.type === LOGIN_SUCCESS) {
    state.login = getEndpointStatus('success');

    state.tokenType = action.payload.type;

    if (action.payload.type === 'otp') {
      state.tokenOtp = action.payload.token;
      storage.set('tokenOtp', action.payload.token);
    } else {
      state.token = action.payload.token;
      storage.set('token', action.payload.token);
    }

    return { ...state };
  }

  if (action.type === LOGIN_FAILURE) {
    state.login = getEndpointStatus('failure');

    return { ...state };
  }

  // updateUser
  if (action.type === UPDATE_USER_REQUEST) {
    state.updateUser = getEndpointStatus('request');
    state.user = { ...state.user, ...action.payload };

    return { ...state };
  }

  if (action.type === UPDATE_USER_SUCCESS) {
    state.updateUser = getEndpointStatus('success');

    return { ...state };
  }

  if (action.type === UPDATE_USER_FAILURE) {
    state.updateUser = getEndpointStatus('failure');

    return { ...state };
  }

  // getIntegrations
  if (action.type === GET_INTEGRATIONS_REQUEST) {
    state.getIntegrations = getEndpointStatus('request');

    return { ...state };
  }

  if (action.type === GET_INTEGRATIONS_SUCCESS) {
    state.getIntegrations = getEndpointStatus('success');
    state.integrations = action.payload;

    return { ...state };
  }

  if (action.type === GET_INTEGRATIONS_FAILURE) {
    state.getIntegrations = getEndpointStatus('failure');

    return { ...state };
  }

  // createIntegration
  if (action.type === CREATE_INTEGRATION_REQUEST) {
    state.createIntegration = getEndpointStatus('request');

    return { ...state };
  }

  if (action.type === CREATE_INTEGRATION_SUCCESS) {
    state.createIntegration = getEndpointStatus('success');

    return { ...state };
  }

  if (action.type === CREATE_INTEGRATION_FAILURE) {
    state.createIntegration = getEndpointStatus('failure');

    return { ...state };
  }

  // impersonateUser
  if (action.type === IMPERSONATE_USER_REQUEST) {
    state.impersonateUser = getEndpointStatus('request');

    return { ...state };
  }

  if (action.type === IMPERSONATE_USER_SUCCESS) {
    state.impersonateUser = getEndpointStatus('success');

    state.token = action.payload.token;
    storage.set('token', action.payload.token);

    return { ...state };
  }

  if (action.type === IMPERSONATE_USER_FAILURE) {
    state.impersonateUser = getEndpointStatus('failure');

    return { ...state };
  }

  // createUser
  if (action.type === CREATE_USER_REQUEST) {
    state.createUser = getEndpointStatus('request');

    return { ...state };
  }

  if (action.type === CREATE_USER_SUCCESS) {
    state.createUser = getEndpointStatus('success');
    state.user.planUsage.users = state.user.planUsage.users + 1;

    return { ...state };
  }

  if (action.type === CREATE_USER_FAILURE) {
    state.createUser = getEndpointStatus('failure');

    return { ...state };
  }

  // join
  if (action.type === JOIN_REQUEST) {
    state.join = getEndpointStatus('request');

    return { ...state };
  }

  if (action.type === JOIN_SUCCESS) {
    state.join = getEndpointStatus('success');

    state.token = action.payload.token;
    storage.set('token', action.payload.token);

    return { ...state };
  }

  if (action.type === JOIN_FAILURE) {
    state.join = getEndpointStatus('failure', [action.payload]);

    return { ...state };
  }

  // deleteUser
  if (action.type === DELETE_USER_REQUEST) {
    state.deleteUser = getEndpointStatus('request');

    return { ...state };
  }

  if (action.type === DELETE_USER_SUCCESS) {
    state.deleteUser = getEndpointStatus('success');
    state.user.planUsage.users = state.user.planUsage.users - 1;

    return { ...state };
  }

  if (action.type === DELETE_USER_FAILURE) {
    state.deleteUser = getEndpointStatus('failure');

    return { ...state };
  }

  // getUsers
  if (action.type === GET_USERS_REQUEST) {
    state.getUsers = getEndpointStatus('request');

    return { ...state };
  }

  if (action.type === GET_USERS_SUCCESS) {
    state.getUsers = getEndpointStatus('success');
    state.users = [ ...action.payload ];

    return { ...state };
  }

  if (action.type === GET_USERS_FAILURE) {
    state.getUsers = getEndpointStatus('failure');

    return { ...state };
  }

  // getUser
  if (action.type === GET_USER_REQUEST) {
    state.getUser = getEndpointStatus('request');

    return { ...state };
  }

  if (action.type === GET_USER_SUCCESS) {
    state.getUser = getEndpointStatus('success');
    state.user = { ...action.payload };

    state.user.plan = { ...action.payload.plan };
    state.user.planUsage = { ...action.payload.planUsage };

    state.userChecked = true;
    state.isAuthenticated = true;

    return { ...state };
  }

  if (action.type === GET_USER_FAILURE) {
    state.getUser = getEndpointStatus('failure');

    return { ...state };
  }

  return state;
}

// Action Creators
export function resetEndpoitState(name) {
  return (dispatch) => {
    dispatch({ type: RESET_ENDPOINT_STATE, payload: { name } });
  };
}

export function logout() {
  return (dispatch) => {
    return dispatch({ type: LOGOUT });
  };
}

export function forgotPassword(data) {
  const request = () => { return { type: FORGOT_PASSWORD_REQUEST } };
  const success = (res) => { return { type: FORGOT_PASSWORD_SUCCESS, payload: res } };
  const failure = (res) => { return { type: FORGOT_PASSWORD_FAILURE, payload: res } };

  return async (dispatch) => {
    dispatch(request());

    const response = await services.forgotPassword(data);

    if (response && response.status === 200) {
      return dispatch(success(response.data));
    } else {
      return dispatch(failure(typeof response !== 'undefined' ? response.data : []));
    }
  };
}

export function signup(data, plan) {
  const request = () => { return { type: SIGNUP_REQUEST } };
  const success = (res) => { return { type: SIGNUP_SUCCESS, payload: res } };
  const failure = (res) => { return { type: SIGNUP_FAILURE, payload: res } };

  return async (dispatch) => {
    dispatch(request());

    const response = await services.signup(data, plan);

    if (response && response.status === 200) {
      return dispatch(success(response.data));
    } else {
      return dispatch(failure(response.data));
    }
  };
}

export function login(data) {
  const request = () => { return { type: LOGIN_REQUEST } };
  const success = (res) => { return { type: LOGIN_SUCCESS, payload: res } };
  const failure = () => { return { type: LOGIN_FAILURE } };

  return async (dispatch) => {
    dispatch(request());

    const response = await services.login(data);

    if (response && response.status === 200) {
      return dispatch(success(response.data));
    } else {
      return dispatch(failure());
    }
  };
}

export function loginOnetool(token) {
  const request = () => { return { type: LOGIN_ONETOOL_REQUEST } };
  const success = (res) => { return { type: LOGIN_ONETOOL_SUCCESS, payload: res } };
  const failure = () => { return { type: LOGIN_ONETOOL_FAILURE } };

  return async (dispatch) => {
    dispatch(request());

    const response = await services.loginOnetool(token);

    if (response && response.status === 200) {
      return dispatch(success(response.data));
    } else {
      return dispatch(failure());
    }
  };
}

export function loginGoogle(data, plan) {
  const request = () => { return { type: LOGIN_GOOGLE_REQUEST } };
  const success = (res) => { return { type: LOGIN_GOOGLE_SUCCESS, payload: res } };
  const failure = (message) => { return { type: LOGIN_GOOGLE_FAILURE, payload: { message } } };

  return async (dispatch) => {
    dispatch(request());

    const response = await services.loginGoogle(data, plan);

    if (response && response.status === 200) {
      return dispatch(success(response.data));
    } else {
      return dispatch(failure(response.data.message));
    }
  };
}

export function loginFacebook(data, plan) {
  const request = () => { return { type: LOGIN_FACEBOOK_REQUEST } };
  const success = (res) => { return { type: LOGIN_FACEBOOK_SUCCESS, payload: res } };
  const failure = (message) => { return { type: LOGIN_FACEBOOK_FAILURE, payload: { message } } };

  return async (dispatch) => {
    dispatch(request());

    const response = await services.loginFacebook(data, plan);

    if (response && response.status === 200) {
      return dispatch(success(response.data));
    } else {
      return dispatch(failure(response.data.message));
    }
  };
}

export function getUser() {
  const request = () => { return { type: GET_USER_REQUEST } };
  const success = (res) => { return { type: GET_USER_SUCCESS, payload: res } };
  const failure = (code) => { return { type: GET_USER_FAILURE, payload: { code } } };

  return async (dispatch, getState) => {
    dispatch(request());

    const state = getState();

    if (state._users.token === null) return dispatch(failure(401));

    const response = await services.getUser(state._users.token);

    if (response.success) {
      return dispatch(success(response.data));
    } else {
      return dispatch(failure(response.status));
    }
  };
}

export function getUsers() {
  const request = () => { return { type: GET_USERS_REQUEST } };
  const success = (res) => { return { type: GET_USERS_SUCCESS, payload: res } };
  const failure = () => { return { type: GET_USERS_FAILURE } };

  return async (dispatch, getState) => {
    dispatch(request());

    const state = getState();

    const response = await services.getUsers(state._users.token);

    if (response) {
      dispatch(success(response));
    } else {
      dispatch(failure());
    }
  };
}

export function updateUser(data, options = {}) {
  const request = (data) => { return { type: UPDATE_USER_REQUEST, payload: data } };
  const success = () => { return { type: UPDATE_USER_SUCCESS } };
  const failure = () => { return { type: UPDATE_USER_FAILURE } };

  options = { ...{ updateApi: true, updateLocal: true }, ...options};

  return async (dispatch, getState) => {
    dispatch(request(options.updateLocal ? data : {}));

    if (!options.updateApi) return dispatch(success());

    const state = getState();
    const response = await services.updateUser(state._users.token, data);

    if (response) {
      return dispatch(success());
    } else {
      return dispatch(failure());
    }
  };
}

export function createUser(data) {
  const request = () => { return { type: CREATE_USER_REQUEST } };
  const success = () => { return { type: CREATE_USER_SUCCESS } };
  const failure = () => { return { type: CREATE_USER_FAILURE } };

  return async (dispatch, getState) => {
    dispatch(request());

    const state = getState();
    const response = await services.createUser(state._users.token, data);

    if (response) {
      return dispatch(success());
    } else {
      return dispatch(failure());
    }
  };
}

export function join(data) {
  const request = () => { return { type: JOIN_REQUEST } };
  const success = (res) => { return { type: JOIN_SUCCESS, payload: res } };
  const failure = (res) => { return { type: JOIN_FAILURE, payload: res } };

  return async (dispatch, getState) => {
    dispatch(request());

    const state = getState();
    const response = await services.join(state._users.token, data);

    if (response.status === 200) {
      return dispatch(success(response.data));
    } else {
      return dispatch(failure(response.data));
    }
  };
}

export function deleteUser(id, password) {
  const request = () => { return { type: DELETE_USER_REQUEST } };
  const success = () => { return { type: DELETE_USER_SUCCESS } };
  const failure = () => { return { type: DELETE_USER_FAILURE } };

  return async (dispatch, getState) => {
    dispatch(request());

    const state = getState();
    const response = await services.deleteUser(state._users.token, id, password);

    if (response) {
      return dispatch(success());
    } else {
      return dispatch(failure());
    }
  };
}

export function impersonateUser(id) {
  const request = () => { return { type: IMPERSONATE_USER_REQUEST } };
  const success = (res) => { return { type: IMPERSONATE_USER_SUCCESS, payload: res } };
  const failure = () => { return { type: IMPERSONATE_USER_FAILURE } };

  return async (dispatch, getState) => {
    dispatch(request());

    const state = getState();
    const response = await services.impersonateUser(state._users.token, id);

    if (response) {
      return dispatch(success(response.data));
    } else {
      return dispatch(failure());
    }
  };
}

export function createIntegration(data) {
  const request = () => { return { type: CREATE_INTEGRATION_REQUEST } };
  const success = (res) => { return { type: CREATE_INTEGRATION_SUCCESS, payload: res } };
  const failure = () => { return { type: CREATE_INTEGRATION_FAILURE } };

  return async (dispatch, getState) => {
    dispatch(request());

    const state = getState();
    const response = await services.createIntegration(state._users.token, data);

    if (response) {
      return dispatch(success(response.data));
    } else {
      return dispatch(failure());
    }
  };
}

export function getIntegrations() {
  const request = () => { return { type: GET_INTEGRATIONS_REQUEST } };
  const success = (res) => { return { type: GET_INTEGRATIONS_SUCCESS, payload: res } };
  const failure = () => { return { type: GET_INTEGRATIONS_FAILURE } };

  return async (dispatch, getState) => {
    dispatch(request());

    const state = getState();
    const response = await services.getIntegrations(state._users.token);

    if (response) {
      return dispatch(success(response.data));
    } else {
      return dispatch(failure());
    }
  };
}

export function initOtp() {
  return async (dispatch, getState) => {
    const state = getState();
    const response = await services.initOtp(state._users.token);

    if (response && response.status === 200) {
      return Promise.resolve(response.data);
    } else {
      return Promise.reject(response && response.data);
    }
  };
}

export function enableOtp(code) {
  return async (dispatch, getState) => {
    const state = getState();
    const response = await services.enableOtp(state._users.token, code);

    if (response && response.status === 200) {
      return Promise.resolve(response.data);
    } else {
      return Promise.reject(response && response.data);
    }
  };
}

export function disableOtp(code) {
  return async (dispatch, getState) => {
    const state = getState();
    const response = await services.disableOtp(state._users.token, code);

    if (response && response.status === 200) {
      return Promise.resolve(response.data);
    } else {
      return Promise.reject(response && response.data);
    }
  };
}

export function validateOtp(code) {
  const success = (res) => { return { type: VALIDATE_OTP_SUCCESS, payload: res } };

  return async (dispatch, getState) => {
    const state = getState();
    const response = await services.validateOtp(state._users.tokenOtp, code);

    if (response && response.status === 200) {
      dispatch(success(response && response.data));

      return Promise.resolve(response.data);
    } else if (response && response.status === 401) { 
      storage.clear();

      return Promise.reject(response && response.data);
    } else {
      return Promise.reject(response && response.data);
    }
  };
}

const services = {
  forgotPassword: async (data) => {
    try {
      const response = await axios({
        method: 'POST',
        url: `${config.apiUrl}/user/forgotpassword`,
        headers: {
          'Content-Type': 'application/json'
        },
        data: data
      });

      return response;
    } catch (e) {
      return e.response;
    }
  },
  signup: async (data, plan) => {
    try {
      const response = await axios({
        method: 'POST',
        url: `${config.apiUrl}/auth/signup`,
        headers: {
          'Content-Type': 'application/json'
        },
        data: {
          ...data,
          plan
        }
      });

      return response;
    } catch (e) {
      return e.response;
    }
  },
  login: async (data) => {
    try {
      const response = await axios({
        method: 'POST',
        url: `${config.apiUrl}/auth/login`,
        headers: {
          'Content-Type': 'application/json'
        },
        data
      });

      return response;
    } catch (e) {
      return e.response;
    }
  },
  loginOnetool: async (token) => {
    try {
      const response = await axios({
        method: 'POST',
        url: `${config.apiUrl}/auth/login/onetool`,
        headers: {
          'Content-Type': 'application/json'
        },
        data: { token }
      });

      return response;
    } catch (e) {
      return e.response;
    }
  },
  loginGoogle: async (data, plan) => {
    try {
      const response = await axios({
        method: 'POST',
        url: `${config.apiUrl}/auth/login/google`,
        headers: {
          'Content-Type': 'application/json'
        },
        data: { ...data, plan }
      });

      return response;
    } catch (e) {
      return e.response;
    }
  },
  loginFacebook: async (data, plan) => {
    try {
      const response = await axios({
        method: 'POST',
        url: `${config.apiUrl}/auth/login/facebook`,
        headers: {
          'Content-Type': 'application/json'
        },
        data: { ...data, plan }
      });

      return response;
    } catch (e) {
      return e.response;
    }
  },
  createUser: async (token, data) => {
    try {
      await axios({
        method: 'POST',
        url: `${config.apiUrl}/users`,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        data
      });

      return true;
    } catch (e) {
      return false;
    }
  },
  join: async (token, data) => {
    try {
      const response = await axios({
        method: 'POST',
        url: `${config.apiUrl}/auth/join`,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        data
      });

      return response;
    } catch (e) {
      return e.response;
    }
  },
  getUser: async (token) => {
    try {
      const response = await axios({
        method: 'GET',
        url: `${config.apiUrl}/user`,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });

      return {
        success: true,
        status: response.status,
        data: response.data
      };
    } catch (e) {
      return {
        success: false,
        status: e.response ? e.response.status : 404,
        data: {}
      };
    }
  },
  getUsers: async (token) => {
    try {
      const response = await axios({
        method: 'GET',
        url: `${config.apiUrl}/users`,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });

      return response.data;
    } catch (e) {
      return false;
    }
  },
  updateUser: async (token, data) => {
    let url = `${config.apiUrl}/user`;

    if (data._id) {
      url = `${config.apiUrl}/users/${data._id}`;
    }

    try {
      const response = await axios({
        method: 'PUT',
        url,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        data
      });

      return response.data;
    } catch (e) {
      return false;
    }
  },
  deleteUser: async (token, id, password) => {
    let url = `${config.apiUrl}/users/${id}`;

    if (id === 'self') {
      url = `${config.apiUrl}/user`;
    }

    try {
      await axios({
        method: 'DELETE',
        url,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        data: {
          password
        }
      });

      return true;
    } catch (e) {
      return false;
    }
  },
  impersonateUser: async (token, id) => {
    try {
      const response = await axios({
        method: 'GET',
        url: `${config.apiUrl}/admin/impersonate/${id}`,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });

      return response;
    } catch (e) {
      return e.response;
    }
  },
  initOtp: async (token) => {
    try {
      const response = await axios({
        method: 'POST',
        url: `${config.apiUrl}/otp/init`,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });

      return response;
    } catch (e) {
      return e.response;
    }
  },
  enableOtp: async (token, code) => {
    try {
      const response = await axios({
        method: 'POST',
        url: `${config.apiUrl}/otp/enable`,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        data: {
          code
        }
      });

      return response;
    } catch (e) {
      return e.response;
    }
  },  
  disableOtp: async (token, code) => {
    try {
      const response = await axios({
        method: 'POST',
        url: `${config.apiUrl}/otp/disable`,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        data: {
          code
        }
      });

      return response;
    } catch (e) {
      return e.response;
    }
  },
  validateOtp: async (token, code) => {
    try {
      const response = await axios({
        method: 'POST',
        url: `${config.apiUrl}/otp/validate`,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        data: { code }
      });

      return response;
    } catch (e) {
      return e.response;
    }
  },
  getIntegrations: async (token) => {
    try {
      const response = await axios({
        method: 'GET',
        url: `${config.apiUrl}/integrations`,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });

      return response;
    } catch (e) {
      return e.response;
    }
  },
  createIntegration: async (token, data) => {
    try {
      const response = await axios({
        method: 'POST',
        url: `${config.apiUrl}/integrations`,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        data
      });

      return response;
    } catch (e) {
      return e.response;
    }
  },
};
