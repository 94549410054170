import styles from './Integrations.module.css';

import React from 'react';

import { TextInput, Toggle, Card, CardRow, CardRowLabel, CardRowContent } from 'ui';

import logoIcon from 'assets/images/integrations/googleTagManager-small.png';

const GoogleTagManager = ({ integration, onChange }) => {
  return <>
    <div className={styles.head}>
      <img src={logoIcon} width="32px" alt="logo" className={styles.logo} />
      <div className={styles.label}>Google Tag Manager</div>
      <div className={styles.desc}>Add your GTM container ID to QuestionScout forms and surveys for conversion tracking, site analytics, retargeting, and more.</div>

      <div className={styles.statusLabel}>Integration Status</div>
      <div className={styles.statusValue} onClick={() => onChange('enabled', !integration.enabled, false)}>
        <Toggle className={styles.statusValueToggle} checked={integration.enabled} readOnly={true} onChange={() => { }} />
        <div className={styles.statusValueText}>This integration is currently <span>{integration.enabled ? 'enabled' : 'disabled'}</span></div>
      </div>
    </div>

    <Card className={styles.card}>
      <CardRow>
        <CardRowLabel title="Google Tag Manager Container ID" subTitle="Insert the Google Tag Manager Container ID" />

        <CardRowContent>
          <TextInput value={integration.containerId} placeholder="GTM-XXXXX" width={180} onChange={(e) => onChange('containerId', e.target.value, true)} />
        </CardRowContent>
      </CardRow>
    </Card>
  </>;
}

export default GoogleTagManager;
