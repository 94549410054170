import React from 'react';
import isJsonString from 'helpers/isJsonString.js';

const getValue = (obj, options) => {
  let value = null;
  let values = [];

  if (obj.type === 'checkbox') {
    values = (JSON.parse(obj.value) || { values: [null] }).values;

    if (values.length === 0) return '';
  }

  if (obj.type === 'dropdown') {
    value = obj.value;

    if (!value) return '';
  }

  if (obj.type === 'radio') {
    value = (JSON.parse(obj.value) || { value: null }).value;

    if (!value) return '';
  }

  if (value) return (options.find((option) => option.ref === value) || { value: null }).value;
  if (values.length !== 0) return values.map((v) => (options.find((option) => option.ref === v) || { value: '' }).value).join(', ');

  return obj.value;
};

const deserialize = (string) => {
  return [{
    children: [{ type: 'paragraph', text: encodeURI(string) }]
  }];
};

const decode = (str) => {
  str = str.replace(/%(?![0-9][0-9a-fA-F]+)/g, '%25')

  return decodeURI(str);
};

const serialize = (node, values, options, variables, index) => {
  let text = node.text;

  if (text) {
    text = decode(text);

    if (node.bold) text = <strong>{text}</strong>;
    if (node.italic) text = <em>{text}</em>;
    if (node.underline) text = <u>{text}</u>;

    return text;
  };

  const children = (node.children || []).map((n, i) => serialize(n, values, options, variables, i));

  switch (node.type) {
    case 'mention':
      if (node.origin === 'calculations') return (variables.find((variable) => variable._id === node._id) || { value: '___' }).value;

      if (Array.isArray(values)) {
        if (!node._id || !values || !values.find((v) => v.field === node._id)) return '';

        return getValue(values.find((v) => v.field === node._id), options[node._id]) || '______';
      } else if (values === Object(values)) {
        if (!node._id || !values || !values[node._id]) return '';

        return getValue(values[node._id], options[node._id]) || '______';
      } else {
        return '______';
      }
    case 'paragraph':
      return <p key={index}>{children}</p>;
    case 'link':
      return <a key={index} style={{ pointerEvents: 'none' }}>{children}</a>;
    case 'bulleted-list':
      return <ul key={index}>{children}</ul>
    case 'list-item':
      return <li key={index}>{children}</li>
    case 'numbered-list':
      return <ol key={index}>{children}</ol>
    default:
      return <React.Fragment key={index}>{children}</React.Fragment>;
  }
}

const plainText = (node, values, options, variables) => {
  if (node.text) return decodeURI(node.text);

  const children = (node.children || []).map((n) => plainText(n, values, options, variables));

  switch (node.type) {
    case 'mention':
      if (node.origin === 'calculations') return '___';

      if (Array.isArray(values)) {
        if (!node._id || !values || !values.find((v) => v.field === node._id)) return '';

        return getValue(values.find((v) => v.field === node._id), options[node._id]) || '______';
      } else if (values === Object(values)) {
        if (!node._id || !values || !values[node._id]) return '';

        return getValue(values[node._id], options[node._id]) || '______';
      } else {
        return '______';
      }
    default:
      return children.join('');
  }
};

export default function getFieldLabel(value, values, type = 'text', options = [], variables = []) {
  if (typeof value === 'string') value = value.trim();

  let valueCopy = value;
  let decodedURI = '';

  if (typeof valueCopy === 'string') valueCopy = valueCopy.trim();

  const nodes = isJsonString(valueCopy) ? JSON.parse(valueCopy) : deserialize(valueCopy || '');

  if (!nodes || !Array.isArray(nodes) || typeof nodes === 'string' || nodes.length === 0) return '';

  try {
    if (type === 'text') {
      return nodes.map((n) => plainText(n, values, options, variables)).join('');
    } else if (type === 'jsx') {
      return nodes.map((n, index) => serialize(n, values, options, variables, index));
    }
  } catch (e) {
    console.log(e);
  }

  return decodedURI;
};
