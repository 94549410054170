import styles from './SettingsMessaging.module.css';

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import defaultMessages from 'form/messages/en.json';

import {
  ioUpdateForm, updateForm, copyFormMessaging
} from 'store/ducks/builder.js';

import {
  getForms
} from 'store/ducks/_forms.js';

import { MentionsInput, FormRow, Button, Select, Loader } from 'ui';

const SettingsMessaging = () => {
  const dispatch = useDispatch();

  const formId = useSelector(state => state.builder.form._id);
  const messages = useSelector(state => state.builder.form.messages);
  const forms = useSelector(state => state._forms.forms);

  const [initialValueKey, setInitialValueKey] = useState(0);
  const [formToCopyFrom, setFormToCopyFrom] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    dispatch(getForms());
  }, []);

  const handleMessageValueUpdate = (key, value) => {
    const param = {
      messages: {
        ...messages,
        [key]: value
      }
    };

    // console.log(value.replace(/\"/g, '\\"'));

    dispatch(updateForm(param));
    dispatch(ioUpdateForm(param));
  };

  const restoreDefault = (key) => {
    const param = {
      messages: { ...messages }
    };

    delete param.messages[key];

    dispatch(updateForm(param));
    dispatch(ioUpdateForm(param));

    setInitialValueKey(initialValueKey + 1);
  };

  const restoreAll = () => {
    setLoading(true);

    dispatch(ioUpdateForm({ messages: {} })).then(() => window.location.reload());
  };

  const copyFromAnotherForm = () => {
    if (!formToCopyFrom) return;

    setLoading(true);

    dispatch(copyFormMessaging(formToCopyFrom)).then(() => window.location.reload());
  };

  return (
    <div className={styles.card}>
      <div className={styles.top}>
        <div className={styles.copy}>
          <Select width={320} maxHeight={200} options={forms.filter((f) => f._id !== formId).map((f) => {
            return { label: f.name, value: f._id };
          })} margin="0 10px 0 0" value={
            { label: (forms.find((option) => option._id === formToCopyFrom) || { name: '- Select a form to copy messaging from -' }).name, value: formToCopyFrom }
          } onChange={(selected) => setFormToCopyFrom(selected.value)} />
          <Button theme="white" disabled={!formToCopyFrom} onClick={() => copyFromAnotherForm()}>Copy</Button>
        </div>

        <Button theme="white" onClick={() => restoreAll()}>Restore all to messaging default</Button>
      </div>

      <hr />

      <div className={styles.row}>
        {Object.keys(defaultMessages).map((key, index) => {
          return <React.Fragment key={key}>
            <FormRow label={defaultMessages[key].label} description={defaultMessages[key].description}>
              <div className={styles.formRow}>
                <MentionsInput
                  initialValue={`${messages[key] || defaultMessages[key].value}`}
                  initialValueKey={initialValueKey}
                  onChange={(value) => handleMessageValueUpdate(key, value)}
                  data={defaultMessages[key].data || []}
                />
                <Button theme="white" className={styles.restore} onClick={() => restoreDefault(key)}>Restore default</Button>
              </div>
            </FormRow>

            {index + 1 < Object.keys(defaultMessages).length && <hr />}
          </React.Fragment>;
        })}
      </div>

      {loading && <div className={styles.loader}>
        <Loader size={50} />
      </div>}
    </div>
  );
}

export default SettingsMessaging;
