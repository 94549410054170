import styles from './Select.module.css';

import React, { Component, useState } from 'react';
import { ChromePicker } from 'react-color';
import ScrollArea from 'react-scrollbar';
import Color from 'color';
import ReactSelect from 'react-select';

import { ReactComponent as EditIcon } from 'assets/images/edit.svg';
import { ReactComponent as DropdownIcon } from 'assets/images/dropdown-arrow.svg';

const select2CustomStyles = {
  control: (provided) => ({
    ...provided,
    minHeight: '32px',
    border: '1px solid #BFBFBF',
    borderColor: '#BFBFBF',
    background: '#FFFFFF',
    borderRadius: '4px',
    color: '#656565',
    fontSize: '13px',
    fontWeight: '400',
    lineHeight: '22px',
    boxShadow: 0,
    cursor: 'pointer',
    '&:hover': {
      borderColor: '#BFBFBF'
    }
  }),
  option: (provided, state) => ({
    ...provided,
    background: state.isSelected ? 'rgba(0, 100, 246, 0.05)' : '#fff',
    color: '#656565',
    cursor: 'pointer',
    padding: '6px 15px',
    '&:hover': {
      background: '#FAFAFA',
      color: '#656565',
      border: 0
    }
  }),
  singleValue: (provided) => ({
    ...provided,
    width: '100%',
    color: '#656565',
    marginLeft: 0,
    marginRight: 0,
    maxWidth: 'calc(100% - 16px)'
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: 'none'
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: 4
  }),
  clearIndicator: (provided) => ({
    ...provided,
    padding: 4
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: '0 0 0 15px'
  }),
  input: (provided) => ({
    ...provided,
    margin: 0,
    padding: 0
  })
};

const DropdownIndicator = () => <div className={styles.dropdownIndicator}>
  <DropdownIcon />
</div>;

export const Select2 = (props) => {
  let classList = [styles.select2, props.className];

  return <ReactSelect {...props}
    styles={select2CustomStyles}
    isSearchable={props.filter === true}
    components={{ DropdownIndicator }}
    className={classList.join(' ')} />;
};

export class Select extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selected: this.props.value,
      showDropdown: false,
      position: 'bottom',
      opacity: 0,
      query: ''
    };

    this.selectRef = React.createRef();
    this.scrollAreaRef = React.createRef();
  }

  handleItemClick = (option) => {
    this.setState({
      showDropdown: false,
      selected: option,
      query: ''
    });

    this.props.onChange(option);
  }

  handleInputClick = () => {
    this.setState({
      showDropdown: !this.state.showDropdown,
      opacity: 0
    }, () => {
      setTimeout(() => {
        let showOnTop = false;
        let select, parent;
        let maxHeight;

        if (this.props.disablePositionDetect) {
          this.setState({
            opacity: 1
          });

          return;
        }

        if (this.selectRef && this.selectRef.current && this.scrollAreaRef && this.scrollAreaRef.current) {
          select = this.selectRef.current.getBoundingClientRect();
          parent = document.body.getBoundingClientRect();
          maxHeight = this.scrollAreaRef.current.state.realHeight > 310 ? 310 : this.scrollAreaRef.current.state.realHeight;

          showOnTop = parent.height - (select.top + select.height + 10) < maxHeight;
        }

        if (showOnTop) {
          this.scrollAreaRef.current.scrollArea.refresh();
          this.scrollAreaRef.current.scrollArea.scrollBottom();
        }

        this.setState({
          position: showOnTop ? 'top' : 'bottom',
          opacity: 1
        });
      });
    });
  }

  handleCoverClick = () => {
    this.setState({
      showDropdown: false,
      query: ''
    });
  }

  handleQueryChange = (value) => {
    this.setState({
      query: value
    });
  }

  render() {
    let {
      value, options, width, style, disabled, error, size, maxHeight, margin, className, filter
    } = this.props;

    let filteredOptins = options;

    if (filter) filteredOptins = options.filter((option) => typeof option.label === 'string' && option.label.toLowerCase().includes(this.state.query));

    return (
      <div className={[
        styles.select,
        error ? styles.error : '',
        size ? styles[size] : styles.default,
        className
      ].join(' ')} style={{ width, margin, maxWidth: width }}>
        <div className={[
          styles.selectInput,
          this.state.showDropdown ? styles.active : '',
          disabled ? styles.disabled : ''
        ].join(' ')} ref={this.selectRef} onClick={() => this.handleInputClick()} style={style}>
          {!filter && value.label}
          {filter && <>
            {!this.state.showDropdown && value.label}
            {this.state.showDropdown && <input type="text" value={this.state.query} onChange={(e) => this.handleQueryChange(e.target.value)} autoFocus={true} placeholder="Search" />}
          </>}
        </div>

        {this.state.showDropdown && <>
          <div className={styles.selectCover} onClick={() => this.handleCoverClick()} />
        </>}

        <div className={[styles.selectDropdown, styles[this.state.position]].join(' ')} style={{
          width: '100%',
          maxHeight: maxHeight || 308,
          top: this.state.position === 'top' ? -310 : 34,
          opacity: this.state.opacity,
          display: this.state.showDropdown ? 'block' : 'none'
        }} onClick={() => this.handleCoverClick()}>
          <div className={styles.selectDropdownContent}>
            <ScrollArea speed={0.8}
              ref={this.scrollAreaRef}
              className={styles.scrollArea}
              onScroll={this.handleOnScroll}
              contentClassName={styles.scrollAreaContent}
              stopScrollPropagation={true}
              style={{ maxHeight: maxHeight || 308 }}
              horizontal={false}>
              <div className={styles.selectDropdownItems}>
                {filteredOptins.map((option) => {
                  return (
                    <div key={option.value} className={[
                      styles.selectDropdownItem,
                      value.value === option.value ? styles.selected : ''
                    ].join(' ')} onClick={() => this.handleItemClick(option)}>{option.label}</div>
                  )
                })}
              </div>
            </ScrollArea>
          </div>
        </div>

      </div>
    )
  }
}

export class FontSelect extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selected: this.props.value,
      showDropdown: false,
      position: 'bottom',
      opacity: 0
    };

    this.selectRef = React.createRef();
    this.scrollAreaRef = React.createRef();
  }

  handleItemClick = (option) => {
    this.setState({
      showDropdown: false,
      selected: option
    });

    this.props.onChange(option);
  }

  handleInputClick = () => {
    this.setState({
      showDropdown: !this.state.showDropdown,
      opacity: 0
    }, () => {
      setTimeout(() => {
        let showOnTop = false;
        let select, parent;
        let maxHeight;

        if (this.selectRef && this.selectRef.current && this.scrollAreaRef && this.scrollAreaRef.current) {
          select = this.selectRef.current.getBoundingClientRect();
          parent = document.body.getBoundingClientRect();
          maxHeight = this.scrollAreaRef.current.state.realHeight > 242 ? 242 : this.scrollAreaRef.current.state.realHeight;

          showOnTop = parent.height - (select.top + select.height + 10) < maxHeight;
        }

        if (showOnTop) {
          this.scrollAreaRef.current.scrollArea.refresh();
          this.scrollAreaRef.current.scrollArea.scrollBottom();
        }

        this.setState({
          position: showOnTop ? 'top' : 'bottom',
          opacity: 1
        });
      });
    });
  }

  handleCoverClick = () => {
    this.setState({
      showDropdown: false
    });
  }

  render() {
    let {
      value, options, width, disabled, maxHeight
    } = this.props;

    if (typeof value.label !== 'undefined') {
      value.label = value.label.charAt(0).toUpperCase() + value.label.slice(1);
    }

    return (
      <div className={styles.select} style={{ width }}>
        <div className={[
          styles.selectInput,
          this.state.showDropdown ? styles.active : '',
          disabled ? styles.disabled : ''
        ].join(' ')} ref={this.selectRef} onClick={() => this.handleInputClick()} style={{
          fontFamily: this.state.selected.value
        }}>{value.label}</div>
        {this.state.showDropdown && <>
          <div className={styles.selectCover} onClick={() => this.handleCoverClick()} />
        </>}

        <div className={[styles.selectDropdown, styles[this.state.position]].join(' ')} style={{
          width,
          maxHeight: maxHeight || 242,
          top: this.state.position === 'top' ? -244 : 34,
          opacity: this.state.opacity,
          display: this.state.showDropdown ? 'block' : 'none'
        }} onClick={() => this.handleCoverClick()}>
          <div className={styles.selectDropdownContent}>
            <ScrollArea speed={0.8}
              ref={this.scrollAreaRef}
              className={styles.scrollArea}
              onScroll={this.handleOnScroll}
              contentClassName={styles.scrollAreaContent}
              stopScrollPropagation={true}
              style={{ maxHeight: maxHeight || 242 }}
              horizontal={false}>
              <div className={styles.selectDropdownItems}>
                {options.map((option) => {
                  return (
                    <div key={option.value} className={[
                      styles.selectDropdownItem,
                      value.value === option.value ? styles.selected : ''
                    ].join(' ')} style={{
                      fontFamily: option.value
                    }} onClick={() => this.handleItemClick(option)}>{option.label}</div>
                  )
                })}
              </div>
            </ScrollArea>
          </div>
        </div>
      </div>
    )
  }
}

export class ColorSelect extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show: false
    };
  }

  handleClick = () => {
    this.setState({
      show: !this.state.show
    });
  }

  handleClose = () => {
    this.setState({ show: false });
  };

  render() {
    const {
      value, onChange, width, disabled
    } = this.props;

    return (
      <>
        <div className={[
          styles.selectInput,
          styles.colorSelect,
          this.state.show ? styles.active : '',
          disabled ? styles.disabled : ''
        ].join(' ')} onClick={() => this.handleClick()} style={{ width: width }}>
          <svg width="13" height="17" xmlns="http://www.w3.org/2000/svg"><g fill="none" fillRule="evenodd"><path d="M-5-3h23v23H-5z" /><path d="M6.43 16.166a6.307 6.307 0 0 1-4.5-1.942 6.582 6.582 0 0 1 .086-9.174L5.97 1.09a.801.801 0 0 1 .605-.256.852.852 0 0 1 .605.264l3.886 4.02a6.582 6.582 0 0 1-.077 9.174 6.248 6.248 0 0 1-4.56 1.874z" fill={value} fillRule="nonzero" /></g></svg>
        </div>

        {this.state.show && <div className={styles.colorSelectPopover}>
          <div className={styles.selectCover} onClick={() => this.handleClose()} />
          <ChromePicker color={value} onChangeComplete={onChange} />
        </div>}
      </>
    )
  }
}

export const ColorPaletteSelect = ({ onChange, palette, value, width, dropdownWidth, disabled, onNewColor }) => {
  const [show, setShow] = useState(false);

  const handleSelect = (selected) => {
    onChange(selected);
  };

  const handleChange = (c) => {
    onChange(`rgba(${c.rgb.r}, ${c.rgb.g}, ${c.rgb.b}, ${c.rgb.a})`);
  };

  const colorName = `${Color(typeof value === 'string' ? value : value.value).hex()}, ${Color(typeof value === 'string' ? value : value.value).alpha() * 100}% alpha`;

  return (
    <>
      <div className={[
        styles.selectInput,
        styles.colorPaletteSelect,
        show ? styles.active : '',
        disabled ? styles.disabled : ''
      ].join(' ')} onClick={() => setShow(true)} style={{ width: width }}>
        <svg width="13px" height="17px" viewBox="0 0 13 17" version="1.1" xmlns="http://www.w3.org/2000/svg">
          <g stroke="none" strokeWidth="0.3" fill="none" fillRule="evenodd">
            <g transform="translate(-194.000000, -192.000000)">
              <g transform="translate(189.000000, 189.000000)">
                <g>
                  <rect x="0" y="0" width="23" height="23"></rect>
                  <g transform="translate(5.111111, 3.833333)">
                    <mask id="mask-2" fill="white">
                      <path d="M6.31862292,15.3325838 C4.61818972,15.3180977 2.99568165,14.6177714 1.81918473,13.3904826 C-0.639093934,10.8126896 -0.601339877,6.74833149 1.90440136,4.21660982 L5.85845311,0.25574554 C6.014316,0.0880313442 6.23454001,-0.00498091619 6.4634912,0.000205908961 C6.69247363,0.00338803628 6.91053826,0.0985582877 7.06852929,0.264263528 L10.9544077,4.28475373 C13.4150311,6.86025437 13.3810526,10.9245895 10.8777128,13.4586265 C9.67929414,14.6816082 8.03107128,15.3590898 6.31862292,15.3325838 Z"></path>
                    </mask>
                    <g fillRule="nonzero"></g>
                    <g mask="url(#mask-2)" fill="#000000" fillOpacity="0.14" fillRule="nonzero">
                      <g transform="translate(-1.111111, -2.833333)">
                        <path d="M6,12 L6,15 L3,15 L3,12 L6,12 Z M12,12 L12,15 L9,15 L9,12 L12,12 Z M18,12 L18,15 L15,15 L15,12 L18,12 Z M6,6 L6,9 L3,9 L3,6 L6,6 Z M12,6 L12,9 L9,9 L9,6 L12,6 Z M18,6 L18,9 L15,9 L15,6 L18,6 Z M6,0 L6,3 L3,3 L3,0 L6,0 Z M12,0 L12,3 L9,3 L9,0 L12,0 Z"></path>
                        <path d="M3,15 L3,18 L0,18 L0,15 L3,15 Z M9,15 L9,18 L6,18 L6,15 L9,15 Z M15,15 L15,18 L12,18 L12,15 L15,15 Z M3,9 L3,12 L0,12 L0,9 L3,9 Z M9,9 L9,12 L6,12 L6,9 L9,9 Z M15,9 L15,12 L12,12 L12,9 L15,9 Z M3,3 L3,6 L0,6 L0,3 L3,3 Z M9,3 L9,6 L6,6 L6,3 L9,3 Z"></path>
                      </g>
                    </g>
                  </g>
                </g>
                <g>
                  <rect x="0" y="0" width="23" height="23"></rect>
                  <path strokeOpacity="1" stroke={Color(typeof value === 'string' ? value : value.value).negate().hex()} fill={typeof value === 'string' ? value : value.value} fillRule="nonzero" d="M11.4339934,18.6659353 L11.4374723,18.665977 C13.0128925,18.690362 14.5292129,18.0670974 15.6331226,16.9405675 C17.9463485,14.5989757 17.9777458,10.8433898 15.705998,8.46557058 L11.817764,4.44262652 C11.7524744,4.37414904 11.6623287,4.33480664 11.5632779,4.33341098 C11.4771874,4.33146063 11.3943936,4.36642885 11.3234218,4.44232782 L7.37088457,8.40166844 C5.05543949,10.7411209 5.02055344,14.496728 7.29123924,16.8778103 C8.37445122,18.0077869 9.86834086,18.6525974 11.4339934,18.6659353 Z"></path>
                </g>
              </g>
            </g>
          </g>
        </svg>
        <div className={styles.label}>{value.name || colorName}</div>
      </div>

      {show && <div className={styles.colorSelectPopover}>
        <div className={styles.selectCover} onClick={() => setShow(false)} />

        <div className={styles.colorPaletteDropdown} style={{ width: dropdownWidth }}>
          <ChromePicker className={styles.chromePicker} color={typeof value === 'string' ? value : value.value} onChangeComplete={handleChange} />
          <ScrollArea speed={0.8}
            className={styles.scrollArea}
            contentClassName={styles.scrollAreaContent}
            stopScrollPropagation={true}
            horizontal={false}>
            <ul>
              <li onClick={onNewColor}><span className={styles.colorCircle} style={{
                background: '#FFFFFF',
                border: '1px solid rgb(204, 204, 204)'
              }}><EditIcon /></span></li>

              {palette.map((color) => <li key={color._id} className={typeof value === 'object' && value._id === color._id ? styles.active : ''}><div onClick={() => handleSelect(color)} className={styles.colorCircle}><span style={{
                background: color.value,
                border: `1px solid ${Color(color.value).darken(0.3)}`
              }} /></div></li>)}
            </ul>
          </ScrollArea>
        </div>
      </div>}
    </>
  );
}
