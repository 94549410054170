import styles from './Menu.module.css';

import React from 'react';

import {
  TitleField, SizeWeightAndColorField, CheckboxField, SizeWithColorField,
  SizeWithTypeField, SizeField, SelectField, ColorField, GradientField,
  InputStyleField, ToggleField, CopyField
} from '../ThemeMenuFields.js';

const typeOptions = [
  { label: '%', value: '%' },
  { label: 'px', value: 'px' }
];

const fontWeightOptions = [
  { label: 400, value: 400 },
  { label: 600, value: 600 }
];

const alignOptions = [
  { label: 'Left', value: 'left' },
  { label: 'Center', value: 'center' },
  { label: 'Right', value: 'right' }
];

const stylingOptions = [
  { label: 'Solid', value: 'solid' },
  { label: 'Dotted', value: 'dotted' },
  { label: 'Dashed', value: 'dashed' }
];

const States = ({ title, subTitle, id, settings, colors, onChange, onNewColor, onCopy }) => {
  return (
    <>
      <TitleField title={title} subTitle={subTitle} />

      {subTitle !== 'Idle' && <ToggleField label={`Different styling on ${subTitle}`}
        settings={settings}
        onChange={onChange}
        margin="25px 0 0 0"
        value={`${id}Enable`} />}

      {(subTitle === 'Idle' || settings[`${id}Enable`]) && <>
        {subTitle === 'Hover' && <CopyField onCopy={onCopy}
          margin="15px 0 0 0"
          id={id}
          values={['MainTextColor', 'HelpTextColor', 'Background', 'BorderColor', 'BorderStyle', 'Shadow', 'ShadowColor', 'ShadowOffsetX', 'ShadowOffsetY', 'ShadowBlur', 'ShadowSpread']}
          options={[
            { label: 'Idle', value: 'fileUploadsIdle' }
          ]} />}

        {subTitle === 'Idle' && <SizeWeightAndColorField title="Main text"
          settings={settings}
          colors={colors}
          onChange={onChange}
          onNewColor={onNewColor}
          weights={fontWeightOptions}
          margin="25px 0 0 0"
          size={`${id}MainTextFontSize`}
          weight={`${id}MainTextWeight`}
          color={`${id}MainTextColor`} />}

        {subTitle !== 'Idle' && <ColorField title="Main text color"
          settings={settings}
          colors={colors}
          onChange={onChange}
          onNewColor={onNewColor}
          margin="25px 0 0 0"
          color={`${id}MainTextColor`} />}

        {subTitle === 'Idle' && <SizeWeightAndColorField title="Help text"
          settings={settings}
          colors={colors}
          onChange={onChange}
          onNewColor={onNewColor}
          weights={fontWeightOptions}
          margin="25px 0 0 0"
          size={`${id}HelpTextFontSize`}
          weight={`${id}HelpTextWeight`}
          color={`${id}HelpTextColor`} />}

        {subTitle !== 'Idle' && <ColorField title="Help text color"
          settings={settings}
          colors={colors}
          onChange={onChange}
          onNewColor={onNewColor}
          margin="25px 0 0 0"
          color={`${id}HelpTextColor`} />}

        <ColorField title="Background"
          settings={settings}
          colors={colors}
          onChange={onChange}
          onNewColor={onNewColor}
          margin="25px 0 0 0"
          color={`${id}Background`} />

        {subTitle === 'Idle' && <SizeWithColorField title="Border"
          settings={settings}
          colors={colors}
          onChange={onChange}
          onNewColor={onNewColor}
          margin="25px 0 0 0"
          size={`${id}BorderSize`}
          color={`${id}BorderColor`} />}

        {subTitle !== 'Idle' && <ColorField title="Border color"
          settings={settings}
          colors={colors}
          onChange={onChange}
          onNewColor={onNewColor}
          margin="25px 0 0 0"
          color={`${id}BorderColor`} />}

        <SelectField title="Border style"
          settings={settings}
          onChange={onChange}
          options={stylingOptions}
          margin="25px 0 0 0"
          value={`${id}BorderStyle`} />

        {subTitle === 'Idle' && <SizeField title="Input height"
          settings={settings}
          onChange={onChange}
          margin="25px 0 0 0"
          size={`${id}Height`} />}

        {subTitle === 'Idle' && <SizeField title="Roundness"
          settings={settings}
          onChange={onChange}
          margin="25px 0 0 0"
          size={`${id}Roundness`} />}

        {subTitle === 'Idle' && <SizeField title="Horizontal padding"
          settings={settings}
          onChange={onChange}
          margin="25px 0 0 0"
          size={`${id}HorizontalPadding`} />}

        <CheckboxField label="Add shadows"
          settings={settings}
          onChange={onChange}
          margin="25px 0 0 0"
          value={`${id}Shadow`} />

        {settings[`${id}Shadow`] && <>
          <ColorField title="Input shadow color"
            settings={settings}
            colors={colors}
            onChange={onChange}
            onNewColor={onNewColor}
            margin="10px 0 0 0"
            color={`${id}ShadowColor`} />

          <SizeField title="Input shadow horizontal length"
            settings={settings}
            onChange={onChange}
            margin="25px 0 0 0"
            size={`${id}ShadowOffsetX`} />

          <SizeField title="Input shadow vertical length"
            settings={settings}
            onChange={onChange}
            margin="25px 0 0 0"
            size={`${id}ShadowOffsetY`} />

          <SizeField title="Input shadow blur"
            settings={settings}
            onChange={onChange}
            margin="25px 0 0 0"
            size={`${id}ShadowBlur`} />

          <SizeField title="Input shadow spread"
            settings={settings}
            onChange={onChange}
            margin="25px 0 0 0"
            size={`${id}ShadowSpread`} />
        </>}
      </>}
    </>
  );
};

const FileUploads = ({ settings, colors, onChange, onNewColor, onCopy }) => {
  return (
    <>
      <TitleField title="General Field Settings" />

      <SizeWithTypeField title="Maximum field width"
        settings={settings}
        onChange={onChange}
        margin="25px 0 0 0"
        types={typeOptions}
        size="fileUploadsWidth"
        type="fileUploadsWidthType" />

      <SelectField title="Field alignment"
        settings={settings}
        onChange={onChange}
        options={alignOptions}
        margin="25px 0 0 0"
        value="fileUploadsAlignment" />

      <div className={styles.doubleRow}>
        <div className={styles.left}>
          <SizeField title="Field top margin"
            settings={settings}
            onChange={onChange}
            margin="25px 0 0 0"
            size={`fileUploadsTopMargin`} />
        </div>
        <div className={styles.right}>
          <SizeField title="Field bottom margin"
            settings={settings}
            onChange={onChange}
            margin="25px 0 0 0"
            size={`fileUploadsBottomMargin`} />
        </div>
      </div>

      <States title="Field Styling" subTitle="Idle" id="fileUploadsIdle" settings={settings} colors={colors} onChange={onChange} onNewColor={onNewColor} onCopy={onCopy} />
      <States title="Field Styling" subTitle="Hover" id="fileUploadsHover" settings={settings} colors={colors} onChange={onChange} onNewColor={onNewColor} onCopy={onCopy} />

      <TitleField title="Files list styling" />

      <SizeWeightAndColorField title="Main text"
        settings={settings}
        colors={colors}
        onChange={onChange}
        onNewColor={onNewColor}
        weights={fontWeightOptions}
        margin="25px 0 0 0"
        size="fileUploadsFileTextFontSize"
        weight="fileUploadsFileTextWeight"
        color="fileUploadsFileTextColor" />

      <ColorField title="Background"
        settings={settings}
        colors={colors}
        onChange={onChange}
        onNewColor={onNewColor}
        margin="25px 0 0 0"
        color="fileUploadsFileBackground" />

      <SizeWithColorField title="Border"
        settings={settings}
        colors={colors}
        onChange={onChange}
        onNewColor={onNewColor}
        margin="25px 0 0 0"
        size="fileUploadsFileBorderSize"
        color="fileUploadsFileBorderColor" />

      <SelectField title="Border style"
        settings={settings}
        onChange={onChange}
        options={stylingOptions}
        margin="25px 0 0 0"
        value="fileUploadsFileBorderStyle" />

      <SizeField title="Input height"
        settings={settings}
        onChange={onChange}
        margin="25px 0 0 0"
        size="fileUploadsFileHeight" />

      <SizeField title="Roundness"
        settings={settings}
        onChange={onChange}
        margin="25px 0 0 0"
        size="fileUploadsFileRoundness" />

      <SizeField title="File horizontal padding"
        settings={settings}
        onChange={onChange}
        margin="25px 0 0 0"
        size="fileUploadsFileHorizontalPadding" />

      <SizeField title="File vertical margin"
        settings={settings}
        onChange={onChange}
        margin="25px 0 0 0"
        size="fileUploadsFileVerticalMargin" />

      <CheckboxField label="Add shadows"
        settings={settings}
        onChange={onChange}
        margin="25px 0 0 0"
        value="fileUploadsFileShadow" />

      {settings.fileUploadsFileShadow && <>
        <ColorField title="Input shadow color"
          settings={settings}
          colors={colors}
          onChange={onChange}
          onNewColor={onNewColor}
          margin="10px 0 0 0"
          color="fileUploadsFileShadowColor" />

        <SizeField title="Input shadow horizontal length"
          settings={settings}
          onChange={onChange}
          margin="25px 0 0 0"
          size="fileUploadsFileShadowOffsetX" />

        <SizeField title="Input shadow vertical length"
          settings={settings}
          onChange={onChange}
          margin="25px 0 0 0"
          size="fileUploadsFileShadowOffsetY" />

        <SizeField title="Input shadow blur"
          settings={settings}
          onChange={onChange}
          margin="25px 0 0 0"
          size="fileUploadsFileShadowBlur" />

        <SizeField title="Input shadow spread"
          settings={settings}
          onChange={onChange}
          margin="25px 0 0 0"
          size="fileUploadsFileShadowSpread" />
      </>}
    </>
  );
}

export default FileUploads;
