import styles from './Header.module.css';

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { verifyPermission } from 'misc/helpers.js';
import NoltWidget from 'components/shared/Nolt.js';

import {
  showUserDropdown, hideUserDropdown, resendVerificationEmail
} from 'store/ducks/user.js';

import {
  logout
} from 'store/ducks/_users.js';

import { Button, Avatar } from 'ui';

import { ReactComponent as StarsIcon } from 'assets/images/stars.svg';
import { ReactComponent as Logo } from 'assets/images/logo-icon-bw.svg';
import { ReactComponent as LogoutIcon } from 'assets/images/logout.svg';
import { ReactComponent as WarningIcon } from 'assets/images/warning.svg';

const Header = ({ center, showAccount, showMessages, showMenu }) => {
  const dispatch = useDispatch();

  const user = useSelector(state => state._users.user);
  const showDropdown = useSelector(state => state.user.showUserDropdown);

  const [userVerifiedResended, setUserVerifiedResended] = useState(false);

  const isLTD = user && user.lifeTime && user.lifeTime.enabled;

  const dropdown = <>
    <div className={styles.dropdownCover} onClick={() => dispatch(hideUserDropdown())} />
    <div className={styles.dropdown}>
      <ul>
        <li>
          <div>Account Management</div>
        </li>
        {user.authType !== 'onetool' && <li>
          <Link to={`/user/profile`} onClick={() => dispatch(hideUserDropdown())}>Profile</Link>
        </li>}
        {user.authType !== 'onetool' && verifyPermission('accessBilling', user) && <li>
          {!isLTD &&<Link to={`/user/billing`} onClick={() => dispatch(hideUserDropdown())}>Billing{!user.hasSubscription && <div className={styles.goPro}>GO PRO</div>}</Link>}
          {isLTD &&<Link to={`/user/billing`} onClick={() => dispatch(hideUserDropdown())}>LTD Plan</Link>}
        </li>}
        {user.authType !== 'onetool' && verifyPermission('accessUsers', user) && <li>
          <Link to={`/user/users`} onClick={() => dispatch(hideUserDropdown())}>Users</Link>
        </li>}
        {user.authType !== 'onetool' && verifyPermission('accessDomains', user) && <li>
          <Link to={`/user/domains`} onClick={() => dispatch(hideUserDropdown())}>Custom Domains</Link>
        </li>}
        {user.authType !== 'onetool' && <li>
          <Link to={`/user/affiliate`} onClick={() => dispatch(hideUserDropdown())}>Affiliate<div className={styles.commission}>50% commission</div></Link>
        </li>}
        {/* {user.authType !== 'onetool' && verifyPermission('accessConnectedServices', user) && <li>
          <Link to={`/user/accounts`} onClick={() => dispatch(hideUserDropdown())}>Connected Services</Link>
        </li>} */}
        {user.authType === 'onetool' &&<li>
          <Link to={`/user/onetool`} onClick={() => dispatch(hideUserDropdown())}>Plan Information</Link>
        </li>}
        <li>
          <NoltWidget onClick={() => dispatch(hideUserDropdown())}>Feedback</NoltWidget>
        </li>
        <li>
          <a href="https://support.questionscout.com/en" target="_blank" rel="noopener noreferrer" onClick={() => dispatch(hideUserDropdown())}>Support</a>
        </li>
        <li onClick={() => {
          dispatch(logout());
          dispatch(hideUserDropdown());
        }}>
          <span>Log Out</span><LogoutIcon />
        </li>
      </ul>
    </div>
  </>;

  return (
    <div className={[
      styles.main,
      center ? styles.center : ''
    ].join(' ')}>
      <div className={styles.top}>
        <div className={styles.left}>
          <Link to={`/`} className={styles.logo}><Logo /></Link>

          {showMenu && <ul className={styles.menu}>
            <li>
              <Link to={`/`} className={window.location.pathname === '/' ? styles.active : ''}>Workspaces</Link>
            </li>
            <li>
              <Link to={`/themes`} className={window.location.pathname.indexOf('/themes') !== -1 ? styles.active : ''}>Themes</Link>
            </li>
          </ul>}
        </div>

        {showAccount && <div className={styles.right}>
          {!user.pro && user.role === 'holder' && <div className={styles.upgrade}>
            <Button link={`/user/billing/plan`} theme="orange" size="small" icon={<StarsIcon />}>Upgrade</Button>
          </div>}

          <div className={styles.account} onClick={() => !showDropdown ? dispatch(showUserDropdown()) : dispatch(hideUserDropdown())}>
            <Avatar name={user.name} email={user.email} size={33} tooltip={false} clickable={true} border={false} />
          </div>

          {showDropdown && dropdown}
        </div>}
      </div>

      {showMessages && <div className={styles.bottom}>
        {!user.verified && user.role === 'holder' && <div className={styles.message}>
          {!userVerifiedResended && <p><WarningIcon />As a security measure, receiving answers with your forms is paused until you have verified your email.</p>}
          {userVerifiedResended && <p>We have sent an email with a confirmation link to your email address. Check your spam or junk folder if you don’t see the email in your inbox.</p>}

          {!userVerifiedResended && <Button theme="white" onClick={() => {
            dispatch(resendVerificationEmail());
            setUserVerifiedResended(true);
          }}>Resend Verification Email</Button>}
        </div>}

        {(user.planUsage.submissions > user.plan.submissions || user.planUsage.filesStorage > user.plan.filesStorage) && <div className={styles.message}>
          {!userVerifiedResended && <p>
            <WarningIcon />You have exceeded your maximum monthly usage limits. ({user.planUsage.submissions} out of {user.plan.submissions} submissions, {user.planUsage.filesStorage}mb out of {user.plan.filesStorage}mb file upload)
          </p>}

          {!user.pro && <Button theme="white" link='/user/billing'>Upgrade to Pro</Button>}
          {user.pro && <Button theme="white" link='/user/billing'>Upgrade Your Plan</Button>}
        </div>}
      </div>}
    </div>
  )
};

export default Header;
