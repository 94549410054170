import styles from './AddEditUsers.module.css';

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import validator from 'validator';

import {
  hideAddEditUsersModal
} from 'store/ducks/user.js';

import {
  resetEndpoitState, getUsers, createUser, updateUser
} from 'store/ducks/_users.js';

import { Button, Modal, FormRow, TextInput, Loader, Radio } from 'ui';

const AddEditUsers = () => {
  const dispatch = useDispatch();

  const show = useSelector(state => state.user.addEditUsersModal._show);
  const userId = useSelector(state => state.user.addEditUsersModal.user);
  const users = useSelector(state => state._users.users);

  const user = useSelector(state => state._users.user);

  const createUserLoading = useSelector(state => state._users.createUser.loading);
  const createUserSuccess = useSelector(state => state._users.createUser.success);
  const createUserError = useSelector(state => state._users.createUser.error);

  const updateUserLoading = useSelector(state => state._users.updateUser.loading);
  const updateUserSuccess = useSelector(state => state._users.updateUser.success);
  const updateUserError = useSelector(state => state._users.updateUser.error);

  const [formEmail, setFormEmail] = useState('');
  const [formRole, setFormRole] = useState('user');

  const exceed = !userId && user.planUsage.users >= user.plan.users;

  useEffect(() => {
    if (userId) {
      setFormEmail(users.find((u) => u._id === userId).email);
      setFormRole(users.find((u) => u._id === userId).role);
    }
  }, [userId]);

  const handleEnterKeyPress = (e) => {
    if (createUserLoading || updateUserLoading || e.key !== 'Enter') return;

    submit();
  }

  const cancel = () => {
    dispatch(resetEndpoitState('createUser'));
    dispatch(resetEndpoitState('updateUser'));
    dispatch(hideAddEditUsersModal());

    setFormEmail('');
    setFormRole('user');
  }

  const submit = () => {
    if (userId) {
      dispatch(updateUser({
        _id: userId,
        role: formRole
      }, { updateLocal: false }));
    } else {
      dispatch(createUser({
        email: formEmail,
        role: formRole
      }));
    }
  }

  if (createUserSuccess || updateUserSuccess) {
    dispatch(getUsers());
    cancel();
  }

  if (createUserError || updateUserError) {
    cancel();
  }

  return (
    <>
      {exceed && <Modal isOpen={show} onRequestClose={cancel} width={320} header="Upgrade your users limit" footer={<>
        <Button size="small" theme="white" onClick={cancel}>Close</Button>
        {(user.role === 'holder') && <Button size="small" theme="blue" margin="0 0 0 15px" link="/user/billing">Go to Billing</Button>}
      </>}>
        <div className={styles.content}>
          {!user.customizedFree && user.role === 'holder' && <p>Your account has reached maximum limit of users. If you would like to create more, head over to your billing page and configure your Pro plan which start at prices as low as $1.</p>}
          {!user.customizedFree && user.role === 'admin' && <p>Your account has reached maximum limit of users. Please contact your account administrator to increase the users limits.</p>}

          {user.customizedFree && user.role === 'holder' && <p>Your current Customized Free plan allows you to create {user.plan.users} user{user.plan.users === 1 ? '' : 's'}. If you would like to create more, head over to your billing page and configure your Pro plan which start at prices as low as $1.</p>}
          {user.customizedFree && user.role === 'admin' && <p>Your current Customized Free plan allows you to create {user.plan.users} user{user.plan.users === 1 ? '' : 's'}. Please contact your account administrator to increase the users limits.</p>}
        </div>
      </Modal>}

      {!exceed && <Modal isOpen={show} onRequestClose={cancel} width={500} header={userId ? 'Editing user' : 'Inviting user to your account'} footer={<>
        <Button size="small" theme="white" onClick={cancel} margin="0 15px 0 0">Cancel</Button>
        <Button size="small" theme="blue" onClick={submit} disabled={createUserLoading || updateUserLoading || !validator.isEmail(formEmail)}>{userId ? 'Update user' : 'Invite user'}</Button>
      </>}>
        <div className={styles.content}>
          {(createUserLoading || updateUserLoading) && <Loader size={30} />}

          {!createUserLoading && !updateUserLoading && <>
            <FormRow label="Email">
              <TextInput type="email" value={formEmail} disabled={userId} autoFocus={true} width={'100%'} placeholder="user@domain.com" onKeyPress={handleEnterKeyPress} onChange={(e) => setFormEmail(e.target.value)} />
            </FormRow>

            <FormRow label="Role">
              <ul className={styles.role}>
                <li onClick={() => setFormRole('admin')}>
                  <Radio value={formRole === 'admin'} />

                  <div className={styles.text}>
                    <span>Administrator</span>
                    <p>Can <strong>access and manage</strong> all workspaces, forms and users.</p>
                  </div>
                </li>
                <li onClick={() => setFormRole('mod')}>
                  <Radio value={formRole === 'mod'} />

                  <div className={styles.text}>
                    <span>Moderator</span>
                    <p>Can <strong>access and manage</strong> all workspaces and forms.</p>
                  </div>
                </li>
                <li onClick={() => setFormRole('user')}>
                  <Radio value={formRole === 'user'} />

                  <div className={styles.text}>
                    <span>User</span>
                    <p>Can <strong>access</strong> workspaces, forms & themes when invited by a moderator or admin.</p>
                  </div>
                </li>
              </ul>
            </FormRow>
          </>}
        </div>
      </Modal>}
    </>
  );
};

export default AddEditUsers;
