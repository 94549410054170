import styles from './BillingPlan.module.css';

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getFormsPrice, getSubmissionsPrice, getUsersPrice, getFilesStoragePrice, getDomainsPrice, formatBytes, getTaxRateForCountry } from 'misc/helpers.js';

import { Card, Button, Loader } from 'ui';

class BillingPlan extends Component {
  render() {
    const {
      planUsage, billing, billingLoading, planType
    } = this.props;

    let taxRate;
    let totalNet = 0;
    let totalGross = 0;
    let isUnlimited;

    if (billing && billing.address) {
      taxRate = getTaxRateForCountry(billing.address.country, billing.vatEu ? billing.vatEu.value : false);

      totalNet = Math.round(((billing.plan.brandingRemoval ? 5 : 0) + getFormsPrice(billing.plan.forms) + getDomainsPrice(billing.plan.domains) + getSubmissionsPrice(billing.plan.submissions) + getUsersPrice(billing.plan.users) + getFilesStoragePrice(billing.plan.filesStorage)) * 100) / 100;
      isUnlimited = totalNet >= 99 || planType === 'unlimited';

      if (isUnlimited) totalNet = 99;

      totalGross = (Math.round((totalNet + (totalNet / 100 * taxRate)) * 100) / 100);
    }

    return (
      <Card className={styles.main}>
        <div className={styles.left}>
          <div className={styles.head}>
            <div className={styles.title}>Your current QuestionScout Pro Plan</div>
            <Button theme="blue" size="small" disabled={billingLoading} link="/user/billing/plan">Update Plan</Button>
          </div>

          {!billingLoading && <ul>
            <li>
              <div>All Features</div>
              <div style={{ color: '#24B47E' }}>Included</div>
            </li>
            <li>
              <div>{isUnlimited ? <><div>Unlimited</div> forms</> : <>{billing.plan.forms} total forms</>} <span>({planUsage.forms || 0} in use)</span></div>
              {!isUnlimited && <div>€{getFormsPrice(billing.plan.forms)}</div>}
            </li>
            <li>
              <div>{isUnlimited ? <><div>Unlimited</div> monthly responses</> : <>{billing.plan.submissions} monthly responses</>} <span>({planUsage.submissions || 0} used this month)</span></div>
              {!isUnlimited && <div>€{getSubmissionsPrice(billing.plan.submissions)}</div>}
            </li>
            <li>
              <div>{isUnlimited ? <><div>Unlimited</div> users</> : <>{billing.plan.users} user{billing.plan.users > 1 && 's'}</>} <span>({planUsage.users || 1} in use)</span></div>
              {!isUnlimited && <div>€{getUsersPrice(billing.plan.users)}</div>}
            </li>
            <li>
              <div>{formatBytes((billing.plan.filesStorage || 0) * 1000000)} file storage <span>({formatBytes((planUsage.filesStorage || 0) * 1000000)} used this month)</span></div>
              {!isUnlimited && <div>€{getFilesStoragePrice(billing.plan.filesStorage)}</div>}
            </li>
            <li>
              <div>{isUnlimited ? <><div>Unlimited</div> domains</> : <>{billing.plan.domains} domain{billing.plan.domains === 1 ? '' : 's'}</>} <span>({planUsage.domains} in use)</span></div>
              {!isUnlimited && <div>€{getDomainsPrice(billing.plan.domains)}</div>}
            </li>
            <li>
              <div>{isUnlimited ? <>Branding removal</> : <>Branding removal</>} <span>({isUnlimited || billing.plan.brandingRemoval ? 'included' : 'excluded'})</span></div>
              {!isUnlimited && <div>€{billing.plan.brandingRemoval ? 5 : 0}</div>}
            </li>
          </ul>}

          {!billingLoading && <div className={styles.tax}>
            <div>Tax</div>
            <div>€{Math.round((totalNet / 100 * taxRate) * 100) / 100}</div>
          </div>}

          {!billingLoading && <div className={styles.total}>
            <div>Total Monthly Bill</div>
            <div>€{totalGross}</div>
          </div>}

          {!billingLoading && billing.coupon && <div className={styles.coupon}>
            Coupon: <strong>{billing.coupon.name}</strong> - {billing.coupon.percent_off}% off {billing.coupon.duration_in_months && <>for {billing.coupon.duration_in_months} month{billing.coupon.duration_in_months > 1 ? 's' : ''}</>}
          </div>}

          {billingLoading && <Loader size={30} />}
        </div>

        <div className={styles.right}>
          <div className={styles.head}>
            <div className={styles.title}>Payment Method</div>
            <Button theme="white" size="small" disabled={billingLoading} link="/user/billing/paymentmethod">Edit</Button>
          </div>

          {!billingLoading && <div className={styles.collectionMethod}>
            You are currently using
            {billing.collectionMethod === 'charge_automatically' && <><strong> Automatic Payments</strong> with a <strong>Credit Card</strong>.</>}
            {billing.collectionMethod === 'send_invoice' && <><strong> Manual Payments</strong> with a <strong>invoices</strong>.</>}
          </div>}

          {!billingLoading && billing.card && billing.collectionMethod === 'charge_automatically' && <div className={styles.card}>
            <p>{billing.card.brand}</p>
            <p>**** **** **** {billing.card.last4}</p>
          </div>}

          {billingLoading && <Loader size={30} />}
        </div>
      </Card>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    planType: state._users.user.planType,
    billing: state.user.billing,
    billingLoading: state.user.billingLoading,
    planUsage: state._users.user.planUsage
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(BillingPlan);
