import checkLogic from 'helpers/checkLogic.js';

export default function getThankYouPage(defaultThankYouPage, thankYouPages, fields, values) {
  let selected = defaultThankYouPage;

  if (!Array.isArray(thankYouPages)) return selected;

  for (let page of thankYouPages) {
    if (checkLogic(page.conditions, page.conditionsType, values, fields)) {
      selected = page;
      break;
    }
  }

  return selected;
};
