import React from 'react';
import { Range, getTrackBackground } from 'react-range';

export const Slider = ({ value, values, onChange, step, min, max }) => {
  return (
    <Range
      step={step || 1}
      min={min || 0}
      max={max || (values.length - 1)}
      values={typeof value === 'number' ? [value] : value}
      onChange={(index) => onChange(index)}
      renderTrack={({ props, children }) => (
        <div onMouseDown={props.onMouseDown} onTouchStart={props.onTouchStart} style={{
          ...props.style,
          height: '32px',
          display: 'flex',
          width: '100%'
        }}>
          <div ref={props.ref} style={{
            height: '5px',
            width: '100%',
            borderRadius: '3px',
            background: getTrackBackground({
              values: typeof value === 'number' ? [value] : value,
              colors: typeof value === 'number' ? ['#732A56', '#E7E7E7'] : ['#E7E7E7', '#732A56', '#E7E7E7'],
              min: 0,
              max: values.length - 1
            }),
            alignSelf: 'center'
          }}>{children}</div>
        </div>
      )}
      renderThumb={({ props, isDragged }) => (
        <div {...props} style={{
          ...props.style,
          backgroundColor: '#FFFFFF',
          border: '1px solid #732A56',
          borderRadius: '100%',
          boxShadow: '0 2px 3px 0 rgba(0, 0, 0, 0.1)',
          width: '16px',
          height: '16px'
        }}>
          <div style={{
            backgroundImage: !isDragged ? 'linear-gradient(180deg, #C7D5E1 0%, #FFFFFF 100%)' : 'linear-gradient(180deg, #C7D5E1 0%, #E7E7E7 100%)',
            borderRadius: '100%',
            width: '8px',
            height: '8px',
            margin: '4px 4px 4px 4px'
          }} />
        </div>
      )}
    />
  );
};

export const SliderMultiple = ({ values, onChange, step, min, max }) => {
  return <Range
      values={values}
      step={step || 1}
      min={min || 0}
      max={max || 100}
      onChange={onChange}
      renderTrack={({ props, children }) => (
        <div onMouseDown={props.onMouseDown} onTouchStart={props.onTouchStart} style={{
          ...props.style,
          height: '32px',
          display: 'flex',
          width: '100%'
        }}>
          <div ref={props.ref} style={{
            height: '5px',
            width: '100%',
            borderRadius: '3px',
            background:  '#732A56',
            alignSelf: 'center'
          }}>{children}</div>
        </div>
      )}
      renderThumb={({ props, isDragged, index }) => (
        <div {...props} style={{
          ...props.style,
          backgroundColor: '#FFFFFF',
          border: '1px solid #732A56',
          borderRadius: '100%',
          boxShadow: '0 2px 3px 0 rgba(0, 0, 0, 0.1)',
          width: '16px',
          height: '16px'
        }}>
          <div style={{
            backgroundImage: !isDragged ? 'linear-gradient(180deg, #C7D5E1 0%, #FFFFFF 100%)' : 'linear-gradient(180deg, #C7D5E1 0%, #E7E7E7 100%)',
            borderRadius: '100%',
            width: '8px',
            height: '8px',
            margin: '4px 4px 4px 4px'
          }} />
        </div>
      )}
    />;
};
