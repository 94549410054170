import styles from './Menu.module.css';

import React from 'react';

import {
  TitleField, SizeWeightAndColorField, SizeWithColorField, SizeField, ColorField
} from '../ThemeMenuFields.js';

const fontWeightOptions = [
  { label: 400, value: 400 },
  { label: 600, value: 600 }
];

const ProgressBar = ({ settings, colors, onChange, onNewColor }) => {
  return (
    <>
      <SizeField title="Progress bar height"
        settings={settings}
        onChange={onChange}
        margin="25px 0 0 0"
        size="progressBarHeight" />

      <ColorField title="Progress bar color"
        settings={settings}
        colors={colors}
        onChange={onChange}
        onNewColor={onNewColor}
        margin="25px 0 0 0"
        color="progressBarColor" />

      <ColorField title="Progress bar background color"
        settings={settings}
        colors={colors}
        onChange={onChange}
        onNewColor={onNewColor}
        margin="25px 0 0 0"
        color="progressBarBackgroundColor" />

      <SizeWeightAndColorField title="Progress bar badge text"
        settings={settings}
        colors={colors}
        onChange={onChange}
        onNewColor={onNewColor}
        weights={fontWeightOptions}
        margin="25px 0 0 0"
        size="progressBarBadgeFontSize"
        weight="progressBarBadgeWeight"
        color="progressBarBadgeColor" />

      <ColorField title="Progress bar badge background color"
        settings={settings}
        colors={colors}
        onChange={onChange}
        onNewColor={onNewColor}
        margin="25px 0 0 0"
        color="progressBarBadgeBackgroundColor" />

      <SizeWithColorField title="Progress bar badge border"
        settings={settings}
        colors={colors}
        onChange={onChange}
        onNewColor={onNewColor}
        margin="25px 0 0 0"
        size="progressBarBadgeBorderSize"
        color="progressBarBadgeBorderColor" />

      <SizeField title="Progress bar badge roundness"
        settings={settings}
        onChange={onChange}
        margin="25px 0 0 0"
        size="progressBarBadgeRoundness" />

      <SizeField title="Progress bar badge horizontal padding"
        settings={settings}
        onChange={onChange}
        margin="25px 0 0 0"
        size="progressBarBadgeHorizontalPadding" />

      <SizeField title="Progress bar badge vertical padding"
        settings={settings}
        onChange={onChange}
        margin="25px 0 0 0"
        size="progressBarBadgeVerticalPadding" />
    </>
  );
}

export default ProgressBar;
